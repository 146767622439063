import { lang } from "../lang";

//Hooks
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

//Material UI
import { Button, Grid, Typography } from "@material-ui/core";

//Actions
import snackbarActions from "../actions/snackbar.actions";
import modalActions from "../actions/modal.actions";

import UserService from "../services/users/UserService";

const Userservive = new UserService();

function DeleteBuyTimeDayComponent(props) {
  let history = useHistory();
  const locale = localStorage.locale;
  const dispatch = useDispatch();

  // console.log("delete", props);

  function del() {
    props.handleDeleteDay(props.dayindex)
    closeModal();
  }

  function closeModal() {
    // console.log("In Close Modal DeleteUserOrCustomer Component");
    dispatch(modalActions.closeModal());
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>
          {lang[locale].buyTimeDeleteConfirm} ?
        </Typography>
        <br />
        <Typography>
          <b>{lang[locale].buyTime} : {props.day}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} style={{marginTop : "1em"}}>
        <Grid container justify="space-between">
          <Grid item>
            <Button color="primary" onClick={closeModal}>
              {lang[locale].close}
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" variant="contained" onClick={del}>
              {lang[locale].send}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DeleteBuyTimeDayComponent;