/**
 * essential configuration
 */
const prod = {
    url: {
        //API_URL: "https://192.168.100.75:8080",
        // API_URL: "https://emdacs.timpetools.de",
        API_URL: "https://emdacs-test.timpetools.de",
    },
    version: "0.1",
};
const dev = {
    url: {
        API_URL: "http://localhost:3000",
    },
    version: "0.1",
};
export const config = process.env.NODE_ENV === "development" ? dev : prod;
