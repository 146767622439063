import React, { useEffect, useState, useRef, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import {
  SortingState,
  IntegratedSorting,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
  SummaryState,
  IntegratedSummary,
  GroupingState,
  IntegratedGrouping,

} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  TableFilterRow,
  TableSummaryRow,
  TableGroupRow,
  ExportPanel,
  Toolbar,
  GroupingPanel,
  DragDropProvider,
  TableColumnVisibility,
  ColumnChooser,
  TableColumnReordering,
  Template,
  TemplatePlaceholder,
  TemplateConnector,
} from "@devexpress/dx-react-grid-material-ui";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { GridExporter } from "@devexpress/dx-react-grid-export";
import { lang } from "../lang";
import LoadcurveService from "../services/loadcurves/LoadcurveService";
import AuthService from "../services/auth/AuthService";
import snackbarActions from "../actions/snackbar.actions";
import { useDispatch } from "react-redux";
import DateRange from "@material-ui/icons/DateRange";
import Input from "@material-ui/core/Input";
import TwoDecimalComponent from "./TwoDecimal";
import $ from "jquery";
import authActions from "../actions/auth.actions";
import { Link } from "react-router-dom";
import { Button, Typography, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core";

/* interface OrderDataGridProps {
    changeNavTitle(title: string): void;
} */

const service = new LoadcurveService();

/*combines multiple plugins*/
/*const DataTypeProviders = () => <Plugin></Plugin>;*/

function OrdersDataGridComponent(props) {
  const theme = useTheme();
  let history = useHistory();
  const locale = localStorage.locale;
  const role = AuthService.getRole();
  const dispatch = useDispatch();
  const [orders, setOrders] = useState(null);
  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([
    { columnName: "customerName", direction: "asc" },
  ]);
  const [columnWidths, setColumnWidths] = useState([
    { columnName: "customerName", width: 150 },
    { columnName: "dealticket", width: 150 },
    { columnName: "name", width: 150 },
    // { columnName: "percentage", width: 150 },
    { columnName: "amount", width: 150 },
    { columnName: "dat", width: 180 },
    { columnName: "price", width: 180 },
    { columnName: "charge", width: 150 },
    { columnName: "totalPrice", width: 200 },
    { columnName: "totalCosts", width: 200 },
    { columnName: "cost", width: 250 },
    { columnName: "margin", width: 200 },
    { columnName: "email", width: 200 },
    { columnName: "commodity", width: 150 },
    { columnName: "validators", width: 150 },
    { columnName: "zrid", width: 150 },
  ]);

  /* Grouping per customer */

  const [grouping, setGrouping] = useState([]);

  const [groupSummaryItems] = useState([
    { columnName: "customerName", type: "count" },
    { columnName: "amount", type: "sum" },
    { columnName: "margin", type: "sum" },
    {
      columnName: "cost",
      type: "sum",
    },
    { columnName: "price", type: "weightedAverage" },
    { columnName: "totalCosts", type: "sum" },
    { columnName: "totalPrice", type: "totalWeightedAverage" },
  ]);

  /* Disables Grouing by zrid */

  const [groupingStateColumnExtensions] = useState([
    { columnName: "zrid", groupingEnabled: false },
  ]);

  /* Defines a set of filters */
  const [dateFilterOperations] = useState([
    "month",
    "contains",
    "startsWith",
    "endsWith",
  ]);

  const [numberFilterOperations] = useState([
    /* "equal",
        "notEqual", */
    "greaterThan",
    /* "greaterThanOrEqual", */
    "lessThan",
    /*  "lessThanOrEqual", */
  ]);

  /* Classification of columns in datatypes for filtering options*/

  const [linkColumns] = useState(["zrid"]);
  const [dateColumns] = useState(["dat"]);
  const [numberColumns] = useState([
    "percentage",
    "price",
    "margin",
    "cost",
    "totalPrice",
    "totalCosts",
    "charge",
  ]);
  const [amountColumns] = useState(["amount"]);

  /*----------------------- EXPORT ---------------------------------------*/
  // const handleSave = (workbook) => {
  //     workbook.xlsx.writeBuffer().then((buffer) => {
  //         saveAs(
  //             new Blob([buffer], { type: "application/octet-stream" }),
  //             "Data.csv"
  //         );
  //     });
  // };

  const getRows = (orders) => {
    let rows = [];

    if (orders) {
      orders.map((order, id) => rows.push({ id, ...order }));
      // console.log("rows", rows);
      // console.log("orders", orders);
    }
    return rows;
  };

  const exporterRef = useRef(null);

  const startExport = useCallback(
    (options) => {
      exporterRef.current.exportGrid(options);
    },
    [exporterRef]
  );

  /*defines the title of the page*/
  useEffect(() => {
    props.changeNavTitle(lang[locale].allOrders);
    document.title = lang[locale].allOrders + " | EMDACS";
  }, []);

  useEffect(() => {
    dispatch(authActions.checkLogin());
  }, []);

  /*Translates the table */
  const filterRowMessages = {
    month: lang[locale].filterMonth,
    contains: lang[locale].contains,
    notContains: lang[locale].notContains,
    startsWith: lang[locale].startsWith,
    endsWith: lang[locale].endsWith,
    equal: lang[locale].equals,
    notEqual: lang[locale].doesNotEqual,
    greaterThan: lang[locale].greaterThan,
    greaterThanOrEqual: lang[locale].greaterThanOrEqual,
    lessThan: lang[locale].lessThan,
    lessThanOrEqual: lang[locale].lessThanOrEqual,
  };

  const tableMessages = {
    noData: lang[locale].noData,
  };

  const groupingPanelMessages = {
    groupByColumn: lang[locale].groupByColumn,
  };

  const exportMessages = {
    exportAll: lang[locale].exportData,
  };

  const handleResetFilters = () => {
    setFilters([]);
  };

  function getOrdersData() {
    if (role.simple_role === "LIEFERANT") {
      service
        .getAllOrders()
        .then((res) => {
          if (res === []) {
            setOrders([]);
          } else {
            setOrders(editOrders(res));
          }
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          setOrders([]);
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    } else {
      service
        .getOrders()
        .then((res) => {
          if (res === []) {
            setOrders([]);
          } else {
            setOrders(editOrders(res));
          }
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          setOrders([]);
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
  }
  /*gets the data*/
  useEffect(() => {
    getOrdersData();
  }, []);

  function deleteHandler(customer, zrid) {
    service.deleteVirtualBuy(customer, zrid);
    setTimeout(() => {
      getOrdersData();
      dispatch(
        snackbarActions.openSnackbar(
          lang[locale].removeOrderFromOrders,
          "success"
        )
      );
    }, 2000);
  }

  const editOrders = (orders) => {
    // console.log("orders", orders);
    if (orders !== []) {
      for (var i in orders) {
        orders[i].id = orders[i].id ? orders[i].id : 0;
        orders[i].dat = orders[i].dat
          ? orders[i].dat.split("T").join(", ")
          : " ";
        orders[i].price = orders[i].price ? orders[i].price : 0;
        // orders[i].percentage = orders[i].percentage ? orders[i].percentage * 100 : 0;
        orders[i].zrid = orders[i].prog_zrid;
        // orders[i].prog_zrid = orders[i].prog_zrid ? orders[i].prog_zrid : 0;
        orders[i].zrid_order = orders[i].zrid_order ? orders[i].zrid_order : 0;
        orders[i].dealticket = orders[i].dealticket ? orders[i].dealticket : 0;
        orders[i].name = orders[i].name ? orders[i].name.split("_")[0] : " ";
        orders[i].amount = orders[i].amount ? orders[i].amount : 0;
        orders[i].commodity = orders[i].commodity === 1 ? "Strom" : "Gas";
        orders[i].email = orders[i].email ? orders[i].email : " ";
        orders[i].customer = orders[i].customer ? orders[i].customer : " ";
        orders[i].customer_id = orders[i].customer_id
          ? orders[i].customer_id
          : 0;
        orders[i].zrid = orders[i].zrid ? orders[i].zrid : 0;
        orders[i].cost = orders[i].price
          ? orders[i].price * orders[i].amount
          : 0;
        orders[i].margin = orders[i].charge
          ? orders[i].charge * orders[i].amount
          : 0;
        orders[i].charge = orders[i].charge ? orders[i].charge : " ";
        orders[i].customerName = orders[i].customer ? orders[i].customer : " ";
        orders[i].validators = orders[i].validators
          ? orders[i].validators.map((v) => " " + v)
          : " ";
        orders[i].totalPrice = orders[i].charge
          ? orders[i].price + orders[i].charge
          : orders[i].price;
        orders[i].totalCosts = orders[i].amount * orders[i].totalPrice;
      }
      return orders;
    }
  };

  // csv export process
  const conOrders = { ...orders };

  const convertOrders = (conOrders) => {
    let rowsToCsv = [];
    if (conOrders !== []) {
      for (let i in conOrders) {
        conOrders[i].amount = conOrders[i].amount.toLocaleString("de");
        conOrders[i].charge = conOrders[i].charge.toLocaleString("de");
        conOrders[i].commodity = conOrders[i].commodity.toLocaleString("de");
        conOrders[i].cost = conOrders[i].cost.toLocaleString("de");
        conOrders[i].customer = conOrders[i].customer.toLocaleString("de");
        // conOrders[i].customerName = conOrders[i].customerName.toLocaleString('de');
        conOrders[i].customer_id = conOrders[i].customer_id.toLocaleString(
          "de"
        );
        conOrders[i].dat = conOrders[i].dat.toLocaleString("de");
        conOrders[i].dealticket = conOrders[i].dealticket.toString();
        conOrders[i].email = conOrders[i].email.toLocaleString("de");
        conOrders[i].id = conOrders[i].id.toLocaleString("de");
        conOrders[i].margin = conOrders[i].margin.toLocaleString("de");
        conOrders[i].name = conOrders[i].name.toString();
        // conOrders[i].percentage = conOrders[i].percentage.toLocaleString('de');
        conOrders[i].price = conOrders[i].price.toLocaleString("de");
        conOrders[i].prog_zrid = conOrders[i].prog_zrid.toString();
        conOrders[i].totalCosts = conOrders[i].totalCosts.toLocaleString("de");
        conOrders[i].totalPrice = conOrders[i].totalPrice.toLocaleString("de");
        conOrders[i].validators = conOrders[i].validators.toLocaleString("de");
        conOrders[i].zrid_order = conOrders[i].zrid_order.toString();
        conOrders[i].zrid = conOrders[i].zrid.toString();
        rowsToCsv.push({
          id: conOrders[i].id,
          amount: conOrders[i].amount,
          charge: conOrders[i].charge,
          commodity: conOrders[i].commodity,
          cost: conOrders[i].cost,
          customer: conOrders[i].customer,
          // customerName : conOrders[i].customerName,
          customer_id: conOrders[i].customer_id,
          dat: conOrders[i].dat,
          dealticket: conOrders[i].dealticket,
          email: conOrders[i].email,
          margin: conOrders[i].margin,
          name: conOrders[i].name,
          // percentage : conOrders[i].percentage,
          price: conOrders[i].price,
          prog_zrid: conOrders[i].prog_zrid,
          totalCosts: conOrders[i].totalCosts,
          totalPrice: conOrders[i].totalPrice,
          validators: conOrders[i].validators,
          zrid_order: conOrders[i].zrid_order,
          zrid: conOrders[i].zrid,
        });
      }
      return rowsToCsv;
    }
  };

  /*----------------------- EXPORT ---------------------------------------*/
  const handleSave = async () => {
    // workbook.xlsx.writeBuffer().then((buffer) => {
    //     saveAs(
    //         new Blob([buffer], { type: "application/octet-stream" }),
    //         "Data.csv"
    //     );
    // });
    // new ObjectsToCsv(rows).toDisk('rows.csv', {allColumns : true});

    if (convertOrders(conOrders)) {
      let csv = "";
      // let header = await Object.keys(convertOrders(conOrders)[0]).join(";");
      let header = await Object.keys(convertOrders(conOrders)[0])
        .map((i) => {
          if (i === "id") {
            return "id";
          }
          if (i === "amount") {
            return `${lang[locale].amount} [MWh]`;
          }
          if (i === "customer") {
            return lang[locale].customer;
          }
          if (i === "charge") {
            return `${lang[locale].charge} [EUR/MWh]`;
          }
          if (i === "dealticket") {
            return lang[locale].dealticket;
          }
          // if ( i === "percentage" ){
          //   return lang[locale].percentage
          // }
          if (i === "dat") {
            return lang[locale].buyDate;
          }
          if (i === "price") {
            return `${lang[locale].price} [EUR/MWh]`;
          }
          if (i === "totalPrice") {
            return `${lang[locale].totalPrice} [EUR/MWh]`;
          }
          if (i === "totalCosts") {
            return `${lang[locale].totalCostsWithOutUmlaut} [EUR]`;
          }
          if (i === "cost") {
            return `${lang[locale].costSupplier} [EUR]`;
          }
          if (i === "commodity") {
            return "commodity";
          }
          if (i === "email") {
            return `${lang[locale].buyerWithOutUmlaut}`;
          }
          if (i === "margin") {
            return `${lang[locale].margin} [EUR]`;
          }
          if (i === "name") {
            return lang[locale].name;
          }
          if (i === "customer_id") {
            return lang[locale].customerId;
          }
          if (i === "prog_zrid") {
            return "Prog_Zrid";
          }
          if (i === "validators") {
            return `${lang[locale].confirmationWithOutUmlaut}`;
          }
          if (i === "zrid_order") {
            return "Zrid_Order";
          }
          if (i === "zrid") {
            return "zrid";
          }
        })
        .join(";");

      let values = await convertOrders(conOrders)
        .map((o) =>
          Object.values(o) === " " || null || 0
            ? Object.values(o).join(";")
            : Object.values(o).join(";")
        )
        .join("\n");

      csv += header + "\n" + values;

      var a = document.createElement("a");
      a.href = "data:attachment/csv," + encodeURIComponent(csv);
      a.target = "_blank";
      a.download = "myFile.csv";

      document.body.appendChild(a);
      a.click();
    }
  };

  const columnsSupplier = [
    { name: "zrid", title: lang[locale].offersId },
    {
      name: "customerName",
      title: lang[locale].customer,
    },
    {
      name: "dealticket",
      title: lang[locale].dealticket,
    },
    { name: "name", title: lang[locale].name },
    // {
    //   name: "percentage",
    //   title: lang[locale].percentage + " (%)",
    // },
    {
      name: "amount",
      title: lang[locale].amount + " (MWh)",
    },
    {
      name: "dat",
      title: lang[locale].buyDate,
    },
    {
      name: "price",
      title: lang[locale].price + " (€/MWh)",
    },
    {
      name: "charge",
      title: lang[locale].charge + " (€/MWh)",
    },
    {
      name: "totalPrice",
      title: lang[locale].totalPrice + " (€/MWh)",
    },
    { name: "totalCosts", title: lang[locale].totalCosts + " (€/MWh)" },
    {
      name: "cost",
      title: lang[locale].costSupplier + " (€)",
    },
    {
      name: "margin",
      title: lang[locale].margin + " (€)",
    },
    { name: "email", title: lang[locale].buyer },
    { name: "commodity", title: "Commodity" },
    {
      name: "validators",
      title: lang[locale].confirmation,
    },
  ];

  const columnsCustomer = [
    { name: "zrid", title: lang[locale].offersId },
    {
      name: "dealticket",
      title: lang[locale].dealticket,
    },
    { name: "name", title: lang[locale].name },
    // {
    //   name: "percentage",
    //   title: lang[locale].percentage + " (%)",
    // },
    {
      name: "amount",
      title: lang[locale].amount + " (MWh)",
    },
    {
      name: "dat",
      title: lang[locale].buyDate,
    },
    {
      name: "price",
      title: lang[locale].price + " (€/MWh)",
    },
    {
      name: "cost",
      title: lang[locale].cost + " (€)",
    },
    { name: "email", title: lang[locale].buyer },
    { name: "commodity", title: "Commodity" },
    {
      name: "validators",
      title: lang[locale].confirmation,
    },
  ];

  // const getRows = (orders) => {
  //     let rows = [];

  //     if (orders) {
  //         orders.map((order, id) => rows.push({ id, ...order }));
  //     }

  //     return rows;
  // };

  /*-------------------- FILTER ----------------------------------------------*/
  /* customizes the fitler icon for the date column */
  /*--------------------------------------------------------------------------*/

  const FilterIcon = ({ type, ...restProps }) => {
    if (type === "month") return <DateRange {...restProps} />;
    return <TableFilterRow.Icon type={type} {...restProps} />;
  };

  /*custom filter for month*/

  const [filteringColumnExtensions] = useState([
    {
      columnName: "dat",
      predicate: (value, filter, row) => {
        if (!filter.value.length) return true;
        if (filter && filter.operation === "month") {
          const month = parseInt(value.split("-")[1], 10);
          return month === parseInt(filter.value, 10);
        }
        return IntegratedFiltering.defaultPredicate(value, filter, row);
      },
    },
  ]);

  /*-------------------- Summary ----------------------------------------------*/
  /* defines a custom summary type: median, weightedAverage */
  /*--------------------------------------------------------------------------*/

  const summaryCalculator = (type, rows, getValue) => {
    if (!rows.length) {
      return 0;
    }
    if (type === "weightedAverage") {
      const totalCosts = rows.reduce((acc, row) => {
        return acc + row.cost;
      }, 0);
      const totalAmount = rows.reduce((acc, row) => {
        return acc + row.amount;
      }, 0);
      const weightedAverage = totalCosts / totalAmount;
      return weightedAverage;
    }
    if (type === "totalWeightedAverage") {
      const totalCosts = rows.reduce((acc, row) => {
        return acc + row.totalPrice * row.amount;
      }, 0);
      const totalAmount = rows.reduce((acc, row) => {
        return acc + row.amount;
      }, 0);
      const totalWeightedAverage = totalCosts / totalAmount;
      return totalWeightedAverage;
    }
    return IntegratedSummary.defaultCalculator(type, rows, getValue);
  };

  const summaryMessages = {
    count: lang[locale].count,
    weightedAverage: lang[locale].weightedAverage,
    totalWeightedAverage: lang[locale].weightedAverage,
    sum: lang[locale].sum,
  };

  /*Defining the summary row of all entries */

  const [totalSummaryItems] = useState([
    { columnName: "customerName", type: "count" },
    { columnName: "amount", type: "sum" },
    { columnName: "margin", type: "sum" },
    { columnName: "cost", type: "sum" },
    { columnName: "price", type: "weightedAverage" },
    { columnName: "totalCosts", type: "sum" },
    { columnName: "totalPrice", type: "totalWeightedAverage" },
  ]);

  /*-------------------- Formatting ----------------------------------------------*/
  /* formatting values */
  /*-------------------------------------------------------------------------------*/

  const NumberFormatter = ({ value }) => (
    <>
      {value &&
        value.toLocaleString("de-DE", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })}
    </>
  );

  const AmountFormatter = ({ value }) => (
    <>
      {value &&
        value.toLocaleString("de-DE", {
          maximumFractionDigits: 3,
          minimumFractionDigits: 3,
        })}
    </>
  );

  const DateFormatter = ({ value }) => (
    <>
      {value &&
        new Date(value).toLocaleString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })}
    </>
  );

  const LinkFormatter = ({ row, value }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: row.virtual ? "space-between" : "flex-end",
        alignItems: "center",
      }}
    >
      {row.virtual && role.permissions.includes("VIRTUAL_ORDER") && (
        // <Button onClick={() => deleteHandler(row.customer_id, row.id)}>

        <DeleteIcon
          style={{ fontSize: "large", cursor: "pointer" }}
          onClick={() => deleteHandler(row.customer_id, row.id)}
        />
        // </Button>
      )}{" "}
      <Typography
        to={`/app/myplans/detailed/${row.customer_id}/${row.prog_zrid}`}
        style={{ textDecoration: "none" }}
        color="primary"
        component={Link}
      >
        {/* {console.log("LinkFormatter", row)} */}
        {value}
      </Typography>
    </div>
  );

  const NumberTypeProvider = (props) => (
    <DataTypeProvider
      formatterComponent={NumberFormatter}
      editorComponent={NumberEditor}
      {...props}
    />
  );

  const AmountTypeProvider = (props) => (
    <DataTypeProvider
      formatterComponent={AmountFormatter}
      /* editorComponent={AmountEditor} */
      {...props}
    />
  );

  const DateTypeProvider = (props) => (
    <DataTypeProvider
      formatterComponent={DateFormatter}
      /* editorComponent={AmountEditor} */
      {...props}
    />
  );

  const LinkTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={LinkFormatter} {...props} />
  );

  /*Formats the value's in the input fields to match the internal search */

  const NumberEditor = ({ value, onValueChange }) => {
    const handleChange = (event) => {
      const { value: targetValue } = event.target;
      if (targetValue.trim() === "") {
        onValueChange();
        return;
      }
      onValueChange(parseFloat(targetValue));
    };
    return (
      <Input
        type="number"
        fullWidth
        value={value === undefined ? "" : value}
        inputProps={{
          inputComponent: TwoDecimalComponent,
          placeholder: "Filter...",
        }}
        onChange={handleChange}
      ></Input>
    );
  };

  /* Remove the horizontal scroll bar with jquery*/

  $(".TableContainer-root-19").css("overflow", "initial");

  /*--------------------  Column visibility------------------------------------------*/
  /* Uses localStorage to store the default hidden colums if they are not set yet */
  /*-------------------------------------------------------------------------------*/

  useEffect(() => {
    if (
      !localStorage.defaultHiddenColumnNamesSupplier &&
      role.simple_role === "LIEFERANT"
    ) {
      localStorage.setItem(
        "defaultHiddenColumnNamesSupplier",
        JSON.stringify([
          "validators",
          "email",
          "price",
          "percentage",
          "dealticket",
          "commodity",
        ])
      );
    }

    if (
      !localStorage.defaultHiddenColumnNamesCustomer &&
      role.simple_role === "USER"
    ) {
      localStorage.setItem(
        "defaultHiddenColumnNamesCustomer",
        JSON.stringify([])
      );
    }
  }, []);

  /* Set changes to hidden columns in localStorage */

  const setHiddenColumnNamesSupplier = (props) => {
    localStorage.setItem(
      "defaultHiddenColumnNamesSupplier",
      JSON.stringify(props)
    );
  };

  const setHiddenColumnNamesCustomer = (props) => {
    localStorage.setItem(
      "defaultHiddenColumnNamesCustomer",
      JSON.stringify(props)
    );
  };

  /* Get the visible columns from the localStorage */

  const defaultHiddenColumnNamesSupplier =
    localStorage.defaultHiddenColumnNamesSupplier &&
    JSON.parse(localStorage.getItem("defaultHiddenColumnNamesSupplier"));

  const defaultHiddenColumnNamesCustomer =
    localStorage.defaultHiddenColumnNamesCustomer &&
    JSON.parse(localStorage.getItem("defaultHiddenColumnNamesCustomer"));

  /*--------------------  Column reordering ------------------------------------------*/
  /* Uses localStorage to store the order of the columns if they are not set yet */
  /*-------------------------------------------------------------------------------*/

  useEffect(() => {
    if (
      !localStorage.defaultOrderSupplier &&
      role.simple_role === "LIEFERANT"
    ) {
      localStorage.setItem(
        "defaultOrderSupplier",
        JSON.stringify([
          "zrid",
          "customerName",
          "dealticket",
          "name",
          // "percentage",
          "dat",
          "amount",
          "price",
          "charge",
          "totalPrice",
          "cost",
          "margin",
          "totalCosts",
          "email",
          "commodity",
          "validators",
        ])
      );
    }

    if (!localStorage.defaultOrderCustomer && role.simple_role === "USER") {
      localStorage.setItem(
        "defaultOrderCustomer",
        JSON.stringify([
          "zrid",
          "dealticket",
          "name",
          // "percentage",
          "dat",
          "amount",
          "price",
          "cost",
          "email",
          "commodity",
          "validators",
        ])
      );
    }
  }, []);

  /* Set changes to hidden columns in localStorage */

  const setColumnOrderSupplier = (props) => {
    localStorage.setItem("defaultOrderSupplier", JSON.stringify(props));
  };

  const setColumnOrderCustomer = (props) => {
    localStorage.setItem("defaultOrderCustomer", JSON.stringify(props));
  };

  const defaultOrderSupplier = localStorage.defaultOrderSupplier
    ? JSON.parse(localStorage.getItem("defaultOrderSupplier"))
    : [
        "zrid",
        "customerName",
        "dealticket",
        "name",
        // "percentage",
        "amount",
        "dat",
        "price",
        "charge",
        "totalCosts",
        "totalPrice",
        "cost",
        "margin",
        "email",
        "commodity",
        "validators",
      ];

  const defaultOrderCustomer = localStorage.defaultOrderCustomer
    ? JSON.parse(localStorage.getItem("defaultOrderCustomer"))
    : [
        "zrid",
        "dealticket",
        "name",
        // "percentage",
        "amount",
        "dat",
        "price",
        "cost",
        "email",
        "commodity",
        "validators",
      ];

  /* Aligning the number columns right */

  const [tableColumnExtensions] = useState([
    { columnName: "zrid", align: "right" },
    { columnName: "customerName", align: "right" },
    { columnName: "name", align: "right" },
    { columnName: "dealticket", align: "right" },
    // { columnName: "percentage", align: "right" },
    { columnName: "amount", align: "right" },
    { columnName: "dat", align: "right" },
    { columnName: "price", align: "right" },
    { columnName: "cost", align: "right" },
    { columnName: "totalPrice", align: "right" },
    { columnName: "totalCosts", align: "right" },
    { columnName: "charge", align: "right" },
    { columnName: "margin", align: "right" },
    { columnName: "email", align: "right" },
    { columnName: "commodity", align: "right" },
    { columnName: "validators", align: "right" },
  ]);

  /* Color the Header Row */
  const TitleComponent = (props) => {
    return (
      <TitleComponent
        {...props}
        style={{ color: theme.palette.primary.main }}
      />
    );
  };
  /* Color the Summary Row */

  const ItemComponent = (props) => {
    return (
      <TableSummaryRow.Item
        {...props}
        style={{ color: theme.palette.secondary.main }}
      />
    );
  };

  const rows = getRows(orders);
  return (
    <div>
      <Paper>
        {role.simple_role === "LIEFERANT" ? (
          <>
            {filters.length ? (
              <Tooltip title="Clear Filter">
                <Button style={{ margin: "1rem" }} onClick={handleResetFilters}>
                  <FilterAltOffIcon />
                </Button>
              </Tooltip>
            ) : null}
            <Grid rows={rows} columns={columnsSupplier}>
              {/* <DragDropProvider /> */}
              <DateTypeProvider
                for={dateColumns}
                availableFilterOperations={dateFilterOperations}
              />
              <NumberTypeProvider
                for={numberColumns}
                availableFilterOperations={numberFilterOperations}
                editorComponent={NumberEditor}
              />
              <AmountTypeProvider
                for={amountColumns}
                availableFilterOperations={numberFilterOperations}
              />
              <LinkTypeProvider for={linkColumns} />
              <FilteringState
                filters={filters}
                defaultFilters={[]}
                onFiltersChange={setFilters}
              />
              <IntegratedFiltering
                columnExtensions={filteringColumnExtensions}
              />
              <SortingState sorting={sorting} onSortingChange={setSorting} />
              <IntegratedSorting />
              <GroupingState
                grouping={grouping}
                onGroupingChange={setGrouping}
                columnExtensions={groupingStateColumnExtensions}
              />
              <IntegratedGrouping />
              <Table
                messages={tableMessages}
                columnExtensions={tableColumnExtensions}
              />
              <TableColumnResizing
                columnWidths={columnWidths}
                onColumnWidthsChange={setColumnWidths}
              />
              <TableColumnReordering
                defaultOrder={defaultOrderSupplier}
                onOrderChange={setColumnOrderSupplier}
              />
              <TableHeaderRow
                TitleComponent={TitleComponent}
                showSortingControls
                showGroupingControls
              />
              <TableFilterRow
                showFilterSelector
                iconComponent={FilterIcon}
                messages={filterRowMessages}
              />
              <SummaryState
                totalItems={totalSummaryItems}
                groupItems={groupSummaryItems}
              />
              <TableColumnVisibility
                defaultHiddenColumnNames={defaultHiddenColumnNamesSupplier}
                onHiddenColumnNamesChange={setHiddenColumnNamesSupplier}
              />
              <IntegratedSummary calculator={summaryCalculator} />
              <TableGroupRow showColumnsWhenGrouped />
              <TableSummaryRow
                itemComponent={ItemComponent}
                messages={summaryMessages}
              />
              <Toolbar />
              <ColumnChooser />
              <GroupingPanel
                showGroupingControls
                messages={groupingPanelMessages}
              />
              {rows.length > 0 ? (
                <span>
                  <ExportPanel
                    startExport={startExport}
                    messages={exportMessages}
                  />
                </span>
              ) : null}
            </Grid>
            <GridExporter
              ref={exporterRef}
              rows={rows}
              columns={columnsSupplier}
              grouping={grouping}
              totalSummaryItems={totalSummaryItems}
              groupSummaryItems={groupSummaryItems}
              onSave={handleSave}
            />
          </>
        ) : (
          <div>
            {filters.length ? (
              <Tooltip title="Clear Filter">
                <Button style={{ margin: "1rem" }} onClick={handleResetFilters}>
                  <FilterAltOffIcon />
                </Button>
              </Tooltip>
            ) : null}
            <Grid rows={getRows(orders)} columns={columnsCustomer}>
              {/* <DragDropProvider /> */}
              <DataTypeProvider
                for={dateColumns}
                availableFilterOperations={dateFilterOperations}
              />
              <NumberTypeProvider
                for={numberColumns}
                availableFilterOperations={numberFilterOperations}
                editorComponen={NumberEditor}
              />
              <AmountTypeProvider
                for={amountColumns}
                availableFilterOperations={numberFilterOperations}
              />
              <LinkTypeProvider for={linkColumns} />
              <FilteringState
                filters={filters}
                defaultFilters={[]}
                onFiltersChange={setFilters}
              />
              <IntegratedFiltering
                columnExtensions={filteringColumnExtensions}
              />
              <SortingState sorting={sorting} onSortingChange={setSorting} />
              <IntegratedSorting />
              <Table
                messages={tableMessages}
                columnExtensions={tableColumnExtensions}
              />
              <TableColumnResizing
                columnWidths={columnWidths}
                onColumnWidthsChange={setColumnWidths}
              />
              <TableColumnReordering
                defaultOrder={defaultOrderCustomer}
                onOrderChange={setColumnOrderCustomer}
              />
              <TableHeaderRow
                TitleComponent={TitleComponent}
                showSortingControls
              />
              <TableFilterRow
                showFilterSelector
                iconComponent={FilterIcon}
                messages={filterRowMessages}
              />
              <SummaryState totalItems={totalSummaryItems} />
              <TableColumnVisibility
                defaultHiddenColumnNames={defaultHiddenColumnNamesCustomer}
                onHiddenColumnNamesChange={setHiddenColumnNamesCustomer}
              />
              <IntegratedSummary calculator={summaryCalculator} />
              <TableSummaryRow
                itemComponent={ItemComponent}
                messages={summaryMessages}
              />
              <Toolbar />
              <ColumnChooser />
              {rows.length > 0 ? (
                <ExportPanel
                  startExport={startExport}
                  messages={exportMessages}
                />
              ) : null}
            </Grid>
            <GridExporter
              ref={exporterRef}
              rows={rows}
              columns={columnsCustomer}
              totalSummaryItems={totalSummaryItems}
              onSave={handleSave}
            />
          </div>
        )}
      </Paper>
    </div>
  );
}

export default OrdersDataGridComponent;
