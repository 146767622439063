import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "../services/helpers/useSelector";
import forecastActions from "../actions/forecast.actions";
import {
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Typography,
} from "@material-ui/core";
import { lang } from "../lang";
import LoadcurveService from "../services/loadcurves/LoadcurveService";
import snackbarActions from "../actions/snackbar.actions";
import { useHistory } from "react-router-dom";

const loadcurveService = new LoadcurveService();

/**
 * Gets temperaturstations and shows a select where it can get selected
 * @constructor
 */
export function SelectTemperatureStation() {
    let history = useHistory();
    const [stations, setStations] = useState<undefined | string[]>();
    const locale = localStorage.locale;

    const dispatch = useDispatch();

    const temperatureStation = useSelector(
        (state) => state.forecast.data.temperatureStation
    );

    /**
     * Saves tempstation in state
     * @param e
     */
    function handleChange(e) {
        dispatch(
            forecastActions.saveForecastSingle(
                "temperatureStation",
                e.target.value
            )
        );
    }

    useEffect(() => {
        //get stations with loadcurveService
        loadcurveService
            .getStations()
            .then((stations) => {
                setStations(stations);
            })
            .catch((e) => {
                if (e.message === "401") {
                    return history.push(`/logout`);
                }
                console.error(e);
                dispatch(snackbarActions.openSnackbar(e.message, "error"));
            });
    }, []);

    return (
        <>
            {stations === undefined ? (
                <CircularProgress data-testid="circular" />
            ) : (
                <>
                    <Grid item xs={6}>
                        <FormControl
                            fullWidth={true}
                            required
                            error={
                                temperatureStation === "" ||
                                temperatureStation === 0
                            }
                        >
                            <InputLabel id="temperature">
                                {lang[locale].temperatureStation}
                            </InputLabel>
                            <Select
                                labelId="temperature"
                                id="temperatureSelect"
                                value={temperatureStation ? temperatureStation : ''}
                                onChange={handleChange}
                            >
                                {/* <MenuItem value={0}>
                                    {lang[locale].notSelected}
                                </MenuItem> */}
                                {stations.map((station) => (
                                    <MenuItem
                                        key={station}
                                        data-testid="temperatureStation"
                                        value={station ? station : ''}
                                    >
                                        {station}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>
                                <Typography variant="caption">
                                    <Link
                                        href="https://klimaportal.timpetools.de"
                                        target="_blank"
                                    >
                                        {lang[locale].temperatureFinder}
                                    </Link>
                                </Typography>
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                </>
            )}
        </>
    );
}
