import { AnyAction } from "redux";
import { modalConstants } from "constants/modal.constants";
import ModalModel from "typeDefs/ModalModel";

const initialState: ModalModel = {
    open: false,
};
/**
 * Reducer for opening and closing the snackbar
 * @param state State
 * @param action Action
 */
export function modal(state: ModalModel = initialState, action: AnyAction) {
    switch (action.type) {
        case modalConstants.OPEN:
            // console.log("in modalConstants OPEN reducer", {
            //     ...state,
            //     open: true,
            //     props: action.props,
            //     body: action.body,
            //     lg: action.lg,
            // });
            return {
                ...state,
                open: true,
                props: action.props,
                body: action.body,
                lg: action.lg,
            };
        case modalConstants.CLOSE:
            // console.log("in modalConstants CLOSE reducer", {
            //     ...state,
            //     open: false,
            // });
            return {
                ...state,
                open: false,
            };
        default:
            return state;
    }
}
