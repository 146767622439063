import React, { useCallback, useEffect, useState, useRef } from "react";
import { lang } from "../lang";

//Material UI
import {
  Button,
  Grid,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";

//Hooks
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { useSelector } from "../services/helpers/useSelector";

//Actions
import modalActions from "../actions/modal.actions";
import forecastActions from "../actions/forecast.actions";
import snackbarActions from "../actions/snackbar.actions";
import authActions from "../actions/auth.actions";

//Services
import ForecastService from "../services/forecast/ForecastService";
import AuthService from "../services/auth/AuthService";

//Components
import { SelectHolidayComponent } from "./SelectHoliday";
import { TogglePowerGasComponent } from "./TogglePowerGas";
import { SelectTemperatureStation } from "./SelectTemperatureStation";
import { SelectStandardTradingProduct } from "./SelectStandardTradingProduct";
import { SelectUsage } from "./SelectUsage";
import { SelectForecastType } from "./SelectForecastType";
import { SelectProfileTypeComponent } from "./SelectProfileType";
import { SelectUsageType } from "./SelectUsageType";
import SelectMeasuredDatesComponent from "./SelectMeasuredDates";
import SelectVariantComponent from "./SelectVariant";
import SelectForecastNameComponent from "./SelectForecastName";
import SelectPriceCurveComponent from "./SelectPriceCurve";
import SelectDateComponent from "./SelectDate";
import FormelPriceComponent from "./FormelPrice";
import { customer } from "reducers/customer.reducer";
import { add, differenceInDays, sub } from "date-fns";
import { useLocation } from "react-router";

const forecastService = new ForecastService();

/**
 * Component for creating forecast
 * todo create tests for showing the right elements on forecast type
 * @constructor
 */
function CreateForecastComponentPE() {
  const location = useLocation();
  const pathName = location.pathname?.split("/")[2];
  const toDay = new Date();
  const tommorow = add(toDay, { days: 1})
  let endYear = toDay.getFullYear() + 4;
  let endOfNextFourYears = new Date(endYear, 11, 31);
  const lastYear = sub(toDay, {years :1})
  const dispatch = useDispatch();
  const locale = localStorage.locale;

  //state from redux
  const { forecast, ciSettings } = useSelector((state) => state);
  const { progFrom, progTo } = useSelector((state) => state.forecast.data);

  const { constants } = useSelector((state) => state);
  const { forecastType } = forecast.data;

  const [disabled, setDisabled] = useState(true);
  //redirect user to fleximport?
  const [redirect, setRedirect] = useState(false);
  const [minDate, setMinDate] = useState<Date>(new Date());
  const [maxDate, setMaxDate] = useState<Date>(
    new Date("January 01, 2400 00:00:00")
  );
  const [onlyGas, setOnlyGas] = useState(false);
  const [onlyPower, setOnlyPower] = useState(false);
  const role = AuthService.getRole();
  const [fromPageCreate, setFromPageCreate] = useState("");


  /* Probably has to be set to null or undefined possibly */

//   const selectedCustomer = useSelector(
//     (state) => state.customer["customerData"]["id"]
//   );
//   const customerType = useSelector(
//     (state) => state.customer["customerData"]["type"]
//   );
  const tranche = useSelector((state) => state.forecast.data?.tranche);
  const divideAmount = useSelector(
    (state) => state.forecast.data.divide_amount
  );
  const gesamtFormel = useSelector((props) => props.modal.props?.gesamtFormel);
  const formula = useSelector((props) => props.modal.props?.formula);
  const props = useSelector((props) => props.modal.props);

  const preisType = useSelector((state) => state.forecast.data.pricing_type);
//   const selectedCustomerP = useSelector(
//     (props) => props.customer["customerData"]["permissions"]
//   );
//   const selectedCustomerPerm = Object.values(selectedCustomerP);
  const [btnSubmitText, setBtnSubmitText] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [DFC, setDFC] = useState(false);
  const [TrancheDisable, setTrancheDisable] = useState(false);
  const [preiskurveListe, setPreiskurveListe] = useState(undefined);

  //closes modal
  const closeModal = useCallback(() => {
    dispatch(forecastActions.reset());
    dispatch(modalActions.closeModal());
  }, [dispatch]);

  // function differenceCal(x, y) {
  //   let ondDay = 1000 * 60 * 60 * 24;
  //   let millisecondes = x - y;
  //   // console.log(Math.round(millisecondes / ondDay) + 1);
  //   return Math.round(millisecondes / ondDay) + 1;
  // }

  // let diffDays = differenceCal(progTo.getTime(), progFrom.getTime());

  const sendErrorFromChild = (Err) => {
    if (Err.length === 0) {
      setDFC(false);
    } else if (Err.length > 0) setDFC(true);
  };

  function handleChangeTranche(e) {
    if(e.target.value.length > 3) return false;
    if(e.target.value < 2){
      dispatch(forecastActions.saveForecastSingle("divide_amount", true));
    }
    // customerType === "E" && e.target.value < 1
    //   ? setTrancheDisable(true)
    //   : setTrancheDisable(false);
    // dispatch(
    //   forecastActions.saveForecastSingle("tranche", Number(e.target.value))
    // );
  }

  function handleChangeSelect(e) {
    dispatch(forecastActions.saveForecastSingle("priceType", e.target.value));
  }

  const handlerCheckbox = (e) => {
    if (e.target.checked) {
      dispatch(forecastActions.saveForecastSingle("divide_amount", false));
    } else dispatch(forecastActions.saveForecastSingle("divide_amount", true));
  };
  useEffect(() => {
    dispatch(authActions.checkLogin());
  }, []);

  useEffect(() => {
    // if (role.simple_role !== "LIEFERANT") {
      if (
        role.permissions.includes("STROM") &&
        !role.permissions.includes("GAS")
      ) {
        setOnlyPower(true);
      } else if (
        role.permissions.includes("GAS") &&
        !role.permissions.includes("STROM")
      ) {
        setOnlyGas(true);
      }
    // }
    // if (role.simple_role === "LIEFERANT") {
    //   if (
    //     selectedCustomerPerm.includes("STROM") &&
    //     !selectedCustomerPerm.includes("GAS")
    //   ) {
    //     setOnlyPower(true);
    //   } else if (
    //     selectedCustomerPerm.includes("GAS") &&
    //     !selectedCustomerPerm.includes("STROM")
    //   ) {
    //     setOnlyGas(true);
    //   }
    // }
  }, []);

  useEffect(() => {
    setFromPageCreate("FromCreate");
  }, []);

  // useEffect(() => {
  //   // if (tranche === undefined) {
  //   //   if (customerType === "E") {
  //       dispatch(forecastActions.saveForecastSingle("tranche", 1));
  //   //   }
  //   //   if (customerType === "F") {
  //       // dispatch(forecastActions.saveForecastSingle("tranche", 0));
  //   //   }
  //   // }
  // }, []);

  /**
   * Handles submit. Either redirects the user or just dispatches an event that the forecast should get send to the
   * server
   */
  function handleSubmit(): void {
    //input check
    if (
      !forecastService.checkLoadcurveUploadButtonDisabled(
        forecast.data,
        forecastType,
        constants
      ).disabled
    ) {
      if (forecastType === 1 || forecastType === 2 || forecastType === 8) {
        //just redirect, forecast is saved to the state
        setRedirect(true);
      } else {
        //directly dispatch
        dispatch(
          forecastActions.createDirect()
        );
      }
    }
    setBtnSubmitText(lang[locale].processing);
    props.setPEfilterChange(1);
    setBtnDisable(true);
  }
  function setMinMax(min: string, max: string) {
    setMinDate(new Date(min));
    setMaxDate(new Date(max));
  }
  useEffect(() => {
    //check if the button should be disabled
    setDisabled(
      forecastService.checkLoadcurveUploadButtonDisabled(
        forecast.data,
        forecastType,
        constants
      ).disabled ||
        // (role.simple_role === "LIEFERANT" && !selectedCustomer) || ||
        (preisType === "FORMEL" && gesamtFormel === undefined) ||
        // Check the min and max date of price curve
        ((forecast.data.pricing_type?.toLowerCase().indexOf("spot") === -1) && forecast.data.progFrom < minDate) ||
        ((forecast.data.pricing_type?.toLowerCase().indexOf("spot") === -1) && forecast.data.progTo > maxDate) ||
        ((forecast.data.pricing_type?.toLowerCase().indexOf("spot") !== -1) && pathName !== "pricedevelopment" && differenceInDays(forecast.data.progFrom,tommorow) < 0 ) ||
        ((forecast.data.pricing_type?.toLowerCase().indexOf("spot") !== -1) && pathName === "pricedevelopment" && differenceInDays(forecast.data.progFrom,lastYear) < 0 ) ||
        ((forecast.data.pricing_type?.toLowerCase().indexOf("spot") !== -1) && differenceInDays(forecast.data.progTo, endOfNextFourYears) > 0) ||
        (DFC && forecastType !== 1) ||
        (preisType === "FORMEL" && (formula === undefined || Object.keys(formula).length === 0))
    );
  }, [forecastType, forecast.data, DFC, formula]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //if the request is complete and no error is given, open a snackbar
    if (forecast.finishedRequest) {
      dispatch(
        snackbarActions.openSnackbar(
          lang[locale].forecastSuccessfullyCreated,
          "success"
        )
      );
      dispatch(forecastActions.reset());
      closeModal();
    }
  }, [closeModal, dispatch, forecast.finishedRequest, locale]);

  return (
    <Grid container spacing={2}>
      {redirect && (
        <Redirect
          to={{
            pathname: "/app/fleximportPE",
            state: { customer: 1, isPriceDevelopment: true },
          }}
        />
      )}
      <Grid
        item
        xl={6}
        lg={6}
        md={5}
        xs={5}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          style={{
            marginRight: "2rem",
            color: onlyGas ? "#FAB515" : onlyPower ? "#0983B1" : "white",
          }}
        >
          {lang[locale].foreCast}
        </Typography>
        <TogglePowerGasComponent />
      </Grid>
      {/* <Grid item xl={4} lg={12} md={4} xs={4}>

            </Grid> */}
      {/* {forecast.error && (
        <Grid item xs={12}>
          <Typography color="error" data-testid="submiterror">
            {forecast.error.message}
          </Typography>
        </Grid>
      )} */}

      <Grid item xs={12}>
        <Grid container alignItems="center" justify="space-between" spacing={2}>
          <SelectForecastNameComponent />
          <Grid item xs={6} style={{ marginBottom: "2em" }}>
            <SelectPriceCurveComponent
              gas={forecast.data.gas}
              setMinMax={setMinMax}
              // gesamtFormel={gesamtFormel}
              fromPage={fromPageCreate}
              preisType={preisType}
              isInCreatePE={props.isPriceDevelopment}
              setPreiskurveListe={setPreiskurveListe}
            />
          </Grid>
          <SelectDateComponent minDate={minDate} maxDate={maxDate} />
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <SelectForecastType />
      </Grid>
      {/* <Grid item xs={3}>
        {preisType !== "SPOT" ? (
          <TextField
            inputProps={{ "data-testid": "tranche", min: 0, maxLength: 4, pattern: "([^0-9]*)" }}
            value={forecast.data.tranche ? forecast.data.tranche : ""}
            fullWidth
            onChange={handleChangeTranche}
            label={lang[locale].noOfTranches}
            required
            // error={
            //   tranche === undefined || (customerType === "E" && tranche < 1)
            // }
          />
        ) : null}
      </Grid>
      {preisType !== "SPOT" && forecast.data.tranche && forecast.data.tranche > 1 ? (
        <Grid
          style={{ padding: "0.5rem 0 0 2rem", lineHeight: "0.3em" }}
          item
          xs={3}
        >
          <FormGroup>
            <InputLabel style={{ fontSize: "0.85em" }}>
              Tranchenmenge
            </InputLabel>
            <FormControlLabel
              control={<Checkbox onChange={handlerCheckbox} />}
              label="100%"
            />
          </FormGroup>
        </Grid>
      ) : null} */}
      <Grid item xs={6}>
        {(forecastType === 3 ||
          forecastType === 4 ||
          forecastType === 5 ||
          forecastType === 9) && <SelectProfileTypeComponent />}
      </Grid>
      {forecastType === 6 && (
        <SelectStandardTradingProduct
          divideAmount={divideAmount}
          sendErrorFromChild={sendErrorFromChild}
        />
      )}
      {(forecastType === 4 || forecastType === 8) && (
        <SelectTemperatureStation />
      )}
      {forecastType === 4 && <SelectUsageType />}
      {(forecastType === 3 || forecastType === 4) && (
        <SelectUsage
          divideAmount={divideAmount}
          sendErrorFromChild={sendErrorFromChild}
          tranche={tranche}
        />
      )}
      {forecastType === 4 && <SelectVariantComponent />}
      {forecast.data.usageType === 1 && forecastType === 4 && (
        <SelectMeasuredDatesComponent />
      )}
      {(forecastType === 3 || forecastType === 1 || forecastType === 2) && (
        <>
          <Grid item xs={12} lg={6}>
            <SelectHolidayComponent />
          </Grid>
          <Grid item xs={12} lg={6}></Grid>
        </>
      )}
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <Grid container justify="flex-start">
              <Grid item>
                <Button
                  // color="primary"
                  style={{color : ciSettings.primary}}
                  onClick={() => {
                    closeModal();
                  }}
                >
                  {lang[locale].close}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2} justify="flex-end" alignItems="center">
              <Grid item>
                <Button
                  // color="primary"
                  style={{backgroundColor : (disabled || btnDisable) ? "#595959" : ciSettings.primary, color : (disabled || btnDisable) ? "#898989" : "white"}}
                  variant="contained"
                  data-testid="submitButton"
                  onClick={handleSubmit}
                  disabled={disabled || btnDisable}
                >
                  <>
                    {forecastType === 1 ||
                    forecastType === 2 ||
                    forecastType === 8
                      ? lang[locale].next
                      : btnSubmitText.length > 0
                      ? btnSubmitText
                      : lang[locale].createPriceHistory}
                  </>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CreateForecastComponentPE;
