import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Button,
  Typography,
  Tooltip as MTooltip,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import UploadIcon from '@mui/icons-material/Upload';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import { lang } from "../lang";
import PriceHistoryGraph from "./PriceHistoryGraph";
import { priceConstants } from "../constants/price.constants";
import { makeStyles } from "@material-ui/core/styles";
//@ts-ignore
import indics from "../static/indics.pdf";

//React Router Dom
import { useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

//TypeDefs
import OrderModel from "../typeDefs/OrderModel";
import LoadcurveModel from "../typeDefs/LoadcurveModel";
import LoadcurvePriceHistory from "../typeDefs/LoadcurvePriceHistory";
import LoadCurveIndicator from "../typeDefs/LoadCurveIndicator";
import LoadCurveFullHistory from "../typeDefs/LoadCurveFullHistory";

//Hooks
import { useDispatch } from "react-redux";
import { useSelector } from "../services/helpers/useSelector";
import { useHistory } from "react-router-dom";

//Actions
import authActions from "../actions/auth.actions";
import snackbarActions from "../actions/snackbar.actions";

//Services
import AuthService from "../services/auth/AuthService";
import UserService from "../services/users/UserService";
import LoadcurveService from "../services/loadcurves/LoadcurveService";
import { useLocation } from "react-router";

//components
import ShowIstDataComponent from "./ShowIstData";
import SetPriceBoundsComponent from "./SetPriceBounds";
import ForecastInformationCardComponent from "./ForecastInformationCard";
import LineChartComponent from "./LineChart";
import ShowPriceAndBuyComponent from "./ShowPriceAndBuy";
// import Charts from "./Charts";
// import { Legend, LineChart, Tooltip, XAxis, YAxis } from "recharts";

const service = new LoadcurveService();
const userService = new UserService();

function Circular() {
  return (
    <Grid container justify="center" alignItems="center">
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
}

function DetailedViewComponent({ changeNavTitle }) {
  const location = useLocation();
  const pathNameIndexFour = location.pathname?.split("/")[4];
  const pathNameIndexFive = location.pathname?.split("/")[5];
  let history = useHistory();
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  //get the loadcurve id from params
  const { customer_id, id }: any = useParams();
  const [indictypeA, setIndictypeA] = useState("EMA");
  const [indictypeB, setIndictypeB] = useState("SMA");
  const [indictypeC, setIndictypeC] = useState("WILLIAMS");
  const [indictypeD, setIndictypeD] = useState("MACD");
  const [indictypeG, setIndictypeG] = useState("EMA9");
  const [indictypeE, setIndictypeE] = useState("MOMENTUM");
  const [indictypeF, setIndictypeF] = useState("BOLLINGER-BAND");
  const [dataToExport, setDataToExport] = useState(undefined);
  const [upperLimit, setUpperLimit] = useState(0);
  const [lowerLimit, setLowerLimit] = useState(0);

  const [days, setDays] = useState(365);
  const [istData, setIstData] = useState(true);
  const [priceHistory, setPriceHistory] = React.useState<
    undefined | LoadcurvePriceHistory[]
  >();
  const [indicator, setIndicator] = useState<
    LoadCurveIndicator[] | undefined
  >();
  const [fullHistory, setFullHistory] = useState<
    LoadCurveFullHistory[] | undefined
  >();
  // const [typeChart, setTypeChart] = useState("");
  const [loadcurve, setLoadcurve] = useState<undefined | LoadcurveModel>();
  const [loadcurveData, setLoadcurveData] = useState<undefined | object[]>();
  const [orders, setOrders] = React.useState<undefined | OrderModel[]>();
  const priceState = useSelector((state) => state.price);
  const ciSettings = useSelector(
    (state) => state.ciSettings
  );
  const locale = localStorage.locale;
  const role = AuthService.getRole();
  const dispatch = useDispatch();
  const [type, setType] = useState<string>("");
  const useStyles = makeStyles({
    prognosisCurve: {
      textAlign: "center",
    },
  });
  const rotatedTextStyle = {
    transform: 'rotate(-90deg)',
    width : 100,
    marginRight : "-4rem"
  };
  const IstDataFromChild = (istData) => {
    if (istData) {
      setIstData(false);
    } else setIstData(true);
  };

  const classes = useStyles();

  const disabled =
    orders && orders.length > 0 && orders?.length === loadcurve?.tranche;

  useEffect(() => {
    dispatch(authActions.checkLogin());
  }, []);

  /*checks if the buying process if 4AP is active*/
  useEffect(() => {
    role.simple_role === "USER" &&
      userService
        .getCustomer()
        .then((res) => {
          setType(res.type);
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
  }, []);


  /**
   * Gets loadcurve information from the
   * api like history and "dbkopf" information
   * @param id
   */
  async function getLoadcurve(id: number) {
    //first get general loadcurve data
    try {
      const data = await service.getSingleLoadcurve(id);
      setLoadcurve(data);
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
        /* return; */
      }
    }
    //get loadcurve data
    try {
      const data = await service.getLoadcurveFullData(id);
      setLoadcurveData(data);
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      }
    }
    //get the price history
    try {
      const hist = await service.getLoadcurvePriceHistory(id);
      let newHist: Array<any> = [];
      hist.forEach((h) => {
        newHist.push({
          preis: Math.round(h.preis * 100) / 100,
          // dat: new Date(h.dat).getTime(),
          dat: new Date(h.dat).getTime(),
          // kennung: h.kennung,
          erstellungsdatum: h.erstellungsdatum,
          kombinierterPreis: [
            Math.round(h.preis * 100) / 100,
            ((h.preis + h.aufschlag) * 100) / 100,
          ],
        });
      });
      // console.log("hist",hist, typeof(hist));
      // console.log("newhist",newHist, typeof(newHist));
      setPriceHistory(newHist);
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        // dispatch(snackbarActions.openSnackbar(e.message, "error"));
      }
    }
  }

  function getOrders() {
    if (loggedIn) {
      service
        .getOrdersByZrid(id)
        .then((data) => {
          setOrders(data);
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
  }

  // get intraday
  function getintraday(id: number) {
    service
      .getIntraday(id)
      .then((data) => {
        // setOrders(data);
        console.log("intraday in datail view", data);
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  }

  const infoClickHandler = () => {
    // const pdfPath = process.env.PUBLIC_URL + "/static/indics.pdf";
    const newWindow = window.open("", "_blank");
    // <iframe src="${config.url.API_URL}/api/weekly_report" width="100%" height="100%"></iframe>
      //@ts-ignore
      newWindow.document.write(`
      <html>
      <head>
      <title>Info | EMDACS</title>
          </head>
          <body style="margin: 0;">
          <iframe src="${indics}" width="100%" height="100%" style="border: none;"></iframe>
          </body>
          </html>
          `);
  }

  async function getIndicators(
    id: number,
    indictypeA: string,
    indictypeB: string,
    indictypeC: string,
    indictypeD: string,
    indictypeE: string,
    indictypeF: string,
    indictypeG: string,
    days: number
  ) {
    try {
      const indic = await service.getLoadcurveIndicators(
        id,
        indictypeA,
        indictypeB,
        indictypeC,
        indictypeD,
        indictypeE,
        indictypeF,
        indictypeG,
        days
      );
      // let newindic: Array<any> = [];
      // indic.map((IndicData,i) =>{
      //     if (IndicData.Type === "EMA" && IndicData.Capacity === 20){
      //         newindic.push([...indic,{ema20 : IndicData.Data[i].Value}])
      //     }
      //     if (IndicData.Type === "EMA" && IndicData.Capacity === 90){
      //         newindic.push(...newindic,{ ema90 : IndicData.Data.Value})
      //     }
      // if (IndicData.Type === "EMA" && IndicData.Capacity === 200){
      //     newindic.push({time : IndicData.Data.Time, ema200 : IndicData.Data.Value})
      // }
      // if (IndicData.Type === "SMA" && IndicData.Capacity === 20){
      //     newindic.push({time : IndicData.Data.Time, sma20 : IndicData.Data.Value})
      // }
      // if (IndicData.Type === "SMA" && IndicData.Capacity === 90){
      //     newindic.push({time: IndicData.Data.Time, sma90 : IndicData.Data.Value})
      // }
      // if (IndicData.Type === "SMA" && IndicData.Capacity === 200){
      //     newindic.push({time : IndicData.Data.Time, sma200 : IndicData.Data.Value})
      // }

      //     newindic.push({
      //         ema20 : (IndicData.Type === "EMA" && IndicData.Capacity === 20) && IndicData.Data,
      //         ema90 : (IndicData.Type === "EMA" && IndicData.Capacity === 90) && IndicData.Data,
      //         ema200 : (IndicData.Type === "EMA" && IndicData.Capacity === 200) && IndicData.Data,
      //         sma20 : (IndicData.Type === "SMA" && IndicData.Capacity === 20) && IndicData.Data,
      //         sma90 : (IndicData.Type === "SMA" && IndicData.Capacity === 90) && IndicData.Data,
      //         sma200 : (IndicData.Type === "SMA" && IndicData.Capacity === 200) && IndicData.Data,
      // })
      // console.log("newindic", newindic);
      // });
      //@ts-ignore
      setIndicator(indic);
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      }
    }
  }

  async function getFullPreisHistory(
    id: number,
    indictypeA: string,
    indictypeB: string,
    days: number
  ) {
    try {
      const fullHist = await service.getFullPreisHistory(
        id,
        indictypeA,
        indictypeB,
        days
      );
      let newFullHist: Array<any> = [];
      fullHist.forEach((h) => {
        newFullHist.push({
          preis: Math.round(h.preis * 100) / 100,
          dat: h.dat,
          // kennung: h.kennung,
          erstellungsdatum: h.erstellungsdatum,
          VaR: h.var,
          kombinierterPreis: [
            Math.round(h.preis * 100) / 100,
            ((h.preis + h.aufschlag) * 100) / 100,
          ],
        });
      });

      //@ts-ignore
      setFullHistory(newFullHist);
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      }
    }
  }

  useEffect(() => {
    if (loggedIn) {
      getIndicators(
        id,
        indictypeA,
        indictypeB,
        indictypeC,
        indictypeD,
        indictypeE,
        indictypeF,
        indictypeG,
        days
      );
      getFullPreisHistory(id, indictypeA, indictypeB, days);
      // getintraday(id);
    }
  }, []);

  useEffect(() => {
    if (loggedIn) {
      document.title = lang[locale].details + " | EMDACS";
      getLoadcurve(id);
      getOrders();
    }
  }, []);
  //just use this here to update the nav title
  React.useEffect(() => {
    changeNavTitle(
      loadcurve && loadcurve.zr_name.length > 14
        ? loadcurve?.zr_name.split("_")[0].slice(0, 14) + "... / " + id
        : loadcurve?.zr_name.split("_")[0] + " / " + id
    );
  }, [loadcurve]);


  const removeKeys = (data, keysToRemove) => {
    return data.map(item => {
      const newItem = { ...item };
      keysToRemove.forEach(key => delete newItem[key]);
      return newItem;
    });
  };

    const convertToCSV = (dataToExport) => {
      // const csvRows = [];
      // //@ts-ignore
      // csvRows.push('date;price');
      // fullHistory.forEach(item => {
      //   //@ts-ignore
      //   csvRows.push(`${item.dat.split("T")[0]};${parseFloat(item.preis).toLocaleString()}`);
      // });
      // return csvRows.join('\n');
    const filteredData = removeKeys(dataToExport, ['erstellungsdatum', 'wiliminus20', 'wiliminus80', 'kombinierterPreis' ,'time', 'dat', 'preis']);
    const keys = ["Datum", ...Object.keys(filteredData[0]).filter(key => key !== 'Datum')];
    const csv = [
      keys.join(';'), // header row
      ...filteredData.map(row => keys.map(key => {   
         
         return row[key].toLocaleString()  
      }).join(';')) // data rows
    ].join('\n');
    return csv;
    };
  
    const downloadCSV = (data) => {
      const csvData = convertToCSV(data);
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
  
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', `${loadcurve?.zr_name}_${loadcurve?.zrid}`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

  return (
    <Grid container style={{ padding: "0 5em", width: "100%" }} spacing={10}>
      <Grid item xs={12}>
        {pathNameIndexFour === pathNameIndexFive ? (
          <Button
            // color="primary"
            style={{color : ciSettings.primary}}
            component={HashLink}
            to={`/app/pricedevelopment`}
            onClick={() => {
              dispatch({ type: priceConstants.PRICE_RESET });
            }}
          >
            {lang[locale].back}
          </Button>
        ) : (
          <Button
            // color="primary"
            style={{color : ciSettings.primary}}
            component={HashLink}
            to={`/app/myplans/${customer_id}#${id}`}
            onClick={() => {
              dispatch({ type: priceConstants.PRICE_RESET });
            }}
          >
            {lang[locale].back}
          </Button>
        )}
      </Grid>

      {/* {indicator && 
            <Grid
            xs={12}
            style={{ margin: "auto", padding: "0px 40px 40px" }}>
                <EmaHistoryGraph
                data={indicator}
                width={"100%"}
                height={500}
                />
            </Grid>
            } */}

      {loadcurve &&
        loadcurve.datenart !== 2 &&
        priceHistory &&
        priceHistory.length > 0 && (
          <Grid
            item
            xs={12}
            className={classes.prognosisCurve}
            style={{ margin: "auto", padding: "0" }}
          >
            <Typography variant="h6" gutterBottom>
              {lang[locale].priceHistory}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <MTooltip title={lang[locale].csvExport}>
                <IconButton
                onClick={()=>downloadCSV(removeKeys(dataToExport, ['erstellungsdatum', 'wiliminus20', 'wiliminus80', 'kombinierterPreis' ,'time', 'dat', 'preis']))}
                style={{
                color: "white",
              }}>
                  <UploadIcon fontSize="small" />
                </IconButton>
              </MTooltip>
              <MTooltip title={lang[locale].infoOscillatorsIndicators}>
                <IconButton
                onClick={infoClickHandler}
                style={{
                color: "orange",
              }}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </MTooltip>
            </div>
            <PriceHistoryGraph
              referenceBottom={priceState.lower}
              referenceTop={priceState.upper}
              referenceActive={priceState.active}
              height={350}
              width={"100%"}
              orders={orders}
              indicatores={indicator}
              fullHistories={fullHistory}
              upperLimit={upperLimit}
              lowerLimit={lowerLimit}
              setDataToExport={setDataToExport}
              dataToExport={dataToExport}
            />
          </Grid>
        )}

      <div style={{ margin: "auto" }}>
        {loadcurve &&
          // role.simple_role !== "LIEFERANT" &&
          // activate price watching for all users
          loadcurveData &&
          loadcurveData.length > 0 &&
          role.permissions.includes("MONITORING") && (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <SetPriceBoundsComponent zrid={id} setUpperLimit={setUpperLimit} setLowerLimit={setLowerLimit}/>
            </Grid>
          )}
      </div>

      {loadcurve && priceHistory && priceHistory.length > 0 && (
        <Grid item xs={12} style={{ margin: "auto" }}>
          <ShowPriceAndBuyComponent
            disabled={disabled}
            amount={loadcurve.menge}
            recentPrice={
              priceHistory[priceHistory.length - 1].kombinierterPreis[1]
                ? priceHistory[priceHistory.length - 1].kombinierterPreis[1]
                : priceHistory[priceHistory.length - 1].preis
            }
            zrid={loadcurve.zrid}
            name={loadcurve.zr_name.split("_")[0]}
          />
        </Grid>
      )}

      {loadcurveData && loadcurveData.length > 0 ? (
        <div style={{ margin: "auto", height: "auto", width: "100%" }}>
          <Grid
            item
            xs={12}
            className={classes.prognosisCurve}
            style={{ margin: "auto", height: "auto", width: "100%" }}
          >
            <MTooltip title={lang[locale].zoominout}>
              <InfoIcon fontSize="small" />
            </MTooltip>
            <Typography variant="h6" gutterBottom>
              {lang[locale].prognosisCurve}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{display : "flex", flexDirection : "row", justifyContent : "center", alignItems : "center"}}>
          <div style={rotatedTextStyle}>{lang[locale].performance}&nbsp;[kW]</div>
            <LineChartComponent
              color={
                loadcurve && loadcurve.commodity_id === 1
                  ? "primary"
                  : "secondary"
              }
              data={loadcurveData}
              title=""
            />
          </Grid>
        </div>
      ) : (
        <Circular />
      )}

      <div style={{ paddingTop: "6vh", margin: "auto" }}>
        {loadcurveData && loadcurveData.length > 0 && (
          <>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* {istData && (
                            <>
                                <Typography><h3>{loadcurve?.zr_name}</h3></Typography><br />
                            </>
                            )} */}
              <ForecastInformationCardComponent
                zrid={id}
                loadcurve={loadcurve}
              />
            </Grid>
          </>
        )}
      </div>
      {loadcurveData && loadcurveData.length > 0 && (
        <Grid item xs={12} style={{ margin: "auto" }}>
          <ShowIstDataComponent
            zrid={id}
            color={
              loadcurve && loadcurve.commodity_id === 1
                ? "primary"
                : "secondary"
            }
            IstDataFromChild={IstDataFromChild}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default DetailedViewComponent;
