import React from "react";
import { Button, Grid } from "@material-ui/core";
import ShowOrdersComponent from "./ShowOrders";
import { useDispatch } from "react-redux";
import modalActions from "../actions/modal.actions";
import { lang } from "../lang";

/**
 * When clicking the "bought" chip, this modal appears and shows the history of the
 * single loadcurve
 * @constructor
 */
function SingleBuyHistoryModalComponent(props) {
    const hist = props.orders;

    const dispatch = useDispatch();
    const locale = localStorage.locale;
    function handleClose() {
        // console.log("In Close Modal SingleBuyHistory Component");

        dispatch(modalActions.closeModal());
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <ShowOrdersComponent orders={hist} />
            </Grid>
            <Grid item xs={12}>
                <Button color="primary" onClick={handleClose}>
                    {lang[locale].close}
                </Button>
            </Grid>
        </Grid>
    );
}

export default SingleBuyHistoryModalComponent;
