import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import forecastActions from "../actions/forecast.actions";
import { KeyboardDatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import { addDays, startOfYear, endOfYear, subDays, parseISO } from "date-fns";
import { lang } from "../lang";
//Material UI
import {
  Button,
  Grid,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useSelector } from "../services/helpers/useSelector";

const MTBComponent = (props) => {
  const dispatch = useDispatch();
  const locale = localStorage.locale;
  const [unmittlebarDanach, setUnmittlebarDanach] = useState(true);
  const [amBestimmtehandelsTag, setamBestimmtehandelsTag] = useState<any>(false);
  const [überSpot, setÜberSpot] = useState(false);
  const [bestimmteDatum, setBestimmteDatum] = useState<any>(undefined);
  const forecastData = useSelector((state) => state.forecast.data);

  useEffect(() => {
    const preiskurveSpot = props.preiskurveListe.find((i) => i.kind === "SPOT");
    const preiskurveCurrent = props.preiskurveListe.find(
      (i) => i.description === forecastData.priceCurve
    );
    if (unmittlebarDanach === true && preiskurveCurrent !== undefined) {
      dispatch(
        forecastActions.saveForecastSingle(
          "fallback_pricing_curve_id",
          preiskurveCurrent.pk_id
        )
      );
      dispatch(forecastActions.saveForecastSingle("fallback_pricing_date", ""));
    }
    if (amBestimmtehandelsTag === true && preiskurveCurrent !== undefined) {
      dispatch(
        forecastActions.saveForecastSingle(
          "fallback_pricing_curve_id",
          preiskurveCurrent.pk_id
        )
      );
      setBestimmteDatum(new Date().toISOString());
      dispatch(forecastActions.saveForecastSingle("fallback_pricing_date", bestimmteDatum));
    }
    if (überSpot === true && preiskurveCurrent !== undefined) {
      dispatch(
        forecastActions.saveForecastSingle(
          "fallback_pricing_curve_id",
          preiskurveSpot.pk_id
        )
      );
      dispatch(forecastActions.saveForecastSingle("fallback_pricing_date", ""));
    }
  }, [unmittlebarDanach, amBestimmtehandelsTag, überSpot]);
  
  useEffect(() => {
    const preiskurveCurrent = props.preiskurveListe.find(
      (i) => i.description === forecastData.priceCurve
    );
    if (unmittlebarDanach === true && preiskurveCurrent !== undefined) {
      dispatch(
        forecastActions.saveForecastSingle(
          "fallback_pricing_curve_id",
          preiskurveCurrent.pk_id
        )
      );
      dispatch(forecastActions.saveForecastSingle("fallback_pricing_date", ""));
    }
  }, []);

  useEffect(() => {
    dispatch(
      forecastActions.saveForecastSingle(
        "fallback_pricing_date",
        bestimmteDatum
      )
    );
  }, [bestimmteDatum]);

  return (
    <div>
      <p style={{fontSize : "1rem"}}>
        {lang[locale].endeOptions}
      </p>
      <div>
        <FormControlLabel
          style={{ margin: "1rem 0 0 1rem" }}
          control={
            <Checkbox
              value="unmittlebarDanach"
              checked={unmittlebarDanach}
              onChange={() => {
                setÜberSpot(false);
                setamBestimmtehandelsTag(false);
                setUnmittlebarDanach((unmittlebarDanach) => !unmittlebarDanach);
              }}
              //   disabled={handleDays.length === 0}
            />
          }
          label={lang[locale].immediatelyAfterwards}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <FormControlLabel
          style={{ margin: "1rem 0 0 1rem" }}
          control={
            <Checkbox
              value="amBestimmtehandelsTag"
              checked={amBestimmtehandelsTag}
              onChange={() => {
                setamBestimmtehandelsTag(
                  (amBestimmtehandelsTag) => !amBestimmtehandelsTag
                );
                setUnmittlebarDanach(false);
                setÜberSpot(false);
              }}
              //   disabled={handleDays.length === 0}
            />
          }
          label={lang[locale].onSpecificTradingDay}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === "de" ? deLocale : enLocale}>
        <KeyboardDatePicker
          // inputValue={bewirtDatumEnd !== undefined ? bewirtDatumEnd.slice(0, 10) : " "}
          // inputValue={bewirtDatumEnd !== undefined ? bewirtDatumEnd.slice(0, 10).split("-").reverse().join() : " "}
          style={{ width: "20%", margin: "1rem 0 0 2rem" }}
          helperText=""
          required
          variant="inline"
          format="dd.MM.yyyy"
          fullWidth
          autoOk={true}
          minDate={
            //@ts-ignore
             addDays(forecastData.accept_from, 1)
          }
          // minDate={}
          // maxDate={subDays(forecast.data.progFrom, 1)}
          onChange={(e) => setBestimmteDatum(e?.toISOString())}
          value={bestimmteDatum}
          //   error={}
          //   minDateMessage={
          //     "Das Datum darf nicht vor dem " +
          //     format(tommorow, "dd.MM.yyyy") +
          //     " liegen."
          //   }
          id="startdate"
          data-testid="startdate"
          // label={lang[locale].enddate}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          disabled={!amBestimmtehandelsTag}
          // TextFieldComponent={TextFieldComponent}
          // error={}
        />
        </MuiPickersUtilsProvider>
      </div>
      <div>
        <FormControlLabel
          style={{ margin: "1rem 0 0 1rem" }}
          control={
            <Checkbox
              value="überSpot"
              checked={überSpot}
              onChange={() => {
                setUnmittlebarDanach(false);
                setamBestimmtehandelsTag(false);
                setÜberSpot((überSpot) => !überSpot);
              }}
              //   disabled={handleDays.length === 0}
            />
          }
          label={lang[locale].aboutSpot}
        />
      </div>
    </div>
  );
};

export default MTBComponent;
