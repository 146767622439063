import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import snackbarActions from "../actions/snackbar.actions";
import { DateRange } from "react-date-range";
import { addDays, startOfYear, endOfYear, subDays, parseISO } from "date-fns";
import { KeyboardDatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import { useDispatch } from "react-redux";
import { lang } from "lang";
import { useSelector } from "../services/helpers/useSelector";
import modalActions from "../actions/modal.actions";
import forecastActions from "../actions/forecast.actions";
import DateRangeABKPage from "./DateRangeABKPage";
import LoadcurveService from "../services/loadcurves/LoadcurveService";

//Material UI
import {
  Button,
  Grid,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import "react-date-range/dist/styles.css"; // Import the default styles
import "react-date-range/dist/theme/default.css"; // Import the default theme

const ABKFieldsComponent = ({
  tranche,
  ATB,
  MTB,
  Angebotsende,
  sendErrorFromChild,
  handleSubmitTrigger,
  handleDays,
}) => {
  const service = new LoadcurveService();
  const locale = localStorage.locale;
  const { forecast } = useSelector((state) => state);
  let history = useHistory();
  const [bewirtDatumStart, setBewirtDatumStart] = useState<any | undefined>(
    undefined
  );
  const [bewirtDatumEnd, setBewirtDatumEnd] = useState<any | undefined>(
    undefined
  );
  const [bewirtDatumStartObj, setBewirtDatumStartObj] = useState(undefined);
  const [bewirtDatumEndObj, setBewirtDatumEndObj] = useState(undefined);
  const [holidays, setHolidays] = useState<any>([]);
  const dispatch = useDispatch();

  //@ts-ignore
  const minDate = forecast.data.accept_from && forecast.data.accept_to && !ATB ? forecast.data.accept_from : addDays(forecast.data.accept_to, 1)

  function handleChangeMehrfachTranche() {}

  useEffect(() => {
    service
      .getHolidayABK()
      .then((res) => {
        setHolidays(res.map((i) => new Date(i).toString()));
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  }, []);

  // console.log("bewend, bewstarts, new date", bewirtDatumEnd && bewirtDatumStart && bewirtDatumEnd, bewirtDatumStart,new Date(), new Date(bewirtDatumStart) === new Date())

  function ABKhandler() {
    dispatch(
      //@ts-ignore
      modalActions.openModal(
        DateRangeABKPage,
        {
          bewirtDatumStart: bewirtDatumStartObj,
          bewirtDatumEnd: bewirtDatumEndObj,
          holidays: holidays,
          handleDays: handleDays,
          handleSubmitTrigger: handleSubmitTrigger,
        },
        8
      )
    );
  }

  function EndDatumHandler(e) {
    if((e != "Invalid Date") && (e >= minDate)){
      setBewirtDatumEnd(e?.toISOString());
      setBewirtDatumEndObj(e);
      dispatch(forecastActions.saveForecastSingle("order_to", e));
    }
  }
  function StartDatumHandler(e) {
    if((e != "Invalid Date") && (e >= minDate)){
    setBewirtDatumStart(e?.toISOString());
    setBewirtDatumStartObj(e);
    dispatch(forecastActions.saveForecastSingle("order_from", e));
    }
  }

  useEffect(() => {
    if (
      (forecast.data.accept_from !== undefined &&
        bewirtDatumEnd !== undefined &&
        bewirtDatumStart !== undefined &&
        (bewirtDatumEnd < bewirtDatumStart ||
          bewirtDatumStart < forecast.data.accept_from)) ||
      (forecast.data.accept_from !== undefined &&
        bewirtDatumEnd !== undefined &&
        bewirtDatumStart !== undefined &&
        (bewirtDatumEnd < bewirtDatumStart ||
          bewirtDatumEnd < forecast.data.accept_from))
    ) {
      sendErrorFromChild("error");
    } else {
      sendErrorFromChild(0);
    }
  }, [bewirtDatumEnd, bewirtDatumStart]);

  // useEffect(()=>{
  //   setBewirtDatumEnd(bewirtDatumEnd)
  // },[bewirtDatumEnd])

  // useEffect(()=>{
  //   setBewirtDatumStart(bewirtDatumStart)
  // },[bewirtDatumStart])

  const TextFieldComponent = (props) => {
    return <TextField {...props} disabled={true} />;
  };

  useEffect(() => {
      setBewirtDatumStart(subDays(new Date(), 2));
      dispatch(
        forecastActions.saveForecastSingle("order_to", subDays(new Date(), 2))
      );
      setBewirtDatumEnd(subDays(new Date(), 2));
      dispatch(
        forecastActions.saveForecastSingle("order_from", subDays(new Date(), 2))
      );
      setBewirtDatumStartObj(undefined);
      setBewirtDatumEndObj(undefined);
  }, [Angebotsende, ATB, tranche]);

  return (
    <>
      {forecast.data.accept_to !== undefined && (
        <Grid
          item
          xs={10}
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "2rem",
          }}
        >
          <Grid item xs={8}>
            <Typography style={{ padding: "2rem 0 0 1rem" }}>
              <h4>{lang[locale].ManagementPeriod}</h4>
            </Typography>
            <Grid item xs={8} style={{ display: "flex", flexDirection: "row" }}>
              <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === "de" ? deLocale : enLocale}>
                <KeyboardDatePicker
                  // inputValue={bewirtDatumStart !== undefined ? bewirtDatumStart.slice(0, 10).split("-").reverse().join() : " "}
                  style={{ marginLeft: "1rem" }}
                  helperText=""
                  variant="inline"
                  format="dd.MM.yyyy"
                  fullWidth
                  autoOk={true}
                  minDate={
                    //@ts-ignore
                    !ATB ? forecast.data.accept_from : addDays(forecast.data.accept_to, 1)
                  }
                  maxDate={subDays(forecast.data.progFrom, 1)}
                  onChange={(e) => StartDatumHandler(e)}
                  value={bewirtDatumStart}
                  id="startdate"
                  data-testid="startdate"
                  label={lang[locale].startdate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  // TextFieldComponent={TextFieldComponent}
                  error={
                    forecast.data.accept_from !== undefined &&
                    bewirtDatumEnd !== undefined &&
                    bewirtDatumStart !== undefined &&
                    (bewirtDatumEnd < bewirtDatumStart ||
                      bewirtDatumStart < forecast.data.accept_from)
                  }
                />
              </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === "de" ? deLocale : enLocale}>
                <KeyboardDatePicker
                  // inputValue={bewirtDatumEnd !== undefined ? bewirtDatumEnd.slice(0, 10) : " "}
                  // inputValue={bewirtDatumEnd !== undefined ? bewirtDatumEnd.slice(0, 10).split("-").reverse().join() : " "}
                  style={{ marginLeft: "4rem" }}
                  helperText=""
                  variant="inline"
                  format="dd.MM.yyyy"
                  fullWidth
                  autoOk={true}
                  // minDate={
                  //   //@ts-ignore
                  //   !ATB ? forecast.data.accept_from : addDays(forecast.data.accept_from, 1)
                  // }
                  minDate={!ATB ? forecast.data.accept_from : addDays(forecast.data.accept_to, 1)}
                  maxDate={subDays(forecast.data.progFrom, 1)}
                  onChange={(e) => EndDatumHandler(e)}
                  value={bewirtDatumEnd}
                  //   error={}
                  //   minDateMessage={
                  //     "Das Datum darf nicht vor dem " +
                  //     format(tommorow, "dd.MM.yyyy") +
                  //     " liegen."
                  //   }
                  id="startdate"
                  data-testid="startdate"
                  label={lang[locale].enddate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  // TextFieldComponent={TextFieldComponent}
                  error={
                    forecast.data.accept_from !== undefined &&
                    bewirtDatumEnd !== undefined &&
                    bewirtDatumStart !== undefined &&
                    (bewirtDatumEnd < bewirtDatumStart ||
                      bewirtDatumEnd < forecast.data.accept_from)
                  }
                />
              </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
          {forecast.data.accept_from &&
          bewirtDatumEnd &&
          bewirtDatumStart &&
          bewirtDatumEnd >= bewirtDatumStart &&
          !(bewirtDatumEnd < forecast.data.accept_from) &&
          !(bewirtDatumStart < forecast.data.accept_from) &&
          ATB ? (
            <Button
              style={{ width: "40%", padding: 0, marginTop: "2rem" }}
              color="primary"
              onClick={ABKhandler}
            >
              {lang[locale].procurementCalendar}
            </Button>
          ) : null}
        </Grid>
      )}
    </>
  );
};

export default ABKFieldsComponent;
