import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "services/helpers/useSelector";
import authActions from "actions/auth.actions";
import { Redirect } from "react-router-dom";
import { Grid, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    grid: {
        backgroundColor: theme.palette.background.default,
        height: "100vh",
    },
}));

/*
 * Initial Loading Component
 */
function Loading() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const loggedIn = useSelector((state) => state.auth.loggedIn);
    useEffect(() => {
        //checks login once on component mount
        dispatch(authActions.checkLogin());
    }, [dispatch]);
    return (
        <>
            {loggedIn ? (
                <Redirect to="/app/myplans" />
            ) : (
                <>
                    {loggedIn === undefined ? (
                        <Grid
                            container
                            justify="center"
                            className={classes.grid}
                            data-testid="Loading"
                            alignItems="center"
                        >
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    ) : (
                        <Redirect to="/auth/login" />
                    )}
                </>
            )}
        </>
    );
}
export default Loading;
