import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
// import { Line, LineChart, CartesianGrid, XAxis,YAxis } from "recharts";
import { Chart, Legend, registerables } from "chart.js";
import { lang } from "../lang";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button, TextField, Tooltip } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import PortfolioService from "./../services/portfolios/PortfolioService";
import { useDispatch } from "react-redux";
import { useSelector } from "../services/helpers/useSelector";

Chart.register(...registerables);
const locale = localStorage.locale;

const StepLineChart = ({
  portfolioDetails,
  StepDiagramData,
  totalAmount,
  resultArray,
  CustomerID,
  portfolioID,
  greenArrayStep,
  DaysDifference,
}) => {
const PFMservive = new PortfolioService();
const { ciSettings } = useSelector((state) => state);
const convertedXData = resultArray.map((i) => Number(i.toFixed(0)));
const labels = convertedXData;
const [lowerStart, setLowerStart] = useState(20);
const [lowerEnd, setLowerEnd] = useState(95);
const [upperStart, setUpperStart] = useState(10);
const [upperEnd, setUpperEnd] = useState(80);
const [showDeatils, setShowDetails] = useState(false);
const iteratenum = Math.floor(100 /DaysDifference);
// console.log("greenArrayStep",greenArrayStep, iteratenum)
const mergedArray = greenArrayStep.map((item, i) =>
  Object.assign({ date: item, step: StepDiagramData[i] })
);
// const MergedFlat =  mergedArray.flatMap(item => Array(iteratenum).fill(item))

const options = {
  scales: {
    x: {
      type: 'linear',
      position: "bottom",
      ticks : {
        stepSize: (Math.round(100 /DaysDifference)),
       },
      color: "#4F5050",
      grid: {
        color: "#454545",
      },
      // labels: labels,
      labels: Array.from({ length: DaysDifference + 1 }, (_, index) =>{
        while((index * (100 /DaysDifference)) < 101) return (index * (100 /DaysDifference))
      }),
      // type: "line",
      title: {
        color: "white",
        display: true,
        text: "Bewirtschaftungszeitraum [%]",
        font: {
          size: 18,
        },
      },
      min : 0,
      max: 100
    },
    y: {
      grid: {
        color: "#454545",
      },
      gridLines: {
        zeroLineColor: '#ffcc33'
    },
      title: {
        color: "white",
        display: true,
        text: "Beschaffungsgrad [%]",
        font: {
          size: 18,
        },
      },
        min : 0,
        max: 100,
    },
  },

  plugins: {
    legend: { display: false },
    tooltip: {
      enabled: true,
      displayColors: false,
      backgroundColor: "#424242",
      callbacks: {
        title: () => null,
        label: function (context) {
          if (context.datasetIndex !== 2) {
            return "";
          }
          // return context.dataIndex % 3 === 0 ? context : '';
          const label1 = `Datum : ${mergedArray[context.dataIndex].date}`;
          const label2 = `Beschaffungsgrad : ${Number(
            context.parsed.y.toFixed(2)
          )} %`;
          return [label1, label2];
        },
        
      },
      position: "average",
    },
  },
};
// const options2 = {
//   scales: {
//     x: {
//       grid : {
//         color : "#303030"
//       },
//       ticks: {
//         display: true,
//         autoSkip: true,
//         beginAtZero: true,
//         color : "#303030",
//       },
//       gridLines: {
//         display: false,
//       },
//       labels: Array.from({ length: 101 }, (_, index) => index),
//       // type: "line",
//       title: {
//         color: "white",
//         display: false,
//         text: "Bewirtschaftungszeitraum [%]",
//         font: {
//           size: 18,
//         },

//       },
//     },
//     y: {
//       ticks: {
//         display: false,
//         autoSkip: true,
//         beginAtZero: true,
//         color : "#303030",
//       },
//       gridLines: {
//         display: false,
//       },
//       enabled: false,
//       grid : {
//         color : "#303030"
//       },
//       title: {
//         color: "white",
//         display: false,
//         text: "Beschaffungsgrad [%]",
//         font: {
//           size: 18,
//         },
//       },
//     },
//   },

//   plugins: {
//     legend: { display: false },
//     tooltip: {
//       enabled: true,
//       displayColors: false,
//       backgroundColor: "#424242",
//       callbacks: {
//         title: () => null,
//         label: function (context) {
//           if (context.datasetIndex !== 2) {
//             return "";
//           }
//           const label1 = `Datum : ${mergedArray[context.dataIndex].date}`;
//           const label2 = `Beschaffungsgrad : ${Number(
//             context.parsed.y.toFixed(2)
//           )} %`;
//           return [label1, label2];
//         },
//       },
//       position: "average",
//     },
//   },
// };

function SaveHedgeValueHandler(
  CustomerID,
  portfolioID,
  lowerStart,
  lowerEnd,
  upperStart,
  upperEnd
) {
  const Data = {
    lower_start: lowerStart,
    lower_end: lowerEnd,
    upper_start: upperStart,
    upper_end: upperEnd,
  };
  PFMservive.setHedgeChannalValues(CustomerID, portfolioID, Data);
  setShowDetails(!showDeatils);
}
// const labels = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40]
// const labels = portfolioDetails.filter((p) => p.zrid === p.zrid_ref)
// .map((pfdt) => (pfdt.order_date
//   .split("T")[0]
//   .split("-")
//   .reverse()
//   .join(".")))
// const dataForDataSet = StepDiagramData.map((pfdt)=> 100 * (pfdt.amount / totalAmount))
// const dataForDataSet =  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 4.11985617259288853, 4.11985617259288853, 4.11985617259288853, 4.11985617259288853]
// const data = {
//   labels: labels,
//   datasets: [
//     {
//       label: "Beschaffungsgrad [%]",
//       data: mergedArray.map((j) => j.step),
//       fill: false,
//       borderColor: "green",
//       stepped: true, // This enables the step line
//     },
//     {
//       // label: "untene Line",
//       borderColor: "rgba(0, 0, 255, 0)",
//       backgroundColor: "rgba(0, 0, 255, 0)",
//       spanGaps: true,
//       tooltips: {
//         enabled: false,
//       },
//       title: {
//         enabled: false,
//       },
//       data: [
//         { x: lowerStart, y: 0 },
//         { x: 100, y: lowerEnd },
//         // {x:3, y:10},{x:4,y:15},
//       ], // Y-values for the diagonal line
//       fill: false,
//     },
//   ],
// };

const data = {
  datasets: [
    {
      // label: "untene Line",
      borderColor: "rgba(75,192,192,1)",
      backgroundColor: "rgba(75,192,192,0.4)",
      spanGaps: true,
      tooltips: {
        enabled: false,
      },
      title: {
        enabled: false,
      },
      data: [
        { x: lowerStart, y: 0 },
        { x: 100, y: lowerEnd },
        // {x:3, y:10},{x:4,y:15},
      ], // Y-values for the diagonal line
      fill: false,
    },
    {
      label: "obene Line",
      borderColor: "red",
      backgroundColor: "red",
      tooltips: {
        enabled: false,
      },
      data: [
        { x: 0, y: upperStart },
        { x: upperEnd, y: 100 },
      ], // Y-values for the diagonal line
      fill: false,
    },
    {
      label: "Beschaffungsgrad [%]",
      data:  mergedArray.map((j) => j.step),
      fill: false,
      borderColor: "green",
      stepped: true, // This enables the step line
      // stepSize : iteratenum,
      // pointRadius: function (context) {
      //   if (context.dataIndex % iteratenum !== 0) {
      //     return 0;
      //   }
      //   return 2.5;
      // },
    },
  ],
};

useEffect(()=>{
  PFMservive.getHedgeChannalValues( CustomerID,portfolioID  ).then(res=>{
    setLowerEnd(res.lower_end);
    setLowerStart(res.lower_start);
    setUpperEnd(res.upper_end);
    setUpperStart(res.upper_start);
  })
},[])
return (
  <>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {showDeatils === false ? (
        <Tooltip title="Hedge channel settings">
          <IconButton
            edge="end"
            aria-label="setting"
            onClick={() => setShowDetails(!showDeatils)}
            style={{
              color: "white",
              margin: "0 0.4em",
            }}
            // id={selectedToDelete}
            // disabled={PortfolioName === ""}
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() =>
              SaveHedgeValueHandler(
                CustomerID,
                portfolioID,
                lowerStart,
                lowerEnd,
                upperStart,
                upperEnd
              )
            }
            // color="primary"
            style={{ color : ciSettings.primary }}
          >
            {lang[locale].save ? lang[locale].save : <CircularProgress size={15} />}
          </Button>
          <Button
            onClick={() => setShowDetails(!showDeatils)}
            // color="primary"
            style={{ margin: "0 2rem", color : ciSettings.primary }}
          >
            {lang[locale].close ? lang[locale].close : <CircularProgress size={15} />}
          </Button>
        </div>
      )}

      <h1>Hedgekanal</h1>
    </div>
    {showDeatils ? (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <TextField
            value={upperStart}
            onChange={(e) => setUpperStart(Number(e.target.value))}
            style={{
              width: "3%",
              margin: "0 1rem 0 3rem",
              textAlign: "center",
            }}
          ></TextField>
          <h4>
            Maximaler Beschaffungsgrad in % zu Beginn des
            Bewirtschaftungszeitraumes
          </h4>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <TextField
            value={upperEnd}
            onChange={(e) => setUpperEnd(Number(e.target.value))}
            style={{
              width: "3%",
              margin: "0 1rem 0 3rem",
              textAlign: "center",
            }}
          ></TextField>
          <h4>
            Zeit des Bewirtschaftungszeitraumes in %, wann frühestens 100%
            Beschaffungsgrad erreicht werden darf
          </h4>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <TextField
            value={lowerStart}
            onChange={(e) => setLowerStart(Number(e.target.value))}
            style={{
              width: "3%",
              margin: "0 1rem 0 3rem",
              textAlign: "center",
            }}
          ></TextField>
          <h4>
            Zeit des Bewirtschaftungszeitraumes in %, wann spätestens die
            Beschaffung beginnen muss
          </h4>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <TextField
            value={lowerEnd}
            onChange={(e) => setLowerEnd(Number(e.target.value))}
            style={{
              width: "3%",
              margin: "0 1rem 0 3rem",
              textAlign: "center",
            }}
          ></TextField>
          <h4>
            Minimaler Beschaffungsgrad in % am Ende des
            Bewirtschaftungszeitraumes
          </h4>
        </div>
      </div>
    ) : null}
    <div>
      <Line 
        //@ts-ignore
        data={data}
        //@ts-ignore
        options={options}
        style={{minWidth : "90%"}}
        />
     {/* <Line style={{position : "absolute", zIndex : 1, marginLeft : "3.5rem", top : 6.5}}
     
        //@ts-ignore
        data={data2}
        //@ts-ignore
        options={options2}
      /> */}
    </div>
    {/* <Line data={data} options={options} /> */}
    {/* <Line data={data} options={options} /> */}
  </>
);
};

export default StepLineChart;

// const PFMservive = new PortfolioService();
// const convertedXData = resultArray.map((i) => Number(i.toFixed(0)));
// const labels = convertedXData;
// const [lowerStart, setLowerStart] = useState(20);
// const [lowerEnd, setLowerEnd] = useState(95);
// const [upperStart, setUpperStart] = useState(10);
// const [upperEnd, setUpperEnd] = useState(80);
// const [showDeatils, setShowDetails] = useState(false);

// // console.log("StepDiagramData",StepDiagramData)

// const mergedArray = greenArrayStep.map((item, i) =>
//   Object.assign({ date: item, step: StepDiagramData[i] })
// );

// const options = {
//   scales: {
//     x: {
//       color: "#4F5050",
//       grid: {
//         color: "#454545",
//       },
//       labels: labels,
//       // type: "line",
//       title: {
//         color: "white",
//         display: true,
//         text: "Bewirtschaftungszeitraum [%]",
//         font: {
//           size: 18,
//         },
//       },
//     },
//     y: {
//       grid: {
//         color: "#454545",
//       },
//       gridLines: {
//         zeroLineColor: '#ffcc33'
//     },
//       title: {
//         color: "white",
//         display: true,
//         text: "Beschaffungsgrad [%]",
//         font: {
//           size: 18,
//         },
//       },
//     },
//   },

//   plugins: {
//     legend: { display: false },
//     tooltip: {
//       enabled: true,
//       displayColors: false,
//       backgroundColor: "#424242",
//       callbacks: {
//         title: () => null,
//         label: function (context) {
//           // if (context.datasetIndex !== 1) {
//           //   return "";
//           // }
//           const label1 = `Datum : ${mergedArray[context.dataIndex].date}`;
//           const label2 = `Beschaffungsgrad : ${Number(
//             context.parsed.y.toFixed(2)
//           )} %`;
//           return [label1, label2];
//         },
//       },
//       position: "average",
//     },
//   },
// };
// const options2 = {
//   scales: {
//     x: {
//       grid : {
//         color : "#303030"
//       },
//       ticks: {
//         display: true,
//         autoSkip: true,
//         beginAtZero: true,
//         color : "#303030",
//       },
//       gridLines: {
//         display: false,
//       },
//       labels: Array.from({ length: 101 }, (_, index) => index),
//       // type: "line",
//       title: {
//         color: "white",
//         display: false,
//         text: "Bewirtschaftungszeitraum [%]",
//         font: {
//           size: 18,
//         },

//       },
//     },
//     y: {
//       ticks: {
//         display: false,
//         autoSkip: true,
//         beginAtZero: true,
//         color : "#303030",
//       },
//       gridLines: {
//         display: false,
//       },
//       enabled: false,
//       grid : {
//         color : "#303030"
//       },
//       title: {
//         color: "white",
//         display: false,
//         text: "Beschaffungsgrad [%]",
//         font: {
//           size: 18,
//         },
//       },
//     },
//   },

//   plugins: {
//     legend: { display: false },
//     tooltip: {
//       enabled: true,
//       displayColors: false,
//       backgroundColor: "#424242",
//       callbacks: {
//         title: () => null,
//         label: function (context) {
//           if (context.datasetIndex !== 2) {
//             return "";
//           }
//           const label1 = `Datum : ${mergedArray[context.dataIndex].date}`;
//           const label2 = `Beschaffungsgrad : ${Number(
//             context.parsed.y.toFixed(2)
//           )} %`;
//           return [label1, label2];
//         },
//       },
//       position: "average",
//     },
//   },
// };

// function SaveHedgeValueHandler(
//   CustomerID,
//   portfolioID,
//   lowerStart,
//   lowerEnd,
//   upperStart,
//   upperEnd
// ) {
//   const Data = {
//     lower_start: lowerStart,
//     lower_end: lowerEnd,
//     upper_start: upperStart,
//     upper_end: upperEnd,
//   };
//   PFMservive.setHedgeChannalValues(CustomerID, portfolioID, Data);
//   setShowDetails(!showDeatils);
// }
// // const labels = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40]
// // const labels = portfolioDetails.filter((p) => p.zrid === p.zrid_ref)
// // .map((pfdt) => (pfdt.order_date
// //   .split("T")[0]
// //   .split("-")
// //   .reverse()
// //   .join(".")))
// // const dataForDataSet = StepDiagramData.map((pfdt)=> 100 * (pfdt.amount / totalAmount))
// // const dataForDataSet =  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 0.11985617259288853, 4.11985617259288853, 4.11985617259288853, 4.11985617259288853, 4.11985617259288853]
// const data = {
//   labels: labels,
//   datasets: [
//     {
//       label: "Beschaffungsgrad [%]",
//       data: mergedArray.map((j) => j.step),
//       fill: false,
//       borderColor: "green",
//       stepped: true, // This enables the step line
//     },
//     {
//       // label: "untene Line",
//       borderColor: "rgba(0, 0, 255, 0)",
//       backgroundColor: "rgba(0, 0, 255, 0)",
//       spanGaps: true,
//       tooltips: {
//         enabled: false,
//       },
//       title: {
//         enabled: false,
//       },
//       data: [
//         { x: lowerStart, y: 0 },
//         { x: 100, y: lowerEnd },
//         // {x:3, y:10},{x:4,y:15},
//       ], // Y-values for the diagonal line
//       fill: false,
//     },
//   ],
// };

// const data2 = {
//   datasets: [
//     {
//       // label: "untene Line",
//       borderColor: "rgba(75,192,192,1)",
//       backgroundColor: "rgba(75,192,192,0.4)",
//       spanGaps: true,
//       tooltips: {
//         enabled: false,
//       },
//       title: {
//         enabled: false,
//       },
//       data: [
//         { x: lowerStart, y: 0 },
//         { x: 100, y: lowerEnd },
//         // {x:3, y:10},{x:4,y:15},
//       ], // Y-values for the diagonal line
//       fill: false,
//     },
//     {
//       label: "obene Line",
//       borderColor: "red",
//       backgroundColor: "red",
//       tooltips: {
//         enabled: false,
//       },
//       data: [
//         { x: 0, y: upperStart },
//         { x: upperEnd, y: 100 },
//       ], // Y-values for the diagonal line
//       fill: false,
//     },
//     // {
//     //   label: "Beschaffungsgrad [%]",
//     //   data: mergedArray.map((j) => j.step),
//     //   fill: false,
//     //   borderColor: "green",
//     //   stepped: true, // This enables the step line
//     // },
//   ],
// };

// useEffect(()=>{
//   PFMservive.getHedgeChannalValues( CustomerID,portfolioID,).then(res=>{
//     setLowerEnd(res.lower_end);
//     setLowerStart(res.lower_start);
//     setUpperEnd(res.upper_end);
//     setUpperStart(res.upper_start);
//   })
// },[])
// return (
//   <>
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       {showDeatils === false ? (
//         <Tooltip title="Hedge channel settings">
//           <IconButton
//             edge="end"
//             aria-label="setting"
//             onClick={() => setShowDetails(!showDeatils)}
//             style={{
//               color: "white",
//               margin: "0 0.4em",
//             }}
//             // id={selectedToDelete}
//             // disabled={PortfolioName === ""}
//           >
//             <SettingsIcon />
//           </IconButton>
//         </Tooltip>
//       ) : (
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             justifyContent: "center",
//           }}
//         >
//           <Button
//             onClick={() =>
//               SaveHedgeValueHandler(
//                 CustomerID,
//                 portfolioID,
//                 lowerStart,
//                 lowerEnd,
//                 upperStart,
//                 upperEnd
//               )
//             }
//             color="primary"
//           >
//             {lang[locale].save}
//           </Button>
//           <Button
//             onClick={() => setShowDetails(!showDeatils)}
//             color="primary"
//             style={{ margin: "0 2rem" }}
//           >
//             {lang[locale].close}
//           </Button>
//         </div>
//       )}

//       <h1>Hedgekanal</h1>
//     </div>
//     {showDeatils ? (
//       <div>
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "flex-start",
//             alignItems: "flex-start",
//           }}
//         >
//           <TextField
//             value={upperStart}
//             onChange={(e) => setUpperStart(Number(e.target.value))}
//             style={{
//               width: "3%",
//               margin: "0 1rem 0 3rem",
//               textAlign: "center",
//             }}
//           ></TextField>
//           <h4>
//             Maximaler Beschaffungsgrad in % zu Beginn des
//             Bewirtschaftungszeitraumes
//           </h4>
//         </div>
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "flex-start",
//             alignItems: "flex-start",
//           }}
//         >
//           <TextField
//             value={upperEnd}
//             onChange={(e) => setUpperEnd(Number(e.target.value))}
//             style={{
//               width: "3%",
//               margin: "0 1rem 0 3rem",
//               textAlign: "center",
//             }}
//           ></TextField>
//           <h4>
//             Zeit des Bewirtschaftungszeitraumes in %, wann frühestens 100%
//             Beschaffungsgrad erreicht werden darf
//           </h4>
//         </div>
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "flex-start",
//             alignItems: "flex-start",
//           }}
//         >
//           <TextField
//             value={lowerStart}
//             onChange={(e) => setLowerStart(Number(e.target.value))}
//             style={{
//               width: "3%",
//               margin: "0 1rem 0 3rem",
//               textAlign: "center",
//             }}
//           ></TextField>
//           <h4>
//             Zeit des Bewirtschaftungszeitraumes in %, wann spätestens die
//             Beschaffung beginnen muss
//           </h4>
//         </div>
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "flex-start",
//             alignItems: "flex-start",
//           }}
//         >
//           <TextField
//             value={lowerEnd}
//             onChange={(e) => setLowerEnd(Number(e.target.value))}
//             style={{
//               width: "3%",
//               margin: "0 1rem 0 3rem",
//               textAlign: "center",
//             }}
//           ></TextField>
//           <h4>
//             Minimaler Beschaffungsgrad in % am Ende des
//             Bewirtschaftungszeitraumes
//           </h4>
//         </div>
//       </div>
//     ) : null}
//     <div >
//       <Line style={{position : "absolute", zIndex : 2 ,marginBottom : "0.3rem"}}
//         //@ts-ignore
//         data={data}
//         //@ts-ignore
//         options={options}
//         />
//      <Line style={{position : "absolute", zIndex : 1, marginLeft : "3.5rem", marginTop : "0.3rem", width : "0,713rem", height : "0,732rem"}}
//         //@ts-ignore
//         data={data2}
//         //@ts-ignore
//         options={options2}
//       />
//     </div>
//     {/* <Line data={data} options={options} /> */}
//     {/* <Line data={data} options={options} /> */}
//   </>
// );