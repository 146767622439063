import React, { useEffect, useState } from "react";
import { lang } from "../lang";
import PriceService from "../services/price/PriceService";
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  Select,
  Typography,
  FormControl,
  Button
} from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import CardAddButtonComponent from "./CardAddButton";
import AuthService from "../services/auth/AuthService";
import authActions from "../actions/auth.actions";
import LoadcurveCardComponentPE from "./LoadcurveCardPE";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';

const service = new PriceService();

const PriceDevelopment = (props) => {
  const role = AuthService.getRole();
  const dispatch = useDispatch();
  const locale = localStorage.locale;
  const [isPriceDevelopment, setIsPriceDevelopment] = useState(false);
  const [filtered, setFiltered] = useState<any[] | undefined>(undefined);
  const [PEfilterChange, setPEfilterChange] = useState(0);
  const [filters, setFilters] = useState("");
  const [isSticky, setIsSticky] = useState(false);

  const defaultProps = {
    options: filtered,
    getOptionLabel: (filtered) => filtered.zr_name.split("_")[0],
  };

  function handleTextChange(e) {
    console.log("e", e.target.outerText);
    setFilters(e.target.outerText.split("_")[0]);
  }

  function handleClearText(){
    setFilters("");
}

  useEffect(() => {
    dispatch(authActions.checkLogin());
  }, []);

  useEffect(() => {
    service.getPriceDevelopment().then((res) => {
      setFiltered(res.reverse());
    });
  }, []);

  useEffect(() => {
    if (PEfilterChange === 1) {
      let timeOut = setTimeout(() => {
        service.getPriceDevelopment().then((res) => {
          setFiltered(res.reverse());
          setPEfilterChange(0);
        });
      }, 20000);
      return () => {
        clearTimeout(timeOut);
      };
    }
    if (PEfilterChange === 2) {
      let timeOut2 = setTimeout(() => {
        service.getPriceDevelopment().then((res) => {
          if (res.length === undefined) {
            setFiltered(undefined);
          }
          setFiltered(res.reverse());
          setPEfilterChange(0);
        });
      }, 5000);
      return () => {
        clearTimeout(timeOut2);
      };
    }
  }, [PEfilterChange]);

  const filtereddd = filtered
    ? filtered.filter(
        (curve) =>
          curve.zr_name
            .split("_")[0]
            .toLowerCase()
            .includes(filters?.length > 0 && filters.toLowerCase()) ||
          curve.zrid.toString().includes(filters)
      )
    : null;

  useEffect(() => {
    props.changeNavTitle(lang[locale].pricedevelopment);
    document.title = lang[locale].pricedevelopment + " | EMDACS";
  }, []);

  useEffect(() => {
    setIsPriceDevelopment(true);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {
        role.simple_role === "LIEFERANT" ? (
          <Box
            flexDirection="row"
            display="flex"
            bgcolor="background.paper"
            alignItems="center"
            height="6em"
            margin="2rem"
            borderRadius="5px"
            justifyContent="space-between"
            style={{
              position: isSticky === true ? "fixed" : "relative",
              zIndex: isSticky ? 1 : 0,
              backgroundColor: isSticky
                ? "rgba(255, 255, 255, 0.2)"
                : "#424242",
              boxShadow: isSticky ? "0 4px 30px rgba(0, 0, 0, 0.1)" : "0",
              backdropFilter: isSticky ? "blur(5px)" : "1px",
              border: isSticky ? "1px solid rgba(255, 255, 255, 0.3)" : "0",
              width: isSticky ? "81%" : "90%",
            }}
          >
            <Box p={6}>
              <CardAddButtonComponent
                role={role}
                locale={locale}
                customer={1}
                active={true}
                isPriceDevelopment={isPriceDevelopment}
                setPEfilterChange={setPEfilterChange}
                // role={role}
                // locale={locale}
                // customer={customer_id}
                // active={customer && customer.current_count < customer.prog_count}
              />
            </Box>
            {/* <Box p={3} style={{ margin: "0 0 1.1em" }}>
            <TextField
              id="fulltext_filter"
              label={lang[locale].search}
              value={filters}
              inputProps={{ "data-testid": "fullTextFilter" }}
              onChange={handleTextChange}
            />
          </Box> */}
            {/* {filtered && (
              //@ts-ignore
              <Autocomplete
                style={{ width: "10%", marginRight: "2rem" }}
                {...defaultProps}
                id="clear-on-escape"
                clearOnEscape
                onChange={(e) => handleTextChange(e)}
                // onChange={(e) => {
                //   handleTextChange(e);
                //   console.log("e", e.target);
                // }}
                // onChange={(e, val) => {(val || val === "") && handleTextChange(e, val)}}
                sx={{
                  width: 300,
                  "& button[title='Clear']": {
                    visibility: "visible",
                  },
                }}
                freeSolo={true}
                disableClearable={true}
                renderInput={(params) => (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      {...params}
                      label={lang[locale].search}
                      variant="standard"
                    />
                    {filters.length > 0 ? (
                      <Button
                        style={{ margin: "1rem 0 0 0.5rem" }}
                        onClick={handleClearText}
                      >
                        <DeleteIcon fontSize="small" />
                      </Button>
                    ) : null}
                    {/* <IconButton style={{color:"white"}} onClick={()=>setFilters("")}>
                    <CloseIcon/>
                    </IconButton> */}
                  {/* </div> */}
                {/* )} */}
              {/* /> */}
            {/* )} */}
          </Box>
        ) : (
          <Box
            flexDirection="row"
            display="flex"
            bgcolor="background.paper"
            alignItems="center"
            height="6em"
            margin="2rem"
            borderRadius="5px"
            justifyContent="flex-end"
            style={{
              position: isSticky === true ? "fixed" : "relative",
              zIndex: isSticky ? 1 : 0,
              backgroundColor: isSticky
                ? "rgba(255, 255, 255, 0.2)"
                : "#424242",
              boxShadow: isSticky ? "0 4px 30px rgba(0, 0, 0, 0.1)" : "0",
              backdropFilter: isSticky ? "blur(5px)" : "1px",
              border: isSticky ? "1px solid rgba(255, 255, 255, 0.3)" : "0",
              width: isSticky ? "81%" : "95%",
            }}
          >
            {/* {filtered && (
              //@ts-ignore
              <Autocomplete
                style={{ width: "10%", marginRight: "2em" }}
                {...defaultProps}
                id="clear-on-escape"
                clearOnEscape
                onChange={(e) => handleTextChange(e)}
                // onChange={(e) => {
                //   handleTextChange(e);
                //   console.log("e", e.target);
                // }}
                // onChange={(e, val) => {(val || val === "") && handleTextChange(e, val)}}
                sx={{
                  width: 300,
                  "& button[title='Clear']": {
                    visibility: "visible",
                  },
                }}
                freeSolo={true}
                disableClearable={true}
                renderInput={(params) => (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      {...params}
                      label={lang[locale].search}
                      variant="standard"
                    />
                    {/* <IconButton style={{color:"white"}} onClick={()=>setFilters("")}>
                    <CloseIcon/>
                    </IconButton> */}
                  {/* </div> */}
                {/* )} */}
              {/* /> */}
            {/* )}  */}
          </Box>
        )
        // (
        //   <Box
        //   flexDirection="row"
        //   display="flex"

        //   bgcolor="background.paper"
        //   alignItems="center"
        //   height="6em"
        //   margin="2rem"
        //   borderRadius="5px"
        //   justifyContent="space-between"
        //   style={{
        //     position: isSticky === true ? "fixed" : "relative",
        //     zIndex: isSticky ? 1 : 0,
        //     backgroundColor: isSticky ? "rgba(255, 255, 255, 0.2)" : "#424242",
        //     boxShadow: isSticky ? "0 4px 30px rgba(0, 0, 0, 0.1)" : "0",
        //     backdropFilter: isSticky ? "blur(5px)" : "1px",
        //     border: isSticky ? "1px solid rgba(255, 255, 255, 0.3)" : "0",
        //     width: "30%",
        //     justifyContent : "center",
        //     marginLeft : "50em",
        //   }}
        // >
        //   {filtered && (
        //     //@ts-ignore
        //     <Autocomplete
        //       style={{ width: "40%", marginRight: "2rem" }}
        //       {...defaultProps}
        //       id="clear-on-escape"
        //       clearOnEscape
        //       onChange={(e) => handleTextChange(e)}
        //       onClick={(e) => console.log("e", e)}
        //       renderInput={(params) => (
        //         <TextField
        //           {...params}
        //           label={lang[locale].search}
        //           variant="standard"
        //         />
        //       )}
        //     />
        //   )}
        // </Box>
        // )
      }
      {filtereddd && filtereddd.length > 0 ? (
        <>
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={4}
            style={{
              padding: "3.5em 1.5em 0em 1.5em",
            }}
          >
            {filtereddd.map((curve) => (
              <Grid
                item
                xs={12}
                md={6}
                key={curve.zrid}
                style={{ maxWidth: "45em" }}
              >
                <LoadcurveCardComponentPE
                  loadcurve={curve}
                  id={curve.zrid}
                  setPEfilterChange={setPEfilterChange}
                />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          flex-direction="column"
          justifyContent="center"
          /* minHeight="70vh" */
        >
          <div
            style={{
              display: "flex",
              margin: "4em auto",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2>{lang[locale].noLoadcurves}</h2>
          </div>
        </Box>
      )}
    </>
  );
};

export default PriceDevelopment;
