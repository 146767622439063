import React, { useState } from "react";
import { lang } from "../lang";

//Materials UI
import {
  Button,
  TextField,
  CardContent,
  Card,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import StyledGasPowerSwitch from "./StyledGasPowerSwitch";

//Hooks
import { useDispatch } from "react-redux";

//services
import PortfolioService from "./../services/portfolios/PortfolioService";
import modalActions from "actions/modal.actions";
import snackbarActions from "actions/snackbar.actions";

const PFMservive = new PortfolioService();

function AddPortfolioPage(props) {
  const dispatch = useDispatch();

  const locale = localStorage.locale;
  const [portfolioName, setPortfolioName] = useState("");
  const [portfolioCommodity, setPortfolioCommodity] = useState(1);

  const AddPortfoHandler = () => {
    PFMservive.createportfolio({
      name: portfolioName,
      commodity: portfolioCommodity,
      customer_id: props.id,
    })
      .then((res) => {
        if (res.status === "ok") {
          dispatch(
            snackbarActions.openSnackbar("successfully added", "success")
          );
          props.getAllPortfolio();
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
    props.getAllPortfolio(props.id);
    dispatch(modalActions.closeModal());
  };

  const commodityhandler = (e) => {
    if (e.target.checked === true) {
      setPortfolioCommodity(1);
    } else setPortfolioCommodity(2);
  };

  const closePortfoHandler = () => {
    dispatch(modalActions.closeModal());
  };

  return (
    <>
      {/* // <CardContent> */}
      {/* <form onSubmit={addCustomer}> */}
      <form>
        <Grid>
          <Typography
            variant="body2"
            color="primary"
            style={{ marginLeft: "1em" }}
          ></Typography>
        </Grid>
        <Box
          flexDirection="row"
          bgcolor="background.paper"
          display="flex"
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <>
            <Grid item>
              <TextField
                value={portfolioName}
                id="newFirstName"
                label={"Portfolio Name"}
                className="maximalWidth"
                type="text"
                onChange={(e) => setPortfolioName(e.target.value)}
                variant="outlined"
                required
                autoComplete="off"
              />
            </Grid>
            <Grid style={{display : "flex", flexDirection : "row", justifyContent : "center", alignItems : "center"}}>
              <Grid item color="primary">
                {lang[locale].gas}
              </Grid>
              <Grid item>
                <StyledGasPowerSwitch
                  checked={
                    portfolioCommodity === 1
                      ? true
                      : portfolioCommodity === 2 && false
                  }
                  onChange={commodityhandler}
                  disabled={false}
                />
              </Grid>
              <Grid item color="primary">
                {lang[locale].power}
              </Grid>
            </Grid>
          </>
          <>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={AddPortfoHandler}
                disabled={portfolioName.length < 3}
                style={{ marginRight: "1em" }}
              >
                {lang[locale].add}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                onClick={closePortfoHandler}
              >
                {lang[locale].close}
              </Button>
            </Grid>
          </>
        </Box>
      </form>
      {/* </CardContent> */}
    </>
  );
}

export default AddPortfolioPage;
