import React, { useEffect, useState } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { lang } from "lang";
import LoadcurveCardComponent from "./LoadcurveCard";
import LoadcurveModel from "../typeDefs/LoadcurveModel";
import { useSelector } from "services/helpers/useSelector";
import UserService from "../services/users/UserService";

function CustomerPlansFinishedComponent({ filtered, curvelistChanged, orderListChanged, DataKind }) {
    const Userservice = new UserService();
    const locale = localStorage.locale;
    const [handleDays,setHandleDays] = useState<any[]>([]);
    // @ts-ignore
    const customer = useSelector((state) => state.customer.customerData);

    const current_count = customer?.current_count;

    useEffect(()=>{
            Userservice.getSupplierBuyTimes().then(res=>setHandleDays(res))     
    },[])

    const HandledaysUpdate = () => handleDays.map(i =>{
        if (i.day === 1){
            return {...i, tag : lang[locale].monday}
        }
        if (i.day === 2){
            return {...i, tag : lang[locale].tuesday}
        }
        if (i.day === 3){
            return {...i, tag : lang[locale].wednesday}
        }
        if (i.day === 4){
            return {...i, tag : lang[locale].thursday}
        }
        if (i.day === 5){
            return {...i, tag : lang[locale].friday}
        }
        if (i.day === 6){
            return {...i, tag : lang[locale].saturday}
        }
        if (i.day === 0){
            return {...i, tag : lang[locale].sunday}
        }
    })


    return (
        <>
            <Grid
                container
                justify="center"
                alignItems="center"
                spacing={4}
                style={{
                    padding: "3.5em 1.5em 0em 1.5em",
                }}
            >
                {current_count && current_count > 0 && filtered && filtered.length > 0 ? (
                    <>
                        {filtered.map((curve: LoadcurveModel) => (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                key={curve.zrid}
                                style={{ maxWidth: "45em" }}
                            >
                                <LoadcurveCardComponent
                                    loadcurve={curve}
                                    id={customer?.id}
                                    curvelistChanged={curvelistChanged}
                                    orderListChanged={orderListChanged}
                                    DataKind={DataKind}
                                    //@ts-ignore
                                    handleDays={HandledaysUpdate()}
                                />
                            </Grid>
                        ))}
                    </>
                ) : (
                    <Box
                        display="flex"
                        alignItems="center"
                        flex-direction="column"
                        justifyContent="center"
                        /* minHeight="70vh" */
                    >
                        <Typography variant="h6" color="textSecondary">
                            {(DataKind === 1 || undefined) ? lang[locale].noLoadcurves : DataKind === 5 ? "Derzeit sind keine gelöschten Angebote vorhanden" : DataKind === 4 ? "Derzeit sind keine abgelaufenen Angebote vorhanden" : DataKind === 6 && "Derzeit sind keine ausstehenden Angebote vorhanden" }
                        </Typography>
                    </Box>
                )}
            </Grid>
        </>
    );
}

export default CustomerPlansFinishedComponent;
