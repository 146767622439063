import { usersConstants } from "constants/users.constants";
import { AnyAction } from "redux";

export interface customerReducerInitialStateType {
    users: Array<Object>;
}

let initialState: customerReducerInitialStateType = {
    users: [],
};

export function users(
    state: customerReducerInitialStateType = initialState,
    action: AnyAction
) {
    switch (action.type) {
        case usersConstants.UPDATE_ALL_USERS:
            // console.log("In User Actions");
            return {
                ...state,
                users: action.users,
            };
        case usersConstants.GET_ALL_USERS:
            // console.log("In User Actions");
            return {
                ...state.users,
            };
        case usersConstants.UPDATE_SINGLE_USER:
            let users = [...state.users];
            //ts-ignore
            const index = users.findIndex(
                // @ts-ignore
                (user) => user.uid === action.user.uid
            );
            // @ts-ignore
            users[index].role.permissions = action.user.permissions;
            return { users };
        case usersConstants.DELETE_SINGLE_USER:
            let usersCopy = [...state.users];
            usersCopy = usersCopy.filter(
                //@ts-ignore
                (user) => user.uid !== Number(action.uid)
            );
            return { users: usersCopy };
        case usersConstants.RESET:
            return {
                users: [],
            };
        default:
            return state;
    }
}
