import React, { useEffect } from "react";
import { lang } from "../lang";

// Materila UI
import { Tabs, Tab, Box } from "@material-ui/core";

// Hooks
import { useDispatch } from "react-redux";
import { Route, Link, useRouteMatch } from "react-router-dom";

// Services
// import UserService from "../services/users/UserService";
import { useSelector } from "services/helpers/useSelector";

//Components
import SupplierUserListComponent from "./SupplierUserList";
import CustomerListComponent from "./CustomerList";
import UserListComponent from "./UserList";

//Actions
import authActions from "actions/auth.actions";

// const service = new UserService();

function MyTabs() {
  const coloR = useSelector((state) => state.ciSettings);
  const locale = localStorage.locale;
  const routeMatch = useRouteMatch([
    "/app/users/customers",
    "/app/users/suppliers",
  ]);
  const currentTab = routeMatch?.path;


  return (
    <Box style={{ width: "100%" }}>
      <>
        <Box>
          <Tabs
            TabIndicatorProps={{
              style: {
                backgroundColor: coloR.secondary,
              },
            }}
            value={currentTab}
            aria-label="Usermanagement Tabs"
          >
            <Tab
              value="/app/users/customers"
              label={lang[locale].customer}
              to={"/app/users/customers"}
              component={Link}
            />
            <Tab
              value="/app/users/suppliers"
              label={lang[locale].supplier}
              to={"/app/users/suppliers"}
              component={Link}
            />
          </Tabs>
        </Box>
      </>
    </Box>
  );
}

function UsermanagementComponent(props) {
  const locale = localStorage.locale; // eslint-disable-line react-hooks/exhaustive-deps
  const dispatch = useDispatch();

  useEffect(() => {
    props.changeNavTitle(lang[locale].users);
    document.title = lang[locale].users + " | EMDACS";
  }, []);

  useEffect(() => {
    dispatch(authActions.checkLogin());
  }, []);

  return (
    <Box style={{ width: "100%" }}>
      <MyTabs />
      <Route exact path={"/app/users/customers"}>
        <CustomerListComponent
          coloR={props.coloR}
          AngebotMaxCount={props.AngebotMaxCount}
        />
      </Route>
      <Route exact path={"/app/users/suppliers"}>
        <SupplierUserListComponent coloR={props.coloR} />
      </Route>

      <Route exact path={"/app/users/customers/:name/:id"}>
        <UserListComponent coloR={props.coloR} />
      </Route>
    </Box>
  );
}

export default UsermanagementComponent;
