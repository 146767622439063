import React from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch } from "react-redux";
import snackbarActions from "actions/snackbar.actions";
import { useSelector } from "services/helpers/useSelector";
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/**
 * Component for Snackbar
 */
function SnackbarComponent() {
    const open = useSelector(state => state.snack?.open);
    const message = useSelector(state => state.snack.message);
    const color = useSelector(state => state.snack.color);

    const dispatch = useDispatch();


    //closes the snackbar
    function handleClose() {
        dispatch(snackbarActions.closeSnackbar());
    }


    return (
        <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={color}>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default SnackbarComponent;