import React from "react";
import { Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    switchGas: {
        backgroundColor: theme.palette.secondary.main,
    },
    thumbGas: {
        backgroundColor: theme.palette.secondary.main,
    },
    thumbPower: {
        backgroundColor: theme.palette.primary.main,
    },
}));

interface IStyledGasPowerSwitchProps {
    onChange: (e) => void;
    checked: boolean;
    disabled: boolean;
}

/**
 * Showing a switch which is blue when on and yellow when off
 * @constructor
 */
function StyledGasPowerSwitch(props: IStyledGasPowerSwitchProps) {
    const classes = useStyles();
    return (
        <Switch
            checked={props.checked}
            onChange={props.onChange}
            data-testid="powertoggle"
            name="checkedB"
            color="primary"
            disabled={props.disabled}
            classes={{
                track: classes.switchGas,
                thumb: !props.checked ? classes.thumbGas : classes.thumbPower,
            }}
        />
    );
}

export default StyledGasPowerSwitch;
