import React, { useEffect, useState, useRef } from "react";
import { lang } from "../lang";
import { Link } from "react-router-dom";
import classNames from "clsx";
import TwoDecimalComponent from "./TwoDecimal";
import { styled } from "@mui/material/styles";

//Material UI
import {
  Paper,
  Button,
  Grid,
  FormGroup,
  TextField,
  FormControlLabel,
  TableCell,
  IconButton,
  Switch,
  Typography,
  Tooltip,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

//Services
import UserService from "../services/users/UserService";

//Components
import DeleteUserOrCustomerComponent from "./DeleteUserOrCustomer";
import ThousandSeperatorInputComponent from "./ThousandSeperatorInput";

//Hooks
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "services/helpers/useSelector";

//Actions
import snackbarActions from "../actions/snackbar.actions";
import modalActions from "../actions/modal.actions";
import customerActions from "actions/customer.actions";

//Models
import CustomerModel from "../typeDefs/CustomerModel";

//DevExpress Grid
import {
  Grid as TableGrid,
  Table as TB,
  TableHeaderRow,
  PagingPanel,
  TableColumnResizing,
  TableRowDetail,
  TableFilterRow,
} from "@devexpress/dx-react-grid-material-ui";
import {
  PagingState,
  IntegratedPaging,
  RowDetailState,
  EditingState,
  FilteringState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";
import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder,
  Action,
} from "@devexpress/dx-react-core";

//Icons
import Cancel from "@material-ui/icons/Close";
import GroupIcon from "@material-ui/icons/Group";
import DeleteIcon from "@material-ui/icons/Delete";
import Badge from "@material-ui/core/Badge";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import AddCustomerComponent from "./AddCustomer";
import { customer } from "reducers/customer.reducer";
import Permission from "../typeDefs/Permission";

import AuthService from "../services/auth/AuthService";
const service = new UserService();

/**
 * Shows a accordion full with user information
 * @constructor
 */
function CustomerListComponent(props) {
  const role = AuthService.getRole();
  let history = useHistory();
  const dispatch = useDispatch();
  const [rows, setRows] = React.useState<CustomerModel[]>([]);
  const [permissions, setPermissions] = useState<Permission[]>();
  const [power_formel_charge, setPower_Formel_charge] = useState<number>(0);
  const [
    power_Preiskurve_charge,
    setPower_Preiskurve_charge,
  ] = useState<number>(0);
  const [power_Spot_charge, setPower_Spot_charge] = useState<number>(0);
  const [Gas_formel_charge, setGas_Formel_charge] = useState<number>(0);
  const [Gas_Preiskurve_charge, setGas_Preiskurve_charge] = useState<number>(0);
  const [Gas_Spot_charge, setGas_Spot_charge] = useState<number>(0);
  const [Power_preiskurve_useVar, setPower_preiskurve_useVar] = useState(false);
  const [Gas_preiskurve_useVar, setGas_preiskurve_useVar] = useState(false);
  const locale = localStorage.locale;
  const [updated, setUpdated] = useState([]);
  const [seletAll, setSelectAll] = useState(false);
  const [deseletAll, setDeSelectAll] = useState(false);
  const [TakingOverFrom, setTakingOverFrom] = useState(false);
  const [SelectedCustomer, setSelectedCustomer] = useState("");
  const [clickRow, setClickRow] = useState<number>(0);
  const [openRows, setOpenRows] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const theme = useTheme();

  // @ts-ignore
  const customersStore = useSelector((state) => state.customer.customers);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#5c5959",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  //gives the timeout function always the actual state
  const updatedRef = useRef(updated);
  updatedRef.current = updated;

  const selectHandler = (e) => {
    e.target.select();
  };

  const messages = {
    LocalizationMessages: {
      rowsPerPage: lang[locale].RowPerPage, // Custom text for Rows per page
    },
  };

  /**
   * Gets customers from api
   */

  useEffect(() => {
    service
      .getCustomers()
      .then((customers) => {
        dispatch(customerActions.updateAll(customers));
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        // console.error(e);
        // dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  }, [updated]);

  /** Gets the customers out of the store */

  useEffect(() => {
    const rows = JSON.parse(JSON.stringify(customersStore));
    rows.length !== 0 && setRows(editCustomers(rows));
  }, [customersStore]);

  useEffect(() => {
    service
      .getPermissions()
      .then((perms) => {
        setPermissions(perms.filter((perm) => perm.role === "USER"));
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  }, []);

  const changePermissions = (id, permission) => {
    if (TakingOverFrom === false && SelectedCustomer === "") {
      // @ts-ignore
      const users = [...rows];

      const rowIndex = users.findIndex((user) => user.id === id);

      let permissions = users[rowIndex].permissions;

      //@ts-ignore
      permissions.includes(permission)
        ? //@ts-ignore
          (permissions = permissions.filter((perm) => perm !== permission))
        : //@ts-ignore
          permissions.push(permission);

      users[rowIndex].permissions = permissions;

      setRows(users);
    } else if (TakingOverFrom === true && SelectedCustomer !== "") {
      // @ts-ignore
      const users = [...rows];

      const rowIndex = users.findIndex((user) => user.id === id);

      let permissions = customersStore?.find((i) => i.name === SelectedCustomer)
        .permissions;

      //@ts-ignore
      permissions.includes(permission)
        ? //@ts-ignore
          (permissions = permissions.filter((perm) => perm !== permission))
        : //@ts-ignore
          permissions.push(permission);

      users[rowIndex].permissions = permissions;

      setRows(users);
    }
  };

  // useEffect(()=>{
  //   if(openRows > 1){
  //     setSelectAll(false);
  //     setDeSelectAll(false);
  //     setTakingOverFrom(false);
  //   }
  // },[openRows])

  /**  React DevExpress Table */

  const columns = [
    { name: "kunde_nr", title: lang[locale].customerId },
    { name: "name", title: "Name" },
    { name: "type", title: lang[locale].type },
    { name: "user", title: " " },
    { name: "delete", title: " " },
  ];

  const [columnWidths, setColumnWidths] = useState([
    { columnName: "kunde_nr", width: 150 },
    { columnName: "name", width: 175 },
    { columnName: "type", width: 150 },
    { columnName: "user", width: 60 },
    { columnName: "delete", width: 60 },
  ]);

  const [pageSizes] = useState([5, 10, 15, 0]);

  const editCustomers = (customers) => {
    if (customers !== []) {
      for (var i in customers) {
        customers[i].type =
          customers[i].type === "F" ||
          customers[i].type === lang[locale].prognosisCustomer
            ? lang[locale].prognosisCustomer
            : customers[i].type === "E" ||
              customers[i].type === lang[locale].endCustomer
            ? lang[locale].endCustomer
            : customers[i].type === "I" ||
              customers[i].type === lang[locale].industrialCustomer
            ? lang[locale].industrialCustomer
            : null;
        customers[i].user = (
          <Button
              startIcon={
                <Badge
                  badgeContent={
                    customers.every(person => person.current_user_count === 0) ? "?" : customers[i].current_user_count
                  }
                  invisible={ !customers.every(person => person.current_user_count === 0) && customers[i].current_user_count === 0}
                  color="primary"
                >
              <GroupIcon fontSize="small"/>
            
              </Badge>
            }
              variant="text"
              component={Link}
              size="small"
             
              to={`/app/users/customers/${customers[i].name}/${customers[i].id}`}
            ></Button>
        );
        customers[i].delete = (
          <Button
            startIcon={
              customers[i].has_orders ? (
                <Tooltip
                  style={{ color: "orange", textAlign: "center" }}
                  title={lang[locale].customerwithpurchazedproduct}
                >
                  <DeleteIcon />
                </Tooltip>
              ) : (
                <Tooltip
                  style={{ color: "green" }}
                  title={lang[locale].customerwithoutpurchazedproduct}
                >
                  <DeleteIcon />
                </Tooltip>
              )
            }
            variant="text"
            size="small"
            value={[customers[i].id, customers[i].name]}
            onClick={openDeleteModal}
          ></Button>
        );
      }
      return customers;
    }
  };

  function handleChange(e) {
    setSelectedCustomer(e.target.value);
  }

  function premissionsNameTranslator(permissionName) {
    if (lang[locale].add === "Hinzufügen") {
      switch (permissionName) {
        case "TRADING":
          return "HANDEL";
        case "VIEW_DETAIL":
          return "DETAIL ANZEIGEN";
        case "MONITORING":
          return "ÜBERWACHUNG";
        case "VIEW_HISTORY":
          return "VERLAUF ANZEIGEN";
        case "VIEW_INTRADAY":
          return "INTRADAY ANZEIGEN";
        case "VIRTUAL_ORDER":
          return "VIRTUELLE KÄUFE";

        default:
          return permissionName;
      }
    }
    return permissionName;
  }

  function openDeleteModal(e) {
    const values = e.currentTarget.value.split(",");
    const id = values[0];
    const name = values[1];
    dispatch(
      modalActions.openModal(DeleteUserOrCustomerComponent, {
        type: "customer",
        id,
        information: "Name: " + name + " ID: " + id,
      })
    );
  }

  /**
   *
   *Event Handelers
   *
   **/

  const changeStatus = (id) => {
    const customers = [...rows];

    // console.log("customers", rows);
    const rowIndex = customers.findIndex((customer) => customer.id === id);

    // console.log("rowIndex", rowIndex);
    let { status } = customers[rowIndex];
    const updateStatus = status === 2 ? 1 : 2;

    customers[rowIndex].status = updateStatus;

    setRows(customers);
  };

  /**  DEVEXPRESS
   *
   * Custom Plugin Expandable Form
   *
   **/

  // console.log("clickrow", clickRow)

  const getRowId = (row) => row.id;

  function refreshDetails() {
    const filtered = rows.filter((row) => row.id === clickRow);
    const charge = filtered.map((item) => item.charge);
    if (charge.length > 0) {
      const powerformel = charge[0].filter(
        (item) => item["pricing_type"] === "FORMEL" && item["commodity"] === 1
      )[0].value;
      setPower_Formel_charge(powerformel);
      const gasformel = charge[0].filter(
        (item) => item["pricing_type"] === "FORMEL" && item["commodity"] === 2
      )[0].value;
      setGas_Formel_charge(gasformel);
      const powerpreis = charge[0].filter(
        (item) =>
          item["pricing_type"] === "PREISKURVE" && item["commodity"] === 1
      )[0].value;
      setPower_Preiskurve_charge(powerpreis);
      const powerPreisUseVar = charge[0].filter(
        (item) =>
          item["pricing_type"] === "PREISKURVE" && item["commodity"] === 1
      )[0].use_var;
      setPower_preiskurve_useVar(powerPreisUseVar);
      const gaspreis = charge[0].filter(
        (item) =>
          item["pricing_type"] === "PREISKURVE" && item["commodity"] === 2
      )[0].value;
      setGas_Preiskurve_charge(gaspreis);
      const gasPreisUseVar = charge[0].filter(
        (item) =>
          item["pricing_type"] === "PREISKURVE" && item["commodity"] === 2
      )[0].use_var;
      setGas_preiskurve_useVar(gasPreisUseVar);
      const powerspot = charge[0].filter(
        (item) => item["pricing_type"] === "SPOT" && item["commodity"] === 1
      )[0].value;
      setPower_Spot_charge(powerspot);
      const gasSpot = charge[0].filter(
        (item) => item["pricing_type"] === "SPOT" && item["commodity"] === 2
      )[0].value;
      setGas_Spot_charge(gasSpot);
    }
  }

  useEffect(() => {
    refreshDetails();
  }, [clickRow]);

  useEffect(() => {
    refreshDetails();
  }, []);

  const DetailContent = ({ row, ...rest }) => {
    const { processValueChange, applyChanges, cancelChanges } = rest;
    return (
      <Grid container spacing={10} className="customerList">
        <Grid item xs={4}>
          <FormGroup>
            <TextField
              onFocus={selectHandler}
              required
              // error={row.prog_count < row.current_count || !row.prog_count}
              margin="normal"
              id="name"
              value={row.name}
              onChange={processValueChange}
              name="name"
              label={lang[locale].newCustomer}
              // InputProps={{
              //   inputComponent: ThousandSeperatorInputComponent,
              // }}
            />
            <TextField
              onFocus={selectHandler}
              required
              error={row.prog_count < row.current_count || !row.prog_count}
              margin="normal"
              id="prog_count"
              value={row.prog_count}
              onChange={processValueChange}
              name="prog_count"
              label={lang[locale].maxProg}
              InputProps={{
                inputComponent: ThousandSeperatorInputComponent,
              }}
            />
            {/* {console.log("row type", row.type)} */}
            {(row.type === lang[locale].prognosisCustomer ||
              lang[locale].industrialCustomer) && (
              <>
                <TextField
                  onFocus={selectHandler}
                  required
                  margin="normal"
                  id="limit_order"
                  value={row.limit_order}
                  onChange={processValueChange}
                  name="limit_order"
                  label={lang[locale].limitOrder}
                  InputProps={{
                    inputComponent: ThousandSeperatorInputComponent,
                  }}
                  disabled={row.type === "Industriekunde"}
                />
                <TextField
                  onFocus={selectHandler}
                  required
                  margin="normal"
                  id="limit_month"
                  value={row.limit_month}
                  onChange={processValueChange}
                  name="limit_month"
                  label={lang[locale].limitMonth}
                  InputProps={{
                    inputComponent: ThousandSeperatorInputComponent,
                  }}
                  disabled={row.type === "Industriekunde"}
                />
                <TextField
                  onFocus={selectHandler}
                  required
                  margin="normal"
                  id="limit_year"
                  value={row.limit_year}
                  onChange={processValueChange}
                  name="limit_year"
                  label={lang[locale].limitYear}
                  InputProps={{
                    inputComponent: ThousandSeperatorInputComponent,
                  }}
                  disabled={row.type === "Industriekunde"}
                />
                <TextField
                  onFocus={selectHandler}
                  required
                  margin="normal"
                  id="expire"
                  value={row.expire?.split("T")[0]}
                  onChange={processValueChange}
                  name="expire"
                  label={lang[locale].expireDate}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  onFocus={selectHandler}
                  required
                  margin="normal"
                  id="user_count"
                  value={row.user_count}
                  onChange={processValueChange}
                  name="user_count"
                  label={lang[locale].userCount}
                  InputProps={{
                    inputComponent: ThousandSeperatorInputComponent,
                  }}
                  disabled={row.type === "Industriekunde"}
                />
                {/* <KeyboardDatePicker
                    helperText=""
                    required
                    variant="inline"
                    disabled={row.type === "Industriekunde"}
                    margin="normal"
                    data-testid={"expire"}
                    error={row.expire === null}
                    value={(row.expire)?.split('T')[0]}
                    autoOk={true}
                    onChange={processValueChange}
                    id="expire"
                    name="expire"
                    label={"Expire Date"}
                /> */}
              </>
            )}
          </FormGroup>
        </Grid>

        <Grid item xs={4}>
          <Grid container>
            {/* <Grid item xs={12}>
              <Typography variant="caption">
                {lang[locale].surcharges}
              </Typography>
            </Grid> */}
            <Grid item xs={12}>
              <FormGroup>
                <Typography style={{ fontSize: "0.9em", marginTop: "1em" }}>
                  {lang[locale].surchargePowerFormel}
                </Typography>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid item xs={4} style={{ marginTop: 0 }}>
                    <TextField
                      onFocus={selectHandler}
                      margin="normal"
                      id="power_Formel"
                      defaultValue={power_formel_charge}
                      value={row.power_Formel}
                      onChange={processValueChange}
                      name="power_Formel"
                      label={`${lang[locale].static}:`}
                      InputProps={{
                        inputComponent: TwoDecimalComponent,
                      }}
                      disabled={row.type === "Industriekunde"}
                    />
                  </Grid>
                </Grid>

                <Typography style={{ fontSize: "0.9em", marginTop: "1em" }}>
                  {lang[locale].surchargePowerPrice}
                </Typography>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid item xs={4} style={{ marginTop: 0 }}>
                    <TextField
                      onFocus={selectHandler}
                      margin="normal"
                      id="power_Preiskurve"
                      defaultValue={power_Preiskurve_charge}
                      value={row.power_Preiskurve}
                      onChange={processValueChange}
                      name="power_Preiskurve"
                      label={`${lang[locale].static}:`}
                      InputProps={{
                        inputComponent: TwoDecimalComponent,
                      }}
                      disabled={row.type === "Industriekunde"}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ margin: "1.4em 0 0 2.5em" }}>
                    <InputLabel style={{ fontSize: "0.74em" }}>
                      {`${lang[locale].dynamic}:`}
                    </InputLabel>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Power_preiskurve_useVar}
                          onChange={(e) =>
                            setPower_preiskurve_useVar(!Power_preiskurve_useVar)
                          }
                          disabled={row.type === "Industriekunde"}
                        />
                      }
                      label="VaR"
                    />
                  </Grid>
                </Grid>

                <Typography style={{ fontSize: "0.9em", marginTop: "1em" }}>
                  {lang[locale].surchargePowerSpot}
                </Typography>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid item xs={4}>
                    <TextField
                      onFocus={selectHandler}
                      margin="normal"
                      id="power_Spot"
                      defaultValue={power_Spot_charge}
                      value={row.power_Spot}
                      onChange={processValueChange}
                      name="power_Spot"
                      label={`${lang[locale].static}:`}
                      InputProps={{
                        inputComponent: TwoDecimalComponent,
                      }}
                      disabled={row.type === "Industriekunde"}
                    />
                  </Grid>
                </Grid>

                <Typography style={{ fontSize: "0.9em", marginTop: "1em" }}>
                  {lang[locale].surchargeGasFormel}
                </Typography>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid item xs={4}>
                    <TextField
                      onFocus={selectHandler}
                      margin="normal"
                      id="gas_Formel"
                      defaultValue={Gas_formel_charge}
                      value={row.gas_Formel}
                      onChange={processValueChange}
                      name="gas_Formel"
                      label={`${lang[locale].static}:`}
                      InputProps={{
                        inputComponent: TwoDecimalComponent,
                      }}
                      disabled={row.type === "Industriekunde"}
                    />
                  </Grid>
                </Grid>

                <Typography style={{ fontSize: "0.9em", marginTop: "1em" }}>
                  {lang[locale].surchargeGasPrice}
                </Typography>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid item xs={4}>
                    <TextField
                      onFocus={selectHandler}
                      margin="normal"
                      id="gas_Preiskurve"
                      defaultValue={Gas_Preiskurve_charge}
                      value={row.gas_Preiskurve}
                      onChange={processValueChange}
                      name="gas_Preiskurve"
                      label={`${lang[locale].static}:`}
                      InputProps={{
                        inputComponent: TwoDecimalComponent,
                      }}
                      disabled={row.type === "Industriekunde"}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ margin: "1.4em 0 0 2.5em" }}>
                    <InputLabel style={{ fontSize: "0.74em" }}>
                    {`${lang[locale].dynamic}:`}
                    </InputLabel>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Gas_preiskurve_useVar}
                          onChange={(e) =>
                            setGas_preiskurve_useVar(!Gas_preiskurve_useVar)
                          }
                          disabled={row.type === "Industriekunde"}
                        />
                      }
                      label="VaR"
                    />
                  </Grid>
                </Grid>
                <Typography style={{ fontSize: "0.9em", marginTop: "1em" }}>
                  {lang[locale].surchargeGasSpot}
                </Typography>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid item xs={4}>
                    <TextField
                      onFocus={selectHandler}
                      margin="normal"
                      id="gas_Spot"
                      defaultValue={Gas_Spot_charge}
                      value={row.gas_Spot}
                      onChange={processValueChange}
                      name="gas_Spot"
                      label={`${lang[locale].static}:`}
                      InputProps={{
                        inputComponent: TwoDecimalComponent,
                      }}
                      disabled={row.type === "Industriekunde"}
                    />
                  </Grid>
                </Grid>
              </FormGroup>
            </Grid>

            <Grid item xs={12} style={{ padding: 25 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={row.status === 2 ? true : false}
                      onChange={() => changeStatus(row.id)}
                      name="status"
                      color="primary"
                      disabled={row.type === "Industriekunde"}
                    />
                  }
                  label={lang[locale].fourEyesPrinciple}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <Grid item xs={12} style={{ padding: 25 }}>
            <Typography variant="caption" style={{fontSize : "1rem"}}>
              {lang[locale].permissions}:
            </Typography>
            <FormControlLabel
              style={{ marginLeft: "2rem" }}
              control={
                <Checkbox
                  checked={seletAll}
                  size="small"
                  onChange={() => {
                    setTakingOverFrom(false);
                    setSelectedCustomer("");
                    setDeSelectAll(false);
                    setSelectAll(true);
                    permissions?.map((perm) => {
                      if (
                        role.permissions.includes(perm.name) &&
                        !row.permissions.includes(perm.name)
                      )
                        changePermissions(row.id, perm.name);
                    });
                  }}
                  name="permission"
                  color="primary"
                  // disabled={!kundePermissions.permissions.includes(perm.name) && !row.role.permissions.includes(perm.name)}
                />
              }
              label={lang[locale].selectAll}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={deseletAll}
                  size="small"
                  onChange={() => {
                    setTakingOverFrom(false);
                    setSelectedCustomer("");
                    setSelectAll(false);
                    setDeSelectAll(true);
                    permissions?.map((perm) => {
                      if (
                        role.permissions.includes(perm.name) &&
                        row.permissions.includes(perm.name)
                      )
                        // !row.permissions.includes(perm.name) &&
                        changePermissions(row.id, perm.name);
                    });
                  }}
                  name="permission"
                  color="primary"
                  // disabled={!kundePermissions.permissions.includes(perm.name) && !row.role.permissions.includes(perm.name)}
                />
              }
              label={lang[locale].deselectAll}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={TakingOverFrom}
                  size="small"
                  onChange={() => {
                    setDeSelectAll(false);
                    setSelectAll(false);
                    setTakingOverFrom(!TakingOverFrom);
                    setSelectedCustomer("");
                    permissions?.map((perm) => {
                      customersStore
                        ?.find((i) => i.name === SelectedCustomer)
                        ["role"].permissions?.includes(perm.name);
                      changePermissions(row.uid, perm.name);
                    });
                  }}
                  name="permission"
                  color="primary"
                  // disabled={!kundePermissions.permissions.includes(perm.name) && !row.role.permissions.includes(perm.name)}
                />
              }
              label={lang[locale].TakingOverFrom}
            />
            {TakingOverFrom ? (
              <Select
                value={SelectedCustomer ? SelectedCustomer : ""}
                onChange={(e) => handleChange(e)}
              >
                {customersStore
                  .filter((i) => i.name !== row.name)
                  .map((user) => (
                    //@ts-ignore
                    <MenuItem
                    disabled={SelectedCustomer === user.name}
                    value={user.name}
                    //@ts-ignore
                      onClick={(e) => { setSelectedCustomer(e);
                      }}
                    >
                      {user.name}
                    </MenuItem>
                  ))}
              </Select>
            ) : null}
          </Grid>
          {permissions?.map((perm) => (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 400 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell width={300}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={row.permissions.includes(perm.name)}
                            checked={
                              TakingOverFrom === true && SelectedCustomer !== ""
                                ? customersStore
                                    ?.find((i) => i.name === SelectedCustomer)
                                    .permissions?.includes(perm.name)
                                : row.permissions.includes(perm.name)
                            }
                            size="small"
                            onClick={() => {
                              setTakingOverFrom(false);
                              setSelectedCustomer("");
                              setDeSelectAll(false);
                            }}
                            onChange={() => {
                              changePermissions(row.id, perm.name);
                            }}
                            name="permission"
                            color="primary"
                            disabled={
                              !role.permissions.includes(perm.name) &&
                              !row.permissions.includes(perm.name)
                            }
                          />
                        }
                        label={premissionsNameTranslator(perm.name)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">{perm.help}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody></TableBody>
              </Table>
            </TableContainer>
            // <Grid item key={perm.name}>

            // </Grid>
          ))}
          <Grid
            container
            spacing={3}
            justify="flex-end"
            style={{ marginTop: "2em" }}
          >
            <Grid item>
              <Button
                disabled={row.prog_count < row.current_count || !row.prog_count}
                // onClick={()=>{applyChanges(); setIsChanged(true)}}
                onClick={applyChanges}
                variant="text"
                color="primary"
              >
                {lang[locale].save}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  cancelChanges();
                  setExpandedRowIds([]);
                }}
                color="secondary"
              >
                {/* <Button onClick={()=>{cancelChanges(); setIsChanged(true)}} color="secondary"> */}
                {lang[locale].cancel}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const styles = (theme) => ({
    toggleCell: {
      textAlign: "center",
      textOverflow: "initial",
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing(1),
    },
    toggleCellButton: {
      verticalAlign: "middle",
      display: "inline-block",
      padding: theme.spacing(1),
    },
  });

  const ToggleCellBase = ({
    style,
    expanded,
    classes,
    onToggle,
    tableColumn,
    tableRow,
    row,
    className,
    ...restProps
  }) => {
    const handleClick = (e) => {
      // if(isChanged){
      //  setOpenRows((prev)=> prev - 1);
      // }
      // if(!isChanged){
      //   setOpenRows((prev)=> prev + 1);
      // }
      // if(openRows === 2){
      //   setOpenRows(0)
      // }
      // console.log("rest props", e)

      if (expandedRowIds.length === 0) {
        const newarray = [...expandedRowIds, row.id];
        //@ts-ignore
        setExpandedRowIds(newarray);
        e.stopPropagation();
        refreshDetails();
        onToggle();
        setSelectedCustomer("");
        setTakingOverFrom(false);
        setSelectAll(false);
        setDeSelectAll(false);
      }
      if (
        expanded &&
        expandedRowIds.length > 0 &&
        expandedRowIds[0] === row.id
      ) {
        // e.stopPropagation();
        // refreshDetails();
        onToggle();
        setExpandedRowIds([]);
      }
      setSelectedCustomer("");
      setTakingOverFrom(false);
      setSelectAll(false);
      setDeSelectAll(false);
    };
    // console.log("permissions", permissions, "customerstore", customersStore, "row", row)
    // console.log("customerstore", customersStore)
    return (
      <TableCell
        className={classNames(classes.toggleCell, className)}
        style={style}
        {...restProps}
      >
        <IconButton className={classes.toggleCellButton} onClick={handleClick}>
          {expanded ? (
            <Cancel />
          ) : !expanded && expandedRowIds.length > 0 ? null : (
            <KeyboardArrowDownIcon />
          )}
        </IconButton>
      </TableCell>
    );
  };

  // @ts-ignore
  const ToggleCell = withStyles(styles, { name: "ToggleCell" })(ToggleCellBase);

  const DetailEditCell = () => (
    <Plugin name="detailEdit">
      <Action
        name="toggleDetailRowExpanded"
        action={(
          { rowId },
          { expandedDetailRowIds },
          { startEditRows, stopEditRows }
        ) => {
          const rowIds = [rowId];
          // console.log("rowIds", rowIds);
          const isCollapsing = expandedDetailRowIds.indexOf(rowId) > -1;
          setClickRow(rowId);
          if (isCollapsing) {
            setTimeout(() => {
              reset(rowIds[0], updatedRef.current);
            }, 2000);
            stopEditRows({ rowIds });
          } else {
            startEditRows({ rowIds });
          }
        }}
      />
      <Template
        name="tableCell"
        // @ts-ignore
        predicate={({ tableRow }) => tableRow.type === TableRowDetail.ROW_TYPE}
      >
        {(params) => (
          <TemplateConnector>
            {(
              { tableColumns, createRowChange, rowChanges },
              {
                changeRow,
                commitChangedRows,
                cancelChangedRows,
                toggleDetailRowExpanded,
              }
            ) => {
              if (
                // @ts-ignore
                tableColumns.indexOf(params.tableColumn) !== 0
              ) {
                return null;
              }
              const {
                // @ts-ignore
                tableRow: { rowId },
              } = params;
              const row = {
                // @ts-ignore
                ...params.tableRow.row,
                ...rowChanges[rowId],
              };

              const processValueChange = ({ target: { name, value } }) => {
                const changeArgs = {
                  rowId,
                  change: createRowChange(
                    row,
                    value !== undefined ? value : 0,
                    name
                  ),
                };
                changeRow(changeArgs);
              };
              // console.log(row);
              const applyChanges = () => {
                toggleDetailRowExpanded({ rowId });
                commitChangedRows({ rowIds: [rowId] });
              };
              const cancelChanges = () => {
                toggleDetailRowExpanded({ rowId });
                cancelChangedRows({ rowIds: [rowId] });
              };

              return (
                <TemplatePlaceholder
                  params={{
                    ...params,
                    row,
                    tableRow: {
                      // @ts-ignore
                      ...params.tableRow,
                      row,
                    },
                    changeRow,
                    processValueChange,
                    applyChanges,
                    cancelChanges,
                  }}
                />
              );
            }}
          </TemplateConnector>
        )}
      </Template>
    </Plugin>
  );

  const DetailCell = ({
    children,
    changeRow,
    editingRowIds,
    addedRows,
    processValueChange,
    applyChanges,
    cancelChanges,
    ...restProps
  }) => {
    const { row } = restProps;

    return (
      // @ts-ignore
      <TableRowDetail.Cell {...restProps}>
        {React.cloneElement(children, {
          row,
          changeRow,
          processValueChange,
          applyChanges,
          cancelChanges,
        })}
      </TableRowDetail.Cell>
    );
  };

  function reset(id, updated) {
    //should just be performed if rows is not updated

    if (updated.includes(id)) {
      let newUpdated = [...updated];
      newUpdated = newUpdated.filter((entry) => entry !== id);
      // @ts-ignore
      setUpdated(newUpdated);
      return;
    }

    //if row was not updated, reset

    const rows = JSON.parse(JSON.stringify(customersStore));
    const previous = rows.filter((obj) => obj.id === id)[0];

    const current = [...rows];
    const rowIndex = current && current.findIndex((row) => row.id === id);

    current[rowIndex] = previous;

    let newUpdated = [...updated];
    newUpdated = newUpdated.filter((user) => user !== id);
    // @ts-ignore
    setUpdated(newUpdated);
    setRows(editCustomers(current));
  }

  const handleSubmit = (id, changedRows) => {
    let customers = [...changedRows];
    let selectedCustomer = customers.filter((row) => row.id === id)[0];
    customers = customers.filter((row) => row.id === id)[0];

    const customerSingle = {
      ...customers,
      // @ts-ignore
      type:
        // @ts-ignore
        customers.type === lang[locale].prognosisCustomer
          ? "F"
          : lang[locale].endCustomer
          ? "E"
          : lang[locale].industrialCustomer
          ? "I"
          : null,
    };

    const customer = {
      // @ts-ignore
      id: customers.id,
      // @ts-ignore
      name: customers.name,
      // @ts-ignore
      prognose_count: customers.prog_count,
      // @ts-ignore
      limit_order: customers.limit_order,
      // @ts-ignore
      limit_year: customers.limit_year,
      // @ts-ignore
      limit_month: customers.limit_month,
      // @ts-ignore
      status: customers.status,
      // @ts-ignore
      user_count: customers.user_count,
      // @ts-ignore
      charge: [
        // @ts-ignore
        {
          use_var: false,
          pricing_type: "FORMEL",
          commodity: 1,
          value:
            selectedCustomer.power_Formel !== undefined
              ? selectedCustomer.power_Formel
              : power_formel_charge,
        },
        // @ts-ignore
        {
          use_var: Power_preiskurve_useVar,
          pricing_type: "PREISKURVE",
          commodity: 1,
          value:
            selectedCustomer.power_Preiskurve !== undefined
              ? selectedCustomer.power_Preiskurve
              : power_Preiskurve_charge,
        },
        {
          use_var: false,
          pricing_type: "SPOT",
          commodity: 1,
          value:
            selectedCustomer.power_Spot !== undefined
              ? selectedCustomer.power_Spot
              : power_Spot_charge,
        },
        {
          use_var: false,
          pricing_type: "FORMEL",
          commodity: 2,
          value:
            selectedCustomer.gas_Formel !== undefined
              ? selectedCustomer.gas_Formel
              : Gas_formel_charge,
        },
        {
          use_var: Gas_preiskurve_useVar,
          pricing_type: "PREISKURVE",
          commodity: 2,
          value:
            selectedCustomer.gas_Preiskurve !== undefined
              ? selectedCustomer.gas_Preiskurve
              : Gas_Preiskurve_charge,
        },
        {
          use_var: false,
          pricing_type: "SPOT",
          commodity: 2,
          value:
            selectedCustomer.gas_Spot !== undefined
              ? selectedCustomer.gas_Spot
              : Gas_Spot_charge,
        },
      ],
      // @ts-ignore
      permissions: TakingOverFrom === true && SelectedCustomer !== "" ? customersStore?.find((i) => i.name === SelectedCustomer).permissions : customers.permissions,
      // @ts-ignore
      expire: customers.expire,
    };

    console.log("customer",customer)

    setExpandedRowIds([]);
    customer &&
      service
        // @ts-ignore
        .updateCustomer(customer)
        .then(() => {
          //To only reset if not updated
          let updateArray = [...updated];
          // @ts-ignore
          updateArray.push(id);
          setUpdated(updateArray);

          dispatch(customerActions.updateSingle(customerSingle));
          dispatch(
            snackbarActions.openSnackbar(
              lang[locale].successCustomerUpdate,
              "success"
            )
          );
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          dispatch(
            snackbarActions.openSnackbar(
              lang[locale].maxForecastCount[0] +
                props.AngebotMaxCount +
                lang[locale].maxForecastCount[1],
              "error"
            )
          );
        });
  };

  const commitChanges = ({ changed }) => {
    const id = Object.keys(changed)[0];
    const changedRows = rows?.map((row) =>
      changed[row.id] ? { ...row, ...changed[row.id] } : row
    );

    //save customer changes in the backend
    setRows(changedRows);
    handleSubmit(Number(id), changedRows);
  };

  /* delete & user colum should not be filterable */

  const FilterCell = (props) => {
    const { column } = props;
    if (column.name === "delete" || column.name === "user") {
      return null;
    }
    return <TableFilterRow.Cell {...props} />;
  };

  /* makes the title row blue */

  const TitleComponent = (props) => {
    return (
      // @ts-ignore
      <TitleComponent
        {...props}
        style={{ color: theme.palette.primary.main }}
      />
    );
  };

  /* sorting*/

  const [sorting, setSorting] = useState([
    { columnName: "kunde_nr", direction: "asc" },
    { columnName: "name", direction: "asc" },
    { columnName: "type", direction: "asc" },
  ]);

  const comparePriority = (a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) return -1;

    if (a.toLowerCase() > b.toLowerCase()) return 1;

    return 0;
  };

  const [integratedSortingColumnExtensions] = useState([
    { columnName: "name", compare: comparePriority },
  ]);

  return (
    <>
      <AddCustomerComponent name={customer.name} coloR={props.coloR} />

      {rows && rows.length > 0 && (
        <Paper>
          <TableGrid rows={rows} columns={columns} getRowId={getRowId} >
            <FilteringState defaultFilters={[]} />
            <IntegratedFiltering />
            <RowDetailState defaultExpandedRowIds={[1]} />
            <EditingState
              defaultEditingRowIds={[1]}
              // @ts-ignore
              onCommitChanges={commitChanges}
            />
            <SortingState
              // @ts-ignore
              sorting={sorting}
              onSortingChange={setSorting}
            />
            <IntegratedSorting
              columnExtensions={integratedSortingColumnExtensions}
            />
            <PagingState defaultCurrentPage={0} defaultPageSize={5} />
            <IntegratedPaging />
            <TB />
            <TableColumnResizing
              columnWidths={columnWidths}
              // @ts-ignore
              onColumnWidthsChange={setColumnWidths}
            />
            <TableHeaderRow
              //@ts-ignore
              TitleComponent={TitleComponent}
              showSortingControls
            />
            <TableFilterRow cellComponent={FilterCell} />
            <TableRowDetail
              contentComponent={DetailContent}
              // @ts-ignore
              cellComponent={DetailCell}
              // @ts-ignore
              toggleCellComponent={ToggleCell}
            />
            <DetailEditCell />
            
            <PagingPanel
            //@ts-ignore
            messages={messages.LocalizationMessages} pageSizes={pageSizes} />
            {/* <CustomPagingPanel /> */}
          </TableGrid>
        </Paper>
      )}
    </>
  );
}

export default CustomerListComponent;
