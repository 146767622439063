export const authConstants = {
    LOGIN_REQUEST: "AUTH_LOGIN_REQUEST",
    LOGIN_FAILURE: "AUTH_LOGIN_FAILURE",
    LOGIN_SUCCESS: "AUTH_LOGIN_SUCCESS",
    NOT_LOGGEDIN: "AUTH_NOT_LOGGEDIN",
    REGISTRATION_REQUEST: "AUTH_REG_REQUEST",
    REGISTRATION_FAILURE: "AUTH_REG_FAILURE",
    REGISTRATION_SUCCESS: "AUTH_REG_SUCCESS",
    LOGOUT: "AUTH_LOGOUT",
    TFA_VALIDATE: "TFA_VALIDATE",
    TFA_ACTIVATE: "TFA_ACTIVATE",
    TFA_INTERNALVALIDATION : "TFA_INTERNALVALIDATION",
    TFA_LEAVEPAGE: "TFA_LEAVEPAGE"
};
