import { config } from "services/helpers/Constants";
import Role from "../../typeDefs/Role";
import ResponseObject from "../../typeDefs/ResponseObject";
import errorCodeTranslator from "../helpers/errorCodeTranslator";

/**
 * Service for the whole Authentication-Process
 */
export default class AuthService {
    public static getRole(): Role {
        return JSON.parse(localStorage.role);
    }
    /**
     * Authenticates the user with username and password
     * @param email Email of the user
     * @param password password of the user
     */
    public async loginUserByEmailAndPassword(
        email: string,
        password: string
    ): Promise<ResponseObject> {
        const res = await fetch(config.url.API_URL + "/api/login", {
            body: JSON.stringify({ email, password }),
            headers: {
                "Content-Type": "application/json",
                Accept: "*",
            },
            method: "POST",
        });

        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Checks cookie and refreshs the session
     */
    public async refreshCookie(): Promise<ResponseObject> {
        const res = await fetch(config.url.API_URL + "/api/refresh");
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Triggers passwordreset with email
     * @param email email for which the passwort link should be created
     */
    public async resetPasswordTrigger(email: string): Promise<Response> {
        const res = await fetch(config.url.API_URL + "/api/password_reset", {
            method: "POST",
            body: JSON.stringify({
                email,
            }),
        });
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Resets the password
     * @param password new password
     * @param key the key (Token)
     */
    public async resetPassword(
        password: string,
        key: string
    ): Promise<Response> {
        const res = await fetch(config.url.API_URL + "/api/password_reset", {
            body: JSON.stringify({
                password,
                token: key,
            }),
            method: "PATCH",
        });
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Resets the password in the profile
     * @param password new password
     */
    public async resetPersonalInformation(params: object): Promise<Response> {
        const res = await fetch(
            config.url.API_URL + "/api/personal/information",
            {
                method: "PATCH",
                body: JSON.stringify(params),
            }
        );
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Logs the user out and deletes cooke serverside
     */
    public async logout(): Promise<Response> {
        const res = await fetch(config.url.API_URL + "/api/logout");
        const json = await res.json();
        if (res.status === 401) {
            return json;
        }
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    public async startTwoFA(): Promise<string> {
        const res = await fetch(config.url.API_URL + "/api/2fa/start");
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json.message;
    }

    public async activateTwoFA(active: boolean, pin: string): Promise<string> {
        const res = await fetch(config.url.API_URL + "/api/2fa/activate", {
            method: "POST",
            body: JSON.stringify({ active, tfa: pin }),
        });
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json.message;
    }

    public async validateTwoFA(tfa: string): Promise<ResponseObject> {
        const res = await fetch(config.url.API_URL + "/api/2fa/validate", {
            method: "POST",
            body: JSON.stringify({
                tfa,
            }),
        });
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    public async checkLogin(): Promise<Response> {
        const res = await fetch(config.url.API_URL + "/api/cookie");
        if (res.status !== 200) {
            const json = await res.json();
            throw { message: json.message };
            /*  throw { message: errorCodeTranslator(json.message) }; */
        }
        return res;
    }
}
