import { useDispatch } from "react-redux";
import { useSelector } from "../services/helpers/useSelector";
import forecastActions from "../actions/forecast.actions";
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import { lang } from "../lang";
import React from "react";

/**
 * is for selecting the usage type
 * @constructor
 */
export function SelectUsageType() {
    const dispatch = useDispatch();
    const usageType = useSelector((state) => state.forecast.data.usageType);
    //saves usage type in redux state
    function handleChange(e) {
        dispatch(
            forecastActions.saveForecastSingle("usageType", e.target.value)
        );
    }

    const locale = localStorage.locale;
    return (
        <Grid item xs={6}>
            <FormControl fullWidth={true} required error={usageType === 0}>
                <InputLabel id="usageType">{lang[locale].usageType}</InputLabel>
                <Select
                    labelId="usageType"
                    id="usageTypeSelect"
                    value={usageType ? usageType : ''}
                    onChange={handleChange}
                >
                    {/* <MenuItem value={0}>{lang[locale].notSelected}</MenuItem> */}
                    <MenuItem value={1}>{lang[locale].measured}</MenuItem>
                    <MenuItem value={2}>{lang[locale].specific}</MenuItem>
                </Select>
            </FormControl>
        </Grid>
    );
}
