import React from "react";
import { Card, CardContent, Typography, Grid, Button,Tooltip as MTooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { lang } from "../lang";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import modalActions from "../actions/modal.actions";
import BuyLoadcurveComponent from "./BuyLoadcurve";
import AuthService from "../services/auth/AuthService";
// import authActions from "../actions/auth.actions";

const useStyles = makeStyles((theme) => ({
    noBold: {
        fontWeight: 100,
    },
}));

/**
 * Shows recent price and the amount of the loadcurve
 * Also will be responsible for buying energy
 * Is used in detailed view
 * @param recentPrice the recent price to show
 * @param amount the energy amount
 * @constructor
 */
function ShowPriceAndBuyComponent({ recentPrice, amount, zrid, disabled, name }) {
    const classes = useStyles();
    const locale = localStorage.locale;
    const role = AuthService.getRole();
    const dispatch = useDispatch();

    function handleClick() {
        dispatch(
            modalActions.openModal(BuyLoadcurveComponent, {
                zrid,
                recentPrice,
                amount,
                name,
            })
        );
    }

    return (
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid container justify="space-around" alignItems="center">
                        <Grid item>
                            <Typography variant="caption">
                                <b>{lang[locale].recentPrice}</b>
                            </Typography>
                            <Typography variant="h4" className={classes.noBold}>
                                <NumberFormat
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    value={recentPrice > 0 ? recentPrice : null}
                                    displayType={"text"}
                                />{" "}
                                {recentPrice > 0 ? "€/MWh" : "n/a"}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                <b>{lang[locale].amount}</b>
                            </Typography>
                            <Typography variant="h4" className={classes.noBold}>
                                <NumberFormat
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    value={amount}
                                    displayType={"text"}
                                    decimalScale={3}
                                    fixedDecimalScale={true}
                                />{" "}
                                MWh
                            </Typography>
                        </Grid>
                        {/* {role.simple_role !== "LIEFERANT" && (
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={
                                        !role.permissions.includes("TRADING") ||
                                        disabled ||
                                        recentPrice <= 0
                                    }
                                    onClick={handleClick}
                                >
                                    {lang[locale].buy}
                                </Button>
                            </Grid>
                        )} */}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
}

export default ShowPriceAndBuyComponent;
