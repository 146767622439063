import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { lang } from "../lang";
import { useSelector } from "../services/helpers/useSelector";
import priceActions from "../actions/price.actions";
import { useDispatch } from "react-redux";
import TwoDecimalComponent from "./TwoDecimal";
import { modal } from "reducers/modal.reducer";
import modalActions from "actions/modal.actions";
import loadcurveActions from "../actions/loadcurve.actions";
import AuthService from "../services/auth/AuthService";
import UserService from "../services/users/UserService";
import customerActions from "actions/customer.actions";


const service = new UserService();
/**
 * Component for setting price bound
 * @param zrid
 * @constructor
 */
function SetPriceBoundsComponent({ zrid, setUpperLimit, setLowerLimit }) {
  const dispatch = useDispatch();
  const locale = localStorage.locale;
  const [lowerLocal, setLowerLocal] = useState("");
  const [upperLocal, setUpperLocal] = useState("");

  const state = useSelector((state) => state.price);
  // @ts-ignore
  // const customer_id = useSelector((state) => state.customer.customerData.id);
  const role = AuthService.getRole();

  //saves bounds to state and api
  function handleClick(e) {
    dispatch(priceActions.togglePriceBound(zrid, e.target.checked));
    dispatch(
      priceActions.setPriceBounds(
        zrid,
        parseFloat(upperLocal),
        parseFloat(lowerLocal)
      )
      );
  }
  //Use effect for getting the lower and upper on first
  //mount
  useEffect(() => {
    dispatch(priceActions.getPriceBounds(zrid));
  }, []);
  /**
   * Useeffect for changing the local
   * values after initial getting
   */
  useEffect(() => {
    if (state.active) {
      setUpperLocal(state.upper);
      setLowerLocal(state.lower);
    }
  }, [state.upper, state.lower]);

  useEffect(() => {
    if (!state.active) {
      setUpperLocal("");
      setLowerLocal("");
    }
  }, [state.active]);

  const helperTextHandle = (upperLocal, lowerLocal) => {
    if (upperLocal < lowerLocal) {
      return `${lang[locale].upperBound.split("[")[0]} < ${
        lang[locale].lowerBound.split("[")[0]
      }`;
    }
    if (upperLocal === lowerLocal) {
      return `${lang[locale].upperBound.split("[")[0]} = ${
        lang[locale].lowerBound.split("[")[0]
      }`;
    }
    if (upperLocal === 0 || lowerLocal === 0) {
      return `${lang[locale].upperBound.split("[")[0]} / ${
        lang[locale].lowerBound.split("[")[0]
      } = 0`;
    }
  };

  useEffect(() => {
        dispatch(loadcurveActions.resetState());
        // dispatch(loadcurveActions.getLoadcurves(customer_id));
        /* Update the customer object or the customers to have the new current count of the prognosis counter */
        role.simple_role !== "LIEFERANT"
            ? service.getCustomer().then((res) => {
                  dispatch(customerActions.update(res));
              })
            : service.getCustomers().then((customers) => {
                  dispatch(customerActions.updateAll(customers));
              });
        // handleClose();
}, [state.active]);

  return (
    <Grid container spacing={4} style={{ position: "relative" }}>
      <Grid item>
        <TextField
          disabled={state.active}
          value={lowerLocal}
          id="lower"
          label={lang[locale].lowerBound}
          /*  type="number" */
          InputProps={{
            inputComponent: TwoDecimalComponent,
          }}
          onChange={(e) => {
            setLowerLocal(e.target.value);
            setLowerLimit(Number(e.target.value))
          }}
          helperText={helperTextHandle(upperLocal, lowerLocal)}
          error={
            upperLocal < lowerLocal ||
            upperLocal === lowerLocal ||
            !upperLocal ||
            !lowerLocal ||
            parseFloat(upperLocal) < 0 ||
            parseFloat(lowerLocal) < 0
          }
        />
      </Grid>
      <Grid item>
        <TextField
          disabled={state.active}
          value={upperLocal}
          id="upper"
          /* type="number" */
          label={lang[locale].upperBound}
          InputProps={{
            inputComponent: TwoDecimalComponent,
          }}
          onChange={(e) => {
            setUpperLocal(e.target.value);
            setUpperLimit(Number(e.target.value))
          }}
          helperText={helperTextHandle(upperLocal, lowerLocal)}
          error={
            upperLocal < lowerLocal ||
            upperLocal === lowerLocal ||
            !upperLocal ||
            !lowerLocal ||
            parseFloat(upperLocal) < 0 ||
            parseFloat(lowerLocal) < 0
          }
        />
      </Grid>

      <Grid item >
        <FormControl>
          <FormControlLabel
            style={{ marginTop: 16 }}
            control={
              <Checkbox
                disabled={
                  upperLocal < lowerLocal ||
                  upperLocal === lowerLocal ||
                  !upperLocal ||
                  !lowerLocal ||
                  parseFloat(upperLocal) < 0 ||
                  parseFloat(lowerLocal) < 0
                }
                checked={state.active}
                data-testid="active"
                name="active"
                color="primary"
                onClick={handleClick}
              />
            }
            label={lang[locale].setBounds}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default SetPriceBoundsComponent;
