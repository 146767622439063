import { constantsConstants } from "constants/constants.constants";

function get() {
    return { type: constantsConstants.GET_CONSTANTS };
}

function update(constantsData) {

    return { type: constantsConstants.UPDATE_CONSTANTS, constantsData };
}

const constantsActions = {
    get,
    update,
};

export default constantsActions;
