import { combineReducers } from "redux";
import { auth } from "reducers/auth.reducer";
import { snack } from "reducers/snackbar.reducer";
import { loadcurve } from "reducers/loadcurve.reducer";
import { modal } from "reducers/modal.reducer";
import { forecast } from "reducers/forecast.reducer";
import { price } from "reducers/price.reducer";
import { interval } from "reducers/interval.reducer";
import { pricecurveshower } from "./pricecurveshower.reducer";
import { user } from "./user.reducer";
import { customer } from "./customer.reducer";
import { constants } from "./constants.reducer";
import { users } from "./users.reducer";
import { ciSettings } from "reducers/ciSettings.reducer"; 

const rootReducer = combineReducers({
    auth,
    snack,
    loadcurve,
    modal,
    forecast,
    price,
    interval,
    pricecurveshower,
    user,
    customer,
    constants,
    users,
    ciSettings,
});

export default rootReducer;
