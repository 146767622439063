import React from "react";
import { CssBaseline } from "@material-ui/core";

//Hooks
import { Redirect } from "react-router";
import { useSelector } from "services/helpers/useSelector";

//Components
import LoginFormComponent from "./LoginForm";
import TfaFormComponent from "./TfaForm";

interface LoginComponentProps {
    locale: string;
}

/**
 * Component which has the form for logging the user in
*/
function LoginComponent(props: LoginComponentProps) {

    const loggedIn = useSelector((state) => state.auth.loggedIn);
    const active = useSelector((state) => state.auth.active);
    const validated = useSelector((state) => state.auth.validated);

    const checkRedirect = () => {
        return (loggedIn && !active) || (loggedIn && active && validated);
    };

    return (
        <>
            <CssBaseline />
            {checkRedirect() && <Redirect to="/app/pricedevelopment" />}
            {!loggedIn && <LoginFormComponent {...props} />}
            {loggedIn && active && !validated && (
                <TfaFormComponent {...props} />
            )}
        </>
    );
}

export default LoginComponent;
