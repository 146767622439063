import { useSelector } from "../services/helpers/useSelector";
import { useDispatch } from "react-redux";
import forecastActions from "../actions/forecast.actions";
import {
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import { lang } from "../lang";
import ProfileType from "../typeDefs/ProfileType";
import React from "react";

/**
 * Component to select the profile type if needed
 * @constructor
 */
export function SelectProfileTypeComponent() {
    const locale = localStorage.locale;

    const profileTypes = useSelector((state) => state.forecast.profileTypes);
    const profile = useSelector((state) => state.forecast.data.profile);

    const dispatch = useDispatch();

    /**
     * Changes profile in state
     * @param e
     */
    function handleChange(e) {
        dispatch(forecastActions.saveForecastSingle("profile", e.target.value));
        dispatch(forecastActions.saveForecastSingle("temperatureStation", ""));
        dispatch(forecastActions.saveForecastSingle("usage", 0));
        dispatch(forecastActions.saveForecastSingle("usageType", 0));

    }

    return (
        <>
            {profileTypes ? (
                <FormControl fullWidth={true} required error={profile === 0}>
                    <InputLabel id="profile">
                        {lang[locale].profileType}
                    </InputLabel>
                    <Select
                        labelId="profile"
                        id="profileSelect"
                        inputProps={{ "data-testid": "profileSelect" }}
                        value={profile ? profile : ''}
                        onChange={handleChange}
                    >
                        {/* <MenuItem value={0}>
                            {lang[locale].notSelected}
                        </MenuItem> */}
                        {profileTypes.map((profile: ProfileType) => (
                            <MenuItem
                                data-testid="profile"
                                key={profile.id}
                                value={profile.id ? profile.id : ''}
                            >
                                {profile.bez}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                <CircularProgress />
            )}
        </>
    );
}
