import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import PFM_Beispiel from "./../img/PFM_Beispiel.jpg";
import BlockIcon from "@mui/icons-material/Block";
import _ from "lodash";
import { differenceInDays, add, subDays } from "date-fns";

import {
  Box,
  TextField,
  MenuItem,
  Select,
  Typography,
  FormControl,
  Button,
  Grid,
  Tooltip as MTooltip,
} from "@material-ui/core";
import {
  Area,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Brush,
  ComposedChart,
  Cell,
  Scatter,
  Dot,
  LineChart
} from "recharts";
import StepLineChart from "./StepLineChart";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from '@mui/icons-material/Settings';

import { Pie, PieChart } from "recharts";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import DeletePortfolioComponent from "./DeletePortfolio";

//Actions
import authActions from "../actions/auth.actions";
import modalActions from "actions/modal.actions";
import customerActions from "actions/customer.actions";
import snackbarActions from "actions/snackbar.actions";

//services
import UserService from "../services/users/UserService";
import LoadcurveService from "services/loadcurves/LoadcurveService";
import PortfolioService from "./../services/portfolios/PortfolioService";

import CardAddButtonComponent from "./CardAddButton";
import AddPortfolioPage from "./AddPortfolioPage";
import { useSelector } from "../services/helpers/useSelector";

import { lang } from "../lang";
import EditPortfolio from "./EditPortfolio";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const service = new UserService();
const PFMservive = new PortfolioService();
const LoadCurveService = new LoadcurveService();
const options: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

function PfmComponent(props, role) {
  const localized = localStorage.locale;
  const showLocalLang = lang[localized].add === "Add" ? "en-EN" : "de-DE";
  let history = useHistory();
  const classes = useStyles();
  const { customers } = useSelector((state) => state.customer);
  const sortedCustomers = _.orderBy(
    customers,
    [(cust) => cust.name?.toLowerCase()],
    ["asc"]
  );
  // const { customer_id } = useParams<{ customer_id?: string | undefined }>();
  const [ezkShow, setEzkShow] = useState(true);
  const [smalKShow, setSmalKShow] = useState(true);
  const [OPShow, setOPShow] = useState(true);
  const [KpOPShow, setKpOPShow] = useState(true);
  const [customerName, setCustomerName] = useState<number | string>("");
  const [PortfolioName, setPortfolioName] = useState<number | string>("");
  const [portfolios, setPortfolios] = useState<any>([]);
  const [pfmStart, setPfmStart] = useState<any>(undefined); 
  const [pfmEnd, setPfmEnd] = useState<any>(undefined); 
  const [deliveryStart, setDeliveryStart] = useState<any>(undefined); 
  const [deliveryEnd, setDeliveryEnd] = useState<any>(undefined); 
  const [portfolioDetails, setPortfolioDetails] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState<any>(0);
  const [selectedToDelete, setSelectedToDelete] = useState<any>(0);
  const [showChart, setShowChart] = useState(false);
  const [commodity, setCommodity] = useState<any>(0);
  const [currentAmount, setCurrentAmount] = useState("");
  const [portfolioEditZrIds, setPortfolioEditZrIds] = useState<any>([]);
  const [uniqueIds, setUniqueIds] = useState<any>([]);
  const [greenArrayStep, setGreenArrayStep] = useState<any>([]);
  const [portfolioEditZrIdspass, setPortfolioEditZrIdspass] = useState<any>([]);
  const locale = localStorage.locale;
  const [DaysDifference,setDaysDifference] = useState(1);
  const [actuellPreis, setActuellpreis] = useState<any>([]);
  const [UniqZridWithAmount, setUniqZridWithAmount] = useState<any>([]);
  const [TotalAmountZrid, setTotalAmountZrid] = useState<any>([]);


  const calculateDaysBetween = (startDate, endDate) => {
    const newDaysList = [];

    // Copy the start date to avoid modifying the original date
    let currentDate = new Date(startDate);

    // Iterate through each day until reaching the end date
    while (currentDate <= endDate) {
      //@ts-ignore
      newDaysList.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return(newDaysList);
  };



  const dispatch = useDispatch();
  //@ts-ignore
  const customer = useSelector((state) => state.customer["customerData"]?.id);

  const showColors = ["#C8D401", "#0583B1"];

  const portolioCopy = [...portfolioDetails];

    // Function to generate an array of numbers from 0 to the specified number
    const generateNumberArray = (num) => {
      return Array.from({ length: num + 1}, (_, index) => index * (100 / DaysDifference));
    };
  
    // Use the function to generate an array based on the provided number
    const resultArray = generateNumberArray(DaysDifference);


  const toggleHandler = (e) => {
    if (e.dataKey === "einzelDP") {
      setEzkShow(!ezkShow);
    }
    if (e.dataKey === "SumAllDP") {
      setSmalKShow(!smalKShow);
    }
    if (e.dataKey === "opDprice") {
      setOPShow(!OPShow);
    }
    if (e.dataKey === "KplusOPDprice") {
      setKpOPShow(!KpOPShow);
    }
  };

  const sumValuesWithPrevious = (array) => {
    let sumAmount = 0;
    let sumKosten = 0;
    let sumPrice = 0;
    let beschaffung = 0;

    return array
      .filter((i) => i.zrid === i.zrid_ref)
      .map((item, index) => {
        sumAmount += item.amount / 1000;
        sumKosten += item.price * (item.amount / 1000);
        sumPrice = item.price;
        beschaffung = 100 * (item.amount / 1000 / totalAmount);

        return {
          ...item,
          amount: sumAmount,
          price: sumPrice,
          kosten: sumKosten,
          beschaffung: beschaffung,
          dprice: sumKosten / sumAmount,
        };
      });
  };

  const calculateDaysDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the difference in milliseconds
    //@ts-ignore
    const differenceInMilliseconds = end - start;

    // Convert milliseconds to days
    const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    // Update the state with the calculated difference
    setDaysDifference(differenceInDays);
  };


  useEffect(() => {
    props.changeNavTitle(lang[locale].portfolioManagement);
    document.title = lang[locale].portfolioManagement + " | EMDACS";
  }, []);

  useEffect(() => {
    dispatch(authActions.checkLogin());
  }, []);

  useEffect(() => {
    // customer_id && setCustomerId(Number(customer_id));
    props.role.simple_role === "LIEFERANT" &&
      service
        .getCustomers()
        .then((res) => {
          dispatch(customerActions.updateAll(res));
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          // console.error(e);
          // dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
  }, []);

  const getallportfilios = (e) => {
    setCustomerName(e.target.value);
  };
  const getallportfilioDetails = (e) => {
    setPortfolioName(e.target.value);
  };

  useEffect(() => {
    if (PortfolioName === "" || portfolios.length === 0) {
      setShowChart(false);
    } else setShowChart(true);
  }, [PortfolioName]);

  const [zoomLevel, setZoomLevel] = useState(1);

  useEffect(() => {
    const handleZoomChange = () => {
      const zoomLevel = window.devicePixelRatio;
      setZoomLevel(zoomLevel); // Adjust the threshold as needed (e.g., 1.5 for 50% zoom)
    };

    window.addEventListener('resize', handleZoomChange);
    return () => {
      window.removeEventListener('resize', handleZoomChange);
    };
  }, []);


  // const checkDisable = () =>{
  //   if(customerName === 0 || undefined) return true
  // }

  const getAllPortfolio = () => {
    if (props.role.simple_role === "LIEFERANT") {
      PFMservive.getAllPortfolios(Number(customerName))
        .then((res) => {
          setPortfolios(res);
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          // dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    } else if (props.role.simple_role === "USER") {
      //@ts-ignore
      PFMservive.getAllPortfolios(Number(customer))
        .then((res) => {
          setPortfolios(res);
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          // dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
  };

  const portfoDetailHandler = (id) => {
    setSelectedToDelete(id);

    if (props.role.simple_role === "LIEFERANT") {
      PFMservive.getPortfolioDetails(Number(customerName), id)
        .then((res) => {
          //@ts-ignore
          setPfmStart(res.portfolio_from);
          //@ts-ignore
          setPfmEnd(res.portfolio_to);
          //@ts-ignore
          setDeliveryStart(res.delivery_start);
          //@ts-ignore
          setDeliveryEnd(res.delivery_end);
          //@ts-ignore
          calculateDaysDifference(res.portfolio_from, res.portfolio_to)
          //@ts-ignore
          // calculateDaysBetween(res.portfolio_from, res.portfolio_to)
          // console.log("calculateDaysBetween(res.portfolio_from, res.portfolio_to)", Date.now(),calculateDaysBetween(res.portfolio_from, new Date(res.portfolio_to).getTime()))
          //@ts-ignore
          if(Date.now() <= new Date(res.portfolio_to).getTime()){
            
            //@ts-ignore
            setGreenArrayStep(calculateDaysBetween(res.portfolio_from, Date.now()))
          }
          //@ts-ignore
          if(Date.now() > new Date(res.portfolio_to).getTime()){
            //@ts-ignore
            setGreenArrayStep(calculateDaysBetween(res.portfolio_from, new Date(res.portfolio_to).getTime()))
          }
          setCommodity(res["commodity"]);
          setPortfolioDetails(res["data"]);
          setCurrentAmount(res["current_amount"]);
          setPortfolioEditZrIds(
            Object.entries(
              res["portfolio_header_data"]
            ).map(([ZrKauf, info]) => ({ ZrKauf, info }))
          );
          setTotalAmountZrid(res["portfolio_header_data"]);
          setTotalAmount(res["total_amount"] / 1000);
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    } else {
      //@ts-ignore
      PFMservive.getPortfolioDetails(Number(customer), id)
        .then((res) => {
          //@ts-ignore
          setPfmStart(res.portfolio_from);
          //@ts-ignore
          setPfmEnd(res.portfolio_to);
          //@ts-ignore
          setDeliveryStart(res.delivery_start);
          //@ts-ignore
          setDeliveryEnd(res.delivery_end);
          //@ts-ignore 
          calculateDaysDifference(res.portfolio_from, res.portfolio_to)
          //@ts-ignore
          if(Date.now() <= new Date(res.portfolio_to).getTime()){
            //@ts-ignore
            setGreenArrayStep(calculateDaysBetween(res.portfolio_from, Date.now()))
          }
          //@ts-ignore
          if(Date.now() > new Date(res.portfolio_to).getTime()){
            //@ts-ignore
            setGreenArrayStep(calculateDaysBetween(res.portfolio_from, new Date(res.portfolio_to).getTime()))
          }  
          setCommodity(res["commodity"]);
          setPortfolioDetails(res["data"]);
          setCurrentAmount(res["current_amount"]);
          setPortfolioEditZrIds(
            Object.entries(
              res["portfolio_header_data"]
            ).map(([ZrKauf, info]) => ({ ZrKauf, info }))
          );
          setTotalAmountZrid(res["portfolio_header_data"]);
          setTotalAmount(res["total_amount"] / 1000);
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
      }
  };

  useEffect(()=>{
    const existed = [];
    const UniqZridWithAmountTemp = Object.values(portfolioDetails.filter(i=> i.amount > 0 ).reduce((acc, obj)=>{
      if(!acc[obj.zrid] || acc[obj.zrid].current_amount < obj.current_amount){
        acc[obj.zrid] = obj;
        //@ts-ignore
        existed.push(obj.zrid);
      }
      return acc;
    },{}));
    //@ts-ignore
    const UniqZridWithOutAmountTemp = Object.values(portfolioDetails.filter(i=> existed.indexOf(Number(i.zrid)) === -1).reduce((acc, obj)=>{
      if(!acc[obj.zrid]){
        acc[obj.zrid] = obj;
      }
      return acc;
    },{}));
    //@ts-ignore
    const uniqueIdsTempo = Array.from(new Set(portfolioDetails.map(obj => obj.zrid)));
    setUniqueIds(uniqueIdsTempo)
    setUniqZridWithAmount([...UniqZridWithAmountTemp, ...UniqZridWithOutAmountTemp]);
  },[portfolioDetails])
  

           
useEffect(() => {
    if (UniqZridWithAmount) {
      //@ts-ignore
        Promise.all(uniqueIds.map((item) => LoadCurveService.getLoadCurveLatestHistory(item)))
            .then((actuells) => setActuellpreis(actuells))
            .catch((error) => console.log(error));
    }
}, [UniqZridWithAmount]);

// console.log("actuellPreis",actuellPreis)
  function ArrayCombiner(){
    const combinedArray = [...UniqZridWithAmount, ...actuellPreis];

// Group objects by their IDs
const groupedObjects = combinedArray.reduce((acc, obj) => {
    const id = obj.zrid;
    if (!acc[id]) {
        acc[id] = { id };
    }
    Object.assign(acc[id], obj, {totalAmountPfm : totalAmount * 1000}, {TotalAmountZriD : portfolioEditZrIds.filter(i => {
      if(Number(i.ZrKauf) === id){
        return i.info.full_amount
      };
    })});
    return acc;
}, {});

// Convert grouped objects back into an array
const mergedArray = Object.values(groupedObjects);

return mergedArray;
  }


  const sumValuesWithPreviousOffenMitaktuellen = (array) => {
    //@ts-ignore
    
    let sumAmount = 0;
    let sumKosten = 0;
    let sumPrice = 0;
    let beschaffung = 0;
    
    return array
    .map((item, index) => {
      //@ts-ignore
      if( item.TotalAmountZriD && item.TotalAmountZriD.length > 0){
          sumAmount += (item.TotalAmountZriD[0]["info"]["full_amount"] - item.current_amount) / 1000;
          sumKosten += (item.op_price + item.aufschlag) * ((item.TotalAmountZriD[0]["info"]["full_amount"] / 1000) - (item.current_amount / 1000));
          sumPrice = (item.op_price + item.aufschlag);
          beschaffung = 100 * (item.current_amount / 1000 / totalAmount);
  
          return {
            ...item,
            OPamount: sumAmount,
            price: sumPrice,
            kosten: sumKosten,
            beschaffung: beschaffung,
            dprice: sumKosten / sumAmount,
          };
        }
        });
  };

  // console.log("portfolioDetails",portfolioDetails)
  // console.log("sumValuesWithPreviousOffenMitaktuellen",sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner()))
  // console.log("array combiner",ArrayCombiner())
  // console.log("actuellPreis",actuellPreis)
  // console.log("unig",UniqZridWithAmount)

  function compare(arr1, arr2) {
    let matchingArray = [];

    for (let obj1 of arr1) {
      for (let obj2 of arr2) {
        if (obj1.zrid === Number(obj2.ZrKauf)) {
          // if(obj1.true_order === true){
          //@ts-ignore
          matchingArray.push({
            //@ts-ignore
            ...obj1, mengeOp: obj2["info"].full_amount / 1000 - obj1.current_amount / 1000, KostenOp: (obj2["info"].full_amount / 1000 - obj1.current_amount / 1000) * obj1.op_price, });

          // }
          // else{
          //   //@ts-ignore
          //   matchingArray.push({

          //     //@ts-ignore
          //     ...obj1, mengeOp:obj2["info"].full_amount / 1000 , KostenOp: (obj2["info"].full_amount / 1000 * obj1.price)
          //   });
          // }
        }
      }
    }

    return matchingArray;
  }

  const Compared = compare(portfolioDetails, portfolioEditZrIds);

  const reducedObjectOP = Object.entries(
    Compared.reduce((accumulator, currentValue) => {
      const { KostenOp, order_date, zrid_ref } = currentValue;
      //@ts-ignore
      if (!accumulator[order_date + zrid_ref]) {
        accumulator[order_date + zrid_ref] = KostenOp;
        //@ts-ignore
      } else {
        //@ts-ignore
        accumulator[order_date + zrid_ref] += KostenOp;
      }

      return accumulator;
    }, {})
  );

  const reducedObjectMenge = Object.entries(
    Compared.reduce((accumulator, currentValue) => {
      const { mengeOp, order_date, zrid_ref } = currentValue;
      //@ts-ignore
      if (!accumulator[order_date + zrid_ref]) {
        accumulator[order_date + zrid_ref] = mengeOp;
        //@ts-ignore
      } else if (accumulator[order_date + zrid_ref]) {
        //@ts-ignore
        accumulator[order_date + zrid_ref] += mengeOp;
      }

      return accumulator;
    }, {})
  );

  const mergeOP = (array1, array2) => {
    const mergedArray = [];

    for (let element1 of array1) {
      for (let element2 of array2) {
        if (element1[0] === element2[0]) {
          const mergedElement = element1.concat(element2.slice(1));
          //@ts-ignore
          mergedArray.push(mergedElement);
        }
      }
    }

    return mergedArray;
  };

  const offenePosition = mergeOP(reducedObjectMenge, reducedObjectOP);
  const summeAlleKauf = sumValuesWithPrevious(portolioCopy);

  const mergeOPPlusKauf = (arr1, arr2) => {
    let matchingArray = [];

    for (let obj1 of arr1) {
      for (let obj2 of arr2) {
        if (obj1[0] === obj2["order_date"] + obj2.zrid_ref) {
          //@ts-ignore
          matchingArray.push({ OPKauf: obj1[2] + obj2.kosten });
          // break; // Assuming each ID is unique, exit the inner loop once a match is found
        }
      }
    }

    return matchingArray;
  };

  useEffect(() => {
    if (typeof customerName === "string" || customer === undefined) {
      setPortfolioName("");
      setShowChart(false);
    }
    if (PortfolioName === "" || portfolios.length === 0) {
      setShowChart(false);
    } else setShowChart(true);
    getAllPortfolio();
  }, [customerName, customer]);

  const portfolioDeleteHandler = (id) => {
    // console.log("e",e.target.value)
    props.role.simple_role === "LIEFERANT"
      ? dispatch(
          //@ts-ignore
          modalActions.openModal(
            DeletePortfolioComponent,
            {
              customerName,
              id,
              getAllPortfolio,
              PortfolioName,
            },
            4
          )
        )
      : dispatch(
          //@ts-ignore
          modalActions.openModal(
            DeletePortfolioComponent,
            {
              customer,
              id,
              getAllPortfolio,
              PortfolioName,
            },
            4
          )
        );

    // e.stopImmediatePropagation();
  };
  //opens the modal with the right component
  function handleClick(customerName, body: React.FunctionComponent) {
    props.role.simple_role === "LIEFERANT"
      ? dispatch(
          modalActions.openModal(
            body,
            {
              id: customerName,
              getAllPortfolio: getAllPortfolio,
            },
            5
          )
        )
      : dispatch(
          modalActions.openModal(
            body,
            {
              id: customer,
              getAllPortfolio: getAllPortfolio,
            },
            5
          )
        );
  }

  function handleEditPortfolio(id) {
    props.role.simple_role === "LIEFERANT"
      ? dispatch(
          //@ts-ignore
          modalActions.openModal(
            EditPortfolio,
            {
              customerName,
              selectedToDelete,
              getAllPortfolio,
              PortfolioName,
              portfolioEditZrIds,
              portfoDetailHandler,
              role: "LIEFERANT",
              commodity,
            },
            4
          )
        )
      : props.role.simple_role === "USER" &&
        dispatch(
          //@ts-ignore
          modalActions.openModal(
            EditPortfolio,
            {
              customer,
              selectedToDelete,
              getAllPortfolio,
              PortfolioName,
              portfolioEditZrIds,
              portfoDetailHandler,
              role: "USER",
              commodity,
            },
            4
          )
        );
  }

  const portfolioBeschaffungAmount =
    totalAmount &&
    100 *
      (sumValuesWithPrevious(portolioCopy)[
        sumValuesWithPrevious(portolioCopy).length - 1
      ]?.amount /
        totalAmount);

  const data01 = [
    {
      name: `${lang[locale].procured} [%]`,
      value: Number(portfolioBeschaffungAmount.toFixed(2)),
    },
    {
      name: `${lang[locale].openPosition} [%]`,
      value: Number((100 - portfolioBeschaffungAmount).toFixed(2)),
    },
  ];

  const sortedportFolios = _.orderBy(
    portfolios,
    [(port) => port.name?.toLowerCase()],
    ["asc"]
  );

  const portfolioDetailsCopy = portfolioDetails.filter((i) => i.true_order);

  // console.log("mergeOP(reducedObjectMenge, reducedObjectOP)",mergeOP(reducedObjectMenge, reducedObjectOP), mergeOP(reducedObjectMenge, reducedObjectOP).length)
  // console.log("sumValuesWithPrevious(portolioCopy)",sumValuesWithPrevious(portolioCopy))



  const gesamtDiagram = sumValuesWithPrevious(portolioCopy).map((item, i) =>
    Object.assign({
      order_date: item.order_date,
      SumAllDP: item.dprice,
      einzelDP: portfolioDetailsCopy[i].price,
      opDprice:  (mergeOP(reducedObjectMenge, reducedObjectOP)[i][2] !== undefined &&  mergeOP(reducedObjectMenge, reducedObjectOP)[i][1] !== undefined) &&
        (mergeOP(reducedObjectMenge, reducedObjectOP)[i][2] /
        mergeOP(reducedObjectMenge, reducedObjectOP)[i][1]),
      KplusOPDprice:
        //@ts-ignore
        mergeOPPlusKauf(offenePosition, summeAlleKauf)[i].OPKauf / totalAmount,
    })
  );
  const PResum = sumValuesWithPrevious(portolioCopy).map((pfdt) => pfdt)

  // console.log("greenArrayStep", greenArrayStep)

  const StepDiagramData = sumValuesWithPrevious(portolioCopy).map((pfdt) => pfdt)

  function CreateGreenArrayStep (PResum, greenArrayStep) {
    let matchingArray = [];
    let indexArray =[];
    let results = [];
    let LastDayofDiagram = greenArrayStep[greenArrayStep.length - 1];
    let lastKauf = new Date(PResum[PResum.length - 1]?.order_date);
    let DiffeDays = differenceInDays(LastDayofDiagram, lastKauf);
    for(let i = 0;i < PResum.length; i++){
      for (let j = 0; j < greenArrayStep.length;j++) {
        //@ts-ignore
        if (new Date(PResum[i].order_date).toDateString() === greenArrayStep[j].toDateString()) { 

          //@ts-ignore
          matchingArray.push(
            //@ts-ignore
            Number(100 * (PResum[i].amount / totalAmount)));
            //@ts-ignore
            indexArray.push(Number(j));
            //@ts-ignore
            results.push( Number(100 * (PResum[i].amount / totalAmount)))
          }
          // else {
            //   //@ts-ignore
            //   matchingArray.push(
              //     //@ts-ignore
              //     matchingArray[matchingArray.length - 1])
              //   }
              
              // console.log("indexarray",indexArray)
              // console.log("matchingArray",new Date(PResum[i].order_date).toDateString(), greenArrayStep[j].toDateString(),matchingArray)
            }
          }
          
          

           let cons1 = indexArray.map((i,index)=>{
            
            if(index === 0){
              return new Array(indexArray[0]).fill(0)
            }
          })
           let cons2 = indexArray.map((i,index)=>{
            
            if( index > 0){
              return new Array(indexArray[index] - indexArray[index - 1]).fill(results[index - 1])
            }
          })
           let cons3 = indexArray.map((i,index)=>{
            
            if(index === indexArray.length - 1){
              return new Array(DiffeDays + 2).fill(results[index])
            }
          })
          // let cons =indexArray.map((i,index)=>{
          //   console.log("index", indexArray, results)
          //   if(index === 0){
          //     return new Array(indexArray[0]).fill(0)
          //   }
          //   if(index > 0 && (index !== (indexArray.length - 1))){
          //     return new Array(indexArray[index] - indexArray[index - 1]).fill(results[index - 1])
          //   }
          //   if(index > 0 && (index === (indexArray.length - 1))){
  
          //     return new Array(10).fill(results[index - 1])
          //   }

            
          // })
          //@ts-ignore
          const flattenedArray = [].concat(...cons1).concat(...cons2).concat(...cons3).filter(function( element ) {
            return element !== undefined;
         });;

          // console.log("flattenedArray",flattenedArray)


          return flattenedArray;
          
}

const CheckDataStep = CreateGreenArrayStep(PResum, greenArrayStep);


  return (
    <>
      <Box
        flexDirection="row"
        display="flex"
        bgcolor="background.paper"
        alignItems="center"
        justifyContent="flexStart"
        height="6em"
        borderRadius="5px"
      >
        <Button
          variant="contained"
          style={{ marginLeft: "1rem" }}
          onClick={(e) => {
            handleClick(customerName, AddPortfolioPage);
          }}
          disabled={
            props.role.simple_role === "LIEFERANT" && customerName === ""
          }
        >
          {lang[locale].add}
        </Button>
        {props.role.simple_role === "LIEFERANT" && (
          <Box p={3} style={{ margin: "0 0 0.9em 0.9em" }}>
            <FormControl className={classes.formControl}>
              <Select
                id="selectCustomer"
                value={customerName}
                onChange={getallportfilios}
                displayEmpty
                className={classes.selectEmpty}
                MenuProps={MenuProps}
              >
                <MenuItem value={""}>
                  <em>{lang[locale].selectCustomer}</em>
                </MenuItem>
                {sortedCustomers &&
                  // @ts-ignore
                  sortedCustomers.map((cust) => (
                    <MenuItem value={cust.id} key={cust.id}>
                      {cust.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}
        {sortedportFolios && sortedportFolios.length > 0 && (
          // <Grid >
          <Grid
            style={{
              marginTop: "1em",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {/* <FormControl className={classes.formControl}> */}
            <Select
              autoWidth
              id="selectPortfolio"
              value={PortfolioName}
              onChange={getallportfilioDetails}
              displayEmpty
              className={classes.selectEmpty}
              MenuProps={MenuProps}
              style={{
                margin: "0 0 0.9em 0.9em",
                display: "flex",
              }}
              // onMouseEnter={refreshPorfolio}
            >
              <MenuItem value={""} style={{ width: "100%" }}>
                <em>{lang[locale].selectPortfolio}</em>
              </MenuItem>
              {sortedportFolios &&
                // @ts-ignore
                sortedportFolios.map((port) => (
                  <MenuItem
                    value={port.name}
                    key={port.id}
                    onClick={() => {
                      portfoDetailHandler(port.id);
                    }}
                    style={{
                      color: port.commodity === 1 ? "#1C83AD" : "#FAB516",
                    }}
                  >
                    {port.name}
                  </MenuItem>
                ))}
            </Select>
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={() => handleEditPortfolio(selectedToDelete)}
              style={{
                color: PortfolioName === "" ? "grey" : "white",
                margin: "0 0 0.4em 1em",
              }}
              id={selectedToDelete}
              disabled={PortfolioName === ""}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => portfolioDeleteHandler(selectedToDelete)}
              style={{
                color: PortfolioName === "" ? "grey" : "white",
                margin: "0 0 0.4em 1em",
              }}
              id={selectedToDelete}
              disabled={PortfolioName === ""}
            >
              <DeleteIcon />
            </IconButton>
            {/* </FormControl> */}
          </Grid>
        )}
      </Box>
      {/* <img src={PFM_Beispiel} alt="PFM_Beispiel" /> */}
      {showChart && portfolioDetails.length > 0 ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              width: "90%",
              margin: "2rem 3.5rem",
            }}
          >
            <TableContainer
              component={Paper}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    // flexDirection: "column",
                    justifyContent: "center",
                    padding: "1em",
                    boxSizing: "border-box",
                    fontSize: "1.5em",
                    fontWeight: "bold",
                    width: "100%",
                    color: "white",
                    background: "#5c5959",
                  }}
                >
                  {lang[locale].singlePurchases}
                </div>
                <Table
                  sx={{ background: "#5c5959" }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      {/* <TableCell> </TableCell> */}
                      <TableCell align="center" style={{ color: "white",flex:1,whiteSpace : "nowrap"}}>
                        <b>{lang[locale].offresid}</b>
                      </TableCell>
                      <TableCell align="center" style={{ color: "white",flex:1,whiteSpace : "nowrap"}}>
                        <b>{lang[locale].purchaseOn}:</b>
                      </TableCell>
                      <TableCell align="center" style={{ color: "white",flex:1,whiteSpace : "nowrap"}}>
                        <b>
                          {lang[locale].cost}
                          <br /> [€]
                        </b>
                      </TableCell>
                      <TableCell align="center" style={{ color: "white",flex:1,whiteSpace : "nowrap"}}>
                        <b>
                          {lang[locale].amount}
                          <br /> [MWh]
                        </b>
                      </TableCell>
                      <TableCell align="center" style={{ color: "white",flex:1,whiteSpace : "nowrap"}}>
                        <b>
                          {lang[locale].Dprice}
                          <br /> [€/MWh]
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {portfolioDetails &&
                      portfolioDetails
                        .filter((p) => p.zrid === p.zrid_ref)
                        .map((pfdt) => (
                          <TableRow
                            key={pfdt.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {/* <TableCell component="th" scope="portFolios">
                        {pfdt.name}
                      </TableCell> */}
                            <TableCell
                              align="center"
                              style={{ color: "white", padding: 0 }}
                            >
                              <Typography
                                to={`/app/myplans/detailed/${customerName ? customerName : customer}/${pfdt.zrid}`}
                                style={{ textDecoration: "none" }}
                                color="primary"
                                component={Link}
                              >
                                {/* {console.log("LinkFormatter", row)} */}
                                {pfdt.zrid}
                              </Typography>
                            </TableCell>
                            <MTooltip title={pfdt.zrid}>
                              <TableCell
                                align="center"
                                style={{ color: "white" }}
                              >
                                {pfdt.order_date
                                  .split("T")[0]
                                  .split("-")
                                  .reverse()
                                  .join(".")}
                              </TableCell>
                            </MTooltip>

                            <TableCell
                              align="center"
                              style={{ color: "white" }}
                            >
                              <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                fixedDecimalScale={true}
                                decimalScale={2}
                                // suffix=" €/MWh"
                                value={pfdt.price * (pfdt.amount / 1000)}
                                displayType={"text"}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ color: "white" }}
                            >
                              <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                fixedDecimalScale={true}
                                decimalScale={3}
                                // suffix=" €/MWh"
                                value={pfdt.amount / 1000}
                                displayType={"text"}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ color: "white" }}
                            >
                              <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                fixedDecimalScale={true}
                                decimalScale={2}
                                // suffix=" €/MWh"
                                value={pfdt.price}
                                displayType={"text"}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </div>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    // flexDirection: "row",
                    justifyContent: "center",
                    padding: "1em",
                    boxSizing: "border-box",
                    fontSize: "1.5em",
                    fontWeight: "bold",
                    width: "100%",
                    color: "white",
                    background: "#494847",
                  }}
                >
                  {lang[locale].SumOfAllPurchases}
                </div>
                <Table
                  sx={{ background: "#494847" }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      {/* <TableCell> </TableCell> */}
                      <TableCell align="center" style={{ color: "white",flex:1,whiteSpace : "nowrap"}}>
                        <b>
                          {lang[locale].cost}
                          <br /> [€]
                        </b>
                      </TableCell>
                      <TableCell align="center" style={{ color: "white",flex:1,whiteSpace : "nowrap"}}>
                        <b>
                          {lang[locale].amount}
                          <br /> [MWh]
                        </b>
                      </TableCell>
                      <TableCell align="center" style={{ color: "white",flex:1,whiteSpace : "nowrap"}}>
                        <b>
                          {lang[locale].Dprice}
                          <br /> [€/MWh]
                        </b>
                      </TableCell>
                      <TableCell align="center" style={{ color: "white",flex:1,whiteSpace : "nowrap"}}>
                        <b>
                          {lang[locale].procurement}
                          <br /> [%]
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {portfolioDetails &&
                     sumValuesWithPrevious(portolioCopy).map((pfdt) => (
                        <TableRow
                          key={pfdt.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {/* <TableCell component="th" scope="portfolios">
                        {pfdt.name}
                      </TableCell> */}
                          {/* <TableCell align="center">{pfdt.order_date}</TableCell> */}
                          <TableCell align="center" style={{ color: "white" }}>
                            <NumberFormat
                              thousandSeparator="."
                              decimalSeparator=","
                              fixedDecimalScale={true}
                              decimalScale={2}
                              // suffix=" €/MWh"
                              value={pfdt.kosten}
                              displayType={"text"}
                            />
                          </TableCell>
                          <TableCell align="center" style={{ color: "white" }}>
                            <NumberFormat
                              thousandSeparator="."
                              decimalSeparator=","
                              fixedDecimalScale={true}
                              decimalScale={3}
                              // suffix=" €/MWh"
                              value={pfdt.amount}
                              displayType={"text"}
                            />
                          </TableCell>
                          <TableCell align="center" style={{ color: "white" }}>
                            <NumberFormat
                              thousandSeparator="."
                              decimalSeparator=","
                              fixedDecimalScale={true}
                              decimalScale={2}
                              // suffix=" €/MWh"
                              value={pfdt.kosten / pfdt.amount}
                              displayType={"text"}
                            />
                          </TableCell>
                          <TableCell align="center" style={{ color: "white" }}>
                            <NumberFormat
                              thousandSeparator="."
                              decimalSeparator=","
                              fixedDecimalScale={true}
                              decimalScale={2}
                              // suffix=" €/MWh"
                              value={100 * (pfdt.amount / totalAmount)}
                              displayType={"text"}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    background: "#5c5959",
                    padding: "1em",
                    width: "100%",
                    fontSize: "1.5em",
                    fontWeight: "bold",
                    height: "auto",
                    color: "white",
                  }}
                >
                  {lang[locale].openPosition}
                </div>
                <Table
                  sx={{ background: "#5c5959" }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ color: "white",flex:1,whiteSpace : "nowrap"}}>
                        <b>
                          {lang[locale].cost}
                          <br /> [€]
                        </b>
                      </TableCell>
                      <TableCell align="center" style={{ color: "white",flex:1,whiteSpace : "nowrap"}}>
                        <b>
                          {lang[locale].amount}
                          <br /> [MWh]
                        </b>
                      </TableCell>
                      <TableCell align="center" style={{ color: "white",flex:1,whiteSpace : "nowrap"}}>
                        <b>
                          {lang[locale].Dprice}
                          <br /> [€/MWh]
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mergeOP(reducedObjectMenge, reducedObjectOP) &&
                      mergeOP(reducedObjectMenge, reducedObjectOP).map(
                        (pfdt) => (
                          <TableRow
                            key={pfdt[0]}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              align="center"
                              style={{ color: "white" }}
                            >
                              <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                fixedDecimalScale={true}
                                decimalScale={2}
                                // suffix=" €/MWh"
                                //@ts-ignore
                                value={Number(pfdt[2].toFixed(2)) <= 0 ? 0 : pfdt[2]}
                                displayType={"text"}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ color: "white" }}
                            >
                              <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                fixedDecimalScale={true}
                                decimalScale={3}
                                // suffix=" €/MWh"
                                //@ts-ignore
                                value={Number(pfdt[2].toFixed(2)) <= 0 ? 0 : pfdt[1]}
                                displayType={"text"}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ color: "white" }}
                            >
                              <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                fixedDecimalScale={true}
                                decimalScale={2}
                                // suffix=" €/MWh"
                                //@ts-ignore
                                value={Number(pfdt[2].toFixed(2)) <= 0 ? " " : (pfdt[2] / pfdt[1])}
                                displayType={"text"}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                </Table>
              </div>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    background: "#494847",
                    padding: "1em",
                    width: "100%",
                    fontSize: "1.5em",
                    fontWeight: "bold",
                    height: "auto",
                    color: "white",
                  }}
                >
                  {lang[locale].purchasePlusOP}
                </div>
                <Table
                  sx={{ background: "#494847" }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ color: "white",flex:1,whiteSpace : "nowrap"}}>
                        <b>
                          {lang[locale].cost}
                          <br /> [€]
                        </b>
                      </TableCell>
                      <TableCell align="center" style={{ color: "white",flex:1,whiteSpace : "nowrap"}}>
                        <b>
                          {lang[locale].amount}
                          <br /> [MWh]
                        </b>
                      </TableCell>
                      <TableCell align="center" style={{ color: "white",flex:1,whiteSpace : "nowrap"}}>
                        <b>
                          {lang[locale].Dprice}
                          <br /> [€/MWh]
                        </b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mergeOPPlusKauf(offenePosition, summeAlleKauf) &&
                      mergeOPPlusKauf(offenePosition, summeAlleKauf).map(
                        (pfdt) => (
                          <TableRow
                            key={pfdt[0]}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              align="center"
                              style={{ color: "white" }}
                            >
                              <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                fixedDecimalScale={true}
                                decimalScale={2}
                                // suffix=" €/MWh"
                                //@ts-ignore
                                value={pfdt.OPKauf}
                                displayType={"text"}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ color: "white" }}
                            >
                              <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                fixedDecimalScale={true}
                                decimalScale={3}
                                // suffix=" €/MWh"
                                value={totalAmount}
                                displayType={"text"}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ color: "white" }}
                            >
                              <NumberFormat
                                thousandSeparator="."
                                decimalSeparator=","
                                fixedDecimalScale={true}
                                decimalScale={2}
                                // suffix=" €/MWh"
                                //@ts-ignore
                                value={pfdt.OPKauf / totalAmount}
                                displayType={"text"}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </div>
          <div
            style={{
              marginLeft: "1rem",
              display: "flex",
              flexDirection: "row",
              // justifyContent: "space-evenly",
            }}
          >
            <ResponsiveContainer height={750} width={"70%"}>
              <ComposedChart
                data={gesamtDiagram.map((pfdd) => pfdd)}
                data-testid="areachart"
                style={{ margin: "0 0" }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#f5f5f5" />
                <YAxis domain={["auto", "auto"]} />
                <XAxis
                  tickFormatter={(monthArray) =>
                    new Date(monthArray).toLocaleString(showLocalLang, options)
                  }
                  allowDataOverflow={true}
                  dataKey="order_date"
                  allowDuplicatedCategory={false}
                  type="category"
                  interval="preserveStart"
                  angle={25}
                  dx={6}
                  dy={6}
                />
                <Legend
                  verticalAlign="bottom"
                  height={32}
                  onClick={toggleHandler}
                />
                <Line
                  name={lang[locale].singlePurchases + " [€/MWh]"}
                  type="monotone"
                  dataKey="einzelDP"
                  stroke="#2a7528"
                  hide={!ezkShow}
                  dot={false}
                  isAnimationActive={false}
                />
                <Line
                  name={lang[locale].SumOfAllPurchases + " [€/MWh]"}
                  type="monotone"
                  dataKey="SumAllDP"
                  stroke="#33ffff"
                  hide={!smalKShow}
                  dot={false}
                  isAnimationActive={false}
                />
                <Line
                  name={lang[locale].openPosition + " [€/MWh]"}
                  type="monotone"
                  dataKey="opDprice"
                  stroke="#42b3f5"
                  hide={!OPShow}
                  dot={false}
                  isAnimationActive={false}
                />
                <Line
                  name={lang[locale].purchasePlusOP + " [€/MWh]"}
                  type="monotone"
                  dataKey="KplusOPDprice"
                  stroke="#ebad1c"
                  hide={!KpOPShow}
                  dot={false}
                  isAnimationActive={false}
                />
                <Brush
                  height={20}
                  opacity={0.1}
                  stroke="green"
                  y={637}
                  style={{ zIndex: 1 }}
                  // startIndex={0}
                  // endIndex={60}
                />
              </ComposedChart>
            </ResponsiveContainer>
            <div style={{display : "flex", flexDirection : "column" , marginLeft : "5rem", marginTop : "3rem"}}>

            <PieChart width={250} height={250} style={{marginLeft : "3rem"}}>
              <Legend verticalAlign="bottom" align="center" />
              <Pie
                data={data01}
                dataKey="value"
                nameKey="name"
                label
                cx="50%"
                cy="50%"
                outerRadius={60}
                stroke=""
                startAngle={0}
                //   fill="#8884d8"
                >
                {data01.map((entry, index) => (
                  <Cell fill={showColors[index]} />
                  ))}
              </Pie>
            </PieChart>
            <div style={{marginLeft : "5rem",width : "50%"}}>
            <div style={{ marginTop : "2rem"}}>{lang[locale].TotalManagementPeriod}</div>
            <div>{pfmStart !== undefined && pfmStart.toLocaleString().split("T")[0].split("-").reverse().join(".")} - {pfmEnd !== undefined && pfmEnd.toLocaleString().split("T")[0].split("T")[0].split("-").reverse().join(".")}</div>
            <div style={{ marginTop : "1rem"}}>{lang[locale].totalDeliveryPeriod}</div>
            <div>{deliveryStart !== undefined && deliveryStart.toLocaleString().split("T")[0].split("-").reverse().join(".")} - {deliveryEnd !== undefined && deliveryEnd.toLocaleString().split("T")[0].split("T")[0].split("-").reverse().join(".")}</div>
            <p style={{ marginTop : "2rem", textAlign : "justify"}}>Bei vollständiger Beschaffung aller offenen Positionen zum jetzigen Zeitpunkt ergeben sich folgende Gesamtkosten:</p>
            
            
            <div>Aktuell offene Positionen: </div>
            <div><b>Kosten:  &nbsp;</b>
            <NumberFormat
            thousandSeparator="."
            decimalSeparator=","
            fixedDecimalScale={true}
            decimalScale={2}
            suffix=" €"
            //@ts-ignore
            value={(sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner()).length > 0 && sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner())[sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner()).length - 1] !== undefined && Math.round(Number(sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner())[sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner()).length - 1].beschaffung)) < 100) ? sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner())[sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner()).length - 1].kosten : 0 }
            displayType={"text"}
            /></div>
            <div><b>Durchschnittspreis:  &nbsp;</b>
            <NumberFormat
            thousandSeparator="."
            decimalSeparator=","
            fixedDecimalScale={true}
            decimalScale={2}
            suffix=" €/MWh"
            //@ts-ignore
            value={(sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner()).length > 0 && sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner())[sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner()).length - 1] !== undefined && Math.round(Number(sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner())[sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner()).length - 1].beschaffung)) < 100) ? sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner())[sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner()).length - 1].dprice : 0}
            displayType={"text"}
            /></div>
            
            
            <div style={{marginTop : "2rem"}}><b>Portfolio-Gesamtkosten:  &nbsp;</b>
            <NumberFormat
            thousandSeparator="."
            decimalSeparator=","
            fixedDecimalScale={true}
            decimalScale={2}
            suffix=" €"
            //@ts-ignore
            value={(sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner())[sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner()).length - 1] !== undefined && sumValuesWithPrevious(portolioCopy)[sumValuesWithPrevious(portolioCopy).length - 1]) ? (sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner())[sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner()).length - 1].kosten + sumValuesWithPrevious(portolioCopy)[sumValuesWithPrevious(portolioCopy).length - 1].kosten) : 0}
            displayType={"text"}
            /></div>
            <div><b>Portfolio-Durchschnittspreis:  &nbsp;</b>
            <NumberFormat
            thousandSeparator="."
            decimalSeparator=","
            fixedDecimalScale={true}
            decimalScale={2}
            suffix=" €/MWh"
            //@ts-ignore
            value={(sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner())[sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner()).length - 1] !== undefined && sumValuesWithPrevious(portolioCopy)[sumValuesWithPrevious(portolioCopy).length - 1]) ? ((sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner())[sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner()).length - 1].kosten + sumValuesWithPrevious(portolioCopy)[sumValuesWithPrevious(portolioCopy).length - 1].kosten) / (sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner())[sumValuesWithPreviousOffenMitaktuellen(ArrayCombiner()).length - 1].totalAmountPfm) * 1000) : 0}
            displayType={"text"}
            /></div>     
            </div>
            </div>
          </div>
          <div
            style={{
              margin: "3rem 1rem 0 4rem",
              width : "90%",
              height: "50%",

            }}
          >
            <ResponsiveContainer>
              <>
              <StepLineChart DaysDifference={DaysDifference} greenArrayStep={greenArrayStep.map((i)=>i.toLocaleString().split(", ")[0])} portfolioID={selectedToDelete} CustomerID={props.role.simple_role === "LIEFERANT" ? Number(customerName) : props.role.simple_role === "USER" ? Number(customer) : null} portfolioDetails={portfolioDetails} StepDiagramData={CheckDataStep} totalAmount={totalAmount} resultArray={resultArray}/>
              <Line type="monotone" dataKey="100" stroke="#8884d8" />
            </>
            </ResponsiveContainer>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            margin: "4rem auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>{lang[locale].noPortfolioDetailMessage}</h2>
        </div>
      )}
    </>
  );
}
export default PfmComponent;

// function groupObjectsByDate(objects) {
//   const groupedObjects = {};

//   objects.forEach((obj) => {
//     const date = obj.order_date;
//     if (groupedObjects[date]) {
//       groupedObjects[date].push(obj);
//     } else {
//       groupedObjects[date] = [obj];
//     }
//   });

//   return groupedObjects;
// }

// const fullMenge = portfolioEditZrIds.reduce(function(accumulator, currentObject) {
//   let product = currentObject["info"].full_amount * currentObject["info"].tranche;
//   return accumulator + product;
// }, 0);


// useEffect(()=>{
//   const uniqueZrifRefs = [...new Set(portfolioDetails.map(item => item.zrid_ref))]
//   console.log("uniqueZrifRefs",uniqueZrifRefs)

//   function MakeDatesDifference(arr1, arr2) {
    
//     let matchingArray = [];
    

//     arr2.map(i=> {
//       for (let obj1 of arr1) {
//         if (obj1.zrid_ref === i) {
//           // if(obj1.true_order === true){
//             const digit1 = Math.floor(Math.random() * 5)
//             const digit2 = Math.floor(Math.random() * 9)
//           //@ts-ignore
//           matchingArray.push({
//             //@ts-ignore
//             ...obj1,order_date: obj1.order_date.split("T")[0] + "T" + obj1.order_date.split("T")[1].split(":")[0] + ":" + (i.toString().split('')[1] % 5) + ( i.toString().split('')[2] % 9) + ":" + (Math.floor(Math.random() * 60))

//           // }
//           // else{
//           //   //@ts-ignore
//           //   matchingArray.push({

//           //     //@ts-ignore
//           //     ...obj1, mengeOp:obj2["info"].full_amount / 1000 , KostenOp: (obj2["info"].full_amount / 1000 * obj1.price)
//           //   });
//           // }
//         })
//       }
//     }
//     })

//     return matchingArray;
  
//   }

//   const portfolioDetail = MakeDatesDifference(portfolioDetails, uniqueZrifRefs);
//   setPortfolioDetail(portfolioDetail);

// },[portfolioDetails])