import loadcurveConstants from "constants/loadcurve.constants";
import LoadcurveModel from "typeDefs/LoadcurveModel";
import LoadcurveService from "services/loadcurves/LoadcurveService";
import AuthService from "../services/auth/AuthService";
import snackbarActions from "actions/snackbar.actions";
import { useHistory } from "react-router-dom";

const service: LoadcurveService = new LoadcurveService();
/* let history = useHistory(); */
/**
 * Gets all personal loadcurves
*/
function getLoadcurves(customer_id = 0,datakind) {
    const role = AuthService.getRole();
    console.debug("Getting loadcurves..");
    return (dispatch) => {
        dispatch(request());

        return role.simple_role === "LIEFERANT"
            ? service
                  .getLoadcurvesSupplier(customer_id, datakind)
                  .then((curves: LoadcurveModel[]) => {
                    
                      dispatch(
                          success(curves.length > 0 ? curves.reverse() : [])
                      );
                  })
                  .catch((e: Error) => {
                      /* if (e.message === "401") {
                          return history.push(`/logout`);
                      } */
                    //   console.log("Error", e);
                      dispatch(failure(new Error(e.message)));
                  })
                  : role.simple_role !== "LIEFERANT" &&
                  service
                  .getLoadcurvesCustomer(datakind)
                  .then((curves: LoadcurveModel[]) => {
                      
                      dispatch(
                          success(curves.length > 0 ? curves.reverse() : [])
                        );
                    })
                    .catch((e: Error) => {
                        /* if (e.message === "401") {
                          return history.push(`/logout`);
                      } */
                    //   console.log("Error", e);
                      dispatch(failure(new Error(e.message)));
                  });
    };

    function request() {
        return {
            type: loadcurveConstants.GET_REQUEST,
        };
    }
    function success(loadcurves: LoadcurveModel[]) {
        return {
            type: loadcurveConstants.GET_SUCCESS,
            loadcurves,
        };
    }
    function failure(error: Error) {
        return {
            type: loadcurveConstants.GET_FAILURE,
            error,
        };
    }
}

/**
 * Gets all personal loadcurves
 */
function getLoadcurvesRemaining(customer_id = 0) {
    const role = AuthService.getRole();
    console.debug("Getting remaining loadcurves..");
    return (dispatch) => {
        dispatch(request());

        return role.simple_role === "LIEFERANT"
            ? service
                  .getLoadcurvesRemainingSupplier(customer_id)
                  .then((curves: any) => {
                    
                      //CHECK IF ERROR IS COVERED
                      dispatch(
                          success(curves.length > 0 ? curves.reverse() : [])
                      );
                  })
                  .catch((e: Error) => {
                    //   console.log("Error", e);
                      dispatch(failure(new Error(e.message)));
                  })
            : service
                  .getLoadcurvesRemainingCustomer()
                  .then((curves: any) => {
                    
                      //CHECK IF ERROR IS COVERED
                      dispatch(
                          success(curves.length > 0 ? curves.reverse() : [])
                      );
                  })
                  .catch((e: Error) => {
                      /* if (e.message === "401") {
                          return history.push(`/logout`);
                      } */
                    //   console.log("Error", e);
                      dispatch(failure(new Error(e.message)));
                  });
    };

    function request() {
        return {
            type: loadcurveConstants.GET_REQUEST,
        };
    }
    function success(loadcurvesRemaining: any) {
        return {
            type: loadcurveConstants.GET_SUCCESS_REMAINING,
            loadcurvesRemaining,
        };
    }
    function failure(error: Error) {
        return {
            type: loadcurveConstants.GET_FAILURE,
            error,
        };
    }
}

/**
 * deletes a loadcurve by zrid
 * @param zrid deletes loadcurve
 */
function deleteLoadcurve(zrid: number) {
    return (dispatch) => {
        dispatch(request());

        service
            .deleteLoadcurve(zrid)
            .then(() => {
                dispatch(success());
            })
            .catch((e) => {
                /* if (e.message === "401") {
                    return history.push(`/logout`);
                } */
                dispatch(snackbarActions.openSnackbar(e.message, "error"));
                dispatch(failure(new Error(e.message)));
            });
    };
    function request() {
        return {
            type: loadcurveConstants.DELETE_REQUEST,
        };
    }

    function success() {
        return {
            type: loadcurveConstants.DELETE_SUCCESS,
        };
    }

    function failure(error: Error) {
        return {
            type: loadcurveConstants.DELETE_FAILURE,
            error,
        };
    }
}
/**
 * deletes a loadcurve by zrid
 * @param zrid deletes loadcurve
 */
function deleteLoadcurvePE(zrid: number) {
    return (dispatch) => {
        dispatch(request());

        service
            .deleteLoadcurvePE(zrid)
            .then(() => {
                console.log("success")
                dispatch(success());
            })
            .catch((e) => {
                /* if (e.message === "401") {
                    return history.push(`/logout`);
                } */
                // dispatch(snackbarActions.openSnackbar(e.message, "error"));
                dispatch(failure(new Error(e.message)));
            });
    };
    function request() {
        return {
            type: loadcurveConstants.DELETE_REQUEST,
        };
    }

    function success() {
        return {
            type: loadcurveConstants.DELETE_SUCCESS,
        };
    }

    function failure(error: Error) {
        return {
            type: loadcurveConstants.DELETE_FAILURE,
            error,
        };
    }
}
/**
 * Action for creating loadcurves. Calls the service and returns the action type
 * @param file
 * @param priceCurve the pricecurve used
 */
function createLoadcurve(file: File | undefined, priceCurve: string) {
    return (dispatch) => {
        dispatch(request());
        if (file)
            return service
                .createLoadcurve(file, priceCurve)
                .then(() => {
                    dispatch(success());
                })
                .catch((e) => {
                    /* if (e.message === "401") {
                        return history.push(`/logout`);
                    } */
                    console.error(e);
                    dispatch(failure(new Error(e.message)));
                });
        else {
            dispatch(failure(new Error("No file provided")));
        }
    };

    function request() {
        return {
            type: loadcurveConstants.POST_REQUEST,
        };
    }

    function success() {
        return {
            type: loadcurveConstants.POST_SUCCESS,
        };
    }

    function failure(error: Error) {
        return {
            type: loadcurveConstants.POST_FAILURE,
            error,
        };
    }
}

/**
 * Function to recreate the original state after a loadcurve gets created
 */
function resetState() {
    return {
        type: loadcurveConstants.RESET_STATE,
    };
}

/**
 * Function to reset the original state when logout
 */
function resetStateLogout() {
    return {
        type: loadcurveConstants.RESET_STATE_LOGOUT,
    };
}

const loadcurveActions = {
    getLoadcurves,
    getLoadcurvesRemaining,
    createLoadcurve,
    resetState,
    resetStateLogout,
    deleteLoadcurve,
    deleteLoadcurvePE,
};

export default loadcurveActions;
