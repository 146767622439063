import {AnyAction} from "redux";
import {intervalConstants} from "../constants/interval.constants";

interface IIntervalReducerState{
    interval?: number
}

const initialState: IIntervalReducerState = {

};
/**
 * Reducer for creating the interval to
 * @param state State
 * @param action Action
 */
export function interval(state: IIntervalReducerState = initialState, action: AnyAction) {
    switch (action.type) {
        case intervalConstants.STOP:
            clearInterval(state.interval)
            return {
                ...initialState,
            };
        case intervalConstants.START:

            clearInterval(state.interval)

            return {
                ...state,
                interval: action.interval
            };

        default:
            return state;
    }
}
