import React, { useEffect, useState } from "react";
import {
    Switch,
    Typography,
    Card,
    CardMedia,
    Box,
    TextField,
    Button,
    Grid,
} from "@material-ui/core";
import { lang } from "../lang";
import AuthService from "services/auth/AuthService";
import authActions from "actions/auth.actions";
import { useDispatch } from "react-redux";
import { useSelector } from "services/helpers/useSelector";
import { useHistory } from "react-router-dom";
// import snackbarActions from "../actions/snackbar.actions";
import _ from "lodash";

const service = new AuthService();
//1. QR-Code muss validiert werden mit einer Bestätigungspin und dann wird setQr(null), wenn status = true ist
//2. Wenn status true ist und on auf false gesetzt wird, wird nur das Input Feld mit Pin gezeigt.
//3. Bei erfolgreicher Aktivierung oder Deaktivierung der 2FA Authentifikation sollte eine Erfolgsnachricht angezeigt werden
//4. Fehlermeldungen sollten eingebunden werden

function Set2FAComponent(props) {
    let history = useHistory();
    const locale = localStorage.locale;
    const [on, setOn] = useState<boolean | null>(null);
    const [pin, setPin] = useState<string | null>(null);
    const [qr, setQr] = useState<string | null>(null);
    
    const active = useSelector((state) => state.auth.active);
    
    const dispatch = useDispatch();

    const isNumeric = number => 
  _.isFinite(_.parseInt(number)) && !_.isNaN(_.parseInt(number)) && number.length > 5
    //Initializing local state
    useEffect(() => {
        setOn(active);
    }, []);

    const handleChange = () => {
        setOn(!on);
    };

    //For activating 2FA
    useEffect(() => {
        on && !active &&
            service
                .startTwoFA()
                .then((res) => setQr(res))
                .catch((e) => {
                    // console.log("Error", e);
                });
        !on && setQr(null);
    }, [on]);

    //For activating 2FA
    function onSubmit(e) {
        e.preventDefault();
        dispatch(authActions.activate2FA(on, pin));
        setTimeout(() => {
            history.push(`/logout`);
        }, 3000);
    }

    return (
        <>
            <div style={{ display: "flex", flexDirection : "row", alignItems : "center", marginBottom : "2rem" }}>
                <Typography variant="subtitle2" color="inherit" gutterBottom>
                    {lang[locale].explanation2FA}
                </Typography>
                <Switch
                    //@ts-ignore
                    checked={on}
                    onChange={handleChange}
                    name="twoFA"
                    color="primary"
                />
                <span style={{fontSize : "0.7rem"}}>{on && active ? `${lang[locale].Activated}` : ((on && !active) || (!on && active)) ? `${lang[locale].pending}` : (!on && !active) && `${lang[locale].Disabled}`}</span>
            </div>
            {qr && !active && (
                <>
                    <Typography
                        variant="subtitle2"
                        color="inherit"
                        gutterBottom
                    >
                        {lang[locale].scanQR}
                    </Typography>
                    <Box /*display="flex" justifyContent="center"*/ p={5}>
                        <Box style={{ maxWidth: "12vw" }}>
                            <>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        alt="QR-Code"
                                        image={"data:image/png;base64," + qr}
                                    ></CardMedia>
                                </Card>
                            </>
                        </Box>
                    </Box>
                    <Box pb={2}>
                        <Typography
                            variant="subtitle2"
                            color="inherit"
                            gutterBottom
                        >
                            {lang[locale].activateWithPin}
                        </Typography>
                    </Box>

                    <form onSubmit={onSubmit}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    id="pin"
                                    label="Pin"
                                    variant="outlined"
                                    className="maximalWidth"
                                    onChange={(e) => {
                                        setPin(e.target.value);
                                    }}
                                    required
                                    /* error={error ? true : false} */
                                    autoComplete="off"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    // color="primary"
                                    style={{backgroundColor : !pin || !isNumeric(pin) ? "#595959" : props.coloR.primary, color : !pin || !isNumeric(pin) ? "#898989" : "white"}}
                                    type="submit"
                                    data-testid="submit"
                                    disabled={!pin || !isNumeric(pin)}
                                >
                                    <>{lang[locale].activate}</>
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </>
            )}
            {!on && active && (
                <form onSubmit={onSubmit}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                id="pin"
                                label="Pin"
                                variant="outlined"
                                className="maximalWidth"
                                onChange={(e) => {
                                    setPin(e.target.value);
                                }}
                                required
                                /* error={error ? true : false} */
                                autoComplete="off"
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                variant="contained"
                                // color="primary"
                                style={{backgroundColor : !pin ? "#595959" : props.coloR.primary, color : !pin ? "#898989" : "white"}}
                                type="submit"
                                data-testid="submit"
                                disabled={!pin}
                            >
                                <>{lang[locale].deactivate}</>
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            )}
        </>
    );
}

export default Set2FAComponent;
