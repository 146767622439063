import React, { useEffect, useState } from "react";
import { Box, TextField } from "@material-ui/core";
import { lang } from "lang";
import { MenuItem, Select, Typography, FormControl,Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import _ from "lodash";
import DeleteIcon from '@mui/icons-material/Delete';

//TypeDefs
import CustomerModel from "../typeDefs/CustomerModel";

//Hooks
import { useDispatch } from "react-redux";
import { useSelector } from "services/helpers/useSelector";
import { useHistory, useParams } from "react-router-dom";

//Services
import UserService from "../services/users/UserService";

//Actions
// import authActions from "../actions/auth.actions";
import customerActions from "actions/customer.actions";
import snackbarActions from "../actions/snackbar.actions";

//Components
import CardAddButtonComponent from "./CardAddButton";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    })
);

const service = new UserService();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

/**
 * Component for filtering the loadcurves
 */

function FilterComponentSupplier({ filters, setFilters, role, setPEfilterChange }) {
    let history = useHistory();
    const locale = localStorage.locale;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [customerId, setCustomerId] = useState<number | string>("");
    const [filteredCustomer, setFilteredCustomer] = useState<
        CustomerModel | {}
    >({});
    // @ts-ignore
    const customer = useSelector((state) => state.customer.customerData);
    const loadcurves = useSelector((state) => state.loadcurve.loadcurves);
    const {customer_id} = useParams<{customer_id?: string | undefined}>();
    const { customers } = useSelector((state) => state.customer);
    const sortedCustomers = _.orderBy(customers, [cust => cust.name?.toLowerCase()], ["asc"]);
    const [isSticky, setIsSticky] = useState(false);
    
    function handleClearText(){
        setFilters("");
    }

    useEffect(() => {
        customer_id && setCustomerId(Number(customer_id));
        service
            .getCustomers()
            .then((res) => {
                // console.log("customer_id" , customer_id);
                // console.log("customers", customer);
                dispatch(customerActions.updateAll(res));
            })
            .catch((e) => {
                if (e.message === "401") {
                    return history.push(`/logout`);
                }
                // console.error(e);
                // dispatch(snackbarActions.openSnackbar(e.message, "error"));
            });
    }, []);

    useEffect(() => {
        customer_id && setCustomerId(Number(customer_id));
        service
            .getCustomers()
            .then((res) => {
                // console.log("customer_id" , customer_id);
                // console.log("customers", customer);
                dispatch(customerActions.updateAll(res));
            })
            .catch((e) => {
                if (e.message === "401") {
                    return history.push(`/logout`);
                }
                // console.error(e);
                // dispatch(snackbarActions.openSnackbar(e.message, "error"));
            });
    }, [loadcurves]);

    /**
     * Filter Functionality
     */

    const handleCustomerChange = (e) => {
        history.push(`/app/myplans/${e.target.value}`);
        setCustomerId(e.target.value);
    };

    // const nameCounts = sortedCustomers.reduce((acc, item) => {
    //     acc[item.name] = (acc[item.name] || 0) + 1;
    //     return acc;
    //   }, {});

    function handleTextChange(e) {
        setFilters(e.target.value);
    }

    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 100) {
            setIsSticky(true);
            
          } else {
            setIsSticky(false);
            
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    /*  Filters the selected customer object by given id */

    useEffect(() => {
        const filtered = customers
            ? // @ts-ignore
              customers.filter((c) => c.id === customerId)
            : {};
        setFilteredCustomer(filtered[0]);
    }, [handleCustomerChange, customerId]);

    /* Sets the customer object in the store */

    useEffect(() => {
        filteredCustomer !== {} &&
            dispatch(customerActions.update(filteredCustomer));
    }, [filteredCustomer]);

    return (
        <Box
            flexDirection="row"
            display="flex"
            bgcolor="background.paper"
            alignItems="center"
            height="6em"
            borderRadius="5px"
            style={{position : isSticky === true ? "fixed" : "relative", zIndex : isSticky ? 2 : 0, backgroundColor : isSticky ? "rgba(255, 255, 255, 0.2)" : "#424242", boxShadow : isSticky ? "0 4px 30px rgba(0, 0, 0, 0.1)" : "0", backdropFilter : isSticky ? "blur(5px)" : "1px" , border : isSticky ? "1px solid rgba(255, 255, 255, 0.3)" : "0", width : isSticky ? "81%" : "100%" }}
            
        >
            <Box p={3} padding={"2em"}>
                <CardAddButtonComponent

                    role={role}
                    locale={locale}
                    customer={customer_id}
                    active={
                        customer && (customer.current_count < customer.prog_count)
                    }
                    isPriceDevelopment={false}
                    setPEfilterChange={0}
                />
            </Box>

            <Box p={3} flexGrow={1} style={{margin : "0 0 1.1em"}}>
                <FormControl className={classes.formControl}>
                    <Select
                        id="selectCustomer"
                        value={customerId}
                        onChange={handleCustomerChange}
                        displayEmpty
                        className={classes.selectEmpty}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value={""}>
                            <em>{lang[locale].selectCustomer}</em>
                        </MenuItem>
                        {sortedCustomers &&
                            // @ts-ignore
                            sortedCustomers.map((cust, index) => (
                                <MenuItem value={cust.id} key={cust.id}>
                                   {`${cust.name} (${cust.kunde_nr})`}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Box>
            {customer ? (
                <>
                    <Box p={3} style={{margin : "0 0 1.1em"}}>
                        <TextField
                            id="fulltext_filter"
                            label={lang[locale].search}
                            value={filters}
                            inputProps={{ "data-testid": "fullTextFilter" }}
                            onChange={handleTextChange}
                        />
                           {
                            filters.length > 0 ?  <Button style={{margin: "1rem 0 0 0.5rem"}} onClick={handleClearText}><DeleteIcon fontSize="small"/></Button> : null
                           }
                    </Box>
                    <Box p={3}>
                        <Typography variant="body2">
                            {lang[locale].progcount} <br />{" "}
                            {customer.current_count +
                                " / " +
                                customer.prog_count}
                        </Typography>
                    </Box>
                </>
            ) : null}
        </Box>
    );
}

export default FilterComponentSupplier;
