/**
 * Constants for customer
 */

export const customerConstants = {
    GET_CUSTOMER: "GET_CUSTOMER",
    UPDATE_CUSTOMER: "UPDATE_CUSTOMER",
    UPDATE_ALL_CUSTOMERS: "UPDATE_ALL_CUSTOMERS",
    GET_ALL_CUSTOMERS: "GET_ALL_CUSTOMERS",
    DELETE_SINGLE_CUSTOMER: "DELETE_SINGLE_CUSTOMER",
    UPDATE_SINGLE_CUSTOMER: "UPDATE_SINGLE_CUSTOMER",
};
