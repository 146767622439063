/**
 * Constants for price actions and reducers
 */
export const priceConstants = {
    GET_BOUNDS_REQUEST: "PRICE_GET_BOUNDS_REQUEST",
    GET_BOUNDS_SUCCESS: "PRICE_GET_BOUNDS_SUCCESS",
    GET_BOUNDS_FAILURE: "PRICE_GET_BOUNDS_FAILURE",
    SAVE_BOUNDS_REQUEST: "PRICE_SAVE_BOUNDS_REQUEST",
    SAVE_BOUNDS_SUCCESS: "PRICE_SAVE_BOUNDS_SUCCESS",
    SAVE_BOUNDS_FAILURE: "PRICE_SAVE_BOUNDS_FAILURE",
    TOGGLE_ACTIVE_REQUEST: "PRICE_TOGGLE_ACTIVE_REQUEST",
    TOGGLE_ACTIVE_SUCCESS: "PRICE_TOGGLE_ACTIVE_SUCCESS",
    TOGGLE_ACTIVE_FAILURE: "PRICE_TOGGLE_ACTIVE_FAILURE",
    PRICE_RESET: "PRICE_RESET"
}
