import { lang } from "../lang";

//Hooks
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

//Material UI
import { Button, Grid, Typography } from "@material-ui/core";

//Actions
import snackbarActions from "../actions/snackbar.actions";
import modalActions from "../actions/modal.actions";

import PortfolioService from "./../services/portfolios/PortfolioService";

const PFMservive = new PortfolioService();

function DeletePortfolioComponent(props) {
  let history = useHistory();
  const locale = localStorage.locale;
  const dispatch = useDispatch();

  // console.log("delete", props);

  function del() {
    PFMservive.DeletePortfolio(Number(props.customerName ? props.customerName : props.customer), props.id)
      .then(() => {
        props.getAllPortfolio();
        dispatch(
          snackbarActions.openSnackbar("Portfolio erfolgreich gelöscht.", "success")
        );
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
    closeModal();
  }

  function closeModal() {
    // console.log("In Close Modal DeleteUserOrCustomer Component");
    dispatch(modalActions.closeModal());
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>
          {lang[locale].deletePortfolio}
        </Typography>
        <Typography>
          <b>PFM : {props.PortfolioName}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} style={{marginTop : "1em"}}>
        <Grid container justify="space-between">
          <Grid item>
            <Button color="primary" onClick={closeModal}>
              {lang[locale].close}
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" variant="contained" onClick={del}>
              {lang[locale].send}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DeletePortfolioComponent;
