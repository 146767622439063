import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "../services/helpers/useSelector";
import forecastActions from "../actions/forecast.actions";
import {
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import { lang } from "../lang";
import LoadcurveService from "../services/loadcurves/LoadcurveService";
import snackbarActions from "../actions/snackbar.actions";
import { useHistory } from "react-router-dom";

const loadcurveService = new LoadcurveService();

/**
 * Component for selecting holiday zone

 * @constructor
 */
export function SelectHolidayComponent() {
    let history = useHistory();
    const [holidays, setHolidays] = useState<string[] | undefined>(undefined);
    const locale = localStorage.locale;

    const dispatch = useDispatch();

    const holidayRegion = useSelector(
        (state) => state.forecast.data.holidayRegion
    );

    //save chosen holiday region in state
    function handleChange(e) {
        dispatch(
            forecastActions.saveForecastSingle("holidayRegion", e.target.value)
        );
    }

    //fetches things from api like holidays and so on
    useEffect(() => {
        //gets holidays and sets them in the state

        loadcurveService
            .getHolidays()
            .then((days) => {
                setHolidays(days);
            })
            .catch((e) => {
                if (e.message === "401") {
                    return history.push(`/logout`);
                }
                console.error(e);
                dispatch(snackbarActions.openSnackbar(e.message, "error"));
            });
    }, []);
    return (
        <>
            {holidays === undefined ? (
                <CircularProgress data-testid="loading" />
            ) : (
                <FormControl
                    fullWidth={true}
                    required
                    error={holidayRegion === "" || holidayRegion === "0"}
                >
                    <InputLabel id="bundesland">
                        {lang[locale].holidayRegion}
                    </InputLabel>
                    <Select
                        labelId="bundesland"
                        id="bundeslandselect"
                        value={holidayRegion ? holidayRegion : ''}
                        onChange={handleChange}
                    >
                        {/* <MenuItem value="0">
                            {lang[locale].notSelected}
                        </MenuItem> */}
                        {holidays.map((day) => (
                            <MenuItem key={day} value={day}>
                                {day}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </>
    );
}
