import SnackbarModel from "typeDefs/SnackbarModel";
import { AnyAction } from "redux";
import { snackbarConstants } from "constants/snackbar.constants";

const initialState: SnackbarModel = {
    open: false,
    color: "",

};
/**
 * Reducer for opening and closing the snackbar
 * @param state State
 * @param action Action
 */
export function snack(state: SnackbarModel = initialState, action: AnyAction) {
    switch (action.type) {
        case snackbarConstants.OPEN:
            return {
                ...state,
                open: true,
                message: action.message,
                color: action.color
            };
        case snackbarConstants.CLOSE:
            return {
                ...state,
                open: false
            };
        default:
            return state;
    }
}
