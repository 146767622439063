import CreateForecastModel from "../../typeDefs/CreateForecastModel";
import {
    addDays,
    addHours,
    addMinutes,
    differenceInCalendarDays,
    parse,
    differenceInDays,
} from "date-fns";
import JsonReturn from "../../typeDefs/JsonReturn";
import XLSX from "xlsx";
import Papa from "papaparse";
import ContextData from "../../typeDefs/ContextData";
import FleximportData, { timeChangeData } from "../../typeDefs/FleximportData";
import { config } from "../helpers/Constants";
import FleximportFPLArray from "../../typeDefs/FleximportFPLArray";
import PriceCurveModel from "../../typeDefs/PriceCurveModel";
import ForecastInformation from "../../typeDefs/ForecastInformation";
import CheckLoadcurveFormular from "../../typeDefs/CheckLoadcurveFormular";
import errorCodeTranslator from "../helpers/errorCodeTranslator";
import { lang } from "../../lang";
import { constantsReducerInitialStateType } from "../../reducers/constants.reducer";

interface CreateResponse {
    message: string;
}

class ForecastService {
    /**
     * Is responsable for getting the status of a created forecast
     * @param name the name of the forecast
     */

    public async getStatus(name: string): Promise<any> {
        const res = await fetch(
            config.url.API_URL + "/api/personal/data/forecast/name",
            {
                method: "POST",
                body: JSON.stringify({
                    name,
                }),
            }
        );
        if (res.status === 204) {
            // console.log("in 204");
            return 2;
        }
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        // console.log("res", res);
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;

        /*if (data.message.status === "0") resolve(name);
            else if (data.message.status === "1") reject(name);*/
    }

    /**
     * Gets information from api and returns
     * the informationstring to the caller
     * @param zrid
     */
    public async getForecastInformation(
        zrid: number
    ): Promise<any> {
        const res = await fetch(
            config.url.API_URL + "/api/personal/data/forecast/" + zrid
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status === 204) {
            return [];
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    //checks if two dates are the same one
    public checkSameDay(first: Date, second: Date, cond: boolean = false): boolean {
        if (cond) {
        // console.log("day1", "month1", "year1", first.getDate(), first.getMonth(), first.getFullYear());
        // console.log("day2", "month2", "year2", second.getDate(), second.getMonth(), second.getFullYear());
        // console.log("day", "month", "year", first.getDate() === second.getDate(), first.getMonth() === second.getMonth(), first.getFullYear() === second.getFullYear());
        }
        return (
            first.getDate() === second.getDate() &&
            first.getMonth() === second.getMonth() &&
            first.getFullYear() === second.getFullYear()
        );
    }

    /**
     * formats given date to yyyy-MM-dd
     */
    public formatDate(date): Promise<Date> {
        let d = parse(date, "dd.MM.yyyy", new Date());
        return new Promise((resolve, reject) => {
            /* resolve(format(d, "yyyy-MM-dd")); */
            resolve(d);
        });
    }

    /**
     * Creates forecast either with or without a forecast
     * flex is the route to only import a forecast
     * @param data
     * @param route either flex or inipro
     * @param customerId for creating for customers
     */
    public async createForecast(
        data: CreateForecastModel,
        route: "inipro" | "flex",
        customerId?: number
    ): Promise<CreateResponse> {
        console.debug(
            "Submitting forecast with data and to route /" + route,
            data
        );
        const rightRoute =
            "/api/" +
            (customerId
                ? "admin/" + customerId + "/forecast/" + route
                : "upload/" + route);
        const res = await fetch(config.url.API_URL + rightRoute, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(data),
        });
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200)
            throw {
                message: errorCodeTranslator(json.message),
            };

        return json;
    }
    /**
     * Creates forecast either with or without a forecast
     * flex is the route to only import a forecast
     * @param data
     * @param route either flex or inipro
     * @param customerId for creating for customers
     */
    public async createForecastDirect(
        data: CreateForecastModel,
        route: "direct",
        customerId?: number
    ): Promise<CreateResponse> {
        console.debug(
            "Submitting forecast with data and to route /" + route,
            data
        );
        const res = await fetch(config.url.API_URL + "/api/admin/base/upload/inipro", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(data),
        });
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200)
            throw {
                message: errorCodeTranslator(json.message),
            };

        return json;
    }

    /**
     * Puts input xlsx file to json
     */
    public handleXLSXToJson(file: File): Promise<JsonReturn> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (filereadere) {
                if (filereadere.target) {
                    // Declare vars for overriding
                    let keys: string[] = [];
                    let maxKeyLength = 0;
                    // Create the array from the buffer
                    const data = new Uint8Array(
                        filereadere.target.result as ArrayBuffer
                    );
    
                    // Create the workbook
                    const workbook = XLSX.read(data, { type: "array" });
    
                    // Get the json from the xlsx
                    const json: Object[] = XLSX.utils.sheet_to_json(
                        workbook.Sheets[workbook.SheetNames[0]],
                        { raw: false, header: 1 }
                    );
    
                    // Function to format dates
                    const formatDate = (dateString: string): string => {
                        // Regular expression to match dates in mm/dd/yyyy or mm/dd/yy format
                        const regex = /(\d{1,2})\/(\d{1,2})\/(\d{2,4})/;
                        return dateString.replace(regex, (match, p1, p2, p3) => {
                            // Pad single digit day and month with a leading zero
                            const day = p2.length === 1 ? `0${p2}` : p2;
                            const month = p1.length === 1 ? `0${p1}` : p1;
                            // Ensure the year is in yyyy format
                            const year = p3.length === 2 ? `20${p3}` : p3;
                            return `${day}.${month}.${year}`;
                        });
                    };
    
                    // Process the JSON data to format dates
                    const processedJson = json.map((row: any) => {
                        const processedRow: any = {};
                        for (const key in row) {
                            if (typeof row[key] === 'string' && /\d{1,2}\/\d{1,2}\/\d{2,4}/.test(row[key])) {
                                processedRow[key] = formatDate(row[key]);
                            } else {
                                processedRow[key] = row[key];
                            }
                        }
                        return processedRow;
                    });
    
                    // Go through the data and check for key length
                    for (let i = 0; i < processedJson.length; i++) {
                        if (Object.keys(processedJson[i]).length > maxKeyLength) {
                            maxKeyLength = Object.keys(processedJson[i]).length;
                            keys = Object.keys(processedJson[i]);
                        }
                    }
    
                    const ret: JsonReturn = {
                        data: processedJson,
                        keys: keys,
                    };
                    resolve(ret);
                }
            };
            reader.readAsArrayBuffer(file);
        });
    }

    /**
     * Puts csv to json
     * @param file
     */
    public handleCSVToJson(file: File): Promise<JsonReturn> {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                delimitersToGuess: ["\t", ";", "|", ","],
                transformHeader: (h, i) => {
                    return "Loc " + i;
                },
                dynamicTyping: true,
                complete: function (results) {
                    const data = results.data;

                    let keys: string[] = [];
                    //get random keys
                    for (let i = 0; i < data[0].length; i++) {
                        let key = "SP " + i;
                        keys.push(key);
                    }
                    let returnObj: object[] = [];
                    data.forEach((row) => {
                        let pushObj = {};
                        for (let i = 0; i < row.length; i++) {
                            pushObj[keys[i]] = row[i];
                        }
                        returnObj.push(pushObj);
                    });
                    let ret: JsonReturn = {
                        data: returnObj,
                        keys: keys,
                    };
                    resolve(ret);
                },
            });
        });
    }

    /**
     * Calculates given value with a unit to KW
     * @param unit Unit "kWh, KW, MW, mwH"
     * @param value Value which should get calculated
     * @param time In which time for KWH and MWH only
     */
    public static calculateToKw(
        unit: string,
        value: number,
        time: number = 0
    ): number {
        let ret: number;
        switch (unit) {
            case "kWh":
                ret = value / time;
                break;
            case "MWH":
                ret = (value / time) * 1000;
                break;
            case "kW":
                ret = value;
                break;
            case "MW":
                ret = value * 1000;
                break;
            default:
                ret = value;
        }

        return ret;
    }

    /**
     * Calculates given unit to kwH
     * @param unit Unit either kwh, mw, mwh, kw
     * @param value
     * @param factor
     */
    public static calculateToKwH(
        unit: string,
        value: number,
        factor: number = 0
    ): number {
        let ret: number;

        switch (unit) {
            case "kWh":
                ret = value;
                break;
            case "MW":
                ret = value * factor * 1000;
                break;
            case "kW":
                ret = value * factor;
                break;
            case "MWH":
                ret = value * 1000;
                break;
            default:
                ret = value;
        }

        return ret;
    }

    public static roundDate(d: Date): Date {
        return new Date(d.getFullYear(), d.getMonth(), d.getDate());
    }

    /**
     * Calculates 24 or 1 value per day to 96 values per day
     */
    public calculateToRightAmount(
        data: FleximportFPLArray[],
        //factor is normalFactor (1, 24 or 96)
        factor: number,
        unit: string,
        gas: boolean
    ): object[] {
        const array: object[] = [];

        /* Berechnet die Zeitumstellungstage im März und Oktober */

        let startDate = data[0].date;
        let endDatePointer = data[data.length-1].date;
        let endDate = gas? addDays(new Date(
            endDatePointer.getFullYear(),
            endDatePointer.getMonth(),
            endDatePointer.getDate(),
            6
        ),1) : data[data.length-1].date;

        let marchDays = ForecastService.findDaylightSavingSundays(
            ForecastService.roundDate(startDate),
            endDate,
            3
        );
        let octoberDays = ForecastService.findDaylightSavingSundays(
            ForecastService.roundDate(startDate),
            endDate,
            10
        );
        let daylightSavingSundays = marchDays
            .concat(octoberDays)
            .sort((a, b) => (a > b ? 1 : -1))
            .reverse();

        let lastSunday: Date = new Date(2000, 1, 1);
        //Gives lastSunday initial value
        if (daylightSavingSundays.length > 0) {
            let val = daylightSavingSundays.pop();
            lastSunday = val ? val : data[0].date;
        }

        //add the 6 or 7 hours to beginning
        if (gas) {
            let hours = 6;
            if (
                data[0].date.getMonth() === 9 &&
                this.checkSameDay(lastSunday, data[0].date)
            ) {
                hours = 7;
            }
            for (let i = 0; i < hours * 4; i++) {
                array.unshift({
                    value: 0,
                    date: new Date(),
                });
            }
        }

        switch (factor) {
            //Stundenfahrplan
            case 24:
                for (let i = 0; i < data.length; i++) {
                    let value = data[i].value;
                    //check if type is string, if yes, replace stuff
                    if (typeof value === "string") {
                        value = value.replace(/,/g, ".");
                        value = parseFloat(value);
                    }
                    //calculate the right value
                    let calculated = ForecastService.calculateToKw(
                        unit,
                        value,
                        1
                    );

                    for (let z = 0; z < 4; z++) {
                        let obj: FleximportFPLArray = {
                            value: 0,
                            date: data[i].date,
                        };
                        Object.assign(obj, data[i]);
                        obj.value = calculated;
                        array.push(obj);
                    }
                }

                break;

            //Tagesfahrplan
            case 1:
                for (let i = 0; i < data.length; i++) {
                    //Changes the timestamp in gas to 6 in the morning
                    let currentDateStart = gas
                        ? new Date(
                              data[i].date.getFullYear(),
                              data[i].date.getMonth(),
                              data[i].date.getDate(),
                              6,
                              0,
                              0
                          )
                        : data[i].date;

                    //sets the new lastSunday if date is bigger than the lastSunday
                    if (
                        daylightSavingSundays.length > 0 &&
                        // we need a check for hours
                        currentDateStart > lastSunday
                    ) {
                        let val = daylightSavingSundays.pop();
                        lastSunday = val ? val : currentDateStart;
                    }

                    let time = 24;
                    let value = data[i].value;
                    let factor = 96;

                    //check if type is string, if yes, replace stuff
                    if (typeof value === "string") {
                        value = value.replace(/,/g, ".");
                        value = parseFloat(value);
                    }

                    // DEBUG
                    /*
                    if (currentDateStart.getMonth() === 9) {
                        console.log("currentDateStart", currentDateStart);
                        console.log("currentDateStart + 1", addDays(currentDateStart, 1));
                        console.log("lastSunday", lastSunday);
                        console.log("condition", "first", "second", currentDateStart.getMonth() === 9 &&
                            this.checkSameDay(
                                addDays(currentDateStart, 1),
                                lastSunday,
                                false
                            ), currentDateStart.getMonth() === 9, this.checkSameDay(addDays(currentDateStart, 1), lastSunday));
                    }
    */
                    if (
                        currentDateStart.getMonth() === 2 &&
                        this.checkSameDay(
                            addDays(currentDateStart, 1),
                            lastSunday
                        )
                    ) {
                        time = 23;
                        //Freitag Änderung: factor 92 hinzugefügt in der Hoffnung, dass dann die doppelte 6. Stunde verschwindet.
                        factor = 92;
                    } else if (
                        currentDateStart.getMonth() === 9 &&
                        this.checkSameDay(
                            addDays(currentDateStart, 1),
                            lastSunday
                        )
                    ) {
                        //Wenn der erste Tag der Zeitumstellungstag im Oktober ist setzte den Faktor und Time auf 96
                        factor = this.checkSameDay(lastSunday, data[0].date)
                            ? 96
                            : 100;
                        time = this.checkSameDay(lastSunday, data[0].date)
                            ? 24
                            : 25;
                    }

                    //calculate the right value
                    let calculated = ForecastService.calculateToKw(
                        unit,
                        value,
                        time
                    );

                    let z = 0;

                    //In gas it needs to be the value 20 hours later that is 20 * 4  = 80 values later
                    const insertValue = gas ? 80 : 8;
                    //loop for 96 quarter values
                    while (z < factor) {
                        let currentDate = gas
                            ? addMinutes(currentDateStart, z * 15)
                            : data[i].date;
                        let obj: FleximportFPLArray = {
                            value: 0,
                            date: currentDate,
                        };

                        if (
                            currentDate.getMonth() === 2 &&
                            this.checkSameDay(currentDate, lastSunday) &&
                            z >= insertValue &&
                            z < insertValue + 1
                        ) {
                            array.push({
                                value: 0,
                                date: currentDate,
                            });
                            array.push({
                                value: 0,
                                date: addMinutes(currentDate, 15),
                            });
                            array.push({
                                value: 0,
                                date: addMinutes(currentDate, 30),
                            });
                            array.push({
                                value: 0,
                                date: addMinutes(currentDate, 45),
                            });
                        }
                        Object.assign(obj, data[i]);
                        obj.value = calculated;
                        obj.date = currentDate;
                        array.push(obj);
                        z++;
                    }
                }
                break;
            case 96:
                for (let i = 0; i < data.length; i++) {
                    let value = data[i].value;
                    //check if type is string, if yes, replace stuff
                    if (typeof value === "string") {
                        value = value.replace(/,/g, ".");
                        value = parseFloat(value);
                    }
                    let obj: FleximportFPLArray = {
                        value: 0,
                        date: data[i].date,
                    };
                    Object.assign(obj, data[i]);
                    obj.value = ForecastService.calculateToKw(
                        unit,
                        value,
                        0.25
                    );
                    array.push(obj);
                }
                break;
            default:
                return data;
        }
        if (gas) {
            //add the 18 hours at the end
            for (let i = 0; i < 18 * 4; i++) {
                array.push({
                    value: 0,
                    date: new Date(),
                });
            }
        }
        // console.log("Result Array", array);
        return array;
    }

    /**
     * checks if usage is ok
     * @param usage usage as number
     */
    public static checkUsage(usage: number): boolean {
        const maxValue = 10000000000;
        return (
            usage === undefined ||
            usage === 0 ||
            usage.toString() === "-" ||
            usage < 0 ||
            usage > maxValue ||
            usage.toString() === ""
        );
    }

    /**
     * Checks name field for creating forecasts
     * returns true if wrong
     * @param name
     * @param min_forecast_name_length
     * @param max_forecast_name_length
     */
    public static checkName(
        name: string,
        min_forecast_name_length: Number,
        max_forecast_name_length: number
    ): boolean {
        const re = new RegExp(
            "^[a-zA-Z0-9-]" +
                "{" +
                min_forecast_name_length +
                "," +
                max_forecast_name_length +
                "}$"
        );
        return name === "" || !re.test(name);
    }

    /**
     * Checks performance
     * @param performance performance as number
     */
    public static checkPerformance(performance: number): boolean {
        const maxValue = 10000000000;
        const minValue = 1;
        return (
            performance === undefined ||
            performance === 0 ||
            performance.toString() === "" ||
            performance.toString() === "-" ||
            performance < 0 ||
            performance > maxValue ||
            performance < minValue
        );
    }

    public static zellersCongruence(year: number, month: number, day: number): number {
        const K = year % 100;
        const J = Math.floor(year / 100);
        const h =
            day +
            Math.floor((13 * (month + 1)) / 5.0) +
            K +
            Math.floor(K / 4) +
            Math.floor(J / 4) -
            2 * J;
        return ((h + 5) % 7) + 1;
    }

    public static findDaylightSavingSundays(
        startDate: Date,
        endDate: Date,
        month: number
    ): Date[] {
        let daylightSavingArray: Date[] = [];

        if (startDate > endDate) {
            return daylightSavingArray;
        }
        let year = startDate.getFullYear();
        for (; year < endDate.getFullYear() + 1; year++) {
            const weekday = ForecastService.zellersCongruence(year, month, 25);
            const sundayOffset = (7 - weekday) % 7;
            const expectedDate = new Date(
                year,
                month - 1,
                25 + sundayOffset,
                2
            );
            // console.log("expected date", expectedDate);
            if (
                !(expectedDate > endDate ||
                    expectedDate < startDate) ||
                    expectedDate === endDate
                ) {
                daylightSavingArray.push(expectedDate);
            }
        }
        return daylightSavingArray;
    }

    /*
     *
     * Transforms the raw data of the fleximport into a readable FPL Format. Specifically:
     * 1. Adds Zeros to the beginning of the data
     * 2. Checks if and where to put Zeros at the last Sundays of March and October
     * 3. Add Zeros to the end of the data
     *
     */

    public jsonToFPLJson(
        fleximportData: FleximportData,
        gas: boolean
    ): Promise<object[]> {
        return new Promise((resolve, reject) => {
            /*
             *
             * CHECKS IF THE INPUT IS VALID
             *
             */

            // console.log("fleximportData", fleximportData, "gas", gas);
            if (
                fleximportData.startValue === undefined ||
                fleximportData.endValue === undefined ||
                fleximportData.startDateValue === null ||
                fleximportData.endDateValue === null
            )
                return reject("Not enough values");

            /*
             *
             * VARIABLE DEFINITIONS AND INITIALIZAITONS
             *
             */

            //The column of the values
            const indexOfValues = fleximportData.startValue.colIndex;

            //The row of the first value
            let startValueRowIndex = fleximportData.startValue.rowIndex;

            //The raw data
            const data = fleximportData.rows;
            const keys = fleximportData.cols;

            //The starting date of the IST Data
            let date = fleximportData.startDateValue;
            let endDate = gas ? addHours(addDays(fleximportData.endDateValue, 1), 6) : addHours(fleximportData.endDateValue, 23);
            // Sets the starting date to 12pm or 6am
            date =
                gas && fleximportData.normalFactor !== 1
                    ? addHours(date, 6)
                    : date;


            //create a new array where we are just creating a new array with the values and dates
            //A new array for the transformed data to push into
            const newArray: FleximportFPLArray[] = [];

            //increment ist true oder false.
            // Wenn ein Zeitumstellungstag zur 3. Stunde vorkommt, wird nicht hochgezählt
            // Wenn kein Zeitumstellungstag vorkommt wird hochgezählt
            let increment;

            //needed for adding the right values in OCTOBER
            let addFactor = 0;

            /*
             *
             * Results in a list for march and october daylightsaving days
             *
             */



            let marchDays = ForecastService.findDaylightSavingSundays(
                date,
                endDate,
                3
            );
            let octoberDays = ForecastService.findDaylightSavingSundays(
                date,
                endDate,
                10
            );
            let combinedList = marchDays
                .concat(octoberDays)
                .sort((a, b) => (a > b ? 1 : -1))
                .reverse();

            // console.log(
            //     "daylightsaving days combined-sort-reversed",
            //     combinedList
            // );
            let lastSunday: Date = new Date(2000, 1, 1);
            if (combinedList.length > 0) {
                let val = combinedList.pop();
                lastSunday = val ? val : date;
            }

            /*
             * z läuft einen Tag ab.
             * > Bei einem Viertelstundenfahrplan ist z = 4 * 24 also 96 Werte
             * > Bei einem Stundenfahrplan ist z = 24 Werte
             * > Bei einem Stundenfahrplan ist z = 1 Werte
             *
             * > z muss zu Beginn auf 0 oder 6 initialisiert werden.
             *
             * > z ist dafür zuständig zu ermittlen, zu welcher Uhrzeit die Nullen zum Zeitumstellungstag
             * > eingefügt werden.
             */

            let z = gas && fleximportData.normalFactor !== 1 ? 6 : 0;

            /*
             *
             * Prüft wieviele Nullen im März eingefügt werden müssen.
             * > NormalFactor: Ist 96, 24, oder 1 je nach Fahrplan.
             * > Bei einem Viertelstundenfahrplan müssen 4 Nullen eingefügt werden.
             * > Bei einem Stundenfahrplan muss eine 0 eingefügt werden.
             * > Bei einem Tagesfahrplan muss keine 0 eingefügt werden.
             */

            const firstValue = fleximportData.normalFactor === 96 ? 8 : 2;

            const lastValue =
                firstValue + (fleximportData.normalFactor === 96 ? 4 : 1);

            /*
             * 1. WHILE SCHLEIFE:
             * > Läuft einmal durch alle Zeilen durch
             */

            while (startValueRowIndex <= fleximportData.endValue.rowIndex) {
                /*
                 * If Bedingung prüft, ob das Array der Zeitumstellungstage (combinedList)
                 * gefüllt ist und ob das aktuelle Datum der Zeile größer ist als das letzte
                 * Datum im Array.
                 *
                 * Wenn das der Fall ist wird der letzte Wert aus dem Zeitumstellungstage Array
                 * entfernt und gleich der Varibale lastSunday gesetzt.
                 *
                 * Last Sunday ist jetzt der aktuelle Zeitumstellungstag.
                 *
                 * Frage: Kann lastSunday gleich date gesetzt werden?
                 */

                if (
                    combinedList.length > 0 &&
                    date > lastSunday
                ) {
                    let val = combinedList.pop();
                    lastSunday = val ? val : date;
                }

                // Wenn ein Tag abgelaufen ist muss z auf 0 zurückgesetzt werden
                z = z === fleximportData.normalFactor ? 0 : z;

                /*
                 * 2. WHILE SCHLEIFE
                 * Läuft einmal durch einen Tag durch.
                 *
                 * Fuegt die Nullen an den Zeitumstellungstagen hinzu
                 * normalFactor ist 1, 24 oder 96 - je nachdem ob es ein Tages, Stunden- oder
                 * Viertelstundenfahrplan ist
                 */

                while (
                    z < fleximportData.normalFactor &&
                    startValueRowIndex <= fleximportData.endValue?.rowIndex
                ) {
                    //Frage: Was macht increment?
                    increment = true;

                    //Ein neues Objekt welches in das newArray geschoben wird
                    let obj = {
                        date: date,
                        value: data[startValueRowIndex][keys[indexOfValues]],
                    };

                    /* if (this.checkSameDay(new Date(2020, 2, 29), date)) {
                        console.log("Datum Iteration", date, z);
                    } */

                    /*  if (gas) */

                    //ZEITUMSTELLUNGSTAG MÄRZ
                    if (
                        date.getMonth() === 2 &&
                        fleximportData.normalFactor !== 1
                    ) {
                        //lastSunday.setDate(lastSunday.getDate() - 1);
                        // Checks if the missingHours checkmark is set and
                        // if z is in between the hour of time change.
                        if (
                            fleximportData.checked.missingHours &&
                            this.checkSameDay(date, lastSunday) &&
                            z >= firstValue &&
                            z < lastValue
                        ) {
                            // Object for putting in 0 values at the Zeitumstellungstag
                            const nullObj = {
                                date: date,
                                value: 0,
                            };

                            if (fleximportData.normalFactor === 24) {
                                newArray.push(nullObj);
                                newArray.push(obj);
                            } else {
                                newArray.push(nullObj);
                                newArray.push(nullObj);
                                newArray.push(nullObj);
                                newArray.push(nullObj);
                                z = lastValue;

                                newArray.push(obj);
                            }

                            /*  increment = false; */
                        } else {
                            newArray.push(obj);
                        }
                        //ZEITUMSTELLUNGSTAG OKTOBER
                    } else if (
                        date.getMonth() === 9 &&
                        fleximportData.normalFactor !== 1
                    ) {
                        //checks if the rows in during the
                        //Zeitumstellungstag in October need to be added.
                        if (
                            !fleximportData.checked.doubleHours &&
                            this.checkSameDay(date, lastSunday) &&
                            z >= firstValue &&
                            z < lastValue &&
                            fleximportData.normalFactor !== 1
                        ) {
                            //
                            const pushObj = {
                                date,
                                value:
                                    data[startValueRowIndex + addFactor][
                                        keys[indexOfValues]
                                    ],
                            };
                            newArray.push(pushObj);

                            addFactor = addFactor + 1;
                            increment = false;
                        } else {
                            newArray.push(obj);
                        }
                    } else {
                        newArray.push(obj);
                    }

                    //Zählt die Uhrzeit hoch
                    if (fleximportData.normalFactor !== 1) {
                        date =
                            fleximportData.normalFactor === 96
                                ? addMinutes(date, 15)
                                : addHours(date, 1);
                    }
                    z++;
                    if (increment) startValueRowIndex++;
                }

                //Zählt den Tag hoch im Tagesfahrplan
                if (fleximportData.normalFactor === 1) {
                    date = addDays(date, 1);
                }
            }
            // console.log("newArray", newArray);
            resolve(
                this.calculateToRightAmount(
                    newArray,
                    fleximportData.normalFactor,
                    fleximportData.unit,
                    gas
                )
            );
        });
    }

    /**
     * Checks if the data is okay, if no returns true
     * @param data data which should get checked
     * @param forecastType the forecasttype to check
     */
    public checkLoadcurveUploadButtonDisabled(
        data: CreateForecastModel,
        forecastType,
        constants: constantsReducerInitialStateType
    ): CheckLoadcurveFormular {
        let disabled = false;
        //for every forecasttype other specifications
        switch (forecastType) {
            case 1:
            case 2:
                if (
                    data.holidayRegion === undefined ||
                    data.holidayRegion === "0" ||
                    data.holidayRegion === ""
                ) {
                    disabled = true;
                }
                break;
            case 3:
                if (
                    data.holidayRegion === undefined ||
                    data.holidayRegion === "" ||
                    data.holidayRegion === "0"
                ) {
                    disabled = true;
                }
                if (data.profile === 0 || data.profile === undefined) {
                    disabled = true;
                }
                if (ForecastService.checkUsage(data.usage)) {
                    disabled = true;
                }
                break;
            case 4:
                if (
                    data.usageType === 1 &&
                    (data.measuredFrom === null ||
                        data.measuredTo === null ||
                        isNaN(data.measuredFrom.valueOf()) ||
                        isNaN(data.measuredTo.valueOf()) ||
                        data.measuredFrom > data.measuredTo ||
                        data.measuredTo > new Date(Date.now() - 7 * 86400000))
                ) {
                    disabled = true;
                }
                if (data.profile === 0 || data.profile === undefined) {
                    disabled = true;
                }
                if (
                    data.usageType === undefined ||
                    data.usageType > 2 ||
                    data.usageType < 1
                ) {
                    disabled = true;
                }
                if (
                    data.usageType === 1 &&
                    ForecastService.checkUsage(data.usage)
                ) {
                    disabled = true;
                }
                if (
                    data.usageType === 2 &&
                    ForecastService.checkUsage(data.usage)
                ) {
                    disabled = true;
                }
                if (
                    data.temperatureStation === undefined ||
                    data.temperatureStation.toString() === "0" ||
                    data.temperatureStation.toString() === ""
                ) {
                    disabled = true;
                }
                if (
                    data.variant === undefined ||
                    data.variant === 0 ||
                    data.variant.toString() === ""
                ) {
                    disabled = true;
                }
                break;
            case 6:
                if (
                    data.performance === undefined ||
                    ForecastService.checkPerformance(data.performance)
                ) {
                    disabled = true;
                }
                if (
                    data.productType === undefined ||
                    data.productType < 0 ||
                    data.productType > 1
                ) {
                    disabled = true;
                }
                break;
            case 8:
                if (
                    data.temperatureStation === undefined ||
                    data.temperatureStation.toString() === "0" ||
                    data.temperatureStation.toString() === ""
                ) {
                    disabled = true;
                }
                break;
            default:
                disabled = true;
        }
        if (data.progTo !== null) {
            if (isNaN(data.progTo.valueOf())) {
                disabled = true;
            }
        }
        if (data.progFrom !== null) {
            if (isNaN(data.progFrom.valueOf())) {
                disabled = true;
            }
        }
        if (data.progTo === null || data.progFrom === null) {
            disabled = true;
        }
        if (data.progFrom > data.progTo && data.progTo !== null) {
            disabled = true;
        }
        /* if (
            data.progFrom &&
            data.progTo &&
            this.checkSameDay(data.progFrom, data.progTo)
        ) {
            disabled = true;
        } */
        if (data.priceCurve === undefined || data.priceCurve === "0") {
            disabled = true;
        }
        if (
            ForecastService.checkName(
                data.name,
                constants.min_name_length ? constants.min_name_length : 3,
                constants.max_name_length ? constants.max_name_length : 50
            )
        ) {
            disabled = true;
        }
        return {
            disabled,
        };
    }

    /**
     * Checks if fleximport send button should be disabled
     * @param data
     */
    public checkFleximportSendButtonDisabled(
        data: FleximportData,
        minDate: Date,
        maxDate: Date,
        forecastType: number
    ) {
        // console.log("in checkDisabled");
        return (
            data.startValue === undefined ||
            data.endValue === undefined ||
            data.startDateValue === null ||
            data.endDateValue === null ||
            data.endDateValue === undefined ||
            data.startDateValue === undefined ||
            data.unit === "" ||
            this.checkValues(data.startValue, data.endValue) ||
            this.checkFromDate(
                data.startDateValue,
                data.endDateValue,
                minDate,
                forecastType
            ).length !== 0 ||
            this.checkToDate(data.endDateValue, maxDate, forecastType)
                .length !== 0 ||
            data.actualCount !== data.neededCount
        );
    }

    /**
     * Checks from date value of fleximport
     * @param from starting date
     * @param to end date
     */

    public checkFromDate(
        from: Date,
        to: Date | null,
        min: Date,
        forecastType: number,
        stateData?,
        toDay?,
        tommorow?,
    ): string[] {
        const locale = localStorage.locale;
        const check: string[] = [];
        if (to !== null && from > to) {
            check.push(lang[locale].dateWrongError);
        }
        if (isNaN(from.valueOf())) {
            check.push(lang[locale].invalidDate);
        }
        if ((forecastType !== 1 && forecastType !== 8)  && stateData?.toLowerCase().indexOf("spot") === -1 && from < min) {
            check.push(
                lang[locale].smallerThanMinDate +
                " " +
                min.toLocaleString("de-DE", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                })
                );
            }
            if (
                (forecastType !== 1 && forecastType !== 8) && differenceInDays(from,tommorow) < 0 &&
                stateData?.toLowerCase().indexOf("spot") !== -1
                ) {
            check.push(
              lang[locale].smallerThanMinDate + " ",
              tommorow.toLocaleString("de-DE", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            );
          }
        return check;
    }

    /**
     * Checks to date value of fleximport
     * @param to end date
     */

    public checkToDate(to: Date, max: Date, forecastType: number, stateData?, endOfNextFourYears?): string[] {
        const locale = localStorage.locale;
        const check: string[] = [];
        if (isNaN(to.valueOf())) {
            check.push(lang[locale].invalidDate);
        }
        if (forecastType === 0 && stateData?.toLowerCase().indexOf("spot") === -1 && to > max) {
            check.push(
                lang[locale].biggerThanMaxDate +
                    " " +
                    max.toLocaleString("de-DE", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                    })
            );
        }
        if (
            differenceInDays(to, endOfNextFourYears) > 0 &&
            stateData?.toLowerCase().indexOf("spot") !== -1
          ) {
            check.push(
              lang[locale].biggerThanMaxDate +
                " " +
                // lang[locale].biggerThanMaxDateTest + " " +
                endOfNextFourYears.toLocaleString("de-DE", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
            );
          }
        return check;
    }

    /**
     * Checks selected values in fleximport
     * @param startValue starting value
     * @param endValue ending value
     */
    public checkValues(
        startValue: ContextData | undefined,
        endValue: ContextData | undefined
    ) {
        return (
            startValue && endValue && startValue.rowIndex > endValue.rowIndex
        );
    }

    /**
     * Calculates amount of energy in kwh
     * @param data Fleximport data
     */
    public calculateEnergyAmount(data: FleximportData): number | undefined {
        const cols = data.cols;
        const rows = data.rows;
        const startValue = data.startValue;
        const endValue = data.endValue;
        if (startValue && endValue && data.normalFactor > 0 && data.unit) {
            let amount = 0;
            const factor =
                data.normalFactor === 96
                    ? 0.25
                    : data.normalFactor === 24
                    ? 1
                    : 24;
            for (let i = startValue.rowIndex; i < endValue.rowIndex + 1; i++) {
                let value = rows[i][cols[startValue.colIndex]];
                if (typeof value === "string") {
                    value = parseFloat(
                        rows[i][cols[startValue.colIndex]].replace(/,/g, ".")
                    );
                }
                //check for unit and based on that, recalculate to the right kwh value
                amount += ForecastService.calculateToKwH(
                    data.unit,
                    value,
                    factor
                );
            }
            //check if kw or mw and round to full at kw and to 3 digits for mw
            const rounded = data.unit.toLowerCase().includes("kw")
                ? amount.toFixed(0)
                : amount.toFixed(3);
            return parseFloat(rounded);
        }
        return undefined;
    }

    /**
     * Calculates needed count for rows in fleximport
     * @param data Fleximport data with all the values
     * @param gas is gas or power?
     * Returns fleximportdata
     */
    public calculateNeededCount(
        data: FleximportData,
        gas: boolean
    ): FleximportData | void {
        //the row ids of days where time gets changed
        const marchRowIds: timeChangeData[] = [];
        const octoberRowIds: timeChangeData[] = [];
        const firstDate = data.startDateValue;
        let secondDate = data.endDateValue;
        //some initialization
        let normal = 0;
        let less = 0;
        let more = 0;
        let count = 0;
        let dateDiff: number | null = null;

        if (
            secondDate &&
            firstDate &&
            secondDate >= firstDate &&
            data.startValue &&
            data.endValue
        ) {

            // console.log("first", firstDate);
            // console.log("second", secondDate);
            dateDiff = differenceInCalendarDays(secondDate, firstDate) + 1;
            let calculateTimeDifference = true;
            secondDate = gas? addHours(addDays(secondDate, 1), 23) : addHours(secondDate, 23);
            let DLSM = ForecastService.findDaylightSavingSundays(firstDate, secondDate, 3);
            let DLSO = ForecastService.findDaylightSavingSundays(firstDate, secondDate, 10);
            //if both of this exist, calculate the count of rows which are marked
            const vals = data.endValue.rowIndex - data.startValue.rowIndex + 1;
            // DEBUG
            // console.log("DLSM", DLSM);
            // console.log("DLSO", DLSO);
            // console.log("vals", vals);
            // console.log("dateDiff", dateDiff);
            // console.log("calculated", vals/dateDiff + 1);

            //Finds out if we are in the 96, 24 or day plan.
            if (Math.floor(vals / dateDiff) + 1 <= 101 && Math.floor(vals / dateDiff) + 1 > 92) {
                normal = 96;
                less = 92;
                more = 100;
            } else if (Math.floor(vals / dateDiff) === 1) {
                normal = 1;
                calculateTimeDifference = false;
            } else if (Math.floor(vals / dateDiff) + 1 <= 26 && Math.floor(vals / dateDiff) + 1 > 23) {
                normal = 24;
                less = 23;
                more = 25;
            }
            if (dateDiff) {
                //check the first value where to insert null values
                const powerFirst = normal === 96 ? 12 : 6;
                const gasFirst = powerFirst * 4;
                const firstValue = gas ? gasFirst : powerFirst;
                //go through every day
                for (let i = 0; i < dateDiff; i++) {
                    let addedDate = addDays(firstDate, i);
                    //if count per day is 1 time difference dont has to be calculated
                    if (!calculateTimeDifference) {
                        count = dateDiff;
                        break;
                    }
                    const lastSunday = new Date(
                        addedDate.getFullYear(),
                        addedDate.getMonth() + 1,
                        0
                    );
                    lastSunday.setDate(
                        lastSunday.getDate() - lastSunday.getDay()
                    );
                    //subtract 1 if gas, because its for gas plans technically the last day
                    if (gas) lastSunday.setDate(lastSunday.getDate() - 1);

                    //check if added date is month March or October
                    if (addedDate.getMonth() === 2) {
                        //check if day is ok
                        if (this.checkSameDay(addedDate, lastSunday)) {
                            let row =
                                (i - 1) * normal +
                                firstValue +
                                (!data.checked.missingHours ? less : normal);
                            if (gas) row = normal === 24 ? row + 2 : row + 8;
                            marchRowIds.push({
                                row,
                                date: addedDate.toLocaleDateString(),
                            });
                        }

                        //check if null values are enabled and last sunday
                        if (
                            !data.checked.missingHours &&
                            this.checkSameDay(addedDate, lastSunday)
                        ) {
                            count += normal;
                        } else if (
                            data.checked.missingHours &&
                            this.checkSameDay(addedDate, lastSunday)
                        ) {
                            count += less;
                        }
                        else {
                            count += normal;
                        }
                    } else if (addedDate.getMonth() === 9) {
                        //check if day is ok
                        if (this.checkSameDay(addedDate, lastSunday)) {
                            const toSubtract =
                                normal === 24
                                    ? data.checked.missingHours
                                        ? 7
                                        : 8
                                    : data.checked.missingHours
                                    ? 9
                                    : 13;
                            let row = i * normal + firstValue - toSubtract;
                            if (gas) row = normal === 24 ? row + 2 : row + 8;
                            octoberRowIds.push({
                                row,
                                date: addedDate.toLocaleDateString(),
                            });
                        }

                        //same as above
                        if (
                            data.checked.doubleHours &&
                            this.checkSameDay(addedDate, lastSunday)
                        ) {
                            count += more;
                        }
                        else {
                            count += normal;
                        }
                    } else {
                        count += normal;
                    }
                }
            }
            // console.log("calculated needed count: ", count);
            // TODO maybe skip loop in the future
            // console.log("short", dateDiff, normal, DLSM.length, DLSO.length);
            // console.log("short calculated count", dateDiff * normal - (data.checked.missingHours? DLSM.length : 0) + (data.checked.doubleHours? DLSO.length: 0));
            return {
                actualCount: 0,
                checked: {
                    missingHours: true,
                    doubleHours: false,
                },
                cols: [],
                dateDifference: dateDiff,
                endDateValue: null,
                endValue: undefined,
                lessFactor: less,
                moreFactor: more,
                neededCount: count,
                normalFactor: normal,
                rows: [],
                marchTimeChangeRowId: marchRowIds,
                octoberTimeChangeRowId: octoberRowIds,
                startDateValue: null,
                startValue: undefined,
                unit: "",
                energy: 0,
            };
        }
    }

    /**
     * Gets variants based on the profile
     * @param profile type
     */
    async getVariants(profile: number | undefined): Promise<number[]> {
        const res = await fetch(config.url.API_URL + "/api/variant/" + profile);
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Gets pricecurves based on gas or power from the api
     *
     * @param gas
     */
    async getPriceCurves(gas: boolean): Promise<PriceCurveModel[]> {
        const res = await fetch(config.url.API_URL + "/api/curve");
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        const pattern = gas ? 2 : 1;
        return json.filter((curve) => curve.commodity === pattern);
        // return json;
    }
}

export default ForecastService;
