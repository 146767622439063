import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { Button } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { lang } from "../lang";
import UserService from "../services/users/UserService";
import modalActions from "../actions/modal.actions";
import snackbarActions from "actions/snackbar.actions";
import { useDispatch } from "react-redux";
import DeleteBuyTimeDayComponent from "./DeleteBuyTimeDay";
import AuthService from "../services/auth/AuthService";

function BuyTimeLimitComponent(props) {
  const dispatch = useDispatch();
  const locale = localStorage.locale;
  const role = AuthService.getRole();
  const service = new UserService();
  const [initialTimes, setInitialTimes] = useState(undefined);
  const [triggerAdd,setTriggerAdd] = useState(false);
  const [triggerDelete,setTriggerDelete] = useState(false);
  const [Times, setTimes] = useState([
    //@ts-ignore
    { day: 0, trading_start_time: "", trading_end_time: "" },
    { day: 1, trading_start_time: "", trading_end_time: "" },
    { day: 2, trading_start_time: "", trading_end_time: "" },
    { day: 3, trading_start_time: "", trading_end_time: "" },
    { day: 4, trading_start_time: "", trading_end_time: "" },
    { day: 5, trading_start_time: "", trading_end_time: "" },
    { day: 6, trading_start_time: "", trading_end_time: "" },
  ]);
  const HandelSubmit= () => {
    if(role.permissions.includes("SETTINGS")){
      service.updateSupplierBuyTimes( Times.map(i=>{
        if((i.trading_end_time && i.trading_start_time) !== ""){
          return i;
        }
      }).filter(i=>i !== undefined))
      // .then(res => console.log("res",res));
      setTimeout(() => {
        service.getSupplierBuyTimes().then(res=>setInitialTimes(res))
      }, 2000);
    }
    setTriggerAdd(true);
  }

  function DeleteDayHandler(dayindex, day){
    dispatch(
      //@ts-ignore
      modalActions.openModal(
        DeleteBuyTimeDayComponent,
        {
          handleDeleteDay,
          dayindex,
          day,
        },
        2
      )
    );
  }
  
  function handleDeleteDay(day){
    if(role.permissions.includes("SETTINGS")){
    service.deleteSupplierBuyTimeDay(day)
    setTimes([
      //@ts-ignore
      { day: 0, trading_start_time: "", trading_end_time: "" },
      { day: 1, trading_start_time: "", trading_end_time: "" },
      { day: 2, trading_start_time: "", trading_end_time: "" },
      { day: 3, trading_start_time: "", trading_end_time: "" },
      { day: 4, trading_start_time: "", trading_end_time: "" },
      { day: 5, trading_start_time: "", trading_end_time: "" },
      { day: 6, trading_start_time: "", trading_end_time: "" },
    ]);
    setInitialTimes(undefined);
    setTimeout(() => {
      service.getSupplierBuyTimes().then(res=>setInitialTimes(res))
    }, 1000);
  }
  setTriggerDelete(true);
  }


  useEffect(()=>{
    service.getSupplierBuyTimes().then(res=>setInitialTimes(res))
  },[])

  useEffect(()=>{
    if(role.permissions.includes("SETTINGS")){
      if(triggerAdd){
        dispatch(
          snackbarActions.openSnackbar(lang[locale].SuccessfulTimeChange, "success")
          );
          setTriggerAdd(false);
        }
    } else {
      if(triggerAdd){
        dispatch(
          snackbarActions.openSnackbar(lang[locale].customerOrders_missing_permissions, "warning")
          );
          setTriggerAdd(false);
        }
    }
  },[triggerAdd])

  useEffect(()=>{
    if(role.permissions.includes("SETTINGS")){
      if(triggerDelete){
        dispatch(
          snackbarActions.openSnackbar(lang[locale].DeleteTimeDay, "success")
          );
          setTriggerDelete(false);
        }
    } else {
      if(triggerDelete){
        dispatch(
          snackbarActions.openSnackbar(lang[locale].customerOrders_missing_permissions, "warning")
          );
          setTriggerDelete(false);
        }
    }
  },[triggerDelete])



  useEffect(()=>{
    if(initialTimes !== undefined){
      setTimes([
        //@ts-ignore
        { day: 0, trading_start_time: initialTimes !== undefined && initialTimes.find(obj => obj.day === 0) ? initialTimes.find(obj => obj.day === 0)["trading_start_time"].split(":")[0] + ":" +initialTimes.find(obj => obj.day === 0)["trading_start_time"].split(":")[1] : "", trading_end_time: initialTimes !== undefined && initialTimes.find(obj => obj.day === 0) ? initialTimes.find(obj => obj.day === 0)["trading_end_time"].split(":")[0] + ":" +initialTimes.find(obj => obj.day === 0)["trading_end_time"].split(":")[1] : "" },
        //@ts-ignore
        { day: 1, trading_start_time: initialTimes !== undefined && initialTimes.find(obj => obj.day === 1) ? initialTimes.find(obj => obj.day === 1)["trading_start_time"].split(":")[0] + ":" +initialTimes.find(obj => obj.day === 1)["trading_start_time"].split(":")[1] : "", trading_end_time: initialTimes !== undefined && initialTimes.find(obj => obj.day === 1) ? initialTimes.find(obj => obj.day === 1)["trading_end_time"].split(":")[0] + ":" +initialTimes.find(obj => obj.day === 1)["trading_end_time"].split(":")[1] : "" },
        //@ts-ignore
        { day: 2,  trading_start_time: initialTimes !== undefined && initialTimes.find(obj => obj.day === 2) ? initialTimes.find(obj => obj.day === 2)["trading_start_time"].split(":")[0] + ":" +initialTimes.find(obj => obj.day === 2)["trading_start_time"].split(":")[1] : "", trading_end_time: initialTimes !== undefined && initialTimes.find(obj => obj.day === 2) ? initialTimes.find(obj => obj.day === 2)["trading_end_time"].split(":")[0] + ":" +initialTimes.find(obj => obj.day === 2)["trading_end_time"].split(":")[1] : "" },
        //@ts-ignore
        { day: 3, trading_start_time: initialTimes !== undefined && initialTimes.find(obj => obj.day === 3) ? initialTimes.find(obj => obj.day === 3)["trading_start_time"].split(":")[0] + ":" +initialTimes.find(obj => obj.day === 3)["trading_start_time"].split(":")[1] : "", trading_end_time: initialTimes !== undefined && initialTimes.find(obj => obj.day === 3) ? initialTimes.find(obj => obj.day === 3)["trading_end_time"].split(":")[0] + ":" +initialTimes.find(obj => obj.day === 3)["trading_end_time"].split(":")[1] : "" },
        //@ts-ignore
        { day: 4, trading_start_time: initialTimes !== undefined && initialTimes.find(obj => obj.day === 4) ? initialTimes.find(obj => obj.day === 4)["trading_start_time"].split(":")[0] + ":" +initialTimes.find(obj => obj.day === 4)["trading_start_time"].split(":")[1] : "", trading_end_time: initialTimes !== undefined && initialTimes.find(obj => obj.day === 4) ? initialTimes.find(obj => obj.day === 4)["trading_end_time"].split(":")[0] + ":" +initialTimes.find(obj => obj.day === 4)["trading_end_time"].split(":")[1] : "" },
        //@ts-ignore
        { day: 5, trading_start_time: initialTimes !== undefined && initialTimes.find(obj => obj.day === 5) ? initialTimes.find(obj => obj.day === 5)["trading_start_time"].split(":")[0] + ":" +initialTimes.find(obj => obj.day === 5)["trading_start_time"].split(":")[1] : "", trading_end_time: initialTimes !== undefined && initialTimes.find(obj => obj.day === 5) ? initialTimes.find(obj => obj.day === 5)["trading_end_time"].split(":")[0] + ":" +initialTimes.find(obj => obj.day === 5)["trading_end_time"].split(":")[1] : "" },
        //@ts-ignore
        { day: 6, trading_start_time: initialTimes !== undefined && initialTimes.find(obj => obj.day === 6) ? initialTimes.find(obj => obj.day === 6)["trading_start_time"].split(":")[0] + ":" +initialTimes.find(obj => obj.day === 6)["trading_start_time"].split(":")[1] : "", trading_end_time: initialTimes !== undefined && initialTimes.find(obj => obj.day === 6) ? initialTimes.find(obj => obj.day === 6)["trading_end_time"].split(":")[0] + ":" +initialTimes.find(obj => obj.day === 6)["trading_end_time"].split(":")[1] : "" },
      ])
    }
  },[initialTimes])


  function handelTimeChanger(e) {
    // Clone the original array and change the property where id is 0
    const updatedArray = Times.map((obj) => {
      if (obj.day === 0 && e.target.id === "So Start") {
        //@ts-ignore
        return { ...obj, trading_start_time: (e.target.value) };
      }
      if (obj.day === 0 && e.target.id === "So End") {
        //@ts-ignore
        return { ...obj, trading_end_time: (e.target.value)};
      }
      if (obj.day === 1 && e.target.id === "Mo Start") {
        return { ...obj, trading_start_time: (e.target.value) };
      }
      if (obj.day === 1 && e.target.id === "Mo End") {
        return { ...obj, trading_end_time: (e.target.value) };
      }
      if (obj.day === 2 && e.target.id === "Di Start") {
        return { ...obj, trading_start_time: (e.target.value) };
      }
      if (obj.day === 2 && e.target.id === "Di End") {
        return { ...obj, trading_end_time: (e.target.value) };
      }
      if (obj.day === 3 && e.target.id === "Mi Start") {
        return { ...obj, trading_start_time: (e.target.value) };
      }
      if (obj.day === 3 && e.target.id === "Mi End") {
        return { ...obj, trading_end_time: (e.target.value) };
      }
      if (obj.day === 4 && e.target.id === "Do Start") {
        return { ...obj, trading_start_time: (e.target.value)};
      }
      if (obj.day === 4 && e.target.id === "Do End") {
        return { ...obj, trading_end_time:(e.target.value) };
      }
      if (obj.day === 5 && e.target.id === "Fr Start") {
        return { ...obj, trading_start_time: (e.target.value) };
      }
      if (obj.day === 5 && e.target.id === "Fr End") {
        return { ...obj, trading_end_time: (e.target.value) };
      }
      if (obj.day === 6 && e.target.id === "Sa Start") {
        return { ...obj, trading_start_time: (e.target.value) };
      }
      if (obj.day === 6 && e.target.id === "Sa End") {
        return { ...obj, trading_end_time: (e.target.value) };
      }
      return obj;
    });

    // Update the state with the new array
    setTimes(updatedArray);
  }


  const checkError = () => {

    if (((Times[0].trading_start_time === "" && Times[0].trading_end_time !== "") || (Times[0].trading_start_time !== "" && Times[0].trading_end_time === "") || (((Times[0].trading_start_time && Times[0].trading_end_time) !== "") && (Number(Times[0].trading_start_time.split(":")[0]) >= Number(Times[0].trading_end_time.split(":")[0])))) ||
        ((Times[1].trading_start_time === "" && Times[1].trading_end_time !== "") || (Times[1].trading_start_time !== "" && Times[1].trading_end_time === "") || (((Times[1].trading_start_time && Times[1].trading_end_time) !== "") && (Number(Times[1].trading_start_time.split(":")[0]) >= Number(Times[1].trading_end_time.split(":")[0])))) ||
        ((Times[2].trading_start_time === "" && Times[2].trading_end_time !== "") || (Times[2].trading_start_time !== "" && Times[2].trading_end_time === "") || (((Times[2].trading_start_time && Times[2].trading_end_time) !== "") && (Number(Times[2].trading_start_time.split(":")[0]) >= Number(Times[2].trading_end_time.split(":")[0])))) ||
        ((Times[3].trading_start_time === "" && Times[3].trading_end_time !== "") || (Times[3].trading_start_time !== "" && Times[3].trading_end_time === "") || (((Times[3].trading_start_time && Times[3].trading_end_time) !== "") && (Number(Times[3].trading_start_time.split(":")[0]) >= Number(Times[3].trading_end_time.split(":")[0])))) ||
        ((Times[4].trading_start_time === "" && Times[4].trading_end_time !== "") || (Times[4].trading_start_time !== "" && Times[4].trading_end_time === "") || (((Times[4].trading_start_time && Times[4].trading_end_time) !== "") && (Number(Times[4].trading_start_time.split(":")[0]) >= Number(Times[4].trading_end_time.split(":")[0])))) ||
        ((Times[5].trading_start_time === "" && Times[5].trading_end_time !== "") || (Times[5].trading_start_time !== "" && Times[5].trading_end_time === "") || (((Times[5].trading_start_time && Times[5].trading_end_time) !== "") && (Number(Times[5].trading_start_time.split(":")[0]) >= Number(Times[5].trading_end_time.split(":")[0])))) ||
        ((Times[6].trading_start_time === "" && Times[6].trading_end_time !== "") || (Times[6].trading_start_time !== "" && Times[6].trading_end_time === "") || (((Times[6].trading_start_time && Times[6].trading_end_time) !== "") && (Number(Times[6].trading_start_time.split(":")[0]) >= Number(Times[6].trading_end_time.split(":")[0]))))
    ) {
      return true;
    } else return false;
  }

  return (
    <>
      <Stack component="form" noValidate spacing={3}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <TextField
            //@ts-ignore
            value={Times[1].trading_start_time ? Times[1].trading_start_time : initialTimes && initialTimes.find(obj => obj.day === 1) ? initialTimes.find(obj => obj.day === 1)["trading_start_time"] : "--:--"}
            style={{ marginBottom: "1rem", marginRight: "2rem" }}
            variant="standard"
            color="primary"
            id="Mo Start"
            label={lang[locale].mondayStart}
            type="time"
            // defaultValue="09:00"
            InputLabelProps={{
              shrink: true,
              style: { color: "white" },
            }}
            inputProps={{
              // step: 3600, // 5 min
              style: { color: "white" },
            }}
            sx={{ width: 150 }}
            onChange={(e) => handelTimeChanger(e)}
            error={  ((Times[1].trading_start_time === "" && Times[1].trading_end_time !== "") || (Times[1].trading_start_time !== "" && Times[1].trading_end_time === "") || (((Times[1].trading_start_time && Times[1].trading_end_time) !== "") && (Number(Times[1].trading_start_time.split(":")[0]) >= Number(Times[1].trading_end_time.split(":")[0])))) }
          />
          <TextField
           //@ts-ignore
           value={Times[1].trading_end_time ? Times[1].trading_end_time :initialTimes && initialTimes.find(obj => obj.day === 1) ? initialTimes.find(obj => obj.day === 1)["trading_end_time"] : "--:--"}
            style={{ marginBottom: "1rem" }}
            variant="standard"
            color="primary"
            id="Mo End"
            label={lang[locale].mondayEnd}
            type="time"
            // defaultValue="09:00"
            InputLabelProps={{
              shrink: true,
              style: { color: "white" },
            }}
            inputProps={{
              // step: 3600, // 5 min
              style: { color: "white" },
            }}
            sx={{ width: 150 }}
            onChange={(e) => handelTimeChanger(e)}
            error={  ((Times[1].trading_start_time === "" && Times[1].trading_end_time !== "") || (Times[1].trading_start_time !== "" && Times[1].trading_end_time === "") || (((Times[1].trading_start_time && Times[1].trading_end_time) !== "") && (Number(Times[1].trading_start_time.split(":")[0]) >= Number(Times[1].trading_end_time.split(":")[0]))))}
          />
          {
          //@ts-ignore
          initialTimes && initialTimes.find(obj => obj.day === 1) && (initialTimes.find(obj => obj.day === 1)["trading_end_time"] && initialTimes.find(obj => obj.day === 1)["trading_start_time"]) !== "" ? (
                      <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => {
                        DeleteDayHandler(1, lang[locale].monday);
                      }}
                      style={{
                        margin: "0 0 0.2rem 2rem",
                        color: "white",
                      }}
                      >
                        <DeleteIcon />
                      </IconButton>
          ) : null }
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <TextField
              //@ts-ignore
            value={Times[2].trading_start_time ? Times[2].trading_start_time :initialTimes && initialTimes.find(obj => obj.day === 2) ? initialTimes.find(obj => obj.day === 2)["trading_start_time"] : "--:--"}
            style={{ marginBottom: "1rem", marginRight: "2rem" }}
            variant="standard"
            color="primary"
            id="Di Start"
            label={lang[locale].tuesdayStart}
            type="time"
            // defaultValue="09:00"
            InputLabelProps={{
              shrink: true,
              style: { color: "white" },
            }}
            inputProps={{
              // step: 3600, // 5 min
              style: { color: "white" },
            }}
            sx={{ width: 150 }}
            onChange={(e) => handelTimeChanger(e)}
            error={ ((Times[2].trading_start_time === "" && Times[2].trading_end_time !== "") || (Times[2].trading_start_time !== "" && Times[2].trading_end_time === "") || (((Times[2].trading_start_time && Times[2].trading_end_time) !== "") && (Number(Times[2].trading_start_time.split(":")[0]) >= Number(Times[2].trading_end_time.split(":")[0])))) }
          />
          <TextField
             //@ts-ignore
            value={Times[2].trading_end_time ? Times[2].trading_end_time :initialTimes && initialTimes.find(obj => obj.day === 2) ? initialTimes.find(obj => obj.day === 2)["trading_end_time"] : "--:--"}
            style={{ marginBottom: "1rem" }}
            variant="standard"
            color="primary"
            id="Di End"
            label={lang[locale].tuesdayEnd}
            type="time"
            // defaultValue="09:00"
            InputLabelProps={{
              shrink: true,
              style: { color: "white" },
            }}
            inputProps={{
              // step: 3600, // 5 min
              style: { color: "white" },
            }}
            sx={{ width: 150 }}
            onChange={(e) => handelTimeChanger(e)}
            error={ ((Times[2].trading_start_time === "" && Times[2].trading_end_time !== "") || (Times[2].trading_start_time !== "" && Times[2].trading_end_time === "") || (((Times[2].trading_start_time && Times[2].trading_end_time) !== "") && (Number(Times[2].trading_start_time.split(":")[0]) >= Number(Times[2].trading_end_time.split(":")[0])))) }
          />
          {
          //@ts-ignore
          initialTimes && initialTimes.find(obj => obj.day === 2) && (initialTimes.find(obj => obj.day === 2)["trading_end_time"] && initialTimes.find(obj => obj.day === 2)["trading_start_time"]) !== "" ? (
                      <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => {
                        DeleteDayHandler(2, lang[locale].tuesday);
                      }}
                      style={{
                        margin: "0 0 0.2rem 2rem",
                        color: "white",
                      }}
                      >
                        <DeleteIcon />
                      </IconButton>
          ) : null }
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <TextField
          //@ts-ignore
            value={Times[3].trading_start_time ? Times[3].trading_start_time :initialTimes && initialTimes.find(obj => obj.day === 3) ? initialTimes.find(obj => obj.day === 3)["trading_start_time"] : "--:--"}
            style={{ marginBottom: "1rem", marginRight: "2rem" }}
            variant="standard"
            color="primary"
            id="Mi Start"
            label={lang[locale].wednesdayStart}
            type="time"
            // defaultValue="09:00"
            InputLabelProps={{
              shrink: true,
              style: { color: "white" },
            }}
            inputProps={{
              // step: 3600, // 5 min
              style: { color: "white" },
            }}
            sx={{ width: 150 }}
            onChange={(e) => handelTimeChanger(e)}
            error={ ((Times[3].trading_start_time === "" && Times[3].trading_end_time !== "") || (Times[3].trading_start_time !== "" && Times[3].trading_end_time === "") || (((Times[3].trading_start_time && Times[3].trading_end_time) !== "") && (Number(Times[3].trading_start_time.split(":")[0]) >= Number(Times[3].trading_end_time.split(":")[0]))))  }
          />
          <TextField
          //@ts-ignore
            value={Times[3].trading_end_time ? Times[3].trading_end_time : initialTimes && initialTimes.find(obj => obj.day === 3) ? initialTimes.find(obj => obj.day === 3)["trading_end_time"] : "--:--"}
            style={{ marginBottom: "1rem" }}
            variant="standard"
            color="primary"
            id="Mi End"
            label={lang[locale].wednesdayEnd}
            type="time"
            // defaultValue="09:00"
            InputLabelProps={{
              shrink: true,
              style: { color: "white" },
            }}
            inputProps={{
              // step: 3600, // 5 min
              style: { color: "white" },
            }}
            sx={{ width: 150 }}
            onChange={(e) => handelTimeChanger(e)}
            error={ ((Times[3].trading_start_time === "" && Times[3].trading_end_time !== "") || (Times[3].trading_start_time !== "" && Times[3].trading_end_time === "") || (((Times[3].trading_start_time && Times[3].trading_end_time) !== "") && (Number(Times[3].trading_start_time.split(":")[0]) >= Number(Times[3].trading_end_time.split(":")[0]))))  }
          />
          {
          //@ts-ignore
          initialTimes && initialTimes.find(obj => obj.day === 3) && (initialTimes.find(obj => obj.day === 3)["trading_end_time"] && initialTimes.find(obj => obj.day === 3)["trading_start_time"]) !== "" ? (
                      <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => {
                        DeleteDayHandler(3, lang[locale].wednesday);
                      }}
                      style={{
                        margin: "0 0 0.2rem 2rem",
                        color: "white",
                      }}
                      >
                        <DeleteIcon />
                      </IconButton>
          ) : null }
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <TextField
          //@ts-ignore
            value={Times[4].trading_start_time ? Times[4].trading_start_time : initialTimes && initialTimes.find(obj => obj.day === 4) ? initialTimes.find(obj => obj.day === 4)["trading_start_time"] : "--:--"}
            style={{ marginBottom: "1rem", marginRight: "2rem" }}
            variant="standard"
            color="primary"
            id="Do Start"
            label={lang[locale].thursdayStart}
            type="time"
            // defaultValue="09:00"
            InputLabelProps={{
              shrink: true,
              style: { color: "white" },
            }}
            inputProps={{
              // step: 3600, // 5 min
              style: { color: "white" },
            }}
            sx={{ width: 150 }}
            onChange={(e) => handelTimeChanger(e)}
            error={ ((Times[4].trading_start_time === "" && Times[4].trading_end_time !== "") || (Times[4].trading_start_time !== "" && Times[4].trading_end_time === "") || (((Times[4].trading_start_time && Times[4].trading_end_time) !== "") && (Number(Times[4].trading_start_time.split(":")[0]) >= Number(Times[4].trading_end_time.split(":")[0])))) }
          />
          <TextField
          //@ts-ignore
            value={Times[4].trading_end_time ? Times[4].trading_end_time : initialTimes && initialTimes.find(obj => obj.day === 4) ? initialTimes.find(obj => obj.day === 4)["trading_end_time"] : "--:--"}
            style={{ marginBottom: "1rem" }}
            variant="standard"
            color="primary"
            id="Do End"
            label={lang[locale].thursdayEnd}
            type="time"
            // defaultValue="09:00"
            InputLabelProps={{
              shrink: true,
              style: { color: "white" },
            }}
            inputProps={{
              // step: 3600, // 5 min
              style: { color: "white" },
            }}
            sx={{ width: 150 }}
            onChange={(e) => handelTimeChanger(e)}
            error={ ((Times[4].trading_start_time === "" && Times[4].trading_end_time !== "") || (Times[4].trading_start_time !== "" && Times[4].trading_end_time === "") || (((Times[4].trading_start_time && Times[4].trading_end_time) !== "") && (Number(Times[4].trading_start_time.split(":")[0]) >= Number(Times[4].trading_end_time.split(":")[0])))) }
            />
             {
          //@ts-ignore
          initialTimes && initialTimes.find(obj => obj.day === 4) && (initialTimes.find(obj => obj.day === 4)["trading_end_time"] && initialTimes.find(obj => obj.day === 4)["trading_start_time"]) !== "" ? (
                      <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => {
                        DeleteDayHandler(4, lang[locale].thursday);
                      }}
                      style={{
                        margin: "0 0 0.2rem 2rem",
                        color: "white",
                      }}
                      >
                        <DeleteIcon />
                      </IconButton>
          ) : null }
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <TextField
          //@ts-ignore
          value={Times[5].trading_start_time ? Times[5].trading_start_time : initialTimes && initialTimes.find(obj => obj.day === 5) ? initialTimes.find(obj => obj.day === 5)["trading_start_time"] : "--:--"}
            style={{ marginBottom: "1rem", marginRight: "2rem" }}
            variant="standard"
            color="primary"
            id="Fr Start"
            label={lang[locale].fridayStart}
            type="time"
            // defaultValue="09:00"
            InputLabelProps={{
              shrink: true,
              style: { color: "white" },
            }}
            inputProps={{
              // step: 3600, // 5 min
              style: { color: "white" },
            }}
            sx={{ width: 150 }}
            onChange={(e) => handelTimeChanger(e)}
            error={  ((Times[5].trading_start_time === "" && Times[5].trading_end_time !== "") || (Times[5].trading_start_time !== "" && Times[5].trading_end_time === "") || (((Times[5].trading_start_time && Times[5].trading_end_time) !== "") && (Number(Times[5].trading_start_time.split(":")[0]) >= Number(Times[5].trading_end_time.split(":")[0])))) }
          />
          <TextField
          //@ts-ignore
          value={Times[5].trading_end_time ? Times[5].trading_end_time : initialTimes && initialTimes.find(obj => obj.day === 5) ? initialTimes.find(obj => obj.day === 5)["trading_end_time"] : "--:--"}
            style={{ marginBottom: "1rem" }}
            variant="standard"
            color="primary"
            id="Fr End"
            label={lang[locale].fridayEnd}
            type="time"
            // defaultValue="09:00"
            InputLabelProps={{
              shrink: true,
              style: { color: "white" },
            }}
            inputProps={{
              // step: 3600, // 5 min
              style: { color: "white" },
            }}
            sx={{ width: 150 }}
            onChange={(e) => handelTimeChanger(e)}
            error={  ((Times[5].trading_start_time === "" && Times[5].trading_end_time !== "") || (Times[5].trading_start_time !== "" && Times[5].trading_end_time === "") || (((Times[5].trading_start_time && Times[5].trading_end_time) !== "") && (Number(Times[5].trading_start_time.split(":")[0]) >= Number(Times[5].trading_end_time.split(":")[0])))) }
            />
             {
          //@ts-ignore
          initialTimes && initialTimes.find(obj => obj.day === 5) && (initialTimes.find(obj => obj.day === 5)["trading_end_time"] && initialTimes.find(obj => obj.day === 5)["trading_start_time"]) !== "" ? (
                      <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => {
                        DeleteDayHandler(5, lang[locale].friday);
                      }}
                      style={{
                        margin: "0 0 0.2rem 2rem",
                        color: "white",
                      }}
                      >
                        <DeleteIcon />
                      </IconButton>
          ) : null }
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
          <TextField
            disabled
           //@ts-ignore
            value={Times[6].trading_start_time ? Times[6].trading_start_time : initialTimes && initialTimes.find(obj => obj.day === 6) ? initialTimes.find(obj => obj.day === 6)["trading_start_time"] : "--:--"}
            style={{ marginBottom: "1rem", marginRight: "2rem" }}
            variant="standard"
            color="primary"
            id="Sa Start"
            label={lang[locale].saturdayStart}
            type="time"
            // defaultValue="09:00"
            InputLabelProps={{
              shrink: true,
              style: { color: "grey" },
            }}
            inputProps={{
              // step: 3600, // 5 min
              style: { color: "white" },
            }}
            sx={{ width: 150 }}
            onChange={(e) => handelTimeChanger(e)}
            error={ ((Times[6].trading_start_time === "" && Times[6].trading_end_time !== "") || (Times[6].trading_start_time !== "" && Times[6].trading_end_time === "") || (((Times[6].trading_start_time && Times[6].trading_end_time) !== "") && (Number(Times[6].trading_start_time.split(":")[0]) >= Number(Times[6].trading_end_time.split(":")[0])))) }
          />
          <TextField
            disabled
             //@ts-ignore
            value={Times[6].trading_end_time ? Times[6].trading_end_time : initialTimes && initialTimes.find(obj => obj.day === 6) ? initialTimes.find(obj => obj.day === 6)["trading_end_time"] : "--:--"}
            style={{ marginBottom: "1rem" }}
            variant="standard"
            color="primary"
            id="Sa End"
            label={lang[locale].saturdayEnd}
            type="time"
            // defaultValue="09:00"
            InputLabelProps={{
              shrink: true,
              style: { color: "grey" },
            }}
            inputProps={{
              // step: 3600, // 5 min
              style: { color: "white" },
            }}
            sx={{ width: 150 }}
            onChange={(e) => handelTimeChanger(e)}
            error={ ((Times[6].trading_start_time === "" && Times[6].trading_end_time !== "") || (Times[6].trading_start_time !== "" && Times[6].trading_end_time === "") || (((Times[6].trading_start_time && Times[6].trading_end_time) !== "") && (Number(Times[6].trading_start_time.split(":")[0]) >= Number(Times[6].trading_end_time.split(":")[0])))) }
          />
             {
          //@ts-ignore
          initialTimes && initialTimes.find(obj => obj.day === 6) && (initialTimes.find(obj => obj.day === 6)["trading_end_time"] && initialTimes.find(obj => obj.day === 6)["trading_start_time"]) !== "" ? (
                      <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => {
                        DeleteDayHandler(6, lang[locale].saturday);
                      }}
                      style={{
                        margin: "0 0 0.2rem 2rem",
                        color: "white",
                      }}
                      >
                        <DeleteIcon />
                      </IconButton>
          ) : null }
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
          <TextField
            disabled
            //@ts-ignore
            value={Times[0].trading_start_time ? Times[0].trading_start_time : initialTimes && initialTimes.find(obj => obj.day === 0) ? initialTimes.find(obj => obj.day === 0)["trading_start_time"] : "--:--"}
            style={{ marginBottom: "1rem", marginRight: "2rem" }}
            variant="standard"
            color="primary"
            id="So Start"
            label={lang[locale].sundayStart}
            type="time"
            // defaultValue="09:00"
            InputLabelProps={{
              shrink: true,
              style: { color: "grey" },
            }}
            inputProps={{
              // step: 3600, // 5 min
              style: { color: "white" },
            }}
            sx={{ width: 150 }}
            onChange={(e) => handelTimeChanger(e)}
            error={ ((Times[0].trading_start_time === "" && Times[0].trading_end_time !== "") || (Times[0].trading_start_time !== "" && Times[0].trading_end_time === "") || (((Times[0].trading_start_time && Times[0].trading_end_time) !== "") && (Number(Times[0].trading_start_time.split(":")[0]) >= Number(Times[0].trading_end_time.split(":")[0])))) }
          />
          <TextField
             disabled
             //@ts-ignore
             value={Times[0].trading_end_time ? Times[0].trading_end_time : initialTimes && initialTimes.find(obj => obj.day === 0) ? initialTimes.find(obj => obj.day === 0)["trading_end_time"] : "--:--"}
            style={{ marginBottom: "1rem" }}
            variant="standard"
            color="primary"
            id="So End"
            label={lang[locale].sundayEnd}
            type="time"
            // defaultValue="09:00"
            InputLabelProps={{
              shrink: true,
              style: { color: "grey" },
            }}
            inputProps={{
              // step: 3600, // 5 min
              style: { color: "white" },
            }}
            sx={{ width: 150 }}
            onChange={(e) => handelTimeChanger(e)}
            error={((Times[0].trading_start_time === "" && Times[0].trading_end_time !== "") || (Times[0].trading_start_time !== "" && Times[0].trading_end_time === "") || (((Times[0].trading_start_time && Times[0].trading_end_time) !== "") && (Number(Times[0].trading_start_time.split(":")[0]) >= Number(Times[0].trading_end_time.split(":")[0]))))}
          />
             {
          //@ts-ignore
          initialTimes && initialTimes.find(obj => obj.day === 0) && (initialTimes.find(obj => obj.day === 0)["trading_end_time"] && initialTimes.find(obj => obj.day === 0)["trading_start_time"]) !== "" ? (
                      <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => {
                        DeleteDayHandler(0, lang[locale].sunday);
                      }}
                      style={{
                        margin: "0 0 0.2rem 2rem",
                        color: "white",
                      }}
                      >
                        <DeleteIcon />
                      </IconButton>
          ) : null }
          </div>
        <Button
          onClick={HandelSubmit}
          variant="contained"
          // color="primary"
          style={{width: 150, marginTop: "3rem",backgroundColor : checkError() ? "#595959" : props.coloR.primary, color : checkError() ? "#898989" : "white"}}
          disabled={checkError()}
          // disabled={Times[0].trading_start_time === "" || Times[0].trading_end_time === "" || Times[1].trading_start_time === "" || Times[1].trading_end_time === "" || Times[2].trading_start_time === "" || Times[2].trading_end_time === "" || Times[3].trading_start_time === "" || Times[3].trading_end_time === "" || Times[4].trading_start_time === "" || Times[4].trading_end_time === ""}
        >
          {lang[locale].save}
        </Button>
      </Stack>
    </>
  );
}

export default BuyTimeLimitComponent;
