import React, { useEffect, useState } from "react";
import PortfolioService from "./../services/portfolios/PortfolioService";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import snackbarActions from "actions/snackbar.actions";
import {
  Paper,
  Button,
  Grid,
  FormGroup,
  TextField,
  FormControlLabel,
  TableCell,
  IconButton,
  CircularProgress,
  Switch,
  Typography,
  Tooltip,
  Checkbox,
  InputLabel,
} from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import { lang } from "../lang";
import modalActions from "actions/modal.actions";
const locale = localStorage.locale;

const PFMservive = new PortfolioService();

function AddToPortfolio(props) {

  const [portfolios, setPortfolios] = useState<any>([]);
  const [portfoliodetails, setPortfoliodetails] = useState<any>([]);
  const [filteredPortfolio, setFilteredPortfolio] = useState<any>([]);
  let history = useHistory();
  const dispatch = useDispatch();

  const btnCloseHandler = () => {
    dispatch(modalActions.closeModal());
  };

  const getAllPortfolio = () => {
    PFMservive.getAllPortfolios(Number(props.customer.id))
      .then((res) => {
        setPortfolios(res);
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  };

  const addCheckboxHandler = (e, id, pfid) => {
    if (e.target.checked) {
      PFMservive.addtoPortfolio(props.customer.id, pfid, id)
        .then((res) => {
          getAllPortfolio();
          // dispatch(snackbarActions.openSnackbar(lang[locale].addSuccessToPfm, "success"));
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          // dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
      }
      if (!e.target.checked) {
        PFMservive.removeFromPortfolio(props.customer.id, pfid, id)
        .then((res) => {
          getAllPortfolio();
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
      }
      getAllPortfolio();
      // dispatch(snackbarActions.openSnackbar(lang[locale].removeSuccessFromPfm, "success"));
  };

  useEffect(() => {
    const fp = portfolios.filter((p) => p.commodity === props.commodity);
    setFilteredPortfolio(fp);
  }, [portfolios]);

  // const getPortfolioDetail = (pfid) => {
  //   PFMservive.getPortfolioDetails(Number(props.customer.id),pfid)
  //     .then((res) => {
  //       // setPortfolios(res);
  //       console.log("all detail", res)
  //     })
  //     .catch((e) => {
  //       if (e.message === "401") {
  //         return history.push(`/logout`);
  //       }
  //       console.error(e);
  //       dispatch(snackbarActions.openSnackbar(e.message, "error"));
  //     });
  // }

  // console.log("filtered", filteredPortfolio)

  useEffect(() => {
    getAllPortfolio();
    // getPortfolioDetail(99);
  }, []);

  return (
    <>
    {filteredPortfolio && filteredPortfolio.length > 0 ?
      <div>
        <h2>
          {lang[locale].addtoportfolio
            ? lang[locale].addtoportfolio[0] +
            props.zrid +
            lang[locale].addtoportfolio[1]
            :<CircularProgress />}
        </h2>
        <Grid item style={{ marginLeft: "2em", display : "flex", flexDirection : "column", maxHeight : "200px", overflowY : filteredPortfolio.length > 3 ? "scroll" : "hidden" }}>
        {filteredPortfolio && filteredPortfolio.length > 0 &&
          filteredPortfolio?.map((perm) => (
            <Tooltip title={perm.entrys.join(" ,")} placement="left-start">
                  <FormControlLabel
                    control={
                      <Checkbox
                      checked={
                        perm.entrys.includes(props.zrid) ? true : false
                      }
                      size="small"
                      onChange={(e) => {
                        addCheckboxHandler(e, props.zrid, perm.id);
                      }}
                      name="permission"
                      color="primary"
                      />
                    }
                    label={perm.name}
                    />
              </Tooltip>
            ))}
            </Grid>
      </div>
      : (<h3 style={{color : "orange"}}>
      {lang[locale].noPortfolioMessage ? lang[locale].noPortfolioMessage : <CircularProgress />}
     </h3>) }
      <Button
        color={"primary"}
        style={{ marginTop: "2em" }}
        onClick={btnCloseHandler}
      >
        {lang[locale].close}
      </Button>
    </>
  );
}

export default AddToPortfolio;
