import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { lang } from "../lang";
import FleximportComponent from "../components/Fleximport";
import TwoDecimalComponent from "./TwoDecimal";
import FourDecimalComponent from "./FourDecimal";

import ContentPasteIcon from "@mui/icons-material/ContentPaste";
//Material UI
import {
  Button,
  Grid,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  Switch,
  IconButton,
  FormControl,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { useSelector } from "../services/helpers/useSelector";

import DeleteIcon from "@mui/icons-material/Delete";
import UndoIcon from "@mui/icons-material/Undo";
import CreateForecastComponent from "./CreateForecast";
import forecastActions from "../actions/forecast.actions";
import modalActions from "../actions/modal.actions";
import { customer } from "reducers/customer.reducer";

function FormelPriceComponent() {
  const locale = localStorage.locale;
  const dispatch = useDispatch();
  const gas = useSelector((state) => state.forecast.data.gas);
  const { progFrom, progTo } = useSelector((state) => state.forecast.data);
  const customerID = useSelector(
    (state) => state.customer["customerData"]["id"]
  );
  const gesamtFormelFromPronose = useSelector(
    (props) => props.modal.props?.gesamtFormel
  );
  const formulafromstate = useSelector((state) => state.forecast.data.formula);
  const props = useSelector((props) => props.modal.props);
  const [faktor, setFaktor] = useState(0);
  const [zeitRaum, setZeitRaum] = useState("");
  const [produkt, setProdukt] = useState("");
  const [year, setYear] = useState(0);
  const [additiv, setAdditiv] = useState(0);
  const [additivDisable, setAdditivDisable] = useState(false);
  const [constant, setConstant] = useState(0);
  const [formulas, setFormulas] = useState<any[]>([]);
  const [formula, setFormula] = useState({});
  const [gesamtFormel, setGesamtFormel] = useState<string[]>([]);

  const zeitraumOption = [
    "J",
    "Q1",
    "Q2",
    "Q3",
    "Q4",
    "M1",
    "M2",
    "M3",
    "M4",
    "M5",
    "M6",
    "M7",
    "M8",
    "M9",
    "M10",
    "M11",
    "M12",
  ];

  function getYearsBetweenDates(startDate, endDate) {
    const startYear = new Date(startDate).getFullYear();
    const endYear = new Date(endDate).getFullYear();
    const years = [];

    for (let year = startYear; year <= endYear; year++) {
      //@ts-ignore
      years.push(year);
    }

    return years;
  }

  // const yearOption = () => {
  //   let thisYear = new Date().getFullYear();
  //   let nextfourYears: string[] = [];
  //   for (let i = 0; i < 4; i++) {
  //     nextfourYears.push((thisYear + i).toString());
  //   }
  //   return nextfourYears;
  // };

  // let finalYearOpt = yearOption();

  const produktOption = ["BASE", "PEAK"];

  // const readFromClipboard = async () => {
  //   try {
  //     const text = await navigator.clipboard.readText();
  
  //     text.split("-")[0] === "Emdacs" && setFormula(text.split("-")[1])
  //   } catch (err) {
  //     console.error('Failed to read clipboard contents: ', err);
  //   }
  // };

  const handleChangeFaktor = (e) => {
    setFaktor(Number(e.target.value));
  };
  const handleChangeZeitRaum = (e) => {
    setZeitRaum(e.target.value);
  };
  const handleChangeProdukt = (e) => {
    setProdukt(e.target.value);
  };
  const handleChangeYear = (e) => {
    setYear(Number(e.target.value));
  };
  const handleChangeAdditiv = (e) => {
    setAdditiv(Number(e.target.value));
  };

  useEffect(() => {
    if (formulafromstate && Object.keys(formulafromstate).length > 0) {
      setConstant(formulafromstate["constant"]);
      setAdditiv(formulafromstate["constant"]);
      if (
        Object.keys(formulafromstate).length > 0 &&
        formulafromstate["formulas"].length > 0
      ) {
        setAdditivDisable(true);
      }
      setFormulas(formulafromstate["formulas"]);
      // if (formulafromstate["formulas"]?.length === 1)
      //   setGesamtFormel([
      //     `${formulafromstate["formulas"][0].factor} x ${formulafromstate["formulas"][0].time_frame} ${formulafromstate["formulas"][0].product} ${formulafromstate["formulas"][0].year} + `,
      //   ]);
      // if (formulafromstate["formulas"]?.length > 1) {
      for (let i of formulafromstate["formulas"]) {
        gesamtFormel.push(
          ` ${i.factor} x ${i.time_frame} ${i.product} ${i.year} + `
        );
        // }
      }
    }
  }, []);

  function checkDisable() {
    return (
      zeitRaum === "" || (!gas && produkt === "") || year === 0 || faktor === 0
      // || additiv === 0
    );
  }

  const handleChangeFormelButton = (e) => {
    e.preventDefault();
    // if (gesamtFormel.length > 0) {
    setGesamtFormel([
      ` ${faktor} x ${zeitRaum} ${gas ? produktOption[0] : produkt} ${year} + `,
      ...gesamtFormel,
    ]);
    setFormulas([
      {
        product: gas ? produktOption[0] : produkt,
        time_frame: zeitRaum,
        year: year,
        factor: faktor,
      },
      ...formulas,
    ]);
    // } else {
    // setGesamtFormel([
    //   `${faktor} x ${zeitRaum} ${
    //     gas ? produktOption[0] : produkt
    //   } ${year} + ${additiv}`,
    //   ...gesamtFormel,
    // ]);
    setConstant(additiv);
    setFormulas([
      {
        product: gas ? produktOption[0] : produkt,
        time_frame: zeitRaum,
        year: year,
        factor: faktor,
      },
      ...formulas,
    ]);
    // }
    setFaktor(0);
    setZeitRaum("");
    setProdukt("");
    setYear(0);
    setAdditivDisable(true);
  };

  useEffect(() => {
    if (formulas && formulas.length > 0) {
      setFormula({ constant, formulas });
    }
  }, [formulas]);

  function handleClickSubmit() {
    dispatch(forecastActions.saveForecastSingle("formula", formula));
    dispatch(forecastActions.saveForecastSingle("pricing_type", "FORMEL"));
    if (props.fromPage === "FromCreate") {
      dispatch(
        modalActions.openModal(CreateForecastComponent, {
          gesamtFormel: gesamtFormel,
          formula: formula,
          preisType: "FORMEL",
        })
      );
    } else if (props.fromPage === "FromBestehende") {
      dispatch(forecastActions.saveForecastSingle("formula", formula));
      dispatch(modalActions.closeModal());
    }
  }

  function handleClickClose() {
    if (props.fromPage === "FromCreate") {
      // if (!checkDisable) {
      dispatch(
        modalActions.openModal(CreateForecastComponent, {
          gesamtFormel: gesamtFormel,
          formula: formula,
          preisType: "FORMEL",
        })
      );
      // }
      // else {
      //   dispatch(
      //     modalActions.openModal(CreateForecastComponent, {
      //       preisType: "FORMEL",
      //       // gesamtFormel: [],
      //     })
      //   );
      // }
      dispatch(forecastActions.saveForecastSingle("pricing_type", "FORMEL"));
    } else if (props.fromPage === "FromBestehende") {
      dispatch(modalActions.closeModal());
    }
    // else dispatch(modalActions.closeModal());
  }

  // console.log("formulafromstate",formulafromstate)

  const handleDeleteFormel = () => {
    dispatch(forecastActions.saveForecastSingle("formula", {}));
    setGesamtFormel([]);
    setAdditiv(0);
    setZeitRaum("");
    setProdukt("");
    setYear(0);
    setAdditivDisable(false);
    setFaktor(0);
    setFormula({});
    setFormulas([]);
    setConstant(0);
  };

  const handleRedo = () => {
    setGesamtFormel(gesamtFormel.splice(1, gesamtFormel.length));
    setFormulas(formulas.splice(1, formulas.length));
    // setFormula(formulas.splice());
  };

  const selectHandler = (e) => {
    e.target.select();
  };

  return (
    <div style={{ maxWidth: "80%", height: "auto" }}>
      <div style={{display: "flex", flexDirection : "row"}}>
      <h2>{lang[locale].SelectFormulaTerm}:</h2>
      {/* <Tooltip title={"Aus Zwischenablage einfügen"}>

      <IconButton
        edge="end"
        aria-label="paste"
        onClick={readFromClipboard}
        style={{
          marginLeft : "3rem",
          color: "white",
        }}
        >
        <ContentPasteIcon />
      </IconButton>
      </Tooltip> */}
      </div>
      <div>
        {/* <span style={{ marginRight: "0.5em" }}>Additive Konstante</span> */}
        {/* <input
          autoFocus
          disabled={additivDisable}
          value={additiv}
          onChange={handleChangeAdditiv}
        /> */}
        <Grid item={true} xs={8}>
          <TextField
            required
            margin="normal"
            style={{ width: "50%" }}
            // id="power_charge"
            onFocus={selectHandler}
            value={additiv ? additiv : 0}
            onChange={handleChangeAdditiv}
            label={lang[locale].AdditiveConstant}
            InputProps={{
              inputComponent: TwoDecimalComponent,
            }}
            disabled={additivDisable}
          />
        </Grid>
      </div>
      <Grid
        container
        xs={10}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "1em 0",
        }}
      >
        <Grid item={true} xs={2}>
          <TextField
            required
            margin="normal"
            id="faktor"
            onFocus={selectHandler}
            value={faktor}
            onChange={handleChangeFaktor}
            label={lang[locale].factor}
            InputProps={{
              inputComponent: FourDecimalComponent,
            }}
            error={faktor === 0}
            // disabled={additiv === 0 ? true : false}
            style={{ marginBottom: "1em" }}
          />
        </Grid>

        <Grid item={true} xs={2}>
          <InputLabel id="Zeitraum">{lang[locale].period}</InputLabel>
          <Select value={zeitRaum} onChange={handleChangeZeitRaum}>
            {zeitraumOption.map((i) => (
              <MenuItem key={i} value={i} style={{ cursor: "pointer" }}>
                {i}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item={true} xs={2}>
          <InputLabel id="Produkt">{lang[locale].product}</InputLabel>
          {gas ? (
            <span>{produktOption[0]}</span>
          ) : (
            <Select
              value={gas ? produktOption[0] : produkt}
              onChange={handleChangeProdukt}
            >
              {produktOption.map((i) => (
                <MenuItem key={i} value={i} style={{ cursor: "pointer" }}>
                  {i}
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>

        <Grid item={true} xs={2}>
          <InputLabel id="Jahr">{lang[locale].Year}</InputLabel>
          <Select value={year} onChange={handleChangeYear}>
            {getYearsBetweenDates(progFrom, progTo).map((i) => (
              <MenuItem key={i} value={i} style={{ cursor: "pointer" }}>
                {i}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "auto",
            width: "100%",
          }}
        >
          <Grid>
            <Button
              disabled={checkDisable()}
              style={{ margin: "2em 0" }}
              color="primary"
              variant="contained"
              onClick={handleChangeFormelButton}
            >
              {lang[locale].AddFormula}
            </Button>
            {gesamtFormel.length > 0 ? (
              <>
                <IconButton onClick={handleDeleteFormel}>
                  <DeleteIcon />
                </IconButton>
                {gesamtFormel.length > 1 && (
                  <IconButton onClick={handleRedo}>
                    <UndoIcon />
                  </IconButton>
                )}
              </>
            ) : null}
          </Grid>
        </Grid>
        <Grid xs={12}>
          {gesamtFormel.length > 0 ? (
            <>
              <span>{lang[locale].OverallFormula}:</span>
              <p>
                {gesamtFormel}
                {additiv}
                {/* {!gesamtFormel && formulafromstate ? constant : " "} */}
              </p>
              {/* <span style={{ margin: "2em 0" }}>
                Preisbasis:{" "}
                <span style={{ marginLeft: "2em" }}>Settlement</span> <Switch />{" "}
                <span>Intraday</span>{" "}
              </span> */}
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "2em 4em 0 0" }}
                  onClick={handleClickSubmit}
                >
                  {lang[locale].AssignOverallFormula}
                </Button>
                <Button
                  style={{ marginTop: "2em" }}
                  color="primary"
                  variant="text"
                  onClick={handleClickClose}
                >
                  {lang[locale].back}
                </Button>
              </div>
            </>
          )
          // : Object.keys(formula).length > 0 ? (<>
          //   <span>Gesamtformel</span>
          //   <p>

          //     {formula.toString()}
          //     {/* {additiv} */}
          //     {/* {!gesamtFormel && formulafromstate ? constant : " "} */}
          //   </p>
          //   {/* <span style={{ margin: "2em 0" }}>
          //     Preisbasis:{" "}
          //     <span style={{ marginLeft: "2em" }}>Settlement</span> <Switch />{" "}
          //     <span>Intraday</span>{" "}
          //   </span> */}
          //   <div>
          //     <Button
          //       variant="contained"
          //       color="primary"
          //       style={{ margin: "2em 4em 0 0" }}
          //       onClick={handleClickSubmit}
          //     >
          //       Gesamtformel der Prognose zuordnen
          //     </Button>
          //     <Button
          //       style={{ marginTop: "2em" }}
          //       color="primary"
          //       variant="text"
          //       onClick={handleClickClose}
          //     >
          //       {lang[locale].back}
          //     </Button>
          //   </div>
          // </>)
          :
          (
            <Button
              style={{ marginTop: "2em" }}
              color="primary"
              variant="text"
              onClick={handleClickClose}
            >
              {lang[locale].back}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default FormelPriceComponent;
