import React, { useEffect, useState } from "react";
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@material-ui/core";
import { lang } from "../lang";
import { useSelector } from "../services/helpers/useSelector";
import { useDispatch } from "react-redux";
import forecastActions from "../actions/forecast.actions";


/**
 * Component with a select for selecting the unit
 * @constructor
 */
function SelectUnitComponent() {
    const locale = localStorage.locale;
    const unit = useSelector((state) => state.forecast.fleximportData.unit);
    const energy = useSelector((state) => state.forecast.fleximportData.energy);
    const data = useSelector(state => state.forecast.fleximportData);
    const [select,setSelect] = useState(true);

    const dispatch = useDispatch();

    useEffect(()=>{
        if(data.actualCount > 0 && data.neededCount > 0 && (data.actualCount === data.neededCount)){
            setSelect(false);
        } else setSelect(true);
    },[data.actualCount, data.neededCount]);

    function handleChange(e) {
            dispatch(forecastActions.saveFleximportSingle("unit", e.target.value));
    }

    return (
        <Grid container spacing={4} alignItems="center">
            <Grid item xs={6} style={{ padding: "0.5rem 0.5rem 1rem 1rem" }}>
                <FormControl fullWidth={true}>
                    <InputLabel htmlFor="age-native-simple">
                        {lang[locale].unit}
                    </InputLabel>
                    <Select value={unit} onChange={handleChange} disabled={select} error={unit === ""}>
                        <MenuItem value="">
                            {lang[locale].notSelected}{" "}
                        </MenuItem>
                        <MenuItem value={"kW"}>kW</MenuItem>
                        <MenuItem value={"kWh"}>kWh</MenuItem>
                        <MenuItem value={"MW"}>MW</MenuItem>
                        <MenuItem value={"MWH"}>MWh</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <div style={{ padding: "0.5rem 0.5rem 1rem 1rem" }}>
                    <Typography>{lang[locale].energyAmount} in kWh:</Typography>
                    {unit ? (
                    <Typography>
                        {energy > 0 
                            ? energy.toLocaleString()
                            : lang[locale].noProvided}
                    </Typography>
                    ) : (" ")
                    }
                </div>
            </Grid>
        </Grid>
    );
}

export default SelectUnitComponent;
