import React, { useEffect, useState } from "react";
import ForecastService from "../services/forecast/ForecastService";
import Papa from "papaparse";
import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { KeyboardDatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import { styled } from "@mui/material/styles";
import { lang } from "../lang";
import NumberFormat from "react-number-format";
import ForecastInformation from "../typeDefs/ForecastInformation";
import { useDispatch } from "react-redux";
import snackbarActions from "actions/snackbar.actions";
import { useHistory } from "react-router-dom";
import { useSelector } from "../services/helpers/useSelector";
import { useLocation } from "react-router";
import ForecastInfoABKCalender from "./ForecastInfoABKCalender";
import modalActions from "../actions/modal.actions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { addDays, subDays } from "date-fns";

import { Tooltip as MTooltip } from "@material-ui/core";
import AuthService from "../services/auth/AuthService";
import LoadcurveService from "services/loadcurves/LoadcurveService";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface IForecastInformation {
  forecastFunction: string;
  forecastType: number;
  profileType: number;
  measureFrom: string;
  priceCurve: string;
  measureTo: string;
  forecastPeriod: string;
  productType: string;
  temperatureStation: string;
  holidayRegion: string;
  variant: string;
  performance: number;
  usage: number | undefined;
  //specific or measured
  usageType: string;
  creationTime: string;
  priceCurveName: string;
  creator: string;
  zrid?: number;
  name?: string;
  orderTo: string;
  orderFrom: string;
  acceptFrom: string;
  acceptTo: string;
  mehrFachTranchen: number;
  calendar: string;
}

const service = new ForecastService();
const CurveService = new LoadcurveService();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#5c5959",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

/**
 * Component for getting and showing the
 * Forecastinformation if provided
 * @constructor
 */
function ForecastInformationCardComponent({ zrid, loadcurve }) {
  const [ShowKalender, setShowKalender] = useState(false);
  const [EditBtn, setEditBtn] = useState(true);
  const [bewirtDatumEnd, setBewirtDatumEnd] = useState<any | undefined>(
    undefined
  );
  const role = AuthService.getRole();
  let history = useHistory();
  const forecastTypes = [
    "Individuelle Prognose",
    "Vergleichstagsverfahren",
    "Durchschnittliche Woche",
    "SLP",
    "Gas-TLP",
    "Fertige Struktur",
    "Standardhandelsprodukt",
    "Wärmeabhängig",
  ];
  const customerType = useSelector((state) => state.customer);
  const variants = ["ohne Wind", "windarm", "normal", "windig", "windreich"];
  const location = useLocation();
  const pathNameIndexFour = location.pathname?.split("/")[4];
  const pathNameIndexFive = location.pathname?.split("/")[5];
  //if still getting === undefined, if nothing found, null
  const [forecastModel, setForecastModel] = useState<
    IForecastInformation | undefined | null
  >(undefined);
  const [formelFromJson, setFormelFromjson] = useState({});
  const [constant, setConstant] = useState(0);
  const [formulas, setFormulas] = useState<any[]>([]);
  const [gesamtFormel, setGesamtFormel] = useState<string[]>([]);
  const tranche = loadcurve?.tranche;
  const [rowCalenderString, setRowCalenderString] = useState("");
  const [CalenderArray, setCalenderArray] = useState([]);

  const dispatch = useDispatch();

  function ABKhandler(e) {
    dispatch(
      //@ts-ignore
      modalActions.openModal(
        ForecastInfoABKCalender,
        {
          CalenderArray: CalenderArray,
        },
        8
      )
    );
  }

  const locale = localStorage.locale;

//   async function getLoadCurve(){
//     try{
//     const data = await CurveService.getSingleLoadcurve(loadcurve.zrid);

//   } catch (e) {
//     if (e instanceof Error) {
//       if (e.message === "401") {
//         return history.push(`/logout`);
//       }
//       console.error(e);
//       dispatch(snackbarActions.openSnackbar(e.message, "error"));
//       /* return; */
//     }
//   }
// }

// const copyToClipboard = async () => {
//   try {
//     await navigator.clipboard.writeText(`Emdacs-${gesamtFormel}${constant}`);
//     // alert('Text copied to clipboard!');
//   } catch (err) {
//     console.error('Failed to copy: ', err);
//   }
// };

  function getForecastInfo(){
    service
    .getForecastInformation(zrid)
    .then((serviceData: ForecastInformation) => {
      serviceData.formula &&
        setFormelFromjson(JSON.parse(serviceData.formula));
      serviceData.calendar && setRowCalenderString(serviceData.calendar);

      Papa.parse(serviceData.info, {
        delimitersToGuess: ["|"],
        complete: (results) => {
          let model: IForecastInformation = {
            zrid: undefined,
            forecastFunction: "",
            forecastPeriod: "",
            measureFrom: "",
            measureTo: "",
            forecastType: -1,
            holidayRegion: "",
            performance: 0,
            productType: "",
            profileType: serviceData.profiletype,
            temperatureStation: "",
            usage: undefined,
            usageType: "",
            priceCurve: "",
            variant: "",
            priceCurveName: "",
            creationTime: "",
            creator: "",
            name: "",
            orderTo: "",
            orderFrom: "",
            acceptFrom: "",
            acceptTo: "",
            mehrFachTranchen: 0,
            calendar: "",
          };

          //slice the unnecessary elements
          const data = results.data[0].slice(2, results.data[0].length - 1);

          data.forEach((d) => {
            //split by = because thats how its split
            const splitted = d.split("=");

            switch (splitted[0]) {
              case "Prognoseverfahren":
                model.forecastType = parseFloat(splitted[1]);
                break;
              case "Feiertagsregion":
                model.holidayRegion = splitted[1];
                break;
              case "Verbrauchsart":
                model.usageType = splitted[1];
                break;
              case "SLPJVMWh":
                model.usage = parseFloat(splitted[1]);
                break;
              case "PLZ":
                model.temperatureStation = splitted[1];
                break;
              case "Prognosezeiten":
                model.forecastPeriod = splitted[1];
                break;
              case "MessVon":
                model.measureFrom = splitted[1];
                break;
              case "MessBis":
                model.measureTo = splitted[1];
                break;
              case "IsBase":
                model.productType =
                  parseFloat(splitted[1]) === 0 ? "Peak" : "Base";
                break;
              case "Leistung_kW":
                model.performance = parseFloat(splitted[1]);
                break;
              case "Variante":
                model.variant = splitted[1];
            }
          });
          //switch over the first item of array
          // console.log("serviceData", serviceData);
          model.zrid = serviceData.zrid;
          model.profileType = serviceData.profiletype;
          model.forecastFunction = serviceData.function;
          model.creationTime = serviceData.timestamp;
          model.creator = serviceData.email;
          /*  const splitName = serviceData.name.split("_"); */
          model.priceCurveName = serviceData.pricingcurve;
          model.name = loadcurve?.zr_name;
          model.orderFrom = loadcurve.order_from;
          model.orderTo = loadcurve.order_to;
          model.acceptFrom = loadcurve.accept_from;
          model.acceptTo = loadcurve.accept_to;
          model.mehrFachTranchen = loadcurve.max_order_tranche;
          // console.log("serviceData", serviceData);
          /* model.priceCurve = splitName[1];
                      model.priceCurveName = splitName[0]; */
          setForecastModel(model);
        },
      });
    })
    .catch((e) => {
      if (e.message === "401") {
        return history.push(`/logout`);
      }
      // console.error("Error getting forecastinformation for ZRID", zrid, e);
      // dispatch(snackbarActions.openSnackbar(e.message, "error"));
      setForecastModel(null);
    });
  }

  useEffect(() => {
    getForecastInfo()
    // getLoadCurve()
  }, []);



  function decodeBase64ToArray(base64String) {
    // Decode the Base64 string
    const decodedString = atob(base64String);
    // Split the decoded string into an array of strings based on a delimiter (e.g., comma)

    return decodedString;
  }

  useEffect(() => {
    //@ts-ignore
    setCalenderArray(decodeBase64ToArray(rowCalenderString).split(",").map((i) => i.replace(/[\[\]'""]/g, ""))
    );
  }, [rowCalenderString]);

  useEffect(() => {
    if (Object.keys(formelFromJson).length > 0) {
      setConstant(formelFromJson["constant"]);
      setFormulas(formelFromJson["formulas"]);
      if (formelFromJson["formulas"].length === 1)
        setGesamtFormel([
          `${formelFromJson["formulas"][0].factor} x ${formelFromJson["formulas"][0].time_frame} ${formelFromJson["formulas"][0].product} ${formelFromJson["formulas"][0].year} + `,
        ]);
      if (formelFromJson["formulas"].length > 1) {
        for (let i of formelFromJson["formulas"]) {
          gesamtFormel.push(
            ` ${i.factor} x ${i.time_frame} ${i.product} ${i.year} + `
          );
        }
      }
    }
  }, [Object.keys(formelFromJson).length]);

  function BewirtEndChangeHandler(e) {
    CurveService.changeOrderEndDate(Number(zrid), bewirtDatumEnd.toISOString()).then((res) =>{
      
    });
    setEditBtn(true);
    setShowKalender(false);
  }

  function Translate(bez){
    return (locale !== "de" && bez === "Standardhandelsprodukt") ? "Standard trading product" : bez 
 }

  return (
    <>
      {/* {forecastModel !== null && forecastModel !== undefined && (
        <Grid container style={{ flexGrow: 1 }} direction="row" spacing={5}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h6" gutterBottom>
              {lang[locale].additionalInformation}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Card style={{ width: "100%" }}>
              <CardContent> */}
      {/* <Typography>
                  <b>{lang[locale].offresid} </b>
                  {forecastModel.zrid}
                </Typography> */}
      {/* <Typography>
                  <b>{lang[locale].offerName}: </b>
                  {forecastModel.name}
                </Typography> */}
      {/* <Typography>
                  <b>{lang[locale].forecastType}:</b>{" "}
                  {forecastModel.forecastFunction}
                </Typography> */}
      {/* <Typography>
                  <b>{lang[locale].creationTime}:</b>{" "}
                  {new Date(forecastModel.creationTime).toLocaleString()}
                </Typography> */}
      {/* <Typography>
                  <b>{lang[locale].priceCurveName}:</b>{" "}
                  {forecastModel.priceCurveName}
                </Typography> */}

      {/* {formelFromJson && Object.keys(formelFromJson).length > 0 && (
                  <MTooltip
                    title={`${gesamtFormel.join("")}${constant}`}
                    aria-label="Name"
                  >
                    <Typography>
                      <b>{lang[locale].formula}:</b>{" "}
                      {gesamtFormel.length < 20
                        ? `${gesamtFormel.join("")}${constant}`
                        : `${gesamtFormel.join("").slice(0, 17)}...${constant}`}
                    </Typography>
                  </MTooltip>
                )} */}
      {/* <Typography>
                  <b>{lang[locale].forecastPeriod}:</b>{" "}
                  {forecastModel.forecastPeriod}
                </Typography> */}
      {/* {forecastModel.holidayRegion && (
                  <Typography>
                    <b>{lang[locale].holidayRegion}:</b>{" "}
                    {forecastModel.holidayRegion}
                  </Typography>
                )} */}
      {/* {(forecastModel.forecastType === 3 ||
                  forecastModel.forecastType === 4) && (
                  <Typography>
                    <b>{lang[locale].profileType}:</b>{" "}
                    {forecastModel.profileType}
                  </Typography>
                )} */}

      {/* {Object.keys(forecastModel.usageType) &&
                forecastModel.usageType === "spezifisch" ? (
                  <Typography>
                    <b>{lang[locale].usageType}:</b> {lang[locale].specific}
                  </Typography>
                ) : forecastModel.usageType === "gemessen" ? (
                  <Typography>
                    <b>{lang[locale].usageType}:</b> {lang[locale].measured}
                  </Typography>
                ) : null}
                {forecastModel.usage && (
                  <Typography>
                    <b>
                      {Object.keys(forecastModel.usageType) &&
                      forecastModel.usageType === "spezifisch"
                        ? `${lang[locale].daily}${lang[locale].usage}`
                        : forecastModel.usageType === "gemessen"
                        ? `${lang[locale].measured}${lang[locale].usage}`
                        : null}
                    </b>{" "}
                    {Object.keys(forecastModel.usageType) &&
                    forecastModel.usageType === "spezifisch" ? (
                      <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale={true}
                        decimalScale={3}
                        suffix=" MWh / Tag"
                        value={forecastModel.usage}
                        displayType={"text"}
                      />
                    ) : forecastModel.usageType === "gemessen" ? (
                      <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        fixedDecimalScale={true}
                        decimalScale={3}
                        suffix=" MWh"
                        value={forecastModel.usage}
                        displayType={"text"}
                      />
                    ) : null}
                  </Typography>
                )}
                {forecastModel.measureFrom && (
                  <Typography>
                    <b>{lang[locale].measuredPeriod}:</b>{" "}
                    {forecastModel.measureFrom}-{forecastModel.measureTo}
                  </Typography>
                )}
                {forecastModel.variant && (
                  <Typography>
                    <b>{lang[locale].variant}:</b> {forecastModel.variant}{" "}
                    {variants[parseFloat(forecastModel.variant) - 1]}
                  </Typography>
                )}
                {forecastModel.temperatureStation && (
                  <Typography>
                    <b>{lang[locale].temperatureStation}:</b>{" "}
                    {forecastModel.temperatureStation}
                  </Typography>
                )}
                {forecastModel.forecastType === 6 && (
                  <>
                    <Typography>
                      <b>{lang[locale].productType}:</b>{" "}
                      {forecastModel.productType}
                    </Typography>
                    <Typography>
                      <b>{lang[locale].performance}:</b>{" "}
                      <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        value={forecastModel.performance}
                        displayType={"text"}
                        decimalScale={0}
                        fixedDecimalScale={true}
                      />{" "}
                      kW
                    </Typography>
                  </>
                )}
                <Typography>
                  <b>{lang[locale].creator}:</b> {forecastModel.creator}
                </Typography>
                {pathNameIndexFive !== pathNameIndexFour &&
                  tranche !== undefined && (
                    <Typography>
                      <b>{lang[locale].noOfTranches}:</b>{" "}
                      {customerType["customerData"]["type"] !== undefined &&
                      customerType["customerData"]["type"] === "F" &&
                      tranche === 0
                        ? lang[locale].unlimited
                        : tranche}
                    </Typography>
                  )}
                {forecastModel.mehrFachTranchen && (
                  <Typography>
                    <b>{lang[locale].maxmoreTranchenOrder}:</b>{" "}
                    {forecastModel.mehrFachTranchen}
                  </Typography>
                )}
                {rowCalenderString.length > 4 && CalenderArray.length > 0 && (
                  <Typography>
                    <a
                      href="#"
                      onClick={(e) => ABKhandler(e)}
                      style={{ color: "#20F0F7" }}
                    >
                      {lang[locale].procurementCalendar}
                    </a>
                  </Typography> */}
      {/* )} */}
      {/* {
                  forecastModel.acceptFrom && (
                    <Typography>
                  <b>{lang[locale].acceptFrom}:</b>{" "}
                  { forecastModel.acceptFrom
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join(".")}
                </Typography>
                  )
                } */}
      {/* {forecastModel.orderFrom && forecastModel.orderTo && (
                  <Typography>
                    <b>{lang[locale].ManagementPeriod}:</b>{" "}
                    {forecastModel.orderFrom
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join(".")}{" "}
                    -{" "}
                    {forecastModel.orderTo &&
                      forecastModel.orderTo
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join(".")}
                  </Typography>
                )}
                {forecastModel.acceptTo && (
                  <Typography>
                    <b>{lang[locale].acceptTo}:</b>{" "}
                    {forecastModel.acceptTo
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join(".")}
                  </Typography>
                )}

                <Grid item xs={6}></Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )} */}

      {forecastModel !== null && forecastModel !== undefined && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  {" "}
                  <b>{lang[locale].offresid} </b>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {forecastModel.zrid}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  {" "}
                  <b>{lang[locale].offerName}: </b>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {forecastModel.name}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  {" "}
                  <b>{lang[locale].forecastType}: </b>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {Translate(forecastModel.forecastFunction)}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  {" "}
                  <b>{lang[locale].creationTime}:</b>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {new Date(forecastModel.creationTime).toLocaleString()}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  {" "}
                  <b>{lang[locale].priceCurveName}:</b>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {forecastModel.priceCurveName}
                </StyledTableCell>
              </TableRow>
              {formelFromJson && Object.keys(formelFromJson).length > 0 && (
                <TableRow>
                  <MTooltip
                    title={`${gesamtFormel.join("")}${constant}`}
                    aria-label="Name"
                  >
                    <StyledTableCell>
                      {" "}
                      <b>{lang[locale].formula}</b>
                    </StyledTableCell>
                  </MTooltip>
                  <StyledTableCell align="left">
                    {gesamtFormel.length < 20
                      ? `${gesamtFormel.join("")}${constant}`
                      : `${gesamtFormel.join("").slice(0, 17)}...${constant}`}
                      {/* <IconButton
                      edge="end"
                      aria-label="paste"
                      onClick={copyToClipboard}
                      style={{
                      marginLeft : "3rem",
                      color: "white",
                      }}
                        >
                     <ContentCopyIcon />
                     </IconButton> */}
                  </StyledTableCell>
                </TableRow>
              )}
              <TableRow>
                <StyledTableCell>
                  {" "}
                  <b>{lang[locale].deliveryPeriod}:</b>
                </StyledTableCell>
                <StyledTableCell align="left">
                  {forecastModel.forecastPeriod.split("-")[0]}&nbsp;-&nbsp;{forecastModel.forecastPeriod.split("-")[1]}
                </StyledTableCell>
              </TableRow>
              {forecastModel.holidayRegion && (
                <>
                  <StyledTableCell>
                    {" "}
                    <b>{lang[locale].holidayRegion}:</b>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {forecastModel.holidayRegion}
                  </StyledTableCell>
                </>
              )}
              {(forecastModel.forecastType === 3 ||
                forecastModel.forecastType === 4) && (
                <TableRow>
                  <StyledTableCell>
                    <b>{lang[locale].profileType}:</b>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {forecastModel.profileType}
                  </StyledTableCell>
                </TableRow>
              )}
              {Object.keys(forecastModel.usageType) &&
              forecastModel.usageType === "spezifisch" ? (
                <>
                  <StyledTableCell>
                    <b>{lang[locale].usageType}:</b>
                  </StyledTableCell>
                  <StyledTableCell>{lang[locale].specific}</StyledTableCell>
                </>
              ) : forecastModel.usageType === "gemessen" ? (
                <>
                  <StyledTableCell>
                    <b>{lang[locale].usageType}:</b>
                  </StyledTableCell>
                  <StyledTableCell>{lang[locale].measured}</StyledTableCell>
                </>
              ) : null}

              {forecastModel.usage && (
                <TableRow>
                  {Object.keys(forecastModel.usageType) &&
                  forecastModel.usageType === "spezifisch" ? (
                    <>
                      <StyledTableCell>
                       <b>{lang[locale].dailyusage}:</b>
                        {/* {lang[locale].usage} */}
                      </StyledTableCell>
                      <StyledTableCell>
                        <NumberFormat
                          thousandSeparator="."
                          decimalSeparator=","
                          fixedDecimalScale={true}
                          decimalScale={3}
                          suffix=" MWh / Tag"
                          value={forecastModel.usage}
                          displayType={"text"}
                        />
                      </StyledTableCell>
                    </>
                  ) : forecastModel.usageType === "gemessen" ? (
                    <>
                      <StyledTableCell>
                        <b>{lang[locale].MeasuredConsumption}:</b>
                      </StyledTableCell>
                      <StyledTableCell>
                        <NumberFormat
                          thousandSeparator="."
                          decimalSeparator=","
                          fixedDecimalScale={true}
                          decimalScale={3}
                          suffix=" MWh"
                          value={forecastModel.usage}
                          displayType={"text"}
                        />
                      </StyledTableCell>
                    </>
                  ) : null}
                </TableRow>
              )}
              {/* {Object.keys(forecastModel.usageType) &&
                (forecastModel.usageType === "spezifisch" ? (
                  <TableRow></TableRow>
                ) : forecastModel.usageType === "gemessen" ? (
                  <TableRow></TableRow>
                ) : null)} */}
              {forecastModel.measureFrom && (
                <TableRow>
                  <StyledTableCell>
                    <b>{lang[locale].measuredPeriod}:</b>{" "}
                  </StyledTableCell>
                  <StyledTableCell>
                    {forecastModel.measureFrom}-{forecastModel.measureTo}
                  </StyledTableCell>
                </TableRow>
              )}
              {forecastModel.variant && (
                <TableRow>
                  <StyledTableCell>
                    <b>{lang[locale].variant}:</b>
                  </StyledTableCell>
                  <StyledTableCell>
                    {forecastModel.variant}{" "}
                    {variants[parseFloat(forecastModel.variant) - 1]}
                  </StyledTableCell>
                </TableRow>
              )}
              {forecastModel.temperatureStation && (
                <TableRow>
                  <StyledTableCell>
                    <b>{lang[locale].temperatureStation}:</b>{" "}
                  </StyledTableCell>
                  <StyledTableCell>
                    {forecastModel.temperatureStation}
                  </StyledTableCell>
                </TableRow>
              )}
              {forecastModel.forecastType === 6 && (
                <>
                  <TableRow>
                    <StyledTableCell>
                      <b>{lang[locale].productType}:</b>{" "}
                    </StyledTableCell>
                    <StyledTableCell>
                      {forecastModel.productType}
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell>
                      <b>{lang[locale].performance}:</b>{" "}
                    </StyledTableCell>
                    <StyledTableCell>
                    <NumberFormat
                          thousandSeparator="."
                          decimalSeparator=","
                          fixedDecimalScale={true}
                          decimalScale={0}
                          suffix=" kW"
                          value={forecastModel?.performance}
                          displayType={"text"}
                        />
                    </StyledTableCell>
                  </TableRow>
                </>
              )}
              <TableRow>
                <StyledTableCell>
                  <b>{lang[locale].creator}:</b>
                </StyledTableCell>
                <StyledTableCell>{forecastModel.creator}</StyledTableCell>
              </TableRow>
              {pathNameIndexFive !== pathNameIndexFour &&
                tranche !== undefined && (
                  <TableRow>
                    <StyledTableCell>
                      <b>{lang[locale].noOfTranches}:</b>{" "}
                    </StyledTableCell>
                    <StyledTableCell>
                      {customerType["customerData"]["type"] !== undefined &&
                      customerType["customerData"]["type"] === "F" &&
                      tranche === 0
                        ? lang[locale].unlimited
                        : tranche}
                    </StyledTableCell>
                  </TableRow>
                )}
              {forecastModel.mehrFachTranchen && (
                <TableRow>
                  <StyledTableCell>
                    <b>{lang[locale].maxmoreTranchenOrder}:</b>{" "}
                  </StyledTableCell>
                  <StyledTableCell>
                    {forecastModel.mehrFachTranchen}
                  </StyledTableCell>
                </TableRow>
              )}
              {rowCalenderString.length > 4 && CalenderArray.length > 0 ? (
                <TableRow>
                  <StyledTableCell><b>{lang[locale].TypeOfTrancheProcurement}:</b></StyledTableCell>
                  <StyledTableCell>
                      {lang[locale].Atp}
                  </StyledTableCell>
                </TableRow>
              ) :
              <TableRow>
                  <StyledTableCell><b>{lang[locale].TypeOfTrancheProcurement}:</b></StyledTableCell>
                  <StyledTableCell>
                      {lang[locale].Mtp}
                  </StyledTableCell>
                </TableRow>
              }
              {rowCalenderString.length > 4 && CalenderArray.length > 0 && (
                <TableRow>
                  <StyledTableCell><b>{lang[locale].procurementCal}:</b></StyledTableCell>
                  <StyledTableCell>
                    <a
                      href="#"
                      onClick={(e) => ABKhandler(e)}
                      style={{ color: "#20F0F7" }}
                    >
                      {lang[locale].procurementCalendar}
                    </a>
                  </StyledTableCell>
                </TableRow>
              )}
              {/* {
                  forecastModel.acceptFrom && (
                    <Typography>
                  <b>{lang[locale].acceptFrom}:</b>{" "}
                  { forecastModel.acceptFrom
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join(".")}
                </Typography>
                  )
                } */}
              {forecastModel.orderFrom && forecastModel.orderTo && (
                <TableRow>
                  <StyledTableCell>
                    <b>{lang[locale].ManagementPeriod}:</b>{" "}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {forecastModel.orderFrom
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join(".")}{" "}
                    -{" "}
                    {forecastModel.orderTo &&
                      forecastModel.orderTo
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join(".")}
                    {role.simple_role === "LIEFERANT" && EditBtn && (
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => {
                          setShowKalender(true);
                          setEditBtn(false);
                        }}
                        style={{
                          margin: "0 0 0.2rem 2rem",
                          color: "white",
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    {role.simple_role === "LIEFERANT" && ShowKalender && (
                      <>
                        <Grid item xs={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === "de" ? deLocale : enLocale}>
                          <KeyboardDatePicker
                            // inputValue={bewirtDatumEnd !== undefined ? bewirtDatumEnd.slice(0, 10) : " "}
                            // inputValue={bewirtDatumEnd !== undefined ? bewirtDatumEnd.slice(0, 10).split("-").reverse().join() : " "}
                            style={{ margin: "0 0 0.8rem 2rem" }}
                            helperText=""
                            required
                            variant="inline"
                            format="dd.MM.yyyy"
                            fullWidth
                            autoOk={true}
                            minDate={addDays(
                                new Date(
                                  forecastModel.orderTo
                                ),
                                1
                              )}
                            maxDate={subDays(
                              new Date(
                                forecastModel.forecastPeriod.split("-")[0]
                              ),
                              1
                            )}
                            onChange={(e) => setBewirtDatumEnd(e)}
                            value={bewirtDatumEnd}
                            // defaultValue={forecastModel.orderTo}
                            //   error={}
                            //   minDateMessage={
                            //     "Das Datum darf nicht vor dem " +
                            //     format(tommorow, "dd.MM.yyyy") +
                            //     " liegen."
                            //   }
                            id="startdate"
                            data-testid="startdate"
                            label={lang[locale].enddate}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            // TextFieldComponent={TextFieldComponent}
                            // error={
                            //   forecast.data.accept_from !== undefined &&
                            //   bewirtDatumEnd !== undefined &&
                            //   bewirtDatumStart !== undefined &&
                            //   (bewirtDatumEnd < bewirtDatumStart ||
                            //     bewirtDatumEnd < forecast.data.accept_from)
                            // }
                          />
                        </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid xs={3} style={{ marginLeft: "4rem" }}>
                          <IconButton
                            style={{ color: "white" }}
                            onClick={(e)=>BewirtEndChangeHandler(e)}
                          >
                            <CheckIcon></CheckIcon>
                          </IconButton>
                          <IconButton
                            style={{ marginLeft: "2rem", color: "white" }}
                            onClick={() => {
                              setEditBtn(true);
                              setShowKalender(false);
                            }}
                          >
                            <ClearIcon></ClearIcon>
                          </IconButton>
                        </Grid>
                      </>
                    )}
                  </StyledTableCell>
                </TableRow>
              )}
              {forecastModel.acceptTo && (
                <TableRow>
                  <StyledTableCell>
                    <b>{lang[locale].acceptTo}:</b>{" "}
                  </StyledTableCell>
                  <StyledTableCell>
                    {forecastModel.acceptTo
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join(".")}
                  </StyledTableCell>
                </TableRow>
              )}
            </TableHead>
            <TableBody></TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default ForecastInformationCardComponent;
