import React, { useState, useEffect } from "react";
import { lang } from "lang";
import {
  Grid,
  TextField,
  Typography,
  Button,
  CircularProgress,
  FormControl,
  Input,
  Checkbox,
  MenuItem,
  InputLabel,
  ListItemText,
  Select,
  Tooltip as MTooltip,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import authActions from "actions/auth.actions";
import modalActions from "../actions/modal.actions";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import _ from "lodash";
import { useSelector } from "services/helpers/useSelector";
import UserService from "../services/users/UserService";
import { useHistory } from "react-router-dom";
import snackbarActions from "../actions/snackbar.actions";
import LoadcurveService from "services/loadcurves/LoadcurveService";


const GeneralVerification = (props) => {
  const service: LoadcurveService = new LoadcurveService();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const userService = new UserService();
  const active = useSelector((state) => state.auth.active);
  const internalValidate = useSelector((state) => state.auth.internalValidate);
  const locale = localStorage.locale;
  const dispatch = useDispatch();
  const [validUser,setvalidUser] = useState(false);
  const [pin, setPin] = useState<string | undefined>();
  const [confirmationEmails, setConfirmationEmails] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);


  let history = useHistory();
  
  // console.log("internalValidate",internalValidate)
  // console.log("validUser",validUser)

  const isNumeric = (number) =>
    _.isFinite(_.parseInt(number)) &&
    !_.isNaN(_.parseInt(number)) &&
    number.length > 5;

    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        formControl: {
          margin: theme.spacing(1),
          minWidth: 350,
          maxWidth: 400,
        },
      })
    );

    const classes = useStyles();

    const handleSelect = (event) => {
      // console.log("handelSelected", event.target.value);
      setSelectedEmails(event.target.value as string[]);
    };

    const handleAcceptOffer = () => {
      //@ts-ignore
      service.getAcceptOffer(props.zrid,pin);
      props.setSnackbarChanged(true);
      dispatch(authActions.leavePage(false));
      setPin(undefined);
      dispatch(modalActions.closeModal());
    }

  function onSubmit(e) {
    e.preventDefault();
    dispatch(authActions.internalValidate(pin));
    setvalidUser(internalValidate);
    props.setSnackbarChanged(false);
  }

  useEffect(() => {
    userService
      .getCustomersEmails()
      .then((res) => {
        setConfirmationEmails(res);
        /*If there is just one email address it is automatically set as the selectedConfirmationEmail*/
        res.length === 1 && setSelectedEmails(res);
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        // dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <h1 style={{marginLeft : "1em"}}>{lang[locale].GeneralVerification}</h1>
      {!active ? (
        <div style={{margin: "0em 0em 2em em", padding:"2em 2em", color: "orange"}}>
          {lang[locale].twofa_muss_aktiv}
        </div>
      ) : (
        <form onSubmit={onSubmit}>
          <Grid
            container
            direction="column"
            spacing={4}
            data-testid="loginform"
            style={{margin: "0em 0em 2em em", padding:"2em 2em"}}
          >
            <Grid item xs={12}>
              <Typography variant="h5">{lang[locale].twoFA}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {lang[locale].inputPin}
              </Typography>
            </Grid>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                marginLeft : "1em"
              }}
            >
              <Grid item xs={4}>
                <TextField
                  id="pin"
                  label="Pin"
                  variant="outlined"
                  type="pin"
                  className="maximalWidth"
                  onChange={(e) => {
                    setPin(e.target.value);
                  }}
                  required
                  autoFocus
                  autoComplete="off"
                  disabled={internalValidate}
                  /* error={error ? true : false} */
                />
              </Grid>

              <Grid item xs={4}>
                <Grid container>
                {
                    
                    !internalValidate ? (<Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        data-testid="submit"
                        disabled={!pin || !isNumeric(pin)}
                        /* disabled={checkDisabled() || loading} */
                      >
                        <>{lang[locale].authenticating}</>
                      </Button>) : (<span style={{display : "flex",flexDirection : "row",justifyContent : "center",color : "green", alignItems : "center"}}><VerifiedUserIcon/> &nbsp; {lang[locale].verified}</span> )
                }
                  
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
      {/* { props.fourEyesStatus === 2 ? (
        <Grid item xs={12} style={{margin: "0em 0em 2em em", padding:"2em 2em"}}>
          <Grid item xs={12}>
              <Typography variant="h5">4AP:</Typography>
            </Grid>
        {confirmationEmails.length === 1 && (
          <>
            <p>{lang[locale].confirmation + ":"}</p>
            <p>{confirmationEmails[0]}</p>
          </>
        )}
        {confirmationEmails.length > 1 && (
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-mutiple-chip-label">
              {lang[locale].confirmation}
            </InputLabel>
            <Select
              labelId="demo-mutiple-chip-label"
              id="demo-mutiple-chip"
              multiple
              value={selectedEmails}
              onChange={handleSelect}
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) =>
                (selected as string[]).join(", ")
              }
              MenuProps={MenuProps}
              // disabled={finalBuy}
            >
              {confirmationEmails.map((email) => (
                <MenuItem key={email} value={email}>
                  <Checkbox
                    checked={selectedEmails.indexOf(email) > -1}
                  />
                  <ListItemText primary={email} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>
      ) : (<span>4AP ist off</span>)
                      
        } */}
        <div style={{display : "flex", flexDirection : "row", justifyContent : "flex-end", marginTop : "2rem"}}>

        <Button color="primary" onClick={()=>{dispatch(authActions.leavePage(false)); setPin(undefined); dispatch(modalActions.closeModal())}}>{lang[locale].close}</Button>
        <Button disabled={!internalValidate} onClick={()=>handleAcceptOffer()} color="primary">{lang[locale].next}</Button>
        </div>
    </>
  );
};

export default GeneralVerification;
