import { config } from "services/helpers/Constants";
import LoadcurveModel from "typeDefs/LoadcurveModel";
import LoadcurvePriceHistory from "typeDefs/LoadcurvePriceHistory";
import LoadcurveIndicators from "typeDefs/LoadcurveIndicators";
import LoadCurveFullHistory from "typeDefs/LoadCurveFullHistory";
import LoadcurveIntraday from "typeDefs/LoadcurveIntraday";
import ForecastModel from "../../typeDefs/ForecastModel";
import ProfileType from "../../typeDefs/ProfileType";
import { addHours, addMinutes, setHours } from "date-fns";
import OrderModel from "../../typeDefs/OrderModel";
import errorCodeTranslator from "../helpers/errorCodeTranslator";

/**
 * Class for managing loadcurves
 */
class LoadcurveService {
    /**
     * Fetches the csv data
     * @param amountOfValues either 96 or 24. How many values should be exported per day
     * @param zrid The zrid of the loadcure
     */

    public async getLoadcurveCSV(amountOfValues: number, zrid: number) {
        const res = await fetch(
            config.url.API_URL +
                "/api/personal/data/download/csv/" +
                zrid +
                "/" +
                amountOfValues
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status !== 200) {
            const json = await res.json();
            throw { message: errorCodeTranslator(json.message) };
        }
        return res;
    }
    public async getAcceptOffer(zrid: number, tfa:string) {
        const res = await fetch(
            config.url.API_URL +
                "/api/accept-offer/" +
                zrid,{
                    method: "POST",
                    body: JSON.stringify({ tfa : tfa }),
                }
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status !== 200) {
            const json = await res.json();
            throw { message: errorCodeTranslator(json.message) };
        }
        return res;
    }

    /** Getting the loadcurve of which the status is 1 or 2 */

    public async getLoadcurvesRemainingCustomer(): Promise<any> {
        const res = await fetch(
            config.url.API_URL + "/api/customer/data/remaining"
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status === 204) {
            return [];
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        
        return json;
    }

    public async getLoadcurvesRemainingSupplier(customer_id): Promise<any> {
        const res = await fetch(
            config.url.API_URL + "/api/admin/" + customer_id + "/data/remaining"
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status === 204) {
            return [];
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        
        return json;
    }

    /**
     * Getting the loadcurves directly via get-request from the server
     */
    public async getLoadcurvesCustomer(datakind): Promise<LoadcurveModel[]> {
        const res = await fetch(config.url.API_URL + "/api/customer/data?data_kind=" + datakind);
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status === 204) {
            return [];
        }
        const json = await res.json();

        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }
    
    public async getLoadcurvesSupplier(customer_id, datakind): Promise<LoadcurveModel[]> {
        const res = await fetch(
            config.url.API_URL + "/api/admin/" + customer_id + "/data?data_kind=" + datakind
            );
            if (res.status === 401) {
                throw { message: res.status.toString() };
            }
            if (res.status === 204) {
                return [];
            }
            const json = await res.json();
            if (res.status !== 200) {
                throw { message: errorCodeTranslator(json.message) };
            }
        
        return json;
    }

    /**
     * Is for getting a single Loadcurve plus information
     * @param zrid The zrid of the loadcurve which needs to be get
     */
    public async getSingleLoadcurve(zrid: number): Promise<LoadcurveModel> {
        const res = await fetch(
            config.url.API_URL + "/api/personal/data/" + zrid
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Gets full data of loadcurve
     * @param zrid of the loadcurve
     */
    public async getLoadcurveFullData(zrid: number): Promise<object[]> {
        return fetch(config.url.API_URL + "/api/personal/data/full/" + zrid)
            .then((res) => {
                if (res.status === 401) {
                    throw { message: res.status.toString() };
                }
                let json = res.json();
                return json;
            })
            .then((json) => {
                //the array that gets returned
                let retArray: Array<number[]> = [];
                //the data array
                let dateArray: number[] = [];
                //the value array
                let valueArray: number[] = [];
                for (let i = 0; i < json.data.length; i++) {
   
                    let date = new Date(json.data[i].timestamp);
                    //set the hours initially to 0 in order to count the hours up later
                    // date = setHours(date, 0);

                    // for (let z = 0; z < json.data.length; z++) {
                        dateArray.push(date.getTime() / 1000);
                        valueArray.push(json.data[i].value);
                        //If the data consists of 96 values that means 1 data point every 15 min
                        //Add 15 min: That gives us 4 values per hour and 96 values in a day.
                        // if (json.data.length / 96 === 1) {
                        //     date = addMinutes(date, 15);
                        // } else {
                        //     // if there are 24 values then count the hours up by 1
                        //     // that gives us 24 hours in a day.
                        //     date = addHours(date, 1);
                        // }
                    // }
                }
            
                retArray.push(dateArray);
                retArray.push(valueArray);
                return retArray;
            });
    }

    /**
     * Gets price history of one loadcurve
     * @param zrid: ID of loadcurve
     */
    public async getLoadcurvePriceHistory(
        zrid: number
    ): Promise<LoadcurvePriceHistory[]> {
        const res = await fetch(
            `${config.url.API_URL}/api/personal/hist/${zrid}`
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status === 204) return [];
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json.history;
    }
    /**
     * Gets intraday  of one loadcurve
     * @param zrid: ID of loadcurve
     */
    public async getIntraday(
            zrid: number
        ): Promise<LoadcurveIntraday[]> {
            const res = await fetch(
                `${config.url.API_URL}/api/personal/hist/intraday/${zrid}`
            );
            
            if (res.status === 401) {
                throw { message: res.status.toString() };
            }
            if (res.status === 204) return [];
            const json = await res.json();
            
            if (res.status !== 200) {
                throw { message: errorCodeTranslator(json.message) };
            }
            return json;
        }

    /**
     * Gets indicators of one loadcurve
     * @param zrid: ID of loadcurve
     */
     public async getLoadcurveIndicators(
        zrid: number,
        indictypeA:string,
        indictypeB:string,
        indictypeC:string,
        indictypeD:string,
        indictypeE:string,
        indictypeF:string,
        indictypeG:string,
        days: number
    ): Promise<LoadcurveIndicators[]> {
        const res = await fetch(
            `${config.url.API_URL}/api/personal/hist/${zrid}?indicator=${indictypeA}&indicator=${indictypeB}&indicator=${indictypeC}&indicator=${indictypeD}&indicator=${indictypeE}&indicator=${indictypeF}&indicator=${indictypeG}&days=${days}`
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status === 204) return [];
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json.indicators;
    }


    /**
     * Gets full Preis history of one loadcurve
     * @param zrid: ID of loadcurve
     */
     public async getFullPreisHistory(
        zrid: number,
        indictypeA:string,
        indictypeB:string,
        days: number
    ): Promise<LoadCurveFullHistory[]> {
        const res = await fetch(
            `${config.url.API_URL}/api/personal/hist/${zrid}?indicator=${indictypeA}&indicator=${indictypeB}&days=${days}`
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status === 204) return [];
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json.history;
    }

    /**
     * Gets latest price history of one loadcurve
     * @param zrid: ID of loadcurve
     */

    public async getLoadCurveLatestHistory(zrid: number): Promise<any> {
        const res = await fetch(
            config.url.API_URL + "/api/personal/hist/latest/" + zrid
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status === 204) return null;
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Gets batch latest price history of one loadcurve
     */

    public async getLoadCurveBatchLatestHistory(loadcurvelist): Promise<any> {
        const res = await fetch(
            config.url.API_URL + "/api/personal/hist/latest",{
                method: "POST",
                body: JSON.stringify({ ids : loadcurvelist }),
            });
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status === 204) return null;
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Gets batch latest price history of one loadcurve
     */

    public async getLoadCurveBatchFullHistory(loadcurvelist): Promise<any> {
        const res = await fetch(
            config.url.API_URL + "/api/personal/hist",{
                method: "POST",
                body: JSON.stringify({ ids : loadcurvelist })},
            );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status === 204) return null;
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    public async changeOrderEndDate(zrid, time): Promise<any> {
        const res = await fetch(
            config.url.API_URL + "/api/admin/update/order/timeframe",{
                method: "POST",
                body: JSON.stringify({ zrid : zrid, time : time })},
            );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status === 204) return null;
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Uploads a loadcurve (.fpl, .fpg) to the api
     * @param file FPL file which gets uploaded
     * @param priceCurve the pricecurve to price the loadcurve
     */
    public async createLoadcurve(
        file: File,
        priceCurve: string
    ): Promise<Response> {
        //create new form object
        const formData = new FormData();
        formData.append("fileupload", file);
        formData.append("pricecurve", priceCurve);
        //fetch to the api
        const res = await fetch(config.url.API_URL + "/api/upload/ttpfm", {
            method: "POST",
            body: formData,
        });
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Gets holidays and returns them
     */
    public async getHolidays(): Promise<string[]> {
        const res = await fetch(config.url.API_URL + "/api/generic/holiday");
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json.descriptor;
    }
    /**
     * Gets holidays and returns them
     */
    public async getHolidayABK(): Promise<string[]> {
        const res = await fetch(config.url.API_URL + "/api/holiday");
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Gets power or gas profiles for slp loadcurves
     */
    public async getProfiles(type: string): Promise<ProfileType[]> {
        const res = await fetch(config.url.API_URL + "/api/generic/" + type);
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Gets forecasttypes
     * @param type either gas or power
     */
    public async getForecastTypes(type: string): Promise<ForecastModel[]> {
        const res = await fetch(config.url.API_URL + "/api/forecast/" + type);
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Gets stations for temperatures and so on
     */
    public async getStations(): Promise<string[]> {
        const res = await fetch(
            config.url.API_URL + "/api/generic/temperature"
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json.descriptor;
    }

    /**
     * Deletes loadcurve on api
     * @param zrid of loadcurve which should get deleted
     */
    public async deleteLoadcurve(zrid: number): Promise<void> {
        const res = await fetch(
            config.url.API_URL + "/api/personal/data/delete/" + zrid,
            {
                method: "DELETE",
            }
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }
    /**
     * Deletes loadcurve Preis Entwicklung on api
     * @param zrid of loadcurve which should get deleted
     */
    public async deleteLoadcurvePE(zrid: number): Promise<void> {
        const res = await fetch(
            config.url.API_URL + "/api/admin/base/delete/" + zrid,
            {
                method: "DELETE",
            }
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200 && res.status !== 204) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    public async deleteCaseNumber(caseNumber: string): Promise<void> {
        const res = await fetch(config.url.API_URL + "/api/order/delete/case/" + caseNumber, {method: "DELETE"});
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }
    /**
     * Buys loadcurve to recent price
     * @param zrid of loadcurve which should get bought
     * @param tan to send to server
     */
    public async buyLoadcurve(zrid: number, tan: string, CaseNumber: string, tfa:string ): Promise<void> {
        const res = await fetch(config.url.API_URL + "/api/order/buy/" + zrid, {
            method: "POST",
            body: JSON.stringify({ tan, case_number:CaseNumber, tfa }),
        });
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }


    public async buyDelayedLoadcurve(zrid: number, date: string, tfa:string ): Promise<void> {
        const res = await fetch(config.url.API_URL + "/api/order/buy/delayed/" + zrid, {
            method: "POST",
            body: JSON.stringify({ date, tfa, zrid }),
        });
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200 ) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }


    public async createVirtualBuy(customer: number, dat, amount, price, prog_zrid, virtual): Promise<void> {
        const res = await fetch(config.url.API_URL + "/api/customer/" + customer +"/order/insert", {
            method: "POST",
            body: JSON.stringify({ dat, amount, price, prog_zrid, virtual }),
        });
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    public async deleteVirtualBuy(customer: number, zrid:number): Promise<void> {
        const res = await fetch(
            config.url.API_URL + "/api/customer/" + customer +"/order/delete/" + zrid,
            {
                method: "DELETE",
            }
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Starts the buying process
     * creates a tan
     */
    public async startBuying(
        price: number,
        zrid: number,
        emails: string[],
        percentage: number,
        quantity: number,
        overwrite: boolean,

    ): Promise<void> {
        const res = await fetch(
            config.url.API_URL + "/api/order/start/case",
            {
                method: "POST",
                body: JSON.stringify({ price, zrid, emails, percentage, quantity, overwrite }),
            }
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200)
            throw {
                message: errorCodeTranslator(json.message),
            };
        return json;
    }

    public async fplCharge(zrid: number, charge: number): Promise<string> {
        const res = await fetch(config.url.API_URL + "/api/admin/update/fplcharge", {
            method: "POST",
            body: JSON.stringify({ zrid, charge }),
        });
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json.message;
    }
    /**
     * Gets all orders of a customer
     */
    public async getOrders(): Promise<OrderModel[]> {
        const res = await fetch(config.url.API_URL + "/api/order/fullhistory");
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status === 204) {
            return [];
        }
        const json = await res.json();
        if (res.status !== 200)
            throw {
                message: errorCodeTranslator(json.message),
            };
        return json;
    }

    public async getAllOrders(): Promise<OrderModel[]> {
        const res = await fetch(config.url.API_URL + "/api/admin/orders");
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status === 204) {
            return [];
        }
        const json = await res.json();
        if (res.status !== 200)
            throw {
                message: errorCodeTranslator(json.message),
            };

        return json;
    }

    /**
     * Gets orders from api for a certain zrid
     * @param zrid of the loadcurve
     */
    public async getOrdersByZrid(zrid: number): Promise<OrderModel[]> {
        const res = await fetch(
            config.url.API_URL + "/api/order/history/" + zrid
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        } else if (res.status === 204) {
            return [];
        }
        const json = await res.json();

        if (res.status !== 200)
            throw {
                message: errorCodeTranslator(json.message),
            };
        return json;
    }

    public async getConstants(): Promise<any> {
        const res = await fetch(config.url.API_URL + "/api/constants");
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200)
            throw {
                message: errorCodeTranslator(json.message),
            };
        return json;
    }
}

export default LoadcurveService;
