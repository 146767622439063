import React from "react";
import { Grid, TextField, Tooltip } from "@material-ui/core";
import { lang } from "../lang";

function ShowValuesComponent({ startValueInput, endValueInput }) {
    const locale = localStorage.locale;
    return (
        <Grid container alignItems="center" spacing={4}>
            <Grid item xs={6} style={{padding : "0.8rem 1rem 1rem 1rem"}}>
                <Tooltip title={lang[locale].startValueHint}>
                <TextField
                    error={startValueInput === ""}
                    id="startvalue"
                    label={lang[locale].startvalue}
                    name="startvalue"
                    disabled
                    type="text"
                    fullWidth
                    value={startValueInput}
                    
                    />
                </Tooltip>
            </Grid>
            <Grid item xs={6} style={{padding : "0.8rem 1rem 1rem 1rem"}}>
                <Tooltip title={lang[locale].startValueHint}>
                <TextField
                    error={endValueInput === ""}
                    fullWidth
                    id="endvalue"
                    label={lang[locale].endvalue}
                    name="endvalue"
                    disabled
                    type="text"
                    value={endValueInput}
                />
                </Tooltip>
            </Grid>
        </Grid>
    );
}

export default ShowValuesComponent;
