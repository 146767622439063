import React, { useEffect } from "react";
import ForecastService from "../services/forecast/ForecastService";
import LoadcurveService from "../services/loadcurves/LoadcurveService";
import LineChartComponent from "./LineChart";
import { Grid } from "@material-ui/core";
import LoadcurveModel from "../typeDefs/LoadcurveModel";
import { useDispatch } from "react-redux";
import snackbarActions from "actions/snackbar.actions";
import { useHistory } from "react-router-dom";
import { useSelector } from "../services/helpers/useSelector";
import { lang } from "../lang";

const forecastService = new ForecastService();
const loadcurveService = new LoadcurveService();

/**
 * Shows the IST Data (also fetches them)
 * @constructor
 * @param zrid of the forecast
 */
function ShowIstDataComponent({ zrid, color, IstDataFromChild }) {
    const rotatedTextStyle = {
        transform: 'rotate(-90deg)',
        width : 100,
        marginRight : "0.1rem"
      };
    const locale = localStorage.locale;
    const [istData, setIstData] = React.useState<undefined | object[]>();
    const loggedIn = useSelector((state) => state.auth.loggedIn);
    const [loadcurve, setLoadcurve] = React.useState<
        LoadcurveModel | undefined
    >(undefined);
    const dispatch = useDispatch();
    let history = useHistory();

    //gets istData from api
    useEffect(() => {
        if(loggedIn){
            forecastService
            .getForecastInformation(zrid)
            .then((res) => {
                //check if zrid is there
                if (res.zrid_ist) {
                    loadcurveService
                    .getLoadcurveFullData(res.zrid_ist)
                    .then((data) => {
                        setIstData(data);
                        IstDataFromChild(true);
                        loadcurveService
                        .getSingleLoadcurve(res.zrid_ist)
                        .then((curve) => {
                            setLoadcurve(curve);
                        });
                    });
                }
            })
            .catch((e) => {
                if (e.message === "401") {
                    return history.push(`/logout`);
                }
                console.error(e);
                dispatch(snackbarActions.openSnackbar(e.message, "error"));
            });
        }
    }, []);

    return (
        <>
            {istData && loadcurve && (
                <Grid container>
                    <Grid item xs={6} style={{display : "flex", flexDirection : "row", justifyContent : "center", alignItems : "center"}}>
                    <div style={rotatedTextStyle}>{lang[locale].performance}&nbsp;[kW]</div>
                    <LineChartComponent
                        color={color}
                        data={istData}
                        title={`${lang[locale].actualData}: ${loadcurve?.zr_name}`}
                    />
                    </Grid>
                    {/* {console.log("loadcurve", loadcurve)} */}
                </Grid>
            )}
        </>
    );
}

export default ShowIstDataComponent;
