import React, { useEffect, useState } from "react";

import {
  Card,
  Paper,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
  useTheme,
  Chip,
  IconButton,
  CircularProgress,
  Grow,
  Menu,
  MenuItem,
  Tooltip as MTooltip,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  LineChart,
  XAxis,
  YAxis,
  Line,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { lang } from "lang";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import VizSensor from "react-visibility-sensor";

//TypeDefs
import OrderModel from "../typeDefs/OrderModel";
import LoadcurvePriceHistory from "typeDefs/LoadcurvePriceHistory";
import LoadcurveModel from "typeDefs/LoadcurveModel";
import LoadcurveIntraday from "typeDefs/LoadcurveIntraday";

//Icons
import InfoIcon from "@material-ui/icons/Info";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import MoreVertIcon from "@material-ui/icons/MoreVert";

//Hooks
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSelector } from "../services/helpers/useSelector";

//Services
import AuthService from "../services/auth/AuthService";
import PriceService from "../services/price/PriceService";
import LoadcurveService from "services/loadcurves/LoadcurveService";
import ForecastService from "../services/forecast/ForecastService";

import UserService from "../services/users/UserService";

//Actions
import snackbarActions from "../actions/snackbar.actions";
import authActions from "../actions/auth.actions";
import modalActions from "../actions/modal.actions";

//Components
import SingleBuyHistoryModalComponent from "./SingleBuyHistoryModal";
import DeleteLoadcurveComponentPE from "./DeleteLoadcurvePE";
import ExportComponent from "./Export";
import BuyLoadcurveComponent from "./BuyLoadcurve";
import SetPriceBoundMainPageComponent from "./SetPriceBoundMainPage";
import LineProgress from "./LineProgress";
import AddToPortfolio from "./AddToPortfolio";
import OfferSurchargeComponent from "./OfferSurchargePage";

interface CardProps {
  loadcurve: LoadcurveModel;
  id: any;
  curvelistChanged?: string;
  orderListChanged?: boolean;
  setPEfilterChange?: any;
}

interface DiagramData {
  name: Date;
  price: number;
}

const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
  },
  forecastChip: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
  },
  boughtChip: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

/**
 * Formats date to dd.mm.yyyy
 * @param date The date which should get formatted
 */
function formatDate(date: Date): string {
  return (
    ("0" + date.getDate()).slice(-2) +
    "." +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "." +
    date.getFullYear()
  );
}

const service: LoadcurveService = new LoadcurveService();
const priceService: PriceService = new PriceService();
const tooltipOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};
const LegendOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  // day: "numeric",
};
const intraOptions: Intl.DateTimeFormatOptions = {
  // year: "numeric",
  // month: "short",
  // day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

function LoadcurveCardComponentPE({
  loadcurve,
  id,
  setPEfilterChange,
}: CardProps) {
  const loadCurveCopy = { ...loadcurve };
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  // const loadcurveLenght = useSelector(
  //   (state) => state.loadcurve.loadcurves.length
  // );
  const forecastService = new ForecastService();
  let history = useHistory();
  const role = AuthService.getRole();
  const theme = useTheme();
  const locale = localStorage.locale;
  const localized = localStorage.locale;

  const dispatch = useDispatch();
  const [priceHistory, setPriceHistory] = useState<any>(undefined);
  const [inraDayPrice, setIntradayPrice] = useState<any>(undefined);
  const [recentPrice, setRecentPrice] = useState<number | undefined>(undefined);
  const [recentDate, setRecentDate] = useState<string | undefined>(undefined);
  const [recentTime, setRecentTime] = useState<string | undefined>(undefined);
  const [watchingIndicator, setWatchingIndicator] = useState(false);
  const [PreisHistoryChart, setPreisHistoryChart] = useState(true);
  const [intradayChart, setIntradayChart] = useState(false);
  const [orders, setOrders] = useState<OrderModel[] | undefined>(undefined);
  const [showHistory, setShowHistory] = useState(true);
  const [historyRight, setHistoryRight] = useState(false);
  const [showIntra, setShowIntra] = useState(false);
  const [intraRight, setIntraRight] = useState(false);
  const statePriceActive = useSelector((state) => state.price);
  const customerData = useSelector((state) => state.customer["customerData"]);
  const {ciSettings} = useSelector((state) => state);
  const [readHist, setReadHist] = useState(true);
  //date for checking
  const [loading, setLoading] = React.useState(true);
  const [preisType, setPreisType] = useState("");
  const [isBuying, setIsBuying] = useState(false);
  //for loading price history
  //dates but formatted
  const [from, setFrom] = React.useState("");
  const [to, setTo] = React.useState("");
  //menu stuff
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [charge, setCharge] = useState<any>(undefined);
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const [latestOrderList, setLatestOrderList] = useState<
    OrderModel[] | undefined
  >(undefined);
  let interval;
  const showLocalLang = lang[localized].add === "Add" ? "en-EN" : "de-DE";
  interface PayloadData {
    dataKey: string;
    name: string;
    payload: object;
    value: number;
  }

  function handleEnterMouse(e) {
    if (orders?.length !== loadcurve.tranche) {
      getOrdersByZrid();
    }
  }

  // convert first letter of preis type on loadcurves
  const firstLetterCapitalPreisType = (x) => {
    if (x === "PREISKURVE") {
      const tempo = lang[locale].pricecurve.toLowerCase();
      return tempo.charAt(0).toUpperCase() + tempo.slice(1);
    }
    if (x === "FORMEL") {
      const tempo = lang[locale].formula.toLowerCase();
      return tempo.charAt(0).toUpperCase() + tempo.slice(1);
    }
    if (x === "SPOT") {
      const tempo = "SPOT".toLowerCase();
      return tempo.charAt(0).toUpperCase() + tempo.slice(1);
    }
    if (x === "FESTPREIS") {
      return lang[locale].fixedprice;
    }
  };

  //opens menu
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    getlatestOrdersByZrid();
    dispatch(authActions.checkLogin());
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles close of menu
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * opens Export Modal
   */
  const handleExportClick = () => {
    handleClose();
    dispatch(
      modalActions.openModal(
        ExportComponent,
        {
          zrid: loadcurve.zrid,
          commodity_id: loadcurve.commodity_id,
        },
        4
      )
    );
  };

  const offerSurchargeHandler = () => {
    handleClose();
    dispatch(
      modalActions.openModal(
        OfferSurchargeComponent,
        {
          zrid: loadcurve.zrid,
          commodity_id: loadcurve.commodity_id,
          fpl_charge: loadcurve.fpl_charge,
        },
        2
      )
    );
  };

  const sendBuyStatusFromChild = (buy) => {
    if (buy === 1) {
      setIsBuying(true);
    } else if (buy === 0) setIsBuying(false);
  };
  const handleBuyClick = () => {
    handleClose();
    if (
      latestOrderList &&
      orders &&
      orders?.length !== latestOrderList?.length
    ) {
      setTimeout(() => {
        dispatch(
          snackbarActions.openSnackbar(
            lang[locale].parallelBuyWarning,
            "warning"
          )
        );
        getOrdersByZrid();
      }, 2000);
    } else {
      dispatch(
        modalActions.openModal(BuyLoadcurveComponent, {
          zrid: loadcurve.zrid,
          recentPrice: recentPrice,
          amount: loadcurve.menge,
          name: loadcurve.zr_name.split("_")[0],
          sendBuyStatusFromChild: sendBuyStatusFromChild,
          tranche: loadcurve.tranche,
        })
      );
    }
  };

  function handlerSetPreisBound() {
    handleClose();
    dispatch(
      //@ts-ignore
      modalActions.openModal(SetPriceBoundMainPageComponent,{zrid: loadcurve.zrid},4));
    }

  const handleAddToPFM = () => {
    handleClose();
    if (loadcurve.tranche === 0) {
      setTimeout(() => {
        dispatch(
          snackbarActions.openSnackbar(
            lang[locale].zeroTrancheWarning,
            "warning"
          )
        );
      }, 1000);
    }
    if (loadcurve.tranche && loadcurve.tranche > 0) {
      dispatch(
        //@ts-ignore
        modalActions.openModal(
          AddToPortfolio,
          {
            commodity: loadcurve.commodity_id,
            zrid: loadcurve.zrid,
            customer: customerData,
          },
          4
        )
      );
    }
  };

  const btnDeleteDisable =
    (!role.permissions?.includes("STROM") && loadcurve.commodity_id === 1) ||
    (!role.permissions?.includes("GAS") && loadcurve.commodity_id === 2) ||
    (preisType !== "SPOT" && recentPrice === undefined);

  function toggleIntraHistory() {
    setShowHistory(!showHistory);
    setPreisHistoryChart(!PreisHistoryChart);
    setShowIntra(!showIntra);
    setIntradayChart(!intradayChart);
  }

  useEffect(() => {
    if (loggedIn) {
      forecastService
        .getForecastInformation(loadcurve.zrid)
        .then((res) => {
          //check if zrid is there
          setPreisType(res.pricing_kind);
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          // dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
  }, []);

  useEffect(() => {
    if (loggedIn) {
      if (
        role.permissions?.includes("VIEW_INTRADAY") &&
        role.permissions?.includes("VIEW_HISTORY")
      ) {
        setHistoryRight(true);
        setShowHistory(true);
        setIntraRight(true);
        setShowIntra(false);
      } else if (
        role.permissions?.includes("VIEW_INTRADAY") &&
        !role.permissions?.includes("VIEW_HISTORY")
      ) {
        setShowHistory(false);
        setHistoryRight(false);
        setIntraRight(true);
        setIntradayChart(true);
        setShowIntra(true);
      } else if (
        !role.permissions?.includes("VIEW_INTRADAY") &&
        role.permissions?.includes("VIEW_HISTORY")
      ) {
        setHistoryRight(true);
        setShowHistory(true);
        setIntraRight(false);
        setIntradayChart(false);
      }
    }
  }, []);

  useEffect(() => {
    if (loggedIn) {
      const fromDate = new Date(loadcurve.von);
      const toDate = new Date(loadcurve.bis);
      //set from date
      setFrom(formatDate(fromDate));
      setTo(formatDate(toDate));
    }
  }, [loadcurve]);

  function handleDelete() {
    handleClose();
    dispatch(
      modalActions.openModal(DeleteLoadcurveComponentPE, {
        zrid: loadcurve.zrid,
        name: loadcurve.zr_name,
        setPEfilterChange,
      })
    );
  }

  function getOrdersByZrid() {
    if (loggedIn) {
      service
        .getOrdersByZrid(loadcurve.zrid)
        .then((data: OrderModel[]) => {
          setOrders(data);
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
  }

  function getlatestOrdersByZrid() {
    if (loggedIn) {
      service
        .getOrdersByZrid(loadcurve.zrid)
        .then((data: OrderModel[]) => {
          setLatestOrderList(data);
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
  }

  function getPricingActive() {
    if (
      role.permissions.includes("MONITORING")
      // &&
      // role.simple_role !== "LIEFERANT"
    ) {
      priceService
        .getPriceBounds(loadcurve.zrid)
        .then((res) => {
          if (res.status === 1) setWatchingIndicator(true);
          if (res.status === 0) setWatchingIndicator(false);
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          setWatchingIndicator(false);
          console.error(e);
          // dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
  }

  function handleShowOrders() {
    // setTimeout(() => {
    if (orders)
      dispatch(
        modalActions.openModal(SingleBuyHistoryModalComponent, { orders }, 10)
      );
    // }, 2000);
  }

  /*Get the last price*/

  function getLatestHistory() {
    if (loggedIn) {
      service
        .getLoadCurveLatestHistory(loadcurve.zrid)
        .then((data: LoadcurvePriceHistory) => {
          if (data && data.preis === -1) {
            setRecentPrice(-1);
          } else if (data) {
            const price =
              role.simple_role === "LIEFERANT"
                ? data.preis + data.aufschlag
                : data.preis;

            // const name = new Date(data.erstellungsdatum);
            setCharge(data.aufschlag);
            setRecentPrice(price);
            setRecentDate(new Date(data.erstellungsdatum).toLocaleDateString());
            setRecentTime(new Date(data.erstellungsdatum).toLocaleTimeString());
            /*Checks if the date is the same*/
            // priceHistory &&
            //   name &&
            //   priceHistory[-1]?.name === name &&
            //   setPriceHistory(priceHistory.pop());
            // priceHistory && setPriceHistory(priceHistory.push({ name, price }));
          } else {
            setRecentPrice(-1);
          }
          //disable loading
          setLoading(false);
        })
        .catch((e: Error) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          interval = null;
          console.error("Getting history of ", loadcurve.zrid, e);
          //also disable loading
          setLoading(false);
          clearInterval(interval);
          console.error(e);
          // dispatch(snackbarActions.openSnackbar(e.message, "warning"));
        });
    }
  }
  // console.log("pricehist", priceHistory);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getHistory() {
    if (loggedIn && priceHistory === undefined && recentPrice === undefined) {
      service
        .getLoadcurvePriceHistory(loadcurve.zrid)
        .then((data: LoadcurvePriceHistory[]) => {
          if (data.length !== 0 && data[data.length - 1].preis === -1) {
            setPreisHistoryChart(false);
            setRecentPrice(-1);
          }
          //check if data is there
          else if (data.length !== 0) {
            //sort array

            const diagramArray: DiagramData[] = [];
            //get the right values
            // const charge = data[0].aufschlag;
            setCharge(data[data.length - 1].aufschlag);
            data.forEach((histElement) => {
              const price = Number(histElement.preis);
              const pushObj: DiagramData = {
                name: new Date(histElement.erstellungsdatum),
                price: Math.round(price * 100) / 100 + histElement.aufschlag,
              };
              diagramArray.push(pushObj);
            });

            // diagramArray.reverse();

            //set states
            setPriceHistory(
              diagramArray.length > 60
                ? diagramArray.slice(Math.max(diagramArray.length - 60, 1))
                : diagramArray
            );
            setReadHist(false);
            //set recent data
            setRecentPrice(diagramArray[diagramArray.length - 1].price);
            setRecentDate(
              new Date(
                data[data.length - 1].erstellungsdatum
              ).toLocaleDateString()
            );
            setRecentTime(
              new Date(
                data[data.length - 1].erstellungsdatum
              ).toLocaleTimeString()
            );
          } else {
            setRecentPrice(undefined);
            setPriceHistory(undefined);
          }
          //disable loading
          setLoading(false);
        })
        .catch((e: Error) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          interval = null;
          // console.error("Getting history of ", loadcurve.zrid, e);
          //also disable loading
          setLoading(false);
          clearInterval(interval);
          console.error(e);
          // dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
  }

  /* Get intraday*/
  function getIntradayfunc() {
    service
      .getIntraday(loadcurve.zrid)
      .then((data: LoadcurveIntraday[]) => {
        if (data) {
          // data.forEach((histElement) => {
          //   const price = Number(histElement.preis);
          //   const pushObj: DiagramData = {
          //     name: new Date(histElement.dat),
          //     price: Math.round(price * 100) / 100 + data[data.length - 1].aufschlag,
          //   };
          //   diagramArray.push(pushObj);
          // });
          let intraDayDiagram = [];
          data.forEach((intradayElement) => {
            const pushOBJ = {
              name: new Date(intradayElement.dat),
              price: intradayElement.preis,
              zrid: intradayElement.zrid,
            };
            //@ts-ignore
            intraDayDiagram.push(pushOBJ);
          });
          setIntradayPrice(intraDayDiagram);
          setIntradayChart(true);
        } else {
          // setRecentPrice(-1);
        }
        //disable loading
      })
      .catch((e: Error) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
      });
  }
  // console.log("inradayPrice", inraDayPrice);

  let trendingIcon;
  if (priceHistory && priceHistory.length > 1 && recentPrice)
    trendingIcon =
      priceHistory[priceHistory?.length - 2].price < recentPrice ? (
        <TrendingUpIcon fontSize="small" color="error" />
      ) : priceHistory[priceHistory?.length - 2].price > recentPrice ? (
        <TrendingDownIcon fontSize="small" className={classes.success} />
      ) : (
        <TrendingFlatIcon fontSize="small" color="secondary" />
      );
  //get history initially and also get the orders

  //hook used to get price history
  useEffect(() => {
    //get price history from database
    if (!priceHistory && !interval) {
      console.debug("...setting interval");
    } else if (
      (priceHistory && priceHistory.length > 1) ||
      (priceHistory && priceHistory[0] && priceHistory[0].price !== -1)
    ) {
      clearInterval(interval);
      interval = null;
    }
    //cleanup and false the is mounted state
    return () => {
      clearInterval(interval);
      interval = null;
    };
  }, [dispatch, priceHistory]);

  /* updates the pricehistory every minute*/
  useEffect(() => {
    if (loggedIn && intraRight && visible) {
      getIntradayfunc();
    }
  }, []);

  // useEffect(() => {
  //   // if (loggedIn && visible) {
  //     const interval = setInterval(() => {
  //       getLatestHistory();
  //       // getOrdersByZrid();
  //     }, 20000);
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   // }
  // }, []);

  // useEffect(() => {
  //   if (loggedIn && role.permissions?.includes("VIEW_HISTORY") && visible) {
  //     const interval = setInterval(() => {
  //       getOrdersByZrid();
  //     }, 10000);
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }
  // }, [orderListChanged]);

  useEffect(() => {
    if (loggedIn && role.permissions?.includes("VIEW_HISTORY") && visible) {
      const interval = setInterval(() => {
        if (
          priceHistory === undefined &&
          recentPrice === undefined &&
          readHist === true
        ) {
          getHistory();
        }
        // }, 30000);
      }, 10000);
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  useEffect(() => {
    if (loggedIn && role.permissions?.includes("VIEW_HISTORY")) {
      const timeOut = setTimeout(() => {
        if (
          priceHistory === undefined &&
          recentPrice === undefined &&
          readHist === true
        ) {
          getHistory();
        }
        // }, 40000);
      }, 10000);
      return () => {
        clearTimeout(timeOut);
      };
    }
  }, []);

  useEffect(() => {
    if (loggedIn && role.permissions?.includes("VIEW_INTRADAY") && visible) {
      const interval = setTimeout(() => {
        getIntradayfunc();
      }, 90000);
      return () => {
        clearTimeout(interval);
      };
    }
  }, []);

  useEffect(() => {
    if (loggedIn && visible) {
      getPricingActive();
    }
  }, [statePriceActive]);

  useEffect(() => {
    if (loggedIn) {
      if (
        !role.permissions?.includes("VIEW_DETAIL") &&
        !role.permissions?.includes("VIEW_INTRADAY") &&
        !role.permissions?.includes("VIEW_HISTORY")
      ) {
        getLatestHistory();
        getOrdersByZrid();
        getPricingActive();
      }
      if (
        role.permissions?.includes("VIEW_DETAIL") &&
        !role.permissions?.includes("VIEW_INTRADAY") &&
        !role.permissions?.includes("VIEW_HISTORY")
      ) {
        getLatestHistory();
        getOrdersByZrid();
        getPricingActive();
      }
    }
  }, []);

  function CustomToolTipPreisHistorie({
    active,
    payload,
    // label,
    // setLocalPriceCurveData,
  }) {
    const locale = localStorage.locale;
    let realPayload: PayloadData | undefined = undefined;
    let dateKey = "";
    if (payload[0]) {
      realPayload = payload[0];
      let keys = Object.keys(payload[0].payload);
      //filter for the right date key
      dateKey = keys.filter((key) => key !== realPayload?.dataKey)[0];
    }

    return (
      <>
        {realPayload && realPayload.value > 0 && (
          <Paper style={{ padding: "20px 10px" }}>
            <Typography>
              {lang[locale].date}:{" "}
              {payload[0].payload.erstellungsdatum
                ? new Date(payload[0]?.payload.erstellungsdatum).toLocaleString(
                    showLocalLang,
                    tooltipOptions
                    // {
                    //   day: "2-digit",
                    //   month: "2-digit",
                    //   year: "numeric",
                    //   hour: "2-digit",
                    //   minute: "2-digit",
                    //   second: "2-digit",
                    // }
                  )
                : new Date(realPayload.payload[dateKey]).toLocaleString(
                    showLocalLang,
                    tooltipOptions
                  )}
            </Typography>
            <Typography>
              {lang[locale].price} : {` `}
              <NumberFormat
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale={true}
                decimalScale={2}
                suffix=" €/MWh"
                value={realPayload.value}
                displayType={"text"}
              />
            </Typography>
            {realPayload.payload["kombinierterPreis"] && (
              <Typography>
                {lang[locale].totalPrice} : {` `}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  decimalScale={2}
                  suffix=" €/MWh"
                  value={realPayload.payload["kombinierterPreis"][1]}
                  displayType={"text"}
                />
              </Typography>
            )}
          </Paper>
        )}
      </>
    );
  }
  function CustomToolTipIntraDayPreis({
    active,
    payload,
    // label,
    // setLocalPriceCurveData,
  }) {
    const locale = localStorage.locale;
    let realPayload: PayloadData | undefined = undefined;
    let dateKey = "";
    if (payload[0]) {
      realPayload = payload[0];
      let keys = Object.keys(payload[0].payload);
      //filter for the right date key
      dateKey = keys.filter((key) => key !== realPayload?.dataKey)[0];
    }

    return (
      <>
        {realPayload && realPayload.value > 0 && (
          <Paper style={{ padding: "20px 10px" }}>
            <Typography>
              {lang[locale].time}:{" "}
              {payload[0].payload.name
                ? new Date(payload[0]?.payload.name).toLocaleString(
                    showLocalLang,
                    {
                      // day: "2-digit",
                      // month: "2-digit",
                      // year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      // second: "2-digit",
                    }
                  )
                : new Date(realPayload.payload[dateKey]).toLocaleString(
                    showLocalLang,
                    tooltipOptions
                  )}
            </Typography>
            <Typography>
              {realPayload.name} : {` `}
              <NumberFormat
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale={true}
                decimalScale={2}
                suffix=" €/MWh"
                value={realPayload.value}
                displayType={"text"}
              />
            </Typography>
            {/* {realPayload.payload["kombinierterPreis"] && (
              <Typography>
                {lang[locale].totalPrice} : {` `}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  decimalScale={2}
                  suffix=" €/MWh"
                  value={realPayload.payload["kombinierterPreis"][1]}
                  displayType={"text"}
                />
              </Typography>
            )} */}
          </Paper>
        )}
      </>
    );
  }

  return (
    <VizSensor
      onChange={(isVisible) => {
        if (loggedIn && isVisible && !orders) {
          setVisible(true);
          if (
            !role.permissions?.includes("VIEW_HISTORY") &&
            role.permissions?.includes("VIEW_INTRADAY")
          ) {
            setPreisHistoryChart(false);
            setIntradayChart(true);
            getLatestHistory();
            getOrdersByZrid();
            getPricingActive();
            getIntradayfunc();
          }
          if (
            role.permissions?.includes("VIEW_HISTORY") &&
            role.permissions?.includes("VIEW_INTRADAY")
          ) {
            setPreisHistoryChart(true);
            getHistory();
            getOrdersByZrid();
            getPricingActive();
            getIntradayfunc();
          } else if (
            role.permissions?.includes("VIEW_HISTORY") &&
            !role.permissions?.includes("VIEW_INTRADAY")
          ) {
            setIntradayChart(false);
            getHistory();
            getOrdersByZrid();
            getPricingActive();
            // getIntradayfunc();
          }
        }
      }}
    >
      {loading ? (
        <Skeleton variant="rect" max-width={300} height={250}></Skeleton>
      ) : (
        <Card style={{ maxWidth: "95%" }}>
          <>
            <div
              style={{
                width: "100%",
                backgroundColor:
                  loadcurve.commodity_id === 1
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main,
                height: 23,
              }}
            >
              {/* <span
                style={{
                  color: "#3d3b3b",
                  fontSize: "0.8em",
                  padding: "0.5rem 0.5rem 0.5rem",
                }}
              >
                {firstLetterCapitalPreisType(preisType)}
              </span> */}
              <span style={{
                  color:  loadcurve.commodity_id === 1 ? "white" : "#3d3b3b",
                  fontSize: "1.2em",
                  padding: "0.7rem 0.5rem",
                  margin : "0.2rem"
                }}>{`${loadCurveCopy.zr_name.split("_")[0]}`}</span>
            </div>
            {isBuying ? <LineProgress /> : null}
            <CardContent>
              <Grow in={true}>
                <Grid
                  container
                  spacing={2}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={12}>
                    <Grid container alignItems="center" justify="center">
                      <Grid item xs={6}>
                        <Grid container alignItems="center" spacing={1}>
                          {watchingIndicator && (
                            <Grid item>
                              <MTooltip
                                title={lang[locale].priceWatching}
                                aria-label="watched"
                              >
                                <PriorityHighIcon color="secondary" />
                              </MTooltip>
                            </Grid>
                          )}
                          {/* <Grid item>
                              <>
                                {customerData["type"] !== "F" ? (
                                  <MTooltip
                                    title={
                                      orders && orders.length > 0 &&
                                      orders.length === loadcurve.tranche
                                        ? `Sie haben alle ${loadcurve.tranche} Tranchen Gekauft, es ist kein weitere Kauf möglich`
                                        : ""
                                    }
                                  >
                                    <Chip
                                      onMouseEnter={(e)=>handleEnterMouse(e)}
                                      // label={orders && `${orders.length} ${lang[locale].from} ${loadcurve.tranche} x ${lang[locale].boughtPlan}`}
                                      label={orders && `${orders.length} / ${loadcurve.tranche} ${lang[locale].boughtPlan}`}
                                      variant={
                                        orders && orders.length === loadcurve.tranche
                                          ? "default"
                                          : "outlined"
                                      }
                                      onClick={handleShowOrders}
                                      className={classes.boughtChip}
                                    />
                                  </MTooltip>
                                ) : loadcurve.tranche && loadcurve.tranche > 0 ? (
                                    <Chip
                                    onMouseEnter={(e)=>handleEnterMouse(e)}
                                      label={
                                        loadcurve.tranche && orders &&
                                        loadcurve.tranche > 0 &&
                                        // `${orders.length} ${lang[locale].from} ${loadcurve.tranche} x ${lang[locale].boughtPlan}`
                                        `${orders.length} / ${loadcurve.tranche} ${lang[locale].boughtPlan}`
                                          
                                      }
                                      variant={orders && orders.length === loadcurve.tranche
                                        ? "default"
                                        : "outlined"}
                                      onClick={handleShowOrders}
                                      className={classes.boughtChip}
                                    />
                                  
                                ) : loadcurve.tranche === 0 && orders && orders?.length > 0 ? (<Chip
                                  onMouseEnter={(e)=>handleEnterMouse(e)}
                                  // label={`${orders.length} x ${lang[locale].boughtPlan}`}
                                  label={`${orders.length} ${lang[locale].boughtPlan}`}
                                  variant={orders && orders.length === loadcurve.tranche
                                    ? "default"
                                    : "outlined"}
                                  onClick={handleShowOrders}
                                  className={classes.boughtChip}
                                />) : null}
                              </>
                            
                          </Grid> */}
                        </Grid>
                      </Grid>

                      <Grid item xs={6}>
                        <Grid container justify="flex-end" alignItems="center">
                          <Grid item>
                            <Typography variant={"caption"}>
                              {from} - {to}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <IconButton
                              aria-label="settings"
                              onClick={handleClick}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="simple-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleClose}
                            >
                              <MenuItem onClick={handleExportClick}>
                                {lang[locale].export}
                              </MenuItem>
                              {/* {role.permissions?.includes("PFM") ? (
                                <MenuItem onClick={handleAddToPFM}>
                                  {lang[locale].addToPfm}
                                </MenuItem>
                              ) : null} */}
                              {/* {
                                role.simple_role === "LIEFERANT" &&
                                (
                                  <MenuItem onClick={offerSurchargeHandler}>
                                  {lang[locale].offerSurcharge}
                                  </MenuItem>
                                )
                              } */}
                              {loadcurve &&
                                // activate price Watching for all users
                                // role.simple_role !== "LIEFERANT" &&
                                role.permissions.includes("MONITORING") && (
                                  <MenuItem onClick={handlerSetPreisBound}>
                                    {lang[locale].priceMonitoring}
                                  </MenuItem>
                                )}
                              {/* {loadcurve &&
                                role.simple_role !== "LIEFERANT" &&
                                role.permissions?.includes("TRADING") && preisType !== "SPOT" &&(
                                  <MenuItem
                                    onClick={handleBuyClick}
                                    disabled={
                                     (orders && orders.length > 0 && orders?.length === loadcurve.tranche) || recentPrice === undefined}
                                  >
                                    {lang[locale].buy}
                                  </MenuItem>
                                )} */}
                              {role.simple_role === "LIEFERANT" && (
                                <MenuItem
                                  onClick={handleDelete}
                                  disabled={btnDeleteDisable}
                                >
                                  <Typography>{lang[locale].delete}</Typography>
                                </MenuItem>
                              )}
                            </Menu>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={4} md={5}>
                    {role.simple_role === "LIEFERANT" && (
                      <Typography color="textSecondary" variant="body2" noWrap>
                        ID: {loadcurve.zrid}
                      </Typography>
                    )}
                    {/* {loadCurveCopy.zr_name.split("_")[0].length > 14 ? (
                      <MTooltip
                        title={
                          loadCurveCopy.zr_name.split("_")[0].length > 14
                            ? loadcurve.zr_name.split("_")[0]
                            : ""
                        }
                        aria-label="Name"
                      >
                        <Typography
                          color="textSecondary"
                          variant="body2"
                          noWrap
                        >
                          {loadCurveCopy.zr_name.split("_")[0].length > 14
                            ? `${loadCurveCopy.zr_name
                                .split("_")[0]
                                .slice(0, 11)}...`
                            : loadCurveCopy.zr_name.split("_")[0]}
                        </Typography>
                      </MTooltip>
                    ) : (
                      <Typography color="textSecondary" variant="body2" noWrap>
                        <h1>{`${loadCurveCopy.zr_name.split("_")[0]}`}</h1>
                      </Typography>
                    )} */}

                    <Typography variant="h5" noWrap>
                      {recentPrice && recentPrice > 0 ? (
                        <>
                          {" "}
                          <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale={true}
                            decimalScale={2}
                            value={recentPrice}
                            displayType={"text"}
                          />{" "}
                          {trendingIcon}{" "}
                          <span
                            style={{
                              fontSize: 14,
                            }}
                          >
                            €/MWh
                          </span>
                        </>
                      ) : recentPrice === undefined ? (
                        lang[locale].inWork
                      ) : (
                        lang[locale].inWork
                      )}
                    </Typography>

                    <Typography color={"inherit"} variant="caption">
                      <>
                        {recentDate &&
                        recentPrice !== 0 &&
                        recentPrice !== undefined
                          ? lang[locale].priceFrom +
                            ": " +
                            recentDate +
                            " " +
                            recentTime
                          : lang[locale].noPriceGiven}
                      </>
                    </Typography>
                  </Grid>

                  {showHistory ? (
                    <>
                      {priceHistory && priceHistory.length > 1 ? (
                        <>
                          <Grid item xs={12} md={7}>
                            <ResponsiveContainer height={90}>
                              <LineChart
                                data={priceHistory}
                                data-testid="areachart"
                              >
                                <YAxis
                                  domain={["auto", "auto"]}
                                  stroke={theme.palette.text.secondary}
                                  interval="preserveStartEnd"
                                />
                                <XAxis
                                  dataKey="name"
                                  stroke={theme.palette.text.secondary}
                                  tickFormatter={
                                    (tick) =>
                                      new Date(tick).toLocaleString(
                                        showLocalLang,
                                        LegendOptions
                                      )
                                    // .split(".")
                                    // .slice(1)
                                    // .join("")
                                  }
                                />
                                <Tooltip
                                  wrapperStyle={{ outline: "none" }}
                                  position={{ x: -50, y: 40 }}
                                  content={
                                    <CustomToolTipPreisHistorie
                                      payload={priceHistory}
                                      active="true"
                                    />
                                  }
                                />
                                {/* <Tooltip contentStyle={{color: "white",backgroundColor:"#303030",width:"50%"}} itemStyle={{ color: "#ebad1c" }}/> */}

                                <Line
                                  name="Preis"
                                  dataKey="price"
                                  stroke={
                                    theme.palette.type === "dark"
                                      ? theme.palette.secondary.main
                                      : theme.palette.primary.main
                                  }
                                  dot={false}
                                  isAnimationActive={false}
                                />
                              </LineChart>
                            </ResponsiveContainer>
                          </Grid>
                        </>
                      ) : null}
                    </>
                  ) : null}
                  {showIntra ? (
                    <>
                      {inraDayPrice ? (
                        <Grid item xs={12} md={7}>
                          <ResponsiveContainer height={90}>
                            <LineChart
                              data={inraDayPrice}
                              data-testid="areachart"
                            >
                              <YAxis
                                domain={["auto", "auto"]}
                                stroke={theme.palette.text.secondary}
                                interval="preserveStartEnd"
                              />
                              <XAxis
                                dataKey="name"
                                stroke={theme.palette.text.secondary}
                                tickFormatter={(tick) =>
                                  tick
                                    .toLocaleString(showLocalLang, intraOptions)
                                    .split(":")
                                    .slice(0, 1)
                                    .join("")
                                }
                              />
                              <Tooltip
                                position={{ x: -50, y: 40 }}
                                wrapperStyle={{ outline: "none" }}
                                content={
                                  <CustomToolTipIntraDayPreis
                                    payload={inraDayPrice}
                                    active="true"
                                  />
                                }
                              />
                              {/* <Tooltip contentStyle={{color: "white",backgroundColor:"#303030",width:"50%"}} itemStyle={{ color: "#ebad1c" }}/> */}

                              <Line
                                name="preis"
                                dataKey="price"
                                stroke={
                                  theme.palette.type === "dark"
                                    ? theme.palette.secondary.main
                                    : theme.palette.primary.main
                                }
                                dot={false}
                              />
                            </LineChart>
                          </ResponsiveContainer>
                        </Grid>
                      ) : (
                        <p style={{ maxWidth: "40%" }}>
                          {lang[locale].noIntraday}
                        </p>
                      )}
                    </>
                  ) : null}
                  {preisType === "SPOT" &&
                    (recentPrice === undefined || recentPrice === 0 || recentPrice === -1) && (
                      <p style={{ maxWidth: "50%", color: "#FAB515", height:78 }}>
                        {lang[locale].noSpotPrice} {from}!
                      </p>
                    )}
                </Grid>
              </Grow>
            </CardContent>
            <Grow in={true}>
              <CardActions>
                {/* <Grid container justify="flex-start" alignItems="center">
                  <Grid item style={{ maxWidth: "90%" }}>
                    {recentPrice && recentPrice > 0 ? (
                      <Typography color="textSecondary" variant="body2">
                        {lang[locale].cost}:{" "}
                        <NumberFormat
                          thousandSeparator="."
                          decimalSeparator=","
                          fixedDecimalScale={true}
                          decimalScale={2}
                          suffix=" €"
                          value={(loadcurve.menge * recentPrice) / 1000}
                          displayType={"text"}
                        />
                      </Typography>
                    ) : (recentPrice === undefined || recentPrice === 0) ? (
                      <Typography color="textSecondary" variant="body2">
                    {lang[locale].cost}:{" n/a"}
                     </Typography>
                    ) : (
                      lang[locale].inWork 
                    )}
                    <Typography color="textSecondary" variant="body2">
                      {lang[locale].amount}:{" "}
                      <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        value={loadcurve.menge / 1000}
                        displayType={"text"}
                        decimalScale={3}
                        fixedDecimalScale={true}
                        suffix=" MWh"
                      />{" "}
                    </Typography>

                    {role.simple_role === "LIEFERANT" && (
                      <Typography color="textSecondary" variant="body2">
                        {lang[locale].charge}:{" "}
                        <NumberFormat
                          thousandSeparator="."
                          decimalSeparator=","
                          fixedDecimalScale={true}
                          decimalScale={2}
                          suffix=" €/MWh"
                          value={charge}
                          displayType={"text"}
                        />
                      </Typography>
                    )}
                  </Grid>
                </Grid> */}
                {historyRight && intraRight ? (
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item>
                      {showIntra && !showHistory && preisType !== "SPOT" ? (
                        <Button
                          // color={"primary"}
                          style={{color : ciSettings.primary}}
                          size="medium"
                          // component={Link}
                          onClick={toggleIntraHistory}
                          // to={
                            //   "/app/myplans/detailed/" + id + "/" + loadcurve.zrid
                            // }
                            >
                          {lang[locale].history}
                        </Button>
                      ) : null}
                      {showHistory && !showIntra && preisType !== "SPOT" ? (
                        <Button
                        // color={"primary"}
                        style={{color : ciSettings.primary}}
                        size="medium"
                        // component={Link}
                        onClick={toggleIntraHistory}
                        // to={
                          //   "/app/myplans/detailed/" + id + "/" + loadcurve.zrid
                          // }
                          >
                          {lang[locale].intraday}
                        </Button>
                      ) : null}
                      {role.permissions?.includes("VIEW_DETAIL") ? (
                        <Button
                        disabled={
                          (priceHistory === undefined ||
                          recentPrice === undefined) && preisType !== "SPOT"
                        }
                        // color={"primary"}
                        style={{color : ciSettings.primary}}
                        size="medium"
                        component={Link}
                        to={
                          "/app/myplans/detailed/" + id + "/" + loadcurve.zrid
                        }
                        >
                          {lang[locale].details}
                        </Button>
                      ) : null}
                    </Grid>
                  </Grid>
                ) : !historyRight && intraRight ? (
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item>
                      {role.permissions?.includes("VIEW_DETAIL") ? (
                        <Button
                        disabled={
                           (priceHistory === undefined ||
                            recentPrice === undefined) && preisType !== "SPOT"
                        }
                        // color={"primary"}
                        style={{color : ciSettings.primary}}
                        size="medium"
                        component={Link}
                        to={
                          "/app/myplans/detailed/" + id + "/" + loadcurve.zrid
                        }
                        >
                          {lang[locale].details}
                        </Button>
                      ) : null}
                    </Grid>
                  </Grid>
                ) : historyRight && !intraRight ? (
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item>
                      {role.permissions?.includes("VIEW_DETAIL") ? (
                        <Button
                        style={{color : ciSettings.primary}}
                        // color={"primary"}
                        size="medium"
                        component={Link}
                        to={
                          "/app/myplans/detailed/" + id + "/" + loadcurve.zrid
                        }
                        >
                          {lang[locale].details}
                        </Button>
                      ) : null}
                    </Grid>
                  </Grid>
                ) : !historyRight && !intraRight ? (
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item>
                      {role.permissions?.includes("VIEW_DETAIL") ? (
                        <Button
                        // color={"primary"}
                        style={{color : ciSettings.primary}}
                          size="medium"
                          component={Link}
                          to={
                            "/app/myplans/detailed/" + id + "/" + loadcurve.zrid
                          }
                        >
                          {lang[locale].details}
                        </Button>
                      ) : null}
                    </Grid>
                  </Grid>
                ) : null}
              </CardActions>
            </Grow>
          </>
        </Card>
      )}
    </VizSensor>
  );
}

export default LoadcurveCardComponentPE;
