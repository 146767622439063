import React, { useState } from "react";
import {
    Button,
    CircularProgress,
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";
import { lang } from "../lang";
import { useDispatch, useSelector } from "react-redux";
import snackbarActions from "../actions/snackbar.actions";
import userActions from "../actions/user.actions";
// import authActions from "actions/auth.actions";
import AuthService from "services/auth/AuthService";

const service = new AuthService();

function SetNewNameProfileComponent(props) {
    const UserInfo = useSelector((state) => state["user"]);
    const [loading, setLoading] = useState(false);
    const [newFirstName, setNewFirstName] = useState("");
    const [newLastName, setNewLastName] = useState("");
    const dispatch = useDispatch();
    const locale = localStorage.locale;

    function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        //reset password on api
        service
            .resetPersonalInformation({
                firstname: newFirstName,
                lastname: newLastName,
            })
            .then(() => {
                setLoading(false);
                dispatch(userActions.update(newFirstName, newLastName));
                setNewFirstName("");
                setNewLastName("");

                dispatch(
                    snackbarActions.openSnackbar(
                        lang[locale].newNameSuccess,
                        "success"
                    )
                );
            })
            .catch((e) => {
                setLoading(false);
                console.error(e);
                dispatch(snackbarActions.openSnackbar(e.message, "error"));
            });
    }

    function checkDisabled() {
        return newFirstName === "" || newLastName === "" || newLastName.length > 50 || newFirstName.length > 50;
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container direction="column" spacing={2}>
                    <Typography
                        variant="subtitle2"
                        color="inherit"
                        gutterBottom
                    >
                        {lang[locale].setNewName}
                    </Typography>
                    <Grid item xs={4}>
                        <TextField
                            placeholder={UserInfo.firstName}
                            value={newFirstName}
                            id="firstName"
                            label={lang[locale].firstName}
                            className="maximalWidth"
                            type="text"
                            variant="outlined"
                            onChange={(e) => {
                                setNewFirstName(e.target.value);
                            }}
                            required
                            error={newFirstName.length > 50}
                            autoComplete="given-name"
                            helperText={newFirstName.length > 50 && lang[locale].morethanAllowed}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            placeholder={UserInfo.lastName}
                            value={newLastName}
                            id="lastName"
                            label={lang[locale].lastName}
                            className="maximalWidth"
                            type="text"
                            variant="outlined"
                            onChange={(e) => {
                                setNewLastName(e.target.value);
                            }}
                            required
                            error={newLastName.length > 50}
                            autoComplete="family-name"
                            helperText={newLastName.length > 50 && lang[locale].morethanAllowed}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="contained"
                            // color="primary"
                            style={{backgroundColor : checkDisabled() || loading ? "#595959" : props.coloR.primary, color : checkDisabled() || loading ? "#898989" : "white"}}
                            type="submit"
                            data-testid="submitReset"
                            disabled={checkDisabled() || loading}
                        >
                            {loading ? (
                                <CircularProgress size={30} />
                            ) : (
                                lang[locale].send
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

export default SetNewNameProfileComponent;
