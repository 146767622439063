import { useSelector } from "../services/helpers/useSelector";
import { useDispatch } from "react-redux";
import forecastActions from "../actions/forecast.actions";
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    ListItemText,
} from "@material-ui/core";
import { lang } from "../lang";
import React, { useEffect, useState } from "react";
import CommalessThousandSeperatorComponent from "./CommalessThousandSeperator";

/**
 * Is for selecting either base or peak. For gas only base
 * @constructor
 */
export function SelectStandardTradingProduct({sendErrorFromChild,divideAmount}) {
    const data = useSelector((state) => state.forecast.data);
    const [selectName, setSelectName] = useState("");
    const tranche = useSelector((state) => state.forecast.data?.tranche);
    const [TempoPerformance, setTempoPerformance] = useState(0);

    const locale = localStorage.locale;

    const dispatch = useDispatch();

    function handleSetLeist(e) {
        dispatch(
            forecastActions.saveForecastSingle(e.target.name, TempoPerformance)
        );
    }
    function handleChange(e) {
        if(e.target.value === "BASE"){
            setSelectName("BASE");
            dispatch(
                forecastActions.saveForecastSingle("productType", 1)
            );
        }
        else if(e.target.value === "PEAK"){
            setSelectName("PEAK");
            dispatch(
                forecastActions.saveForecastSingle("productType", 0)
            );
        }
    }
    useEffect(() => {
        if (data.gas) {
            dispatch(forecastActions.saveForecastSingle("productType", 1));
        }
    }, [data.gas]);

    const { min_usage_mwh_power_shp, max_usage_mwh_power_shp, min_usage_mwh_gas_shp, max_usage_mwh_gas_shp} = useSelector(
        (state) => state.constants
    );

    function checkPerformance(val: Number): string[] {
        let check: string[] = [];
        if(tranche && tranche > 1 && divideAmount === true){
            if (val < (data.gas? min_usage_mwh_gas_shp * tranche : min_usage_mwh_power_shp * tranche) * 1000) {
                check.push(
                    lang[locale].performanceTooSmall +
                        (data.gas? min_usage_mwh_gas_shp * tranche : min_usage_mwh_power_shp * tranche) * 1000 +
                        " kW" +
                        lang[locale].usageSecond
                );
            }
            if (val > (data.gas? max_usage_mwh_gas_shp : max_usage_mwh_power_shp) * 1000) {
                check.push(
                    lang[locale].performanceTooBig +
                        (data.gas? max_usage_mwh_gas_shp : max_usage_mwh_power_shp) * 1000 +
                        " kW" +
                        lang[locale].usageSecond
                );
            }
        }else{

            if (val < (data.gas? min_usage_mwh_gas_shp : min_usage_mwh_power_shp) * 1000) {
                check.push(
                    lang[locale].performanceTooSmall +
                    (data.gas? min_usage_mwh_gas_shp : min_usage_mwh_power_shp) * 1000 +
                    " kW" +
                    lang[locale].usageSecond
                    );
                }
                if (val > (data.gas? max_usage_mwh_gas_shp : max_usage_mwh_power_shp) * 1000) {
                    check.push(
                        lang[locale].performanceTooBig +
                        (data.gas? max_usage_mwh_gas_shp : max_usage_mwh_power_shp) * 1000 +
                        " kW" +
                        lang[locale].usageSecond
                        );
                    }
                }
                sendErrorFromChild(check);
        return check;
    }

    return (
        <>
            <Grid item xs={12}>
                <Grid container spacing={4}>
                    <Grid item>
                        {data.gas ? (
                            <>
                                <Typography variant="caption">
                                    {lang[locale].productType}
                                </Typography>
                                <Typography>Base</Typography>
                            </>
                        ) : (
                            <FormControl style={{ minWidth: 150 }} required>
                                <InputLabel id="stdtrading">
                                    {lang[locale].productType}
                                </InputLabel>
                                <Select
                                    value={data.productType === 1 ? "BASE" : data.productType === 0 && "PEAK"}
                                    onChange={handleChange}
                                    labelId="stdtrading"
                                    name="productType"
                                    id="stdtradingselect"
                                    error={data.productType === 2}
                                >
                                    {/* <MenuItem value={2}>
                                        {lang[locale].notSelected}
                                    </MenuItem> */}

                                    <MenuItem data-testid="base" value="BASE">
                                        Base
                                    </MenuItem>
                                    <MenuItem data-testid="peak" value="PEAK">
                                        Peak
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            
                            value={TempoPerformance ? TempoPerformance : ""}
                            id="stdtradingvalue"
                            name="performance"
                            onFocus={(e) => e.target.select()}
                            inputProps={{
                                "data-testid": "performanceTextfield",
                            }}
                            InputProps={{
                                inputComponent: CommalessThousandSeperatorComponent,
                            }}
                            label={lang[locale].performance + " in kW"}
                            onChange={(e)=>{ if(e.target.value !== undefined){
                                setTempoPerformance(Number(e.target.value))
                            } else setTempoPerformance(0)
                        }}
                            onBlur={handleSetLeist}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required
                            error={
                                TempoPerformance === 0 ||
                                TempoPerformance === undefined
                            }
                        />
                        {checkPerformance(TempoPerformance).map((entry) => (
                            <ListItemText key={entry}>
                                <Typography
                                    variant="caption"
                                    color="error"
                                    data-testid="date-error"
                                >
                                    {entry}
                                </Typography>
                            </ListItemText>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
