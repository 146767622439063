import { userConstants } from "constants/user.constants";
import { AnyAction } from "redux";

export interface userReducerInitialStateType {
    firstName: string;
    lastName: string;
}

const initialState: userReducerInitialStateType = {
    firstName: "",
    lastName: "",
};

/**
 * This is the reducer which is responsible for the user data
 * @param state The reducers initial state
 * @param action the action which gets passed to the reducer
 */

export function user(
    state: userReducerInitialStateType = initialState,
    action: AnyAction
) {
    switch (action.type) {
        case userConstants.GET_USER:
            return { ...state };
        case userConstants.UPDATE_USER:
            return {
                ...state,
                lastName: action.lastName,
                firstName: action.firstName,
            };
        default:
            return state;
    }
}
