import React, { useCallback, useEffect } from "react";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Tooltip,
} from "@material-ui/core";
import { lang } from "../lang";
import { useSelector } from "../services/helpers/useSelector";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import forecastActions from "../actions/forecast.actions";

const useStyles = makeStyles(() => ({
    inputMargin: {
        marginTop: 16,
    },
}));

/**
 * Is for checking if hours are missing or if double hours are there in october
 * @param scrollTo handles scrollto event
 * @param marchRow the row where time change happens in march
 * @param octoberRow same for october
 * @param lastRow lastRow for scrolling on mouseUp
 * @param resetScroll resets scroll to standard
 * @constructor
 */
function TimedifferenceChecksComponent({
    scrollTo,
    marchRow,
    octoberRow,
    resetScroll,
    firstValueRow,
}) {
    const locale = localStorage.locale;
    const classes = useStyles();
    const checked = useSelector(
        (state) => state.forecast.fleximportData.checked
    );
    const neededCount = useSelector(
        (state) => state.forecast.fleximportData.neededCount
    );

    const gas = useSelector((state) => state.forecast.data.gas);

    const dispatch = useDispatch();

    const gasDayShow = (MarchDay) => {
        const dd = parseInt(MarchDay.split(".")[0]); 
        const mm = MarchDay.split(".")[1]; 
        const yy = MarchDay.split(".")[2]; 
        const Date = (`${dd + 1}.${mm}.${yy}`);
        return Date;
    }

    const mouseUpEventHandler = useCallback(() => {
        resetScroll();
    }, [scrollTo]);
    //the useeffect which adds event listener
    useEffect(() => {
        if (neededCount)
            document.addEventListener("mouseup", mouseUpEventHandler);
        return () => {
            document.removeEventListener("mouseup", mouseUpEventHandler);
        };
    }, [mouseUpEventHandler, neededCount]);

    //handles check
    function handleCheck(e) {
        const newData = {
            ...checked,
            [e.target.name]: e.target.checked,
        };
        dispatch(forecastActions.saveFleximportSingle("checked", newData));
    }

    return (
        <Grid container alignItems="center">
            <Grid item xs={12} md={6}>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControl className={classes.inputMargin}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked.missingHours}
                                        onChange={handleCheck}
                                        data-testid="missingHoursCheckbox"
                                        name="missingHours"
                                        color="primary"
                                    />
                                }
                                label={lang[locale].nullvalues}
                            />
                        </FormControl>
                    </Grid>
                    {neededCount && marchRow ? (
                        <Grid item xs={12}>
                            <Grid item>
                                {gas ? (
                                    <Tooltip title={gasDayShow(marchRow.date)}>
                                    <Button
                                        color="primary"
                                        onMouseDown={() => {
                                            scrollTo(
                                                "first",
                                                marchRow.row + firstValueRow
                                            );
                                        }}
                                    >
                                        {lang[locale].showTimeChangeDay}
                                    </Button>
                                </Tooltip>
                                ) : (
                               <Tooltip title={marchRow.date}>
                                    <Button
                                        color="primary"
                                        onMouseDown={() => {
                                            scrollTo(
                                                "first",
                                                marchRow.row + firstValueRow
                                            );
                                        }}
                                    >
                                        {lang[locale].showTimeChangeDay}
                                    </Button>
                                </Tooltip>)}
                            </Grid>
                        </Grid>
                    ) : (
                        ""
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControl className={classes.inputMargin}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked.doubleHours}
                                        onChange={handleCheck}
                                        data-testid="doubleHoursCheckbox"
                                        name="doubleHours"
                                        color="primary"
                                    />
                                }
                                label={lang[locale].doubleHour}
                            />
                        </FormControl>
                    </Grid>
                    {neededCount && octoberRow ? (
                        <Grid item xs={12}>
                            {gas ? (
                                <Tooltip title={gasDayShow(octoberRow.date)}>
                                <Button
                                    color="primary"
                                    onMouseDown={() => {
                                        scrollTo(
                                            "second",
                                            octoberRow.row + firstValueRow
                                        );
                                    }}
                                >
                                    {lang[locale].showTimeChangeDay}
                                </Button>
                            </Tooltip>) : 
                            ( <Tooltip title={octoberRow.date}>
                                <Button
                                    color="primary"
                                    onMouseDown={() => {
                                        scrollTo(
                                            "second",
                                            octoberRow.row + firstValueRow
                                        );
                                    }}
                                >
                                    {lang[locale].showTimeChangeDay}
                                </Button>
                            </Tooltip>)}
                        </Grid>
                    ) : (
                        ""
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default TimedifferenceChecksComponent;
