import { ciSettingsConstants } from "constants/ciSettings.constants";
import { AnyAction } from "redux";

export interface ciSettingsReducerInitialStateType {
    primary: string;
    secondary: string;
    gradient: any;
    logo: any;
}

const initialState: ciSettingsReducerInitialStateType = {
    primary: "",
    secondary: "",
    gradient: [],
    logo:""
};

/**
 * This is the reducer which is responsible for the user data
 * @param state The reducers initial state
 * @param action the action which gets passed to the reducer
 */

export function ciSettings(
    state: ciSettingsReducerInitialStateType = initialState,
    action: AnyAction
) {
    switch (action.type) {
        case ciSettingsConstants.GET_CI:
            return { ...state };
        case ciSettingsConstants.UPDATE_CI:
            return {
                ...state,
                secondary: action.secondary,
                primary: action.primary,
                gradient: action.gradient,
                logo: action.logo,
            };
        default:
            return state;
    }
}
