import React from "react";
import { Button } from "@material-ui/core";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';


export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              background: "#303030",
              height: "100vh",
              color: "white",
            }}
          >
            <div style={{ marginLeft: "3rem" }}>
              <h1 style={{ fontSize: "6em" }}>Oops...</h1>
              <p style={{ fontSize: "3em", padding: 0 }}>
              Leider können wir die von Ihnen gesuchte Seite nicht finden.
              </p>
            <Button onClick={()=>window.location.reload()}>zurück Home<span>&nbsp;&nbsp;</span><HomeRoundedIcon fontSize="large" /></Button>
            </div>
          </div>

      );
    }

    return this.props.children;
  }
}
