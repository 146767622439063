import React, { useEffect, useRef, useState } from "react";
import { lang } from "../lang";
import NumberFormat from "react-number-format";
import Timer from "./Timer";
import _ from "lodash";

//Material UI
import {
  Grid,
  TextField,
  Typography,
  Button,
  CircularProgress,
  FormControl,
  Input,
  Checkbox,
  MenuItem,
  InputLabel,
  ListItemText,
  Select,
  Tooltip as MTooltip,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

//Hooks
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSelector } from "../services/helpers/useSelector";

//Icons
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircle";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

//Services
import UserService from "../services/users/UserService";
import LoadcurveService from "../services/loadcurves/LoadcurveService";
import PortfolioService from "./../services/portfolios/PortfolioService";

//Actions
import modalActions from "../actions/modal.actions";
import snackbarActions from "../actions/snackbar.actions";
import authActions from "actions/auth.actions";

function PercentageInputComponent(props) {
  const { inputRef, onChange, ...other } = props;
  const { max_order_scale } = useSelector((state) => state.constants);

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      suffix="%"
      onFocus={(e) => {
        e.target.select();
      }}
      decimalScale={0}
      allowNegative={false}
      isAllowed={(values) => {
        return (
          parseFloat(values.value) <= max_order_scale || values.value === ""
        );
      }}
    />
  );
}

const service = new LoadcurveService();
const userService = new UserService();

/**
 * Component for handling the buy thingy
 * @param props zrid and so on
 * @constructor
 */
function BuyLoadcurveComponent(props) {
  const customerId = useSelector(
    (state) => state.customer["customerData"]["id"]
  );
  const [percentage, setPercentage] = useState(100);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const PFMservive = new PortfolioService();
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clicks, setClicks] = useState(1);
  const [finalBuy, setFinalBuy] = useState(false);
  const [tan, setTan] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [overwrite, setOverwrite] = useState(false);

  const inputRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const locale = localStorage.locale;
  const [messages, setMessages] = useState<string[]>([]);
  const [confirmationEmails, setConfirmationEmails] = useState([]);
  const [selection, setSelection] = useState<
    [{ email: string; status: string }]
  >([{ email: "", status: "" }]);
  const internalValidate = useSelector((state) => state.auth.internalValidate);
  const [CaseNumber, setCaseNumber] = useState("");
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [extraKauf, setExtraKauf] = useState(false);
  const [actuellSum, setActuellSum] = useState<any>([]);
  const [MaxerlaubtKaufArray, setMaxerlaubtKaufArray] = useState<any>([]);
  const [confirmed, setConfirmed] = useState<Boolean>(false);
  const [status, setStatus] = useState<number>(1);
  const [pin, setPin] = useState("");
  const [type, setType] = useState<string>("");
  const [btnBuyActive, setBtnBuyActive] = useState(true);
  const [countDown, setCountDown] = useState(10);
  let history = useHistory();

  function handleChange(e) {
    setPercentage(e.target.value);
  }

  const getSinglePortfolio = () => {
    //@ts-ignore
    PFMservive.getInPortfolio(Number(customerId), props.zrid)
      .then((res) => {
        if(res.length === 1){
          //@ts-ignore
          setPortfolios(res[0]);
        }
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        // dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  };

  const isNumeric = (number) =>
    _.isFinite(_.parseInt(number)) &&
    !_.isNaN(_.parseInt(number)) &&
    number.length > 5;

  const diissfsdf =
    (100 - props.UpperStart) /
    (Array.from({ length: 101 }, (_, index) => {
      while (index * (100 / props.DaysDifference) <= 100)
        return index * (100 / props.DaysDifference);
    })
      .map((i) => {
        if (i !== undefined && i <= props.UpperEnd) return i;
      })
      .filter((i) => i !== undefined).length -
      1);

  useEffect(()=>{
    const MaxerlaubtKaufArrayTempo = Array.from({ length: 101 }, (_, index) => {
      while (index * (100 / props.DaysDifference) <= 100)
        return index * diissfsdf + props.UpperStart;
    })
      .map((i) => {
        if (i !== undefined && i <= 100) return i;
      })
      .filter((i) => i !== undefined)
      .slice(0, props.greenArrayStep.length);
      setMaxerlaubtKaufArray(MaxerlaubtKaufArrayTempo);
  },[])


  // console.log("maxKaudProzent", MaxerlaubtKaufArray[MaxerlaubtKaufArray.length - 1])

  function checkDisabled() {
    return selectedEmails.length === 0 && status === 2;
  }

  function handleClose() {
    if (CaseNumber.length > 0) {
      service.deleteCaseNumber(CaseNumber);
    }
    props.sendBuyStatusFromChild(0);
    dispatch(modalActions.closeModal());
  }

  function handleCloseAfterBuy() {
    dispatch(modalActions.closeModal());
  }

  // const calculateDaysDifference = (startDate, endDate) => {
  //   const start = new Date(startDate);
  //   const end = new Date(endDate);

  //   // Calculate the difference in milliseconds
  //   //@ts-ignore
  //   const differenceInMilliseconds = end - start;

  //   // Convert milliseconds to days
  //   const differenceInDays = Math.floor(
  //     differenceInMilliseconds / (1000 * 60 * 60 * 24)
  //   );

  //   // Update the state with the calculated difference
  //   setDaysDifference(differenceInDays);
  // };

  const sumValuesWithPrevious = (array) => {
    let sumAmount = 0;
    let sumKosten = 0;
    let sumPrice = 0;
    let beschaffung = 0;

    return array
      .filter((i) => i.zrid === i.zrid_ref)
      .map((item, index) => {
        sumAmount += item.amount / 1000;
        sumKosten += item.price * (item.amount / 1000);
        sumPrice = item.price;
        beschaffung += 100 * ((item.amount / 1000) / props.TotalAmount);

        return {
          ...item,
          amount: sumAmount,
          price: sumPrice,
          kosten: sumKosten,
          beschaffung: beschaffung,
          dprice: sumKosten / sumAmount,
        };
      });
  };

  useEffect(() => {
    // getAllPortfolio();
    if(props.PortfolioId){
      getSinglePortfolio();
      setActuellSum(sumValuesWithPrevious(props.PortfolioDetails));
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
    if(props.PortfolioId){
      //@ts-ignore
      if (MaxerlaubtKaufArray.length > 0 && actuellSum.length > 0 && (100 * ((actuellSum[actuellSum.length - 1].amount + props.amount) / props.TotalAmount)) > MaxerlaubtKaufArray[MaxerlaubtKaufArray.length - 1]
      ) {
        setExtraKauf(true);
        // console.log("(100 * ((actuellSum[actuellSum.length - 1].amount + props.amount) / props.TotalAmount))",((actuellSum[actuellSum.length - 1].amount ))
      }
      else if ((MaxerlaubtKaufArray.length === 0 || actuellSum.length === 0) && (100 * (( props.amount) / props.TotalAmount) > props.UpperStart)) { 
          setExtraKauf(true);
        }
        else setExtraKauf(false);
      }   
    }, 2000);
    return ()=>clearInterval(interval)
  }, [props.PortfolioDetails, actuellSum, MaxerlaubtKaufArray]);

  // console.log("tranche",props.tranche,"total",props.TotalAmount,"extraKa", extraKauf,"actuelsum",actuellSum.length, MaxerlaubtKaufArray.length,"MaxerlaubtKaufArray", MaxerlaubtKaufArray, 100 * (( props.amount) / props.TotalAmount) > props.UpperStart)
  function handleFinalBuy() {
    setBtnBuyActive(false);
    setTimeout(() => {
      setBtnBuyActive(true)
      setCountDown(10);
    }, 10000);
    
    props.sendBuyStatusFromChild(1);
    //@ts-ignore
    service.buyLoadcurve(props.zrid, tan, CaseNumber, pin)
      .then((res) => {
        setLoading(false);

        dispatch(
          snackbarActions.openSnackbar(lang[locale].buySuccess, "success")
        );
        props.sendBuyStatusFromChild(0);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        setRedirect(true);
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        setLoading(false);
        console.error(e);
        if(e.message !== 'Die eingegebene PIN ist nicht korrekt, bitte versuchen Sie es erneut.'){
          handleClose();
          props.sendBuyStatusFromChild(0);
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
    // handleCloseAfterBuy();
  }

  const TranchenDiff =
    props.tranche - props.ordersLength > props.max_Order_Tranche
      ? props.max_Order_Tranche
      : props.tranche - props.ordersLength;
  // Generate an array of numbers from 1 to count
  const numbers = Array.from({ length: TranchenDiff }, (_, index) => index + 1);

  function handleSubmit() {
    dispatch(authActions.checkLogin());
    //@ts-ignore
    setClicks((prevValue) => prevValue + 1);
    // console.log("Clickeeed", clicks);

    //call service
    if (clicks >= 1) {
      setLoading(true);
      if (percentage)
        service
          .startBuying(
            props.recentPrice as number,
            props.zrid,
            selectedEmails,
            percentage,
            quantity,
            overwrite
          )
          .then((res) => {
            setCaseNumber(res["message"]);
            setLoading(false);

            setFinalBuy(true);
          })
          .catch((e) => {
            if (e.message === "401") {
              return history.push(`/logout`);
            }
            setLoading(false);
            dispatch(snackbarActions.openSnackbar(e.message, "warning"));
          });
    }
  }

  /*checks if the buying process if 4AP is active*/
  useEffect(() => {
    userService
      .getCustomer()
      .then((res) => {
        // console.log("res status", res);
        setStatus(res.status);
        setType(res.type);
        res.type === "E" && setPercentage(100);
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    userService
      .getCustomersEmails()
      .then((res) => {
        setConfirmationEmails(res);
        /*If there is just one email address it is automatically set as the selectedConfirmationEmail*/
        res.length === 1 && setSelectedEmails(res);
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        // dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      formControl: {
        margin: theme.spacing(1),
        minWidth: 350,
        maxWidth: 400,
      },
    })
  );
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const classes = useStyles();

  const handleSelect = (event) => {
    // console.log("handelSelected", event.target.value);
    setSelectedEmails(event.target.value as string[]);
  };

  useEffect(() => {

    if (countDown > 0 && btnBuyActive === false) {
      const timer = setInterval(() => {
        setCountDown(countDown - 1);
      }, 1000);
      return () => clearInterval(timer); // Cleanup the timer on unmount
    }
  }, [btnBuyActive,countDown]);

  useEffect(() => {
    const interval = setInterval(() => {
      userService
        .getCustomer()
        .then((res) => {
          getStatus(res.status);
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }, 3000);

    return () => clearInterval(interval);
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  /*checks if the selected emails confirmed the order*/
  function getStatus(s) {
    userService
      .getStatus(props.zrid)
      .then((res) => {
        setSelection(res);

        let confirmed = true;
        /*if 4AP is inactive confirmed is always true*/
        if (res === 1) {
          setConfirmed(true);
        } else {
          res.map((o) => o.status === "N" && (confirmed = false));
          setConfirmed(confirmed);
        }
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  }

  useEffect(() => {
    // Set a timeout to disable the button after 2000 milliseconds (2 seconds)
    const timeoutId = setTimeout(() => {
      setButtonDisabled(false);
    }, 3000);

    // Clear the timeout to prevent disabling the button if the component unmounts before 2 seconds
    return () => clearTimeout(timeoutId);
  }, []); 

  /* Checks how much percentage can be bought */
  useEffect(() => {
    const msg: string[] = [];
    const calCulatedCosts =
      props.recentPrice * ((props.amount * percentage) / 100);
    userService
      .getCustomer()
      .then((res) => {
        /* Check if variable exists */
        const alreadyBoughtMonth = res.c_limit_month ? res.c_limit_month : 0;
        const alreadyBoughtYear = res.c_limit_year ? res.c_limit_year : 0;
        /*Calculate how much can still be bought*/
        const buyableMonthlyCosts = res.limit_month - alreadyBoughtMonth;
        const buyableYearlyCosts = res.limit_year - alreadyBoughtYear;
        const costs = [
          buyableMonthlyCosts,
          buyableYearlyCosts,
          res.limit_order,
        ];

        const sortedCosts = costs.sort((a, b) => a - b);
        const smallest = sortedCosts[0];

        const maxPerc = Math.floor(
          (smallest * 100) / props.recentPrice / props.amount
        );

        if (smallest <= 0) {
          msg.push(lang[locale].purchaseLimitExhausted);
        } else if (maxPerc < 1) {
          msg.push(lang[locale].purchaseCannotBeBought);
        } else if (calCulatedCosts > smallest) {
          msg.push(
            lang[locale].purchaseLimitExceededOne +
              ` ${maxPerc}` +
              lang[locale].purchaseLimitExceededTwo
          );
        }
        setMessages(msg);
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  }, [percentage]); // eslint-disable-line react-hooks/exhaustive-deps

  //check if neds to be redirected
  if (redirect) return <Redirect to={"/app/myplans"} />;
  return (
    <>
      <Grid container item xs={12} style={{ marginBottom: "1rem" }}>
        <Typography variant="h5" gutterBottom>
          {lang[locale].buyPrognosis}
        </Typography>
      </Grid>

      <Grid container spacing={3} data-testid="BuyModal">
        {/* <Grid container item xs={12}>
          <Typography variant="body1" style={{ marginRight: "6px" }}>
            {lang[locale].buyToFollowingPrice}
          </Typography>

          <Typography variant="body1" color="secondary">
            <NumberFormat
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              data-testid={"price"}
              fixedDecimalScale={true}
              value={props.recentPrice}
              displayType={"text"}
              color="primary"
            />
             &nbsp; €/MWh
          </Typography>
        </Grid> */}

        <>
          {type && type !== "E" && props.tranche === undefined && (
            <Grid container xs={12} item>
              <Grid item xs={12}>
                <TextField
                  error={!percentage || messages.length > 0}
                  inputRef={inputRef}
                  helperText={!finalBuy ? lang[locale].buyHelperText : null}
                  inputProps={{ "data-testid": "percentage" }}
                  InputProps={{
                    inputComponent: PercentageInputComponent,
                  }}
                  value={percentage}
                  onChange={handleChange}
                  label={lang[locale].percentageForecast}
                  disabled={finalBuy}
                />
              </Grid>
              <Grid item xs={12}>
                {messages.length > 0 &&
                  messages.map((entry: string) => (
                    <ListItemText key={entry}>
                      <Typography
                        variant="caption"
                        color="error"
                        data-testid="date-error"
                      >
                        {entry}
                      </Typography>
                    </ListItemText>
                  ))}
              </Grid>
            </Grid>
          )}
        </>

        {clicks >= 1 && (
          <>
            <Grid item xs={6}>
              <Typography>{lang[locale].offresid}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{props.zrid}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Name:</Typography>
            </Grid>
            <Grid item xs={6}>
              {props.name.length > 14 ? (
                <MTooltip title={props.name}>
                  <Typography>{`${props.name.slice(0, 13)}...`}</Typography>
                </MTooltip>
              ) : (
                <Typography>{props.name}</Typography>
              )}
            </Grid>
            {type && type !== "E" && (
              <>
                <Grid item xs={6}>
                  <Typography>{lang[locale].percentageForecast}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <NumberFormat
                    value={percentage}
                    displayType={"text"}
                  ></NumberFormat>
                  %
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <Typography>{lang[locale].amount}:</Typography>
            </Grid>
            <Grid item xs={6}>
              <NumberFormat
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={3}
                data-testid={"price"}
                fixedDecimalScale={true}
                value={((props.amount * percentage) / 100) * quantity}
                displayType={"text"}
              ></NumberFormat>{" "}
              MWh
            </Grid>
            <Grid item xs={6}>
              <Typography>{lang[locale].specificPrice}:</Typography>
            </Grid>
            <Grid item xs={6}>
              <NumberFormat
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                data-testid={"price"}
                fixedDecimalScale={true}
                value={props.recentPrice}
                displayType={"text"}
              />{" "}
              &nbsp; €/MWh
            </Grid>
            <Grid item xs={6}>
              <Typography>{lang[locale].cost}:</Typography>
            </Grid>
            <Grid item xs={6}>
              <NumberFormat
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                data-testid={"price"}
                fixedDecimalScale={true}
                value={
                  props.recentPrice *
                  ((props.amount * percentage) / 100) *
                  quantity
                }
                displayType={"text"}
              />{" "}
              €
            </Grid>
            <Grid item xs={6}>
              <Typography>{lang[locale].noOfTranches}:</Typography>
            </Grid>
            {!finalBuy && props.max_Order_Tranche > 1 ? (
              <Grid item xs={6}>
                {/* <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  // decimalScale={2}
                  data-testid={"price"}
                  fixedDecimalScale={true}
                  value={quantity}
                  displayType={"text"}
                />{" "} */}
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={Number(quantity)}
                  onChange={(e) => setQuantity(Number(e.target.value))}
                >
                  {numbers.map((number) => (
                    <MenuItem key={number} value={number}>
                      {number}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem> */}
                </Select>
              </Grid>
            ) : (
              <Grid item xs={6}>
                {quantity}
              </Grid>
            )}

            {checkDisabled() ? (
              <>
                <Grid item xs={10} style={{ color: "red", fontSize: "1.2em" }}>
                  {lang[locale].foureyewarning}
                </Grid>
                <Grid item xs={10}>
                  {lang[locale].foureyewarningMessage}
                </Grid>
              </>
            ) : null}
            <Grid item xs={12}>
              {confirmationEmails.length === 1 && status === 2 && (
                <>
                  <p>{lang[locale].confirmation + ":"}</p>
                  <p>{confirmationEmails[0]}</p>
                </>
              )}
              {confirmationEmails.length > 1 && status === 2 && (
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-mutiple-chip-label">
                    {lang[locale].confirmation}
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={selectedEmails}
                    onChange={handleSelect}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) =>
                      (selected as string[]).join(", ")
                    }
                    MenuProps={MenuProps}
                    disabled={finalBuy}
                  >
                    {confirmationEmails.map((email) => (
                      <MenuItem key={email} value={email}>
                        <Checkbox
                          checked={selectedEmails.indexOf(email) > -1}
                        />
                        <ListItemText primary={email} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </>
        )}
        {finalBuy ? (
          <>
            <Grid item xs={8}>
              <br></br>
              <Typography>{lang[locale].pleaseEnterTan}</Typography>
              <br></br>
              <Typography>
                {lang[locale].remainingTime}:{" "}
                <Timer timerType="priceHoldingTime" />
              </Typography>
              {CaseNumber && (
                <Typography style={{ marginTop: "1rem" }}>
                  {lang[locale].casenumber}: {CaseNumber}
                </Typography>
              )}
            </Grid>
            {/*<Grid item xs={12}>
              <TextField
                error={tan === ""}
                inputProps={{ "data-testid": "percentage" }}
                label="TAN"
                required
                variant="filled"
                onChange={(e) => {
                  setTan(e.target.value.trim());
                }}
              />
            </Grid>*/}
            <Grid
            container
            direction="column"
            spacing={4}
            data-testid="loginform"
            style={{margin: "0em 0em 2em em", padding:"2em 2em"}}
          >
            {/* <Grid item xs={12}>
              <Typography variant="h5">{lang[locale].twoFA}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {lang[locale].inputPin}
              </Typography>
            </Grid> */}
            <Grid
              // style={{
              //   display: "flex",
              //   flexDirection: "row",
              //   justifyContent: "space-evenly",
              //   alignItems: "center",
              //   marginLeft : "1em"
              // }}
            >
              <Grid item xs={2}>
                <TextField
                  id="pin"
                  label="2FA Pin"
                  variant="filled"
                  type="pin"
                  className="maximalWidth"
                  onChange={(e) => {
                    setPin(e.target.value);
                  }}
                  required
                  autoFocus
                  autoComplete="off"
                  disabled={internalValidate}
                  error={pin === ""}
                />
              </Grid>
            </Grid>
          </Grid>
          </>
        ) : (
          ""
        )}

        <Grid item xs={6} style={{ position: "relative" }}>
          <Grid container>
            <Grid item style={{ position: "absolute", bottom: 0 }}>
              <Button color="primary" onClick={handleClose}>
                {lang[locale].close}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}> 
            {
              extraKauf && !finalBuy &&
              <>
              <h3 style={{color : "red"}}>{lang[locale].foureyewarning}</h3>
              <p>{lang[locale].hedgechannelVerlassMeldung[0]}
              {actuellSum.length > 0 && (
              <NumberFormat
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                data-testid={"price"}
                fixedDecimalScale={true}
                value= {((props.TotalAmount * (MaxerlaubtKaufArray[MaxerlaubtKaufArray.length - 1] - (actuellSum[actuellSum.length - 1].beschaffung))) / 100)}
                displayType={"text"}
              /> 
              )}{""}
              {
                actuellSum.length === 0 && (
                  <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  data-testid={"price"}
                  fixedDecimalScale={true}
                  value= {((props.TotalAmount * props.UpperStart) / 100)}
                  displayType={"text"}
                />)
              }{" "}
              {lang[locale].hedgechannelVerlassMeldung[1]} {props.greenArrayStep.length === 0 ? props.UpperStart : MaxerlaubtKaufArray[MaxerlaubtKaufArray.length - 1]}% {lang[locale].hedgechannelVerlassMeldung[2]} {lang[locale].hedgechannelVerlassMeldung[3]}
              {
                actuellSum.length > 0 && (<NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  data-testid={"price"}
                  fixedDecimalScale={true}
                  value= {100 * ((actuellSum[actuellSum.length - 1].amount + props.amount) / props.TotalAmount)}
                  displayType={"text"}
                />)
              }
              {
                actuellSum.length === 0 && (<NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  data-testid={"price"}
                  fixedDecimalScale={true}
                  value= { 100 * (( props.amount) / props.TotalAmount)}
                  displayType={"text"}
                />
                 )
              } %
              </p>
              </>
            }
          <Grid container justify="flex-end" style={{display : "flex", flexDirection : "row"}}>
            
            <Grid item>
              {!finalBuy ? (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    data-testid="buyButton"
                    disabled={
                      !percentage ||
                      messages.length > 0 ||
                      loading ||
                      props.recentPrice <= 0 ||
                      (clicks > 0 &&
                        type !== "E" &&
                        selectedEmails.length === 0 &&
                        status === 2) ||
                      (clicks > 0 && checkDisabled()) || isButtonDisabled
                    }
                    onClick={handleSubmit}
                  >
                    {loading ? (
                      <CircularProgress size={30} />
                    ) : (
                      <>{
                        isButtonDisabled ? <CircularProgress size={30} /> : <>{lang[locale].next}</>
                      }
                       
                      </>
                    )}
                  </Button>
                </>
              ) : (
                <>
                  {status !== 1 && <h4>{lang[locale].confirmationStatus}:</h4>}
                  {selectedEmails.length !== 0 && (
                    <>
                      <p>
                        {selection.map((c) => (
                          <p key={c.email}>
                            {c.email}
                            {"    "}
                            {c.status === "Y" ? (
                              <CheckCircleOutlineIcon color="secondary" />
                            ) : status !== 1 ? (
                              <QueryBuilderIcon />
                            ) : null}
                          </p>
                        ))}
                      </p>
                    </>
                  )}
                  <Button
                    color="primary"
                    variant="contained"
                    data-testid="buyButton"
                    disabled={
                      !percentage ||
                      loading ||
                      // checkDisabled() ||
                      props.recentPrice <= 0 ||
                      confirmed === false || pin === "" || pin?.length < 6 || !btnBuyActive
                    }
                    onClick={handleFinalBuy}
                  >
                    {loading ? (
                      <CircularProgress size={30} />
                    ) : (
                      <>
                        <>{lang[locale].finalBuy} {!btnBuyActive && <span style={{color : "orange", fontSize : "1.5rem"}}> &nbsp; {countDown}</span> }</>
                      </>
                    )}
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default BuyLoadcurveComponent;
