import {lang} from "../lang";
import {useDropzone} from "react-dropzone";
import {Grid} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    active: {
        borderColor: theme.palette.primary.main
    },
    accept: {
        borderColor: theme.palette.success.main
    },
    reject: {
        borderColor: theme.palette.error.main
    },
    baseStyle: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: theme.palette.background.default,
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out"
    },
}))

/**
 * Function which shows the file Upload and handles it
 * @param handleFile
 * @constructor
 */
function UploadFormularComponent({onDropAccepted, onDropRejected, dropText= lang[localStorage.locale].drop}) {
    const langPack = lang[localStorage.locale ? localStorage.locale : "de"];
    //styling of component
    const classes = useStyles();
    //rejected drop

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
        isDragAccept
    } = useDropzone({maxSize: 5000000, onDropAccepted, onDropRejected});


    return <Grid container spacing={4} >

        <Grid item xs={12}>
            <div data-testid="dropzone" {...getRootProps()}
                 className={`${classes.baseStyle} ${isDragActive ? classes.active : ""} ${isDragAccept ? classes.accept : ""} ${isDragReject ? classes.reject : ""}`}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>{langPack.dropHere}</p> :
                        <p>{dropText}</p>
                }
            </div>
        </Grid>
    </Grid>;
}

export default UploadFormularComponent;
