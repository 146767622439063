import React, { useEffect, useState, useRef } from "react";
import { Grid, Button, Box, Tooltip,  Select, MenuItem } from "@material-ui/core";
import { lang } from "lang";

//Icons
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Badge from "@material-ui/core/Badge";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";

//Services
import ForecastService from "../services/forecast/ForecastService";
import UserService from "../services/users/UserService";
import LoadcurveService from "../services/loadcurves/LoadcurveService";

//Actions
import snackbarActions from "actions/snackbar.actions";
import forecastActions from "../actions/forecast.actions";
import loadcurveActions from "../actions/loadcurve.actions";
import customerActions from "actions/customer.actions";
import constantsActions from "actions/constants.actions";

//Hooks
import { useSelector } from "services/helpers/useSelector";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

//Components
import SupplierPlansPendingComponent from "./SupplierPlansPending";
import SupplierPlansFinishedComponent from "./SupplierPlansFinished";

import OrderModel from "typeDefs/OrderModel";
const forecastService = new ForecastService();
const userService = new UserService();
const loadcurveService = new LoadcurveService();

function SupplierPlansComponent({ filters }) {
  let history = useHistory();
  const locale = localStorage.locale;
  const dispatch = useDispatch();
  // @ts-ignore
  const customer = useSelector((state) => state.customer.customerData);
  const customer_id = customer && customer.id;
  const names = useSelector((state) => state.forecast.finishedNames);
  const loadcurves = useSelector((state) => state.loadcurve.loadcurves);
  const loadCurveLength = { ...loadcurves };
  const loadcurvesRemaining = useSelector(
    (state) => state.loadcurve.loadcurvesRemaining
  );
  const loggedIn = useSelector((state) => state.auth.loggedIn);

  const iniproError = useSelector((state) => state.constants.inipro_error);
  const ModalStatus = useSelector((state) => state.modal.open);
  // console.log("IniproErrorsupplier", iniproError);

  /* defines if the prognosis or the remaining pending prognosis are being shown */
  const [pending, setPending] = useState<boolean>(false);
  const [pendingbadge, setPendingbadge] = useState<boolean>(false);
  const [curvelistChanged, setCurvelistChanged] = useState(false);
  const [orderListChanged, setOrderListChanged] = useState(false);
  const [orderList, setOrderList] = useState<OrderModel[] | undefined>(
    undefined
  );
  const [DataKind,setDataKind] = useState<any>(1);
  const [tempoNames,setTempoNames] = useState([]);

  const CurveListLength = Object.values(loadCurveLength).length;
  const OrderListLength = orderList && orderList.length;

  const useCurvePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const useOrderPrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevCurveList = useCurvePrevious(CurveListLength);
  const prevOrderList = useOrderPrevious(OrderListLength);

  useEffect(() => {
    if (prevCurveList && prevCurveList < CurveListLength) {
      setCurvelistChanged(true);
    } else setCurvelistChanged(false);
  }, [CurveListLength]);

  const handleClick = () => {
    setPending(!pending);
    setPendingbadge(!pendingbadge);
  };

  const updateConstants = () => {
    loadcurveService
      .getConstants()
      .then((res) => {
        dispatch(constantsActions.update(res));
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  };

  useEffect(()=>{
    setTempoNames(names);
  },[names])

  // Status: Ausstehende Prognose = 2    ***  Fehler bei Prognoseerstellung = 1  ***   Prognose erstellt = 0
  useEffect(() => {
    if (loggedIn) {

      if (customer_id > 0 && loggedIn) {
        if (names.length > 0) {
          const start = Date.now();
          const interval = setInterval(() => {
            //Max time to requesting new prognosis is 15 minutes (900000 milliseconds)
            if (Date.now() - start > 900000) {
              console.debug("...clearing interval beginning");
              // console.log("...clearing interval beginning");
              return () => clearInterval(interval);
            }
            names.forEach((name) => {
              if(names.indexOf(name.toString()) !== -1){
              forecastService
                .getForecastInformation(Number(name))
                .then((res) => {         
                  if (res.status === 2 || res === 2) {
                    dispatch(
                      loadcurveActions.getLoadcurvesRemaining(customer_id)
                    );
                    dispatch(forecastActions.removeName(res.zrid));
                    //@ts-ignore
                    const newTempo = tempoNames.filter(item => item.toString() !== name.toString())
                    setTempoNames(newTempo)
                    console.debug("...status is 2");
                    return () => clearInterval(interval);
                  }
                  if (res.status === 0) {
                    console.debug("Removing name from finished names", res.name);
                    dispatch(
                      loadcurveActions.getLoadcurvesRemaining(customer_id)
                    );
                    dispatch(loadcurveActions.getLoadcurves(customer_id,DataKind));
                    dispatch(forecastActions.removeName(res.zrid));
                    /* Updating the current count coutner */
                    userService.getCustomers().then((customers) => {
                      dispatch(customerActions.updateAll(customers));
                    });
                    return () => clearInterval(interval);
                  } else if (res.status === 1) {
                    // console.log("Prognose Erstellung ist schief gelaufen");
                    updateConstants();
                    dispatch(
                      loadcurveActions.getLoadcurvesRemaining(customer_id)
                    );
                    dispatch(
                      snackbarActions.openSnackbar(
                        iniproError[0][locale],
                        "error"
                      )
                    );
                    return () => clearInterval(interval);
                  }
                })
                .catch((e) => {
                  if (e.message === "401") {
                    return history.push(`/logout`);
                  }
                  console.debug(
                    "Removing name from finished names, because of error",
                    e
                  );
  
                  dispatch(loadcurveActions.getLoadcurvesRemaining(customer_id));
                  dispatch(forecastActions.removeName(name));
                  // console.log("In SupplierPlans");
                  // dispatch(snackbarActions.openSnackbar(e.message, "error"));
                  return () => clearInterval(interval);
                });
              }
            });
            //requesting every 10 seconds
          }, 15000);
          return () => {
            clearInterval(interval);
          };
        }
      }
    }
  }, [names]);

  function getOrders() {
    if (loggedIn) {
      loadcurveService
        .getOrders()
        .then((data: OrderModel[]) => {
          setOrderList(data);
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
  }

  useEffect(() => {
    if (loggedIn) {
      getOrders();
    }
  }, []);

  useEffect(() => {
    if (customer_id > 0 && loggedIn) {
      dispatch(loadcurveActions.getLoadcurvesRemaining(customer_id));
      dispatch(loadcurveActions.getLoadcurves(customer_id, DataKind));
    }
  }, [customer_id,DataKind]);

  useEffect(() => {
    if (customer_id > 0 && loggedIn && ModalStatus === false) {
      const interval = setInterval(() => {
        dispatch(loadcurveActions.getLoadcurves(customer_id, DataKind));
        dispatch(loadcurveActions.getLoadcurvesRemaining(customer_id));
      }, 10000);
      return () => {
        // clearing interval end
        clearInterval(interval);
      };
    }
  }, [loadcurves]);

  useEffect(() => {
    if (
      loggedIn &&
      prevCurveList !== undefined &&
      CurveListLength !== undefined &&
      prevCurveList < CurveListLength
    ) {
      setCurvelistChanged(true);
    }
    if (
      loggedIn &&
      prevCurveList !== undefined &&
      CurveListLength !== undefined &&
      prevCurveList === CurveListLength
    ) {
      setCurvelistChanged(false);
    }
  }, [loadCurveLength]);

  useEffect(() => {
    if (
      loggedIn &&
      prevOrderList !== undefined &&
      OrderListLength !== undefined &&
      prevOrderList < OrderListLength
    ) {
      setOrderListChanged(true);
    }
    if (
      loggedIn &&
      prevOrderList !== undefined &&
      OrderListLength !== undefined &&
      prevOrderList === OrderListLength
    ) {
      setOrderListChanged(false);
    }
  }, [orderList]);

  useEffect(() => {
    const refreshPage = () => {
      window.location.reload();
    };
    document.addEventListener("onLoad", refreshPage);
    return () => {
      document.removeEventListener("onLoad", refreshPage);
    };
  }, []);

  //  Filters the loadcurves according to the set filter (Id or name)
  const filtered = loadcurves
    ? loadcurves.filter(
        (curve) =>
          curve.zr_name
            .split("_")[0]
            .toLowerCase()
            .includes(filters.toLowerCase()) ||
          curve.zrid.toString().includes(filters)
      )
    : null;

  return (
    <>
      {customer ? (
        <>
          <Box flexDirection="row" display="flex" justifyContent="flex-end">
            <div
              style={{
                width: "100%",
                margin: "0 auto",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <p style={{ margin: "0" }}>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    listStyle: "none",
                  }}
                >
                  <li
                    style={{
                      zIndex: 1,
                      color: customer.permissions?.includes("STROM")
                        ? "LawnGreen"
                        : "grey",
                    }}
                  >
                    <Tooltip title={lang[locale].powerPermission}>
                      <FlashOnIcon />
                    </Tooltip>
                  </li>
                  <li
                    style={{
                      zIndex: 1,
                      paddingLeft: "15px",
                      color: customer.permissions?.includes("GAS")
                        ? "LawnGreen"
                        : "grey",
                    }}
                  >
                    <Tooltip title={lang[locale].gasPermission}>
                      <WhatshotIcon />
                    </Tooltip>
                  </li>
                  <li
                    style={{
                      zIndex: 1,
                      paddingLeft: "15px",
                      color: customer.permissions?.includes("TRADING")
                        ? "LawnGreen"
                        : "grey",
                    }}
                  >
                    <Tooltip title={lang[locale].tradingPermission}>
                      <ShoppingBasketIcon />
                    </Tooltip>
                  </li>
                  <li
                    style={{
                      zIndex: 1,
                      paddingLeft: "15px",
                      color: customer.permissions?.includes("MONITORING")
                        ? "LawnGreen"
                        : "grey",
                    }}
                  >
                    <Tooltip title={lang[locale].priceBoundsPermission}>
                      <PriorityHighIcon />
                    </Tooltip>
                  </li>
                </ul>
              </p>
              <Select value={DataKind} style={{margin : "0.7rem 0 0 3rem"}} autoWidth placeholder="Sortieren" title="Sortieren" onChange={(e)=>setDataKind(e.target.value)}>
                <MenuItem value={1}>{lang[locale].Activeoffers}</MenuItem>
                <MenuItem value={4}>{lang[locale].Inactiveoffers}</MenuItem>
                <MenuItem value={5}>{lang[locale].Deletedoffers}</MenuItem>
                <MenuItem value={6}>{lang[locale].OffersInPreparation}</MenuItem>
              </Select>
            </div>
            <Box pb={2}>
              {!pendingbadge &&
              loadcurvesRemaining &&
              loadcurvesRemaining.length > 0 ? (
                <Badge
                  badgeContent={
                    loadcurvesRemaining.length
                      ? loadcurvesRemaining.length
                      : null
                  }
                  invisible={pendingbadge}
                  color="error"
                >
                  <Button
                    variant="text"
                    size="small"
                    startIcon={pending && <ArrowBackIosIcon />}
                    endIcon={!pending && <ArrowForwardIosIcon />}
                    onClick={handleClick}
                    style={{textTransform: "none"}}
                  >
                    {pending
                      ? lang[locale].finishedCurves
                      : lang[locale].pendingCurves}
                  </Button>
                </Badge>
              ) : (
                <Badge
                  badgeContent={loadcurves ? loadcurves.length : null}
                  invisible={!pendingbadge}
                  color="primary"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Button
                    variant="text"
                    size="small"
                    startIcon={pending && <ArrowBackIosIcon />}
                    endIcon={!pending && <ArrowForwardIosIcon />}
                    onClick={handleClick}
                    style={{textTransform: "none"}}
                  >
                    {pending
                      ? lang[locale].finishedCurves
                      : lang[locale].pendingCurves}
                  </Button>
                </Badge>
              )}
            </Box>
          </Box>
        </>
      ) : null}

      <Grid container spacing={10}>
        {customer ? (
          pending ? (
            <SupplierPlansPendingComponent
              loadcurvesRemaining={loadcurvesRemaining}
            />
          ) : (
            <SupplierPlansFinishedComponent
              filtered={filtered}
              DataKind={DataKind}
              orderListChanged={orderListChanged}
              curvelistChanged={curvelistChanged}
            />
          )
        ) : null}
      </Grid>
    </>
  );
}

export default SupplierPlansComponent;
