/**
 * Service for creating and getting price bounds
 */
import { config } from "../helpers/Constants";
import errorCodeTranslator from "../helpers/errorCodeTranslator";

class PriceService {
    /**
     * Toggles if price bounds are active on a certain loadcurve
     * @param zrid zrid of the loadcurve
     * @param active is it active?
     */
    public async toggleActive(zrid: number, active: boolean): Promise<void> {
        const res = await fetch(
            config.url.API_URL +
                "/api/personal/data/setactive/" +
                zrid +
                "/" +
                (active ? 1 : 0),
            {
                headers: {
                    Authorization: "Bearer " + localStorage.token,
                },
            }
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }
    /**
     * Gets price bounds for a loadcurve from the api
     * @param zrid Zrid of the loadcurve
     */
    public async getPriceBounds(zrid: number): Promise<any> {
        const res = await fetch(
            config.url.API_URL + "/api/personal/data/getpricebounds/" + zrid,
            {
                headers: {
                    Authorization: "Bearer " + localStorage.token,
                },
            }
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if(res.status === 204){
            return {};
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Sets price bounds on server
     * @param zrid zrid of the loadcurve to modify
     * @param upper the top border
     * @param lower the bottom border
     */
    public async setPriceBounds(
        zrid: number,
        upper: number,
        lower: number
    ): Promise<void> {
        const res = await fetch(
            config.url.API_URL + "/api/personal/data/setpricebounds/" + zrid,
            {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.token,
                },
                body: JSON.stringify({
                    lower,
                    upper,
                }),
            }
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    public async getPriceDevelopment() : Promise<any> {
        const res = await fetch(
            config.url.API_URL + "/api/supplier/base"
            // ,
            // {
            //     headers: {
            //         Authorization: "Bearer " + localStorage.token,
            //     },
            // }
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if(res.status === 204){
            return {};
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }
}
export default PriceService;
