import React, { useEffect, useState } from "react";
import { lang } from "../lang";
import NumberFormat from "react-number-format";

// Material UI
import {
  // Card,
  // Chip,
  // Grid,
  Typography,
  Tab,
  Tabs,
  Box,
  Paper,
  Tooltip,
} from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

// Hooks
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// Actions
import authActions from "../actions/auth.actions";
import snackbarActions from "../actions/snackbar.actions";

//Components
import SetNewPasswordProfileComponent from "components/SetNewPasswordProfile";
import SetNewNameProfileComponent from "components/SetNewNameProfile";
import GlobalChargeComponent from "components/GlobalCharge";
import Set2FAComponent from "components/Set2FA";
import BuyTimeLimitComponent from "components/BuyTimeLimit";
import ColorSettingComponent from "./ColorSetting";

//Services
import UserService from "../services/users/UserService";
import AuthService from "../services/auth/AuthService";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        // @ts-ignore
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface ProfileComponentProps {
  changeNavTitle(title: string): void;
}
const service = new UserService();

function ProfileComponent(props: ProfileComponentProps) {
  let history = useHistory();
  const locale = localStorage.locale;
  const role = AuthService.getRole();
  const [customerType, setCustomerType] = useState("");
  const [orderLimit, setOrderLimit] = useState(0);
  const [monthlyOrderLimit, setMonthlyOrderLimit] = useState(0);
  const [usedmonthlyOrderLimit, setUsedMonthlyOrderLimit] = useState(0);
  const [yearlyOrderLimit, setYearlyOrderLimit] = useState(0);
  const [usedYearlyOrderLimit, setUsedYearlyOrderLimit] = useState(0);
  const { simple_role } = AuthService.getRole();
  const dispatch = useDispatch();

  //Tabs
  const [tab, setTab] = React.useState(0);
  const handleTab = (event, newValue) => {
    setTab(newValue);
  };

  const [coloR, setColoR] = useState({
    primary: "#7BFC02",
    secondary: "#F34336",
    gradient: [],
  }); // Initial color, you can set it to any color you prefer


  //   const getCustomerOrder = () => {

  //   }
  useEffect(() => {
    simple_role === "USER" &&
      service
        .getCustomer()
        .then((customer) => {
          setCustomerType(customer.type);
          setOrderLimit(customer.limit_order);
          setMonthlyOrderLimit(customer.limit_month);
          setYearlyOrderLimit(customer.limit_year);
          setUsedMonthlyOrderLimit(customer.c_limit_month);
          setUsedYearlyOrderLimit(customer.c_limit_year);
          // console.log("customerlimitssss", customer.limit_month, customer.limit_order, customer.limit_year);
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
  }, []);

  useEffect(() => {
    props.changeNavTitle(lang[locale].myprofile);
    document.title = lang[locale].myprofile + " | EMDACS";
  }, []);

  useEffect(() => {
    dispatch(authActions.checkLogin());
  }, []);

  useEffect(() => {
    if (role.simple_role === "LIEFERANT") {
      service
        .getSupplierInfo()
        .then((res) => {
          setColoR({
            primary : res.Supplier.color.primary_color !== 0 ?  res.Supplier.color.primary_color :  "#7BFC02" ,
            secondary : res.Supplier.color.secondary_color !== 0 ?  res.Supplier.color.secondary_color :  "#F34336",
            gradient: res.Supplier.color.gradient_color.indexOf("linear") !== -1 ? res.Supplier.color.gradient_color.split("linear-gradient(to right,")[1].split(")")[0].split(",") : res.Supplier.color.gradient_color,
          })
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    } else {
      service
        .getSupplierInfo()
        .then((res) => {
          setColoR({
            primary : res.color.primary_color !== 0 ?  res.color.primary_color :  "#7BFC02" ,
            secondary : res.color.secondary_color !== 0 ?  res.color.secondary_color :  "#F34336",
            gradient: res.color.gradient_color.indexOf("linear") !== -1 ? res.color.gradient_color.split("linear-gradient(to right,")[1].split(")")[0].split(",") : res.color.gradient_color,
          })
          // setProfilePictureDynamic(res.Supplier.picture);
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
  }, []);

  const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
      root: {
        height: 20,
        borderRadius: 5,
        width: 200,
      },
      colorPrimary: {
        backgroundColor:
          theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
      },
      bar: {
        borderRadius: 5,
        backgroundColor: "#1a90ff",
      },
    })
  )(LinearProgress);

  return (
    //Supplier Navigation

    // @ts-ignore
    <Box sx={{ width: "100%" }}>
      {simple_role === "LIEFERANT" && (
        <>
          <Box style={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tab}
              onChange={handleTab}
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: {
                  backgroundColor: coloR.secondary
                }
              }}
            >
              <Tab label={lang[locale].changeName} {...a11yProps(0)} />
              <Tab label={lang[locale].changePassword} {...a11yProps(1)} />
              <Tab label={lang[locale].globalCharges} {...a11yProps(2)} />
              <Tab label={lang[locale].twoFA} {...a11yProps(3)}></Tab>
              <Tab label={lang[locale].buyTimeLimit} {...a11yProps(4)}></Tab>
              <Tab label={lang[locale].colorSettings} {...a11yProps(5)}></Tab>
            </Tabs>
          </Box>
          <TabPanel value={tab} index={0}>
            <Paper style={{ padding: "30px", margin: "20px" }}>
              <SetNewNameProfileComponent coloR={coloR} />
            </Paper>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Paper style={{ padding: "30px", margin: "20px" }}>
              <SetNewPasswordProfileComponent coloR={coloR}/>
            </Paper>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <Paper style={{ padding: "30px", margin: "20px" }}>
              <GlobalChargeComponent coloR={coloR}/>
            </Paper>
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <Paper style={{ padding: "30px", margin: "20px" }}>
              <Set2FAComponent coloR={coloR}/>
            </Paper>
          </TabPanel>
          <TabPanel value={tab} index={4}>
            <Paper style={{ padding: "30px", margin: "20px" }}>
              <BuyTimeLimitComponent coloR={coloR}/>
            </Paper>
          </TabPanel>
          <TabPanel value={tab} index={5}>
            <Paper style={{ padding: "30px", margin: "20px" }}>
              <ColorSettingComponent handleTab={handleTab}/>
            </Paper>
          </TabPanel>
        </>
      )}
      {simple_role === "USER" && customerType !== "E" && customerType !== "I" && (
        <>
          <Box style={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tab}
              onChange={handleTab}
              aria-label="Profil Navigation"
              TabIndicatorProps={{
                style: {
                  backgroundColor: coloR.secondary
                }
              }}
            >
              <Tab label="Order Limits" {...a11yProps(0)} />
              <Tab label={lang[locale].changeName} {...a11yProps(1)} />
              <Tab label={lang[locale].changePassword} {...a11yProps(2)} />
              <Tab label={lang[locale].twoFA} {...a11yProps(3)}></Tab>
            </Tabs>
          </Box>

          <TabPanel value={tab} index={0}>
            <Paper style={{ padding: "30px", margin: "20px" }}>
              <p style={{ marginRight: 5 }}>
                {lang[locale].perBuy} {":"}{" "}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={orderLimit}
                  displayType={"text"}
                />
                <br />
                <br />
                <hr />
              </p>
              <p style={{ marginRight: 5 }}>
                {lang[locale].perMonth} {":"}{" "}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={monthlyOrderLimit}
                  displayType={"text"}
                />
                <br />
                <br />
                {(monthlyOrderLimit && usedmonthlyOrderLimit) && monthlyOrderLimit > 0 ? (
                  <Tooltip
                  title={`${
                    lang[locale].alreadybought
                  } ${usedmonthlyOrderLimit ? usedmonthlyOrderLimit.toFixed(0) : 0} € / ${
                    (usedmonthlyOrderLimit ? (monthlyOrderLimit - usedmonthlyOrderLimit).toFixed(0) : monthlyOrderLimit)} € ${lang[locale].stillFree}`}
                >
                  <BorderLinearProgress
                    variant="determinate"
                    value={(usedmonthlyOrderLimit * 100) / monthlyOrderLimit}
                  />
                </Tooltip>
                ) : null}
                <br />
                <hr />
              </p>
              <p style={{ marginRight: 5 }}>
                {lang[locale].perYear}
                {":"}{" "}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix={" €"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={yearlyOrderLimit}
                  displayType={"text"}
                />
                <br />
                <br />
                {(yearlyOrderLimit && usedYearlyOrderLimit) && yearlyOrderLimit > 0 ? (
                  <Tooltip
                  title={`${
                    lang[locale].alreadybought
                  } ${usedYearlyOrderLimit ? usedYearlyOrderLimit.toFixed(0) : 0} € / ${
                    usedYearlyOrderLimit ? (yearlyOrderLimit - usedYearlyOrderLimit).toFixed(0) : 0} € ${lang[locale].stillFree}`}
                >
                  <BorderLinearProgress
                    variant="determinate"
                    value={(usedYearlyOrderLimit * 100) / yearlyOrderLimit}
                  />
                </Tooltip>
                ) : null}
              </p>
            </Paper>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Paper style={{ padding: "30px", margin: "20px" }}>
              <SetNewNameProfileComponent coloR={coloR} />
            </Paper>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <Paper style={{ padding: "30px", margin: "20px" }}>
              <SetNewPasswordProfileComponent coloR={coloR}/>
            </Paper>
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <Paper style={{ padding: "30px", margin: "20px" }}>
              <Set2FAComponent coloR={coloR}/>
            </Paper>
          </TabPanel>
        </>
      )}
      {simple_role === "USER" && customerType === "E" && (
        <>
          <Box style={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tab}
              onChange={handleTab}
              aria-label="Profil Navigation"
            >
              <Tab label={lang[locale].changeName} {...a11yProps(0)} />
              <Tab label={lang[locale].changePassword} {...a11yProps(1)} />
              <Tab label={lang[locale].twoFA} {...a11yProps(2)}></Tab>
            </Tabs>
          </Box>

          <TabPanel value={tab} index={0}>
            <Paper style={{ padding: "30px", margin: "20px" }}>
              <SetNewNameProfileComponent coloR={coloR}/>
            </Paper>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Paper style={{ padding: "30px", margin: "20px" }}>
              <SetNewPasswordProfileComponent coloR={coloR}/>
            </Paper>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <Paper style={{ padding: "30px", margin: "20px" }}>
              <Set2FAComponent coloR={coloR}/>
            </Paper>
          </TabPanel>
        </>
      )}
      {simple_role === "USER" && customerType === "I" && (
        <>
          <Box style={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tab}
              onChange={handleTab}
              aria-label="Profil Navigation"
            >
              <Tab label={lang[locale].changeName} {...a11yProps(0)} />
              <Tab label={lang[locale].changePassword} {...a11yProps(1)} />
              <Tab label={lang[locale].twoFA} {...a11yProps(2)}></Tab>
            </Tabs>
          </Box>

          <TabPanel value={tab} index={0}>
            <Paper style={{ padding: "30px", margin: "20px" }}>
              <SetNewNameProfileComponent coloR={coloR}/>
            </Paper>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Paper style={{ padding: "30px", margin: "20px" }}>
              <SetNewPasswordProfileComponent coloR={coloR}/>
            </Paper>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <Paper style={{ padding: "30px", margin: "20px" }}>
              <Set2FAComponent coloR={coloR}/>
            </Paper>
          </TabPanel>
        </>
      )}
    </Box>
  );
}

export default ProfileComponent;
