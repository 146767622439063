import { constantsConstants } from "constants/constants.constants";
import { AnyAction } from "redux";

export interface constantsReducerInitialStateType {
    min_length_name?: number;
    max_length_name?: number;
    min_usage_mwh_power_slp?: number;
    max_usage_mwh_power_slp?: number;
    min_usage_mwh_power_shp?: number;
    max_usage_mwh_power_shp?: number;
    min_usage_mwh_gas_shp?: number;
    max_usage_mwh_gas_shp?: number;
    min_usage_mwh_gas_slp_g?: number;
    max_usage_mwh_gas_slp_g?: number;
    min_usage_mwh_gas_slp_s?: number;
    max_usage_mwh_gas_slp_s?: number;
    min_usage_mwh_gas_rlm?: number;
    max_usage_mwh_gas_rlm?: number;
    max_usage_mwh_power_rlm?: number;
    min_usage_mwh_power_rlm?: number;
    price_holding_time?: number;
    max_order_scale?: number;
    min_order_scale?: number;
    min_name_length?: number,
    max_name_length?: number;
    max_mail_length?: number;
    min_password_length?: number;
    max_password_length?: number;
    password_must_contain_chars?: Array<String>;
    support_link?: string;
    wiki_link?: string;
    wiki_link_supplier?: string;
    version?: string;
    cookie_lifetime?: number;
    inipro_error?: Array<iniproError>;
}

export interface iniproError {
    id: string,
    en: string;
    de: string;
}

const initialState: constantsReducerInitialStateType = {
    min_length_name: undefined,
    max_length_name: undefined,
    min_usage_mwh_power_slp: undefined,
    max_usage_mwh_power_slp: undefined,
    min_usage_mwh_power_shp: undefined,
    max_usage_mwh_power_shp: undefined,
    min_usage_mwh_gas_shp: undefined,
    max_usage_mwh_gas_shp: undefined,
    min_usage_mwh_gas_slp_g: undefined,
    max_usage_mwh_gas_slp_g: undefined,
    min_usage_mwh_gas_slp_s: undefined,
    max_usage_mwh_gas_slp_s: undefined,
    price_holding_time: undefined,
    max_order_scale: undefined,
    min_order_scale: undefined,
    min_name_length: undefined,
    max_name_length: undefined,
    max_mail_length: undefined,
    min_password_length: undefined,
    max_password_length: undefined,
    password_must_contain_chars: undefined,
    support_link: undefined,
    wiki_link: undefined,
    wiki_link_supplier: undefined,
    version: undefined,
    cookie_lifetime: undefined,
    inipro_error: undefined,
    min_usage_mwh_gas_rlm: undefined,
    max_usage_mwh_gas_rlm: undefined,
    max_usage_mwh_power_rlm: undefined,
    min_usage_mwh_power_rlm: undefined,
};

export function constants(
    state: constantsReducerInitialStateType = initialState,
    action: AnyAction
) {
    switch (action.type) {
        case constantsConstants.GET_CONSTANTS:
            return { ...state };
        case constantsConstants.UPDATE_CONSTANTS:
            const { constantsData } = action;
            return {
                min_length_name: constantsData.min_length_name,
                max_length_name: constantsData.max_length_name,
                min_usage_mwh_power_slp: constantsData.min_usage_mwh_power_slp,
                max_usage_mwh_power_slp: constantsData.max_usage_mwh_power_slp,
                min_usage_mwh_power_shp: constantsData.min_usage_mwh_power_shp,
                max_usage_mwh_power_shp: constantsData.max_usage_mwh_power_shp,
                min_usage_mwh_gas_shp: constantsData.min_usage_mwh_gas_shp,
                max_usage_mwh_gas_shp: constantsData.max_usage_mwh_gas_shp,
                min_usage_mwh_gas_slp_g: constantsData.min_usage_mwh_gas_slp_g,
                max_usage_mwh_gas_slp_g: constantsData.max_usage_mwh_gas_slp_g,
                min_usage_mwh_gas_slp_s: constantsData.min_usage_mwh_gas_slp_s,
                max_usage_mwh_gas_slp_s: constantsData.max_usage_mwh_gas_slp_s,
                price_holding_time: constantsData.price_holding_time,
                max_order_scale: constantsData.max_order_scale,
                min_order_scale: constantsData.min_order_scale,
                min_name_length: constantsData.min_name_length,
                max_name_length: constantsData.max_name_length,
                max_mail_length: constantsData.max_mail_length,
                min_password_length: constantsData.min_password_length,
                max_password_length: constantsData.max_password_length,
                password_must_contain_chars:
                    constantsData.password_must_contain_chars,
                support_link: constantsData.support_link,
                wiki_link: constantsData.wiki_link,
                wiki_link_supplier: constantsData.wiki_link_supplier,
                version: constantsData.version,
                cookie_lifetime: constantsData.cookie_lifetime,
                inipro_error: constantsData.inipro_error,
                min_usage_mwh_gas_rlm: constantsData.min_usage_mwh_gas_rlm,
                max_usage_mwh_gas_rlm: constantsData.max_usage_mwh_gas_rlm,
                max_usage_mwh_power_rlm: constantsData.max_usage_mwh_power_rlm,
                min_usage_mwh_power_rlm: constantsData.min_usage_mwh_power_rlm,
            };

        default:
            return state;
    }
}
