import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import modalActions from "../actions/modal.actions";
import { lang } from "../lang";
import TwoDecimalComponent from "./TwoDecimal";
import LoadcurveService from "services/loadcurves/LoadcurveService";

function OfferSurchargeComponent(props) {
  const locale = localStorage.locale;
  const service: LoadcurveService = new LoadcurveService();
  const [fplCharge, setfplCharge] = useState(0);
  const dispatch = useDispatch();

  /**
   * Closes modal
   */
  function ssaveAndCloseHandler() {
    service.fplCharge(props.zrid, fplCharge)
    dispatch(modalActions.closeModal());
  }

  const selectHandler = (e) => {
    e.target.select();
  };

  async function getLoadcurve(id: number) {
    
    await service.getSingleLoadcurve(id).then(res=>{
      if(res.fpl_charge)
        setfplCharge(res.fpl_charge);
      })

  }

  useEffect(()=>{
    getLoadcurve(props.zrid)
    setfplCharge(props.fpl_charge)
  },[])

  return (
    <>
      <Typography>{lang[locale].offerSurchargeText}</Typography>
      <Grid item xs={3} style={{ margin: "2em 1em" }}>
        <TextField
          onFocus={selectHandler}
          //   color="primary"
          //   required
          //   fullWidth
          //   id="globalStromPreisF"
          value={fplCharge}
          onChange={(e) => setfplCharge(e.target.value !== undefined ? parseFloat(e.target.value) : 0)}
          //   name="globalStromPreisF"
          //   label={lang[locale].globalStromF}
          InputProps={{
            inputComponent: TwoDecimalComponent,
          }}
        />
      </Grid>
      <Grid
        container
        justify="flex-end"
        style={{ marginTop: "2em", padding: 0, position: "relative" }}
      >
        <Button
          color="primary"
          onClick={ssaveAndCloseHandler}
          style={{ position: "absolute", bottom: -20, right: -4 }}
        >
          {lang[locale].save}
        </Button>
      </Grid>
    </>
  );
}

export default OfferSurchargeComponent;
