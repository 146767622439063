import React,{useState} from "react";
import { Grid, TextField, Typography, ListItemText } from "@material-ui/core";
import { lang } from "../lang";
import { useSelector } from "../services/helpers/useSelector";
import { useDispatch } from "react-redux";
import forecastActions from "../actions/forecast.actions";

function SelectForecastNameComponent() {
    const locale = localStorage.locale;
    const { name } = useSelector((state) => state.forecast.data);
    const { min_name_length, max_name_length } = useSelector(
        (state) => state.constants
    );
    const [TempoName,setTempoName] = useState("");

    const dispatch = useDispatch();
    //handles change for name
    // function handleChange(e) {
    //     // if (e.target.value.length <= 30)
    //     setTempoName(e.target.value)
    // }
    function handleSetName(e) {
        // if (e.target.value.length <= 30)
            dispatch(
                forecastActions.saveForecastSingle("name", TempoName)
            );
    }


    function checkName(name: string): string[] {
        const re = /^[a-zA-Z0-9-]*$/;
        const check: string[] = [];
        if (name.length < min_name_length) {
            check.push(
                lang[locale].nameTooShortFirst +
                    min_name_length +
                    lang[locale].nameTooShortSecond
            );
        }
        if (name.length > max_name_length) {
            check.push(
                lang[locale].nameTooLongFirst +
                    max_name_length +
                    lang[locale].nameTooLongSecond
            );
        }
        if (!re.test(name)) {
            check.push(lang[locale].wrongCharacters);
        }
        return check;
    }

    return (
        <Grid item xs={12} md={6}>
            <TextField
                inputProps={{ "data-testid": "selectName", maxLength : 50 }}
                value={TempoName ? TempoName : ""}
                fullWidth
                onChange={(e)=>setTempoName(e.target.value)}
                onBlur={handleSetName}
                label={lang[locale].name}
                helperText={"a-zA-Z0-9-"}
                error={TempoName.length < 3}
                autoFocus
            />
            {TempoName !== "" &&
                checkName(TempoName) !== [] &&
                checkName(TempoName).map((entry) => (
                    <ListItemText key={entry}>
                        <Typography
                            variant="caption"
                            color="error"
                            data-testid="date-error"
                            key={entry}
                        >
                            {entry}
                        </Typography>
                    </ListItemText>
                ))}
        </Grid>
    );
}

export default SelectForecastNameComponent;
