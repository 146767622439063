import React, { useEffect } from "react";
import { lang } from "../lang";
import OrderModel from "../typeDefs/OrderModel";
import LoadcurveService from "../services/loadcurves/LoadcurveService";
import { useDispatch } from "react-redux";
import snackbarActions from "../actions/snackbar.actions";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import OrderTableComponent from "./OrderTable";
import { useHistory } from "react-router-dom";

const service = new LoadcurveService();

interface IShowOrdersComponent {
    changeNavTitle?(title: string): void;
    orders?: OrderModel[];
}
/**
 * Shows all orders of a user
 * @constructor
 */
function ShowOrdersComponent(props: IShowOrdersComponent) {
    const locale = localStorage.locale;
    let history = useHistory();

    const [orders, setOrders] = React.useState<OrderModel[] | undefined>(
        undefined
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.changeNavTitle) props.changeNavTitle(lang[locale].showOrders);
        document.title = lang[locale].showOrders + " | EMDACS";

        if (!props.orders) {
            //get orders from service
            service
                .getOrders()
                .then((serviceOrders) => {
                    setOrders(serviceOrders);
                })
                .catch((e) => {
                    if (e.message === "401") {
                        return history.push(`/logout`);
                    }
                    console.error(e);
                    dispatch(snackbarActions.openSnackbar(e.message, "error"));
                });
        } else {
            setOrders(props.orders);
        }
    }, []);
    return (
        <>
            {orders ? (
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <OrderTableComponent orders={orders} />
                    </Grid>
                </Grid>
            ) : (
                <>
                    {orders === undefined ? (
                        <Grid container justify="center" alignItems="center">
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    ) : (
                        <Typography
                            variant="h5"
                            color="textSecondary"
                            style={{ marginTop: "3vh" }}
                        >
                            {lang[locale].noOrders}
                        </Typography>
                    )}
                </>
            )}
        </>
    );
}

export default ShowOrdersComponent;
