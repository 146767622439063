import forecastConstants from "../constants/forecast.constants";
import ProfileType from "../typeDefs/ProfileType";
import { store } from "services/helpers/store";

//Services
import ForecastService from "../services/forecast/ForecastService";

const service = new ForecastService();

const forecastActions = {
    removeName,
    create,
    createDirect,
    reset,
    saveForecastSingle,
    updateProfileTypes,
    saveFleximportSingle,
    resetFPLData,
};

function removeName(name: string) {
    return {
        type: forecastConstants.REMOVE_FINISHED_NAMES,
        name,
    };
}

/**
 * Removes only the fpl data so the user can import one again
 */
function resetFPLData() {
    return {
        type: forecastConstants.RESET_FPLDATA,
    };
}
/**
 * Updates the profile types in the state
 * @param profileTypes
 */
function updateProfileTypes(profileTypes: ProfileType[]) {
    return {
        type: forecastConstants.UPDATE_PROFILETYPES,
        profileTypes,
    };
}

/**
 * Overrides a single field in the data field from the reducer
 * @param field
 * @param data
 */
function saveForecastSingle(field: string, data) {
    return {
        type: forecastConstants.SAVE_SINGLE,
        field,
        data,
    };
}

/**
 * Same as above only for fleximport data
 * @param field
 * @param data
 */
function saveFleximportSingle(field: string, data) {
    return {
        type: forecastConstants.SAVE_SINGLE_FLEXIMPORT,
        field,
        data,
    };
}
/**
 * Creates forecasts
 */

function create(customer? /* : CustomerModel | null */) {
    const data = { ...store.getState().forecast.data };

    const fleximportData = store.getState().forecast.fleximportData;
    if (fleximportData.startDateValue)
        data.istFrom = fleximportData.startDateValue;
    if (fleximportData.endDateValue) data.istTo = fleximportData.endDateValue;
    //just divide by 1000 because inipro expects mw/h but we are getting kw/h
    data.usage = data.usage / 1000;
    data.performance = data.performance / 1000;
    if (!data.name) {
        data.name = "istdata";
    }
    return (dispatch) => {
        //request
        dispatch(request());
        //call the service
        return service
            .createForecast(
                data,
                data.forecastType === 0 ? "flex" : "inipro",
                customer ? customer : undefined
            )
            .then((json) => {
                //add name to array for requesting the status only if forecastType is not 0
                if (data.forecastType !== 0) dispatch(addName(json.message));
                dispatch(success());
            })
            .catch((e) => {
                //Does not work here: Option middleware?
                /* if (e.message === "401") {
                    return history.push(`/logout`);
                } */
                console.error(e.message);
                dispatch(error(new Error(e.message)));
            });
    };

    function addName(name: string) {
        return {
            type: forecastConstants.ADD_FINISHED_NAMES,
            name: name.split(".")[0].toLowerCase(),
        };
    }

    function request() {
        return {
            type: forecastConstants.CREATE_REQUEST,
        };
    }

    function success() {
        return {
            type: forecastConstants.CREATE_SUCCESS,
        };
    }

    function error(error: Error) {
        return {
            type: forecastConstants.CREATE_ERROR,
            error,
        };
    }
}
function createDirect(customer? /* : CustomerModel | null */) {
    const data = { ...store.getState().forecast.data };

    const fleximportData = store.getState().forecast.fleximportData;
    if (fleximportData.startDateValue)
        data.istFrom = fleximportData.startDateValue;
    if (fleximportData.endDateValue) data.istTo = fleximportData.endDateValue;
    //just divide by 1000 because inipro expects mw/h but we are getting kw/h
    data.usage = data.usage / 1000;
    data.performance = data.performance / 1000;
    if (!data.name) {
        data.name = "istdata";
    }
    return (dispatch) => {
        //request
        dispatch(request());
        //call the service
        return service
            .createForecastDirect(
                data,
                "direct"
            )
            .then((json) => {
                //add name to array for requesting the status only if forecastType is not 0
                if (data.forecastType !== 0) dispatch(addName(json.message));
                dispatch(success());
            })
            .catch((e) => {
                //Does not work here: Option middleware?
                /* if (e.message === "401") {
                    return history.push(`/logout`);
                } */
                console.error(e.message);
                dispatch(error(new Error(e.message)));
            });
    };

    function addName(name: string) {
        return {
            type: forecastConstants.ADD_FINISHED_NAMES,
            name: name.split(".")[0].toLowerCase(),
        };
    }

    function request() {
        return {
            type: forecastConstants.CREATE_REQUEST,
        };
    }

    function success() {
        return {
            type: forecastConstants.CREATE_SUCCESS,
        };
    }

    function error(error: Error) {
        return {
            type: forecastConstants.CREATE_ERROR,
            error,
        };
    }
}

/**
 * Resets the state
 */
function reset() {
    return {
        type: forecastConstants.RESET,
    };
}

export default forecastActions;
