import React, { useEffect, useState } from "react";
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import { useSelector } from "../services/helpers/useSelector";
import ForecastService from "../services/forecast/ForecastService";
import snackbarActions from "../actions/snackbar.actions";
import { lang } from "../lang";
import { useDispatch } from "react-redux";
import forecastActions from "../actions/forecast.actions";
import { useHistory } from "react-router-dom";

const service = new ForecastService();

/**
 * Component for selecting the variant based on the profile type
 * @constructor
 */
function SelectVariantComponent() {
    let history = useHistory();
    const profile = useSelector((state) => state.forecast.data.profile);
    const variant = useSelector((state) => state.forecast.data.variant);
    const [variants, setVariants] = useState<number[] | undefined>(undefined);
    const locale = localStorage.locale;
    const variantNames = [
        "",
        "ohne Wind",
        "windarm",
        "normal",
        "windig",
        "windreich",
    ];
    const dispatch = useDispatch();
    //handles the change
    function handleChange(e) {
        dispatch(forecastActions.saveForecastSingle("variant", e.target.value));
    }

    //fetch the profiles from api
    useEffect(() => {
        dispatch(forecastActions.saveForecastSingle("variant", 0));

        if (profile !== 0)
            service
                .getVariants(profile)
                .then((variants) => {
                    // {
                        setVariants(variants);
                    // }
                })
                .catch((e) => {
                    if (e.message === "401") {
                        return history.push(`/logout`);
                    }
                    console.error("Error getting pricecurves", e);
                    dispatch(snackbarActions.openSnackbar(e.message, "error"));
                });
    }, [profile]);

    return (
        <Grid item xs={6}>
            <>
                {!variants || !profile ? null : (
                    <>
                        <FormControl fullWidth={true} required error={!variant}>
                            <InputLabel id="variant">
                                {lang[locale].variant}
                            </InputLabel>
                            <Select
                                labelId="variant"
                                id="variantSelect"
                                value={variant ? variant : ''}
                                onChange={handleChange}
                            >
                                {/* <MenuItem value={0}>
                                    {lang[locale].notSelected}
                                </MenuItem> */}
                                {variants.map((vari) => (
                                    <MenuItem
                                        key={vari}
                                        data-testid="variant"
                                        value={vari ? vari : ''}
                                    >
                                        {vari + " - " + variantNames[vari]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </>
                )}
            </>
        </Grid>
    );
}

export default SelectVariantComponent;
