import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { lang } from "lang";
import ProfilePicture from "img/fff.png";
import Logo from "../img/logo.png";
import swdu from "../img/swdu.png";
import metadata from "../metadata.json";
import { format, getISOWeek } from "date-fns";
import { config } from "../services/helpers/Constants";
import {
  makeStyles,
  useTheme,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Avatar,
  Fab,
  Button,
  Tooltip as MTooltip,
  TextField,
} from "@material-ui/core";

import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ShowChart as ShowChartIcon,
  ArrowBack as ArrowBackIcon,
  Person as PersonIcon,
  Group as GroupIcon,
  Payment as PaymentIcon,
  MenuBook as MenuBookIcon,
  Assessment as AssessmentIcon,
  Chat as ChatIcon,
} from "@material-ui/icons";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

import QueryStatsIcon from "@mui/icons-material/QueryStats";

//Components
import MyPlansComponent from "../components/MyPlans";
import FleximportComponent from "../components/Fleximport";
import FleximportComponentPE from "components/FleximportPE";
import DetailedViewComponent from "../components/DetailledView";
import UsermanagementComponent from "../components/Usermanagement";
import OrdersDataGridComponent from "../components/OrdersDataGrid";
import ProfileComponent from "../components/Profile";
import TimerComponent from "components/Timer";
import PfmComponent from "components/PortfolioManagement";
import PriceDevelopment from "components/PriceDevelopment";
import UserOfCustomerComponent from "components/UserOfCustomerList";

// React Router
import { Route, Switch, useLocation } from "react-router";
import { Link } from "react-router-dom";

//Services
import AuthService from "../services/auth/AuthService";
import LoadcurveService from "../services/loadcurves/LoadcurveService";
import UserService from "../services/users/UserService";

//Actions
import userActions from "actions/user.actions";
import snackbarActions from "../actions/snackbar.actions";
import constantsActions from "actions/constants.actions";
import ciSettingsActions from "actions/ciSettings.actions";

//Hooks
import { useDispatch } from "react-redux";
import { useSelector } from "services/helpers/useSelector";
import { useHistory } from "react-router-dom";
// import { customer } from "reducers/customer.reducer";
// import { isNumber } from "@material-ui/x-grid";

const drawerWidth = 300;

const loadcurveService = new LoadcurveService();

// const authService = new AuthService();

// let currentDate = new Date().toLocaleString("de-DE", {
//     year: "numeric",
//     month: "short",
//     day: "numeric",
// });

var rgularExp = {
  contains_alphaNumeric: /^(?!-)(?!.*-)[A-Za-z0-9-]+(?<!-)$/,
  containsNumber: /\d+/,
  containsAlphabet: /[a-zA-Z]/,

  onlyLetters: /^[A-Za-z]+$/,
  onlyNumbers: /^[0-9]+$/,
  onlyMixOfAlphaNumeric: /^([0-9]+[a-zA-Z]+|[a-zA-Z]+[0-9]+)[0-9a-zA-Z]*$/,
};
/**
 * Component for the central routing of the application
 */
function HomeView() {
  const [gradient_color, setGradient_color] = useState("");
  const [primaryColorTheme, setPrimaryColorTheme] = useState("");
  const [secondaryColorTheme, setSecondaryColorTheme] = useState("");
  const [ProfilePictureDynamic, setProfilePictureDynamic] = useState<any>("");
  const [actuelWeekNumber, setActuelWeekNumber] = useState(0);
  const [KWWeekNumber, setKWWeekNumber] = useState(0);
  const [AngebotMaxCount, setAngebotMaxCount] = useState(0);

  const [Year,setYear] = useState( new Date().getFullYear());
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    title: {
      flexGrow: 1,
    },
    logo: {
      height: "5rem",
      padding: "1rem",
    },
    nav: { justifyContent: "space-between", background: gradient_color },
    customizedTheme: { background: `${primaryColorTheme}`, color: "white" },
  }));

  let history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [customerType, setCustomerType] = useState("");
  //standard title for the inapp navbar
  const [navTitle, setNavTitle] = React.useState("Anwendung");
  const [customer, setcustomer] = useState(undefined);
  const locale = localStorage.locale;
  const role = AuthService.getRole();
  const { simple_role } = AuthService.getRole();
  const service = new UserService();
  const dispatch = useDispatch();
  const pathName = location.pathname?.split("/")[3];
  const disable = rgularExp.onlyNumbers.test(pathName);
  const [snackbarChanged, setSnackbarChanged] = useState(false);
  const user = useSelector((state) => state.user);
  const { support_link, wiki_link, wiki_link_supplier, version } = useSelector(
    (state) => state.constants
  );

  const [coloR, setColoR] = useState({
    primary: "#7BFC02",
    secondary: "#F34336",
    gradient: [],
  }); // Initial color, you can set it to any color you prefer

  // useEffect(()=>{
  //   service.getKWStatus().then((res)=>setKWWeekNumber(res.kw))
  // },[])

  useEffect(() => {
    const now = new Date();
    const weekNumber = getISOWeek(now);
    setActuelWeekNumber(weekNumber);
  }, []);

  const MarktBerichtHandler = () => {
    service.getKWStatus().then((res)=>{setKWWeekNumber(res.kw); setYear(res.year)})
    if (
      role.permissions.includes("CHARTS") &&
      actuelWeekNumber === KWWeekNumber
    ) {
      const newWindow = window.open("", "_blank");
      //@ts-ignore
      newWindow.document.write(`
      <html>
      <head>
      <title>${lang[locale].marketreport} | EMDACS</title>
          </head>
          <body style="margin: 0;">
          <iframe src="${config.url.API_URL}/api/weekly_report" width="100%" height="100%"></iframe>
          </body>
          </html>
          `);
        }
        if (
          role.permissions.includes("CHARTS") &&
          actuelWeekNumber > KWWeekNumber
          ) {
      setSnackbarChanged(true);
      setTimeout(() => {
        const newWindow = window.open("", "_blank");
        //@ts-ignore
        newWindow.document.write(`
        <html>
          <head>
            <title>${lang[locale].marketreport} | EMDACS</title>
          </head>
          <body style="margin: 0;">
            <iframe src="${config.url.API_URL}/api/weekly_report" width="100%" height="100%"></iframe>
          </body>
        </html>
      `);
        setSnackbarChanged(false);
      }, 4000);
    } else if (!role.permissions.includes("CHARTS")) {
      setSnackbarChanged(true);
      setTimeout(() => {
        const newWindow = window.open("", "_blank");
        //@ts-ignore
        newWindow.document.write(`
        <html>
          <head>
            <title>${lang[locale].marketreport} | EMDACS</title>
          </head>
          <body style="margin: 0;">
            <iframe src="${config.url.API_URL}/api/weekly_report" width="100%" height="100%"></iframe>
          </body>
        </html>
      `);
        setSnackbarChanged(false);
      }, 4000);
    }
  };

  useEffect(() => {
    loadcurveService
      .getConstants()
      .then((res) => {
        dispatch(constantsActions.update(res));
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  }, []);

  useEffect(() => {
    if (role.simple_role === "LIEFERANT") {
      service
        .getSupplierInfo()
        .then((res) => {
          setAngebotMaxCount(res.Supplier["max_forecast_count"])
          setGradient_color(res.Supplier.color.gradient_color.split("linear-gradient(to right,")[1].length < 10 ? res.Supplier.color.gradient_color.split("linear-gradient(to right,")[1].split(")")[0] : res.Supplier.color.gradient_color);
          setPrimaryColorTheme(res.Supplier.color.primary_color);
          setSecondaryColorTheme(res.Supplier.color.secondary_color);
          setProfilePictureDynamic(res.Supplier.picture);
          setColoR({
            primary : res.Supplier.color.primary_color !== 0 ?  res.Supplier.color.primary_color :  "#7BFC02" ,
            secondary : res.Supplier.color.secondary_color !== 0 ?  res.Supplier.color.secondary_color :  "#F34336",
            gradient: res.Supplier.color.gradient_color,
          })
          dispatch(ciSettingsActions.update(res.Supplier.color.primary_color , res.Supplier.color.secondary_color, res.Supplier.color.gradient_color.indexOf("linear") !== -1 ? res.Supplier.color.gradient_color.split("linear-gradient(to right,")[1].split(")")[0].split(",") : res.Supplier.color.gradient_color,res.Supplier.picture))
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    } else {
      service
        .getSupplierInfo()
        .then((res) => {
          setGradient_color(res.color.gradient_color.split("linear-gradient(to right,")[1].length < 10 ? res.color.gradient_color.split("linear-gradient(to right,")[1].split(")")[0] : res.color.gradient_color );
          setPrimaryColorTheme(res.color.primary_color);
          setSecondaryColorTheme(res.color.secondary_color);
          setProfilePictureDynamic(res.picture);
          setColoR({
            primary : res.color.primary_color !== 0 ?  res.color.primary_color :  "#7BFC02" ,
            secondary : res.color.secondary_color !== 0 ?  res.color.secondary_color :  "#F34336",
            gradient: res.color.gradient_color.indexOf("linear") !== -1 ? res.color.gradient_color.split("linear-gradient(to right,")[1].split(")")[0].split(",") : res.color.gradient_color,
          })
          dispatch(ciSettingsActions.update(res.color.primary_color , res.color.secondary_color, res.color.gradient_color.indexOf("linear") !== -1 ? res.color.gradient_color.split("linear-gradient(to right,")[1].split(")")[0].split(",") : res.color.gradient_color ,res.picture))
    })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
  }, []);

  useEffect(() => {
    fetch(config.url.API_URL + "/api/weekly_report")
      .then((response) => {
        // Access the headers using response.headers.get('Header-Name')
        const contentType = response.headers.get("Content-Disposition");
        //@ts-ignore
        setKWWeekNumber(
          Number(contentType && contentType.split("kw")[1].split(".")[0])
        );
        // // Check if the response is in JSON format
        // if (contentType && contentType.includes('application/json')) {
        //   return response.json(); // Parse JSON response
        // } else {
        //   throw new Error('Invalid content type');
        // }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    snackbarChanged === true && !role.permissions.includes("CHARTS")
      ? dispatch(
          snackbarActions.openSnackbar(
            "Ihnen fehlt das Recht zur Anzeige des aktuellen (kostenpflichtigen) Marktberichts.Falls Sie den aktuellen Bericht wünschen, so klären Sie dies bitte mit Ihrem Berater",
            "warning"
          )
        )
      : role.permissions.includes("CHARTS") &&
        actuelWeekNumber > KWWeekNumber &&
        dispatch(
          snackbarActions.openSnackbar(
            "Leider liegt der Bericht zur aktuellen Kalenderwoche noch nicht vor. Der letzte vorliegende Bericht ist aus Kalenderwoche " + KWWeekNumber +" KW / " + Year,
            "warning"
          )
        );
  }, [snackbarChanged]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const changeNavTitle = (title: string) => {
    setNavTitle(title);
  };
  const necessaryProps = {
    changeNavTitle,
  };
  //changes the drawer state on first load
  useEffect(() => {
    if (window.innerWidth < 1300) handleDrawerClose();
  }, []);

  // useEffect(()=>{
  //     if(pathName === "/app/myplans" || isNumber(pathName)){
  //         setDisable(true);
  //     }
  // },[pathName]);
  //Changes the drawer based on width of the window
  useEffect(() => {
    const changeDrawer = () => {
      if (window.innerWidth < 1300 && open) handleDrawerClose();
      else if (window.innerWidth > 1300 && !open) handleDrawerOpen();
    };
    window.addEventListener("resize", changeDrawer);

    return () => {
      window.removeEventListener("resize", changeDrawer);
    };
  });

  // useEffect(() => {
  //   let timeoutId;

  //   function handleMouseMove(){
  //     setIsMouseMoving(true);
  //     clearTimeout(timeoutId)

  //     timeoutId = setTimeout(() => {
  //       setAutomaticRefresh(true);
  //       localStorage.setItem("automaticRefresh", "true");
  //       window.location.reload();
  //     }, 240000);
  //   }
  //   window.addEventListener("mousemove", handleMouseMove);

  //   return () => {
  //     window.removeEventListener("mousemove", handleMouseMove);
  //     clearTimeout(timeoutId);
  //     setAutomaticRefresh(false);
  //   }
  // }, []);

  useEffect(() => {
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    firstName && lastName && dispatch(userActions.update(firstName, lastName));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   simple_role === "USER" &&
  //     service
  //       .getCustomer()
  //       .then((customer) => {
  //         setCustomerType(customer.type);
  //       })
  //       .catch((e) => {
  //         if (e.message === "401") {
  //           return history.push(`/logout`);
  //         }
  //         console.error(e);
  //         dispatch(snackbarActions.openSnackbar(e.message, "error"));
  //       });
  // }, []);


      useEffect(() => {
        simple_role === "USER" &&
        service
          .getCustomer()
          .then((res) => {
            //@ts-ignore
            setcustomer(res);
            setCustomerType(res.type);
            // setStatus(res.status);
            // setType(res.type);
            // res.type === "E" && setPercentage(100);
          })
          .catch((e) => {
            if (e.message === "401") {
              return history.push(`/logout`);
            }
            console.error(e);
            dispatch(snackbarActions.openSnackbar(e.message, "error"));
          });
      }, []);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.nav}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">{navTitle}</Typography>
          <div>
            <img src={Logo} alt="emdacs logo" className={classes.logo} />
            {/* <img src={swdu} alt="emdacs logo" className={classes.logo} /> */}
          </div>
          <div>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item style={{display : "flex", flexDirection : "row", justifyContent : "flex-end", alignItems : "flex-end"}}>
                <img
                  alt="profilepicture"
                  src={`data:image/png;base64,${ProfilePictureDynamic}`}
                  style={{ width: "12rem", height: "3rem", objectFit : "contain" }}
                />
              </Grid>
              <Grid item>
                <MTooltip
                  title={
                    customerType === "I"
                      ? lang[locale].inIndustrieCustomer
                      : customerType === "F"
                      ? "FahrPlanKunde"
                      : customerType === "E" && "EndKunde"
                  }
                  aria-label="Name"
                >
                  <Typography>
                    {user.firstName + " " + user.lastName}
                    <br />{" "}
                    <b>
                      {role.simple_role === "LIEFERANT"
                        ? lang[locale].isSupplier
                        : role.simple_role === "USER" && customerType === "I"
                        ? lang[locale].inIndustrieCustomer
                        : lang[locale].customer}
                    </b>{" "}
                    {localStorage.company}
                    <br />
                  </Typography>
                </MTooltip>
              </Grid>
            </Grid>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar} style={{padding : "1.1rem"}}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {role.simple_role === "LIEFERANT" ? (
            <ListItem
              selected={location.pathname === "/app/users/customers"}
              button
              key="users"
              component={Link}
              to="/app/users/customers"
            >
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary={lang[locale].users} />
            </ListItem>
          ) : (
            customer !== undefined && 
            (<ListItem
              selected={location.pathname === "/app/users/userofcustomer"}
              button
              key="users"
              component={Link}
              //@ts-ignore
              to={`/app/users/userofcustomer/${customer.name}/${customer.id}`}
              >
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary={lang[locale].users} />
            </ListItem>) 
          )}
          {role.permissions?.includes("VIEW_NAV_OFFERS") ? (
            <ListItem
              selected={location.pathname === "/app/myplans"}
              button
              key="myplans"
              component={Link}
              to="/app/myplans"
              disabled={disable}
            >
              <ListItemIcon>
                <LocalOfferIcon />
              </ListItemIcon>
              <ListItemText primary={lang[locale].myplans} />
            </ListItem>
          ) : (
            ""
          )}

          {(role.permissions?.includes("VIEW_NAV_ORDERS") && (role.simple_role === "LIEFERANT" &&
            role.permissions.includes("USERS"))) ||
          (role.permissions?.includes("VIEW_NAV_ORDERS") && role.simple_role !== "ADMIN" &&
            role.simple_role !== "LIEFERANT" &&
            customerType !== "I") ? (
            <ListItem
              selected={location.pathname === "/app/allorders"}
              button
              key="allorders"
              component={Link}
              to="/app/allorders"
            >
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText primary={lang[locale].showOrders} />
            </ListItem>
          ) : (
            ""
          )}
          {role.permissions?.includes("PFM") ? (
            <ListItem
              selected={location.pathname === "/app/portfoliomanagement"}
              button
              key="portfoliomanagement"
              component={Link}
              to="/app/portfoliomanagement"
            >
              <ListItemIcon>
                <QueryStatsIcon />
              </ListItemIcon>
              <ListItemText primary={lang[locale].portfolioManagement} />
            </ListItem>
          ) : (
            ""
          )}
        </List>
        {
          role.permissions.includes("VIEW_NAV_ORDERS") || role.permissions.includes("VIEW_NAV_OFFERS") || role.permissions.includes("PFM") ?  <Divider /> : null
        }
        {/* <Divider /> */}
        <List>
          <ListItem
            selected={location.pathname === "/app/pricedevelopment"}
            component={Link}
            button
            key="pricedevelopment"
            to="/app/pricedevelopment"
          >
            <ListItemIcon>
              <ShowChartIcon />
            </ListItemIcon>
            <ListItemText primary={lang[locale].pricedevelopment} />
          </ListItem>
          <ListItem
            selected={location.pathname === "/app/myprofile"}
            component={Link}
            button
            key="myprofile"
            to="/app/myprofile"
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={lang[locale].myprofile} />
          </ListItem>
          <ListItem button key="logout" component={Link} to="/logout">
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary={lang[locale].logout} />
          </ListItem>
          <div style={{ position: "fixed", bottom: 15 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* <ListItem button key="weekly" component={Link} to="/api/weekly_report" onClick={()=> openInNewTab(`${config.url.API_URL}/api/weekly_report`)}> */}
              <a
                onClick={MarktBerichtHandler}
                href={`#`}
                // target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "white" }}
              >
                <ListItem style={{ width: "60%" }}>
                  <ListItemIcon>
                    <Fab
                      size="small"
                      // color="primary"
                      style={{backgroundColor : coloR.primary, color : "white"}}
                      // className={classes.customizedTheme}
                      aria-label="support"
                    >
                      <AssessmentIcon />
                    </Fab>
                  </ListItemIcon>
                  <ListItemText
                    hidden={!open}
                    primary={lang[locale].marketreport}
                  ></ListItemText>
                </ListItem>
              </a>
            </div>
            {/* <a
              href={
                role.simple_role === "ADMIN" || role.simple_role === "LIEFERANT"
                  ? wiki_link_supplier
                  : wiki_link
              }
              style={{ textDecoration: "none", color: "inherit" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ListItem>
                <ListItemIcon>
                  <Fab
                    size="small"
                    // color="primary"
                    // className={classes.customizedTheme}
                    style={{backgroundColor : coloR.primary, color : "white"}}
                    aria-label="support"
                  >
                    <MenuBookIcon />
                  </Fab>
                </ListItemIcon>
                <ListItemText
                  hidden={!open}
                  primary={lang[locale].onlineSupport}
                ></ListItemText>
              </ListItem>
            </a> */}
            <a
              href={support_link}
              style={{ textDecoration: "none", color: "inherit" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ListItem>
                <ListItemIcon>
                  <Fab
                    size="small"
                    // color="primary"
                    // className={classes.customizedTheme}
                    style={{backgroundColor : coloR.primary, color : "white"}}
                    aria-label="support"
                  >
                    <ChatIcon />
                  </Fab>
                </ListItemIcon>
                <ListItemText
                  hidden={!open}
                  primary={lang[locale].customerSupport}
                />
              </ListItem>
            </a>
            <ListItem>
              <TimerComponent timerType="session" />
            </ListItem>
            <ListItem>
              <Typography
                variant="caption"
                style={{
                  marginTop: "13%",
                  color: "#9e9e9e",
                  fontSize: "0.5rem",
                  position: "fixed",
                  right: "10px",
                  bottom: "10px",
                }}
              >
                Frontend V.:{" "}
                {`${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}
                <br />
                Backend V.: {version}
              </Typography>
            </ListItem>
          </div>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route exact path="/app/myplans/:customer_id?/:id?">
            <MyPlansComponent {...necessaryProps} />
          </Route>
          <Route exact path="/app/myplans/detailed/:customer_id/:id">
            <DetailedViewComponent {...necessaryProps} />
          </Route>
          <Route
            path="/app/fleximport"
            render={(props) => (
              <FleximportComponent {...props} {...necessaryProps} />
            )}
          />
          <Route
            path="/app/fleximportPE"
            render={(props) => (
              <FleximportComponentPE {...props} {...necessaryProps} />
            )}
          />
          <Route path="/app/users/userofcustomer">
            <UserOfCustomerComponent {...necessaryProps} userType={"UserByUser"} coloR={coloR} AngebotMaxCount={AngebotMaxCount}/>
          </Route>
          <Route path="/app/users">
            <UsermanagementComponent {...necessaryProps} coloR={coloR} AngebotMaxCount={AngebotMaxCount}/>
          </Route>
          <Route path="/app/allorders">
            <OrdersDataGridComponent {...necessaryProps} />
          </Route>
          <Route path="/app/portfoliomanagement">
            <PfmComponent {...necessaryProps} role={role} />
          </Route>
          <Route path="/app/pricedevelopment">
            <PriceDevelopment {...necessaryProps} />
          </Route>
          <Route path="/app/myprofile">
            <ProfileComponent {...necessaryProps} />
          </Route>
        </Switch>
      </main>
    </div>
  );
}

export default HomeView;
