import React from "react";
// import ReactDOM from "react-dom";
import {createRoot} from "react-dom/client";
import App from "./views/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "services/helpers/Theme";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { de } from "date-fns/locale";
import { store } from "./services/helpers/store";
import { format } from "date-fns";
import ErrorBoundary from "components/ErrorBoundary";
// import LogRocket from "logrocket";

Date.prototype.toJSON = function () {
    return format(this, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
};

// LogRocket.init("vk8jml/emdacs");
const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </Provider>,
    </>
    // document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
