import React, { useEffect, useState, useCallback } from "react";

import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
  Grid,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import { lang } from "lang";
import { Redirect } from "react-router";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useDispatch } from "react-redux";
import modalActions from "../actions/modal.actions";
import CreateForecastComponent from "./CreateForecast";

import snackbarActions from "../actions/snackbar.actions";
import { useSelector } from "../services/helpers/useSelector";
import forecastActions from "../actions/forecast.actions";
import ForecastService from "../services/forecast/ForecastService";
import AuthService from "../services/auth/AuthService";

const ForecastInfoABKCalender = (props) => {
  const role = AuthService.getRole();
  // console.log("props", props);

  const forecastService = new ForecastService();

  const locale = localStorage.locale;
  const dispatch = useDispatch();
  const showLocalLang = lang[locale].add === "Add" ? "en-EN" : "de-DE";
  const [selectedDates, setSelectedDates] = useState<any>([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState<any>(
    "Handelstäglich"
  );
  const selectedCustomer = useSelector(
    (state) => state.customer["customerData"]["id"]
  );
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedWeekDay, setSelectedWeekDay] = useState<number>(0);
  const [WeekDayForward, setWeekDayForward] = useState<number>(0);
  const [MonthForward, setMonthForward] = useState<number>(0);
  const [YearForward, setYearForward] = useState<number>(0);
  const [redirect, setRedirect] = useState(false);
  const [selectedMonthlyDate, setSelectedMonthlyDate] = useState<number>(0);
  const { forecast, constants } = useSelector((state) => state);
  const { forecastType } = forecast.data;
  const loadcurveLenght = useSelector((state) => state);
  function isSameDate(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }
  // console.log("selectedDate", selectedDates);
  // console.log("selectedMonthlyDate", selectedMonthlyDate);

  //closes modal
  const closeModal = useCallback(() => {
    dispatch(forecastActions.reset());
    dispatch(modalActions.closeModal());
  }, [dispatch]);

  const convertedDates = props.CalenderArray.map((dateString) => new Date(dateString));
//   function handleSubmit(): void {
//     // handleClose();
//     //input check
//     if (
//       !forecastService.checkLoadcurveUploadButtonDisabled(
//         forecast.data,
//         forecastType,
//         constants
//       ).disabled
//     ) {
//       if (forecastType === 1 || forecastType === 2 || forecastType === 8) {
//         //just redirect, forecast is saved to the state
//         setRedirect(true);
//       } else {
//         //directly dispatch
//         dispatch(
//           forecastActions.create(
//             role.simple_role === "LIEFERANT" ? selectedCustomer : undefined
//           )
//         );
//       }
//     }
//     // setBtnSubmitText(lang[locale].processing);
//     // setBtnDisable(true);
//   }

//   useEffect(() => {
//     //if the request is complete and no error is given, open a snackbar
//     if (forecast.finishedRequest && forecast.error === undefined) {
//       dispatch(
//         snackbarActions.openSnackbar(
//           lang[locale].forecastSuccessfullyCreated,
//           "success"
//         )
//       );
//       dispatch(forecastActions.reset());
//       closeModal();
//     }
//   }, [closeModal, dispatch, forecast.error, forecast.finishedRequest, locale]);

//   const isHoliday = (x) =>
//     props.holidays.find(
//       (item) =>
//         item.toString().split(" ").slice(0, 4).join(" ") ===
//         x.toString().split(" ").slice(0, 4).join(" ")
//     );

//   const isWeekend = (date) => {
//     const day = date.getDay();
//     return day === 0 || day === 6;
//   };

//   const selectHandler = (e) => {
//     e.target.select();
//   };

  function handleClickClose() {
    // dispatch(modalActions.openModal(CreateForecastComponent, {}));
    handleClose();
  }

//   function handleClickWeiter() {
//     dispatch(
//       modalActions.openModal(CreateForecastComponent, {
//         selectedDates: selectedDates,
//       })
//     );
    // handleClose();
//   }
//   const autoSelectMonthlyDate = (calendar) => {
//     const validDatesArray = calendar.filter(
//       (date) => date.getDate() === selectedMonthlyDate && !isWeekend(date)
//     );

//     selectedDates(validDatesArray);
//   };

//   const autoSelectValidWeekDay = (calendar) => {
//     const validDatesArray = calendar.filter((date) => date);
//   };

//   const handleWeekDayChange = (e) => {
//     setSelectedWeekDay(e.target.value);
//   };

  function handleClose() {
    dispatch(modalActions.closeModal());
  }

//   const handleMonthlyDateChange = (e) => {
//     if (e.target.value > 28 || e.target.value < 0) {
//       return null;
//     }
//     if (e.target.value < 29 && e.target.value > 0) {
//       setSelectedMonthlyDate(e.target.value);
//     }
//   };

//   function generateWeekDayInRange() {
//     if (props.bewirtDatumStart && props.bewirtDatumEnd) {
//       const tempMondays = [];
//       let counter = 0;
//       const currentDate = new Date(props.bewirtDatumStart);

//       if (WeekDayForward > 0) {
//         currentDate.setDate(currentDate.getDate() + WeekDayForward * 7);
//       }

//       while (currentDate <= props.bewirtDatumEnd) {
//         if (currentDate.getDay() === selectedWeekDay) {
//           // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
//           // if (counter < 10) {
//           //@ts-ignore
//           tempMondays.push(new Date(currentDate));
//           counter++;
//           // }
//         }
//         currentDate.setDate(currentDate.getDate() + 1);
//       }

//       setSelectedDates(tempMondays);
//     }
//   }

//   const filteredArray = selectedDates.filter(
//     (date) =>
//       !props.holidays.some(
//         (secondDate) =>
//           date.toString().split(" ").slice(0, 4).join(" ") ===
//           secondDate.toString().split(" ").slice(0, 4).join(" ")
//       )
//   );

//   function generateDailySelectInRange() {
//     if (props.bewirtDatumStart && props.bewirtDatumEnd) {
//       const tempMondays = [];
//       // let counter = 0;
//       const currentDate = new Date(props.bewirtDatumStart);

//       while (currentDate <= props.bewirtDatumEnd) {
//         // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
//         !(
//           currentDate.getDay() === 0 ||
//           currentDate.getDay() === 6 ||
//           isHoliday(currentDate.getDay()) !== undefined
//         ) &&
//           //@ts-ignore
//           tempMondays.push(new Date(currentDate));
//         // counter++;

//         currentDate.setDate(currentDate.getDate() + 1);
//       }

//       setSelectedDates(tempMondays);
//     }
//   }

//   useEffect(() => {
//     dispatch(forecastActions.saveForecastSingle("calendar", selectedDates.filter(
//       (date) =>
//         !props.holidays.some(
//           (secondDate) =>
//             date.toString().split(" ").slice(0, 4).join(" ") ===
//             secondDate.toString().split(" ").slice(0, 4).join(" ")
//         ))));
//     dispatch(forecastActions.saveForecastSingle("tranche", selectedDates.filter(
//       (date) =>
//         !props.holidays.some(
//           (secondDate) =>
//             date.toString().split(" ").slice(0, 4).join(" ") ===
//             secondDate.toString().split(" ").slice(0, 4).join(" ")
//         )).length));
//   }, [selectedDates]);

//   const generateMonthlyDateInRange = () => {
//     if (props.bewirtDatumStart && props.bewirtDatumEnd) {
//       const tempMondays = [];
//       let counter = 0;
//       const currentDate = new Date(props.bewirtDatumStart);

//       if (MonthForward > 0) {
//         currentDate.setMonth(currentDate.getMonth() + MonthForward);
//       }

//       while (currentDate <= props.bewirtDatumEnd) {
//         if (
//           currentDate.getDate() === Number(selectedMonthlyDate) &&
//           currentDate.getDay() === 0
//         ) {
//           // if (counter < 10) {
//           //@ts-ignore
//           tempMondays.push(new Date(currentDate.setDate(currentDate.getDate() + 1)));
//           counter++;
//           // }
//         }
//         // if( currentDate.getDate() === Number(selectedMonthlyDate) &&
//         //  holidays.filter((item)=> item.toString().split(" ").slice(0, 4).join(" ") ===
//         // currentDate.getDate().toString().split(" ").slice(0, 4).join(" "))){
//         //   //@ts-ignore
//         //   tempMondays.push(new Date(currentDate.setDate(currentDate.getDate() + 1)));
//         // }

//         if (
//           currentDate.getDate() === Number(selectedMonthlyDate) &&
//           currentDate.getDay() === 6
//         ) {
//           // if (counter < 10) {
//           //@ts-ignore
//           tempMondays.push(new Date(currentDate.setDate(currentDate.getDate() + 2)));
//           counter++;
//           // }
//         } else if (
//           currentDate.getDate() === Number(selectedMonthlyDate) &&
//           !(currentDate.getDay() === 0 || currentDate.getDay() === 6)
//         ) {
//           // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
//           // if (counter < 10) {
//           //@ts-ignore
//           tempMondays.push(new Date(currentDate));
//           counter++;
//           // }
//         }
//         currentDate.setDate(currentDate.getDate() + 1);
//       }

//       setSelectedDates(tempMondays);
//     }
//   };

//   useEffect(() => {
//     if (selectedMonthlyDate > 0) {
//       generateMonthlyDateInRange();
//     }
//   }, [selectedMonthlyDate, MonthForward]);

//   useEffect(() => {
//     if (selectedCheckbox === "Handelstäglich") {
//       generateDailySelectInRange();
//     }
//   }, [selectedCheckbox]);

//   useEffect(() => {
//     if (selectedWeekDay > 0) {
//       generateWeekDayInRange();
//     }
//   }, [selectedWeekDay, WeekDayForward]);

//   const handleDayClick = (day, monthIndex, e) => {
//     const element = e.target;
//     const computedStyle = window.getComputedStyle(element);
//     const cursorStyle = computedStyle.getPropertyValue("cursor");
//     if (cursorStyle === "pointer") {
//       const dateToRemove = new Date(
//         getDateFromMonthAndDay(year, monthIndex + 1, day)
//       );
//       if (
//         filteredArray.find(
//           (item) =>
//             item.toString().split(" ").slice(0, 4).join(" ") ===
//             getDateFromMonthAndDay(year, monthIndex + 1, day)
//               .toString()
//               .split(" ")
//               .slice(0, 4)
//               .join(" ")
//         )
//       ) {
//         const updatedNumbers = filteredArray.filter(
//           (n) => !isSameDate(n, dateToRemove)
//         );
//         setSelectedDates(updatedNumbers);
//       } else {
//         setSelectedDates([
//           ...filteredArray,
//           getDateFromMonthAndDay(year, monthIndex + 1, day),
//         ]);
//       }
//     }
//   };
//   const handleCheckboxChange = (event) => {
//     const value = event.target.value;
//     setSelectedCheckbox(value === selectedCheckbox ? null : value);
//     handleReset();
//   };

  // console.log("selectedCheckbox", selectedCheckbox);
  // console.log("seleccteeedddd", selectedWeekDay);

  function handleReset() {
    setSelectedDates([]);
    setSelectedMonthlyDate(0);
    setSelectedWeekDay(0);
    setYear(new Date().getFullYear());
    setWeekDayForward(0);
  }

  // Helper function to get the first day of the month
  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  function getDateFromMonthAndDay(year, monthNumber, dayNumber) {
    // Convert month number to zero-based index (January is 0, February is 1, etc.)
    const monthIndex = monthNumber - 1;

    // Create a new Date instance with the provided year, monthIndex, and dayNumber
    const date = new Date(year, monthIndex, dayNumber);

    return date;
  }

  function getWeekDayFromMonthAndDay(year, monthNumber, dayNumber) {
    // Convert month number to zero-based index (January is 0, February is 1, etc.)
    // const monthIndex = monthNumber - 1;

    // Create a new Date instance with the provided year, monthIndex, and dayNumber
    const date = new Date(year, monthNumber, dayNumber);

    // Get the day of the week (0 for Sunday, 1 for Monday, etc.)
    const weekDayNumber = date.getDay();
    // console.log("weekDayNumber", weekDayNumber);

    // Create an array of weekday names
    const weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Return the name of the weekday
    return weekDays[weekDayNumber];
  }
  function getWeekDayIndexFromMonthAndDay(year, monthNumber, dayNumber) {
    // Convert month number to zero-based index (January is 0, February is 1, etc.)
    // const monthIndex = monthNumber - 1;

    // Create a new Date instance with the provided year, monthIndex, and dayNumber
    const date = new Date(year, monthNumber, dayNumber);

    // Get the day of the week (0 for Sunday, 1 for Monday, etc.)
    const weekDayNumber = date.getDay();
    // console.log("weekDayNumber", weekDayNumber);

    // Create an array of weekday names
    const weekDays = [0, 1, 2, 3, 4, 5, 6];

    // Return the name of the weekday
    return weekDays[weekDayNumber];
  }

  // Helper function to get the number of days in the month
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const createDaysArray = (year, month) => {
    const daysInMonth = getDaysInMonth(year, month);
    return Array.from({ length: daysInMonth }, (_, i) => i + 1);
  };

  return (
    <div className="yearly-calendar">
        {redirect && (
        <Redirect
          to={{
            pathname: "/app/fleximport",
            state: { customer: selectedCustomer },
          }}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* {YearForward > 0 && ( */}
          <KeyboardArrowLeftIcon
            fontSize="large"
            onClick={() => {
              // if (YearForward > 0) {
                setYearForward((prev) => prev - 1);
                setYear((prev) => prev - 1);
              // }
            }}
          />
        {/* )} */}

        <h1 style={{ margin: "2rem 1rem" }}>
          Autom. Bewirtschaftungskalender {year}
        </h1>
        <KeyboardArrowRightIcon
          fontSize="large"
          onClick={() => {
            setYearForward((prev) => prev + 1);
            setYear((prev) => prev + 1);
          }}
        />
      </div>
      <div className="calendar-grid">
        {Array.from({ length: 12 }, (_, i) => i).map((monthIndex) => (
          <div key={monthIndex} className="month">
            <h2>
              {new Date(year, monthIndex).toLocaleString(showLocalLang, {
                month: "long",
              })}
            </h2>
            <div className="days-grid">
              {Array.from({ length: 7 }, (_, i) => i).map((dayIndex) => (
                <div key={dayIndex} className="calendar-header">
                  {new Date(year, monthIndex, dayIndex + 1).toLocaleString(
                    showLocalLang,
                    {
                      weekday: "short",
                    }
                  )}
                </div>
              ))}
              {createDaysArray(year, monthIndex).map((day, index) => (
                <div
                  key={index}
                  className={`day ${day === 0 ? "empty" : ""} 
                  ${
                    getDateFromMonthAndDay(year, monthIndex + 1, day + 1) <
                      props.bewirtDatumStart ||
                    getDateFromMonthAndDay(year, monthIndex + 1, day) >
                      props.bewirtDatumEnd
                      ? "outofRange"
                      : ""
                  }
                  ${
                    getDateFromMonthAndDay(year, monthIndex + 1, day + 1) >
                      props.bewirtDatumStart &&
                    getDateFromMonthAndDay(year, monthIndex + 1, day) <
                      props.bewirtDatumEnd &&
                    selectedWeekDay > 0 &&
                    convertedDates.find(
                      (item) =>
                        item.toString().split(" ").slice(0, 4).join(" ") ===
                        getDateFromMonthAndDay(year, monthIndex + 1, day)
                          .toString()
                          .split(" ")
                          .slice(0, 4)
                          .join(" ")
                    )
                      ? "inRange"
                      : ""
                  }
                  ${
                    getDateFromMonthAndDay(year, monthIndex + 1, day + 1) >
                      props.bewirtDatumStart &&
                    getDateFromMonthAndDay(year, monthIndex + 1, day) <
                      props.bewirtDatumEnd &&
                    selectedCheckbox === "Handelstäglich" &&
                    convertedDates.find(
                      (item) =>
                        item.toString().split(" ").slice(0, 4).join(" ") ===
                        getDateFromMonthAndDay(year, monthIndex + 1, day)
                          .toString()
                          .split(" ")
                          .slice(0, 4)
                          .join(" ")
                    )
                      ? "inRange"
                      : ""
                  }
                  ${
                    getDateFromMonthAndDay(year, monthIndex + 1, day + 1) >
                      props.bewirtDatumStart &&
                    getDateFromMonthAndDay(year, monthIndex + 1, day) <
                      props.bewirtDatumEnd &&
                    selectedMonthlyDate > 0 &&
                    convertedDates.find(
                      (item) =>
                        item.toString().split(" ").slice(0, 4).join(" ") ===
                        getDateFromMonthAndDay(year, monthIndex + 1, day)
                          .toString()
                          .split(" ")
                          .slice(0, 4)
                          .join(" ")
                    )
                      ? "inRange"
                      : ""
                  }
                   ${
                     convertedDates.find(
                       (item) =>
                         item.toString().split(" ").slice(0, 4).join(" ") ===
                         getDateFromMonthAndDay(
                           year,
                           monthIndex + 1,
                           day
                         ).toString().split(" ").slice(0, 4).join(" ")
                     )
                       ? "selected"
                       : ""
                   }
                  ${
                    convertedDates.length === 9000
                      ? "completed deactive fullactive"
                      : ""
                  }
                  `}
                //   onClick={(e) => handleDayClick(day, monthIndex, e)}
                >
                  {day > 0 ? day : ""}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <Button color="primary" style={{marginTop : "2rem"}} onClick={handleClickClose}>
        {lang[locale].cancel}
      </Button>
    </div>
  );
};

export default ForecastInfoABKCalender;
