import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from '@redux-devtools/extension';
import rootReducer from "../../reducers/root.reducer";
import thunkMiddleware from "redux-thunk";
import thunk from "redux-thunk";

const customMiddleWare = (store) => (next) => (action) => {
    // console.log("Middleware triggered:", action, action.status);
    next(action);
};

export const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(thunkMiddleware, thunk, customMiddleWare)
    )
);
