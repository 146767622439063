import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Grid, TextField } from "@material-ui/core";
import { lang } from "../lang";
import { useDispatch } from "react-redux";
import TwoDecimalComponent from "./TwoDecimal";
import snackbarActions from "../actions/snackbar.actions";
import UserService from "../services/users/UserService";
import { useHistory } from "react-router-dom";

const service = new UserService();

function GlobalChargeComponent(props) {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [globalStromPreisF, setglobalStromPreisF] = useState<number>(0);
  const [globalStromPreisE, setglobalStromPreisE] = useState<number>(0);
  const [globalGasSpotE, setglobalGasSpotE] = useState<number>(0);
  const [globalGasSpotF, setglobalGasSpotF] = useState<number>(0);
  const [globalStromSpotE, setglobalStromSpotE] = useState<number>(0);
  const [globalStromSpotF, setglobalStromSpotF] = useState<number>(0);
  const [globalGasPreisF, setglobalGasPreisF] = useState<number>(0);
  const [globalGasPreisE, setglobalGasPreisE] = useState<number>(0);
  const dispatch = useDispatch();
  const locale = localStorage.locale;
  // @ts-ignore
  const permissions = localStorage.getItem("role").includes("PERMISSIONS");

  const selectHandler = (e) => {
    e.target.select();
  };

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    service
      .updateSupplierCharge([
        {
          type: "F",
          commodity: 1,
          value: globalStromPreisF,
          pricing_type: 'PREISKURVE'
        },
        {
          type: "E",
          commodity: 1,
          value: globalStromPreisE,
          pricing_type: 'PREISKURVE'
        },
        {
          type: "F",
          commodity: 2,
          value: globalGasPreisF,
          pricing_type: 'PREISKURVE'
        },
        {
          type: "E",
          commodity: 2,
          value: globalGasPreisE,
          pricing_type: 'PREISKURVE'
        },
        {
          type: "E",
          commodity: 2,
          value: globalGasSpotE,
          pricing_type: 'SPOT'
        },
        {
          type: "F",
          commodity: 1,
          value: globalStromSpotF,
          pricing_type: 'SPOT'
        },
        {
          type: "E",
          commodity: 1,
          value: globalStromSpotE,
          pricing_type: 'SPOT'
        },
        {
          type: "F",
          commodity: 2,
          value: globalGasSpotF,
          pricing_type: 'SPOT'
        },
      ])
      .then(() => {
        setLoading(false);
        dispatch(
          snackbarActions.openSnackbar(
            lang[locale].newChargesSuccess,
            "success"
          )
        );
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        setLoading(false);
        console.debug("Changing charges failed", e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  }

  useEffect(() => {
    service
      .getSupplierInfo()
      .then((res) => {
        const { charge } = res;
        setglobalStromPreisE(charge.find(i=> i.type === "E" && i.commodity === 1 && i.pricing_type === "PREISKURVE" ).value);
        setglobalStromSpotE(charge.find(i=> i.type === "E" && i.commodity === 1 && i.pricing_type === "SPOT" ).value);
        setglobalGasPreisE(charge.find(i=> i.type === "E" && i.commodity === 2 && i.pricing_type === "PREISKURVE" ).value);
        setglobalGasSpotE(charge.find(i=> i.type === "E" && i.commodity === 2 && i.pricing_type === "SPOT" ).value);
        setglobalStromPreisF(charge.find(i=> i.type === "F" && i.commodity === 1 && i.pricing_type === "PREISKURVE" ).value);
        setglobalStromSpotF(charge.find(i=> i.type === "F" && i.commodity === 1 && i.pricing_type === "SPOT" ).value);
        setglobalGasPreisF(charge.find(i=> i.type === "F" && i.commodity === 2 && i.pricing_type === "PREISKURVE" ).value);
        setglobalGasSpotF(charge.find(i=> i.type === "F" && i.commodity === 2 && i.pricing_type === "SPOT" ).value);
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  }, []);

  useEffect(() => {
    const enterBlock = (event) => {
      if (
        event.which === 13 &&
        (event.location === 3 || event.location === 0)
      ) {
        event.preventDefault();
        dispatch(
          snackbarActions.openSnackbar(
            lang[locale].chargeOnlyWithClick,
            "warning"
          )
        );
      }
    };
    document.addEventListener("keydown", enterBlock);
    return () => {
      document.removeEventListener("keydown", enterBlock);
    };
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container direction="row" spacing={6}>
          <Grid item xs={6}>
            <TextField
              onFocus={selectHandler}
              color="primary"
              required
              fullWidth
              id="globalStromPreisF"
              value={globalStromPreisF}
              onChange={(e) => setglobalStromPreisF(e.target.value !== undefined ? parseFloat(e.target.value) : 0)}
              name="globalStromPreisF"
              label={lang[locale].globalStromF}
              InputProps={{
                inputComponent: TwoDecimalComponent,
              }}
            />
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <TextField
              onFocus={selectHandler}
              color="primary"
              required
              fullWidth
              id="globalStromPreisE"
              value={globalStromPreisE}
              onChange={(e) => setglobalStromPreisE(e.target.value !== undefined ? parseFloat(e.target.value) : 0)}
              name="globalStromE"
              label={lang[locale].globalStromE}
              InputProps={{
                inputComponent: TwoDecimalComponent,
              }}
            />
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <TextField
              onFocus={selectHandler}
              color="primary"
              required
              fullWidth
              id="globalStromSpotF"
              value={globalStromSpotF}
              onChange={(e) => setglobalStromSpotF(e.target.value !== undefined ? parseFloat(e.target.value) : 0)}
              name="globalStromSpotF"
              label={lang[locale].globalStromSpotF}
              InputProps={{
                inputComponent: TwoDecimalComponent,
              }}
            />
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <TextField
              onFocus={selectHandler}
              color="primary"
              required
              fullWidth
              id="globalStromSpotE"
              value={globalStromSpotE}
              onChange={(e) => setglobalStromSpotE(e.target.value !== undefined ? parseFloat(e.target.value) : 0)}
              name="globalStromSpotE"
              label={lang[locale].globalStromSpotE}
              InputProps={{
                inputComponent: TwoDecimalComponent,
              }}
            />
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <TextField
              onFocus={selectHandler}
              color="secondary"
              required
              fullWidth
              id="globalGasPreisF"
              value={globalGasPreisF}
              onChange={(e) => setglobalGasPreisF(e.target.value !== undefined ? parseFloat(e.target.value) : 0)}
              name="globalGasPreisF"
              label={lang[locale].globalGasF}
              InputProps={{
                inputComponent: TwoDecimalComponent,
              }}
            />
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <TextField
              onFocus={selectHandler}
              color="secondary"
              required
              fullWidth
              id="globalGasPreisE"
              value={globalGasPreisE}
              onChange={(e) => setglobalGasPreisE(e.target.value !== undefined ? parseFloat(e.target.value) : 0)}
              name="globalGasPreisE"
              label={lang[locale].globalGasE}
              InputProps={{
                inputComponent: TwoDecimalComponent,
              }}
            />
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <TextField
              onFocus={selectHandler}
              color="secondary"
              required
              fullWidth
              id="globalGasSpotF"
              value={globalGasSpotF}
              onChange={(e) => setglobalGasSpotF(e.target.value !== undefined ? parseFloat(e.target.value) : 0)}
              name="globalGasSpotF"
              label={lang[locale].globalGasSpotF}
              InputProps={{
                inputComponent: TwoDecimalComponent,
              }}
            />
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <TextField
              onFocus={selectHandler}
              color="secondary"
              required
              fullWidth
              id="globalGasSpot"
              value={globalGasSpotE}
              onChange={(e) => setglobalGasSpotE(e.target.value !== undefined ? parseFloat(e.target.value) : 0)}
              name="globalGasSpot"
              label={lang[locale].globalGasSpotE}
              InputProps={{
                inputComponent: TwoDecimalComponent,
              }}
            />
          </Grid>

          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              // color="primary"
              style={{backgroundColor : !permissions ? "#595959" : props.coloR.primary, color : !permissions ? "#898989" : "white"}}
              type="submit"
              data-testid="submitReset"
              disabled={!permissions}
            >
              {loading ? <CircularProgress size={30} /> : lang[locale].send}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default GlobalChargeComponent;
