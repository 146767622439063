import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import modalActions from "../actions/modal.actions";

import {
  Paper,
  Typography,
  Grid,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  FormGroup,
  Checkbox,
} from "@material-ui/core";
import { KeyboardDatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import "style/contextmenu.css";
import MTBComponent from "./ManuelTranchenBeschaffung";
import ABKFieldsComponent from "./ABKFields";
import ContextData from "../typeDefs/ContextData";
import JsonReturn from "../typeDefs/JsonReturn";
import ForecastService from "../services/forecast/ForecastService";
import UserService from "../services/users/UserService";
import snackbarActions from "../actions/snackbar.actions";
import { lang } from "../lang";
import { useSelector } from "../services/helpers/useSelector";
import TableComponent from "./Table";
import UploadFormularComponent from "./UploadFormular";
import forecastActions from "../actions/forecast.actions";
import SelectDataPeriodComponent from "./SelectDataPeriod";
import FleximportValueCardComponent from "./FleximportValueCard";
import FleximportData from "../typeDefs/FleximportData";
import ContextmenuComponent from "./Contextmenu";
import TimedifferenceChecksComponent from "./TimedifferenceChecks";
import SelectUnitComponent from "./SelectUnit";
import ShowValuesComponent from "./ShowValues";
import SelectPriceCurveComponent from "./SelectPriceCurve";
import { Redirect } from "react-router";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import StyledGasPowerSwitch from "./StyledGasPowerSwitch";
import AuthService from "../services/auth/AuthService";
import authActions from "../actions/auth.actions";
import { Tooltip } from "@material-ui/core";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import FormelPriceComponent from "./FormelPrice";
import { differenceInDays, add, subDays } from "date-fns";

const service = new ForecastService();

/**
 * Component for creating imports for various file formats
 * @constructor
 */
function FleximportComponent({ changeNavTitle, ...props }) {

  const Userservice = new UserService();
  const dispatch = useDispatch();
  const preisType = useSelector((state) => state.forecast.data.pricing_type);
  const priceCurve = useSelector((state) => state.forecast.data.priceCurve);
  const FlexImportRowsLength = useSelector(
    (state) => state.forecast.fleximportData.rows.length
  );
  const customerP = useSelector(
    (state) => state.customer["customerData"]["permissions"]
  );
  const { forecast } = useSelector((state) => state);
  const {
    max_usage_mwh_gas_rlm,
    max_usage_mwh_power_rlm,
    min_usage_mwh_gas_rlm,
    min_usage_mwh_power_rlm,
  } = useSelector((state) => state.constants);
  const customerPerm = customerP ? Object.values(customerP) : [];
  const toDay = new Date();
  const tommorow = add(toDay, { days: 1 });
  let endYear = toDay.getFullYear() + 4;
  let endOfNextFourYears = new Date(endYear, 11, 31);
  //todo show a switch if nothing is set in redux state

  //Show if gas is true or false
  const gas = useSelector((state) => state.forecast.data.gas);
  const customerType = useSelector(
    (state) => state.customer["customerData"]["type"]
  );

  //only for inputs
  const [startValueInput, setStartValueInput] = useState<string>("");
  const [endValueInput, setEndValueInput] = useState<string>("");
  const [gasSwitchDisabled, setGasSwitchDisabled] = useState(false);
  const [back, setBack] = useState(false);
  const [minDate, setMinDate] = useState<Date>(new Date());
  const [maxDate, setMaxDate] = useState<Date>(new Date());
  const [redirect, setRedirect] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [fileName, setFileName] = useState<string>("");
  const [cust, setCust] = useState<number>(0);
  const [ErrorTrigger, setErrorTrigger] = useState<boolean>(false);
  const [onlyGas, setOnlyGas] = useState(false);
  const [onlyPower, setOnlyPower] = useState(false);
  const [btndisable, setBtnDisable] = useState(false);
  const [btnSubmitText, setBtnSubmitText] = useState("");
  const tranche = useSelector((state) => state.forecast.data?.tranche);
  const [TrancheDisable, setTrancheDisable] = useState(false);
  const [isCommoditypass, setIsCommoditypass] = useState(true);
  const [Angebotsende, setAngebotsende] = useState<Date | undefined>(undefined);
  const [ATB, setATB] = useState(false);
  const [MTB, setMTB] = useState(false);
  const [handleDays,setHandleDays] = useState<any[]>([]);
  const [preiskurveListe, setPreiskurveListe] = useState(undefined);
  const [manuellKaufType, setManuellKaufType] = useState<any>("");
  const [ohneAllRechte, setOhneAllRechte] = useState(false);
  const [maxTranchen, setMaxTranchen] = useState(1);
  const formula = useSelector((state) => state.forecast.data.formula);
  const divideAmount = useSelector(
    (state) => state.forecast.data.divide_amount
  );
  const ciSettings = useSelector(
    (state) => state.ciSettings
  );
  const DiffDays = useSelector(
    (state) => state.forecast.fleximportData.dateDifference
  );
  const DiffDaysProStart = useSelector((state) => state.forecast.data.progFrom);
  const DiffDaysProEnd = useSelector((state) => state.forecast.data.progTo);

  const DiffDaysPro = differenceInDays(DiffDaysProEnd, DiffDaysProStart);

  const DiffDaysIst = useSelector(
    (state) => state.forecast.fleximportData.dateDifference
  );

  const trancheRlm = tranche && tranche > 0 ? tranche : 1;
  const [energyError, setEnergyError] = useState("");

  const locale = localStorage.locale;
  const role = AuthService.getRole();

  const [showCheckboxes, setShowCheckboxes] = useState(true);
  const stateData = useSelector((state) => state.forecast);
  // const [DFC, setDFC] = useState(false);
  //state data only for fleximports
  const fleximportData = useSelector((state) => state.forecast.fleximportData);

  //To what should be scrolled
  const [scrollTo, setScrollTo] = React.useState({
    first: 0,
    second: 0,
  });
  const [fromPageBestehende, setFromPageBestehende] = useState("");

  const handleInput = (event) => {
    // Get the entered value and convert it to a number
    const inputValue = event.target.value;

    // Check if the entered value is greater than 10
    if (tranche && inputValue > tranche) {
      // If greater than 10, set the value to 10
      event.target.value = tranche;
    }
  };

  const handlePaste = (event) => {
    event.preventDefault(); // Prevent the default paste behavior
  };

  useEffect(() => {
    dispatch(authActions.checkLogin());
  }, []);

  useEffect(() => {
    if (ATB === true) {
      setMTB(false);
      dispatch(forecastActions.saveForecastSingle("order_type", "AUTO"));
      dispatch(forecastActions.saveForecastSingle("tranche", 1));
      setMaxTranchen(1);
    }
    // else if (ATB === false && MTB === false) {
    //   dispatch(forecastActions.saveForecastSingle("order_type", "MANUAL"));
    // }
  }, [ATB]);

  useEffect(() => {
    if (MTB === true && (!role.permissions.includes("DELAYED_TRADING") && role.permissions.includes("TRADING"))) {
      setATB(false);
      dispatch(forecastActions.saveForecastSingle("order_type", "MANUAL"));
      setMaxTranchen(1);
    }

    if (MTB === true && (role.permissions.includes("DELAYED_TRADING") && !role.permissions.includes("TRADING"))) {
      // setATB(false);
      dispatch(forecastActions.saveForecastSingle("order_type", "DELAYED_AUTO"));
      setMaxTranchen(1);
    }

    if (MTB === true && (role.permissions.includes("DELAYED_TRADING") && role.permissions.includes("TRADING"))) {
      setATB(false);
    }

  }, [MTB]);

  useEffect(() => {

    if(manuellKaufType === "MANUAL"){
      dispatch(forecastActions.saveForecastSingle("order_type", "MANUAL"));
    }

    if(manuellKaufType === "DELAYED"){
      dispatch(forecastActions.saveForecastSingle("order_type", "DELAYED_AUTO"));
    }
    
  }, [manuellKaufType]);

  useEffect(() => {
    Userservice.getSupplierBuyTimes().then((res) => setHandleDays(res));
  }, []);

  useEffect(() => {
    !role.permissions.includes("DELAYED_TRADING") &&  !role.permissions.includes("TRADING") && !role.permissions.includes("AUTO_TRADING") && dispatch(forecastActions.saveForecastSingle("order_type", "MANUAL")); setOhneAllRechte(true);
  }, []);

  useEffect(() => {
    if (props.location.state.ATB !== undefined && props.location.state.ATB === "AUTO") {
      dispatch(forecastActions.saveForecastSingle("order_type", "AUTO"));
      // dispatch(forecastActions.saveForecastSingle("tranche", 1));
      // setMaxTranchen(1);
    } else if (props.location.state.ATB === "MANUAL") {
      dispatch(forecastActions.saveForecastSingle("order_type", "MANUAL"));
    }
  }, [props.location.state.ATB]);

  function handleChangeAngebotsende(e) {
    setAngebotsende(e);
    dispatch(forecastActions.saveForecastSingle("accept_to", e));
    dispatch(forecastActions.saveForecastSingle("accept_from", toDay));
  }

  // const TextFieldComponent = (props) => {
  //   return <TextField {...props} disabled={true} />
  // }

  function handleChangeMehrfachTranche(e) {
    setMaxTranchen(Number(e.target.value));

    dispatch(forecastActions.saveForecastSingle("max_order_tranche", Number(e.target.value)));
  }

  /**
   * Scrolls to a certain id
   * @param tableString either first or second
   * @param row the row to scroll to
   */
  function handleScrollTo(tableString: "first" | "second", row: number) {
    setScrollTo({
      ...scrollTo,
      [tableString]: row,
    });
  }

  const sendErrorFromChild = (Err) => {
    console.log("errlength", Err.length)
    if (Err.length === undefined) {
      setErrorTrigger(false);
    } else if (Err.length === 5) {setErrorTrigger(true);  console.log("errorr")}
  };

  /**
   * resets scroll to the standard
   */
  function resetScroll() {
    setScrollTo({
      first: 0,
      second: fleximportData.rows.length - 1,
    });
  }
  function setMinMax(min: string, max: string) {
    setMinDate(new Date(min));
    setMaxDate(new Date(max));
  }

  useEffect(()=>{
    Userservice.getSupplierBuyTimes().then(res=>setHandleDays(res))     
  },[])

  function checkUsage(val: number): string[] {
    let check: string[] = [];
    if (
      forecast.data &&
      forecast.data.forecastType === 1 &&
      forecast.data.pricing_type?.toLowerCase().indexOf("spot") === -1
    ) {
      if (DiffDaysIst) {
        if (
          gas !== true &&
          (((val / 1000) * (DiffDaysPro / DiffDaysIst)) / trancheRlm <
            min_usage_mwh_power_rlm ||
            ((val / 1000) * (DiffDaysPro / DiffDaysIst)) / trancheRlm >
              max_usage_mwh_power_rlm)
        ) {
          check.push(
            lang[locale].flexTranche +
              (
                ((val / 1000) * (DiffDaysPro / DiffDaysIst)) /
                trancheRlm
              ).toFixed(2) +
              " MWh"
          );
        }
        if (
          gas === true &&
          (((val / 1000) * (DiffDaysPro / DiffDaysIst)) / trancheRlm <
            min_usage_mwh_gas_rlm ||
            ((val / 1000) * (DiffDaysPro / DiffDaysIst)) / trancheRlm >
              max_usage_mwh_gas_rlm)
        ) {
          check.push(
            lang[locale].flexTranche +
              (
                ((val / 1000) * (DiffDaysPro / DiffDaysIst)) /
                trancheRlm
              ).toFixed(2) +
              " MWh"
          );
        }
      }
    }
    if (
      forecast.data &&
      forecast.data.forecastType === 0 &&
      forecast.data.pricing_type?.toLowerCase().indexOf("spot") === -1
    ) {
      if (
        gas === true &&
        (val / 1000 / trancheRlm < min_usage_mwh_gas_rlm ||
          val / 1000 / trancheRlm > max_usage_mwh_gas_rlm)
      ) {
        check.push(
          lang[locale].flexTranche +
            (val / 1000 / trancheRlm).toFixed(2) +
            " MWh"
        );
      }
      if (
        gas !== true &&
        (val / 1000 / trancheRlm < min_usage_mwh_power_rlm ||
          val / 1000 / trancheRlm > max_usage_mwh_power_rlm)
      ) {
        check.push(
          lang[locale].flexTranche +
            (val / 1000 / trancheRlm).toFixed(2) +
            " MWh"
        );
      }
    }
    return check;
  }

  // //power min
  // if (tranche && tranche > 1 && divideAmount === true) {
  //   if (
  //     gas !== true &&

  //     (val / 1000 / tranche < min_usage_mwh_power_rlm ||
  //     val / 1000 / tranche > max_usage_mwh_power_rlm)
  //   ) {
  //     check.push(
  //       lang[locale].flexTranche +
  //         (val / 1000 / tranche).toFixed(2) +
  //         " MWh"
  //     );
  //   }
  //   if (
  //     gas === true &&

  //     (val / 1000 / tranche < min_usage_mwh_gas_rlm ||
  //     val / 1000 / tranche > max_usage_mwh_gas_rlm)
  //   ) {
  //     check.push(
  //       lang[locale].flexTranche +
  //         (val / 1000 / tranche).toFixed(2) +
  //         " MWh"
  //     );
  //   }
  // } else {
  //   if (
  //     gas !== true && DiffDays && DiffDays > 365 &&

  //     (val / 1000  < min_usage_mwh_power_rlm ||
  //     val / 1000  > max_usage_mwh_power_rlm)
  //     ) {
  //       check.push(lang[locale].flexTranche + val / 1000 + " MWh");
  //     }
  //     if (
  //       gas === true && DiffDays && DiffDays > 365 &&

  //       (val / 1000 < min_usage_mwh_gas_rlm ||
  //       val / 1000 > max_usage_mwh_gas_rlm)
  //       ) {
  //         check.push(lang[locale].flexTranche + val / 1000 + " MWh");
  //   }
  // }

  //use effect to apply roles
  useEffect(() => {
    // if (role.simple_role !== "LIEFERANT") {
    if (
      !(role.permissions.includes("GAS") || role.permissions.includes("STROM"))
    ) {
      setGasSwitchDisabled(true);
    }
    if (
      role.permissions.includes("STROM") &&
      !role.permissions.includes("GAS")
    ) {
      dispatch(forecastActions.saveForecastSingle("strom", true));
      dispatch(forecastActions.saveForecastSingle("gas", false));
      setOnlyPower(true);
    } else if (
      role.permissions.includes("GAS") &&
      !role.permissions.includes("STROM")
    ) {
      dispatch(forecastActions.saveForecastSingle("gas", true));
      dispatch(forecastActions.saveForecastSingle("strom", false));
      setOnlyGas(true);
    }
    // }
    // if (role.simple_role === "LIEFERANT") {
    //   if (!(customerPerm?.includes("GAS") || customerPerm?.includes("STROM"))) {
    //     setGasSwitchDisabled(true);
    //   }
    //   if (customerPerm?.includes("STROM") && !customerPerm?.includes("GAS")) {
    //     dispatch(forecastActions.saveForecastSingle("strom", true));
    //     dispatch(forecastActions.saveForecastSingle("gas", false));
    //     setOnlyPower(true);
    //   } else if (
    //     customerPerm?.includes("GAS") &&
    //     !customerPerm?.includes("STROM")
    //   ) {
    //     dispatch(forecastActions.saveForecastSingle("gas", true));
    //     dispatch(forecastActions.saveForecastSingle("strom", false));
    //     setOnlyGas(true);
    //   }
    // }
  }, []);

  //prevent pressing F5 key
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.keyCode === 116) {
        // 116 is the key code for F5
        e.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  //prevent pressing ctrl + R key
  useEffect(() => {
    const handleKeyPress = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 82) {
        e.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const selectHandler = (e) => {
    e.target.select();
  };

  //use effect to set the scrolled to thingy
  useEffect(() => {
    handleScrollTo("first", 0);
    handleScrollTo("second", fleximportData.rows.length - 1);
  }, [fleximportData]);

  //accepted drop
  const onDropAccepted = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      const fileSplit = file.name.split(".");
      setFileName(fileSplit[0] + "." + fileSplit[1]);
      const fileType = fileSplit[fileSplit.length - 1].toLowerCase();
      let data: JsonReturn;
      switch (fileType) {
        case "xlsx":
          data = await service.handleXLSXToJson(file);
          break;
        case "xls":
          return dispatch(
            snackbarActions.openSnackbar(lang[locale].wrongFileType, "error")
          );
        default:
          data = await service.handleCSVToJson(file);
          break;
      }
      if (data) {
        dispatch(forecastActions.saveFleximportSingle("rows", data.data));
        dispatch(forecastActions.saveForecastSingle("fileName", file.name));
        dispatch(forecastActions.saveFleximportSingle("cols", data.keys));
      }
    }
  };
  const onDropRejected = (e) => {
    snackbarActions.openSnackbar(lang[locale].wrongFileType, "error");
  };

  //handles the context menu
  async function handleContextClick(e, data: ContextData) {
    //try it because date formatting maybe crash

    try {
      if (data.startdate) {
        dispatch(
          forecastActions.saveFleximportSingle(
            "startDateValue",
            await service.formatDate(data.data.split(" ")[0])
          )
        );
      }
      if (data.enddate) {
        dispatch(
          forecastActions.saveFleximportSingle(
            "endDateValue",
            await service.formatDate(data.data.split(" ")[0])
          )
        );
      }
      if (data.startvalue) {
        dispatch(
          forecastActions.saveFleximportSingle("startValue", {
            data: data.data,
            colIndex: data.colIndex,
            rowIndex: data.rowIndex,
          })
        );
        setStartValueInput(data.data.toString());
      }
      if (data.endvalue) {
        dispatch(
          forecastActions.saveFleximportSingle("endValue", {
            data: data.data,
            colIndex: data.colIndex,
            rowIndex: data.rowIndex,
          })
        );
        setEndValueInput(data.data.toString());
      }
    } catch (e) {
      //if error occurs, just SNACK IT
      dispatch(
        snackbarActions.openSnackbar(lang[locale].dateSelectError, "error")
      );
    }
  }

  const selectedCustomer = props.history.location.state.customer;
  //changes given format to FPL ready json format (submit button)
  async function handleSubmit() {
    const startValue = fleximportData.startValue;
    const endValue = fleximportData.endValue;
    setCust(selectedCustomer);
    setBtnSubmitText(lang[locale].processing);
    setBtnDisable(true);
    if (
      startValue &&
      fleximportData.dateDifference &&
      fleximportData.unit &&
      endValue
    ) {
      try {
        dispatch(
          forecastActions.saveForecastSingle(
            "data",
            await service.jsonToFPLJson(fleximportData, stateData.data.gas)
          )
        );
        if (role.simple_role === "LIEFERANT") {
          dispatch(forecastActions.create(selectedCustomer));
        } else dispatch(forecastActions.create());
        setTimeout(() => {
          setRedirect(true);
        }, 30000);
      } catch (e) {
        if (e instanceof Error) {
          dispatch(
            snackbarActions.openSnackbar(
              /* lang[locale].errorCreatingForecast, */
              e.message,
              "error"
            )
          );
        } else {
          console.log("Unexpected error", e);
        }
      }
    }
  }

  function handleSubmitTrigger(set){
    if(set === 1){
      handleSubmit()
    }
  }

  function handleChangeTranche(e) {
    setMaxTranchen(1);
    if (e.target.value.length > 3) return false;
    if (e.target.value < 2) {
      dispatch(forecastActions.saveForecastSingle("divide_amount", true));
    }
    customerType === "E" && e.target.value < 1
      ? setTrancheDisable(true)
      : setTrancheDisable(false);
    dispatch(
      forecastActions.saveForecastSingle("tranche", Number(e.target.value))
    );
  }

  const handlerCheckbox = (e) => {
    if (e.target.checked) {
      dispatch(forecastActions.saveForecastSingle("divide_amount", false));
    } else dispatch(forecastActions.saveForecastSingle("divide_amount", true));
  };

  //handles switch toggle
  function handleToggle() {
    dispatch(forecastActions.saveForecastSingle("gas", !stateData.data.gas));
  }

  function handleHistory() {
    // if (state.forecast.data.name !== "") {
    //     setBack(true);
    // } else {
    //     setRedirect(true);
    // }
    setCust(selectedCustomer);
    setBack(true);
  }

  //redirects the user and shows snackbar
  useEffect(() => {
    if (stateData.error === undefined && stateData.finishedRequest) {
      dispatch(forecastActions.reset());
      //dispatch(loadcurveActions.resetState());
      dispatch(
        snackbarActions.openSnackbar(
          lang[locale].forecastSuccessfullyCreated,
          "success"
        )
      );
      setRedirect(true);
    }
  }, [stateData.finishedRequest]);
  //change title etc
  useEffect(() => {
    changeNavTitle("Fleximport");

    document.title = "Fleximport | Marketmaker";

    // console.log("In Close Modal Fleximport Component");
    dispatch(modalActions.closeModal());
  }, [changeNavTitle, dispatch]);

  useEffect(() => {
    // console.log(
    //     "Check Disabled",
    //     minDate,
    //     maxDate,
    //     fleximportData,
    //     stateData.data.forecastType
    // );
    // console.log(
    //     "checkdisabled",
    //     service.checkFleximportSendButtonDisabled(
    //         fleximportData,
    //         minDate,
    //         maxDate,
    //         stateData.data.forecastType
    //     )
    // );
    setDisabled(
      service.checkFleximportSendButtonDisabled(
        fleximportData,
        minDate,
        maxDate,
        stateData.data.forecastType
      ) ||
        TrancheDisable ||
        (forecast.data.pricing_type?.toLowerCase().indexOf("spot") !== -1 &&
          (forecast.data.forecastType !== 1 && stateData.data.forecastType !== 8) && 
          //@ts-ignore
          differenceInDays(fleximportData.startDateValue, tommorow) < 0) ||
        (forecast.data.pricing_type?.toLowerCase().indexOf("spot") !== -1 &&
          //@ts-ignore
          differenceInDays(fleximportData.endDateValue, endOfNextFourYears) >
            0) ||
            ((stateData.data.forecastType !== 1 && stateData.data.forecastType !== 8) && (Angebotsende === undefined || Angebotsende === null)) ||
            ErrorTrigger === true ||
        // //@ts-ignore
        // ((forecast.data.pricing_type?.toLowerCase().indexOf("spot") === -1) && fleximportData.startDateValue < minDate ) ||
        // //@ts-ignore
        // ((forecast.data.pricing_type?.toLowerCase().indexOf("spot") === -1) && differenceInDays(fleximportData.endDateValue, maxDate) > 0) ||
        (preisType === "FORMEL" &&
          (formula === undefined || Object.keys(formula).length === 0)) || (ATB === false && MTB === false) || (MTB && manuellKaufType === "")
    );
  }, [
    fleximportData,
    minDate,
    maxDate,
    TrancheDisable,
    preisType,
    formula,
    forecast.data,
    Angebotsende,
    ErrorTrigger,
    ATB,
    MTB
  ]);

  useEffect(() => {
    if (tranche === undefined) {
      if (customerType === "E") {
        dispatch(forecastActions.saveForecastSingle("tranche", 1));
      }
      if (customerType === "F") {
        dispatch(forecastActions.saveForecastSingle("tranche", 0));
      }
    }
  }, []);

  //calculates needed count
  useEffect(() => {
    const data: FleximportData | void = service.calculateNeededCount(
      fleximportData,
      gas
    );

    if (fleximportData.endValue && fleximportData.startValue) {
      //dispatch so that the actual and the needed count are stored in the redux state
      dispatch(
        forecastActions.saveFleximportSingle(
          "actualCount",
          fleximportData.endValue.rowIndex -
            fleximportData.startValue.rowIndex +
            1
        )
      );
    }
    if (data) {
      //set State
      dispatch(
        forecastActions.saveFleximportSingle("neededCount", data.neededCount)
      );
      dispatch(
        forecastActions.saveFleximportSingle("normalFactor", data.normalFactor)
      );
      dispatch(
        forecastActions.saveFleximportSingle("lessFactor", data.lessFactor)
      );
      dispatch(
        forecastActions.saveFleximportSingle("moreFactor", data.moreFactor)
      );
      dispatch(
        forecastActions.saveFleximportSingle(
          "dateDifference",
          data.dateDifference
        )
      );
      dispatch(
        forecastActions.saveFleximportSingle(
          "marchTimeChangeRowId",
          data.marchTimeChangeRowId
        )
      );
      dispatch(
        forecastActions.saveFleximportSingle(
          "octoberTimeChangeRowId",
          data.octoberTimeChangeRowId
        )
      );
      //if factor is daily, dont show checkboxes
      if (data.normalFactor === 1) setShowCheckboxes(false);

      if (gas && DiffDays && FlexImportRowsLength / DiffDays > 25) {
        dispatch(
          snackbarActions.openSnackbar(
            `${lang[locale].rlmcommodityconflict}`,
            "warning"
          )
        );
        setIsCommoditypass(false);
        setDisabled(true);
      }
      // if (
      //   !gas &&
      //   DiffDays &&
      //   (FlexImportRowsLength % DiffDays) < 25 &&
      //   stateData.data.forecastType === 0
      // ) {
      //   dispatch(
      //     snackbarActions.openSnackbar(
      //       `${lang[locale].rlmcommodityconflictStunde} `,
      //       "warning"
      //     )
      //   );
      // }
      if (!gas && DiffDays && (FlexImportRowsLength / DiffDays) < 25) {
        dispatch(
          snackbarActions.openSnackbar(
            `${lang[locale].rlmcommodityconflictStunde} `,
            "warning"
          )
        );
      }
      if (
        gas &&
        DiffDays &&
        FlexImportRowsLength / DiffDays > 25 &&
        stateData.data.forecastType === 0
      ) {
        dispatch(
          snackbarActions.openSnackbar(
            `${lang[locale].fleximportcommodityconflict}`,
            "warning"
          )
        );
        setIsCommoditypass(false);
        setDisabled(true);
      } else if (
        (gas && DiffDays && FlexImportRowsLength / DiffDays < 26) ||
        (!gas && DiffDays && FlexImportRowsLength / DiffDays > 25)
      ) {
        setIsCommoditypass(true);
      }
    }
  }, [
    dispatch,
    fleximportData.checked,
    gas,
    fleximportData.dateDifference,
    fleximportData.startValue,
    fleximportData.endValue,
    fleximportData.startDateValue,
    fleximportData.endDateValue,
    isCommoditypass,
  ]);

  /**
   * Calculates the right energy amount
   */
  useEffect(() => {
    const energy = service.calculateEnergyAmount(fleximportData);
    if (energy)
      if (energy && checkUsage(energy).length > 0) {
        setEnergyError(checkUsage(energy)[0]);
      } else {
        setEnergyError("");
      }
    dispatch(forecastActions.saveFleximportSingle("energy", energy));
  }, [
    dispatch,
    fleximportData.unit,
    fleximportData.startValue,
    fleximportData.endValue,
    fleximportData.startDateValue,
    fleximportData.endDateValue,
    tranche,
    divideAmount,
  ]);

  useEffect(() => {
    setFromPageBestehende("FromBestehende");
  }, []);

 
  //check if user should be redirected
  if (redirect) return <Redirect to={`/app/myplans/${cust}`} />;
  // if (back) return <Redirect to={"/app/myplans?fleximportAbort=true"} />;
  if (back) return <Redirect to={`/app/myplans/${cust}`} />;
  return (
    <>
      {fleximportData.rows.length > 0 ? (
        <Grid container spacing={4} justify="center">
          <Grid item xs={12} lg={5} style={{ marginTop: "1rem" }}>
            <Grid container spacing={4}>
              {stateData.data.forecastType === 0 ? (
                <Grid item xs={12}>
                  <Grid container spacing={4} alignItems="center">
                    {/* <Grid item xs={12}>
                      <Typography variant="h5">
                        {lang[locale].general}
                      </Typography>
                    </Grid> */}
                    {onlyPower ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "90%",
                          marginLeft: "2%",
                          marginTop: "2%",
                        }}
                      >
                        <Tooltip title={lang[locale].powerPermission}>
                          <FlashOnIcon
                            style={{ color: "#0983B1", marginRight: "30%" }}
                          />
                        </Tooltip>
                        <Grid
                          style={{
                            color: stateData.data.gas ? "#FAB515" : "#0983B1",
                          }}
                        >
                          <p
                            style={{
                              overflowWrap: "anywhere",
                            }}
                          >
                            {fileName}
                          </p>
                        </Grid>
                      </div>
                    ) : onlyGas ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "90%",
                          marginLeft: "2%",
                        }}
                      >
                        <Tooltip title={lang[locale].gasPermission}>
                          <WhatshotIcon
                            style={{ color: "#FAB515", marginRight: "30%" }}
                          />
                        </Tooltip>
                        <Grid
                          style={{
                            color: stateData.data.gas ? "#FAB515" : "#0983B1",
                          }}
                        >
                          <p
                            style={{
                              overflowWrap: "anywhere",
                            }}
                          >
                            {fileName}
                          </p>
                        </Grid>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "90%",
                          marginLeft: "2%",
                        }}
                      >
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <StyledGasPowerSwitch
                                checked={!stateData.data.gas}
                                onChange={handleToggle}
                                disabled={gasSwitchDisabled}
                              />
                            }
                            label={
                              stateData.data.gas
                                ? lang[locale].gas
                                : lang[locale].power
                            }
                          />
                        </Grid>
                        <Grid
                          style={{
                            color: stateData.data.gas ? "#FAB515" : "#0983B1",
                            marginLeft: "4em",
                            width: "40%",
                          }}
                        >
                          <p
                            style={{
                              overflowWrap: "anywhere",
                            }}
                          >
                            {fileName}
                          </p>
                        </Grid>
                      </div>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Grid container spacing={4} alignItems="center">
                    {/* <Grid item xs={12}>
                      <Typography variant="h5">
                        {lang[locale].general}
                      </Typography>
                    </Grid> */}
                    {!stateData.data.gas ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "90%",
                          marginLeft: "2%",
                        }}
                      >
                        <Tooltip title={lang[locale].powerPermission}>
                          <FlashOnIcon
                            style={{ color: "#0983B1", marginRight: "30%" }}
                          />
                        </Tooltip>
                        <Grid
                          style={{
                            color: stateData.data.gas ? "#FAB515" : "#0983B1",
                          }}
                        >
                          <p
                            style={{
                              overflowWrap: "anywhere",
                            }}
                          >
                            {fileName}
                          </p>
                        </Grid>
                      </div>
                    ) : stateData.data.gas ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "90%",
                          marginLeft: "2%",
                        }}
                      >
                        <Tooltip title={lang[locale].gasPermission}>
                          <WhatshotIcon
                            style={{ color: "#FAB515", marginRight: "30%" }}
                          />
                        </Tooltip>
                        <Grid
                          style={{
                            color: stateData.data.gas ? "#FAB515" : "#0983B1",
                          }}
                        >
                          <p
                            style={{
                              overflowWrap: "anywhere",
                            }}
                          >
                            {fileName}
                          </p>
                        </Grid>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "90%",
                          marginLeft: "2%",
                        }}
                      >
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <StyledGasPowerSwitch
                                checked={!stateData.data.gas}
                                onChange={handleToggle}
                                disabled={gasSwitchDisabled}
                              />
                            }
                            label={
                              stateData.data.gas
                                ? lang[locale].gas
                                : lang[locale].power
                            }
                          />
                        </Grid>
                        <Grid
                          style={{
                            color: stateData.data.gas ? "#FAB515" : "#0983B1",
                            marginLeft: "4em",
                            width: "40%",
                          }}
                        >
                          <p
                            style={{
                              overflowWrap: "anywhere",
                            }}
                          >
                            {fileName}
                          </p>
                        </Grid>
                      </div>
                    )}
                  </Grid>
                </Grid>
              )}
              {stateData.data.forecastType === 0 && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      {lang[locale].pricingInformation}
                    </Typography>
                    <SelectPriceCurveComponent
                      gas={stateData.data.gas}
                      setMinMax={setMinMax}
                      // gesamtFormel={gesamtFormel}
                      fromPage={fromPageBestehende}
                      preisType={preisType ? preisType : ""}
                      setPreiskurveListe={setPreiskurveListe}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Typography variant="h5">{lang[locale].period}</Typography>
                <SelectDataPeriodComponent
                  forecastType={stateData.data.forecastType}
                  min={minDate}
                  max={maxDate}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">{lang[locale].values}</Typography>
                <ShowValuesComponent
                  endValueInput={endValueInput}
                  startValueInput={startValueInput}
                />
              </Grid>
              {service.checkValues(
                fleximportData.startValue,
                fleximportData.endValue
              ) && (
                <Grid item xs={12}>
                  <Typography color="error">
                    {lang[locale].startValueHigher}
                  </Typography>
                </Grid>
              )}
              {showCheckboxes && fleximportData.startValue && (
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {lang[locale].timedifferenceValues}
                  </Typography>
                  <TimedifferenceChecksComponent
                    scrollTo={handleScrollTo}
                    firstValueRow={fleximportData.startValue.rowIndex}
                    marchRow={fleximportData.marchTimeChangeRowId[0]}
                    octoberRow={fleximportData.octoberTimeChangeRowId[0]}
                    resetScroll={resetScroll}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="h5">
                  {lang[locale].energyAmount}
                </Typography>
                <SelectUnitComponent />
              </Grid>
              {/* <Grid item xs={3}>
                {priceCurve &&
                priceCurve.indexOf("Spotmarkt") === -1 &&
                stateData.data.forecastType === 0 ? (
                  <TextField
                    inputProps={{
                      "data-testid": "tranche",
                      min: 0,
                      pattern: "([^0-9]*)",
                    }}
                    value={forecast.data.tranche ? forecast.data.tranche : ""}
                    fullWidth
                    onChange={handleChangeTranche}
                    label={lang[locale].noOfTranches}
                    required
                    error={
                      tranche === undefined ||
                      (customerType === "E" && tranche < 1)
                    }
                  />
                ) : null}
              </Grid> */}
              {preisType !== "SPOT" && stateData.data.forecastType === 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "1rem",
                  }}
                >
                  <TextField
                    onFocus={(e) => selectHandler(e)}
                    inputProps={{
                      "data-testid": "tranche",
                      min: 0,
                      maxLength: 4,
                      pattern: "([^0-9]*)",
                    }}
                    value={
                      !ATB && forecast.data.tranche
                        ? forecast.data.tranche
                        : ATB
                        ? "automatisch"
                        : ""
                    }
                    fullWidth
                    onChange={handleChangeTranche}
                    label={lang[locale].noOfTranches}
                    error={
                      tranche === undefined ||
                      (customerType === "E" && tranche < 1)
                    }
                  />
                  {
                    (preisType !== "SPOT") &&  (<Tooltip title={lang[locale].automaticTrancheProcurement}>
                      <FormControlLabel
                        style={{ margin: "1rem 0 0 1rem" }}
                        control={
                          <Checkbox
                            value="Monatlich"
                            checked={ATB}
                            onChange={() => setATB((ATB) => !ATB)}
                            disabled={handleDays.length === 0 || !role.permissions.includes("AUTO_TRADING")}
                            style={{color : (ATB === false && MTB === false) ? "#F34336" : "white"}}
                          />
                        }
                        label={lang[locale].Atp}
                      />
                    </Tooltip>)
                  } {
                    preisType !== "SPOT" && (
                      <>
                  <Tooltip title={lang[locale].delayedPurchase}>
                   <FormControlLabel
                   style={{margin : "1rem 0 0 1rem"}}
                   control={
                     <Checkbox
                   value="MTB"
                   checked={MTB}
                   onChange={() => setMTB((MTB) => !MTB)}
                   disabled={handleDays.length === 0 || (!role.permissions.includes("DELAYED_TRADING") && !role.permissions.includes("TRADING"))}
                   style={{color : (ATB === false && MTB === false) ? "#F34336" : "white"}}
                   />
                  }
                  label={lang[locale].Mtp}
                  />
                  </Tooltip>
                  {
            role.permissions.includes("DELAYED_TRADING") &&  role.permissions.includes("TRADING") && MTB && (
              <Select
                  error={MTB && manuellKaufType === ""}
                  labelId="select-type"
                  id="type"
                  value={manuellKaufType}
                  label="Type"
                  onChange={(e)=>setManuellKaufType(e.target.value)}
                  autoWidth
                  style={{ margin: "0.5rem 0 0 1.5rem" }}
                >
                  <MenuItem value={"MANUAL"}>Kauf zum aktuellen Preis</MenuItem>
                  <MenuItem value={"DELAYED"}>Kauf zum Schlusskurs</MenuItem>

                </Select>
            )
          }
          {
            role.permissions.includes("DELAYED_TRADING") &&  !role.permissions.includes("TRADING") && MTB && (
              <p style={{color : "white", margin: "1.8rem 0 0 1.5rem"}}>Kauf zum Schlusskurs</p>
            )
          }
          {
            !role.permissions.includes("DELAYED_TRADING") &&  role.permissions.includes("TRADING") && MTB && (
              <p style={{color : "white", margin: "1.8rem 0 0 1.5rem"}}>Kauf zum aktuellen Preis</p>
            )
          }
          {
            !role.permissions.includes("DELAYED_TRADING") &&  !role.permissions.includes("TRADING") && !role.permissions.includes("AUTO_TRADING") && (
              <p style={{color : "white", margin: "1.8rem 0 0 1.5rem"}}>Kein manueller Kauf möglich</p>
            )
          }
                  </>
                  )
                  }
                </div>
              ) : null}
              {priceCurve &&
              priceCurve.indexOf("Spotmarkt") === -1 &&
              stateData.data.forecastType === 0 &&
              forecast.data.tranche &&
              forecast.data.tranche > 1 ? (
                <Grid
                  style={{
                    marginBottom: "0.3rem",
                    padding: "0.5rem 0 0 2rem",
                    lineHeight: "0.3em",
                  }}
                  item
                  xs={3}
                >
                  <FormGroup>
                    <InputLabel style={{ fontSize: "0.85em" }}>
                      Tranchenmenge
                    </InputLabel>
                    <FormControlLabel
                      control={<Checkbox onChange={handlerCheckbox} />}
                      label="100%"
                    />
                  </FormGroup>
                </Grid>
              ) : null}
              {energyError ? (
                <Grid style={{ marginLeft: "1em" }}>
                  <Typography variant="caption" color="error">
                    {energyError}
                  </Typography>
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <FleximportValueCardComponent />
              </Grid>
              {preisType !== "SPOT" && stateData.data.forecastType === 0 ? (
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Grid
                    item
                    xs={6}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <TextField
                      inputProps={{
                        "data-testid": "tranche",
                        min: 0,
                        maxLength: 4,
                        pattern: "([^0-9]*)",
                      }}
                      value={maxTranchen && !ATB ? maxTranchen : ATB ? 1 : ""}
                      fullWidth
                      onChange={(e)=>handleChangeMehrfachTranche(e)}
                      label={lang[locale].maxmoreTranchenOrder}
                      onPaste={handlePaste}
                      onInput={handleInput}
                      disabled={
                        ATB ||
                        forecast.data.pricing_type
                          ?.toLowerCase()
                          .indexOf("spot") !== -1
                      }
                      // error={}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel
                      style={{
                        fontSize: "0.8rem",
                        marginInlineStart: "1rem",
                        marginTop: "0.2rem",
                      }}
                    >
                      Angebotsende
                    </InputLabel>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === "de" ? deLocale : enLocale}>
                    <KeyboardDatePicker
                      style={{ marginInlineStart: "1rem" }}
                      helperText=""
                      variant="inline"
                      format="dd.MM.yyyy"
                      fullWidth
                      autoOk={true}
                      minDate={Date.now()}
                      maxDate={
                        forecast.fleximportData.startDateValue &&
                        subDays(forecast.fleximportData.startDateValue, 1)
                      }
                      onChange={(e) => handleChangeAngebotsende(e)}
                      value={Angebotsende ? Angebotsende : ""}
                      id="Angebotsende"
                      data-testid="Angebotsende"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      // TextFieldComponent={TextFieldComponent}
                    />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  {/* <Grid>
                          {progFrom !== null &&
                            checkFromDate(progFrom, progTo).map((entry: string) => (
                                <ListItemText>
                                <Typography
                                variant="caption"
                                color="error"
                                data-testid="date-error"
                                key={entry}
                                >
                                {entry}
                                </Typography>
                                </ListItemText>
                                ))}
                            </Grid> */}
                </Grid>
              ) : null}

              {preisType !== "SPOT" &&
              stateData.data.forecastType === 0 &&
              (
                // (tranche && tranche > 1 && !ATB)
                MTB || ATB) ? (
                <Grid item xs={12}>
                  <ABKFieldsComponent
                    handleDays={handleDays}
                    tranche={tranche}
                    ATB={ATB}
                    MTB={MTB}
                    Angebotsende={Angebotsende}
                    handleSubmitTrigger={handleSubmitTrigger}
                    sendErrorFromChild={sendErrorFromChild}
                  />
                </Grid>
              ) : null}
                    {(ohneAllRechte
                    // && tranche && tranche > 1
                  ) && ((forecast.data.order_from?.toString() !== forecast.data.order_to?.toString()) && (forecast.data.order_from && forecast.data.order_to && forecast.data.order_from < forecast.data.order_to)) && (
        <Grid item xs={12}>
          <MTBComponent preiskurveListe={preiskurveListe}/>
        </Grid>
      )}
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item>
                        <Button 
                        // color="primary"
                        style={{color : ciSettings.primary}}
                        onClick={handleHistory}>
                          {lang[locale].back}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container justify={"flex-end"}>
                      <Grid item>
                        {(!ATB) ? (<Button
                            hidden={ATB ? true : false}
                            // color="primary"
                            style={{backgroundColor : disabled ||
                              stateData.loading ||
                              btndisable ||
                              energyError.length > 0 ||
                              !isCommoditypass ?  "#595959" : ciSettings.primary ,color :  disabled ||
                              stateData.loading ||
                              btndisable ||
                              energyError.length > 0 ||
                              !isCommoditypass ? "#898989" : "white" }}
                            onClick={handleSubmit}
                            disabled={
                              disabled ||
                              stateData.loading ||
                              btndisable ||
                              energyError.length > 0 ||
                              !isCommoditypass}
                            variant="contained"
                          >
                            {stateData.loading ? (
                              <>
                              {btnSubmitText} &nbsp;
                              <CircularProgress
                                data-testid="circular"
                                size={25}
                                />
                              </>
                            ) : btnSubmitText.length > 0 ? (
                              <>
                              {btnSubmitText} &nbsp;
                              <CircularProgress
                                data-testid="circular"
                                size={25}
                              />
                              </>

                            ) : (
                              lang[locale].createPriceHistory
                            )}
                          </Button>) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {stateData.data.forecastType === 0 &&
          stateData.data.priceCurve === "0" ? (
            <Grid
              item
              xs={12}
              lg={7}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1>{lang[locale].pricecurvefirst}</h1>
            </Grid>
          ) : (
            <Grid item xs={12} lg={7}>
              {fleximportData.rows.length > 0 && (
                <Grid container direction="column" spacing={2}>
                  {scrollTo.second === fleximportData.rows.length - 1 ? (
                    <Grid item>
                      <Paper
                        style={{
                          height: scrollTo.first !== 0 ? "88vh" : "44vh",
                          width: "100%",
                        }}
                      >
                        <TableComponent
                          cols={fleximportData.cols}
                          rows={fleximportData.rows}
                          scrollTo={scrollTo.first}
                          pressed={scrollTo.first !== 0}
                        />
                      </Paper>
                    </Grid>
                  ) : (
                    ""
                  )}
                  {scrollTo.first === 0 ? (
                    <Grid item>
                      <Paper
                        style={{
                          height:
                            scrollTo.second !== fleximportData.rows.length - 1
                              ? "88vh"
                              : "44vh",
                          width: "100%",
                        }}
                      >
                        <TableComponent
                          cols={fleximportData.cols}
                          rows={fleximportData.rows}
                          scrollTo={scrollTo.second}
                          pressed={
                            scrollTo.second !== fleximportData.rows.length - 1
                          }
                        />
                      </Paper>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              )}
            </Grid>
          )}

          <ContextmenuComponent handleContextClick={handleContextClick} />
        </Grid>
      ) : (
        <Grid container justify="center">
          <Grid item xs={7} style={{ marginBottom: "6vh", marginTop: "3vh" }}>
            <Typography variant="h4">
              {stateData.data.forecastType === 0
                ? lang[locale].progUpload
                : lang[locale].istUpload}
            </Typography>
          </Grid>
          <Grid item xs={7} style={{ marginBottom: "3vh" }}>
            <UploadFormularComponent
              onDropAccepted={onDropAccepted}
              onDropRejected={onDropRejected}
              dropText={
                stateData.data.forecastType === 0
                  ? lang[locale].dropProg
                  : lang[locale].dropIst
              }
            />
          </Grid>
          <Grid item xs={7}>
            <Button
            // color="primary"
            style={{color : ciSettings.primary}}
            onClick={handleHistory}>
              {lang[locale].back}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default FleximportComponent;
