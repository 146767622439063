import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  Button,
  Grid,
  TableCell,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  FormControlLabel,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { styled } from "@mui/material/styles";
import UserModel from "../typeDefs/UserModel";
import UserService from "../services/users/UserService";
import { lang } from "../lang";
import classNames from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Permission from "../typeDefs/Permission";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

//Components
import AddUserComponent from "./AddUser";
import DeleteUserOrCustomerComponent from "./DeleteUserOrCustomer";

//Hooks
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core";
import { useSelector } from "services/helpers/useSelector";

//Actions
// import authActions from "../actions/auth.actions";
import snackbarActions from "../actions/snackbar.actions";
import modalActions from "../actions/modal.actions";
import usersActions from "actions/users.actions";

//DevExpress
import {
  Grid as TableGrid,
  Table as TB,
  TableHeaderRow,
  PagingPanel,
  TableColumnResizing,
  TableRowDetail,
  TableFilterRow,
} from "@devexpress/dx-react-grid-material-ui";
import {
  PagingState,
  IntegratedPaging,
  RowDetailState,
  EditingState,
  FilteringState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";
import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder,
  Action,
} from "@devexpress/dx-react-core";

//Icons
import DeleteIcon from "@material-ui/icons/Delete";
import Cancel from "@material-ui/icons/Close";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const service = new UserService();

/** Documentation can be found here:
 *
 * https://devexpress.github.io/devextreme-reactive/react/grid/docs/guides/editing-in-detail-row/
 *
 **/

function SupplierUserListComponent(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const locale = localStorage.locale;
  const [rows, setRows] = React.useState<UserModel[]>();
  const [permissions, setPermissions] = useState<Permission[]>();
  const [updated, setUpdated] = useState([]);
  const [seletAll, setSelectAll] = useState(false);
  const [deseletAll, setDeSelectAll] = useState(false);
  const [TakingOverFrom, setTakingOverFrom] = useState(false);
  const theme = useTheme();
  const [SelectedSupplier, setSelectedSupplier] = useState("");
  const [expandedRowIds, setExpandedRowIds] = useState([]);

  // @ts-ignore
  const supplierUsersStore = useSelector((state) => state.users.users);

  //gives the timeout function always the actual state
  const updatedRef = useRef(updated);
  updatedRef.current = updated;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#5c5959",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  function handleChange(e){
    setSelectedSupplier(e.target.value)
  }

  const messages = {
    LocalizationMessages: {
      rowsPerPage: lang[locale].RowPerPage, // Custom text for Rows per page
    },
  };

  /**
   * Gets users of current supplier
   */

  useEffect(() => {
    service
      .getUsersFromSupplier()
      .then((users) => {
        dispatch(usersActions.updateAll(users));
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
    return () => {
      dispatch(usersActions.reset());
    };
  }, []);

  /** Gets the users out of the store */

  useEffect(() => {
    const rows = JSON.parse(JSON.stringify(supplierUsersStore));
    rows.length !== 0 && setRows(editSupplierUsers(rows));
  }, [supplierUsersStore]);

  /* Get all supplier permissions */

  useEffect(() => {
    service
      .getPermissions()
      .then((perms) => {
        setPermissions(
          perms.filter(
            (perm) => perm.role === "LIEFERANT" || perm.role === "USER"
          )
        );
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  }, []);

  /**
   * Event Handelers
   */

  const changePermissions = (uid, permission) => {
    if(TakingOverFrom === false && SelectedSupplier === ""){
      // @ts-ignore
      const users = [...rows];
  
      const rowIndex = users.findIndex((user) => user.uid === uid);
  
      let permissions = users[rowIndex].role.permissions;
  
      permissions.includes(permission)
        ? (permissions = permissions.filter((perm) => perm !== permission))
        : permissions.push(permission);
  
      users[rowIndex].role.permissions = permissions;
  
      setRows(users);
    } else if(TakingOverFrom === true && SelectedSupplier !== ""){
           // @ts-ignore
           const users = [...rows];
  
           // @ts-ignore
          const rowIndex = users.findIndex((user) => user.uid === uid);
           // @ts-ignore
          let permissions = supplierUsersStore?.find(i=>i.email === SelectedSupplier)["role"].permissions;
    
          permissions.includes(permission)
          ? (permissions = permissions.filter((perm) => perm !== permission))
          : permissions.push(permission);
    
          // @ts-ignore
          users[rowIndex].role.permissions = permissions;
    
          setRows(users);
    }
  };

  /**  DEVEXPRESS
   *
   * Table Data
   *
   **/

  const columns = [
    { name: "firstname", title: lang[locale].firstName },
    { name: "lastname", title: lang[locale].lastName },
    { name: "email", title: "E-Mail" },
    { name: "delete", title: " " },
  ];

  const [columnWidths, setColumnWidths] = useState([
    { columnName: "firstname", width: 150 },
    { columnName: "lastname", width: 150 },
    { columnName: "email", width: 300 },
    { columnName: "delete", width: 60 },
  ]);

  const [pageSizes] = useState([5, 10, 15, 0]);

  const editSupplierUsers = (users) => {
    for (var i in users) {
      users[i].delete = (
        <Button
          startIcon={<DeleteIcon />}
          variant="text"
          value={[users[i].email, users[i].uid]}
          onClick={openDeleteModal}
        ></Button>
      );
    }
    return users;
  };

  function premissionsNameTranslator(permissionName) {
    if (lang[locale].add === "Hinzufügen") {
      switch (permissionName) {
        case "TRADING":
          return "HANDEL";
        case "VIEW_DETAIL":
          return "DETAIL ANZEIGEN";
        case "MONITORING":
          return "ÜBERWACHUNG";
        case "VIEW_HISTORY":
          return "VERLAUF ANZEIGEN";
        case "VIEW_INTRADAY":
          return "INTRADAY ANZEIGEN";
        case "VIRTUAL_ORDER":
          return "VIRTUELLE KÄUFE";
        case "PERMISSIONS":
          return "ERLAUBNISSE";
        case "SETTINGS":
          return "EINSTELLUNGEN";
        case "INSERT_USER":
          return "BENUTZER EINFÜGEN";
        case "FORECAST_COUNT":
          return "PROGNOSEANZAHL";
        case "INSERT_CUSTOMER":
          return "KUNDE EINFÜGEN";
        case "USERS":
          return "BENUTZER";
        case "INSERT_FOR_USER":
          return "EINLAGE FÜR BENUTZER";
        case "LIMITS":
          return "GRENZEN";
        case "CUSTOMERS":
          return "KUNDEN";

        default:
          return permissionName;
      }
    }
    return permissionName;
  }

  const openDeleteModal = (e) => {
    const values = e.currentTarget.value.split(",");
    const email = values[0];
    const uid = values[1];

    if (uid) {
      dispatch(
        modalActions.openModal(DeleteUserOrCustomerComponent, {
          type: "user",
          id: uid,
          information: email,
        })
      );
    }
  };

  /**  DEVEXPRESS
   *
   * Custom Plugin Expandable Form
   *
   **/

  const getRowId = (row) => row.uid;

  const DetailContent = ({ row, ...rest }) => {
    const { processValueChange, applyChanges, cancelChanges } = rest;
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ padding: 25 }}>
              <Typography variant="caption" style={{fontSize : "1rem"}}>
                {lang[locale].permissions}:
              </Typography>
              <FormControlLabel
                style={{ marginLeft: "2rem" }}
                control={
                  <Checkbox
                    checked={seletAll}
                    size="small"
                    onChange={() => {
                      setTakingOverFrom(false);
                      setSelectedSupplier("");
                      setSelectAll(true);
                      setDeSelectAll(false);
                      permissions?.map((perm) => {
                        if (!row.role.permissions.includes(perm.name))
                          changePermissions(row.uid, perm.name);
                      });
                    }}
                    name="permission"
                    color="primary"
                    // disabled={!kundePermissions.permissions.includes(perm.name) && !row.role.permissions.includes(perm.name)}
                  />
                }
                label={lang[locale].selectAll}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={deseletAll}
                    size="small"
                    onChange={() => {
                      setTakingOverFrom(false);
                      setSelectedSupplier("");
                      setSelectAll(false);
                      setDeSelectAll(true);
                      permissions?.map((perm) => {
                        if (row.role.permissions.includes(perm.name))
                          changePermissions(row.uid, perm.name);
                      });
                    }}
                    name="permission"
                    color="primary"
                    // disabled={!kundePermissions.permissions.includes(perm.name) && !row.role.permissions.includes(perm.name)}
                  />
                }
                label={lang[locale].deselectAll}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={TakingOverFrom}
                    size="small"
                    onChange={() => {
                      setDeSelectAll(false);
                      setSelectAll(false);
                      setTakingOverFrom(true);
                      setSelectedSupplier("");
                      permissions?.map((perm) => {
                        supplierUsersStore
                          ?.find((i) => i.name === SelectedSupplier)
                          ["role"].permissions?.includes(perm.name);
                        changePermissions(row.uid, perm.name);
                      });
                    }}
                    name="permission"
                    color="primary"
                    // disabled={!kundePermissions.permissions.includes(perm.name) && !row.role.permissions.includes(perm.name)}
                  />
                }
                label={lang[locale].TakingOverFrom}
              />
              {TakingOverFrom === true ? (
                <Select
                  value={SelectedSupplier ? SelectedSupplier : ""}
                  onChange={(e) => handleChange(e)}
                >
                  {supplierUsersStore
                    .filter((i) => i.email !== row.email)
                    .map((user) => (
                      <MenuItem
                      disabled={SelectedSupplier === user.email}
                      value={user.email}
                      //@ts-ignore
                      onClick={(e) => setSelectedSupplier(e)}
                      >
                        {user.email}
                      </MenuItem>
                    ))}
                </Select>
              ) : null}
            </Grid>

            {permissions?.map((perm) => (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 400 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell width={300}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={TakingOverFrom === true && SelectedSupplier !== ""? supplierUsersStore?.find(i=>i.email === SelectedSupplier)["role"].permissions?.includes(perm.name) : row.role.permissions.includes(perm.name)}
                              size="small"
                              onClick={()=>{
                                setTakingOverFrom(false);
                                setSelectedSupplier("");
                                setDeSelectAll(false);
                                setSelectAll(false);
                              }}
                              onChange={() => {
                                changePermissions(row.uid, perm.name);
                              }}
                              name="permission"
                              color="primary"
                            />
                          }
                          label={premissionsNameTranslator(perm.name)}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {perm.help}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody></TableBody>
                </Table>
              </TableContainer>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3} justify="flex-end">
            <Grid item>
              <Button onClick={applyChanges} variant="text" color="primary">
                {lang[locale].save}
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={()=>{cancelChanges(); setExpandedRowIds([]);}} color="secondary">
                {lang[locale].cancel}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const styles = (theme) => ({
    toggleCell: {
      textAlign: "center",
      textOverflow: "initial",
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing(1),
    },
    toggleCellButton: {
      verticalAlign: "middle",
      display: "inline-block",
      padding: theme.spacing(1),
    },
  });

  const ToggleCellBase = ({
    style,
    expanded,
    classes,
    onToggle,
    tableColumn,
    tableRow,
    row,
    className,
    ...restProps
  }) => {
    const handleClick = (e) => {
      // e.stopPropagation();
      // onToggle();
      if(expandedRowIds.length === 0){
        const newarray = [...expandedRowIds, row.uid]
        //@ts-ignore
        setExpandedRowIds(newarray)
        e.stopPropagation();
        onToggle();
        setTakingOverFrom(false);
        setSelectedSupplier("");
        setDeSelectAll(false);
        setSelectAll(false);
      }
      if(expanded && expandedRowIds.length > 0 && expandedRowIds[0] === row.uid){
        onToggle();
        setExpandedRowIds([]);
        setTakingOverFrom(false);
        setSelectedSupplier("");
        setDeSelectAll(false);
        setSelectAll(false);
      }
    };
    return (
      <TableCell
        className={classNames(classes.toggleCell, className)}
        style={style}
        {...restProps}
      >
        <IconButton className={classes.toggleCellButton} onClick={handleClick}>
        {expanded ? <Cancel /> : !expanded && expandedRowIds.length > 0 ? null : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
    );
  };

  // @ts-ignore
  const ToggleCell = withStyles(styles, { name: "ToggleCell" })(ToggleCellBase);

  const DetailEditCell = () => (
    <Plugin name="detailEdit">
      <Action
        name="toggleDetailRowExpanded"
        action={(
          { rowId },
          { expandedDetailRowIds },
          { startEditRows, stopEditRows }
        ) => {
          const rowIds = [rowId];

          const isCollapsing = expandedDetailRowIds.indexOf(rowId) > -1;
          if (isCollapsing) {
            //should just be performed if permissions is not updated
            setTimeout(() => {
              reset(rowIds[0], updatedRef.current);
            }, 1000);
            stopEditRows({ rowIds });
          } else {
            startEditRows({ rowIds });
          }
        }}
      />
      <Template
        name="tableCell"
        // @ts-ignore
        predicate={({ tableRow }) => tableRow.type === TableRowDetail.ROW_TYPE}
      >
        {(params) => (
          <TemplateConnector>
            {(
              { tableColumns, createRowChange, rowChanges },
              {
                changeRow,
                commitChangedRows,
                cancelChangedRows,
                toggleDetailRowExpanded,
              }
            ) => {
              if (
                // @ts-ignore
                tableColumns.indexOf(params.tableColumn) !== 0
              ) {
                return null;
              }
              const {
                // @ts-ignore
                tableRow: { rowId },
              } = params;
              const row = {
                // @ts-ignore
                ...params.tableRow.row,
                ...rowChanges[rowId],
              };

              const processValueChange = ({ target: { name, value } }) => {
                const changeArgs = {
                  rowId,
                  change: createRowChange(row, value, name),
                };
                changeRow(changeArgs);
              };

              const applyChanges = () => {
                toggleDetailRowExpanded({ rowId });
                commitChangedRows({ rowIds: [rowId] });
              };
              const cancelChanges = () => {
                toggleDetailRowExpanded({ rowId });
                cancelChangedRows({ rowIds: [rowId] });
              };

              return (
                <TemplatePlaceholder
                  params={{
                    ...params,
                    row,
                    tableRow: {
                      // @ts-ignore
                      ...params.tableRow,
                      row,
                    },
                    changeRow,
                    processValueChange,
                    applyChanges,
                    cancelChanges,
                  }}
                />
              );
            }}
          </TemplateConnector>
        )}
      </Template>
    </Plugin>
  );

  const DetailCell = ({
    children,
    changeRow,
    editingRowIds,
    addedRows,
    processValueChange,
    applyChanges,
    cancelChanges,
    ...restProps
  }) => {
    const { row } = restProps;

    return (
      // @ts-ignore
      <TableRowDetail.Cell {...restProps}>
        {React.cloneElement(children, {
          row,
          changeRow,
          processValueChange,
          applyChanges,
          cancelChanges,
        })}
      </TableRowDetail.Cell>
    );
  };

  function reset(uid, updated) {
    //should just be performed if permissions is not updated

    if (updated.includes(uid)) {
      let newUpdated = [...updated];
      newUpdated = newUpdated.filter((entry) => entry !== uid);
      // @ts-ignore
      setUpdated(newUpdated);
      return;
    }

    //if permissions were not updated, reset

    const rows = JSON.parse(JSON.stringify(supplierUsersStore));
    const previous = rows.filter((obj) => obj.uid === uid)[0];

    const current = [...rows];
    const rowIndex = current && current.findIndex((row) => row.uid === uid);

    current[rowIndex] = previous;

    let newUpdated = [...updated];
    newUpdated = newUpdated.filter((user) => user !== uid);
    // @ts-ignore
    setUpdated(newUpdated);
    setRows(editSupplierUsers(current));
  }

  const handleSubmit = (uid) => {
    setExpandedRowIds([]);
    // @ts-ignore
    const user = rows.filter((obj) => obj.uid === uid)[0];
    const permissions =TakingOverFrom === true && SelectedSupplier !== ""? supplierUsersStore?.find(i=>i.email === SelectedSupplier)["role"].permissions : user.role.permissions;
    const updateUser = { uid, permissions };
    user &&
      service
        .updateUser(updateUser)
        .then(() => {
          //To only reset if not updated
          let updateArray = [...updated];
          // @ts-ignore
          updateArray.push(uid);
          setUpdated(updateArray);
 
          dispatch(usersActions.updateSingle(updateUser));
          dispatch(
            snackbarActions.openSnackbar(lang[locale].userUpdated, "success")
          );
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
  };

  const commitChanges = ({ changed }) => {
    if (changed) {
      const uid = Object.keys(changed)[0];
      const changedRows = rows?.map((row) =>
        // @ts-ignore
        changed[row.uid] ? { ...row, ...changed[row.uid] } : row
      );

      //save permissions changes in the backend
      handleSubmit(Number(uid));

      //TO-DO: Do we need this?
      setRows(changedRows);
    }
  };

  /* delete colum should not be filterable */

  const FilterCell = (props) => {
    const { column } = props;
    if (column.name === "delete") {
      return null;
    }
    return <TableFilterRow.Cell {...props} />;
  };

  /* makes the title row blue */

  const titleComponent = (props) => {
    return (
      // @ts-ignore
      <titleComponent
        {...props}
        // style={{ color: theme.palette.primary.main }}
      />
    );
  };

  /* sorting*/

  const [sorting, setSorting] = useState([
    { columnName: "firstname", direction: "asc" },
    { columnName: "lastname", direction: "asc" },
    { columnName: "email", direction: "asc" },
  ]);

  const comparePriority = (a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) return -1;

    if (a.toLowerCase() > b.toLowerCase()) return 1;

    return 0;
  };

  const [integratedSortingColumnExtensions] = useState([
    { columnName: "lastname", compare: comparePriority },
    { columnName: "firstname", compare: comparePriority },
  ]);

  return (
    <Grid>
      <AddUserComponent role={"LIEFERANT"} coloR={props.coloR} />
      <>
        {rows && rows.length > 0 && (
          <Paper>
            <TableGrid rows={rows} columns={columns} getRowId={getRowId}>
              <FilteringState defaultFilters={[]} />
              <IntegratedFiltering />
              <RowDetailState defaultExpandedRowIds={[1]} />
              <EditingState
                defaultEditingRowIds={[1]}
                // @ts-ignore
                onCommitChanges={commitChanges}
              />
              <SortingState
                // @ts-ignore
                sorting={sorting}
                onSortingChange={setSorting}
              />
              <IntegratedSorting
                columnExtensions={integratedSortingColumnExtensions}
              />
              <PagingState defaultCurrentPage={0} defaultPageSize={5} />
              <IntegratedPaging />
              <TB />
              <TableColumnResizing
                columnWidths={columnWidths}
                // @ts-ignore
                onColumnWidthsChange={setColumnWidths}
              />
              <TableHeaderRow
                showSortingControls
                titleComponent={titleComponent}
              />
              <TableFilterRow cellComponent={FilterCell} />
              <TableRowDetail
                contentComponent={DetailContent}
                // @ts-ignore
                cellComponent={DetailCell}
                // @ts-ignore
                toggleCellComponent={ToggleCell}
              />
              <DetailEditCell />
              <PagingPanel
              //@ts-ignore
              messages={messages.LocalizationMessages} pageSizes={pageSizes} />
            </TableGrid>
          </Paper>
        )}
      </>
    </Grid>
  );
}

export default SupplierUserListComponent;
