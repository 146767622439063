import React, { useState } from "react";
import { Grid, ListItemText, Typography, TextField } from "@material-ui/core";
import { KeyboardDatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import { lang } from "../lang";
import forecastActions from "../actions/forecast.actions";
import { format,formatDistance, addDays, add, differenceInDays, subHours, addHours,sub } from "date-fns";
import { useDispatch } from "react-redux";
import { useSelector } from "../services/helpers/useSelector";
import SelectDateProps from "typeDefs/SelectDateProps";
import { useLocation } from "react-router";


function SelectDateComponent({ minDate, maxDate }: SelectDateProps) {
  const data = useSelector((state) => state.forecast.data);
  const location = useLocation();
  const pathName = location.pathname?.split("/")[2];
  const { progFrom, progTo } = data;
  const toDay = new Date();
  const tommorow = add(toDay, { days: 1})
  const theDayAfterTommorow = add(toDay, { days: 2})
  const fourYearsLater = add(toDay, { years: 4})
  const lastYear = sub(toDay, {years :1})
  let endYear = toDay.getFullYear() + 4;
  let endOfNextFourYears = new Date(endYear, 11, 31);
  
  const locale = localStorage.locale;
  const rightmaxDate = maxDate.toLocaleString("de-DE", {
    timeZone: "UTC",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const dispatch = useDispatch();
  
  function checkFromDate(from: Date, to: Date | null): string[] {
    const check: string[] = [];
    if (to !== null && from > to) {
      check.push(lang[locale].dateWrongError);
    }
    if (isNaN(from.valueOf())) {
      check.push(lang[locale].invalidDate);
    }
    if (
      from > maxDate &&
      data.pricing_type?.toLowerCase().indexOf("spot") === -1
    ) {
      check.push(
        lang[locale].biggerThanMaxDate + " ",
        // lang[locale].biggerThanMaxDateTest + " ",
        maxDate.toLocaleString("de-DE", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      );
    }
    if (
      from < minDate &&
      data.pricing_type?.toLowerCase().indexOf("spot") === -1
    ) {
      check.push(
        lang[locale].smallerThanMinDate + " ",
        minDate.toLocaleString("de-DE", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      );
    }
    if (
      differenceInDays(from,tommorow) < 0 && data.forecastType !== 1 &&
      data.pricing_type?.toLowerCase().indexOf("spot") !== -1 && pathName !== "pricedevelopment"
    ) {
      check.push(
        lang[locale].smallerThanMinDate + " ",
        tommorow.toLocaleString("de-DE", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      );
    }
    if (
      differenceInDays(from,lastYear) < 0 &&
      data.pricing_type?.toLowerCase().indexOf("spot") !== -1 && pathName === "pricedevelopment"
    ) {
      check.push(
        lang[locale].smallerThanMinDate + " ",
        lastYear.toLocaleString("de-DE", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      );
    }
    return check;
  }

  function checkToDate(from: Date, to: Date): string[] {
    const check: string[] = [];
    if (isNaN(to.valueOf())) {
      check.push(lang[locale].invalidDate);
    }
    if (
      to > maxDate &&
      data.pricing_type?.toLowerCase().indexOf("spot") === -1
    ) {
      check.push(
        lang[locale].biggerThanMaxDate +
          " " +
          // lang[locale].biggerThanMaxDateTest + " " +
          maxDate.toLocaleString("de-DE", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
      );
    }
    if (
      differenceInDays(to, endOfNextFourYears) > 0 &&
      data.pricing_type?.toLowerCase().indexOf("spot") !== -1
    ) {
      check.push(
        lang[locale].biggerThanMaxDate +
          " " +
          // lang[locale].biggerThanMaxDateTest + " " +
          endOfNextFourYears.toLocaleString("de-DE", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
      );
    }
    return check;
  }

  const TextFieldComponent = (props) => {
    return <TextField {...props} disabled={true} />
  }

  return (
    // when price art is SPOT
    <>
      {data.pricing_type?.toLowerCase().indexOf("spot") !== -1 ? (
        <>
          <Grid item xs={12} md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === "de" ? deLocale : enLocale}>
            <KeyboardDatePicker
              helperText=""
              variant="inline"
              format="dd.MM.yyyy"
              fullWidth
              autoOk={true}
              minDate={ data.pricing_type?.toLowerCase().indexOf("spot") !== -1 && pathName === "pricedevelopment" ? lastYear : tommorow} 
              onChange={(date: Date | null) => {
                dispatch(forecastActions.saveForecastSingle("progFrom", date));
              }}
              defaultValue={tommorow}
              value={progFrom}
              error={
                progFrom === null ||
                (differenceInDays(progFrom,tommorow) < 0 &&
                  data.pricing_type?.toLowerCase().indexOf("spot") !== -1 && pathName !== "pricedevelopment")
              }
              minDateMessage={
                "Das Datum darf nicht vor dem " +
                format(tommorow, "dd.MM.yyyy") +
                " liegen."
              }
              id="startdate"
              data-testid="startdate"
              label={lang[locale].startdate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
            <Grid>
              {progFrom !== null &&
                checkFromDate(progFrom, progTo).map((entry: string) => (
                  <ListItemText>
                    <Typography
                      variant="caption"
                      color="error"
                      data-testid="date-error"
                      key={entry}
                    >
                      {entry}
                    </Typography>
                  </ListItemText>
                ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === "de" ? deLocale : enLocale}>
            <KeyboardDatePicker
              helperText=""
              variant="inline"
              format="dd.MM.yyyy"
              fullWidth
              autoOk={true}
              value={progTo}
              error={
                progTo === null
              }
              maxDateMessage={
                "Das Datum darf nicht nach dem " +
                format(maxDate, "dd.MM.yyyy") +
                " liegen."
              }
              data-testid="enddate"
              onChange={(date: Date | null) => {
                dispatch(forecastActions.saveForecastSingle("progTo", date));
              }}
              id="enddate"
              label={lang[locale].enddate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            </MuiPickersUtilsProvider>
            <Grid>
              {progTo !== null &&
                checkToDate(progFrom, progTo).map((entry: string) => (
                  <ListItemText>
                    <Typography
                      variant="caption"
                      color="error"
                      data-testid="date-error"
                      key={entry}
                    >
                      {entry}
                    </Typography>
                  </ListItemText>
                ))}
            </Grid>
          </Grid>
        </>
      ) : (
        //when price art is everything instead of SPOT
        <>
          <Grid item xs={12} md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === "de" ? deLocale : enLocale}>
            <KeyboardDatePicker
              helperText=""
              variant="inline"
              format="dd.MM.yyyy"
              fullWidth
              autoOk={true}
              onChange={(date: Date | null) => {
                dispatch(forecastActions.saveForecastSingle("progFrom", date));
                // dispatch(forecastActions.saveForecastSingle("accept_from", toDay));
              }}
              minDate={minDate}
              maxDate={maxDate}
              // defaultValue={tommorow}
              value={progFrom}
              error={
                progFrom === null ||
                (progFrom < minDate &&
                  data.pricing_type?.toLowerCase().indexOf("spot") === -1)
              }
              minDateMessage={
                "Das Datum darf nicht vor dem " +
                format(minDate, "dd.MM.yyyy") +
                " liegen."
              }
              id="startdate"
              data-testid="startdate"
              label={lang[locale].startdate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              TextFieldComponent={TextFieldComponent}
            />
          </MuiPickersUtilsProvider>
            <Grid>
              {progFrom !== null &&
                checkFromDate(progFrom, progTo).map((entry: string) => (
                  <ListItemText>
                    <Typography
                      variant="caption"
                      color="error"
                      data-testid="date-error"
                      key={entry}
                    >
                      {entry}
                    </Typography>
                  </ListItemText>
                ))}
            </Grid>
          </Grid>
          <Grid item  xs={12} md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === "de" ? deLocale : enLocale}>
            <KeyboardDatePicker
              helperText=""
              variant="inline"
              format="dd.MM.yyyy"
              fullWidth
              autoOk={true}
              maxDate={maxDate}
              minDate={minDate}
              // defaultValue={maxDate}
              value={progTo}
              error={
                progTo === null ||
                (progTo > maxDate &&
                  data.pricing_type?.toLowerCase().indexOf("spot") === -1)
              }
              maxDateMessage={
                "Das Datum darf nicht nach dem " +
                format(maxDate, "dd.MM.yyyy") +
                " liegen."
              }
              data-testid="enddate"
              onChange={(date: Date | null) => {
                dispatch(forecastActions.saveForecastSingle("progTo", date));
                // dispatch(forecastActions.saveForecastSingle("accept_from", toDay));
                // dispatch(forecastActions.saveForecastSingle("accept_to", endYear));
              }}
              id="enddate"
              label={lang[locale].enddate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              TextFieldComponent={TextFieldComponent}
            />
          </MuiPickersUtilsProvider>
            <Grid>
              {progTo !== null &&
                checkToDate(progFrom, progTo).map((entry: string) => (
                  <ListItemText>
                    <Typography
                      variant="caption"
                      color="error"
                      data-testid="date-error"
                      key={entry}
                    >
                      {entry}
                    </Typography>
                  </ListItemText>
                ))}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default SelectDateComponent;
