import NumberFormat from "react-number-format";
import React from "react";

/**
 * Input component for material textfield
 * @param props
 * @constructor
 */
function TwoDecimalComponent(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.floatValue,
                    },
                });
            }}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
        />
    );
}

export default TwoDecimalComponent;
