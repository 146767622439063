import { useSelector } from "../services/helpers/useSelector";
import { useDispatch } from "react-redux";
import forecastActions from "../actions/forecast.actions";
import { Grid, TextField, Typography } from "@material-ui/core";
import { lang } from "../lang";
import React from "react";
import CommalessThousandSeperatorComponent from "./CommalessThousandSeperator";

/**
 * element to select usage

 * @constructor
 */
export function SelectUsage({tranche,sendErrorFromChild,divideAmount}) {
    const locale = localStorage.locale;
    const { forecastType, usageType, gas, usage } = useSelector(
        (state) => state.forecast.data
    );

    const {
        min_usage_mwh_power_slp,
        max_usage_mwh_power_slp,
        min_usage_mwh_gas_slp_g,
        max_usage_mwh_gas_slp_g,
        min_usage_mwh_gas_slp_s,
        max_usage_mwh_gas_slp_s,
    } = useSelector((state) => state.constants);

    // console.log(
    //     "measures",
    //     min_usage_mwh_power_slp,
    //     max_usage_mwh_power_slp,
    //     min_usage_mwh_gas_slp_g,
    //     max_usage_mwh_gas_slp_g,
    //     min_usage_mwh_gas_slp_s,
    //     max_usage_mwh_gas_slp_s
    // );

    // console.log(
    //     "state select usage",
    //     useSelector((state) => state.constants)
    // );

    const dispatch = useDispatch();

    function handleChange(e) {
        dispatch(forecastActions.saveForecastSingle("usage", e.target.value));
    }


    function checkUsage(val: Number): string[] {
        let check: string[] = [];
        //power min


        if(tranche > 1 && divideAmount === true){
            if (gas !== true && val < min_usage_mwh_power_slp * 1000 * tranche) {
                check.push(
                    lang[locale].usageTooSmallYear +
                        min_usage_mwh_power_slp * 1000 * tranche+
                        " kWh" +
                        lang[locale].usageSecond
                );
            }
            if (gas === true && usageType === 2 && val < min_usage_mwh_gas_slp_s * 1000 * tranche
            ) {
                check.push(
                    lang[locale].usageTooSmallDay +
                        min_usage_mwh_gas_slp_s * 1000 * tranche +
                        " kWh" +
                        lang[locale].usageSecond
                );
            }
            if (
                gas === true &&
                usageType === 1 &&
                val < min_usage_mwh_gas_slp_g * 1000 * tranche
            ) {
                check.push(
                    lang[locale].usageTooSmallMeasured +
                        min_usage_mwh_gas_slp_g * 1000 * tranche +
                        " kWh" +
                        lang[locale].usageSecond
                );
            }
        } else {
            if (gas !== true && val < min_usage_mwh_power_slp * 1000) {
                check.push(
                    lang[locale].usageTooSmallYear +
                        min_usage_mwh_power_slp * 1000 +
                        " kWh" +
                        lang[locale].usageSecond
                );
            }
    
            //power max
            if (gas !== true && val > max_usage_mwh_power_slp * 1000) {
                check.push(
                    lang[locale].usageTooBigYear +
                        max_usage_mwh_power_slp * 1000 +
                        " kWh" +
                        lang[locale].usageSecond
                );
            }
    
            //gas min spezifisch
            if (
                gas === true &&
                usageType === 2 &&
                val < min_usage_mwh_gas_slp_s * 1000
            ) {
                check.push(
                    lang[locale].usageTooSmallDay +
                        min_usage_mwh_gas_slp_s * 1000 +
                        " kWh" +
                        lang[locale].usageSecond
                );
            }
    
            //gas max spezifisch
            if (
                gas === true &&
                usageType === 2 &&
                val > max_usage_mwh_gas_slp_s * 1000
            ) {
                check.push(
                    lang[locale].usageTooBigDay +
                        max_usage_mwh_gas_slp_s * 1000 +
                        " kWh" +
                        lang[locale].usageSecond
                );
            }
    
            //gas min gemessen
            if (
                gas === true &&
                // tranche > 1 &&
                usageType === 1 &&
                // val < min_usage_mwh_gas_slp_g * 1000 * tranche
                val < min_usage_mwh_gas_slp_g * 1000
            ) {
                check.push(
                    lang[locale].usageTooSmallMeasured +
                        // min_usage_mwh_gas_slp_g * 1000 * tranche +
                        min_usage_mwh_gas_slp_g * 1000+
                        " kWh" +
                        lang[locale].usageSecond
                );
            }
    
            //gas max gemessen
            if (
                gas === true &&
                usageType === 1 &&
                val > max_usage_mwh_gas_slp_g * 1000
            ) {
                check.push(
                    lang[locale].usageTooBigMeasured +
                        max_usage_mwh_gas_slp_g * 1000 +
                        " kWh" +
                        lang[locale].usageSecond
                );
            }
    
        }

        sendErrorFromChild(check);
        
        return check;
    }

    

    return (
        <>
            <Grid item xs={6}>
                <TextField
                    error={usage === 0 || usage === undefined || checkUsage(usage).length > 0}
                    required
                    inputProps={{ "data-testid": "selectUsage" }}
                    InputProps={{
                        inputComponent: CommalessThousandSeperatorComponent,
                    }}
                    value={usage ? usage : ''}
                    onFocus={(e) => e.target.select()}
                    fullWidth
                    onChange={handleChange}
                    label={
                        (forecastType === 3 ? lang[locale].yearlyUsage : "") +
                        lang[locale].usage +
                        (forecastType === 4 && usageType === 2 ? "/Tag" : "") +
                        " in kWh"
                    }
                />
                <Grid>
                    {checkUsage(usage).map((entry) => (
                        <Typography
                            variant="caption"
                            color="error"
                            data-testid="date-error"
                            key={entry}
                        >
                            {entry}
                        </Typography>
                    ))}
                </Grid>
            </Grid>
        </>
    );
}
