/**
 * Translates Errorcodes from the API to useful error codes
 * @param code
 */
import { lang } from "../../lang";

function errorCodeTranslator(code: string): string {
    const locale = localStorage.locale ? localStorage.locale : "de";
    let message = "";
    switch (code) {
        case "TTPFM_si-insert":
            message = lang[locale].TTPFM_si_insert;
            break;
        case "createCustomer_internal-error":
            message = lang[locale].createCustomer_internal_error;
            break;
        case "createCustomer_missing-permissions":
            message = lang[locale].createCustomer_missing_permissions;
            break;
        case "createCustomer_missing-relation":
            message = lang[locale].createCustomer_missing_relation;
            break;
        case "createCustomer_parse-data":
            message = lang[locale].createCustomer_parse_data;
            break;
        case "createForecastForCustomer_missing-permissions":
            message =
                lang[locale].createForecastForCustomer_missing_permissions;
            break;
        case "createForecastForCustomer_missing-relation":
            message = lang[locale].createForecastForCustomer_missing_relation;
            break;
        case "createForecastForCustomer-unknown-function":
            message = lang[locale].createForecastForCustomer_unknownFunction;
            break;
        case "createForecast_unknown-function":
            message = lang[locale].createForecast_unknownFunction;
            break;
        case "createISTData_parse-data":
            message = lang[locale].createISTData_parse_data;
            break;
        case "createUser_customer-relation":
            message = lang[locale].createUser_missing_relation;
            break;
        case "createUser_internal-error":
            message = lang[locale].createUser_internal_error;
            break;
        case "createUser_missing-permissions":
            message = lang[locale].createUser_missing_permissions;
            break;
        case "createUser_missing-relation":
            message = lang[locale].createUser_missing_relation;
            break;
        case "createUser_parse-data":
            message = lang[locale].createUser_parse_data;
            break;
        case "createUser_privilege-escalation":
            message = lang[locale].createUser_privilege_escalation;
            break;
        case "createUser_user-relation":
            message = lang[locale].createUser_user_relation;
            break;
        case "createUser_user-already-exists":
            message = lang[locale].createUser_user_already_exists;
            break;
        case "createUser_email-error":
            message = lang[locale].createUser_email_error;
            break;
        case "createUser_email-too-long":
            message = lang[locale].createUser_email_too_long;
            break;
        case "createUser_empty-data":
            message = lang[locale].createUser_empty_data;
            break;
        case "createUser_invalid-email":
            message = lang[locale].createUser_invalid_email;
            break;
        case "createUser_name-too-long":
            message = lang[locale].createUser_name_too_long;
            break;
        case "createUser_name-too-short":
            message = lang[locale].createUser_name_too_short;
            break;
        case "customerData_internal-error":
            message = lang[locale].customerData_internal_error;
            break;
        case "customerData_missing-permissions":
            message = lang[locale].customerData_missing_permissions;
            break;
        case "customerData_missing-relation":
            message = lang[locale].customerData_missing_relation;
            break;
        case "customerOrders_missing-permissions":
            message = lang[locale].customerOrders_missing_permissions;
            break;
        case "customerOrders_missing-relation":
            message = lang[locale].customerOrders_missing_relation;
            break;
        case "customer_missing-permissions":
            message = lang[locale].customer_missing_permissions;
            break;
        case "customer_missing-relation":
            message = lang[locale].customer_missing_relation;
            break;
        case "customer_parse-data":
            message = lang[locale].customer_parse_data;
            break;
        case "customers_missing-permissions":
            message = lang[locale].customers_missing_permissions;
            break;
        case "deleteCustomer_missing-permissions":
            message = lang[locale].deleteCustomer_missing_permissions;
            break;
        case "deleteCustomer_missing-relation":
            message = lang[locale].deleteCustomer_missing_relation;
            break;
        case "internal-error-delete":
            message = lang[locale].deleteUser_internal_error;
            break;
        case "deleteUser_missing-permissions":
            message = lang[locale].deleteUser_missing_permissions;
            break;
        case "deleteUser_missing-relation":
            message = lang[locale].deleteUser_missing_relation;
            break;
        case "forecastCreation_internal-error":
            message = lang[locale].forecastCreation_internal_error;
            break;
        case "forecastCreation_invalid_IST-timeframe":
            message = lang[locale].forecastCreation_invalid_IST_timeframe;
            break;
        case "forecastCreation_invalid-name":
            message = lang[locale].forecastCreation_invalid_name;
            break;
        case "forecastCreation_invalid-prog-timeframe":
            message = lang[locale].forecastCreation_invalid_prog_timeframe;
            break;
        case "forecastCreation_missing-permissions":
            message = lang[locale].forecastCreation_missing_permissions;
            break;
        case "forecastCreation_parse-data":
            message = lang[locale].forecastCreation_parse_data;
            break;
        case "forecastCreation_parsing-pricing-curve":
            message = lang[locale].forecastCreation_parsing_pricing_curve;
            break;
        case "forecastCreation_pricing-curve-empty":
            message = lang[locale].forecastCreation_pricing_curve_empty;
            break;
        case "forecastCreation_pricing-curve-not-found":
            message = lang[locale].forecastCreation_pricing_curve_not_found;
            break;
        case "forecastCreation_progcount-limit":
            message = lang[locale].forecastCreation_progcount_limit;
            break;
        case "supplierData_missing-permissions":
            message = lang[locale].supplierData_missing_permissions;
            break;
        case "supplierData_missing-relation":
            message = lang[locale].supplierData_missing_relation;
            break;
        case "supplierUsers_missing-permissions":
            message = lang[locale].supplierUsers_missing_permissions;
            break;
        case "supplierUsers_missing-relation":
            message = lang[locale].supplierUsers_missing_relation;
            break;
        case "updateCustomerInfo_internal-error":
            message = lang[locale].updateCustomerInfo_internal_error;
            break;
        case "updateCustomerInfo_missing-permissions":
            message = lang[locale].updateCustomerInfo_missing_permissions;
            break;
        case "updateCustomerInfo_missing-relation":
            message = lang[locale].updateCustomerInfo_missing_relation;
            break;
        case "updateCustomerInfo_parse-data":
            message = lang[locale].updateCustomerInfo_parse_data;
            break;
        case "updatePermissions_internal-error":
            message = lang[locale].updatePermissions_internal_error;
            break;
        case "updatePermissions_missing-permissions":
            message = lang[locale].updatePermissions_missing_permissions;
            break;
        case "updatePermissions_missing-relation":
            message = lang[locale].updatePermissions_missing_relation;
            break;
        case "updatePermissions_parse-data":
            message = lang[locale].updatePermissions_parse_data;
            break;
        case "upload_internal-error":
            message = lang[locale].upload_internal_error;
            break;
        case "upload_unsupported-media-type":
            message = lang[locale].upload_unsupported_media_type;
            break;
        case "users_missing-permissions":
            message = lang[locale].users_missing_permissions;
            break;
        case "users_missing-relation":
            message = lang[locale].users_missing_relation;
            break;
        case "orderFPL_TAN-expired":
            message = lang[locale].orderFPL_TAN_expired;
            break;
        case "orderFPL_no-TAN":
            message = lang[locale].orderFPL_no_TAN;
            break;
        case "orderFPL_yearlimit":
            message = lang[locale].orderFPL_yearlimit;
            break;
        case "orderFPL_monthlimit":
            message = lang[locale].orderFPL_monthlimit;
            break;
        case "orderFPL_orderlimit":
            message = lang[locale].orderFPL_orderlimit;
            break;
        case "orderFPL_unknown-ttpfm-error":
            message = lang[locale].orderFPL_unknown_ttpfm_error;
            break;
        case "orderFPL_bad-amount":
            message = lang[locale].orderFPL_bad_amount;
            break;
        case "orderFPL_bad-timeframe":
            message = lang[locale].orderFPL_bad_timeframe;
            break;
        case "orderFPL_internal-error":
            message = lang[locale].orderFPL_internal_error;
            break;
        case "orderFPL_missing-permissions":
            message = lang[locale].orderFPL_missing_permissions;
            break;
        case "orderFPL_missing-relation":
            message = lang[locale].orderFPL_missing_relation;
            break;
        case "orderFPL_missing-TAN":
            message = lang[locale].orderFPL_missing_TAN;
            break;
        case "orderFPL_ordered-recently":
            message = lang[locale].orderFPL_ordered_recently;
            break;
        case "orderFPL_parse-data":
            message = lang[locale].orderFPL_parse_data;
            break;
        case "orderFPL_TAN-invalid":
            message = lang[locale].orderFPL_TAN_invalid;
            break;
        case "missing-permissions":
            message = lang[locale].missing_permissions;
            break;
        case "internal-error":
            message = lang[locale].internal_error;
            break;
        case "confirmOrder_bad-token":
            message = lang[locale].confirmOrder_bad_token;
            break;
        case "confirmOrder_invalid-token":
            message = lang[locale].confirmOrder_invalid_token;
            break;
        case "createCustomer_customer-exists":
            message = lang[locale].createCustomer_customer_exists;
            break;
        case "createCustomer_customer-type-invalid":
            message = lang[locale].createCustomer_customer_type_invalid;
            break;
        case "createCustomer_name-too-long":
            message = lang[locale].createCustomer_name_too_long;
            break;
        case "deleteFPL_missing-relation":
            message = lang[locale].deleteFPL_missing_relation;
            break;
        case "deleteFPL_order-reference":
            message = lang[locale].deleteFPL_order_reference;
            break;
        case "forecastCreation_deceed-usage-limit":
            message = lang[locale].forecastCreation_deceed_usage_limit;
            break;
        case "forecastCreation_exceed-usage-limit":
            message = lang[locale].forecastCreation_exceed_usage_limit;
            break;
        case "generateTAN_confirmation-emails-conflict":
            message = lang[locale].generateTAN_confirmation_emails_conflict;
            break;
        case "generateTAN_confirmation-emails-missing":
            message = lang[locale].generateTAN_confirmation_emails_missing;
            break;
        case "generateTAN_exceed-limit":
            message = lang[locale].generateTAN_confirmation_emails_missing;
            break;
        case "generateTAN_invalid-data":
            message = lang[locale].generateTAN_invalid_percentage;
            break;
        case "generateTAN_parse-data":
            message = lang[locale].generateTAN_parse_data;
            break;
        case "generateTAN_price-changed":
            message = lang[locale].generateTAN_price_changed;
            break;
        case "generateTAN_price-invalid":
            message = lang[locale].generateTAN_price_invalid;
            break;
        case "generateTAN_send-email-error":
            message = lang[locale].generateTAN_send_email_error;
            break;
        case "generateTAN_send-confirmation-emails":
            message = lang[locale].generateTAN_send_confirmation_emails;
            break;
        case "generateTAN_ordered-recently":
            message = lang[locale].generateTAN_ordered_recently;
            break;
        case "generateTAN_process-in-progress":
            message = lang[locale].generateTAN_process_in_progress;
            break;
        case "getCSV_missing-relation":
            message = lang[locale].getCSV_missing_relation;
            break;
        case "getFPLHeadData_missing-relation":
            message = lang[locale].getFPLHeadData_missing_relation;
            break;
        case "getFPL_missing-relation":
            message = lang[locale].getFPL_missing_relation;
            break;
        case "getForecastInformationByName_missing-relation":
            message =
                lang[locale].getForecastInformationByName_missing_relation;
            break;
        case "getForecastInformationByName_parse-data":
            message = lang[locale].getForecastInformationByName_parse_data;
            break;
        case "getForecastInformation_missing-relation":
            message = lang[locale].getForecastInformation_missing_relation;
            break;
        case "getFullFPLData_missing-relation":
            message = lang[locale].getFullFPLData_missing_relation;
            break;
        case "getHistoryData_missing-relation":
            message = lang[locale].getHistoryData_missing_relation;
            break;
        case "getIntradayData_missing-relation":
            message = lang[locale].getIntradayData_missing_relation;
            break;
        /* case "getLatestUserData_invalid_timestamp":
            message = lang[locale].getLatestUserData_invalid_timestamp;
            break; */
        case "getOrderStatus_missing-relation":
            message = lang[locale].getOrderStatus_missing_relation;
            break;
        case "getPriceBounds_missing-relation":
            message = lang[locale].getPriceBounds_missing_relation;
            break;
        case "getPriceBounds_parse-data":
            message = lang[locale].getPriceBounds_parse_data;
            break;
        case "login-failed":
            message = lang[locale].login_failed;
            break;
        case "login_parse-data":
            message = lang[locale].login_parse_data;
            break;
        case "orderFPL_pending-confirmation":
            message = lang[locale].orderFPL_pending_confirmation;
            break;
        case "orderFPL_send-email-error":
            message = lang[locale].orderFPL_send_email_error;
            break;
        case "pricing_missing-relation":
            message = lang[locale].pricing_missing_relation;
            break;
        case "resetPassswordRequest_email-invalid":
            message = lang[locale].resetPassswordRequest_email_invalid;
            break;
        case "resetPasswordRequest_missing-email":
            message = lang[locale].resetPasswordRequest_missing_email;
            break;
        case "resetPassword_password-empty":
            message = lang[locale].resetPassword_password_empty;
            break;
        case "resetPassword_password-missing-special-chars":
            message = lang[locale].resetPassword_password_missing_special_chars;
            break;
        case "resetPassword_password-short":
            message = lang[locale].resetPassword_password_short;
            break;
        case "resetPassword_signature-expired":
            message = lang[locale].resetPassword_signature_expired;
            break;
        case "resetPassword_signature-invalid":
            message = lang[locale].resetPassword_signature_invalid;
            break;
        case "resetPassword_token-invalid":
            message = lang[locale].resetPassword_token_invalid;
            break;
        case "setPriceBound_missing-relation":
            message = lang[locale].setPriceBound_missing_relation;
            break;
        case "setPriceBounds_lower-greater-upper":
            message = lang[locale].setPriceBounds_lower_greater_upper;
            break;
        case "setPriceBounds_parse-data":
            message = lang[locale].setPriceBounds_parse_data;
            break;
        case "setStatus_missing-relation":
            message = lang[locale].setStatus_missing_relation;
            break;
        case "switchPricing_parse-data":
            message = lang[locale].switchPricing_parse_data;
            break;
        case "updateCustomerCharge_bad-data":
            message = lang[locale].updateCustomerCharge_bad_data;
            break;
        case "updateCustomerCharge_missing-relation":
            message = lang[locale].updateCustomerCharge_missing_relation;
            break;
        case "updatePermissions_privilege-escalation":
            message = lang[locale].updatePermissions_privilege_escalation;
            break;
        case "updateSupplierCharge_bad-data":
            message = lang[locale].updateSupplierCharge_bad_data;
            break;
        case "updateSupplierCharge_missing-relation":
            message = lang[locale].updateSupplierCharge_missing_relation;
            break;
        case "updateUser_current-password-mismatch":
            message = lang[locale].updateUser_current_password_mismatch;
            break;
        case "updateUser_email-exists":
            message = lang[locale].updateUser_email_exists;
            break;
        case "updateUser_email-too-long":
            message = lang[locale].updateUser_email_too_long;
            break;
        case "updateUser_empty-data":
            message = lang[locale].updateUser_empty_data;
            break;
        case "updateUser_invalid-email":
            message = lang[locale].updateUser_invalid_email;
            break;
        case "updateUser_missing-current-password":
            message = lang[locale].updateUser_missing_current_password;
            break;
        case "updateUser_name-too-long":
            message = lang[locale].updateUser_name_too_long;
            break;
        case "updateUser_name-too-short":
            message = lang[locale].updateUser_name_too_short;
            break;
        case "updateUser_parse-data":
            message = lang[locale].updateUser_parse_data;
            break;
        case "updateUser_password-missing-special-chars":
            message = lang[locale].updateUser_password_missing_special_chars;
            break;
        case "updateUser_password-too-short":
            message = lang[locale].updateUser_password_too_short;
            break;
        // t2fa
        case "2fa_missing-token":
            message = lang[locale].tfa_missing_token;
            break;
        case "2fa_token-missmatch":
            message = lang[locale].tfa_token_mismatch;
            break;
        case "2fa_validation-failed":
            message = lang[locale].tfa_validation_failed;
            break;
        case "activate2FA_bad-data":
            message = lang[locale].tfa_bad_data;
            break;
        case "activate2FA_not-found":
            message = lang[locale].tfa_not_found;
            break;
        case "start2FA_cannot-overwrite":
            message = lang[locale].tfa_cannot_overwrite;
            break;
        case "login_expired":
            message = lang[locale].login_expired;
            break;
        case "generateTAN_no-trading-day":
            message = lang[locale].generateTAN_no_trading_day;
            break;
        case "2fa_token-mismatch":
            message = lang[locale].twofa_token_mismatch;
            break;
        case "tfa-token-mismatch":
            message = lang[locale].twofa_token_mismatch;
            break;
        case "invalid-user-count":
            message = lang[locale].invalidUserCount;
            break;
        case "generateTAN_price-too-old":
            message = lang[locale].tooOldPrice;
            break;
        default:
            message = lang[locale].errorGeneric;
            break;
    }
    return message;
}

export default errorCodeTranslator;
