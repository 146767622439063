import { AnyAction } from "redux";
import LoadcurvePriceHistory from "../typeDefs/LoadcurvePriceHistory";
import pricecurveshowerConstants from "../constants/pricecurveshower.constants";

const initialState: LoadcurvePriceHistory = {
    aufschlag: 0,
    dat: new Date(),
    erstellungsdatum: "",
    // kennung: 0,
    preis: 0,
    zrid: 0,
    kombinierterPreis: [],
};
/**
 * Reducer for opening and closing the snackbar
 * @param state State
 * @param action Action
 */
export function pricecurveshower(
    state: LoadcurvePriceHistory = initialState,
    action: AnyAction
) {
    switch (action.type) {
        case pricecurveshowerConstants.SET:
            return {
                ...state,
                // kennung: action.kennung,
                erstellungsdatum: action.erstellungsdatum,
                zrid: action.zrid,
            };
        default:
            return state;
    }
}
