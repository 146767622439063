import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import LoginComponent from "components/Login";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  useTheme,
} from "@material-ui/core";
// import { config } from "services/helpers/Constants";
import ResetComponent from "components/Reset";
import SetNewPasswordComponent from "../components/SetNewPassword";
// import { GB,DE } from 'country-flag-icons/react/3x2';

/**
 * View for authenticating the user, wether registering or logging in
 */
function AuthenticationView() {
  const [locale, setLocale] = React.useState<string>(
    localStorage.locale ? localStorage.locale : "de"
  );

  const theme = useTheme();
  //handles the languagechange
  const handleChange = (event) => {
    setLocale(event.target.value);
    localStorage.locale = event.target.value;
  };

  useEffect(() => {
    document.title = "EMDACS";
  }, []);

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: "1em",
          left: "1em",
        }}
      >
        <Typography variant="h5" style={{ color: theme.palette.text.disabled }}>
          EMDACS
        </Typography>
      </div>

      <Grid
        container
        justify="center"
        style={{ background: theme.palette.background.default }}
        alignItems="center"
        className="maximalHeight"
      >
        <Grid item xs={11} lg={3} md={8}>
          <div
            style={{ background: theme.palette.background.paper, position : "relative" }}
            className="loginDiv boxShadowDecent"
          >
            <FormControl
              style={{
                position: "absolute",
                right: "2.5em",
                top: "4.5em"
              }}
            >
              <Select
                value={localStorage.locale ? localStorage.locale : "de"}
                onChange={handleChange}
                // style={{width : "20%"}}
              >
                <MenuItem value={"de"}>Deutsch</MenuItem>
                <MenuItem value={"en"}>English</MenuItem>
              </Select>
            </FormControl>
            <Route exact path="/auth/login/:confirmed?">
              <LoginComponent locale={locale} />
            </Route>
            <Route exact path="/auth/reset">
              <ResetComponent locale={locale} />
            </Route>
            <Route exact path="/auth/setPassword/:key">
              <SetNewPasswordComponent locale={locale} />
            </Route>
          </div>
          {/* <Grid item xs={12}>
                        <Grid container justify="center">
                            <Typography variant="caption" color="textSecondary">
                                Version {config.version}
                            </Typography>
                        </Grid>
                    </Grid> */}
        </Grid>
      </Grid>
    </>
  );
}

export default AuthenticationView;
