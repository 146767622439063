import { createMuiTheme } from "@material-ui/core/styles";
import { deDE } from "@material-ui/x-grid";

const theme = createMuiTheme(
    {
        palette: {
            type: "dark",
            primary: {
                main: "#0983B1",
            },
            secondary: {
                main: "#FAB515",
            },
        },
    },
    deDE
);

export default theme;
