import React, {  useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  ListItemText,
} from "@material-ui/core";
import { lang } from "../lang";
import AuthService from "../services/auth/AuthService";
import { useDispatch } from "react-redux";
import snackbarActions from "../actions/snackbar.actions";
// import authActions from "../actions/auth.actions";
import { useSelector } from "../services/helpers/useSelector";

const service = new AuthService();

function SetNewPasswordProfileComponent(props) {
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const dispatch = useDispatch();
  const locale = localStorage.locale;
  const { min_password_length, max_password_length } = useSelector(
    (state) => state.constants
  );

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    //reset password on api
    service
      .resetPersonalInformation({
        oldpassword: oldPassword,
        password: newPassword,
      })
      .then(() => {
        setLoading(false);
        setOldPassword("");
        setNewPassword("");
        setRepeatPassword("");
        dispatch(
          snackbarActions.openSnackbar(
            lang[locale].newPasswordSuccess,
            "success"
          )
        );
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  }

  function checkDisabled() {
    return oldPassword === "" || newPassword === "" || repeatPassword === "" || oldPassword.length > 50 || newPassword.length > 50 || repeatPassword.length > 50;
  }

  function checkNewPassword(): string[] {
    const check: string[] = [];
    if (newPassword.length < min_password_length) {
      check.push(
        lang[locale].minimum + min_password_length + lang[locale].characters
      );
    }
    if (newPassword.length > max_password_length) {
      check.push(
        lang[locale].maximum + max_password_length + lang[locale].characters
      );
    }
    if (newPassword === oldPassword) {
      check.push(lang[locale].passwordDifferent);
    }
    if (!newPassword.match(/\d/)) {
      check.push(lang[locale].numberCharacter);
    }
    if (!newPassword.match(/[a-z]/)) {
      check.push(lang[locale].lowercaseCharacter);
    }
    if (!newPassword.match(/[A-Z]/)) {
      check.push(lang[locale].uppercaseCharacter);
    }
    if (!newPassword.match(/[^a-zA-Z\d]/)) {
      check.push(lang[locale].specialCharacter);
    }
    return check;
  }

  function checkRepeatPassword(): string[] {
    const check: string[] = [];

    if (repeatPassword !== newPassword) {
      check.push(lang[locale].passwordMatch);
    }
    return check;
  }

  const pasteVerbote = () => {
    dispatch(snackbarActions.openSnackbar(lang[locale].copyPastePassVerbote, "warning"));
  };
  
  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Typography variant="subtitle2" color="inherit" gutterBottom>
          {lang[locale].setNewPasswordSubtitle}
        </Typography>
        <Grid container direction="column" spacing={2}>
          <ul>
            <li>
              <Typography variant="subtitle2">
                {lang[locale].minimum +
                  min_password_length +
                  lang[locale].characters}
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle2">
                {lang[locale].uppercaseCharacter}
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle2">
                {lang[locale].lowercaseCharacter}
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle2">
                {lang[locale].numberCharacter}
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle2">
                {lang[locale].specialCharacter}
              </Typography>
            </li>
          </ul>

          <Grid item xs={4}>
            <TextField
              value={oldPassword}
              id="oldPassword"
              label={lang[locale].oldPassword}
              className="maximalWidth"
              type="password"
              variant="outlined"
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
              required
              error={oldPassword.length > 50}
              autoComplete="off"
              helperText={oldPassword.length > 50 && lang[locale].morethanAllowed}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              onPaste={(e) => {
                e.preventDefault();
                pasteVerbote();
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              value={newPassword}
              id="newPassword"
              label={lang[locale].newPassword}
              className="maximalWidth"
              type="password"
              variant="outlined"
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              required
              error={newPassword.length > 50}
              autoComplete="off"
              helperText={newPassword.length > 50 && lang[locale].morethanAllowed}
            />
          </Grid>
          {newPassword !== "" && (
            <Grid item xs={4}>
              {checkNewPassword().map((entry) => (
                <ListItemText>
                  <Typography
                    variant="caption"
                    color="error"
                    data-testid="date-error"
                  >
                    {entry}
                  </Typography>
                </ListItemText>
              ))}
            </Grid>
          )}
          <Grid item xs={4}>
            <TextField
              onPaste={(e) => {
                e.preventDefault();
                pasteVerbote();
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              value={repeatPassword}
              id="repeatPassword"
              label={lang[locale].repeatPassword}
              className="maximalWidth"
              type="password"
              variant="outlined"
              onChange={(e) => {
                setRepeatPassword(e.target.value);
              }}
              required
              error={repeatPassword.length > 50}
              autoComplete="off"
              helperText={repeatPassword.length > 50 && lang[locale].morethanAllowed}
            />
          </Grid>
          {repeatPassword !== "" && (
            <Grid item xs={4}>
              {checkRepeatPassword().map((entry) => (
                <Typography
                  variant="caption"
                  color="error"
                  data-testid="date-error"
                >
                  {entry}
                </Typography>
              ))}
            </Grid>
          )}
          <Grid item xs={4}>
            <Button
              variant="contained"
              // color="primary"
              style={{backgroundColor : checkDisabled() ||
                loading ||
                checkNewPassword().length !== 0 ||
                checkRepeatPassword().length !== 0 ? "#595959" : props.coloR.primary, color : checkDisabled() ||
                loading ||
                checkNewPassword().length !== 0 ||
                checkRepeatPassword().length !== 0 ? "#898989" : "white"}}
              type="submit"
              data-testid="submitReset"
              disabled={
                checkDisabled() ||
                loading ||
                checkNewPassword().length !== 0 ||
                checkRepeatPassword().length !== 0
              }
            >
              {loading ? <CircularProgress size={30} /> : lang[locale].send}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default SetNewPasswordProfileComponent;
