import { AnyAction } from "redux";
import { priceConstants } from "../constants/price.constants";
import PriceState from "../typeDefs/PriceState";

const initial: PriceState = {
    active: false,
    upper: 0,
    lower: 0,
    loading: false,
    error: undefined,
};

/**
 * Reducer for keeping the price bounds
 * @param state
 * @param action
 */
export function price(state: PriceState = initial, action: AnyAction) {
    switch (action.type) {
        case priceConstants.TOGGLE_ACTIVE_REQUEST:
        case priceConstants.SAVE_BOUNDS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case priceConstants.GET_BOUNDS_REQUEST:
            return {
                ...initial,
                loading: true,
            };
        case priceConstants.SAVE_BOUNDS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: undefined,
                lower: action.lower,
                upper: action.upper,
            };
        case priceConstants.GET_BOUNDS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: undefined,
                lower: action.lower,
                upper: action.upper,
                active: action.active,
            };
        case priceConstants.TOGGLE_ACTIVE_FAILURE:
        case priceConstants.SAVE_BOUNDS_FAILURE:
        case priceConstants.GET_BOUNDS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case priceConstants.TOGGLE_ACTIVE_SUCCESS:
            return {
                ...state,
                loading: false,
                active: action.active,
            };
        case priceConstants.PRICE_RESET:
            return {
                ...initial,
            };

        default:
            return state;
    }
}
