import { customerConstants } from "constants/customer.constants";

function update(customerData) {
    return {
        type: customerConstants.UPDATE_CUSTOMER,
        customerData,
    };
}

function updateAll(customers) {
    return {
        type: customerConstants.UPDATE_ALL_CUSTOMERS,
        customers,
    };
}

function get() {
    return { type: customerConstants.GET_CUSTOMER };
}

function getAll() {
    return { type: customerConstants.GET_ALL_CUSTOMERS };
}

function deleteSingle(customer) {
    
    return { type: customerConstants.DELETE_SINGLE_CUSTOMER, customer };
}

function updateSingle(customer) {
    return {
        type: customerConstants.UPDATE_SINGLE_CUSTOMER,
        customer,
    };
}

const customerActions = {
    getAll,
    updateAll,
    update,
    get,
    deleteSingle,
    updateSingle,
};

export default customerActions;
