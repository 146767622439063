import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "App.css";
import Loading from "components/Loading";
import AuthenticationView from "./Authentication";
import PrivateRouteComponent from "components/PrivateRoute";
import HomeView from "./Home";

import LogoutComponent from "components/Logout";
import ModalComponent from "components/Modal";
import SnackbarComponent from "components/Snackbar";

/**
 * Entrypoint of the app
 */
function App() {
    return (
        <>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <Loading />
                    </Route>
                    <Route path="/auth">
                        <AuthenticationView />
                    </Route>
                    <Route path="/logout">
                        <LogoutComponent />
                    </Route>
                </Switch>

                <PrivateRouteComponent path="/app">
                    <HomeView />
                </PrivateRouteComponent>
                <ModalComponent />
            </Router>
            <SnackbarComponent />
        </>
    );
}

export default App;
