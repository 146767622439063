import React from "react";
import {ContextMenu, MenuItem} from "react-contextmenu";
import {lang} from "../lang";


/**
 * Function for the context menu
 * @param handleContextClick handler from parent
 * @constructor
 */
function ContextmenuComponent({handleContextClick}) {
    const locale = localStorage.locale;
    return <ContextMenu id="context_menu">
        <MenuItem data={{startdate: true}} onClick={handleContextClick}>
            {lang[locale].startdate}
        </MenuItem>
        <MenuItem data={{startvalue: true}} onClick={handleContextClick}>
            {lang[locale].startvalue}
        </MenuItem>

        <MenuItem divider/>
        <MenuItem data={{enddate: true}} onClick={handleContextClick}>
            {lang[locale].enddate}
        </MenuItem>
        <MenuItem data={{endvalue: true}} onClick={handleContextClick}>
            {lang[locale].endvalue}
        </MenuItem>
    </ContextMenu>

}


export default ContextmenuComponent;
