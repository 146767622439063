import PortfolioModel from "../../typeDefs/PortfolioModel";
import { config } from "../helpers/Constants";
import errorCodeTranslator from "../helpers/errorCodeTranslator";

interface CreateResponse {
    message: string;
    status:string;
}

class PortfolioService {

    //Creates a portfolio for a specific customer
    public async createportfolio(
        data: PortfolioModel
        // customer_id?: number
    ) : Promise<CreateResponse> {

        const res = await fetch(config.url.API_URL + "/api/customer/" + data.customer_id + "/portfolio/create", {
            headers: {
                "content-type":  "application/json",
            },
            method : "POST",
            body:JSON.stringify(data),
        })
        // console.log("data",JSON.stringify(data) )
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        // console.log("json",json )
        return json;
    }

    // get all portfolios from a customer
    public async getAllPortfolios(id: number) : Promise<CreateResponse> {
        const res = await fetch(config.url.API_URL + "/api/customer/" + id +"/portfolio")
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    // get all offers those not in current portfolio
    public async getPortfoliosDifference(id: number, pfID: number) : Promise<CreateResponse> {
        const res = await fetch(config.url.API_URL + "/api/customer/" + id +"/portfolio/difference/" + pfID)
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    // get all portfolio Details
    public async getPortfolioDetails(id: number, pfID: number) : Promise<CreateResponse> {
        const res = await fetch(config.url.API_URL + "/api/customer/" + id +"/portfolio/" + pfID)
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    // get all portfolio Details
    public async getInPortfolio(id: number, pfID: number) : Promise<any> {
        const res = await fetch(config.url.API_URL + "/api/customer/" + id +"/in_portfolio/" + pfID)
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        console.log("in pfm", json)
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    //add Angebot to Portfolio
    public async addtoPortfolio(customerId, portfoilioId, zrid) : Promise<any> {

        const res = await fetch(config.url.API_URL + "/api/customer/" + customerId + "/portfolio/add/" + portfoilioId + "/" + zrid)
        // console.log("data",JSON.stringify(data) )
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        // const json = await res.json();
        // console.log("json", json)
        // if (res.status === 204) {
        //     return [];
        // }
        // if (res.status !== 202) {
        //     throw { message: errorCodeTranslator(json.message) };
        // }
        // console.log("json",json )
        // return json;
    }
    //set HedgeChannelvalues
    public async setHedgeChannalValues(customerId, portfoilioId, data) : Promise<any> {
        const res = await fetch(config.url.API_URL + "/api/customer/" + customerId + "/portfolio/hedge/set/" + portfoilioId, {
            headers: {
                "content-type":  "application/json",
            },
            method : "POST",
            body:JSON.stringify(data),
        })
        // console.log("data",JSON.stringify(data) )
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        // console.log("json",json )
        return json;
    }
        //set HedgeChannelvalues
    public async getHedgeChannalValues(customerId, portfoilioId) : Promise<any> {
        const res =  await fetch(config.url.API_URL + "/api/customer/" + customerId + "/portfolio/hedge/get/" + portfoilioId)
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }
        //delete Hedge values
        public async deleteHedgeChannalValues(customerId, portfoilioId){
            const res = await fetch(config.url.API_URL + "/api/customer/" + customerId + "/portfolio/hedge/delete/" + portfoilioId ,
                {
                    method: "DELETE",
                }
            );
            if (res.status === 401) {
                throw { message: res.status.toString() };
            }
            if(res.status === 201){
                return [];
            }
            if (res.status !== 200) {
                const json = await res.json();
                throw { message: errorCodeTranslator(json.message) };
            }
        }

    //delete angebote from Portfolio
    public async removeFromPortfolio(customer_id:number, portfolio_id:number, zrid:number){
        const res = await fetch(
            config.url.API_URL + "/api/customer/" + customer_id + "/portfolio/remove/" + portfolio_id + "/" + zrid ,
            {
                method: "DELETE",
            }
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if(res.status === 201){
            return [];
        }
        if (res.status !== 200) {
            const json = await res.json();
            throw { message: errorCodeTranslator(json.message) };
        }
    }

    //delete single Portfolio
    public async DeletePortfolio(customer_id:number, portfolio_id:number){
        const res = await fetch(
            config.url.API_URL + "/api/customer/" + customer_id + "/portfolio/delete/" + portfolio_id,
            {
                method: "DELETE",
            }
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if(res.status === 201){
            return [];
        }
        if (res.status !== 200) {
            const json = await res.json();
            throw { message: errorCodeTranslator(json.message) };
        }
    }
    

}

export default PortfolioService;