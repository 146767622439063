import React, { useEffect, useState } from "react";
import { lang } from "lang";
import { Grid, makeStyles } from "@material-ui/core";

//Hooks
import { useDispatch } from "react-redux";
import { useSelector } from "services/helpers/useSelector";
import { useLocation } from "react-router";

//Services
// import ForecastService from "../services/forecast/ForecastService";
import AuthService from "../services/auth/AuthService";

//Actions
import modalActions from "../actions/modal.actions";
import authActions from "../actions/auth.actions";
// import snackbarActions from "actions/snackbar.actions";
import forecastActions from "../actions/forecast.actions";

//Components
import SupplierPlansComponent from "./SupplierPlans";
import CustomerPlansComponent from "./CustomerPlans";
import CreateForecastComponent from "./CreateForecast";
import FilterComponentSupplier from "./FilterSupplier";
import FilterComponent from "./Filter";

const useStyles = makeStyles(() => ({
    grid: {
        padding: "2.5em 1.5em",
    },
}));

/**
 * Component for creating Plans, managing them and showing information
 */
function MyPlansComponent({ changeNavTitle }) {
    const locale = localStorage?.locale ? localStorage?.locale : "de";
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const role = AuthService.getRole();
    const { error } = useSelector((state) => state.loadcurve);
    const [filters, setFilters] = useState<string>("");

    //Change Nav and Document Title
    useEffect(() => {
        document.title = lang[locale].myplans + " | EMDACS";
        changeNavTitle(lang[locale].myplans);
    }, []);

    useEffect(() => {
        dispatch(authActions.checkLogin());
    }, []);

    //useeffect to get the loadcurves from the api
    useEffect(() => {
        const fleximportAbort = new URLSearchParams(location.search).get(
            "fleximportAbort"
        );

        if (fleximportAbort) {
            dispatch(modalActions.openModal(CreateForecastComponent));
            dispatch(forecastActions.resetFPLData());
        } else dispatch(forecastActions.reset());
    }, []);

    //if error, just show snackbar
    // useEffect(() => {
        // if (error)
        //     dispatch(
        //         snackbarActions.openSnackbar(lang[locale].notLoggedIn, "error")
        //     );
    // }, []);

    return (
        <Grid container spacing={4} className={classes.grid}>
            <Grid item xs={12}>
                {role.simple_role === "LIEFERANT" ? (
                    <FilterComponentSupplier
                        filters={filters}
                        setFilters={setFilters}
                        role={role}
                        setPEfilterChange={0}
                    ></FilterComponentSupplier>
                ) : (
                    <FilterComponent
                        filters={filters}
                        setFilters={setFilters}
                        role={role}
                        setPEfilterChange={0}
                    />
                )}
            </Grid>

            {/*  {loading && loadcurves === undefined && (
                <Grid item xs={12}>
                    <Grid container justify="center" alignItems="center">
                        <CircularProgress data-testid="plansloading" />
                    </Grid>
                </Grid>
            )} */}

            <Grid item xs={12}>
                {
                    <>
                        {role.simple_role === "LIEFERANT" ? (
                            <>
                                <SupplierPlansComponent filters={filters} />
                            </>
                        ) : (
                            <CustomerPlansComponent filters={filters} />
                        )}
                    </>
                }
            </Grid>
        </Grid>
    );
}

export default MyPlansComponent;
