import Language from ".";
/**
 * Object for english language
 */
const en: Language = {
    usernameOrPasswordWrong: "Username or password is wrong",
    loginButtonText: "Login",
    loginFormCompany: "Company",
    loginFormPassword: "Password",
    oldPassword: "Old Password",
    newPassword: "New Password",
    repeatPassword: "Repeat Password",
    loginFormUsername: "Username",
    forgotPassword: "Forgot your password?",
    resetPasswordText: "E-Mail Address",
    resetPasswordEmailError: "This is not a correct e-mail address",
    resetPasswordHeader: "Forgot your password?",
    resetPasswordSubtitle:
        "No problem. You will receive an email shortly with the resetting instructions",
    setNewPasswordSubtitle:
        "In order to protect your account, the following characters are required:",
    back: "Back",
    toLogin: "go to login",
    logout: "Logout",
    myprofile: "My Profile",
    myplans: "Offers",
    add: "Add",
    filters: "Filters",
    startdate: "Startdate",
    enddate: "Enddate",
    nofilter: "No Filters",
    deletefilters: "Delete filters",
    details: "Detailled",
    history: "HISTORY",
    intraday: "INTRADAY",
    notLoggedIn: "You are not logged in. Please go back and try again.",
    send: "Send",
    close: "Close",
    drop: "Drop files here to upload",
    dropHere: "Drop here",
    fileName: "Filename",
    fplUpload: "Upload loadcurve",
    maxFileSizeExceeded: "The maximum filesize is 5MB.",
    wrongFileType: "This filetype is not supported.",
    uploadSuccess: "File successfully uploaded",
    amount: "Amount",
    priceFrom: "Price from",
    noPriceGiven: "No price given",
    loadcurve: "Loadcurve",
    foreCast: "Forecast",
    gas: "Gas",
    power: "Power",
    period: "Period",
    general: "General",
    dateWrongError: "The enddate is before the startdate.",
    notSelected: "Nothing selected",
    forecastType: "Forecast type",
    profileType: "Profile type",
    performance: "Performance",
    usage: "Consumption",
    productType: "Product type",
    product: "Product",
    temperatureStation: "Temperature station",
    usageType: "Consumption type",
    holidayRegion: "Holidayregion",
    others: "Others",
    measured: "Measured",
    specific: "Specific",
    MeasuredConsumption: "Measured consumption",
    temperatureFinder: "Where do I find the nearest station?",
    email: "E-Mail",
    successFPL: "You have successfully created the loadcurve.",
    next: "Next",
    errorFieldsWrong: "You submitted a bad request.",
    errorNotEnoughPermissions: "You don't have enough permissions.",
    forecastSuccessfullyCreated:
        "The forecast is successfully created. It will appear shortly in your priceoverview.",
    progUpload: "Upload your forecastdata",
    istUpload: "Upload your data",
    dateSelectError:
        "This Date is in the wrong format. Please type it in manually.",
    actualCount: "Actual count",
    neededCount: "Needed count",
    timedifferenceValues: "Timedifference values",
    values: "Values",
    nullvalues: "Nullvalues",
    doubleHour: "Doubled hour",
    unit: "Unit",
    startvalue: "Start value",
    endvalue: "End value",
    energyAmount: "Energyamount",
    valuesIncorrect: "The values don't match the criteria.",
    noProvided: "No provided",
    startValueHigher: "The first value is higher than the last value.",

    forecastPeriod: "offer period",
    fullHour: "Full hour",
    quarterHour: "Quarter hour",
    valueAmount: "Value amount",
    export: "Export Plan",
    noLoadcurves: "No loadcurves available. Please add one.",
    noPendingLoadcurves: "No pending offers.",
    measuredEnd: "Measureend",
    measuredPeriod: "Measureperiod",
    measuredStart: "Measurestart",
    variant: "Variant",
    fplUploadMenuItem: "Upload FPL-file",
    inWork: "In progress",
    name: "Name",
    passwordResetTriggerFailure:
        "Something went wrong. Please check your details.",
    passwordResetTriggerSuccess:
        "Password reset successful. Please check your inbox.",
    resetPassword: "Reset password",
    resetPasswordError:
        "There was an error resetting your password. Please repeat.",
    resetPasswordSuccess: "Your password was reset successfuly.",
    deleteError: "Error deleting loadcurve with name:",
    cancel: "Cancel",
    reallyWantToDeleteWithName:
        "Do you really want to delete the loadcurve with name: ",
    confirm: "Confirm",
    deleteSuccess: "Successfully deleted loadcurve.",
    delete: "Delete",
    priceCurve: "Pricecurve",
    pricecurve: "Pricecurve",
    formula: "Formula",
    fixedprice : "Fixed Price",
    pricetype: "Price Type",
    type: "Type",
    priceCurveGetError:
        "Error getting price curves. Please log out and log in again.",
    priceCurveChoose: "Please choose a price curve",
    pricingInformation: "Pricing information",
    errorCreatingForecast: "Error creating forecast",
    additionalInformation: "Additional information",
    resetGraphic: "Reset Graphic",
    prognosisCurve: "Forecast Loadcurve",
    actualData: "Actual Data",
    price: "Price",
    recentPrice: "Recent Price",
    buy: "Buy",
    date: "Date",
    upperBound: "Upper Bound [€/MWh]",
    lowerBound: "Lower Bound [€/MWh]",
    setBounds: "Monitor price boundaries",
    usedIstData: "Used Data",
    boughtPlan: "Bought Plan",
    forecast: "Forecast",
    buyToFollowingPrice:
        "Do you really want to buy this loadcurve to the following specific price?  ",
    buySuccess: "Successfully bought loadcurve.",
    buyFailure: "Error buying loadcurve. Please try again later",
    buySelectSize: "Please choose a size",
    buyFullLoadcurve: "Buy full loadcurve",
    boughtAt: "Bought at",
    buypoint: "Purchase time",
    showOrders: "Order History",
    buyDate: "Buydate",
    dealticket: "Dealticket",
    offersId: "Offer-ID",
    offerName:"Offer name",
    percentage: "Percentage",
    cost: "Cost",
    noOrders: "No orders available.",
    showTimeChangeDay: "Show this day",
    createPriceHistory: "Create Price History",
    priceHistory : "Price History",
    prices : "Prices",
    buySingular: "Buy",
    creationTime: "Creation time",
    shouldNotBeBeforeEndDate: "This date should not be before the end date.",
    shouldNotBeBeforeStartDate: "This date should not be after the start date.",
    search: "Search",
    priceCurveName: "Pricecurve name",
    filterDateHelper:
        "Selects all loadcurves inbetween this period. Includes the dates selected.",
    uploadExistingForecast: "Upload existing forecast",
    createForecast: "Create forecast",
    dropIst:
        "Please drop your usage file (Excel, IST, TXT) to upload it. Otherwise click to open a dialog.",
    dropProg:
        "Please drop your forecast file (Excel, IST, TXT) to upload it. Otherwise click to open a dialog.",
    priceCurveInformation: "Pricecurveinformation",
    priceCurveID: "Pricecurve ID",
    priceCurveCreationDate: "Creation Date",
    priceWatching: "Price watching is active",
    users: "User Management",
    addUser: "Add user",
    userUpdated: "Updated User successfully",
    userCreated: "User was successfully created without rights. Please assign the user appropriate rights.",
    userByuserCreated: "User was successfully created with the rights you have. However, the right to trade is never transferred and must always be requested from the supplier!",
    addCustomer: "Add customer",
    firstName: "First name",
    lastName: "Last name",
    isSupplier: "Supplier",
    inIndustrieCustomer: "Industrial Customer",
    chooseCustomer: "Choose customer",
    permissions: "Permissions",
    successCustomerUpdate: "Success updating customer.",
    successCustomerCreation: "Customer was successfully created without rights. Please assign the customer appropriate rights",
    errorCustomerCreation: "Error creating customer.",
    customerName: "Customer name",
    customerId: "Customer ID",
    supplierUser: "Supplier-User",
    supplier: "Supplier",
    customer: "Customer",
    maxProg: "Maximal Forecasts",
    liquidity: "Liquidity",
    editCustomer: "Edit customer",
    editUser: "Edit user",
    limitMonth: "Monthly Limit [€]",
    limitOrder: "Order Limit [€]",
    parallelBuyWarning: "Attention: In the meantime, further purchases have been made for this forecast. If necessary, please check the purchase history before proceeding.",
    flexTranche: "The average tranche amount is outside the permissible range of 5 MWh - 1000000 MWh, current tranche amount: ",
    limitYear: "Year Limit [€]",
    Year : "Year",
    allOrders: "All Orders",
    expireDate: "Expire Date",
    deleteUser:
        "Do you really want do delete the user with the following email address?",
    deleteCustomer:
        "Do you really want to delete the customer with the following information?",
    deletePortfolio: "Do you really want to delete the portfolio?",
    errorGeneric: "An error occured, please try again later.",
    TTPFM_si_insert: "Error during import.",
    createCustomer_internal_error: "An internal error occured.",
    createCustomer_missing_permissions:
        "You do not have the required permission.",
    createCustomer_missing_relation: "Missing relation - Supplier.",
    createCustomer_parse_data: "The input data is incorrect.",
    createForecastForCustomer_missing_permissions:
        "You do not have the required permission.",
    createForecastForCustomer_missing_relation: "Missing relation - Customer.",
    createForecastForCustomer_unknownFunction: "",
    createForecast_unknownFunction: "",
    createISTData_parse_data: "The input data is incorrect.",
    createUser_customer_relation:
        "The created user was not assigned to any Customer.",
    createUser_internal_error: "An internal error occured.",
    createUser_missing_permissions: "You do not have the required permission.",
    createUser_missing_relation: "Missing relation - Customer.",
    createUser_parse_data: "The input data is incorrect.",
    createUser_privilege_escalation:
        "You cannot assign higher permissions when creating.",
    createUser_user_relation:
        "A supplier may not be assigned to a Customer-ID.",
    createUser_user_already_exists: "The user already exists.",
    customerData_internal_error: "An internal error occured.",
    customerData_missing_permissions:
        "You do not have the required permission.",
    customerData_missing_relation: "Missing relation - Customern.",
    customerOrders_missing_permissions:
        "You do not have the required permission.",
    customerOrders_missing_relation: "Missing relation - Customern.",
    customer_missing_permissions: "You do not have the required permission.",
    customer_missing_relation: "Missing relation - Customern.",
    customer_parse_data: "The input data is incorrect.",
    customers_missing_permissions: "You do not have the required permission.",
    deleteCustomer_missing_permissions:
        "You do not have the required permission.",
    deleteCustomer_missing_relation: "Missing relation - Customern.",
    deleteUser_internal_error: "An internal error occured.",
    deleteUser_missing_permissions: "You do not have the required permission.",
    deleteUser_missing_relation: "Missing relation - User.",
    forecastCreation_internal_error: "An internal error occured.",
    forecastCreation_invalid_IST_timeframe: "Please check the selected period.",
    forecastCreation_invalid_name: "The name you have chosen is invalid.",
    forecastCreation_invalid_prog_timeframe:
        "Please check the selected period.",
    forecastCreation_missing_permissions:
        "You do not have the required permission.",
    forecastCreation_parse_data: "The input data is incorrect. Please recreate the forecast.",
    forecastCreation_parsing_pricing_curve: "Invalid price curve.",
    forecastCreation_pricing_curve_empty:
        "The price curve was transmitted empty.",
    forecastCreation_pricing_curve_not_found:
        "The price curve could not be found.",
    forecastCreation_progcount_limit:
        "You have already reached your maximum forecasts.",
    supplierData_missing_permissions:
        "You do not have the required permission.",
    supplierData_missing_relation: "",
    supplierUsers_missing_permissions:
        "You do not have the required permission.",
    supplierUsers_missing_relation: "Missing relation - User.",
    updateCustomerInfo_internal_error: "An internal error occured.",
    updateCustomerInfo_missing_permissions:
        "You do not have the required permission.",
    updateCustomerInfo_missing_relation: "Missing relation - Customern.",
    updateCustomerInfo_parse_data: "The input data is incorrect.",
    updatePermissions_internal_error: "An internal error occured.",
    updatePermissions_missing_permissions:
        "You do not have the required permission.",
    updatePermissions_missing_relation: "Missing relation - Customern.",
    updatePermissions_parse_data: "The input data is incorrect.",
    upload_internal_error: "An internal error occured.",
    upload_unsupported_media_type: "The uploaded file format is not supported.",
    users_missing_permissions: "You do not have the required permission.",
    users_missing_relation: "Missing relation - User.",
    orderFPL_TAN_expired: "The TAN has already expired.",
    orderFPL_no_TAN: "BACK-END: There was no TAN in the table.",
    orderFPL_yearlimit: "This order exceeds your annual limit.",
    orderFPL_monthlimit: "This order exceeds your monthly limit.",
    orderFPL_orderlimit: "This order exceeds your order limit.",
    orderFPL_unknown_ttpfm_error: "An unknown error occurred.",
    orderFPL_bad_amount: "The selected amount is too small.",
    orderFPL_bad_timeframe: "The forecast start is in the past.",
    orderFPL_internal_error: "An internal error occurred.",
    orderFPL_missing_permissions: "You do not have the required permissions.",
    orderFPL_missing_relation: "Missing relation - Forecast.",
    orderFPL_missing_TAN: "Unfortunately, you have not transmitted a TAN.",
    orderFPL_ordered_recently:
        "This loadcurve has already been purchased within the last 5 minutes.",
    orderFPL_parse_data: "",
    orderFPL_TAN_invalid: "The TAN entered is invalid.",
    creator: "Creator",
    buyer: "Buyer",
    nameTooShortFirst: "Name too short. Name should have at least ",
    nameTooShortSecond: "characters.",
    nameTooLongFirst: "Name too long. Name should be max. ",
    nameTooLongSecond: " characters.",
    wrongCharacters: "Invalid characters.",
    invalidDate: "Invalid Date",
    usageTooSmallYear: "Yearly consumption must be at least ",
    usageTooSmallDay: " Daily consumption must be at least ",
    usageTooBigYear: "Yearly consumption must be less than ",
    usageTooBigDay: "Daily consumption must be less than ",
    usageTooSmallMeasured: "Measured consumption must be at least ",
    usageTooBigMeasured: "Measured consumption must be less than ",
    usageSecond: "",
    performanceTooSmall: "Performance must be at least ",
    performanceTooBig: "Performance must be less than ",
    toDateTooBig: "Max. end date: ",
    fromDateTooSmall: "Max. starting date: ",
    selectCustomer: "Select customer",
    biggerThanMaxDate: "Date too big. Max Date is",
    biggerThanMaxDateTest: "Date too big. Max Date in Test Version is",
    smallerThanMinDate: "Date too small. Min Date is",
    smallerThanMinDateTest: "Date too small. Min Date in Test Version is",
    progcount: "Number of offers",
    password: "Password",
    invalidEmail: "Invalid email",
    passwordMatch: "Passwords need to match",
    changePassword: "Change Password",
    changeEmail: "Change Email",
    changeName: "Name ändern",
    passwordDifferent: "Old and new password must be different",
    newPasswordSuccess: "You changed your password successfully",
    newEmailSuccess: "You changed your email address successfully",
    newNameSuccess: "You changed your name successfully",
    newChargesSuccess: "You changed the global charges successfully",
    confirmation: "Valdiators",
    finalBuy: "Commit to buy",
    specificPrice: "Specific price",
    percentageForecast: "Percentage share of the forecast",
    pleaseEnterTan:
        "Please enter the TAN that we have sent to your email address.",
    remainingTime: "Remaining time",
    time: "Time",
    confirmationStatus: "Confirmation status",
    perBuy: "per buy",
    perMonth: "per month",
    perYear: "per year",
    fourEyesPrinciple: "4-eyes-principle",

    surchargePowerPrice: "Additional surcharge Power Price Curve [€/MWh]",
    surchargeGasPrice: "Additional surcharge Gas Price Curve [€/MWh]",
    surchargePowerSpot: "Additional surcharge Power Spot [€/MWh]",
    surchargeGasSpot: "Additional surcharge Gas Spot [€/MWh]",
    surchargePowerFormel: "Additional surcharge Power Formel [€/MWh]",
    surchargeGasFormel: "Additional surcharge Gas Formel [€/MWh]",

    surcharges: "Surcharges",
    prognosisCustomer: "Prognosis customer",
    endCustomer: "End customer",
    industrialCustomer: "industrial customer",
    buyHelperText: "Number needs to be between 1% and 500%",
    buyPrognosis: "Buy prognosis",
    purchaseLimitExhausted:
        "Purchase limit exhausted. No further purchases can be made.",
    purchaseLimitExceededOne: "Purchase limit exceeded. Maximal ",
    purchaseLimitExceededTwo: "% of the current forecast can be bought.",
    purchaseCannotBeBought:
        "Prognosis cannot be bought because the maximum buyable percentage is less than 1.",
    globalStromF: "POWER Price curve Forecastcustomer [€/MWh]",
    globalStromE: "POWER Price curve Consumer [€/MWh]",
    globalStromI: "POWER Industriecustomer [€/MWh]",
    globalStromSpotF: "POWER Spot Forecastcustomer [€/MWh]",
    globalStromSpotE: "POWER Spot Consumer [€/MWh]",
    globalGasF: "GAS Price curve Forecastcustomer [€/MWh]",
    globalGasE: "GAS Price curve Consumer [€/MWh]",
    globalGasI: "GAS Industriecustomer [€/MWh]",
    globalGasSpotF: "GAS Spot Forecastcustomer [€/MWh]",
    globalGasSpotE: "GAS Spot Consumer [€/MWh]",
    globalCharges: "Global charges [€/MWh]",
    buyTimeLimit: "Purchase time setting",
    buyTime: "Purchase time day",
    buyTimeDeleteConfirm : "are you sure you want to delete the purchase time of the specified day",
    charge: "Charge",
    missing_permissions: "You do not have the required permissions",
    internal_error: "An internal error occurred",
    confirmOrder_bad_token: "The entered token is incorrect",
    confirmOrder_invalid_token: "The entered token is invalid",
    createCustomer_customer_exists: "The customer or customer number to be created already exists",
    createCustomer_customer_type_invalid:
        "A customertype that is not valid has been selected.",
    createCustomer_name_too_long: "The chosen customer name is too long",
    createUser_email_error:
        "The email with the initial password could not be sent",
    createUser_email_too_long: "The specified mail address is too long",
    createUser_empty_data: "Not all required fields have been filled in",
    createUser_invalid_email: "The emails is invalid",
    createUser_name_too_long: "The chosen username is too long",
    createUser_name_too_short: "The chosen username is too short",
    deleteFPL_missing_relation:
        "You do not have an assignment to the FPL to be deleted",
    deleteFPL_order_reference:
        "You cannot delete a FPL which was already bought",
    forecastCreation_deceed_usage_limit: "The power specified is too low",
    forecastCreation_exceed_usage_limit: "The specified power is too high",
    generateTAN_confirmation_emails_conflict:
        "The specified verifier is not in the system",
    generateTAN_confirmation_emails_missing:
        "Your purchase limit has not been set, please contact ",
    generateTAN_exceed_limit: "You have reached your TAN limit",
    generateTAN_invalid_data:
        "Incorrect data was transmitted to generate the TAN",
    generateTAN_invalid_percentage:
        "The specified quantity (percentage) is not valid",
    generateTAN_parse_data:
        "An error occurred during the transmission of the data",
    generateTAN_price_changed: "The price for your profile has changed since the TAN was generated and can no longer be guaranteed. Please check the new price and start the purchase process again.",
    generateTAN_price_invalid: "The price is invalid",
    generateTAN_send_email_error: "Could not send TAN email",
    generateTAN_send_confirmation_emails:
        "An error occurred while sending the verification mail",
    generateTAN_ordered_recently:
        "This loadcurve has already been purchased within the last 5 minutes.",
    getCSV_missing_relation:
        "You do not have an assignment to the data to be exported",
    getFPLHeadData_missing_relation: "You do not have an assignment to the FPL",
    getFPL_missing_relation: "You do not have an assignment to the FPL",
    getForecastInformationByName_missing_relation:
        "You do not have an assignment to the forecast",
    getForecastInformationByName_parse_data:
        "An error occurred during the transmission of the data",
    getForecastInformation_missing_relation:
        "You do not have an assignment to the forecast",
    getFullFPLData_missing_relation: "You do not have an assignment to the FPL",
    getHistoryData_missing_relation:
        "You do not have an assignment to the history",
    getIntradayData_missing_relation:
        "You do not have an assignment to the intraday data",
    getLatestUserData_invalid_timestamp: "",
    getOrderStatus_missing_relation:
        "You do not have an assignment to the purchase order",
    getPriceBounds_missing_relation:
        "You do not have an assignment to the price bounds",
    getPriceBounds_parse_data:
        "An error occurred during the transmission of the data",
    login_failed: "Login attempt failed",
    login_parse_data: "An error occurred while submitting the login data",
    orderFPL_pending_confirmation:
        "The confirmation of the purchase is pending",
    orderFPL_send_email_error: "An error occurred while sending the email",
    pricing_missing_relation: "You do not have an assignment to the price",
    resetPassswordRequest_email_invalid:
        "The specified mail address is invalid",
    resetPasswordRequest_missing_email: "No mail address was provided",
    resetPassword_password_empty: "The password is empty",
    resetPassword_password_missing_special_chars:
        "The password does not contain special characters",
    resetPassword_password_short: "The password is too short",
    resetPassword_signature_expired: "The reset token has expired",
    resetPassword_signature_invalid: "The reset token signature is invalid",
    resetPassword_token_invalid: "The entered reset token is invalid",
    setPriceBound_missing_relation:
        "You do not have an assignment to the price bounds",
    setPriceBounds_lower_greater_upper:
        "The lower price bound must not be higher than the upper price bound",
    setPriceBounds_parse_data: "An error occurred while transmitting the data",
    setStatus_missing_relation:
        "You do not have an assignment to the timetable",
    switchPricing_parse_data:
        "An error occurred during the transmission of the data",
    updateCustomerCharge_bad_data: "The entered customer markup is invalid",
    updateCustomerCharge_missing_relation:
        "You do not have an assignment to this customer",
    updatePermissions_privilege_escalation:
        "An attempt was made to grant higher permissions than possible with this license",
    updateSupplierCharge_bad_data: "The entered supplier surcharge is invalid",
    updateSupplierCharge_missing_relation:
        "You do not have an assignment to the supplier",
    updateUser_current_password_mismatch: "The password entered is incorrect",
    updateUser_email_exists: "Email already in use",
    updateUser_email_too_long: "The new mail address is too long",
    updateUser_empty_data: "No data to be changed has been transmitted.",
    updateUser_invalid_email: "The email is invalid",
    updateUser_missing_current_password:
        "The current password was not entered during the change",
    updateUser_name_too_long: "The new username is too long",
    updateUser_name_too_short: "The new username is too short",
    updateUser_parse_data:
        "An error occurred during the transmission of the data",
    updateUser_password_missing_special_chars:
        "The new password must contain special characters",
    updateUser_password_too_short: "The new password is too short",
    totalPrice: "Total price",
    totalCosts: "Total revenue",
    costSupplier: "Total energy price",
    margin: "Margin",
    filterMonth: "Month equals",
    contains: "Contains",
    notContains: "Does not contain",
    startsWith: "Starts with",
    endsWith: "Ends width",
    equals: "Equals",
    doesNotEqual: "Does not equal",
    greaterThan: "Greater than",
    greaterThanOrEqual: "Greater than or equal to",
    lessThan: "Less than",
    lessThanOrEqual: "Less than or equal",
    noData: "No Data",
    totalRowsSelected: "Total rows selected",
    count: "count",
    weightedAverage: "weighted avg.",
    sum: "sum",
    groupByColumn: "To group by a column, please click the list icon to the right of the appropriate field",
    exportData: "Export data",
    customers: "Customers",
    permissionsInfo:
        "The permissions for this user can be set in the user management",
    questionsAndAnswers: "For questions and technical problems:",
    onlineSupport: "Online Support",
    offresid: "Offer-ID:",
    marketreport: "Market Report",
    customerSupport: "Customer Support",
    minimum: "Be at least ",
    maximum: "Be max. ",
    characters: " characters",
    uppercaseCharacter: "Have at least one uppercase",
    lowercaseCharacter: "Have at least one lowercase",
    numberCharacter: "Have at least one number",
    specialCharacter: "Have at least one special character",
    prognosisInProcess: "Prognosis in process",
    prognosisImportError: "Prognosis could not be imported",
    pendingCurves: "Pending Offers",
    pending: "Pending",
    twoFA: "2-Factor-Authentication",
    explanation2FA: "2-factor authentication:",
    scanQR: "1. Scan the code with a Authenticator app of your choice.",
    activateWithPin: "2. After scanning the QR Code please insert the pin.",
    authenticating: "Authenticating",
    inputPin: "Please insert the pin code from your authenticator app",
    tfa_missing_token:
        "No 2fa code found. Please insert the code from your authenticator app",
    tfa_token_mismatch: "Your posted 2fa code was invalid.",
    tfa_bad_data: "An error occurred during the transmission of the data",
    tfa_not_found: "Cannot activate 2FA: 2FA not found.",
    tfa_cannot_overwrite:
        "Cannot start 2FA again, its already stored. Please delete the old 2FA or contact your supplier.",
    tfaActivated: "2-Factor-Authentication activated successfully",
    tfaDeactivated: "2-Factor-Authentication deactivated successfully",
    activate: "activate",
    deactivate: "deactivate",
    confirmedSuccess: "Approval successfully granted",
    finishedCurves: "Finished Offers",
    edit: "edit",
    userlist: "User list",
    save: "Save",
    resetTime: "Renew session",
    sessionExpired: "Session expired",
    addcustomertosupplier: "Adding a new customer to the supplier",
    addusertocustomer: "Adding a new user to the customer",
    addnewsupplier: "Adding a new user to the supplier",
    alreadybought: "Already bought",
    stillFree: "Still Free",
    customerwithpurchazedproduct: "Delete the customer's users and non-purchased forecasts",
    customerwithoutpurchazedproduct: "Delete customer with all users and forecasts",
    copyPastePassVerbote: "The password must be entered manually character by character.",
    chargeOnlyWithClick: `You must click the "SEND" button directly to confirm your request.`,
    powerPermission: "Permission to create new power-offer",
    gasPermission: "Permission to create new gas-offer",
    tradingPermission: "Permission to buy power- and gas offers",
    priceBoundsPermission: "Permission to set priceborders to power- and gas offers",
    startValueHint: "The value can be selected directly from the table on the right using the context menu (right mouse button).",
    yearlyUsage: "Yearly ",
    daily : "Daily",
    static: "Static",
    dynamic: "Dynamic",
    buyerWithOutUmlaut : "buyer",
    purchases: "Purchases",
    confirmationWithOutUmlaut: "Valdiators",
    totalCostsWithOutUmlaut: "Total revenue",
    morethanAllowed: "More than the allowed amount.",
    energyprocurementprice: "Energy procurement price",
    zoominout:"Highlight area to zoom in / Double click to zoom out",
    foureyewarning: "WARNING :",
    RowPerPage: "Rows per page:",
    foureyewarningMessage:"You have activated the four-eyes principle. You need at least one other user who can confirm your purchase. As the only user stored in the system, you cannot make any purchases if the four-eyes principle is activated",
    priceMonitoring: "Price Monitoring",
    addToPfm: "Pfm administration",
    userCount:"User count",
    portfolioManagement : "Portfolio Management",
    selectPortfolio: "Select Portfolio",
    login_expired: "Unfortunately,Your account period is Expired.",
    noIntraday: "Unfortunately there are no intraday price at the moment.",
    noOfTranches:"Number of tranches",
    from: "from",
    csvExport: "Export to CSV",
    processing: "processing ",
    generateTAN_process_in_progress: "For this forecast, someone else is already in an active buying process. You have to wait up to 10 minutes before you can buy this forecast. Before making a new purchase, please check the purchase history so that a forecast is not accidentally bought twice.",
    casenumber: "casenumber",
    noSpotPrice: "The price determination only begins with the start of delivery on ",
    unlimited: "unlimited",
    BolligerBandUp: "Bollingerband Up",
    BolligerBandLow: "Bollingerband Low",
    pricecurvefirst: "Please select preiscurve first",
    rlmcommodityconflict: "Unfortunately, the selected values do not match the commodity, please recreate this forecast.",
    rlmcommodityconflictStunde: "The selected values may not match the current commodity.",
    fleximportcommodityconflict: "Unfortunately, the selected values do not match the commodity. Please check the entered values and try again.",
    addtoportfolio: ["Assign the selected offer ", " to one or more portfolios:"],
    singlePurchases : "Single purchases",
    SumOfAllPurchases: "Sum of all purchases",
    purchaseOn: "Purchase on",
    Dprice : "A-Price",
    procurement: "Procurement",
    procured: "Procured",
    total: "Total",
    noPortfolioMessage : "There is no portfolio yet, please create one from the portfolio management section",
    noPortfolioDetailMessage: "There is no information to display in this portfolio yet",
    addSuccessToPfm : "Successfully added to Portfolio",
    removeSuccessFromPfm : "Successfully removed from Portfolio",
    removeOrderFromOrders: "Successfully removed from Orders",
    openPosition : "Open position",
    offerSurcharge : "Offer Surcharge",
    offerSurchargeText : "Offer-specific surcharge [€/MWh]",
    zeroTrancheWarning : "Unfortunately, the offers with less than 1 tranches cannot be added to the PFM",
    pricedevelopment: "Price Development",
    VirtualPurchase: "Virtual Purchase",
    purchasePlusOP : "Purchases + OP",
    dailyTrading : "Daily trading",
    dailyusage: "Daily consumption",
    weekly: "Weekly on",
    monthly : "Monthly on",
    automaticTrancheProcurement: "automatic tranche procurement",
    Atp: "ATP",
    Mtp: "MTP",
    Dp: "DP",
    Mp: "MP",
    acceptTheOffer : "Accept the offer",
    acceptTheOfferMessage: "You have successfully accepted the offer",
    maxmoreTranchenOrder: "Max multiple tranches",
    acceptFrom : "Acceptance of Offer",
    acceptTo : "Offer end",
    ManagementPeriod : "Management period",
    procurementCal : "Procurement calendar",
    procurementCalendar : "autom. procurement calendar",
    TypeOfTrancheProcurement: "Type of tranche procurement",
    deliveryPeriod : "Delivery period",
    OfferOpenUntil : "Offer open until ",
    OfferWasAccepted : "Offer accepted on ",
    OfferExpiredOn : "Offer expired on ",
    selectAll : "Select all",
    deselectAll : "Deselect all",
    TakingOverFrom : "Taking over from",
    SuccessfulTimeChange: "The purchase time has been saved successfully.",
    DeleteTimeDay: "The purchase time has been removed successfully.",
    monday: "Monday",
    sunday : "Sunday",
    saturday : "Saturday",
    friday : "Friday",
    wednesday : "Wednesday",
    tuesday : "Tuesday",
    thursday : "Thursday",
    mondayStart: "Mon start",
    sundayStart : "Sun start",
    saturdayStart : "Sat start",
    fridayStart : "Fri start",
    wednesdayStart : "Wed start",
    tuesdayStart : "Tue start",
    thursdayStart : "Thu start",
    mondayEnd: "Mon End",
    sundayEnd : "Sun End",
    saturdayEnd : "Sat End",
    fridayEnd : "Fri End",
    wednesdayEnd : "Wed End",
    tuesdayEnd : "Tue End",
    thursdayEnd : "Thu End",
    GeneralVerification:"General verification",
    verified: "Verified",
    totalDeliveryPeriod: "Total delivery period: ",
    hedgechannelVerlassMeldung: ["With the planned procurement you would leave the permitted hedge area. At the moment you are allowed to buy a maximum of "," MWh to stay within the ( under ", " ) of hedge area.", "With this purchase, the procurement level would be "],
    infoOscillatorsIndicators : "info about oscillators and indicators",
    colorSettings : "CI-settings",
    generateTAN_no_trading_day: "Purchasing is currently not possible",
    twofa_token_mismatch: "The entered PIN is not correct, please try again.",
    twofa_muss_aktiv: "To continue this process, you must have 2-factor authentication activated under 'My Profile'.",
    maxForecastCount : ["The maximum forecast number of "," forecasts has been exceeded. Please reduce this value."],
    TotalManagementPeriod: "Total management period: ",
    delayedPurchase : "Manual tranche procurement",
    delayedPurchaseConfirmation: "Delayed purchase confirmation",
    tooOldPrice : "The prices are too old, please try again later",
    invalidUserCount: "You are trying to enter more users than allowed",
    endeOptions: "If everything is not procured at the end of the management period, then the entire remainder will be:",
    immediatelyAfterwards: "On the trading day immediately following the end of the management period",
    onSpecificTradingDay: "Procured on the following trading day",
    aboutSpot: "Procured via the spot market with the following price curve",
    MaxFileSize : "Max file size:",
    Secondary: "Secondary",
    Primary: "Primary",
    gradient: "Gradient",
    newCustomer: "New customer",
    Disabled: "Disabled",
    Activated: "Enabled",
    Activeoffers : "Active offers",
    Inactiveoffers:"Inactive offers",
    Deletedoffers: "Deleted offers",
    OffersInPreparation : "Offers in preparation",
    TrancheQuantity : "Tranche quantity",
    BuyAtCurrentPrice : "Buy at current price",
    NoManualPurchasePossible: "No manual purchase possible",
    BuyAtClosingPrice:"Buy at closing price",
    EndOfOffer : "End of offer",
    automatik: "Automatically",
    AssignOverallFormula : "Assign overall formula to forecast",
    OverallFormula: "Overall formula",
    AddFormula: "Add formula term",
    factor: "Factor",
    AdditiveConstant:"Additive constant [€/MWh]",
    SelectFormulaTerm : "Select a formula term",

};

export default en;
