import { usersConstants } from "constants/users.constants";

function updateAll(users) {
    return {
        type: usersConstants.UPDATE_ALL_USERS,
        users,
    };
}

function getAll() {
    return { type: usersConstants.GET_ALL_USERS };
}

function updateSingle(user) {
    return {
        type: usersConstants.UPDATE_SINGLE_USER,
        user,
    };
}

function reset() {
    return {
        type: usersConstants.RESET,
    };
}

function deleteSingle(uid) {
    return {
        type: usersConstants.DELETE_SINGLE_USER,
        uid,
    };
}

const usersActions = {
    getAll,
    updateAll,
    updateSingle,
    deleteSingle,
    reset,
};

export default usersActions;
