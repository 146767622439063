import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Typography, Button } from "@material-ui/core";
import { lang } from "lang";
import CardAddButtonComponent from "./CardAddButton";
// import authActions from "../actions/auth.actions";
import { useDispatch } from "react-redux";
import UserService from "../services/users/UserService";
import snackbarActions from "../actions/snackbar.actions";
import customerActions from "actions/customer.actions";
import { useSelector } from "services/helpers/useSelector";
import { useHistory } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';

/**
 * Component for filtering the loadcurves
 */

const service = new UserService();

function FilterComponent({ filters, setFilters, role, setPEfilterChange }) {
    let history = useHistory();
    const locale = localStorage.locale;
    const dispatch = useDispatch();
    const ModalStatus = useSelector((state) => state.modal.open);
    // @ts-ignore
    const customer = useSelector((state) => state.customer.customerData);
    const loadcurves = useSelector((state) => state.loadcurve.loadcurves);
    const [isSticky, setIsSticky] = useState(false);
    
    // console.log("customer from useSelect" , customer);
    /**
     * Handles textchange for fulltextfilter
     * @param e
     */
    function handleTextChange(e): void {
        setFilters(e.target.value);
    }

    function handleClearText(){
        setFilters("");
    }
    
    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 100) {
            setIsSticky(true);
            
          } else {
            setIsSticky(false);
            
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    useEffect(() => {
        service
            .getCustomer()
            .then((res) => {
                dispatch(customerActions.update(res));
            })
            .catch((e) => {
                if (e.message === "401") {
                    return history.push(`/logout`);
                }
                console.error(e);
                dispatch(snackbarActions.openSnackbar(e.message, "error"));
            });
    }, []);
    // updating prognose Count by changing loadcurve list
    useEffect(() => {
        const interval = setInterval(() => {
            ModalStatus === false &&
            service
            .getCustomer()
            .then((res) => {
                dispatch(customerActions.update(res));
            })
            .catch((e) => {
                if (e.message === "401") {
                    return history.push(`/logout`);
                }
                console.error(e);
                dispatch(snackbarActions.openSnackbar(e.message, "error"));
            });
        }, 10000);
        return () => {
          // clearing interval end
          clearInterval(interval);
        };
    }, [loadcurves]);

    return (
        <Box flexDirection="row" bgcolor="background.paper" display="flex" alignItems="center" height="6em"
        borderRadius="5px" style={{position : isSticky === true ? "fixed" : "relative", backgroundColor : isSticky ? "rgba(255, 255, 255, 0.2)" : "#424242", boxShadow : isSticky ? "0 4px 30px rgba(0, 0, 0, 0.1)" : "0", backdropFilter : isSticky ? "blur(5px)" : "1px" , border : isSticky ? "1px solid rgba(255, 255, 255, 0.3)" : "0", width : isSticky ? "81%" : "100%", zIndex : isSticky ? 2 : 0 }}>
            <Box p={4} flexGrow={1}>
                <CardAddButtonComponent
                    role={role}
                    locale={locale}
                    customer={null}
                    active={
                        customer && customer.current_count < customer.prog_count
                    }
                    isPriceDevelopment={false}
                    setPEfilterChange={0}
                />
            </Box>
            <Box p={3} style={{margin : "0 0 1.1em"}}>
                <TextField
                    id="fulltext_filter"
                    label={lang[locale].search}
                    value={filters}
                    inputProps={{ "data-testid": "fullTextFilter" }}
                    onChange={handleTextChange}
                />
                {
                    filters.length > 0 ?  <Button style={{margin: "1rem 0 0 0.5rem"}} onClick={handleClearText}><DeleteIcon fontSize="small"/></Button> : null
                }
               
            </Box>
            {customer && (
                <Box justifyContent="flex-end" p={4}>
                    <Grid item>
                        <Typography variant="body2">
                            {lang[locale].customerId + "  " + customer.kunde_nr}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">
                            {lang[locale].progcount +
                                "  " +
                                customer.current_count +
                                " / " +
                                customer.prog_count}
                        </Typography>
                    </Grid>
                </Box>
            )}
        </Box>
    );
}

export default FilterComponent;
