import React, { useCallback, useEffect, useState, useRef } from "react";
import { lang } from "../lang";
import { KeyboardDatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";

//Material UI
import {
  Button,
  Grid,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { add, addDays, differenceInDays, sub } from "date-fns";

//Hooks
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";
import { useSelector } from "../services/helpers/useSelector";

//Actions
import modalActions from "../actions/modal.actions";
import forecastActions from "../actions/forecast.actions";
import snackbarActions from "../actions/snackbar.actions";
import authActions from "../actions/auth.actions";

//Services
import ForecastService from "../services/forecast/ForecastService";
import AuthService from "../services/auth/AuthService";
import UserService from "../services/users/UserService";

//Components
import { SelectHolidayComponent } from "./SelectHoliday";
import { TogglePowerGasComponent } from "./TogglePowerGas";
import { SelectTemperatureStation } from "./SelectTemperatureStation";
import { SelectStandardTradingProduct } from "./SelectStandardTradingProduct";
import { SelectUsage } from "./SelectUsage";
import { SelectForecastType } from "./SelectForecastType";
import { SelectProfileTypeComponent } from "./SelectProfileType";
import { SelectUsageType } from "./SelectUsageType";
import SelectMeasuredDatesComponent from "./SelectMeasuredDates";
import SelectVariantComponent from "./SelectVariant";
import SelectForecastNameComponent from "./SelectForecastName";
import SelectPriceCurveComponent from "./SelectPriceCurve";
import SelectDateComponent from "./SelectDate";
import FormelPriceComponent from "./FormelPrice";
import { customer } from "reducers/customer.reducer";
import ABKFieldsComponent from "./ABKFields";
import { subDays } from "date-fns/esm";
import MTBComponent from "./ManuelTranchenBeschaffung";

const forecastService = new ForecastService();

/**
 * Component for creating forecast
 * todo create tests for showing the right elements on forecast type
 * @constructor
 */
function CreateForecastComponent() {
  const toDay = new Date();
  const Userservice = new UserService();
  const tommorow = add(toDay, { days: 1 });
  const yesterDay = sub(toDay, { days: 1 });
  let endYear = toDay.getFullYear() + 4;
  let endOfNextFourYears = new Date(endYear, 11, 31);
  const dispatch = useDispatch();
  const locale = localStorage.locale;

  //state from redux
  const { forecast } = useSelector((state) => state);
  const { progFrom, progTo } = useSelector((state) => state.forecast.data);

  const { constants, ciSettings } = useSelector((state) => state);
  const { forecastType } = forecast.data;

  const [disabled, setDisabled] = useState(true);
  const [handleDays, setHandleDays] = useState<any[]>([]);
  //redirect user to fleximport?
  const [redirect, setRedirect] = useState(false);
  const [minDate, setMinDate] = useState<Date>(new Date());
  const [maxDate, setMaxDate] = useState<Date>(
    new Date("January 01, 2400 00:00:00")
  );
  const [onlyGas, setOnlyGas] = useState(false);
  const [onlyPower, setOnlyPower] = useState(false);
  const role = AuthService.getRole();
  const [fromPageCreate, setFromPageCreate] = useState("");

  function handleChangeAngebotsende(e) {
    setAngebotsende(e);
    dispatch(forecastActions.saveForecastSingle("accept_to", e));
    dispatch(forecastActions.saveForecastSingle("accept_from", toDay));
  }
  function handleChangeMehrfachTranche(e) {
    setMaxTranchen(Number(e.target.value));

    dispatch(forecastActions.saveForecastSingle("max_order_tranche", e));
  }

  const TextFieldComponent = (props) => {
    return <TextField {...props} disabled={true} />;
  };

  const handlePaste = (event) => {
    event.preventDefault(); // Prevent the default paste behavior
  };

  const handleInput = (event) => {
    // Get the entered value and convert it to a number
    const inputValue = event.target.value;

    // Check if the entered value is greater than 10
    if (tranche && inputValue > tranche) {
      // If greater than 10, set the value to 10
      event.target.value = tranche;
    }
  };

  /* Probably has to be set to null or undefined possibly */

  const selectedCustomer = useSelector(
    (state) => state.customer["customerData"]["id"]
  );
  const customerType = useSelector(
    (state) => state.customer["customerData"]["type"]
  );
  const tranche = useSelector((state) => state.forecast.data?.tranche);
  const divideAmount = useSelector(
    (state) => state.forecast.data.divide_amount
  );
  const gesamtFormel = useSelector((props) => props.modal.props?.gesamtFormel);
  const formula = useSelector((props) => props.modal.props?.formula);
  // const props = useSelector((props) => props.modal.props);
  const preisType = useSelector((state) => state.forecast.data.pricing_type);
  const priceCurve = useSelector((state) => state.forecast.data.priceCurve);

  // const selectedCustomerP = useSelector(
  //   (props) => props.customer["customerData"]["permissions"]
  // );
  // const selectedCustomerPerm = Object.values(selectedCustomerP);
  const [btnSubmitText, setBtnSubmitText] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [DFC, setDFC] = useState(false);
  const [TrancheDisable, setTrancheDisable] = useState(false);
  const [ATB, setATB] = useState(false);
  const [MTB, setMTB] = useState(false);
  const [maxTranchen, setMaxTranchen] = useState(1);
  const [Angebotsende, setAngebotsende] = useState<Date | undefined>(undefined);
  const [preiskurveListe, setPreiskurveListe] = useState(undefined);
  const [manuellKaufType, setManuellKaufType] = useState<any>("");
  const [ohneAllRechte, setOhneAllRechte] = useState(false);

  //closes modal
  const closeModal = useCallback(() => {
    dispatch(forecastActions.reset());
    dispatch(modalActions.closeModal());
  }, [dispatch]);

  useEffect(() => {
    if (ATB === true) {
      setMTB(false);
      dispatch(forecastActions.saveForecastSingle("order_type", "AUTO"));
      dispatch(forecastActions.saveForecastSingle("tranche", 1));
      setMaxTranchen(1);
    }
    // else if (ATB === false && MTB === false) {
    //   dispatch(forecastActions.saveForecastSingle("order_type", "MANUAL"));
    // }
  }, [ATB]);

  useEffect(() => {
    if (
      MTB === true &&
      !role.permissions.includes("DELAYED_TRADING") &&
      role.permissions.includes("TRADING")
    ) {
      setATB(false);
      dispatch(forecastActions.saveForecastSingle("order_type", "MANUAL"));
      setMaxTranchen(1);
    }

    if (
      MTB === true &&
      role.permissions.includes("DELAYED_TRADING") &&
      !role.permissions.includes("TRADING")
    ) {
      // setATB(false);
      dispatch(
        forecastActions.saveForecastSingle("order_type", "DELAYED_AUTO")
      );
      setMaxTranchen(1);
    }

    if (
      MTB === true &&
      role.permissions.includes("DELAYED_TRADING") &&
      role.permissions.includes("TRADING")
    ) {
      setATB(false);
    }
  }, [MTB]);

  useEffect(() => {
    if (manuellKaufType === "MANUAL") {
      dispatch(forecastActions.saveForecastSingle("order_type", "MANUAL"));
    }

    if (manuellKaufType === "DELAYED") {
      dispatch(
        forecastActions.saveForecastSingle("order_type", "DELAYED_AUTO")
      );
    }
  }, [manuellKaufType]);

  useEffect(() => {
    Userservice.getSupplierBuyTimes().then((res) => setHandleDays(res));
  }, []);

  useEffect(() => {
    !role.permissions.includes("DELAYED_TRADING") &&
      !role.permissions.includes("TRADING") &&
      !role.permissions.includes("AUTO_TRADING") &&
      dispatch(forecastActions.saveForecastSingle("order_type", "MANUAL"));
    setOhneAllRechte(true);
  }, []);

  // function differenceCal(x, y) {
  //   let ondDay = 1000 * 60 * 60 * 24;
  //   let millisecondes = x - y;
  //   // console.log(Math.round(millisecondes / ondDay) + 1);
  //   return Math.round(millisecondes / ondDay) + 1;
  // }

  // let diffDays = differenceCal(progTo.getTime(), progFrom.getTime());

  const sendErrorFromChild = (Err) => {
    if (Err.length === 0) {
      setDFC(false);
    } else if (Err.length > 0) setDFC(true);
  };

  function handleChangeTranche(e) {
    setMaxTranchen(1);
    if (e.target.value.length > 3) return false;
    if (e.target.value < 2) {
      dispatch(forecastActions.saveForecastSingle("divide_amount", true));
    }
    customerType === "E" && e.target.value < 1
      ? setTrancheDisable(true)
      : setTrancheDisable(false);
    dispatch(
      forecastActions.saveForecastSingle("tranche", Number(e.target.value))
    );
  }

  function handleChangeSelect(e) {
    dispatch(forecastActions.saveForecastSingle("priceType", e.target.value));
  }

  const handlerCheckbox = (e) => {
    if (e.target.checked) {
      dispatch(forecastActions.saveForecastSingle("divide_amount", false));
    } else dispatch(forecastActions.saveForecastSingle("divide_amount", true));
  };
  useEffect(() => {
    dispatch(authActions.checkLogin());
  }, []);

  useEffect(() => {
    // if (role.simple_role !== "LIEFERANT") {
    if (
      role.permissions.includes("STROM") &&
      !role.permissions.includes("GAS")
    ) {
      setOnlyPower(true);
    } else if (
      role.permissions.includes("GAS") &&
      !role.permissions.includes("STROM")
    ) {
      setOnlyGas(true);
    }
    // }
    // if (role.simple_role === "LIEFERANT") {
    //   if (
    //     selectedCustomerPerm.includes("STROM") &&
    //     !selectedCustomerPerm.includes("GAS")
    //   ) {
    //     setOnlyPower(true);
    //   } else if (
    //     selectedCustomerPerm.includes("GAS") &&
    //     !selectedCustomerPerm.includes("STROM")
    //   ) {
    //     setOnlyGas(true);
    //   }
    // }
  }, []);

  useEffect(() => {
    setFromPageCreate("FromCreate");
  }, []);

  useEffect(() => {
    dispatch(
      forecastActions.saveForecastSingle("max_order_tranche", maxTranchen)
    );
  }, [maxTranchen]);

  useEffect(() => {
    if (tranche === undefined) {
      if (customerType === "E") {
        dispatch(forecastActions.saveForecastSingle("tranche", 1));
      }
      if (customerType === "F") {
        dispatch(forecastActions.saveForecastSingle("tranche", 0));
      }
    }
  }, []);

  /**
   * Handles submit. Either redirects the user or just dispatches an event that the forecast should get send to the
   * server
   */
  function handleSubmit(): void {
    //input check
    if (
      !forecastService.checkLoadcurveUploadButtonDisabled(
        forecast.data,
        forecastType,
        constants
      ).disabled
    ) {
      if (forecastType === 1 || forecastType === 2 || forecastType === 8) {
        //just redirect, forecast is saved to the state
        setRedirect(true);
      } else {
        //directly dispatch
        dispatch(
          forecastActions.create(
            role.simple_role === "LIEFERANT" ? selectedCustomer : undefined
          )
        );
      }
    }
    setBtnSubmitText(lang[locale].processing);
    setBtnDisable(true);
  }
  const selectHandler = (e) => {
    e.target.select();
  };
  function setMinMax(min: string, max: string) {
    setMinDate(new Date(min));
    setMaxDate(new Date(max));
  }
  useEffect(() => {
    //check if the button should be disabled
    setDisabled(
      forecastService.checkLoadcurveUploadButtonDisabled(
        forecast.data,
        forecastType,
        constants
      ).disabled ||
        (role.simple_role === "LIEFERANT" && !selectedCustomer) ||
        (preisType === "FORMEL" && gesamtFormel === undefined) ||
        // Check the min and max date of price curve
        (forecast.data.pricing_type?.toLowerCase().indexOf("spot") === -1 &&
          forecast.data.progFrom < minDate) ||
        (forecast.data.pricing_type?.toLowerCase().indexOf("spot") === -1 &&
          forecast.data.progTo > maxDate) ||
        (forecast.data.pricing_type?.toLowerCase().indexOf("spot") !== -1 &&
          differenceInDays(forecast.data.progFrom, tommorow) < 0) ||
        (forecast.data.pricing_type?.toLowerCase().indexOf("spot") !== -1 &&
          differenceInDays(forecast.data.progTo, endOfNextFourYears) > 0) ||
        (DFC && forecastType !== 1 && forecastType !== 8) ||
        TrancheDisable ||
        Angebotsende === undefined ||
        (tranche &&
          tranche > 1 &&
          forecast.data.order_to &&
          forecast.data.order_from &&
          (differenceInDays(forecast.data.order_to, toDay) === -2 ||
            differenceInDays(forecast.data.order_from, toDay) === -2)) ||
        // (forecast.data.accept_from && forecast.data.order_from && tranche && tranche > 1 && forecast.data.order_from < forecast.data.accept_from) ||
        // (forecast.data.accept_from && forecast.data.order_to && tranche && tranche > 1 && forecast.data.order_to < forecast.data.accept_from) ||
        (preisType === "FORMEL" &&
          (formula === undefined || Object.keys(formula).length === 0)) ||
        (forecast.data.pricing_type?.toLowerCase().indexOf("spot") === -1 && (ATB === false && MTB === false))
    );
  }, [forecastType, forecast.data, DFC, TrancheDisable, formula, Angebotsende]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //if the request is complete and no error is given, open a snackbar
    if (forecast.finishedRequest && forecast.error === undefined) {
      dispatch(
        snackbarActions.openSnackbar(
          lang[locale].forecastSuccessfullyCreated,
          "success"
        )
      );
      dispatch(forecastActions.reset());
      closeModal();
    }
  }, [
    closeModal,
    dispatch,
    forecast.error,
    forecast.finishedRequest,
    locale,
    ATB,
    MTB,
  ]);

  return (
    <Grid container spacing={2}>
      {redirect && (
        <Redirect
          to={{
            pathname: "/app/fleximport",
            state: { customer: selectedCustomer },
          }}
        />
      )}
      <Grid
        item
        xl={10}
        lg={10}
        md={12}
        xs={12}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          style={{
            marginRight: "2rem",
            color: onlyGas ? "#FAB515" : onlyPower ? "#0983B1" : "white",
          }}
        >
          {lang[locale].foreCast}
        </Typography>
        <Grid item xl={4} lg={10} md={12} xs={12}>
          <TogglePowerGasComponent />
        </Grid>
      </Grid>
      {/* <Grid item xl={4} lg={12} md={4} xs={4}>

            </Grid> */}
      {forecast.error && (
        <Grid item xs={12}>
          <Typography color="error" data-testid="submiterror">
            {forecast.error.message}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <Grid container alignItems="center" justify="space-between" spacing={2}>
          <SelectForecastNameComponent />
          <Grid item xs={12} md={6} style={{ marginBottom: "2em" }}>
            <SelectPriceCurveComponent
              gas={forecast.data.gas}
              setMinMax={setMinMax}
              // gesamtFormel={gesamtFormel}
              fromPage={fromPageCreate}
              preisType={preisType}
              setPreiskurveListe={setPreiskurveListe}
            />
          </Grid>
          <SelectDateComponent minDate={minDate} maxDate={maxDate} />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={10}
        lg={10}
        xl={10}
        style={{ display: "flex", flexDirection: "row" }}
      >
        {preisType !== "SPOT" ? (
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Grid>
                <TextField
                  onFocus={(e) => selectHandler(e)}
                  inputProps={{
                    "data-testid": "tranche",
                    min: 0,
                    // maxLength: 4,
                    pattern: "([^0-9]*)",
                  }}
                  value={
                    !ATB && forecast.data.tranche
                      ? forecast.data.tranche
                      : ATB
                      ? lang[locale].automatik
                      : ""
                  }
                  fullWidth
                  
                  onChange={handleChangeTranche}
                  label={lang[locale].noOfTranches}
                
                  error={
                    tranche === undefined ||
                    (customerType === "E" && tranche < 1)
                  }
                />
              </Grid>
              <Grid>
                <TextField
                  
                  onFocus={(e) => selectHandler(e)}
                  inputProps={{
                    "data-testid": "tranche",
                    min: 0,
                    // max: (tranche && tranche > 0) && tranche,
                    // maxLength: 4,
                    pattern: "([^0-9]*)",
                  }}
                  value={maxTranchen && !ATB ? maxTranchen : ATB ? 1 : ""}
                  fullWidth
                  onChange={(e) => handleChangeMehrfachTranche(e)}
                  label={lang[locale].maxmoreTranchenOrder}
                  
                  onPaste={handlePaste}
                  onInput={handleInput}
                  disabled={
                    ATB ||
                    forecast.data.pricing_type
                      ?.toLowerCase()
                      .indexOf("spot") !== -1 ||
                    tranche === 0
                  }
                  style={{ marginLeft: "2rem" }}
                  // error={}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  // margin: "0.8rem 0",
                  marginLeft: "2rem",
                }}
              >
                {preisType !== "SPOT" && (
                  <Tooltip title={lang[locale].automaticTrancheProcurement}>
                    <FormControlLabel
                      style={{ margin: "1rem 0 0 1.5rem" }}
                      control={
                        <Checkbox
                          value="Monatlich"
                          checked={ATB}
                          onChange={() => setATB((ATB) => !ATB)}
                          disabled={
                            handleDays.length === 0 ||
                            !role.permissions.includes("AUTO_TRADING")
                          }
                          style={{
                            color:
                              ATB === false && MTB === false
                                ? "#F34336"
                                : "white",
                          }}
                        />
                      }
                      label={lang[locale].Atp}
                    />
                  </Tooltip>
                )}
                {preisType !== "SPOT" && (
                  <Grid
                    xs={6}
                    md={3}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title={lang[locale].delayedPurchase}>
                      <FormControlLabel
                        style={{ margin: "1rem 0 0 1rem" }}
                        control={
                          <Checkbox
                            value="MTB"
                            checked={MTB}
                            onChange={() => setMTB((MTB) => !MTB)}
                            disabled={
                              handleDays.length === 0 ||
                              (!role.permissions.includes("DELAYED_TRADING") &&
                                !role.permissions.includes("TRADING"))
                            }
                            style={{
                              color:
                                ATB === false && MTB === false
                                  ? "#F34336"
                                  : "white",
                            }}
                          />
                        }
                        label={lang[locale].Mtp}
                      />
                    </Tooltip>
                    {role.permissions.includes("DELAYED_TRADING") &&
                      role.permissions.includes("TRADING") &&
                      MTB && (
                        <Select
                          labelId="select-type"
                          id="type"
                          value={manuellKaufType}
                          label="Type"
                          onChange={(e) => setManuellKaufType(e.target.value)}
                          autoWidth
                          style={{ margin: "1rem 0 0 1.5rem" }}
                        >
                          <MenuItem value={"MANUAL"}>
                            {lang[locale].BuyAtCurrentPrice}
                          </MenuItem>
                          <MenuItem value={"DELAYED"}>
                            {lang[locale].BuyAtClosingPrice}
                          </MenuItem>
                        </Select>
                      )}
                    {role.permissions.includes("DELAYED_TRADING") &&
                      !role.permissions.includes("TRADING") &&
                      MTB && (
                        <p
                          style={{
                            color: "white",
                            margin: "1.8rem 0 0 1.5rem",
                          }}
                        >
                          {lang[locale].BuyAtClosingPrice}
                        </p>
                      )}
                    {!role.permissions.includes("DELAYED_TRADING") &&
                      role.permissions.includes("TRADING") &&
                      MTB && (
                        <p
                          style={{
                            color: "white",
                            margin: "1.8rem 0 0 1.5rem",
                          }}
                        >
                          {lang[locale].BuyAtCurrentPrice}
                        </p>
                      )}
                    {!role.permissions.includes("DELAYED_TRADING") &&
                      !role.permissions.includes("TRADING") &&
                      !role.permissions.includes("AUTO_TRADING") && (
                        <p
                          style={{
                            color: "white",
                            margin: "1.8rem 0 0 1.5rem",
                          }}
                        >
                          {lang[locale].NoManualPurchasePossible}
                        </p>
                      )}
                    {preisType !== "SPOT" &&
                    forecast.data.tranche &&
                    forecast.data.tranche > 1 ? (
                      <Grid
                        style={{
                          padding: "0.4rem 0 0 0",
                          // marginLeft: MTB ? "4rem" : "1rem",
                          marginLeft: "1rem",
                        }}
                        item
                        // xs={6}
                        // md={3}
                      >
                        <FormGroup>
                          <InputLabel style={{ fontSize: "0.85em" }}>
                            {lang[locale].TrancheQuantity}
                          </InputLabel>
                          <FormControlLabel
                            control={<Checkbox onChange={handlerCheckbox} />}
                            label="100%"
                          />
                        </FormGroup>
                      </Grid>
                    ) : null}
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
        ) : null}
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectForecastType />
      </Grid>
      <Grid item xs={6}>
        {(forecastType === 3 ||
          forecastType === 4 ||
          forecastType === 5 ||
          forecastType === 9) && <SelectProfileTypeComponent />}
      </Grid>
      {forecastType === 6 && (
        <SelectStandardTradingProduct
          divideAmount={divideAmount}
          sendErrorFromChild={sendErrorFromChild}
        />
      )}
      {(forecastType === 4 || forecastType === 8) && (
        <SelectTemperatureStation />
      )}
      {forecastType === 4 && <SelectUsageType />}
      {(forecastType === 3 || forecastType === 4) && (
        <SelectUsage
          divideAmount={divideAmount}
          sendErrorFromChild={sendErrorFromChild}
          tranche={tranche}
        />
      )}
      {forecastType === 4 && <SelectVariantComponent />}
      {forecast.data.usageType === 1 && forecastType === 4 && (
        <SelectMeasuredDatesComponent />
      )}
      {(forecastType === 3 || forecastType === 1 || forecastType === 2) && (
        <>
          <Grid item xs={12} lg={6}>
            <SelectHolidayComponent />
          </Grid>
          <Grid item xs={12} lg={6}></Grid>
        </>
      )}
      {preisType !== "SPOT" ? (
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === "de" ? deLocale : enLocale}>
          <KeyboardDatePicker
            // style={{ marginInlineStart: "1rem" }}
            helperText=""
            
            variant="inline"
            format="dd.MM.yyyy"
            fullWidth
            autoOk={true}
            minDate={Date.now()}
            maxDate={subDays(forecast.data.progFrom, 1)}
            onChange={(e) => handleChangeAngebotsende(e)}
            value={Angebotsende ? Angebotsende : ""}
            id="Angebotsende"
            data-testid="Angebotsende"
            label={lang[locale].EndOfOffer}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            TextFieldComponent={TextFieldComponent}
          />
          </MuiPickersUtilsProvider>
          {/* <Grid>
            {progFrom !== null &&
              checkFromDate(progFrom, progTo).map((entry: string) => (
                  <ListItemText>
                  <Typography
                  variant="caption"
                  color="error"
                  data-testid="date-error"
                  key={entry}
                  >
                  {entry}
                  </Typography>
                  </ListItemText>
                  ))}
              </Grid> */}
        </Grid>
      ) : (
        <Grid
          item
          xs={6}
          md={6}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === "de" ? deLocale : enLocale}>

          <KeyboardDatePicker
            // style={{ marginInlineStart: "1rem" }}
            helperText=""
            
            variant="inline"
            format="dd.MM.yyyy"
            fullWidth
            autoOk={true}
            minDate={Date.now()}
            maxDate={subDays(forecast.data.progFrom, 1)}
            onChange={(e) => handleChangeAngebotsende(e)}
            value={Angebotsende ? Angebotsende : ""}
            id="Angebotsende"
            data-testid="Angebotsende"
            label={lang[locale].EndOfOffer}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            TextFieldComponent={TextFieldComponent}
          />
          </MuiPickersUtilsProvider>
          {/* <Grid>
            {progFrom !== null &&
              checkFromDate(progFrom, progTo).map((entry: string) => (
                  <ListItemText>
                  <Typography
                  variant="caption"
                  color="error"
                  data-testid="date-error"
                  key={entry}
                  >
                  {entry}
                  </Typography>
                  </ListItemText>
                  ))}
              </Grid> */}
        </Grid>
      )}

      {
        // (tranche && tranche > 1 && !ATB) ||
        (ATB === true || MTB === true) && priceCurve !== "0" ? (
          <Grid item xs={12}>
            <ABKFieldsComponent
              handleDays={handleDays}
              handleSubmitTrigger={null}
              tranche={tranche}
              ATB={ATB}
              MTB={MTB}
              Angebotsende={Angebotsende}
              sendErrorFromChild={sendErrorFromChild}
            />
          </Grid>
        ) : null
      }
      {ohneAllRechte &&
        // tranche &&
        // tranche > 1 &&
        forecast.data.order_from?.toString() !==
          forecast.data.order_to?.toString() &&
        forecast.data.order_from &&
        forecast.data.order_to &&
        forecast.data.order_from < forecast.data.order_to && (preiskurveListe !== undefined ) && priceCurve !== "0" && (ATB || MTB) && (
          <Grid item xs={12}>
            <MTBComponent preiskurveListe={preiskurveListe} />
          </Grid>
        )}
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <Grid container justify="flex-start">
              <Grid item>
                <Button
                  // color="primary"
                  style={{ color: ciSettings.primary }}
                  onClick={() => {
                    closeModal();
                  }}
                >
                  {lang[locale].close}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2} justify="flex-end" alignItems="center">
              <Grid item>
                {!ATB && (
                  <Button
                    // color="primary"
                    // style={{backgroundColor : ciSettings.primary, color : "white"}}
                    style={{
                      backgroundColor:
                        disabled ||
                        btnDisable ||
                        (forecast.data.order_from &&
                          forecast.data.order_to &&
                          forecast.data.order_from > forecast.data.order_to)
                          ? "#595959"
                          : ciSettings.primary,
                      color:
                        disabled ||
                        btnDisable ||
                        (forecast.data.order_from &&
                          forecast.data.order_to &&
                          forecast.data.order_from > forecast.data.order_to)
                          ? "#898989"
                          : "white",
                    }}
                    variant="contained"
                    data-testid="submitButton"
                    onClick={handleSubmit}
                    disabled={
                      disabled ||
                      btnDisable ||
                      (forecast.data.order_from &&
                        forecast.data.order_to &&
                        forecast.data.order_from > forecast.data.order_to)
                    }
                  >
                    <>
                      {forecastType === 1 ||
                      forecastType === 2 ||
                      forecastType === 8
                        ? lang[locale].next
                        : btnSubmitText.length > 0
                        ? btnSubmitText
                        : lang[locale].createPriceHistory}
                    </>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CreateForecastComponent;
