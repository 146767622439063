import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Column, Table as VirtTable, AutoSizer } from "react-virtualized";
import { ContextMenuTrigger } from "react-contextmenu";
import TableCell from "@material-ui/core/TableCell";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    container: {
        maxHeight: 440,
    },
    flexContainer: {
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
    },
    table: {
        // temporary right-to-left patch, waiting for
        // https://github.com/bvaughn/react-virtualized/issues/454
        "& .ReactVirtualized__Table__headerRow": {
            flip: false,
            paddingRight:
                theme.direction === "rtl" ? "0 !important" : undefined,
        },
    },
    tableRow: {
        cursor: "pointer",
    },
    tableRowHover: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
    },
    tableCell: {
        flex: 1,
    },
    noClick: {
        cursor: "initial",
    },
    baseStyle: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: theme.palette.background.default,
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
    },
    active: {
        borderColor: theme.palette.primary.main,
    },
    accept: {
        borderColor: theme.palette.success.main,
    },
    reject: {
        borderColor: theme.palette.error.main,
    },
    textField: {
        width: "100%",
    },
    inputMargin: {
        marginTop: 16,
    },
    highlight: {
        backgroundColor: theme.palette.primary.main,
        color: "black",
    },
}));

/**
 * Table component for displaying a large amount of data
 * @param cols cols
 * @param rows rows
 * @param scrollTo to what should be scrolled
 * @param pressed if scrollto is true, just update the styles
 * @constructor
 */
function TableComponent({ cols, rows, scrollTo, pressed }) {
    const classes = useStyles();

    function cellRenderer(props) {
        return (
            <ContextMenuTrigger
                id="context_menu"
                collect={() => {
                    return {
                        data: props.cellData,
                        colIndex: props.columnIndex,
                        rowIndex: props.rowIndex,
                    };
                }}
            >
                <TableCell
                    component="div"
                    variant="body"
                    className={clsx(
                        classes.tableCell,
                        classes.flexContainer,
                        classes.tableRowHover,
                        classes.tableRow
                    )}
                >
                    {props.cellData}
                </TableCell>
            </ContextMenuTrigger>
        );
    }

    function headerRenderer({ label }) {
        const l = label.includes("SP") ? label : "SP " + label;
        return (
            <TableCell
                component="div"
                variant="head"
                className={clsx(classes.tableCell, classes.flexContainer)}
            >
                <span>{l}</span>
            </TableCell>
        );
    }

    return (
        <AutoSizer>
            {({ height, width }) => (
                // eslint-disable-next-line react/jsx-no-undef
                <VirtTable
                    width={width}
                    height={height}
                    headerHeight={60}
                    rowHeight={35}
                    rowCount={rows.length}
                    className={classes.table}
                    gridStyle={{
                        direction: "inherit",
                    }}
                    scrollToIndex={scrollTo}
                    rowClassName={clsx(classes.tableRow, classes.flexContainer)}
                    rowGetter={({ index }) => rows[index]}
                >
                    {cols.map((col, index) => (
                        <Column
                            key={index}
                            label={col}
                            headerRenderer={headerRenderer}
                            cellRenderer={cellRenderer}
                            className={
                                !pressed
                                    ? classes.flexContainer
                                    : clsx(
                                          classes.flexContainer,
                                          classes.highlight
                                      )
                            }
                            minWidth={150}
                            dataKey={col}
                        />
                    ))}
                </VirtTable>
            )}
        </AutoSizer>
    );
}

export default TableComponent;
