import React from "react";
import { lang } from "lang";
import { Link } from "react-router-dom";

// Material UI
import {
  Button,
  ButtonGroup,
  Tooltip,
} from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

//Hooks
import { useDispatch } from "react-redux";

//Components
import CreateForecastComponent from "./CreateForecast";
import CreateForecastComponentPE from "./CreateForecastPE";

//Actions
import modalActions from "actions/modal.actions";
// import authActions from "../actions/auth.actions";

/**
 * Component for adding stuff (loadcurves etc)
 */
function CardAddButtonComponent({ locale, role, customer, active, isPriceDevelopment, setPEfilterChange }) {

  const dispatch = useDispatch();


  const disabled =
    !(
      !(!role.permissions.includes("STROM") &&
      !role.permissions.includes("GAS"))
    ) ||
    (role.simple_role === "LIEFERANT" && !customer) ||
    !active;

  //opens the modal with the right component
  function handleClick(
    customer,
    body: React.FunctionComponent,
    props
  ) {
    isPriceDevelopment ? dispatch(modalActions.openModal(body, props)) : dispatch(modalActions.openModal(body, customer));
  }




  /* Übergabe Customer Object an Fleximport Component */
  return (
    <>
      <ButtonGroup
        disableElevation
        variant="contained"
        aria-label="Disabled elevation buttons"
        disabled={disabled}
      >
        <Tooltip title={lang[locale].createForecast}>
          <Button
            onClick={(e) => {
              isPriceDevelopment ? handleClick(1, CreateForecastComponentPE,{isPriceDevelopment, setPEfilterChange}) : 
              handleClick(isPriceDevelopment, CreateForecastComponent,{});
            }}
          >
            <AddOutlinedIcon />
          </Button>
        </Tooltip>
        <Tooltip title={lang[locale].uploadExistingForecast}>
          {
            isPriceDevelopment ? (
              <Button
              component={Link}
              to={{
                pathname: "/app/fleximportPE",
                state: { customer,isPriceDevelopment },
              }}
              //under construction PE
              disabled={true}
            >
              <FileUploadOutlinedIcon />
            </Button>

            ) : (
              <Button
              component={Link}
              to={{
                pathname: "/app/fleximport",
                state: { customer },
              }}
            >
              <FileUploadOutlinedIcon />
            </Button>
            )
          }
         
        </Tooltip>
      </ButtonGroup>
    </>
  );
}

export default CardAddButtonComponent;
