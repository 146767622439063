import React, { useState } from "react";
import { lang } from "../lang";

//Materials UI
import {
  Button,
  TextField,
  CardContent,
  Card,
  Box,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Typography,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

//Hooks
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

//Services
import UserService from "../services/users/UserService";

//Actions
import snackbarActions from "../actions/snackbar.actions";
import customerActions from "actions/customer.actions";
// import { customer } from "reducers/customer.reducer";

const userService = new UserService();

function AddCustomerComponent(props) {
  const [newKundeNr, setNewKundeNr] = useState<string>("");
  const [newName, setNewName] = useState<string>("");
  const [type, setType] = useState<string | number>(0);
  const dispatch = useDispatch();
  let history = useHistory();

  const locale = localStorage.locale;
  const lieferant = localStorage.company.toLowerCase();

  function handleType(e) {
    e.preventDefault();
    setType(e.target.value);
  }

  function addCustomer(e) {
    e.preventDefault();

    const customer = {
      kunde_nr: newKundeNr,
      name: newName,
      type,
    };

    userService
      // @ts-ignore
      .createCustomer(customer)
      .then(() => {
        dispatch(
          snackbarActions.openSnackbar(
            lang[locale].successCustomerCreation,
            "success"
          )
        );
        updateStore();
        setNewKundeNr("");
        setNewName("");
        setType(0);
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  }

  function updateStore() {
    userService
      .getCustomers()
      .then((customers) => {
        dispatch(customerActions.updateAll(customers));
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  }

  function checkDisabled() {
    return (
      newKundeNr === "" ||
      newName === "" ||
      type === 0 ||
      newKundeNr.length > 50 ||
      newName.length > 50
    );
  }

  return (
    <Card>
      <CardContent>
        <form onSubmit={addCustomer}>
          <Grid>
            <Typography
              variant="body2"
              color="primary"
              style={{ marginLeft: "16px" }}
            >
              {props.name !== "isInCustomerList" && (
                <div>
                  {lang[locale].addcustomertosupplier} {localStorage.company}
                </div>
              )}
            </Typography>
          </Grid>
          <Box flexDirection="row" bgcolor="background.paper" display="flex">
            <Box p={2}>
              <TextField
                value={newKundeNr}
                id="newFirstName"
                label={lang[locale].customerId}
                className="maximalWidth"
                type="text"
                variant="outlined"
                onChange={(e) => {
                  setNewKundeNr(e.target.value);
                }}
                autoComplete="off"
                error={newKundeNr.length > 50}
                helperText={
                  newKundeNr.length > 50 && lang[locale].morethanAllowed
                }
              />
            </Box>
            <Box p={2}>
              <TextField
                value={newName}
                id="newLastName"
                label={lang[locale].name}
                className="maximalWidth"
                type="text"
                variant="outlined"
                onChange={(e) => {
                  setNewName(e.target.value);
                }}
                required
                autoComplete="off"
                error={newName.length > 50}
                helperText={newName.length > 50 && lang[locale].morethanAllowed}
              />
            </Box>
            <Box p={2}>
              <FormControl /* fullWidth */>
                <InputLabel id="selec-type">{lang[locale].type}</InputLabel>
                <Select
                  labelId="select-type"
                  id="type"
                  value={type}
                  label="Type"
                  onChange={handleType}
                  autoWidth
                >
                  <MenuItem value={0}>{lang[locale].notSelected}</MenuItem>
                  <MenuItem value={"F"}>
                    {lang[locale].prognosisCustomer}
                  </MenuItem>
                  <MenuItem value={"E"}>{lang[locale].endCustomer}</MenuItem>
                  {lieferant && lieferant === "timpetools" ? (
                    <MenuItem value={"I"}>
                      {lang[locale].industrialCustomer}
                    </MenuItem>
                  ) : null}
                </Select>
              </FormControl>
            </Box>
            <Box p={3}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{backgroundColor : checkDisabled() ? "#595959" : props.coloR.primary, color : checkDisabled() ? "#898989" : "white"}}
                disabled={checkDisabled()}
              >
                {lang[locale].add}
              </Button>
            </Box>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
}

export default AddCustomerComponent;
