import { modalConstants } from "constants/modal.constants";

/**
 * Function which opens the midak
 * @param body Body which should get displayed in modal
 * @param props other props
 * @param lg lg greatness
 */
function openModal(
    body: React.FunctionComponent,
    props?,
    lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
) {
    
    return {
        type: modalConstants.OPEN,
        body: body,
        props: props,
        lg: lg,
    };
}

/**
 * function which closes the midal
 */
function closeModal() {
    
    return {
        type: modalConstants.CLOSE,
    };
}

const modalActions = {
    openModal,
    closeModal,
};

export default modalActions;
