import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  Button,
  Grid,
  Typography,
  Tooltip,
  FormControlLabel,
  Checkbox,
  IconButton,
  TableCell,
  Select,
  MenuItem
} from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { lang } from "../lang";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import classNames from "clsx";

//TypeDefs
import Permission from "../typeDefs/Permission";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

//Services
import UserService from "../services/users/UserService";

//Hooks
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core";
import { useSelector } from "services/helpers/useSelector";

//Actions
import snackbarActions from "../actions/snackbar.actions";
import modalActions from "../actions/modal.actions";
// import authActions from "../actions/auth.actions";
import usersActions from "actions/users.actions";

//Components
import DeleteUserOrCustomerComponent from "./DeleteUserOrCustomer";
import AddUserByUserComponent from "./AddUserByUser";

//Model
import CustomerModel from "../typeDefs/CustomerModel";

//Icons
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Cancel from "@material-ui/icons/Close";

//DevExpress Grid
import {
  Grid as TableGrid,
  Table as TB,
  TableHeaderRow,
  PagingPanel,
  TableColumnResizing,
  TableRowDetail,
  TableFilterRow,
} from "@devexpress/dx-react-grid-material-ui";
import {
  PagingState,
  IntegratedPaging,
  RowDetailState,
  EditingState,
  FilteringState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";
import {
  Plugin,
  Template,
  TemplateConnector,
  TemplatePlaceholder,
  Action,
} from "@devexpress/dx-react-core";
import AuthService from "../services/auth/AuthService";

const service = new UserService();

//To-Do: überlegen ob die Daten ausreichend sind, Benutzerliste konsolidieren?
/**
 * Shows a accordion full with user information
 * @constructor
 */
function UserOfCustomerComponent(props) {
  console.log("props",props)
  const role = AuthService.getRole();
  let history = useHistory();
  const dispatch = useDispatch();
  const locale = localStorage.locale;
  const [rows, setRows] = React.useState<CustomerModel[]>([]);
  const [permissions, setPermissions] = useState<Permission[]>();
  const [updated, setUpdated] = useState([]);
  const [seletAll, setSelectAll] = useState(false);
  const [deseletAll, setDeSelectAll] = useState(false);
  const [TakingOverFrom, setTakingOverFrom] = useState(false);
  const [Selecteduser, setSelecteduser] = useState("");
  const [expandedRowIds, setExpandedRowIds] = useState([]);
  const theme = useTheme();

  // @ts-ignore
  const usersStore = useSelector((state) => state.users.users);
  const state = useSelector((state) => state);
  const [customerState ,setCustomerState] = useState<any>([]); 


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#5c5959",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const messages = {
    LocalizationMessages: {
      rowsPerPage: lang[locale].RowPerPage, // Custom text for Rows per page
    },
  };
  // console.log("usersStore", usersStore);

  //gives the timeout function always the actual state
  const updatedRef = useRef(updated);
  updatedRef.current = updated;

  // @ts-ignore
  const { id, name } = useParams();

  const editUsers = (users) => {
    if (users !== []) {
      for (var i in users) {
        users[i].delete = (
          <Button
            // startIcon={<DeleteIcon />}
            variant="text"
            value={[users[i].email, users[i].uid]}
            // onClick={openDeleteModal}
          ></Button>
        );
        users[i].name = name;
      }
      return users;
    }
  };

  function handleChange(e){
    setSelecteduser(e.target.value)
  }

    useEffect(() => {
      service
        .getCustomer()
        .then((res) => {
          setCustomerState(res);

        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *  Gets the customer users
   */

  useEffect(() => {
      //@ts-ignore
      if (customerState.id) {
        service
        //@ts-ignore
        .getUsersByCustomerId(customerState.id)
        .then((users) => {
          dispatch(usersActions.updateAll(users));
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
      }
      return () => {
        dispatch(usersActions.reset());
      };
  }, [customerState.id]);

  /** Gets the customers out of the store */

  useEffect(() => {
    const rows = JSON.parse(JSON.stringify(usersStore));
    setRows(editUsers(rows));
  }, [usersStore]);

  /* Get all customer permissions */

  useEffect(() => {
    service
      .getPermissions()
      .then((perms) => {
        setPermissions(perms.filter((perm) => perm.role === "USER"));
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  }, []);

  function premissionsNameTranslator(permissionName) {
    if (lang[locale].add === "Hinzufügen") {
      switch (permissionName) {
        case "TRADING":
          return "HANDEL";
        case "VIEW_DETAIL":
          return "DETAIL ANZEIGEN";
        case "MONITORING":
          return "ÜBERWACHUNG";
        case "VIEW_HISTORY":
          return "VERLAUF ANZEIGEN";
        case "VIEW_INTRADAY":
          return "INTRADAY ANZEIGEN";
        case "VIRTUAL_ORDER":
          return "VIRTUELLE KÄUFE";

        default:
          return permissionName;
      }
    }
    return permissionName;
  }

  const changePermissions = (uid, permission) => {
    if(TakingOverFrom === false && Selecteduser === ""){
      // @ts-ignore
      const users = [...rows];
  
      // @ts-ignore
      const rowIndex = users.findIndex((user) => user.uid === uid);
  
      // @ts-ignore
      let permissions = users[rowIndex].role.permissions;
  
      permissions.includes(permission)
        ? (permissions = permissions.filter((perm) => perm !== permission))
        : permissions.push(permission);
  
      // @ts-ignore
      users[rowIndex].role.permissions = permissions;
  
      setRows(users);
    } else if(TakingOverFrom === true && Selecteduser !== ""){
      // @ts-ignore
      const users = [...rows];
  
       // @ts-ignore
      const rowIndex = users.findIndex((user) => user.uid === uid);
       // @ts-ignore
      let permissions = usersStore?.find(i=>i.email === Selecteduser)["role"].permissions;

      permissions.includes(permission)
      ? (permissions = permissions.filter((perm) => perm !== permission))
      : permissions.push(permission);

      // @ts-ignore
      users[rowIndex].role.permissions = permissions;

      setRows(users);
    }
  };

  /**  React DevExpress Table */

  const columns = [
    { name: "firstname", title: lang[locale].firstName },
    { name: "lastname", title: lang[locale].lastName },
    { name: "email", title: "E-Mail" },
    { name: "name", title: lang[locale].customer },
    { name: "delete", title: " " },
  ];

  const [columnWidths, setColumnWidths] = useState([
    { columnName: "firstname", width: 150 },
    { columnName: "lastname", width: 150 },
    { columnName: "email", width: 300 },
    { columnName: "name", width: 150 },
    { columnName: "delete", width: 50 },
  ]);

  const [pageSizes] = useState([5, 10, 15, 0]);

  const openDeleteModal = (e) => {
    const values = e.currentTarget.value.split(",");
    const email = values[0];
    const uid = values[1];

    if (uid) {
      dispatch(
        modalActions.openModal(DeleteUserOrCustomerComponent, {
          type: "user",
          id: uid,
          information: email,
        })
      );
    }
  };
  /**  DEVEXPRESS
   *
   * Custom Plugin Expandable Form
   *
   **/
  const getRowId = (row) => row.uid;

  const DetailContent = ({ row, ...rest }) => {
    //@ts-ignore
    // const kundePermissions = customerState !== undefined &&customerState.find((i) => i);
    // console.log("kundePermissions",kundePermissions)
    const { processValueChange, applyChanges, cancelChanges } = rest;
    return (
      <Grid container spacing={3} className="userList">
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} style={{ padding: 25 }}>
              <Typography variant="caption">
                {lang[locale].permissions}
              </Typography>
              <FormControlLabel
              style={{ marginLeft: "2rem" }}
                control={
                  <Checkbox
                    checked={seletAll}
                    size="small"
                    onChange={() => {
                      permissions?.map((perm)=>{
                        setSelectAll(true);
                        setDeSelectAll(false);
                        setTakingOverFrom(false);
                        setSelecteduser("");
                        if( kundePermissions.permissions.includes(
                          perm.name
                        ) && !row.role.permissions.includes(perm.name))
                        changePermissions(row.uid, perm.name);
                      })
                    }}
                    name="permission"
                    color="primary"
                    // disabled={!kundePermissions.permissions.includes(perm.name) && !row.role.permissions.includes(perm.name)}
                  />
                }
                label={lang[locale].selectAll}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={deseletAll}
                    size="small"
                    onChange={() => {
                      setDeSelectAll(true);
                      setSelectAll(false);
                      setTakingOverFrom(false);
                      setSelecteduser("");
                      permissions?.map((perm)=>{
                        if( kundePermissions.permissions.includes(
                          perm.name
                        ) && row.role.permissions.includes(perm.name))
                        changePermissions(row.uid, perm.name);
                      })
                    }}
                    name="permission"
                    color="primary"
                    // disabled={!kundePermissions.permissions.includes(perm.name) && !row.role.permissions.includes(perm.name)}
                  />
                }
                label={lang[locale].deselectAll}
              />


              <FormControlLabel
                control={
                  <Checkbox
                    checked={TakingOverFrom}
                    size="small"
                    onChange={() => {
                      setDeSelectAll(false);
                      setSelectAll(false);
                      setTakingOverFrom(true);
                      permissions?.map((perm)=>{
                        usersStore?.find(i=>i.email === Selecteduser)["role"].permissions?.includes(perm.name)
                        changePermissions(row.uid, perm.name);
                      })
                    }}
                    name="permission"
                    color="primary"
                    // disabled={!kundePermissions.permissions.includes(perm.name) && !row.role.permissions.includes(perm.name)}
                  />
                }
                label={lang[locale].TakingOverFrom}
              />
              {TakingOverFrom ? (<Select
              
              value={Selecteduser ? Selecteduser : ""}
              onChange={(e)=>handleChange(e)}
              >
               {
                 usersStore.filter(i=> i.email !== row.email).map((user)=>(
                   //@ts-ignore
                   <MenuItem disabled={Selecteduser === user.email} value={user.email} onClick={(e)=>{setSelecteduser(e);}}>{user.email}</MenuItem>
               ))
               }

             </Select>) : null}
              
            </Grid> */}
            {/* {console.log("row permission", row.role.permissions)} */}
            {permissions?.map((perm) => (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 400 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell width={300}>
                        <FormControlLabel
                          control={
                            <Checkbox
                            checked={ usersStore?.find(i=>i.email === row.email)["role"].permissions?.includes(perm.name) }
                              size="small"
                              onChange={() => {
                                changePermissions(row.uid, perm.name);
                              }}
                              onClick={()=>{
                                setTakingOverFrom(false);
                                // setSelecteduser("");
                                setDeSelectAll(false);
                              }}
                              name="permission"
                              color="primary"
                              disabled={props.userType === "UserByUser"}
                            />
                          }
                          label={premissionsNameTranslator(perm.name)}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {perm.help}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody></TableBody>
                </Table>
              </TableContainer>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {/* <Grid container spacing={3} justify="flex-end">
            <Grid item>
              <Button onClick={applyChanges} variant="text" color="primary">
                {lang[locale].save}
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={()=>{cancelChanges();  setExpandedRowIds([]);}} color="secondary">
                {lang[locale].cancel}
              </Button>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    );
  };

  // console.log("permissions", Selecteduser !== "" && usersStore?.find(i=>i.email === Selecteduser)["role"].permissions?.includes("STROM") )

  const styles = (theme) => ({
    toggleCell: {
      textAlign: "center",
      textOverflow: "initial",
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing(1),
    },
    toggleCellButton: {
      verticalAlign: "middle",
      display: "inline-block",
      padding: theme.spacing(1),
    },
  });

  const ToggleCellBase = ({
    style,
    expanded,
    classes,
    onToggle,
    tableColumn,
    tableRow,
    row,
    className,
    ...restProps
  }) => {
    const handleClick = (e) => {
      if(expandedRowIds.length === 0){
        const newarray = [...expandedRowIds, row.uid]
        //@ts-ignore
        setExpandedRowIds(newarray)
        e.stopPropagation();
        // refreshDetails();
        onToggle();
        setSelectAll(false);
        setDeSelectAll(false);
        setTakingOverFrom(false);
        setSelecteduser("");
      }
      if(expanded && expandedRowIds.length > 0 && expandedRowIds[0] === row.uid){
        // e.stopPropagation();
        // refreshDetails();
        onToggle();
        setExpandedRowIds([]);
        setSelectAll(false);
        setDeSelectAll(false);
        setTakingOverFrom(false);
        setSelecteduser("");
      }

    };
    return (
      <TableCell
        className={classNames(classes.toggleCell, className)}
        style={style}
        {...restProps}
      >
        <IconButton className={classes.toggleCellButton} onClick={handleClick}>
        {expanded ? <Cancel /> : !expanded && expandedRowIds.length > 0 ? null : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
    );
  };

  // @ts-ignore
  const ToggleCell = withStyles(styles, { name: "ToggleCell" })(ToggleCellBase);

  const DetailEditCell = () => (
    <Plugin name="detailEdit">
      <Action
        name="toggleDetailRowExpanded"
        action={(
          { rowId },
          { expandedDetailRowIds },
          { startEditRows, stopEditRows }
        ) => {
          const rowIds = [rowId];
          // console.log("rowIds", rowIds);
          const isCollapsing = expandedDetailRowIds.indexOf(rowId) > -1;
          if (isCollapsing) {
            //should just be performed if permissions is not updated
            setTimeout(() => {
              reset(rowIds[0], updatedRef.current);
            }, 1000);
            stopEditRows({ rowIds });
          } else {
            startEditRows({ rowIds });
          }
        }}
      />
      <Template
        name="tableCell"
        // @ts-ignore
        predicate={({ tableRow }) => tableRow.type === TableRowDetail.ROW_TYPE}
      >
        {(params) => (
          <TemplateConnector>
            {(
              { tableColumns, createRowChange, rowChanges },
              {
                changeRow,
                commitChangedRows,
                cancelChangedRows,
                toggleDetailRowExpanded,
              }
            ) => {
              if (
                // @ts-ignore
                tableColumns.indexOf(params.tableColumn) !== 0
              ) {
                return null;
              }
              const {
                // @ts-ignore
                tableRow: { rowId },
              } = params;
              const row = {
                // @ts-ignore
                ...params.tableRow.row,
                ...rowChanges[rowId],
              };

              const processValueChange = ({ target: { name, value } }) => {
                const changeArgs = {
                  rowId,
                  change: createRowChange(row, value, name),
                };
                changeRow(changeArgs);
              };
              // console.log(row);
              const applyChanges = () => {
                toggleDetailRowExpanded({ rowId });
                commitChangedRows({ rowIds: [rowId] });
              };
              const cancelChanges = () => {
                toggleDetailRowExpanded({ rowId });
                cancelChangedRows({ rowIds: [rowId] });
              };

              return (
                <TemplatePlaceholder
                  params={{
                    ...params,
                    row,
                    tableRow: {
                      // @ts-ignore
                      ...params.tableRow,
                      row,
                    },
                    changeRow,
                    processValueChange,
                    applyChanges,
                    cancelChanges,
                  }}
                />
              );
            }}
          </TemplateConnector>
        )}
      </Template>
    </Plugin>
  );

  const DetailCell = ({
    children,
    changeRow,
    editingRowIds,
    addedRows,
    processValueChange,
    applyChanges,
    cancelChanges,
    ...restProps
  }) => {
    const { row } = restProps;

    return (
      // @ts-ignore
      <TableRowDetail.Cell {...restProps}>
        {React.cloneElement(children, {
          row,
          changeRow,
          processValueChange,
          applyChanges,
          cancelChanges,
        })}
      </TableRowDetail.Cell>
    );
  };

  function reset(uid, updated) {
    //should just be performed if permissions is not updated

    if (updated.includes(uid)) {
      let newUpdated = [...updated];
      newUpdated = newUpdated.filter((entry) => entry !== uid);
      // @ts-ignore
      setUpdated(newUpdated);
      return;
    }

    //if permissions were not updated, reset

    const rows = JSON.parse(JSON.stringify(usersStore));
    const previous = rows.filter((obj) => obj.uid === uid)[0];

    const current = [...rows];
    const rowIndex = current && current.findIndex((row) => row.uid === uid);

    current[rowIndex] = previous;

    let newUpdated = [...updated];
    newUpdated = newUpdated.filter((user) => user !== uid);
    // @ts-ignore
    setUpdated(newUpdated);
    setRows(editUsers(current));
  }

  function handleSubmit(uid) {
    setExpandedRowIds([]);
    // @ts-ignore
    const user = rows.filter((obj) => obj.uid === uid)[0];
    // @ts-ignore
    const permissions = TakingOverFrom === true && Selecteduser !== ""? usersStore?.find(i=>i.email === Selecteduser)["role"].permissions : user.role.permissions;
    const updateUser = { uid, permissions };
    user &&
      service
        .updateUser(updateUser)
        .then(() => {
          //To only reset if not updated
          let updateArray = [...updated];
          // @ts-ignore
          updateArray.push(uid);
          setUpdated(updateArray);
          dispatch(usersActions.updateSingle(updateUser));
          dispatch(
            snackbarActions.openSnackbar(lang[locale].userUpdated, "success")
          );
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
  }


  const commitChanges = ({ changed }) => {
    if (changed) {
      const uid = Object.keys(changed)[0];
      const changedRows = rows?.map((row) =>
        // @ts-ignore
        changed[row.uid] ? { ...row, ...changed[row.uid] } : row
      );

      //save permissions changes in the backend
      handleSubmit(Number(uid));
      setRows(changedRows);
    }
  };

  /* delete colum should not be filterable */

  const FilterCell = (props) => {
    const { column } = props;
    if (column.name === "delete") {
      return null;
    }
    if (column.name === "name") {
      return null;
    }
    return <TableFilterRow.Cell {...props} />;
  };

  /* makes the title row blue*/

  const titleComponent = (props) => {
    return (
      // @ts-ignore
      <titleComponent
        {...props}
        // style={{ color: theme.palette.primary.main }}
      />
    );
  };

  /* sorting*/

  const [sorting, setSorting] = useState([
    { columnName: "firstname", direction: "asc" },
    { columnName: "lastname", direction: "asc" },
    { columnName: "email", direction: "asc" },
  ]);

  const comparePriority = (a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) return -1;

    if (a.toLowerCase() > b.toLowerCase()) return 1;

    return 0;
  };

  const [integratedSortingColumnExtensions] = useState([
    { columnName: "lastname", compare: comparePriority },
  ]);

  return (
    <Grid  style={{marginTop: "2rem"}} >
    
      <AddUserByUserComponent role={"USERByUser"}
      //@ts-ignore
      company_id={customerState.id}
      coloR={props.coloR}/>

      {rows && rows.length > 0 ? (
        <>
          <Paper>
            <TableGrid rows={rows} columns={columns} getRowId={getRowId}>
              <FilteringState defaultFilters={[]} />
              <IntegratedFiltering />
              <RowDetailState defaultExpandedRowIds={[1]} />
              <EditingState
                defaultEditingRowIds={[1]}
                // @ts-ignore
                onCommitChanges={commitChanges}
              />
              <SortingState
                // @ts-ignore
                sorting={sorting}
                onSortingChange={setSorting}
              />
              <IntegratedSorting
                columnExtensions={integratedSortingColumnExtensions}
              />
              <PagingState defaultCurrentPage={0} defaultPageSize={5} />
              <IntegratedPaging />
              <TB />
              <TableColumnResizing
                columnWidths={columnWidths}
                // @ts-ignore
                onColumnWidthsChange={setColumnWidths}
              />
              <TableHeaderRow
                showSortingControls
                titleComponent={titleComponent}
              />
              <TableFilterRow cellComponent={FilterCell} />
              <TableRowDetail
                contentComponent={DetailContent}
                // @ts-ignore
                cellComponent={DetailCell}
                // @ts-ignore
                toggleCellComponent={ToggleCell}
              />
              <DetailEditCell />
              <PagingPanel
               //@ts-ignore
               messages={messages.LocalizationMessages} pageSizes={pageSizes} />
            </TableGrid>
          </Paper>
        </>
      ) : (
        <Grid>
          <Typography></Typography>
        </Grid>
      )}
      {/* <Button color="primary" component={Link} to={"/app/users/customers"}>
        {lang[locale].back}
      </Button> */}
    </Grid>
  );
}

export default UserOfCustomerComponent;
