import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useDispatch } from "react-redux";
import authActions from "actions/auth.actions";
import loadcurveActions from "actions/loadcurve.actions";
import { intervalConstants } from "constants/interval.constants";

/**
 * Component for Logging the user out
 * clears localstorage and redirects to Login
 */
function LogoutComponent() {
    const [redirect, setRedirect] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: intervalConstants.STOP });

        //clear neccessary items from localstorage
        dispatch(authActions.logout());
        dispatch(loadcurveActions.resetStateLogout());
        setTimeout(() => {
            setRedirect(true);
        }, 500);
    }, [dispatch]);

    return <>{redirect && <Redirect to="/auth/login" />}</>;
}

export default LogoutComponent;
