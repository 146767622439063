import { authConstants } from "constants/auth.constants";
import { AnyAction } from "redux";

export interface authReducerInitialStateType {
    loading: boolean;
    loggedIn?: boolean;
    registered: boolean;
    error: string | null;
    active: boolean;
    validated: boolean;
    internalValidate: boolean;
    exp: number;
}

const initialState: authReducerInitialStateType = {
    loading: false,
    registered: false,
    loggedIn: undefined,
    error: null,
    active: false,
    validated: false,
    internalValidate:false,
    exp: 0,
};

/**
 * This is the reducer which is responsible for login and registration
 * @param state The reducers initial state
 * @param action the action which gets passed to the reducer
 */
export function auth(
    state: authReducerInitialStateType = initialState,
    action: AnyAction
) {
    switch (action.type) {
        case authConstants.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case authConstants.LOGIN_SUCCESS:
            // console.log("exp reducer", action.exp);
            return {
                ...state,
                loading: false,
                loggedIn: true,
                active: action.active,
                validated: action.validated,
                exp: action.exp,
            };
        case authConstants.LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case authConstants.REGISTRATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case authConstants.REGISTRATION_SUCCESS:
            return {
                ...state,
                loading: false,
                registered: true,
                loggedIn: true,
            };
        case authConstants.REGISTRATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case authConstants.NOT_LOGGEDIN:
            return {
                ...state,
                loading: false,
                loggedIn: false,
                validated: false,
                exp: 0,
            };
        case authConstants.LOGOUT:
            return {
                ...state,
                validated: false,
                loggedIn: false,
                error: null,
                exp: 0,
            };
        case authConstants.TFA_VALIDATE:
            return {
                ...state,
                active: action.active,
                validated: action.validated,
            };
        case authConstants.TFA_ACTIVATE:
            return {
                ...state,
                active: action.active,
            };
        case authConstants.TFA_INTERNALVALIDATION:
            return {
                ...state,
                internalValidate: action.validated,
            };
        case authConstants.TFA_LEAVEPAGE:
            return {
                ...state,
                internalValidate: false,
            };
        default:
            return state;
    }
}
