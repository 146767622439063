/**
 * Constants for customer
 */

export const usersConstants = {
    UPDATE_ALL_USERS: "UPDATE_ALL_USERS",
    GET_ALL_USERS: "GET_ALL_USERS",
    UPDATE_SINGLE_USER: "UPDATE_SINGLE_USER",
    DELETE_SINGLE_USER: "DELETE_SINGLE_USER",
    RESET: "RESET",
};
