import { snackbarConstants } from "constants/snackbar.constants";
/**
 * Function which opens the snackbar
 * @param message Message which should get displayed in snackbar
 * @param color Color like warn or error
 */
function openSnackbar(message: string, color: "error" | "success" | "warning" | "") {
    return {
        type: snackbarConstants.OPEN,
        message,
        color
    };
}
/**
 * function which closes the snackbar
 */
function closeSnackbar() {
    return {
        type: snackbarConstants.CLOSE,
    };
}


const snackbarActions = {
    openSnackbar,
    closeSnackbar,
};

export default snackbarActions;