import React, { useState } from "react";
import { Grid, TextField, Button, Typography } from "@material-ui/core";
import { lang } from "lang";
import { useDispatch } from "react-redux";
import authActions from "actions/auth.actions";
import _ from "lodash";

/**
 * Component which has the form for logging the user in
 */
function TfaFormComponent(props) {
    const locale = props.locale;
    const dispatch = useDispatch();
    const [pin, setPin] = useState<string | undefined>();

    const isNumeric = number => 
  _.isFinite(_.parseInt(number)) && !_.isNaN(_.parseInt(number)) && number.length > 5

    function onSubmit(e) {
        e.preventDefault();
        dispatch(authActions.validate(pin));
    }

    return (
        <form onSubmit={onSubmit}>
            <Grid
                container
                direction="column"
                spacing={4}
                data-testid="loginform"
            >
                <Grid item xs={12}>
                    <Typography variant="h5">{lang[locale].twoFA}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        {lang[locale].inputPin}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        id="pin"
                        label="Pin"
                        variant="outlined"
                        type="pin"
                        className="maximalWidth"
                        onChange={(e) => {
                            setPin(e.target.value);
                        }}
                        required
                        autoFocus
                        autoComplete="off"
                        /* error={error ? true : false} */
                    />
                </Grid>

                <Grid item xs={12}>
                    <Grid container justify="flex-end">
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            data-testid="submit"
                            disabled={!pin || !isNumeric(pin)}
                            /* disabled={checkDisabled() || loading} */
                        >
                            <>{lang[locale].authenticating}</>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}

export default TfaFormComponent;
