import React, { useState, useRef, useEffect } from "react";
import { lang } from "lang";

//Material UI
import { Button, Typography, Popover } from "@material-ui/core";
import { Replay5 as Replay5Icon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

//Hooks
import { useDispatch } from "react-redux";
import { useSelector } from "../services/helpers/useSelector";

//Actions
import authActions from "actions/auth.actions";
import modalActions from "../actions/modal.actions";

//Components
// import LogOutDialog from "./LogOutDialog";

const useStyles = makeStyles((theme) => ({
    container: {
        position: "relative",
    },
    iconButton: {
        position: "relative",
        margin: "40px -15px 0",
        borderRadius: "50px",
    },
    timer: {
        fontSize: "0.8rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    popover: {
        pointerEvents: "none",
    },
    paper: {
        padding: theme.spacing(0.5),
    },
}));

/* The timer component is used in 2 ways
 *   1. a session timer in the Home Component
 *   2. a timer to give time for approving a buying process in the BuyLoadCurveCard Component
 */

function TimerComponent({ timerType }) {
    // console.log("In Timer Component");
    /*
     * the useSelector hook subscribes to changes of the Redux store that means
     * whenever the price_holding_time and cookie_lifetime component is updated in ANY component of the
     * app the useSelector hook receives the updated changes here.
     */

    const { price_holding_time, cookie_lifetime } = useSelector(
        (state) => state.constants
    );

    // console.log("price_holding_time", price_holding_time);
    // console.log("cookie_lifetime", cookie_lifetime);

    const { exp } = useSelector((state) => state.auth);
    const loggedIn = useSelector((state) => state.auth.loggedIn);

    /*
     *  The timer time is different depending on the type of timer. The cookie_lifetime and price_holding_time
     *  are variables stored in the Redux store. The Redux store receives them through the constants.json file located
     *  in the server source code
     */

    const timer_value =
        timerType === "session" ? cookie_lifetime : price_holding_time;

    // console.log("timer_value", timer_value);
    /*
    * We need ref in this, because we are dealing
      with JS setInterval to keep track of it
    */
    const Ref = useRef(null);
    const [timer, setTimer] = useState("");
    const classes = useStyles();
    const dispatch = useDispatch();
    const locale = localStorage.locale;
    const [anchorEl, setAnchorEl] = useState(null);

    /*
     * handleClose closes the modal component (popup) of the BuyLoadCurve Component.
     */

    // function handleClose() {
    //     // console.log("In Close Modal Timer Component");
    //     dispatch(modalActions.closeModal());
    // }

    /*
     * 1.
     * This is the starting point of the timer
     * UseEffect with empty array [] is being called when the component is first mounted.
     * UseEffect with a dependecy [timer_value] is called every time the variable receives a value or new value.
     * It sets the inital time of the timer.
     */

    useEffect(() => {
        if (timer_value) {
            const priceHoldingTimeString =
                timer_value > 9
                    ? String(timer_value) + ":00"
                    : "0" + String(timer_value) + ":00";
            setTimer(priceHoldingTimeString);
        }
    }, [timer_value]);

    const priceHoldingTime = timer_value * 60;

    /*
     * This function is called when the time is reset by clicking on the button "more time".
     * e.preventDefault() is preventing the refresh of the screen.
     */

    const handleReset = (e) => {
        e.preventDefault();
        dispatch(authActions.checkLogin());
    };

    /*
     * Gets the remaining time
     */

    const getTimeRemaining = (e) => {
        const total = (Date.parse(e) - Date.now()) / 1000;
        const hours = Math.floor(total / 60 / 60);
        const seconds = Math.floor(total % 60);
        const minutes = Math.floor((total / 60) % 60);
        return {
            total,
            hours,
            minutes,
            seconds,
        };
    };

    /* 3.
     *  startTimer takes the deadtime as an argument and gets
     *  the total, minutes and seconds remaining out of the
     *  the deadtime with object destructuring.
     *
     *  If the total time remaining is bigger than 0, it sets the new time visible on the screen.
     *
     *  The timer is used in 2 components (Timer and BuyLoadCurve) that's why we have
     *  timerType here. timerType session is the session timer that logs the user out if
     *  the user is not active.
     *
     *  If the the time is over and the timer type is not session the popup modal of the buying
     *  process neeeds to be closed. That's why we use handleClose() here to close the modal.
     */

    const startTimer = (e) => {
        // let { total, hours, minutes, seconds } = getTimeRemaining(e);
        let { total, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            setTimer(
                (minutes > 9 ? minutes : "0" + minutes) +
                    ":" +
                    (seconds > 9 ? seconds : "0" + seconds)
            );
        } else {
            timerType === "session"
                && setTimer(lang[locale].sessionExpired);
                // : handleClose();
        }
    };

    /* 2.
     * When clearTimer is called the first time:
     * ClearTimer takes the deadtime as a parameter and starts the timer
     * with a setInterval hook. The startTimer is called every 1000 ms (1s).
     * Ref.current is set to the constant id which is the interval.
     *
     * When clearTimer is called the second time the and Ref.current is already set  it
     * clears the interval and prevents it from further executing.
     *
     * */
    const clearTimer = (e) => {
        // If you try to remove this line the
        // updating of timer variable will be
        // after 1000ms or 1sec
        //First time
        if (Ref.current) {
            clearInterval(Ref.current);
            // console.log("ClearTimer Second Time");
        }
        const id = setInterval(() => {
            startTimer(e);
        }, 1000);

        //Second time
        Ref.current = id;
        // console.log("ClearTimer First Time");
    };

    /*
     * getDeadTime calculates the deadtime of the timer
     */

    const getDeadTime = () => {
        let deadline = new Date();
        // console.log("In getDeadTime", priceHoldingTime);
        deadline.setSeconds(deadline.getSeconds() + priceHoldingTime);
        return deadline;
    };

    /*
     * When a new session cookie (exp) is set the timer renews.
     * The useEffect hook with a argument [exp] is triggered when the variable (exp) is set or set to something new.
     * When the useEffect contains 2 varibales it is just triggered when both are set.
     */

    useEffect(() => {
        // console.log("In useEffect renew session");
        if (timerType !== "session") {
            //when the timer type is not session is should not renew when the cookie is set to a new value
            return;
        }
        clearTimer(getDeadTime());
    }, [exp, priceHoldingTime]);

    /* 2.
     * useEffect with empty array [] is being called when the component is first mounted.
     * */
    useEffect(() => {
        // console.log("In clearTimer useEffect");
        clearTimer(getDeadTime());
    }, []);

    /*
     * Popup handler:
     */

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            {timerType === "session" ? (<Button
                className={classes.iconButton}
                color="default"
                onClick={handleReset}
            >
                <Typography
                    aria-owns={open ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                >
                    <span className={classes.timer} style={{}}>
                        <span>{timer}</span>
                        <Replay5Icon fontSize="small" />
                    </span>

                    {/* <span><Replay5Icon fontSize="small" /></span> */}
                </Typography>

                <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                        paper: classes.paper,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",

                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",

                        horizontal: "left",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    {loggedIn ? (
                        <Typography style={{ fontSize: "0.8rem" }}>
                        {lang[locale].resetTime}
                    </Typography>
                        ) : null}
                </Popover>

                {/* <span>{lang[locale].resetTime}</span> */}
            </Button>) : (<span>{timer}</span>) }
            
        </div>
    );
}

export default TimerComponent;
