import Language from ".";

/**
 * Object for German language
 */
const de: Language = {
    usernameOrPasswordWrong: "Benutzername oder Kennwort ist falsch",
    loginButtonText: "Einloggen",
    loginFormCompany: "Firma",
    loginFormUsername: "Benutzername",
    loginFormPassword: "Passwort",
    oldPassword: "Altes Passwort",
    newPassword: "Neues Passwort",
    repeatPassword: "Passwort wiederholen",
    forgotPassword: "Passwort vergessen?",
    resetPasswordEmailError: "Dies ist keine korrekte E-Mail",
    resetPasswordText: "E-Mail Adresse",
    resetPasswordSubtitle:
        "Kein Problem. Sie erhalten in Kürze Instruktionen, um Ihr Passwort zurückzusetzen.",
    setNewPasswordSubtitle:
        "Um Ihr Konto zu schützen, muss Ihr Passwort folgende Eigenschaften besitzen:",
    resetPasswordHeader: "Passwort vergessen?",
    back: "Zurück",
    toLogin: "Zum Login",
    logout: "Ausloggen",
    myplans: "Angebote",
    myprofile: "Mein Profil",
    add: "Hinzufügen",
    filters: "Filter",
    startdate: "Startdatum",
    enddate: "Enddatum",
    nofilter: "Kein Filter",
    deletefilters: "Filter löschen",
    details: "Detail",
    history: "HISTORIE",
    intraday: "INTRADAY",
    notLoggedIn:
        "Sie sind nicht eingeloggt, bitte kehren Sie zurück und probieren es erneut.",
    send: "Senden",
    close: "Schließen",
    drop: "Ziehen Sie Dateien hier hin um sie hochzuladen",
    dropProg:
        "Ziehen Sie die Datei (Excel, CSV, TXT,...) mit den Prognosedaten hier hin, um sie hochzuladen. Andernfalls klicken Sie hier um eine Datei auszuwählen.",
    dropIst:
        "Ziehen Sie die Datei (Excel, CSV, TXT,...) mit den Istdaten hier hin, um sie hochzuladen. Andernfalls klicken Sie hier um eine Datei auszuwählen.",
    dropHere: "Hier hinziehen",
    fileName: "Dateiname",
    fplUpload: "Fahrplan hochladen",
    maxFileSizeExceeded: "Die maximale Dateigröße beträgt 5MB.",
    wrongFileType: "Dieser Dateityp wird nicht unterstützt.",
    uploadSuccess: "Datei erfolgreich hochgeladen.",
    amount: "Menge",
    priceFrom: "Preis vom",
    prices : "Preise",
    noPriceGiven: "Kein Preis gegeben",
    foreCast: "Prognose",
    loadcurve: "Fahrplan",
    gas: "Gas",
    power: "Strom",
    period: "Zeitraum",
    general: "Allgemein",
    dateWrongError: "Das Enddatum befindet sich vor dem Startdatum.",
    notSelected: "Nichts ausgewählt",
    forecastType: "Prognosetyp",
    profileType: "Profiltyp",
    performance: "Leistung",
    usage: "Verbrauch",
    dailyusage: "Tagesverbrauch",
    productType: "Produkttyp",
    product: "Produkt",
    temperatureStation: "Temperaturstation",
    usageType: "Verbrauchsart",
    holidayRegion: "Feiertagsregion",
    others: "Sonstiges",
    measured: "Gemessen",
    MeasuredConsumption: "Gemessenverbrauch",
    specific: "Spezifisch",
    temperatureFinder: "Wo finde ich die nächste Station?",
    email: "E-Mail",
    successFPL: "Sie haben den Fahrplan erfolgreich erstellt",
    next: "Weiter",
    errorFieldsWrong:
        "Die Anfrage enthielt Fehler. Bitte probieren Sie es erneut.",
    errorNotEnoughPermissions: "Sie haben keine Berechtigung.",
    forecastSuccessfullyCreated:
        "Sie haben das Angebot erfolgreich in Auftrag gegeben. Es erscheint in Kürze in Ihrer Angebotsübersicht.",
    istUpload: "Laden Sie Ihre Istdaten hoch",
    progUpload: "Laden Sie Ihre Prognosedaten hoch",
    dateSelectError:
        "Das Datum ist in einem falschen Format. Bitte tragen Sie es manuell ein.",
    actualCount: "Tatsächliche Anzahl Werte",
    neededCount: "Benötigte Anzahl Werte",
    timedifferenceValues: "Zeitumstellung",
    values: "Werte",
    nullvalues: "fehlende Stunde Ende März",
    doubleHour: "doppelte Stunde Ende Oktober",
    unit: "Einheit",
    startvalue: "Startwert",
    endvalue: "Endwert",
    energyAmount: "Energiemenge",
    valuesIncorrect: "Die eingegebenen Werte sind inkorrekt.",
    noProvided: "Keine angegeben",
    startValueHigher: "Der Startwert ist höher als der Endwert.",
    forecastPeriod: "Angebotszeitraum",
    fullHour: "Stunden",
    quarterHour: "Viertelstunden",
    valueAmount: "Intervall",
    export: "Prognose exportieren",
    noLoadcurves: "Bitte fügen Sie einen Fahrplan zur Preisüberwachung hinzu.",
    noPendingLoadcurves: "Keine ausstehenden Angebote.",
    measuredPeriod: "Messzeitraum",
    measuredStart: "Messstart",
    measuredEnd: "Messende",
    variant: "Variante",
    fplUploadMenuItem: "FPL/FPG-Datei hochladen",
    inWork: "In Arbeit",
    name: "Name",
    passwordResetTriggerFailure:
        "Es ist etwas schiefgelaufen. Überprüfen Sie Ihre Daten.",
    passwordResetTriggerSuccess:
        "Erfolgreiche Anfrage gestellt. Sehen Sie in Ihrem Posteingang nach.",
    resetPassword: "Passwort zurücksetzen",
    resetPasswordSuccess: "Ihr Passwort wurde erfolgreich zurückgesetzt",
    resetPasswordError:
        "Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.",
    confirm: "Bestätigen",
    reallyWantToDeleteWithName:
        "Wollen Sie den Fahrplan mit folgendem Namen löschen?",
    cancel: "Abbrechen",
    deleteError: "Fehler beim Löschen.",
    deleteSuccess: "Fahrplan erfolgreich gelöscht",
    delete: "Löschen",
    priceCurve: "Preiskurvenauswahl",
    singlePurchases : "Einzelkäufe",
    SumOfAllPurchases : "Summe aller Käufe",
    pricecurve: "Preiskurve",
    formula: "Formel",
    fixedprice : "Festpreis",
    pricetype: "Preisart",
    priceCurveGetError:
        "Fehler beim holen der Preiskurven. Bitte erneut einloggen",
    priceCurveChoose: "Bitte wählen Sie eine Preiskurve aus",
    pricingInformation: "Bepreisungsinformationen",
    errorCreatingForecast: "Fehler beim Erstellen der Prognose",
    additionalInformation: "Zusätzliche Informationen",
    prognosisCurve: "Lastverlaufprognose",
    actualData: "Istdaten",
    offerName: "Angebotsname",
    resetGraphic: "Grafik zurücksetzen",
    price: "Preis",
    recentPrice: "Aktueller Preis",
    buy: "Kaufen",
    date: "Datum",
    upperBound: "Obergrenze [€/MWh]",
    lowerBound: "Untergrenze [€/MWh]",
    setBounds: "Preisgrenzen überwachen",
    usedIstData: "Verwendete Istdaten",
    boughtPlan: "gekauft",
    forecast: "Prognose",
    buyFailure: "Fehler beim Kaufen. Probieren Sie es später erneut",
    buyFullLoadcurve: "100% des Fahrplans kaufen",
    buySelectSize: "Bitte wählen Sie einen prozentualen Anteil",
    buySuccess: "Erfolgreich gekauft",
    buyToFollowingPrice:
        "Möchten Sie diesen Fahrplan zu folgendem spezifischen Preis kaufen?  ",
    boughtAt: "Gekauft am",
    buypoint: "Kaufzeitpunkt",
    showOrders: "Kaufhistorie",
    buyDate: "Kaufdatum",
    dealticket: "Dealticket",
    percentage: "Prozentanteil",
    cost: "Kosten",
    noOrders: "Keine Bestellungen verfügbar.",
    showTimeChangeDay: "Tag anzeigen",
    createPriceHistory: "Preishistorie erstellen",
    buySingular: "Kauf",
    creationTime: "Erstellungszeit",
    shouldNotBeBeforeEndDate:
        "Dieses Datum sollte nicht nach dem Enddatum sein",
    shouldNotBeBeforeStartDate:
        "Dieses Datum sollte nicht vor dem Startdatum sein",
    search: "Suche",
    priceCurveName: "Preiskurvenname",
    filterDateHelper:
        "Wählt alle Fahrpläne aus, die innerhalb dieses Zeitraums liegen.",
    uploadExistingForecast: "Bestehende Prognose hochladen",
    createForecast: "Neue Prognose erstellen",
    priceCurveInformation: "Preiskurveninformationen",
    priceCurveID: "Kennung",
    priceCurveCreationDate: "Erstellungszeit",
    priceWatching: "Preisüberwachung aktiv",
    users: "Nutzerverwaltung",
    addUser: "Benutzer/in hinzufügen",
    addCustomer: "Kunden hinzufügen",
    userUpdated: "Benutzer/in wurde erfolgreich aktualisiert.",
    userCreated: "Benutzer/in wurde erfolgreich ohne Rechte erstellt. Bitte weisen Sie dem Anwender/der Anwenderin entsprechende Rechte zu.",
    userByuserCreated: "Benutzer/in wurde erfolgreich mit denjenigen Rechten erstellt, die Sie besitzen. Das Tradingrecht wird jedoch nie übertragen und muss immer beim Lieferanten beantragt werden!",
    firstName: "Vorname",
    lastName: "Nachname",
    isSupplier: "Lieferant",
    inIndustrieCustomer: "Industriekunde",
    chooseCustomer: "Kunden auswählen",
    expireDate: "Ablaufdatum",
    permissions: "Berechtigungen",
    successCustomerUpdate: "Kunde wurde erfolgreich aktualisiert.",
    successCustomerCreation: "Kunde wurde erfolgreich ohne Rechte erstellt. Bitte weisen Sie dem Kunden entsprechende Rechte zu.",
    errorCustomerCreation:
        "Es ist ein Fehler beim Erstellen des Kunden aufgetreten.",
    customerName: "Kundenname",
    customerId: "Kundennummer",
    supplierUser: "Lieferantenbenutzer",
    supplier: "Lieferant",
    customer: "Kunde",
    maxProg: "Maximale Prognosen",
    liquidity: "Liquidität",
    editCustomer: "Kunden bearbeiten",
    editUser: "Benutzer/in bearbeiten",
    limitYear: "Jahreslimit [€]",
    Year : "Jahr",
    limitOrder: "Limit pro Kauf [€]",
    limitMonth: "Monatslimit [€]",
    allOrders: "Alle Käufe",
    parallelBuyWarning: "Achtung:Für diese Prognose wurden zwischenzeitlich weitere Käufe getätigt. Bitte prüfen Sie das ggf. in der Kaufhistorie, bevor Sie fortfahren.",
    flexTranche: "Die durchschnittliche Tranchenmenge liegt außerhalb des zulässigen Bereiches von 5 MWh - 1000000 MWh, aktuelle Tranchenmenge: ",
    deleteCustomer:
        "Wollen Sie den Kunden mit folgendem Namen und ID wirklich löschen?",
    deletePortfolio:
        "Wollen Sie den Portfolio wirklich löschen?",
    deleteUser:
        "Wollen Sie den Benutzer/in mit folgender Emailadresse wirklich löschen?",
    errorGeneric:
        "Es ist ein Fehler aufgetreten, bitte probieren Sie es später erneut.",
    TTPFM_si_insert: "Fehler beim Import.",
    createCustomer_internal_error:
        "Beim Anlegen des Kunden trat ein interner Fehler auf.",
    createCustomer_missing_permissions:
        "Sie verfügen nicht über die Berechtigung, einen Kunden anzulegen.",
    createCustomer_missing_relation: "Fehlende Zuordnung - Lieferant.",
    createCustomer_parse_data: "Die Inputdaten sind Fehlerhaft.",
    createForecastForCustomer_missing_permissions:
        "Sie verfügen nicht über die Berechtigung eine Prognose anzulegen.",
    createForecastForCustomer_missing_relation: "Fehlende Zuordnung - Kunde.",
    createForecastForCustomer_unknownFunction: "",
    createForecast_unknownFunction: "",
    createISTData_parse_data: "Die Inputdaten sind Fehlerhaft.",
    createUser_customer_relation:
        "Der angelegte Nutzer wurde keinem Kunden zugewiesen.",
    createUser_internal_error: "Ein interner Fehler ist aufgetreten.",
    createUser_missing_permissions:
        "Sie verfügen nicht über die Berechtigung, einen Kunden anzulegen.",
    createUser_missing_relation: "Fehlende Zuordnung - Kunde.",
    createUser_parse_data: "Die Inputdaten sind fehlerhaft.",
    createUser_privilege_escalation:
        "Sie können höhere Berechtigungen nicht beim Erstellen zuweisen.",
    createUser_user_relation:
        "Ein Lieferant darf keiner Kunden-ID zugeordnet werden.",
    createUser_user_already_exists: "Der anzulegende User existiert bereits.",
    createUser_email_error:
        "Die Email mit dem Initialpasswort konnte nicht versendet werden",
    createUser_email_too_long: "Die angegebene Mailadresse ist zu lang",
    createUser_empty_data: "Es wurden nicht alle benötigten Felder ausgefüllt",
    createUser_invalid_email: "Die eingegebene Mailadresse ist ungültig",
    createUser_name_too_long: "Der gewählte Benutzername ist zu lang",
    createUser_name_too_short: "Der gewählte Benutzername ist zu kurz",
    customerData_internal_error: "Ein interner Fehler ist aufgetreten.",
    customerData_missing_permissions:
        "Sie verfügen nicht über die benötigte Berechtigung.",
    customerData_missing_relation: "Fehlende Zuordnung - Kunden.",
    customerOrders_missing_permissions:
        "Sie verfügen nicht über die benötigte Berechtigung.",
    customerOrders_missing_relation: "Fehlende Zuordnung - Kunden.",
    customer_missing_permissions:
        "Sie verfügen nicht über die benötigte Berechtigung.",
    customer_missing_relation: "Fehlende Zuordnung - Kunden.",
    customer_parse_data: "Die Inputdaten sind Fehlerhaft.",
    customers_missing_permissions:
        "Sie verfügen nicht über die benötigte Berechtigung.",
    deleteCustomer_missing_permissions:
        "Sie verfügen nicht über die benötigte Berechtigung.",
    deleteCustomer_missing_relation: "Fehlende Zuordnung - Kunden.",
    deleteUser_internal_error: "Ein interner Fehler ist aufgetreten.",
    deleteUser_missing_permissions:
        "Sie verfügen nicht über die benötigte Berechtigung.",
    deleteUser_missing_relation: "Fehlende Zuordnung - Nutzer.",
    forecastCreation_internal_error: "Ein interner Fehler ist aufgetreten.",
    forecastCreation_invalid_IST_timeframe:
        "Bitte überprüfen Sie den ausgewählten Zeitraum.",
    forecastCreation_invalid_name: "Der von Ihnen gewählte Name ist ungültig.",
    forecastCreation_invalid_prog_timeframe:
        "Bitte überprüfen Sie den ausgewählten Istdatenzeitraum.",
    forecastCreation_missing_permissions:
        "Sie verfügen nicht über die benötigte Berechtigung.",
    forecastCreation_parse_data: "Die Inputdaten sind fehlerhaft. Bitte erstellen Sie die Prognose neu",
    forecastCreation_parsing_pricing_curve: "Ungültige Preiskurve.",
    forecastCreation_pricing_curve_empty:
        "Die Preiskurve wurde leer übermittelt.",
    forecastCreation_pricing_curve_not_found:
        "Die Preiskurve konnte nicht gefunden werden.",
    forecastCreation_progcount_limit:
        "Sie haben die maximale Prognoseanzahl erreicht.",
    supplierData_missing_permissions:
        "Sie verfügen nicht über die benötigte Berechtigung.",
    supplierData_missing_relation: "",
    supplierUsers_missing_permissions:
        "Sie verfügen nicht über die benötigte Berechtigung.",
    supplierUsers_missing_relation: "Fehlende Zuordnung - Nutzer.",
    updateCustomerInfo_internal_error: "Ein interner Fehler ist aufgetreten.",
    updateCustomerInfo_missing_permissions:
        "Sie verfügen nicht über die benötigte Berechtigung.",
    updateCustomerInfo_missing_relation: "Fehlende Zuordnung - Kunden.",
    updateCustomerInfo_parse_data: "Die Inputdaten sind Fehlerhaft.",
    updatePermissions_internal_error: "Ein interner Fehler ist aufgetreten.",
    updatePermissions_missing_permissions:
        "Sie verfügen nicht über die benötigte Berechtigung.",
    updatePermissions_missing_relation: "Fehlende Zuordnung - Kunden.",
    updatePermissions_parse_data: "Die Inputdaten sind Fehlerhaft.",
    upload_internal_error: "Ein interner Fehler ist aufgetreten.",
    upload_unsupported_media_type:
        "Das hochgeladene Dateiformat wird nicht unterstützt.",
    users_missing_permissions:
        "Sie verfügen nicht über die benötigte Berechtigung.",
    users_missing_relation: "Fehlende Zuordnung - Nutzer.",
    orderFPL_TAN_expired: "Die TAN ist bereits abgelaufen.",
    orderFPL_no_TAN: "BACK-END: Es stand keine TAN in der Tabelle.",
    orderFPL_yearlimit: "Diese Bestellung überschreitet Ihr Jahreslimit.",
    orderFPL_monthlimit: "Diese Bestellung überschreitet Ihr Monatslimit.",
    orderFPL_orderlimit:
        "Diese Bestellung überschreitet Ihr Limit für eine Einzelbestellung.",
    orderFPL_unknown_ttpfm_error: "Ein unbekannter Fehler ist aufgetreten.",
    orderFPL_bad_amount: "Die ausgewählte Menge ist zu klein.",
    orderFPL_bad_timeframe: "Der Prognosestart liegt in der Vergangenheit.",
    orderFPL_internal_error: "Ein interner Fehler ist aufgetreten.",
    orderFPL_missing_permissions:
        "Sie verfügen nicht über die benötigte Berechtigung.",
    orderFPL_missing_relation: "Fehlende Zuordnung - Prognose.",
    orderFPL_missing_TAN: "Sie haben leider keine TAN übermittelt.",
    orderFPL_ordered_recently:
        "Dieser Fahrplan wurde innerhalb der letzten 5 Minuten bereits gekauft und kann erst nach Ablauf dieser Zeit erneut gekauft werden.",
    orderFPL_parse_data: "",
    orderFPL_TAN_invalid: "Die eingegebene TAN ist ungültig.",
    creator: "Ersteller/in",
    buyer: "Käufer",
    buyerWithOutUmlaut: "Kaeufer",
    nameTooShortFirst: "Der Name muss aus mindestens ",
    nameTooShortSecond: " Zeichen bestehen.",
    wrongCharacters: "Ungültige Sonderzeichen.",
    invalidDate: "Invalides Datum",
    usageTooSmallYear: "Jahresverbrauch muss mindestens ",
    usageTooSmallDay: "Tagesverbrauch muss mindestens ",
    usageTooBigYear: "Jahresverbrauch darf maximal ",
    usageTooBigDay: "Tagesverbrauch darf maximal ",
    usageTooSmallMeasured: "Messverbrauch muss mindestens ",
    usageTooBigMeasured: "Messverbrauch darf maximal ",
    usageSecond: " betragen.",
    performanceTooSmall: "Leistung muss mindestens ",
    performanceTooBig: "Leistung darf maximal ",
    toDateTooBig: "Maximales Enddatum: ",
    fromDateTooSmall: "Minimales Startdatum: ",
    selectCustomer: "Kunde wählen",
    biggerThanMaxDate: "Datum zu groß. Maximaldatum ist der",
    biggerThanMaxDateTest: "Datum zu groß. Maximaldatum in Test Version ist der",
    smallerThanMinDate: "Datum zu klein. Minimaldatum ist der ",
    smallerThanMinDateTest: "Datum zu klein. Minimaldatum in Test Version ist der ",
    progcount: "Anzahl Angebote",
    password: "Passwort",
    invalidEmail: "Invalide Email",
    passwordMatch: "Passwörter müssen übereinstimmen",
    changePassword: "Passwort ändern",
    changeEmail: "Email ändern",
    changeName: "Name ändern",
    static: "Statisch",
    dynamic: "Dynamisch",
    csvExport: "CSV-Export",
    passwordDifferent: "Altes und neues Passwort müssen sich unterscheiden",
    newPasswordSuccess: "Sie haben erfolgreich Ihr Passwort geändert",
    newEmailSuccess: "Sie haben erfolgreich Ihre Emailadresse geändert",
    newNameSuccess: "Sie haben erfolgreich Ihren Namen geändert",
    newChargesSuccess:
        "Sie haben erfolgreich die globalen Aufschläge geändert.",
    confirmation: "Kaufbestätigung",
    confirmationWithOutUmlaut: "Kaufbestaetigung",
    finalBuy: "Jetzt verbindlich bestellen",
    pricecurvefirst: "Bitte wählen Sie zuerst die Preiskurve aus",
    specificPrice: "Spezifischer Preis",
    percentageForecast: "Prozentualer Anteil",
    pleaseEnterTan:
        "Bitte geben Sie die TAN ein, die wir an Ihre E-Mail Adresse verschickt haben.",
    remainingTime: "Verbleibende Zeit",
    confirmationStatus: "Bestätigungsstatus",
    perBuy: "pro Kauf",
    perMonth: "pro Monat",
    perYear: "pro Jahr",
    fourEyesPrinciple: "4-Augen-Prinzip",
    surchargePowerSpot: "Zusatzaufschlag Strom Spot [€/MWh]",
    surchargeGasSpot: "Zusatzaufschlag Gas Spot [€/MWh]",
    surchargePowerPrice: "Zusatzaufschlag Strom Preiskurve [€/MWh]",
    surchargeGasPrice: "Zusatzaufschlag Gas Preiskurve [€/MWh]",
    surchargePowerFormel: "Zusatzaufschlag Strom Formel [€/MWh]",
    surchargeGasFormel: "Zusatzaufschlag Gas Formel [€/MWh]",
    surcharges: "Zusatzaufschläge",
    prognosisCustomer: "Fahrplankunde",
    endCustomer: "Endkunde",
    industrialCustomer: "Industriekunde",
    buyHelperText: "Zahl muss zwischen 1% und 500% liegen",
    buyPrognosis: "Fahrplan kaufen",
    purchaseLimitExhausted:
        "Kauflimit erschöpft. Es können keine weiteren Käufe getätigt werden.",
    purchaseLimitExceededOne: "Kauflimit überschritten. Es können maximal",
    purchaseLimitExceededTwo: "% dieser Prognose gekauft werden.",
    purchaseCannotBeBought:
        "Kauf kann nicht getätigt werden, da möglich kaufbarer Prozentsatz < 1.",
    globalStromF: "Strom Preiskurve Fahrplankunde [€/MWh] ",
    globalStromE: "Strom Preiskurve Endkunde [€/MWh] ",
    globalStromI: "Strom Preis Industriekunde [€/MWh] ",
    globalStromSpotF: "Strom Spot Fahrplankunde [€/MWh] ",
    globalStromSpotE: "Strom Spot Endkunde [€/MWh] ",
    globalGasF: "Gas Preiskurve Fahrplankunde [€/MWh] ",
    globalGasE: "Gas Preiskurve Endkunde [€/MWh] ",
    globalGasI: "Gas Industriekunde [€/MWh] ",
    globalGasSpotF: "Gas Spot Fahrplankunde [€/MWh] ",
    globalGasSpotE: "Gas Spot Endkunde [€/MWh] ",
    globalCharges: "Globale Aufschläge",
    buyTimeLimit: "Kaufzeit Einstellung",
    buyTime: "Kaufzeit",
    buyTimeDeleteConfirm : "Sind Sie sicher, dass Sie die Kaufzeit des angegebenen Tages löschen möchten",
    BolligerBandUp: "Oberes Bollingerband",
    BolligerBandLow: "Unteres Bollingerband",
    charge: "Aufschlag",
    missing_permissions:
        "Sie verfügen nicht über die benötigten Berechtigungen",
    internal_error: "Ein interner Fehler ist aufgetreten",
    confirmOrder_bad_token: "Der eingegebene Token ist fehlerhaft",
    confirmOrder_invalid_token: "Der eingegebene Token ist ungültig",
    createCustomer_customer_exists: "Der anzulegende Kunde oder Kundennummer existiert bereits",
    createCustomer_customer_type_invalid:
        "Ein nicht gültiger Customertype wurde ausgewählt.",
    createCustomer_name_too_long: "Der gewählte Kundenname ist zu lang",
    deleteFPL_missing_relation:
        "Sie verfügen über keine Zuordnung zu der zu löschenden FPL",
    deleteFPL_order_reference:
        "Sie können keinen Fahrplan löschen der als Referenz eines gekauften Lastgangs verwendet wird",
    forecastCreation_deceed_usage_limit:
        "Die angegebene Leistung ist zu niedrig",
    forecastCreation_exceed_usage_limit: "Die angegebene Leistung ist zu hoch",
    generateTAN_confirmation_emails_conflict:
        "Der angegebene Prüfer ist nicht im System hinterlegt",
    generateTAN_confirmation_emails_missing:
        "Ihr Kauflimit wurde nicht festgelegt, bitte wenden Sie sich an ",
    generateTAN_exceed_limit: "Sie haben Ihr TAN Limit erreicht",
    generateTAN_invalid_data:
        "Zum generieren der TAN wurden fehlerhafte Daten uebermittelt",
    generateTAN_invalid_percentage:
        "Die angegebene Menge (Prozent) ist nicht gültig",
    generateTAN_parse_data: "Beim übermitteln der Daten trat ein Fehler auf",
    generateTAN_price_changed:
        "Der Preis für Ihr Profil hat sich seit dem Generieren der TAN geändert und kann nicht mehr garantiert werden. Bitte prüfen Sie den neuen Preis und starten den Kaufprozess erneut.",
    generateTAN_price_invalid: "Der Preis ist ungültig",
    generateTAN_send_email_error: "Die Mail konnte nicht versendet werden",
    generateTAN_send_confirmation_emails:
        "Beim Senden der Prüfmail ist ein Fehler aufgetreten",
    generateTAN_ordered_recently:
        "Dieser Fahrplan wurde innerhalb der letzten 5 Minuten bereits gekauft und kann erst nach Ablauf dieser Zeit erneut gekauft werden.",
    getCSV_missing_relation:
        "Sie verfügen über keine Zuordnung zu den zu exportierenden Daten",
    getFPLHeadData_missing_relation:
        "Sie verfügen über keine Zuordnung zu der FPL",
    getFPL_missing_relation: "Sie verfügen über keine Zuordnung zu der FPL",
    getForecastInformationByName_missing_relation:
        "Sie verfügen über keine Zuordnung zu der Prognose",
    getForecastInformationByName_parse_data:
        "Beim Übermitteln der Daten ist ein Fehler aufgetreten",
    getForecastInformation_missing_relation:
        "Sie verfügen über keine Zuordnung zu der Prognose",
    getFullFPLData_missing_relation:
        "Sie verfügen über keine Zuordnung zu der FPL",
    getHistoryData_missing_relation:
        "Sie verfügen über keine Zuordnung zu der Historie",
    getIntradayData_missing_relation:
        "Sie verfügen über keine Zuordnung zu den Intradaydaten",
    /* getLatestUserData_invalid_timestamp: "", */
    getOrderStatus_missing_relation:
        "Sie verfügen über keine Zuordnung zu der Bestellung",
    getPriceBounds_missing_relation:
        "Sie verfügen über keine Zuordnung zu den Preisgrenzen",
    getPriceBounds_parse_data:
        "Beim Übermitteln der Daten ist ein Fehler aufgetreten",
    login_failed: "Der Loginversuch ist fehlgeschlagen",
    login_parse_data:
        "Beim Übermitteln der Logindaten ist ein Fehler aufgetreten",
    orderFPL_pending_confirmation: "Die Bestätigung des Kaufvorgangs steht aus",
    orderFPL_send_email_error:
        "Beim versenden der Email ist ein Fehler aufgetreten",
    pricing_missing_relation: "Sie verfügen über keine Zuordnung zu dem Preis",
    resetPassswordRequest_email_invalid:
        "Die angegebene Mailadresse ist ungültig",
    resetPasswordRequest_missing_email: "Es wurde keine Mailadresse angegeben",
    resetPassword_password_empty: "Das Passwort ist leer",
    resetPassword_password_missing_special_chars:
        "Das Passwort enthält keine Sonderzeichen",
    resetPassword_password_short: "Das Passwort ist zu kurz",
    resetPassword_signature_expired: "Der Resettoken ist abgelaufen",
    resetPassword_signature_invalid: "Die Signatur ist ungueltig",
    resetPassword_token_invalid: "Der eingegebene Resettoken ist ungültig",
    setPriceBound_missing_relation:
        "Sie verfügen über keine Zuordnung zu der Preisgrenze",
    setPriceBounds_lower_greater_upper:
        "Die untere Preisgrenze darf nicht über der oberen Preisgrenze liegen",
    setPriceBounds_parse_data:
        "Beim Übermitteln der Daten ist ein Fehler aufgetreten",
    setStatus_missing_relation:
        "Sie verfügen über keine Zuordnung zu dem Fahrplan",
    switchPricing_parse_data:
        "Beim Übermitteln der Daten ist ein Fehler aufgetreten",
    updateCustomerCharge_bad_data:
        "Der eingegebene Kundenaufschlag ist ungültig",
    updateCustomerCharge_missing_relation:
        "Sie verfügen über keine Zurodnung zu diesem Kunden",
    updatePermissions_privilege_escalation:
        "Es wurde versucht höhere Rechte zu vergeben als mit dieser Lizenz möglich",
    updateSupplierCharge_bad_data:
        "Der eingegebene Lieferantenaufschlag ist ungültig",
    updateSupplierCharge_missing_relation:
        "Sie verfügen über keine Zuordnung zu dem Lieferanten",
    updateUser_current_password_mismatch:
        "Das eingegebene Passwort ist fehlerhaft",
    updateUser_email_exists:
        "Die eingegebene Mailadresse wird bereits verwendet",
    updateUser_email_too_long: "Die neue Mailadresse ist zu lang",
    updateUser_empty_data: "Es wurden keine zu ändernden Daten übermittelt",
    updateUser_invalid_email: "Die eingegebene Mailadresse ist ungültig",
    updateUser_missing_current_password:
        "Das aktuelle Passwort wurde bei der Änderung nicht eingegeben",
    updateUser_name_too_long: "Der neue Benutzername ist zu lang",
    updateUser_name_too_short: "Der neue Benutzername ist zu kurz",
    updateUser_parse_data: "Beim übermitteln der Daten trat ein Fehler auf",
    updateUser_password_missing_special_chars:
        "Das eingegebene Password muss Sonderzeichen enthalten",
    updateUser_password_too_short: "Das eingegebene Passwort ist zu kurz",
    totalPrice: "Gesamtpreis",
    costSupplier: "Energiebeschaffungskosten",
    energyprocurementprice: "Energiebeschaffungspreis",
    totalCosts: "Gesamterlös",
    totalCostsWithOutUmlaut: "Gesamterloes",
    margin: "Marge",
    getLatestUserData_invalid_timestamp: "",
    filterMonth: "Nach Monat",
    contains: "Enthält",
    notContains: "Enthält nicht",
    startsWith: "Beginnt mit",
    endsWith: "Endet mit",
    equals: "Ist gleich",
    doesNotEqual: "Ist nicht gleich",
    greaterThan: "Größer als",
    greaterThanOrEqual: "Größer gleich",
    lessThan: "Kleiner als",
    lessThanOrEqual: "Kleiner gleich",
    noData: "Keine Daten verfügbar",
    totalRowsSelected: "Ausgewählte Zeilen",
    count: "Anzahl",
    weightedAverage: "gew. Mittelwert",
    sum: "Summe",
    groupByColumn: "Um nach einer Spalte zu gruppieren, klicken Sie bitte auf das Listensymbol rechts neben dem entsprechenden Feld.",
    exportData: "Daten exportieren",
    customers: "Kunden",
    permissionsInfo:
        "Die Berechtigungen für diese/n Benutzer/in können anschließend im Nutzerprofil festgelegt werden.",
    questionsAndAnswers: "Bei Fragen und Problemen:",
    onlineSupport: "Onlinehilfe",
    offresid: "Angebots-ID: ",
    marketreport: "Marktbericht",
    customerSupport: "Kundensupport",
    minimum: "min. ",
    maximum: "max. ",
    characters: " Zeichen",
    uppercaseCharacter: "min. 1 Großbuchstaben (A-Z)",
    lowercaseCharacter: "min. 1 Kleinbuchstaben (a-z)",
    numberCharacter: "min. 1 Zahl (0-9)",
    specialCharacter: "min. 1 Sonderzeichen",
    prognosisInProcess: "Die Prognose ist in Bearbeitung",
    prognosisImportError: "Die Prognose konnte nicht importiert werden",
    pendingCurves: "Ausstehende Angebote",
    pending: "ausstehend",
    twoFA: "2-Faktor-Authentifizierung",
    explanation2FA:
        "2-Faktor-Authentifizierung:",
    scanQR: "1. Scannen Sie den QR-Code mit einer Authenticator App.",
    authenticating: "Authentifizieren",
    inputPin: "Geben Sie den Code aus Ihrer Authenticator-App ein.",
    tfa_missing_token: "Bitte geben Sie Ihren 2FA-Code an.",
    tfa_token_mismatch: "Der eingegebene 2FA-Code is ungültig.",
    tfa_bad_data: "Beim übermitteln der Daten trat ein Fehler auf",
    tfa_not_found:
        "Das Aktivieren eines nicht vorhandenen 2FA ist nicht moeglich",
    tfa_cannot_overwrite:
        "Aktuelle 2FA kann nicht überschrieben werden. Bitte löschen Sie diese vorher oder kontaktieren Sie Ihren Lieferanten",
    activateWithPin:
        "2. Bitte geben Sie nach dem Scannen des QR-Codes die Pin ein.",
    tfaActivated: "2-Faktor-Authentifizierung erfolgreich aktiviert.",
    tfaDeactivated: "2-Faktor-Authentifizierung erfolgreich deaktiviert.",
    activate: "aktivieren",
    deactivate: "deaktivieren",
    confirmedSuccess: "Freigabe erfolgreich erteilt",
    nameTooLongFirst: "Der Name darf aus max. ",
    nameTooLongSecond: " Zeichen bestehen.",
    finishedCurves: "Fertige Angebote",
    offersId:"Angebots-ID",
    edit: "Bearbeiten",
    userlist: "Benutzerliste",
    save: "Sichern",
    resetTime: "Zeit verlängern",
    sessionExpired: "Zeit abgelaufen",
    addcustomertosupplier: "Hinzufügen eines neuen Kunden zum Lieferanten",
    addusertocustomer: "Hinzufügen eines neuen Benutzers zum Kunden",
    addnewsupplier: "Hinzufügen eines neuen Benutzers zum Lieferanten",
    alreadybought: "bereits gekauft",
    stillFree: "noch Frei",
    customerwithpurchazedproduct: "Benutzer und nicht gekaufte Prognosen des Kunden löschen",
    customerwithoutpurchazedproduct: "Kunde mit allen Benutzern und Prognosen löschen",
    copyPastePassVerbote: "Das Passwort muss zeichenweise manuell eingegeben werden.",
    chargeOnlyWithClick: `Sie müssen direkt auf die Schaltfläche "SENDEN" klicken, um Ihre Anfrage zu bestätigen.`,
    powerPermission: "Berechtigung zum Erstellen neuer Stromangebote",
    gasPermission: "Berechtigung zum Erstellen neuer Gasangebote",
    tradingPermission: "Berechtigung zum Kaufen von Strom- und Gasangeboten",
    priceBoundsPermission: "Berechtigung zum Setzen von Preisgrenzen bei Strom- und Gasangeboten",
    startValueHint : "Der Wert ist über das Kontextmenü (rechte Maustaste) direkt aus der Tabelle rechts auszuwählen",
    yearlyUsage: "Jahres-",
    daily: "Tages",
    morethanAllowed: "Mehr als die erlaubte Menge.",
    zoominout:"Zum Hereinzoomen Bereich markieren / Zum Herauszoomen Doppelklick",
    foureyewarning: "ACHTUNG :",
    foureyewarningMessage:"Das Vier-Augen-Prinzip ist bei Ihnen aktiviert. Damit benötigen Sie mindestens einen weiteren User, der Ihren Kauf bestätigen kann. Als alleinig im System hinterlegter User können Sie bei Aktivierung des Vier-Augen-Prinzips keine Käufe tätigen.",
    priceMonitoring: "Preisüberwachung",
    RowPerPage : "Zeile pro Seite:",
    addToPfm: "PFM Verwaltung",
    portfolioManagement: "Portfoliomanagement",
    selectPortfolio:"Portfolio wählen",
    login_expired: "Leider ist Ihre Kontolaufzeit abgelaufen.",
    time: "Zeit",
    noIntraday:"Momentan gibt es leider keine Intraday Preise",
    noOfTranches:"Tranchenanzahl",
    from: "von",
    processing: "wird bearbeitet ",
    generateTAN_process_in_progress: "Für diese Prognose befindet sich jemand anderes bereits in einem aktiven Kaufprozess. Sie müssen bis zu 10 Minuten warten, bevor Sie diese Prognose kaufen können. Bitte prüfen Sie vor einem erneuten Kauf die Kaufhistorie, damit nicht versehentlich eine Prognose doppelt gekauft wird.",
    casenumber: "Vorgangs-ID",
    noSpotPrice: "Die Preisermittlung beginnt erst mit Lieferbeginn am ",
    unlimited: "unbegrenzt",
    purchases: "Käufe",
    rlmcommodityconflict: "Leider passen die gewählten Werte nicht zur Commodity, bitte erstellen Sie diese Prognose neu.",
    fleximportcommodityconflict: "Leider passen die gewählten Werte nicht zur Commodity. Bitte überprufen Sie die eingegebenen Werte und versuchen Sie es erneut.",
    addtoportfolio: ["Ordnen Sie das gewählte Angebot ", " einem oder mehreren Portfolien zu:" ],
    purchaseOn : "Kauf am",
    Dprice : "D-Preis",
    procurement: "Beschaffung",
    procured: "Beschafft",
    total : "Gesamt",
    noPortfolioMessage : "Es gibt noch kein Portfolio. Bitte erstellen Sie eines im Bereich Portfolioverwaltung",
    noPortfolioDetailMessage : "In diesem Portfolio sind noch keine Informationen zum Anzeigen vorhanden",
    addSuccessToPfm: "erfolgreich zum Portfolio hinzugefügt",
    removeSuccessFromPfm: "erfolgreich aus dem Portfolio entfernt",
    openPosition : "Offene Position",
    type : "Typ",
    offerSurcharge : "Angebotsaufschlag",
    offerSurchargeText : "Angebotsspezifischer Aufschlag [€/MWh]",
    zeroTrancheWarning : "Die Angebote mit Tranchenanzahl weniger als 1 können leider nicht zum PFM hinzugefügt werden",
    pricedevelopment: "Preisentwicklung",
    rlmcommodityconflictStunde: "Die gewählten Werte passen ggf. nicht zur aktuellen Commodity.",
    VirtualPurchase: "Virtueller Kauf",
    purchasePlusOP: "Käufe + OP",
    dailyTrading: "Handelstäglich",
    weekly: "Wöchentlich am",
    monthly : "monatlich am",
    automaticTrancheProcurement : "Automatische Tranchenbeschaffung",
    Atp: "ATB",
    Mtp: "MTB",
    Dp: "LZ",
    Mp: "BZ",
    acceptTheOffer : "Angebot annehmen",
    acceptTheOfferMessage : "Sie haben das Angebot erfolgreich angenommen",
    maxmoreTranchenOrder: "Max Mehrfachtranchen",
    acceptFrom : "Angebotsannahme",
    acceptTo : "Angebotsende",
    ManagementPeriod : "Bewirtschaftungszeitraum",
    procurementCalendar: "autom. Beschaffungskalender",
    procurementCal: "Beschaffungskalender",
    TypeOfTrancheProcurement: "Art der Tranchenbeschaffung",
    removeOrderFromOrders: "erfolgreich aus Bestellungen entfernt",
    deliveryPeriod : "Lieferzeitraum",
    OfferOpenUntil: "Angebot offen bis ",
    OfferWasAccepted : "Angebot angenommen am ",
    OfferExpiredOn : "Angebot ist abgelaufen am ",
    selectAll : "Alle",
    deselectAll : "Keine",
    TakingOverFrom : "übernehmen von",
    SuccessfulTimeChange : "Die Kaufzeit wurde erfolgreich gespeichert.",
    DeleteTimeDay : "Die Kaufzeit wurde erfolgreich entfernt.",
    monday: "Montag",
    sunday : "Sonntag",
    saturday : "Samstag",
    friday : "Freitag",
    wednesday : "Mittwoch",
    tuesday : "Dienstag",
    thursday : "Donnerstag",
    mondayStart: "Mo Start",
    sundayStart : "So Start",
    saturdayStart : "Sa Start",
    fridayStart : "Fr Start",
    wednesdayStart : "Mi Start",
    tuesdayStart : "Di Start",
    thursdayStart : "Do Start",
    mondayEnd: "Mo Ende",
    sundayEnd : "So Ende",
    saturdayEnd : "Sa Ende",
    fridayEnd : "Fr Ende",
    wednesdayEnd : "Mi Ende",
    tuesdayEnd : "Di Ende",
    thursdayEnd : "Do Ende",
    TotalManagementPeriod: "Gesamtbewirtschaftungszeitraum: ",
    totalDeliveryPeriod: "Gesamtlieferzeitraum: ",
    hedgechannelVerlassMeldung : ["Mit der geplanten Beschaffung würden Sie den erlaubten Hedgekanal verlassen. Zum aktuellen Zeitpunkt dürfen Sie maximal "," MWh kaufen, damit Sie innerhalb ( unter ", " ) des Hedgekanals bleiben.", "Mit diesem Kauf, läge der Beschaffungsgrad bei "],
    infoOscillatorsIndicators : "Informationen zu Indikatoren und Oszillatoren",
    priceHistory: "Preishistorie",
    GeneralVerification: "Allgemeine Verifizierung",
    verified: "verifiziert",
    userCount:"Benutzeranzahl",
    delayedPurchase: "Manuelle Tranchenbeschaffung",
    delayedPurchaseConfirmation: "Bestätigung verzögerter Kauf",
    colorSettings : "CI-Einstellungen",
    invalidUserCount: "Sie versuchen, mehr Benutzer einzugeben als erlaubt",
    tooOldPrice: "Die Preise sind zu alt, bitte versuchen Sie es später noch einmal",
    generateTAN_no_trading_day: "Der Kauf ist zum aktuellen Zeitpunkt nicht möglich",
    twofa_token_mismatch : "Die eingegebene PIN ist nicht korrekt, bitte versuchen Sie es erneut.",
    twofa_muss_aktiv : "Um diesen Vorgang fortzusetzen, muss bei Ihnen die 2-Faktor-Authentifizierung unter 'Mein Profil' aktiviert werden.",
    maxForecastCount : ["Die maximale Prognoseanzahl von " ," Prognosen wurde überschritten. Bitte reduzieren Sie diesen Wert."],
    endeOptions: " Wenn am Ende des Bewirtschafftungszeitraumes nicht alles beschafft ist, dann wird der gesamte Rest:",
    immediatelyAfterwards: "am unmittelbar auf das Bewirtschafftungsende folgenden Handelstag beschafft",
    onSpecificTradingDay: "am folgenden Handelstag beschafft",
    aboutSpot: "über den Spotmarkt beschafft mit folgender Preiskurve",
    MaxFileSize : "Maximale Dateigröße:",
    Secondary: "Sekundär",
    Primary: "Primär",
    gradient: "Farbverlauf",
    newCustomer: "Neuer Kunde",
    Disabled: "Deaktiviert",
    Activated: "Aktiviert",
    Activeoffers:"Aktive Angebote",
    Inactiveoffers:"Inaktive Angebote",
    Deletedoffers: "Gelöschte Angebote",
    OffersInPreparation : "Angebote in Vorbereitung",
    TrancheQuantity : "Tranchenmenge",
    BuyAtCurrentPrice : "Kauf zum aktuellen Preis",
    NoManualPurchasePossible: "Kein manueller Kauf möglich",
    BuyAtClosingPrice:"Kauf zum Schlusskurs",
    EndOfOffer: "Angebotsende",
    automatik: "Automatisch",
    AssignOverallFormula: "Gesamtformel der Prognose zuordnen",
    OverallFormula : "Gesamtformel",
    AddFormula: "Formelterm hinzufügen",
    factor: "Faktor",
    AdditiveConstant:"Additive Konstante [€/MWh]",
    SelectFormulaTerm: "Auswahl eines Formelterms",
};  

export default de;
