import React, { useEffect, useState } from "react";

import {
  Card,
  Paper,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
  useTheme,
  Chip,
  IconButton,
  CircularProgress,
  Grow,
  Menu,
  MenuItem,
  Tooltip as MTooltip,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  LineChart,
  XAxis,
  YAxis,
  Line,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import Papa from "papaparse";
import { lang } from "lang";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import VizSensor from "react-visibility-sensor";

//TypeDefs
import OrderModel from "../typeDefs/OrderModel";
import LoadcurvePriceHistory from "typeDefs/LoadcurvePriceHistory";
import LoadcurveModel from "typeDefs/LoadcurveModel";
import LoadcurveIntraday from "typeDefs/LoadcurveIntraday";
import ForecastInformation from "../typeDefs/ForecastInformation";

//Icons
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

//Hooks
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSelector } from "../services/helpers/useSelector";

//Services
import AuthService from "../services/auth/AuthService";
import PriceService from "../services/price/PriceService";
import LoadcurveService from "services/loadcurves/LoadcurveService";
import ForecastService from "../services/forecast/ForecastService";
import PortfolioService from "./../services/portfolios/PortfolioService";

//Actions
import snackbarActions from "../actions/snackbar.actions";
import authActions from "../actions/auth.actions";
import modalActions from "../actions/modal.actions";
import loadcurveActions from "../actions/loadcurve.actions";

//Components
import SingleBuyHistoryModalComponent from "./SingleBuyHistoryModal";
import DeleteLoadcurveComponent from "./DeleteLoadcurve";
import ExportComponent from "./Export";
import BuyLoadcurveComponent from "./BuyLoadcurve";
import SetPriceBoundMainPageComponent from "./SetPriceBoundMainPage";
import LineProgress from "./LineProgress";
import AddToPortfolio from "./AddToPortfolio";
import OfferSurchargeComponent from "./OfferSurchargePage";
import VirtualPurchaseComponent from "./VirtualPurchase";
import {
  parseISO,
  isAfter,
  isBefore,
  isEqual,
  subDays,
  differenceInDays,
} from "date-fns";
import GeneralVerification from "./GeneralVerification";
import DelayedBuy from "./DelayedBuy";

interface CardProps {
  loadcurve: LoadcurveModel;
  id: any;
  curvelistChanged: string;
  orderListChanged: boolean;
  handleDays:[];
  DataKind: number;
}

interface DiagramData {
  name: Date;
  price: number;
}

const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
  },
  forecastChip: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
  },
  boughtChip: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));

/**
 * Formats date to dd.mm.yyyy
 * @param date The date which should get formatted
 */
function formatDate(date: Date): string {
  return (
    ("0" + date.getDate()).slice(-2) +
    "." +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "." +
    date.getFullYear()
  );
}

const service: LoadcurveService = new LoadcurveService();
const priceService: PriceService = new PriceService();
const tooltipOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};
const LegendOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  // day: "numeric",
};
const intraOptions: Intl.DateTimeFormatOptions = {
  // year: "numeric",
  // month: "short",
  // day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

function LoadcurveCardComponent({
  loadcurve,
  id,
  curvelistChanged,
  orderListChanged,
  handleDays,
  DataKind,
}: CardProps) {
  const loadCurveCopy = { ...loadcurve };
  const isBetween =
    //@ts-ignore
    // isEqual(parseISO(loadcurve.accept_from), parseISO(loadcurve.accept_to)) ||
    //@ts-ignore
    ((isAfter(new Date(Date.now()), parseISO(loadcurve.order_from)) || isEqual(new Date(Date.now()), parseISO(loadcurve.order_from))) &&
      //@ts-ignore
      (isBefore(new Date(Date.now()), parseISO(loadcurve.order_to)) || isEqual(new Date(Date.now()), parseISO(loadcurve.order_to))));

  const PFMservive = new PortfolioService();
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  const ModalStatus = useSelector((state) => state.modal.open);
  const {ciSettings} = useSelector((state) => state);
  //@ts-ignore
  const fourEyesStatus = useSelector((state) => state.customer["customerData"].status);
  // console.log("fourEyesStatus",fourEyesStatus);
  //@ts-ignore
  const CustID = useSelector((state) => state.customer["customerData"].id);
  const loadcurveLenght = useSelector(
    (state) => state.loadcurve.loadcurves.length
  );
  //@ts-ignore
  // console.log(differenceInDays(new Date(Date.now()), parseISO(loadcurve.accept_to)), loadcurve.zrid,differenceInDays(new Date(Date.now()),parseISO(loadcurve.accept_to)))
  const forecastService = new ForecastService();
  let history = useHistory();
  const role = AuthService.getRole();
  const theme = useTheme();
  const locale = localStorage.locale;
  const localized = localStorage.locale;
  const variants = ["ohne Wind", "windarm", "normal", "windig", "windreich"];

  const dispatch = useDispatch();
  const [priceHistory, setPriceHistory] = useState<any>(undefined);
  const [inraDayPrice, setIntradayPrice] = useState<any>(undefined);
  const [recentPrice, setRecentPrice] = useState<number | undefined>(undefined);
  const [recentDate, setRecentDate] = useState<string | undefined>(undefined);
  const [recentTime, setRecentTime] = useState<string | undefined>(undefined);
  const [watchingIndicator, setWatchingIndicator] = useState(false);
  const [PreisHistoryChart, setPreisHistoryChart] = useState(true);
  const [intradayChart, setIntradayChart] = useState(false);
  const [orders, setOrders] = useState<OrderModel[] | undefined>(undefined);
  const [orderType, setOrderType] = useState(undefined);
  const [showHistory, setShowHistory] = useState(true);
  const [historyRight, setHistoryRight] = useState(false);
  const [showIntra, setShowIntra] = useState(false);
  const [intraRight, setIntraRight] = useState(false);
  const statePriceActive = useSelector((state) => state.price);
  const customerData = useSelector((state) => state.customer["customerData"]);
  const [readHist, setReadHist] = useState(true);
  const [UpperEnd, setUpperEnd] = useState(80);
  const [UpperStart, setUpperStart] = useState(10);
  const [PortfolioId, setPortfolioId] = useState(0);
  const [PortfolioDetails, setPortfolioDetails] = useState(undefined);
  //date for checking
  const [loading, setLoading] = React.useState(true);
  const [orderChanged, setOrderChanged] = useState(0);
  const [preisType, setPreisType] = useState("");
  const [rowCalenderString, setRowCalenderString] = useState("");
  const [CalenderArray, setCalenderArray] = useState([]);
  const [isBuying, setIsBuying] = useState(false);
  const [IstDataName, setIstDataName] = useState<any>(undefined);
  const [formelFromJson, setFormelFromjson] = useState({});
  const [constant, setConstant] = useState(0);
  const [formulas, setFormulas] = useState<any[]>([]);
  const [gesamtFormel, setGesamtFormel] = useState<string[]>([]);
  const [TotalAmount,setTotalAmount] = useState(0);
  const [DaysDifference,setDaysDifference] = useState(0);
  const [greenArrayStep, setGreenArrayStep] = useState<any>([]);
  //for loading price history
  //dates but formatted
  const [from, setFrom] = React.useState("");
  const [to, setTo] = React.useState("");

  const [forecastModel, setForecastModel] = useState<
    IForecastInformation | undefined | null
  >(undefined);
  //menu stuff
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [charge, setCharge] = useState<any>(undefined);
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const [snackbarChanged, setSnackbarChanged] = useState(false);
  const [latestOrderList, setLatestOrderList] = useState<
    OrderModel[] | undefined
  >(undefined);
  let interval;
  const showLocalLang = lang[localized].add === "Add" ? "en-EN" : "de-DE";
  interface PayloadData {
    dataKey: string;
    name: string;
    payload: object;
    value: number;
  }

  interface IForecastInformation {
    forecastFunction: string;
    forecastType: number;
    profileType: number;
    measureFrom: string;
    priceCurve: string;
    measureTo: string;
    forecastPeriod: string;
    productType: string;
    temperatureStation: string;
    holidayRegion: string;
    variant: string;
    performance: number;
    usage: number | undefined;
    //specific or measured
    usageType: string;
    creationTime: string;
    priceCurveName: string;
    creator: string;
    zrid?: number;
    name?: string;
    orderTo: string;
    orderFrom: string;
    acceptFrom: string;
    acceptTo: string;
    mehrFachTranchen: number;
    calendar: string;
  }

  //    //gets istData from api
  //    useEffect(() => {
  //     if(loggedIn && (forecastModel?.forecastType === 8 ||
  //       forecastModel?.forecastType === 1)){
  //       service
  //       .getSingleLoadcurve(loadcurve.zrid)
  //       .then((curve) => {
  //        console.log(curve)
  //        });
  //     }
  // }, []);

  //gets istData from api
  useEffect(()=>{

    if (loggedIn) {
      forecastService
        .getForecastInformation(loadcurve.zrid)
        .then((res) => {
          //check if zrid is there
          if (res.zrid_ist) {
              service.getSingleLoadcurve(res.zrid_ist).then((curve) => {
                setIstDataName(curve);
              });
            
          }
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          // dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }

  },[])

  useEffect(() => {
    if (orderChanged === 2) {
      setTimeout(() => {
        getOrdersByZrid();
        dispatch(loadcurveActions.getLoadcurves(CustID, DataKind));
        setOrderChanged(0);
      }, 3000);
    }
  }, [orderChanged]);

  function getForecastInfo() {
    forecastService
      .getForecastInformation(loadcurve.zrid)
      .then((serviceData: ForecastInformation) => {
        serviceData.order_type &&
        //@ts-ignore
        setOrderType(serviceData.order_type);
        serviceData.formula &&
        setFormelFromjson(JSON.parse(serviceData.formula));
        // serviceData.calendar && setRowCalenderString(serviceData.calendar);

        Papa.parse(serviceData.info, {
          delimitersToGuess: ["|"],
          complete: (results) => {
            let model: IForecastInformation = {
              zrid: undefined,
              forecastFunction: "",
              forecastPeriod: "",
              measureFrom: "",
              measureTo: "",
              forecastType: -1,
              holidayRegion: "",
              performance: 0,
              productType: "",
              profileType: serviceData.profiletype,
              temperatureStation: "",
              usage: undefined,
              usageType: "",
              priceCurve: "",
              variant: "",
              priceCurveName: "",
              creationTime: "",
              creator: "",
              name: "",
              orderTo: "",
              orderFrom: "",
              acceptFrom: "",
              acceptTo: "",
              mehrFachTranchen: 0,
              calendar: "",
            };

            //slice the unnecessary elements
            const data = results.data[0].slice(2, results.data[0].length - 1);

            data.forEach((d) => {
              //split by = because thats how its split
              const splitted = d.split("=");

              switch (splitted[0]) {
                case "Prognoseverfahren":
                  model.forecastType = parseFloat(splitted[1]);
                  break;
                case "Feiertagsregion":
                  model.holidayRegion = splitted[1];
                  break;
                case "Verbrauchsart":
                  model.usageType = splitted[1];
                  break;
                case "SLPJVMWh":
                  model.usage = parseFloat(splitted[1]);
                  break;
                case "PLZ":
                  model.temperatureStation = splitted[1];
                  break;
                case "Prognosezeiten":
                  model.forecastPeriod = splitted[1];
                  break;
                case "MessFrom":
                  model.measureFrom = splitted[1];
                  break;
                case "MessTo":
                  model.measureTo = splitted[1];
                  break;
                case "IsBase":
                  model.productType =
                    parseFloat(splitted[1]) === 0 ? "Peak" : "Base";
                  break;
                case "Leistung_kW":
                  model.performance = parseFloat(splitted[1]);
                  break;
                case "Variante":
                  model.variant = splitted[1];
              }
            });
            //switch over the first item of array
            // console.log("serviceData", serviceData);
            model.zrid = serviceData.zrid;
            model.profileType = serviceData.profiletype;
            model.forecastFunction = serviceData.function;
            model.creationTime = serviceData.timestamp;
            model.creator = serviceData.email;
            /*  const splitName = serviceData.name.split("_"); */
            model.priceCurveName = serviceData.pricingcurve;
            model.name = loadcurve?.zr_name;
            // model.orderFrom = loadcurve.order_from;
            // model.orderTo = loadcurve.order_to;
            // model.acceptFrom = loadcurve.accept_from;
            // model.acceptTo = loadcurve.accept_to;
            // model.mehrFachTranchen = loadcurve.max_order_tranche;
            // console.log("serviceData", serviceData);
            /* model.priceCurve = splitName[1];
                      model.priceCurveName = splitName[0]; */
            setForecastModel(model);
          },
        });
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        // console.error(
        //   "Error getting forecastinformation for ZRID",
        //   loadcurve.zrid,
        //   e
        // );
        // dispatch(snackbarActions.openSnackbar(e.message, "error"));
        setForecastModel(null);
      });
  }

  // convert first letter of preis type on loadcurves
  const firstLetterCapitalPreisType = (x) => {
    if (x === "PREISKURVE") {
      const tempo = lang[locale].pricecurve.toLowerCase();
      return tempo.charAt(0).toUpperCase() + tempo.slice(1);
    }
    if (x === "FORMEL") {
      const tempo = lang[locale].formula.toLowerCase();
      return tempo.charAt(0).toUpperCase() + tempo.slice(1);
    }
    if (x === "SPOT") {
      const tempo = "SPOT".toLowerCase();
      return tempo.charAt(0).toUpperCase() + tempo.slice(1);
    }
    if (x === "FESTPREIS") {
      return lang[locale].fixedprice;
    }
  };

  function handleEnterMouse(e) {
    if (orders?.length !== loadcurve.tranche) {
      getOrdersByZrid();
    }
  }

  function handleAcceptOffer(id) {
    // service.getAcceptOffer(id);
    // setSnackbarChanged(true);
    dispatch(
      modalActions.openModal(
        GeneralVerification,
        {
          zrid: loadcurve.zrid,
          fourEyesStatus,
          setSnackbarChanged,
        },
        4
      )
    );
    // .then((res) => {
    //   console.log("res", res);
    // })
    // .catch((e) => {
    //   if (e.message === "401") {
    //     return history.push(`/logout`);
    //   }
    //   console.error(e);
    //   // dispatch(snackbarActions.openSnackbar("Angebot annehmen Zeitraum ist abgelaufen", "error"));
    // });
    // dispatch(
    //   snackbarActions.openSnackbar(lang[locale].parallelBuyWarning, "success")
    // );
    // dispatch(snackbarActions.openSnackbar("Sie haben schon des Angebot angenommen", "success"))
    handleClose();
  }

  const calculateDaysDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the difference in milliseconds
    //@ts-ignore
    const differenceInMilliseconds = end - start;

    // Convert milliseconds to days
    const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    // Update the state with the calculated difference
    setDaysDifference(differenceInDays);
  };

  const calculateDaysBetween = (startDate, endDate) => {
    const newDaysList = [];

    // Copy the start date to avoid modifying the original date
    let currentDate = new Date(startDate);

    // Iterate through each day until reaching the end date
    while (currentDate <= endDate) {
      //@ts-ignore
      newDaysList.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return newDaysList;
  };

  const portfoDetailHandler = (CustID, id) => {
    //@ts-ignore
    PFMservive.getPortfolioDetails(Number(CustID), id)
      .then((res) => {

        setPortfolioDetails(res["data"]);
        setTotalAmount(res["total_amount"] / 1000);
        // setActuellSum(sumValuesWithPrevious(res["data"]));
                //@ts-ignore
                calculateDaysDifference(res.portfolio_from, res.portfolio_to);
                //@ts-ignore
                if (Date.now() <= new Date(res.portfolio_to).getTime()) {
                  //@ts-ignore
                  setGreenArrayStep( calculateDaysBetween(res.portfolio_from, Date.now())
                  );
                }
                //@ts-ignore
                if (Date.now() > new Date(res.portfolio_to).getTime()) {
                  //@ts-ignore
                  setGreenArrayStep(calculateDaysBetween( res.portfolio_from,new Date(res.portfolio_to).getTime()
                    )
                  );
                }
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  };

  const getSinglePortfolio = () => {
    //@ts-ignore
    PFMservive.getInPortfolio(Number(CustID), loadcurve.zrid)
      .then((res) => {
        if(res.length === 1){
          //@ts-ignore
          setPortfolioId(res[0]);
        }
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        // dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  };

  // console.log("total", TotalAmount, "portdetail", PortfolioDetails);
  useEffect(() => {
    if(snackbarChanged === true){
      dispatch(
        snackbarActions.openSnackbar(
          lang[locale].acceptTheOfferMessage,
          "success"
        )
      );
      setTimeout(() => {
        dispatch(loadcurveActions.getLoadcurves(CustID, DataKind));
        setSnackbarChanged(false);
      }, 3000);
    } 
  }, [snackbarChanged]);
  
  useEffect(()=>{
    if(PortfolioId > 0){
      portfoDetailHandler(CustID,PortfolioId);
      PFMservive.getHedgeChannalValues(CustID, PortfolioId).then(
        (res) => {
          setUpperEnd(res.upper_end);
          setUpperStart(res.upper_start);
        }
      );
    }
  },[PortfolioId])

  //opens menu
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    getSinglePortfolio();
    getlatestOrdersByZrid();
    dispatch(authActions.checkLogin());
    setAnchorEl(event.currentTarget);
  };

  /**
   * Handles close of menu
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * opens Export Modal
   */
  const handleExportClick = () => {
    handleClose();
    dispatch(
      modalActions.openModal(
        ExportComponent,
        {
          zrid: loadcurve.zrid,
          commodity_id: loadcurve.commodity_id,
        },
        4
      )
    );
  };

  const offerSurchargeHandler = () => {
    handleClose();
    dispatch(
      modalActions.openModal(
        OfferSurchargeComponent,
        {
          zrid: loadcurve.zrid,
          commodity_id: loadcurve.commodity_id,
          fpl_charge: loadcurve.fpl_charge,
        },
        2
      )
    );
  };

  const sendBuyStatusFromChild = (buy) => {
    if (buy === 1) {
      setIsBuying(true);
    } else if (buy === 0) setIsBuying(false);
  };
  const handleBuyClick = () => {
    handleClose();
    if (
      latestOrderList &&
      orders &&
      orders?.length !== latestOrderList?.length
    ) {
     const timer = setTimeout(() => {
        dispatch(
          snackbarActions.openSnackbar(
            lang[locale].parallelBuyWarning,
            "warning"
          )
        );
        getOrdersByZrid();
      }, 2000);
      return ()=> clearTimeout(timer)
    } else {
      if(orderType === "MANUAL"){
        dispatch(
          modalActions.openModal(BuyLoadcurveComponent, {
            zrid: loadcurve.zrid,
            recentPrice: recentPrice,
            amount: loadcurve.menge,
            name: loadcurve.zr_name.split("_")[0],
            sendBuyStatusFromChild: sendBuyStatusFromChild,
            tranche: loadcurve.tranche,
            max_Order_Tranche: loadcurve.max_order_tranche,
            ordersLength: orders?.length,
            TotalAmount,
            PortfolioDetails,
            PortfolioId,
            UpperEnd,
            UpperStart,
            DaysDifference,
            greenArrayStep,
          })
        );
      }
      else if(orderType === "DELAYED_AUTO"){
        dispatch(
          modalActions.openModal(DelayedBuy, {
            zrid: loadcurve.zrid,
            recentPrice: recentPrice,
            amount: loadcurve.menge,
            name: loadcurve.zr_name.split("_")[0],
            sendBuyStatusFromChild: sendBuyStatusFromChild,
            tranche: loadcurve.tranche,
            max_Order_Tranche: loadcurve.max_order_tranche,
            ordersLength: orders?.length,
            TotalAmount,
            PortfolioDetails,
            PortfolioId,
            UpperEnd,
            UpperStart,
            DaysDifference,
            greenArrayStep,
          })
        );
      }
    }
  };

  function handlerVirtualPurchase() {
    handleClose();
    dispatch(
      modalActions.openModal(
        VirtualPurchaseComponent,
        {
          zrid: loadcurve.zrid,
          name: loadcurve.zr_name,
          customer: customerData,
          amount: loadcurve.menge,
          setOrderChanged,
          orderFrom: loadcurve.order_from,
          orderTo:loadcurve.order_to,
          type: forecastModel?.forecastFunction,
          productTyp : forecastModel?.productType,
          ProgFrom: loadcurve.von,
          ProgTo: loadcurve.bis,
          leistung : forecastModel?.performance,
        },
        4
      )
    );
  }

  function handlerSetPreisBound() {
    handleClose();
    dispatch(
      //@ts-ignore
      modalActions.openModal(SetPriceBoundMainPageComponent,{ zrid: loadcurve.zrid, DataKind },4));
  }

  const handleAddToPFM = () => {
    handleClose();
    if (loadcurve.tranche === 0) {
      setTimeout(() => {
        dispatch(
          snackbarActions.openSnackbar(
            lang[locale].zeroTrancheWarning,
            "warning"
          )
        );
      }, 1000);
    }
    if (loadcurve.tranche && loadcurve.tranche > 0) {
      dispatch(
        //@ts-ignore
        modalActions.openModal(
          AddToPortfolio,
          {
            commodity: loadcurve.commodity_id,
            zrid: loadcurve.zrid,
            customer: customerData,
          },
          4
        )
      );
    }
  };

  const btnDeleteDisable =
    (!role.permissions?.includes("STROM") && loadcurve.commodity_id === 1) ||
    (!role.permissions?.includes("GAS") && loadcurve.commodity_id === 2) ||
    (preisType !== "SPOT" && recentPrice === undefined) || (loadcurve && loadcurve.accepted);

  function toggleIntraHistory() {
    setShowHistory(!showHistory);
    setPreisHistoryChart(!PreisHistoryChart);
    setShowIntra(!showIntra);
    setIntradayChart(!intradayChart);
  }

  function decodeBase64ToArray(base64String) {
    // Decode the Base64 string
    const decodedString = atob(base64String);
    // Split the decoded string into an array of strings based on a delimiter (e.g., comma)

    return decodedString;
  }

  useEffect(() => {
    if (loggedIn) {
      forecastService
        .getForecastInformation(loadcurve.zrid)
        .then((res) => {
          //check if zrid is there
          setPreisType(res.pricing_kind);

          res.calendar && setRowCalenderString(res.calendar);
          //@ts-ignore
          setCalenderArray(decodeBase64ToArray(rowCalenderString).split(",").map((i) => i.replace(/[\[\]'""]/g, ""))
          );
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
  }, []);

  useEffect(() => {
    if (loggedIn) {
      if (
        role.permissions?.includes("VIEW_INTRADAY") &&
        role.permissions?.includes("VIEW_HISTORY")
      ) {
        setHistoryRight(true);
        setShowHistory(true);
        setIntraRight(true);
        setShowIntra(false);
      } else if (
        role.permissions?.includes("VIEW_INTRADAY") &&
        !role.permissions?.includes("VIEW_HISTORY")
      ) {
        setShowHistory(false);
        setHistoryRight(false);
        setIntraRight(true);
        setIntradayChart(true);
        setShowIntra(true);
      } else if (
        !role.permissions?.includes("VIEW_INTRADAY") &&
        role.permissions?.includes("VIEW_HISTORY")
      ) {
        setHistoryRight(true);
        setShowHistory(true);
        setIntraRight(false);
        setIntradayChart(false);
      }
    }
  }, []);

  useEffect(() => {
    if (loggedIn) {
      const fromDate = new Date(loadcurve.von);
      const toDate = new Date(loadcurve.bis);
      //set from date
      setFrom(formatDate(fromDate));
      setTo(formatDate(toDate));
    }
  }, [loadcurve]);

  // useEffect(()=>{
  //   if (orders?.length !== loadcurve.tranche) {
  //   const interval = setInterval(() => {
  //     setOrders(loadcurve.orders)
  //     console.log("orders")
  //   }, 10000);
  //   return () => clearInterval(interval);
  // }
  // },[])

  function handleDelete() {
    handleClose();
    dispatch(
      modalActions.openModal(DeleteLoadcurveComponent, {
        zrid: loadcurve.zrid,
        name: loadcurve.zr_name,
        DataKind
      })
    );
  }

  function getOrdersByZrid() {
    if (loggedIn) {
      service
        .getOrdersByZrid(loadcurve.zrid)
        .then((data: OrderModel[]) => {
          setOrders(data);
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
  }

  function getlatestOrdersByZrid() {
    if (loggedIn) {
      service
        .getOrdersByZrid(loadcurve.zrid)
        .then((data: OrderModel[]) => {
          setLatestOrderList(data);
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
  }

  function getPricingActive() {
    if (
      role.permissions.includes("MONITORING")
      // &&
      // role.simple_role !== "LIEFERANT"
    ) {
      priceService
        .getPriceBounds(loadcurve.zrid)
        .then((res) => {
          if (res.status === 1) setWatchingIndicator(true);
          if (res.status === 0) setWatchingIndicator(false);
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          setWatchingIndicator(false);
          console.error(e);
          // dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
  }

  function handleShowOrders() {
    // setTimeout(() => {
    if (orders)
      dispatch(
        modalActions.openModal(SingleBuyHistoryModalComponent, { orders }, 10)
      );
    // }, 2000);
  }

  /*Get the last price*/

  function getLatestHistory() {
    if (loggedIn) {
      service
        .getLoadCurveLatestHistory(loadcurve.zrid)
        .then((data: LoadcurvePriceHistory) => {
          if (data && data.preis === -1) {
            setRecentPrice(-1);
          } else if (data) {
            const price =
              role.simple_role === "LIEFERANT"
                ? data.preis + data.aufschlag
                : data.preis;

            // const name = new Date(data.erstellungsdatum);
            setCharge(data.aufschlag);
            setRecentPrice(price);
            setRecentDate(new Date(data.erstellungsdatum).toLocaleDateString());
            setRecentTime(new Date(data.erstellungsdatum).toLocaleTimeString());
            /*Checks if the date is the same*/
            // priceHistory &&
            //   name &&
            //   priceHistory[-1]?.name === name &&
            //   setPriceHistory(priceHistory.pop());
            // priceHistory && setPriceHistory(priceHistory.push({ name, price }));
          } else {
            setRecentPrice(-1);
          }
          //disable loading
          setLoading(false);
        })
        .catch((e: Error) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          interval = null;
          console.error("Getting history of ", loadcurve.zrid, e);
          //also disable loading
          setLoading(false);
          clearInterval(interval);
          console.error(e);
          // dispatch(snackbarActions.openSnackbar(e.message, "warning"));
        });
    }
  }
  // console.log("pricehist", priceHistory);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getHistory() {
    if (loggedIn && priceHistory === undefined && recentPrice === undefined) {
      service
        .getLoadcurvePriceHistory(loadcurve.zrid)
        .then((data: LoadcurvePriceHistory[]) => {
          if (data.length !== 0 && data[data.length - 1].preis === -1) {
            setPreisHistoryChart(false);
            setRecentPrice(-1);
          }
          //check if data is there
          else if (data.length !== 0) {
            //sort array

            const diagramArray: DiagramData[] = [];
            //get the right values
            // const charge = data[0].aufschlag;
            setCharge(data[data.length - 1].aufschlag);
            data.forEach((histElement) => {
              const price = Number(histElement.preis);
              const pushObj: DiagramData = {
                name: new Date(histElement.erstellungsdatum),
                price: Math.round(price * 100) / 100 + histElement.aufschlag,
              };
              diagramArray.push(pushObj);
            });

            // diagramArray.reverse();

            //set states
            setPriceHistory(
              diagramArray.length > 60
                ? diagramArray.slice(Math.max(diagramArray.length - 60, 1))
                : diagramArray
            );
            setReadHist(false);
            //set recent data
            setRecentPrice(diagramArray[diagramArray.length - 1].price);
            setRecentDate(
              new Date(
                data[data.length - 1].erstellungsdatum
              ).toLocaleDateString()
            );
            setRecentTime(
              new Date(
                data[data.length - 1].erstellungsdatum
              ).toLocaleTimeString()
            );
          } else {
            setRecentPrice(undefined);
            setPriceHistory(undefined);
          }
          //disable loading
          setLoading(false);
        })
        .catch((e: Error) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          interval = null;
          // console.error("Getting history of ", loadcurve.zrid, e);
          //also disable loading
          setLoading(false);
          clearInterval(interval);
          console.error(e);
          // dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
  }

  /* Get intraday*/
  function getIntradayfunc() {
    service
      .getIntraday(loadcurve.zrid)
      .then((data: LoadcurveIntraday[]) => {
        if (data) {
          // data.forEach((histElement) => {
          //   const price = Number(histElement.preis);
          //   const pushObj: DiagramData = {
          //     name: new Date(histElement.dat),
          //     price: Math.round(price * 100) / 100 + data[data.length - 1].aufschlag,
          //   };
          //   diagramArray.push(pushObj);
          // });
          let intraDayDiagram = [];
          data.forEach((intradayElement) => {
            const pushOBJ = {
              name: new Date(intradayElement.dat),
              price: intradayElement.preis,
              zrid: intradayElement.zrid,
            };
            //@ts-ignore
            intraDayDiagram.push(pushOBJ);
          });
          setIntradayPrice(intraDayDiagram);
          setIntradayChart(true);
        } else {
          // setRecentPrice(-1);
        }
        //disable loading
      })
      .catch((e: Error) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
      });
  }
  // console.log("inradayPrice", inraDayPrice);

  let trendingIcon;
  if (priceHistory && priceHistory.length > 1 && recentPrice)
    trendingIcon =
      priceHistory[priceHistory?.length - 2].price < recentPrice ? (
        <TrendingUpIcon fontSize="small" color="error" />
      ) : priceHistory[priceHistory?.length - 2].price > recentPrice ? (
        <TrendingDownIcon fontSize="small" className={classes.success} />
      ) : (
        <TrendingFlatIcon fontSize="small" color="secondary" />
      );
  //get history initially and also get the orders

  //hook used to get price history
  useEffect(() => {
    //get price history from database
    if (!priceHistory && !interval) {
      console.debug("...setting interval");
    } else if (
      (priceHistory && priceHistory.length > 1) ||
      (priceHistory && priceHistory[0] && priceHistory[0].price !== -1)
    ) {
      clearInterval(interval);
      interval = null;
    }
    //cleanup and false the is mounted state
    return () => {
      clearInterval(interval);
      interval = null;
    };
  }, [dispatch, priceHistory]);

  /* updates the pricehistory every minute*/
  // useEffect(() => {
  //   if (loggedIn && intraRight && visible) {
  //     getIntradayfunc();
  //   }
  // }, []);

  useEffect(() => {
    // if (loggedIn && visible) {
    const interval = setInterval(() => {
      getLatestHistory();
      // getOrdersByZrid();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
    // }
  }, []);

  // useEffect(() => {
  //   if (loggedIn && role.permissions?.includes("VIEW_HISTORY") && visible) {
  //     const interval = setInterval(() => {
  //       getOrdersByZrid();
  //     }, 10000);
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }
  // }, [orderListChanged]);

  useEffect(() => {
    if (
      loggedIn &&
      role.permissions?.includes("VIEW_HISTORY") &&
      visible &&
      ModalStatus === false
    ) {
      const interval = setInterval(() => {
        if (
          priceHistory === undefined &&
          recentPrice === undefined &&
          readHist === true
        ) {
          getHistory();
        }
        // }, 30000);
      }, 60000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [curvelistChanged]);

  useEffect(() => {
    if (
      loggedIn &&
      role.permissions?.includes("VIEW_HISTORY") &&
      ModalStatus === false
    ) {
      const timeOut = setTimeout(() => {
        if (
          priceHistory === undefined &&
          recentPrice === undefined &&
          readHist === true
        ) {
          getHistory();
        }
        // }, 40000);
      }, 10000);
      return () => {
        clearTimeout(timeOut);
      };
    }
  }, []);

  // useEffect(() => {
  //   if (loggedIn && role.permissions?.includes("VIEW_INTRADAY") && visible) {
  //     const interval = setTimeout(() => {
  //       getIntradayfunc();
  //     }, 90000);
  //     return () => {
  //       clearTimeout(interval);
  //     };
  //   }
  // }, [loadcurveLenght]);

  useEffect(() => {
    if (loggedIn && visible) {
      getPricingActive();
    }
  }, [statePriceActive]);

  // useEffect(() => {
  //   if (loggedIn) {
  //     if (
  //       !role.permissions?.includes("VIEW_DETAIL") &&
  //       !role.permissions?.includes("VIEW_INTRADAY") &&
  //       !role.permissions?.includes("VIEW_HISTORY")
  //     ) {
  //       getLatestHistory();
  //       getOrdersByZrid();
  //       getPricingActive();
  //     }
  //     if (
  //       role.permissions?.includes("VIEW_DETAIL") &&
  //       !role.permissions?.includes("VIEW_INTRADAY") &&
  //       !role.permissions?.includes("VIEW_HISTORY")
  //     ) {
  //       getLatestHistory();
  //       getOrdersByZrid();
  //       getPricingActive();
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (Object.keys(formelFromJson).length > 0) {
      setConstant(formelFromJson["constant"]);
      setFormulas(formelFromJson["formulas"]);
      if (formelFromJson["formulas"].length === 1)
        setGesamtFormel([
          `${formelFromJson["formulas"][0].factor} x ${formelFromJson["formulas"][0].time_frame} ${formelFromJson["formulas"][0].product} ${formelFromJson["formulas"][0].year} + `,
        ]);
      if (formelFromJson["formulas"].length > 1) {
        for (let i of formelFromJson["formulas"]) {
          gesamtFormel.push(
            ` ${i.factor} x ${i.time_frame} ${i.product} ${i.year} + `
          );
        }
      }
    }
  }, [Object.keys(formelFromJson).length]);

  function CustomToolTipPreisHistorie({
    active,
    payload,
    // label,
    // setLocalPriceCurveData,
  }) {
    const locale = localStorage.locale;
    let realPayload: PayloadData | undefined = undefined;
    let dateKey = "";
    if (payload[0]) {
      realPayload = payload[0];
      let keys = Object.keys(payload[0].payload);
      //filter for the right date key
      dateKey = keys.filter((key) => key !== realPayload?.dataKey)[0];
    }

    return (
      <>
        {realPayload && realPayload.value > 0 && (
          <Paper style={{ padding: "20px 10px" }}>
            <Typography>
              {lang[locale].date}:{" "}
              {payload[0].payload.erstellungsdatum
                ? new Date(payload[0]?.payload.erstellungsdatum).toLocaleString(
                    showLocalLang,
                    tooltipOptions
                    // {
                    //   day: "2-digit",
                    //   month: "2-digit",
                    //   year: "numeric",
                    //   hour: "2-digit",
                    //   minute: "2-digit",
                    //   second: "2-digit",
                    // }
                  )
                : new Date(realPayload.payload[dateKey]).toLocaleString(
                    showLocalLang,
                    tooltipOptions
                  )}
            </Typography>
            <Typography>
              {lang[locale].price} : {` `}
              <NumberFormat
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale={true}
                decimalScale={2}
                suffix=" €/MWh"
                value={realPayload.value}
                displayType={"text"}
              />
            </Typography>
            {realPayload.payload["kombinierterPreis"] && (
              <Typography>
                {lang[locale].totalPrice} : {` `}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  decimalScale={2}
                  suffix=" €/MWh"
                  value={realPayload.payload["kombinierterPreis"][1]}
                  displayType={"text"}
                />
              </Typography>
            )}
          </Paper>
        )}
      </>
    );
  }

  // console.log("isBetween",isBetween, loadcurve.zrid)
  function CustomToolTipIntraDayPreis({
    active,
    payload,
    // label,
    // setLocalPriceCurveData,
  }) {
    const locale = localStorage.locale;
    let realPayload: PayloadData | undefined = undefined;
    let dateKey = "";
    if (payload[0]) {
      realPayload = payload[0];
      let keys = Object.keys(payload[0].payload);
      //filter for the right date key
      dateKey = keys.filter((key) => key !== realPayload?.dataKey)[0];
    }

    return (
      <>
        {realPayload && realPayload.value > 0 && (
          <Paper style={{ padding: "20px 10px" }}>
            <Typography>
              {lang[locale].time}:{" "}
              {payload[0].payload.name
                ? new Date(payload[0]?.payload.name).toLocaleString(
                    showLocalLang,
                    {
                      // day: "2-digit",
                      // month: "2-digit",
                      // year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      // second: "2-digit",
                    }
                  )
                : new Date(realPayload.payload[dateKey]).toLocaleString(
                    showLocalLang,
                    tooltipOptions
                  )}
            </Typography>
            <Typography>
              {realPayload.name} : {` `}
              <NumberFormat
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale={true}
                decimalScale={2}
                suffix=" €/MWh"
                value={realPayload.value}
                displayType={"text"}
              />
            </Typography>
            {/* {realPayload.payload["kombinierterPreis"] && (
              <Typography>
                {lang[locale].totalPrice} : {` `}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  decimalScale={2}
                  suffix=" €/MWh"
                  value={realPayload.payload["kombinierterPreis"][1]}
                  displayType={"text"}
                />
              </Typography>
            )} */}
          </Paper>
        )}
      </>
    );
  }

  return (
    <VizSensor
      onChange={(isVisible) => {
        if (loggedIn && isVisible && !orders) {
          setVisible(true);
          if (
            !role.permissions?.includes("VIEW_HISTORY") &&
            role.permissions?.includes("VIEW_INTRADAY")
          ) {
            setPreisHistoryChart(false);
            setIntradayChart(true);
            getLatestHistory();
            getOrdersByZrid();
            getPricingActive();
            getIntradayfunc();
            getForecastInfo();
            // IstDataNameReader();
          }
          if (
            role.permissions?.includes("VIEW_HISTORY") &&
            role.permissions?.includes("VIEW_INTRADAY")
          ) {
            setPreisHistoryChart(true);
            getHistory();
            getOrdersByZrid();
            getPricingActive();
            getIntradayfunc();
            getForecastInfo();
            // IstDataNameReader();
          } else if (
            role.permissions?.includes("VIEW_HISTORY") &&
            !role.permissions?.includes("VIEW_INTRADAY")
          ) {
            setIntradayChart(false);
            // IstDataNameReader();
            getHistory();
            getOrdersByZrid();
            getPricingActive();
            getForecastInfo();
            // getIntradayfunc();
          }
        }
      }}
    >
      {loading ? (
        <Skeleton variant="rect" max-width={300} height={250}></Skeleton>
      ) : (
        <Card style={{ maxWidth: "95%" }}>
          <>
            <div
              style={{
                width: "100%",
                backgroundColor:
                  loadcurve.commodity_id === 1
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main,
                height: 21,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  color: loadcurve.commodity_id === 1 ? "white" : "#3d3b3b",
                  fontSize: "0.8em",
                  padding: "0.5rem 0.7rem 0.5rem",
                }}
              >
                {firstLetterCapitalPreisType(preisType)}
              </span>
              {/* 
              <MTooltip
                title={
                  loadcurve && loadcurve.accepted
                    ? "Angebote ist schon angenommen"
                    : loadcurve.accept_to &&
                      isBefore(
                        subDays(new Date(Date.now()), 1),
                        new Date(loadcurve.accept_to)
                      )
                    ? "Angebot offen bis " +
                      loadcurve.accept_to
                        .toString()
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join(".")
                    : "Angebot ist abgelaufen am " +
                      loadcurve.accept_to
                        ?.toString()
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join(".")
                }
              >
                <div
                  style={{
                    backgroundColor:
                      loadcurve && loadcurve.accepted
                        ? "#7BFC02"
                        : loadcurve.accept_to &&
                          isBefore(
                            subDays(new Date(Date.now()), 1),
                            new Date(loadcurve.accept_to)
                          )
                        ? "orange"
                        : "red",
                    width: "0.7rem",
                    height: "0.7rem",
                    borderRadius: "50%",
                    marginRight: "0.5rem",
                  }}
                ></div>
              </MTooltip> */}
            </div>
            {isBuying ? <LineProgress /> : null}
            <CardContent>
              <Grow in={true}>
                <Grid
                  container
                  spacing={2}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={12}>
                    <Grid container alignItems="center" justify="center">
                      <Grid item xs={6}>
                        <Grid container alignItems="center" spacing={1}>
                          {watchingIndicator && (
                            <Grid item>
                              <MTooltip
                                title={lang[locale].priceWatching}
                                aria-label="watched"
                              >
                                <PriorityHighIcon color="secondary" />
                              </MTooltip>
                            </Grid>
                          )}
                          <Grid item>
                            <>
                              {customerData["type"] !== "F" ? (
                                <MTooltip
                                  title={
                                    loadcurve.orders !== undefined &&
                                    loadcurve.orders.length > 0 &&
                                    loadcurve.orders.length === loadcurve.tranche
                                      ? `Sie haben alle ${loadcurve.tranche} Tranchen Gekauft, es ist kein weitere Kauf möglich`
                                      : ""
                                  }
                                >
                                  <Chip
                                    onMouseEnter={(e) => handleEnterMouse(e)}
                                    // label={orders && `${orders.length} ${lang[locale].from} ${loadcurve.tranche} x ${lang[locale].boughtPlan}`}
                                    label={
                                      `${loadcurve.orders === undefined ? 0 : loadcurve.orders.length} / ${loadcurve.tranche} ${lang[locale].boughtPlan}`
                                    }
                                    variant={
                                      loadcurve.orders !== undefined &&
                                      loadcurve.orders.length === loadcurve.tranche
                                        ? "default"
                                        : "outlined"
                                    }
                                    onClick={handleShowOrders}
                                    className={classes.boughtChip}
                                  />
                                </MTooltip>
                              ) : loadcurve.tranche && loadcurve.tranche > 0 ? (
                                <Chip
                                  onMouseEnter={(e) => handleEnterMouse(e)}
                                  label={
                                    loadcurve.tranche &&
                                    // loadcurve.orders !== undefined &&
                                    
                                    // `${orders.length} ${lang[locale].from} ${loadcurve.tranche} x ${lang[locale].boughtPlan}`
                                    `${loadcurve.orders === undefined ? 0 : loadcurve.orders.length} / ${loadcurve.tranche} ${lang[locale].boughtPlan}`
                                    // `${lang[locale].boughtPlan}`
                                  }
                                  variant={
                                    loadcurve.orders !== undefined &&
                                    loadcurve.orders.length === loadcurve.tranche
                                      ? "default"
                                      : "outlined"
                                  }
                                  onClick={handleShowOrders}
                                  className={classes.boughtChip}
                                />
                              ) : loadcurve.tranche === undefined &&
                              loadcurve.orders !== undefined &&
                              loadcurve.orders.length > 0 &&
                               (
                                <Chip
                                  onMouseEnter={(e) => handleEnterMouse(e)}
                                  // label={`${orders.length} x ${lang[locale].boughtPlan}`}
                                  label={`${loadcurve.orders === undefined ? 0 : loadcurve.orders.length} ${lang[locale].boughtPlan}`}
                                  variant={
                                    loadcurve.orders &&
                                    loadcurve.orders.length === loadcurve.tranche
                                      ? "default"
                                      : "outlined"
                                  }
                                  onClick={handleShowOrders}
                                  className={classes.boughtChip}
                                />
                              )}
                            </>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={6}>
                        <Grid container justify="flex-end" alignItems="center">
                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <MTooltip
                              title={
                                <>
                                  <span>
                                    <b>
                                      {lang[locale].forecastType}: &nbsp; &nbsp;{" "}
                                      {forecastModel?.forecastFunction}
                                    </b>
                                  </span>
                                  <br />
                                  <span>
                                    <b>
                                      {lang[locale].creationTime}: &nbsp; &nbsp;{" "}
                                      {forecastModel?.creationTime
                                        .toLocaleString()
                                        .split("T")[0]
                                        .split("-")
                                        .reverse()
                                        .join(".")}
                                      &nbsp;
                                      {
                                        forecastModel?.creationTime
                                          .toLocaleString()
                                          .split("T")[1]
                                          .split("+")[0]
                                      }
                                    </b>
                                  </span>
                                  <br />
                                  <span>
                                    <b>
                                      {lang[locale].priceCurveName}: &nbsp;
                                      &nbsp; {forecastModel?.priceCurveName}
                                    </b>
                                  </span>
                                  <br />
                                  {forecastModel?.holidayRegion && (
                                    <>
                                      <span>
                                        <b>
                                          {lang[locale].holidayRegion} &nbsp;
                                          &nbsp; {forecastModel.holidayRegion}
                                        </b>
                                      </span>
                                      <br />
                                    </>
                                  )}
                                  {(forecastModel?.forecastType === 3 ||
                                    forecastModel?.forecastType === 4) && (
                                    <>
                                      <span>
                                        <b>
                                          {lang[locale].profileType}: &nbsp;
                                          &nbsp; {forecastModel?.profileType}
                                        </b>
                                      </span>
                                      <br />
                                    </>
                                  )}
                                  {formelFromJson && Object.keys(formelFromJson).length > 0 && (
                                  <>
                                    <span>
                                    {" "}
                                    <b>{lang[locale].formula}: &nbsp;&nbsp; {gesamtFormel.length < 20
                                    ? `${gesamtFormel.join("")}${constant}`
                                    : `${gesamtFormel.join("").slice(0, 17)}...${constant}`}</b>
                                    </span>
                                    <br />
                                   </>
                                  )}
                                  {forecastModel &&
                                  Object.keys(forecastModel?.usageType) &&
                                  forecastModel?.usageType === "spezifisch" ? (
                                    <>
                                      <span>
                                        <b>
                                          {lang[locale].usageType}:{" "}
                                          {lang[locale].specific}
                                        </b>
                                      </span>
                                      <br />
                                    </>
                                  ) : forecastModel?.usageType ===
                                    "gemessen" ? (
                                    <>
                                      <span>
                                        <b>
                                          {lang[locale].usageType}:{" "}
                                          {lang[locale].measured}
                                        </b>
                                      </span>
                                      <br />
                                    </>
                                  ) : null}

                                  {forecastModel?.usage && (
                                    <>
                                      {Object.keys(forecastModel.usageType) &&
                                      forecastModel.usageType ===
                                        "spezifisch" ? (
                                        <>
                                          <span>
                                            <b>
                                              {lang[locale].dailyusage}:&nbsp;
                                              {/* {lang[locale].usage} */}
                                              &nbsp;&nbsp;
                                              <NumberFormat
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                fixedDecimalScale={true}
                                                decimalScale={3}
                                                suffix=" MWh / Tag"
                                                value={forecastModel.usage}
                                                displayType={"text"}
                                              />
                                            </b>
                                          </span>
                                          <br />
                                        </>
                                      ) : forecastModel.usageType ===
                                        "gemessen" ? (
                                        <>
                                          <span>
                                            <b>
                                              {lang[locale].measured}&nbsp;
                                              {lang[locale].usage}:&nbsp;&nbsp;
                                              <NumberFormat
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                fixedDecimalScale={true}
                                                decimalScale={3}
                                                suffix=" MWh"
                                                value={forecastModel.usage}
                                                displayType={"text"}
                                              />
                                            </b>
                                          </span>
                                          <br />
                                        </>
                                      ) : null}
                                    </>
                                  )}
                                  {forecastModel?.measureFrom && (
                                    <>
                                      <span>
                                        <b>
                                          {lang[locale].measuredPeriod}: &nbsp;
                                          &nbsp; {forecastModel.measureFrom}-
                                          {forecastModel.measureTo}
                                        </b>{" "}
                                      </span>
                                      <br />
                                    </>
                                  )}
                                  {forecastModel?.variant && (
                                    <>
                                      <span>
                                        <b>
                                          {lang[locale].variant}: &nbsp; &nbsp;{" "}
                                          {forecastModel.variant}{" "}
                                          {
                                            variants[
                                              parseFloat(
                                                forecastModel.variant
                                              ) - 1
                                            ]
                                          }
                                        </b>
                                      </span>
                                      <br />
                                    </>
                                  )}
                                  {forecastModel?.productType && (
                                    <>
                                      <span>
                                        <b>
                                          {lang[locale].productType}: &nbsp;
                                          &nbsp; {forecastModel?.productType}
                                        </b>
                                      </span>
                                      <br />
                                    </>
                                  )}
                                  {forecastModel?.productType && (
                                    <>
                                      <span>
                                        <b>
                                          {lang[locale].performance}: &nbsp;
                                          &nbsp;
                                          <NumberFormat
                                          thousandSeparator="."
                                          decimalSeparator=","
                                          fixedDecimalScale={true}
                                          decimalScale={0}
                                          suffix=" kW"
                                          value={forecastModel?.performance}
                                          displayType={"text"}
                                          />
                                        </b>
                                      </span>
                                      <br />
                                    </>
                                  )}
                                  {forecastModel?.temperatureStation && (
                                    <>
                                      <span>
                                        <b>
                                          {lang[locale].temperatureStation}:
                                          &nbsp; &nbsp;{" "}
                                          {forecastModel.temperatureStation}
                                        </b>{" "}
                                      </span>
                                      <br />
                                    </>
                                  )}
                                  {
                                    forecastModel?.creator && (
                                      <>
                                       <span>
                                        <b>
                                          {lang[locale].creator}: &nbsp; &nbsp;{" "}
                                          {forecastModel?.creator}
                                        </b>
                                       </span>
                                       <br />
                                      </>
                                    )
                                  }
                                  {IstDataName?.zr_name && (
                                  <>
                                  <span>
                                    <b>
                                      {lang[locale].actualData}: &nbsp; &nbsp;
                                      {IstDataName.zr_name.split("_")[0]}
                                    </b>
                                  </span>
                                  <br />
                                  </>  
                                  )}
                                  {loadcurve.max_order_tranche && (
                                    <>
                                      <span>
                                        <b>
                                          {lang[locale].maxmoreTranchenOrder}:
                                          &nbsp; &nbsp;{" "}
                                          {loadcurve.max_order_tranche}
                                        </b>
                                      </span>
                                      <br />
                                    </>
                                  )}
                                  <span
                                    style={{
                                      color:
                                        loadcurve && loadcurve.accepted
                                          ? "#7BFC02"
                                          : //@ts-ignore
                                          loadcurve.accept_to && differenceInDays(new Date(Date.now()), parseISO(loadcurve.accept_to) ) <= 0
                                          ? "orange"
                                          : //@ts-ignore
                                            "#F34336",
                                    }}
                                  >
                                    <b>
                                      {loadcurve && loadcurve.accepted && loadcurve.accepted_at
                                      
                                        ? lang[locale].OfferWasAccepted + loadcurve.accepted_at.toString()
                                        .split("T")[0]
                                        .split("-")
                                        .reverse()
                                        .join(".")
                                        : //@ts-ignore
                                        loadcurve.accept_to && differenceInDays( new Date(Date.now()),parseISO(loadcurve.accept_to)) <= 0
                                        ? lang[locale].OfferOpenUntil +
                                          loadcurve.accept_to
                                            .toString()
                                            .split("T")[0]
                                            .split("-")
                                            .reverse()
                                            .join(".")
                                        : lang[locale].OfferExpiredOn +
                                          loadcurve.accept_to
                                            ?.toString()
                                            .split("T")[0]
                                            .split("-")
                                            .reverse()
                                            .join(".")}{" "}
                                    </b>
                                  </span>
                                  <br />
                                </>
                              }
                            >
                              <span
                                //@ts-ignore
                                className={ differenceInDays( new Date(Date.now()),parseISO(loadcurve.accept_to)
                                  ) === 0 &&
                                  loadcurve &&
                                  !loadcurve.accepted
                                    ? "blinking-text"
                                    : ""
                                }
                                style={{
                                  marginRight: "1rem",
                                  color:
                                    loadcurve && loadcurve.accepted
                                      ? "#7BFC02"
                                      : //@ts-ignore
                                      loadcurve.accept_to && differenceInDays(new Date(Date.now()),parseISO(loadcurve.accept_to)) <= 0
                                      ? "orange"
                                      : //@ts-ignore
                                        "#F34336",
                                }}
                              >
                                <InfoOutlinedIcon fontSize="small" />
                              </span>
                            </MTooltip>
                            <MTooltip title={lang[locale].deliveryPeriod}>
                              <Typography variant={"caption"}>
                                {lang[locale].Dp}: {from} - {to}
                              </Typography>
                            </MTooltip>
                          </Grid>
                          <Grid item>
                            <IconButton
                              aria-label="settings"
                              onClick={handleClick}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="simple-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={Boolean(anchorEl)}
                              onClose={handleClose}
                            >
                              <MenuItem onClick={handleExportClick}>
                                {lang[locale].export}
                              </MenuItem>
                              {role.permissions?.includes("PFM") && loadcurve.tranche !== undefined ? (
                                <MenuItem onClick={handleAddToPFM}>
                                  {lang[locale].addToPfm}
                                </MenuItem>
                              ) : null}
                              {role.simple_role === "LIEFERANT" && (
                                <MenuItem onClick={offerSurchargeHandler}>
                                  {lang[locale].offerSurcharge}
                                </MenuItem>
                              )}
                              {loadcurve &&
                                // activate price Watching for all users
                                // role.simple_role !== "LIEFERANT" &&
                                role.permissions.includes("MONITORING") && (
                                  <MenuItem onClick={handlerSetPreisBound}>
                                    {lang[locale].priceMonitoring}
                                  </MenuItem>
                                )}
                              {loadcurve &&
                              role.permissions.includes("VIRTUAL_ORDER") &&
                              loadcurve.tranche &&
                              loadcurve.tranche > 0 &&
                              preisType !== "SPOT" ? (
                                <MenuItem
                                  onClick={handlerVirtualPurchase}
                                  disabled={
                                    (loadcurve.orders !== undefined &&
                                      loadcurve.orders.length > 0 &&
                                      loadcurve.orders.length === loadcurve.tranche) ||
                                    recentPrice === undefined
                                  }
                                >
                                  {lang[locale].VirtualPurchase}
                                </MenuItem>
                              ) : null}
                              {loadcurve &&
                              //@ts-ignore
                              ((role.permissions?.includes("TRADING") && (orderType === "MANUAL")) || (role.permissions?.includes("DELAYED_TRADING") && (orderType === "DELAYED_AUTO"))) && differenceInDays(new Date(Date.now()),parseISO(loadcurve.order_to)) <= 0 &&
                              role.simple_role !== "LIEFERANT" &&
                              loadcurve.accepted === true ? (
                                <MenuItem
                                  onClick={handleBuyClick}
                                  disabled={
                                    (loadcurve.orders !== undefined &&
                                      loadcurve.orders.length > 0 &&
                                      loadcurve.orders.length === loadcurve.tranche) ||
                                    recentPrice === undefined
                                    ||
                                    // !isBetween ||
                                    (preisType === "SPOT") || preisType === ""
                                    ||
                                    (rowCalenderString.length > 4 && CalenderArray.length > 0)
                                  }
                                >
                                  { (rowCalenderString.length > 4 && CalenderArray.length > 0) ? "ATB" : lang[locale].buy  }
                                  <MTooltip style={{fontSize : "32px"}}
                                  title={<>
                                    {
                                      handleDays.map(i=>(
                                          //@ts-ignore
                                        <div style={{display : "flex", flexDirection : "row", margin : "auto"}}><span>{i.tag} &nbsp; &nbsp; {i.trading_start_time} - {i.trading_end_time}</span><br /></div>
                                      ))
                                    }
                                  </>}>
                                  <AccessTimeIcon
                                  // onClick={(e)=>{
                                  //   e.stopPropagation();
                                  //   handleClose();
                                  //   dispatch(
                                  //     modalActions.openModal(DelayedBuy, {
                                  //       zrid: loadcurve.zrid,
                                  //       recentPrice: recentPrice,
                                  //       amount: loadcurve.menge,
                                  //       name: loadcurve.zr_name.split("_")[0],
                                  //       sendBuyStatusFromChild: sendBuyStatusFromChild,
                                  //       tranche: loadcurve.tranche,
                                  //       max_Order_Tranche: loadcurve.max_order_tranche,
                                  //       ordersLength: orders?.length,
                                  //       TotalAmount,
                                  //       PortfolioDetails,
                                  //       PortfolioId,
                                  //       UpperEnd,
                                  //       UpperStart,
                                  //       DaysDifference,
                                  //       greenArrayStep,
                                  //     }, 4)
                                  //   );
                                  // }}
                                  fontSize="small" style={{marginLeft : "4rem"}}/>
                                  </MTooltip>

                                </MenuItem>
                              ) : (
                                //@ts-ignore
                                role.permissions?.includes("TRADING") && loadcurve.accept_to && differenceInDays(new Date(Date.now()),parseISO(loadcurve.accept_to)) <= 0 && (
                                role.simple_role !== "LIEFERANT" &&
                                  <MenuItem
                                    onClick={() =>
                                      handleAcceptOffer(loadcurve.zrid)
                                    }
                                    disabled={ 
                                      (orders &&
                                        orders.length > 0 &&
                                        orders?.length === loadcurve.tranche) ||
                                      (preisType !== "SPOT" &&
                                        recentPrice === undefined)
                                    }
                                  >
                                    {lang[locale].acceptTheOffer}
                                  </MenuItem>
                                )
                              )}

                              <MenuItem
                                onClick={handleDelete}
                                disabled={btnDeleteDisable}
                              >
                                <Typography>{lang[locale].delete}</Typography>
                              </MenuItem>
                            </Menu>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={4} md={5}>
                    <Typography color="textSecondary" variant="body2" noWrap>
                      {lang[locale].offresid} {loadcurve.zrid}
                    </Typography>
                    {loadCurveCopy.zr_name.split("_")[0].length > 14 ? (
                      <MTooltip
                        title={
                          loadCurveCopy.zr_name.split("_")[0].length > 14
                            ? loadcurve.zr_name.split("_")[0]
                            : ""
                        }
                        aria-label="Name"
                      >
                        <Typography
                          color="textSecondary"
                          variant="body2"
                          noWrap
                        >
                          {loadCurveCopy.zr_name.split("_")[0].length > 14
                            ? `${loadCurveCopy.zr_name
                                .split("_")[0]
                                .slice(0, 11)}...`
                            : loadCurveCopy.zr_name.split("_")[0]}
                        </Typography>
                      </MTooltip>
                    ) : (
                      <Typography color="textSecondary" variant="body2" noWrap>
                        {`${loadCurveCopy.zr_name.split("_")[0]}`}
                      </Typography>
                    )}
                    <MTooltip
                      title={
                        recentDate &&
                        recentPrice !== 0 &&
                        recentPrice !== undefined
                          ? lang[locale].priceFrom +
                            ": " +
                            recentDate +
                            " " +
                            recentTime
                          : lang[locale].noPriceGiven
                      }
                    >
                      <Typography variant="h5" noWrap>
                        {recentPrice && recentPrice > 0 ? (
                          <>
                            {" "}
                            <NumberFormat
                              thousandSeparator="."
                              decimalSeparator=","
                              fixedDecimalScale={true}
                              decimalScale={2}
                              value={recentPrice}
                              displayType={"text"}
                            />{" "}
                            {trendingIcon}{" "}
                            <span
                              style={{
                                fontSize: 14,
                              }}
                            >
                              €/MWh
                            </span>
                          </>
                        ) : recentPrice === undefined ? (
                          lang[locale].inWork
                        ) : (
                          lang[locale].inWork
                        )}
                      </Typography>
                    </MTooltip>
                    <MTooltip title={lang[locale].ManagementPeriod}>
                      <Typography color={"inherit"} variant="caption">
                        <>
                          {lang[locale].Mp}:{" "}
                          {loadcurve.order_from
                            ?.toString()
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join(".")}{" "}
                          -{" "}
                          {loadcurve.order_to
                            ?.toString()
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join(".")}
                        </>
                      </Typography>
                    </MTooltip>
                  </Grid>

                  {showHistory ? (
                    <>
                      {priceHistory && priceHistory.length > 1 ? (
                        <>
                          <Grid item xs={12} md={7}>
                            <ResponsiveContainer height={90}>
                              <LineChart
                                data={priceHistory}
                                data-testid="areachart"
                              >
                                <YAxis
                                  domain={["auto", "auto"]}
                                  stroke={theme.palette.text.secondary}
                                  interval="preserveStartEnd"
                                />
                                <XAxis
                                  dataKey="name"
                                  stroke={theme.palette.text.secondary}
                                  tickFormatter={
                                    (tick) =>
                                      new Date(tick).toLocaleString(
                                        showLocalLang,
                                        LegendOptions
                                      )
                                    // .split(".")
                                    // .slice(1)
                                    // .join("")
                                  }
                                />
                                <Tooltip
                                  wrapperStyle={{ outline: "none" }}
                                  position={{ x: -50, y: 40 }}
                                  content={
                                    <CustomToolTipPreisHistorie
                                      payload={priceHistory}
                                      active="true"
                                    />
                                  }
                                />
                                {/* <Tooltip contentStyle={{color: "white",backgroundColor:"#303030",width:"50%"}} itemStyle={{ color: "#ebad1c" }}/> */}

                                <Line
                                  name="Preis"
                                  dataKey="price"
                                  stroke={
                                    theme.palette.type === "dark"
                                      ? theme.palette.secondary.main
                                      : theme.palette.primary.main
                                  }
                                  dot={false}
                                  isAnimationActive={false}
                                />
                              </LineChart>
                            </ResponsiveContainer>
                          </Grid>
                        </>
                      ) : null}
                    </>
                  ) : null}
                  {showIntra ? (
                    <>
                      {inraDayPrice ? (
                        <Grid item xs={12} md={7}>
                          <ResponsiveContainer height={90}>
                            <LineChart
                              data={inraDayPrice}
                              data-testid="areachart"
                            >
                              <YAxis
                                domain={["auto", "auto"]}
                                stroke={theme.palette.text.secondary}
                                interval="preserveStartEnd"
                              />
                              <XAxis
                                dataKey="name"
                                stroke={theme.palette.text.secondary}
                                tickFormatter={(tick) =>
                                  tick
                                    .toLocaleString(showLocalLang, intraOptions)
                                    .split(":")
                                    .slice(0, 1)
                                    .join("")
                                }
                              />
                              <Tooltip
                                position={{ x: -50, y: 40 }}
                                wrapperStyle={{ outline: "none" }}
                                content={
                                  <CustomToolTipIntraDayPreis
                                    payload={inraDayPrice}
                                    active="true"
                                  />
                                }
                              />
                              {/* <Tooltip contentStyle={{color: "white",backgroundColor:"#303030",width:"50%"}} itemStyle={{ color: "#ebad1c" }}/> */}

                              <Line
                                name="preis"
                                dataKey="price"
                                stroke={
                                  theme.palette.type === "dark"
                                    ? theme.palette.secondary.main
                                    : theme.palette.primary.main
                                }
                                dot={false}
                              />
                            </LineChart>
                          </ResponsiveContainer>
                        </Grid>
                      ) : (
                        <p style={{ maxWidth: "40%" }}>
                          {lang[locale].noIntraday}
                        </p>
                      )}
                    </>
                  ) : null}
                  {preisType === "SPOT" &&
                    (recentPrice === undefined || recentPrice === 0 || recentPrice === -1) && (
                      <p style={{ maxWidth: "50%", color: "#FAB515" }}>
                        {lang[locale].noSpotPrice} {from}!
                      </p>
                    )}
                </Grid>
              </Grow>
            </CardContent>
            <Grow in={true}>
              <CardActions>
                <Grid container justify="flex-start" alignItems="center">
                  <Grid item style={{ maxWidth: "90%" }}>
                    {recentPrice && recentPrice > 0 ? (
                      <Typography color="textSecondary" variant="body2">
                        {lang[locale].cost}:{" "}
                        <NumberFormat
                          thousandSeparator="."
                          decimalSeparator=","
                          fixedDecimalScale={true}
                          decimalScale={2}
                          suffix=" €"
                          value={loadcurve.menge * recentPrice}
                          displayType={"text"}
                        />
                      </Typography>
                    ) : recentPrice === undefined || recentPrice === 0 ? (
                      <Typography color="textSecondary" variant="body2">
                        {lang[locale].cost}:{" n/a"}
                      </Typography>
                    ) : (
                      lang[locale].inWork
                    )}
                    <Typography color="textSecondary" variant="body2">
                      {lang[locale].amount}:{" "}
                      <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        value={loadcurve.menge}
                        displayType={"text"}
                        decimalScale={3}
                        fixedDecimalScale={true}
                        suffix=" MWh"
                      />{" "}
                    </Typography>

                    {role.simple_role === "LIEFERANT" && (
                      <Typography color="textSecondary" variant="body2">
                        {lang[locale].charge}:{" "}
                        <NumberFormat
                          thousandSeparator="."
                          decimalSeparator=","
                          fixedDecimalScale={true}
                          decimalScale={2}
                          suffix=" €/MWh"
                          value={charge}
                          displayType={"text"}
                        />
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                {historyRight && intraRight ? (
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item>
                      {showIntra && !showHistory && preisType !== "SPOT" ? (
                        <Button
                          // color={"primary"}
                          style={{color : ciSettings.primary}}
                          size="medium"
                          // component={Link}
                          onClick={toggleIntraHistory}
                          // to={
                          //   "/app/myplans/detailed/" + id + "/" + loadcurve.zrid
                          // }
                        >
                          {lang[locale].history}
                        </Button>
                      ) : null}
                      {showHistory && !showIntra && preisType !== "SPOT" ? (
                        <Button
                          // color={"primary"}
                          style={{color : ciSettings.primary}}
                          size="medium"
                          // component={Link}
                          onClick={toggleIntraHistory}
                          // to={
                          //   "/app/myplans/detailed/" + id + "/" + loadcurve.zrid
                          // }
                        >
                          {lang[locale].intraday}
                        </Button>
                      ) : null}
                      {role.permissions?.includes("VIEW_DETAIL") ? (
                        <Button
                          disabled={
                            (priceHistory === undefined ||
                            recentPrice === undefined) && preisType !== "SPOT"
                          }
                          // color={"primary"}
                          style={{color : ciSettings.primary}}

                          size="medium"
                          component={Link}
                          to={
                            "/app/myplans/detailed/" + id + "/" + loadcurve.zrid
                          }
                        >
                          {lang[locale].details}
                        </Button>
                      ) : null}
                    </Grid>
                  </Grid>
                ) : !historyRight && intraRight ? (
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item>
                      {role.permissions?.includes("VIEW_DETAIL") ? (
                        <Button
                          disabled={
                            (priceHistory === undefined ||
                            recentPrice === undefined) && preisType !== "SPOT"
                          }
                          // color={"primary"}
                          style={{color : ciSettings.primary}}

                          size="medium"
                          component={Link}
                          to={
                            "/app/myplans/detailed/" + id + "/" + loadcurve.zrid
                          }
                        >
                          {lang[locale].details}
                        </Button>
                      ) : null}
                    </Grid>
                  </Grid>
                ) : historyRight && !intraRight ? (
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item>
                      {role.permissions?.includes("VIEW_DETAIL") ? (
                        <Button
                          // color={"primary"}
                          style={{color : ciSettings.primary}}
                          size="medium"
                          component={Link}
                          to={
                            "/app/myplans/detailed/" + id + "/" + loadcurve.zrid
                          }
                        >
                          {lang[locale].details}
                        </Button>
                      ) : null}
                    </Grid>
                  </Grid>
                ) : !historyRight && !intraRight ? (
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item>
                      {role.permissions?.includes("VIEW_DETAIL") ? (
                        <Button
                          // color={"primary"}
                          style={{color : ciSettings.primary}}

                          size="medium"
                          component={Link}
                          to={
                            "/app/myplans/detailed/" + id + "/" + loadcurve.zrid
                          }
                        >
                          {lang[locale].details}
                        </Button>
                      ) : null}
                    </Grid>
                  </Grid>
                ) : null}
              </CardActions>
            </Grow>
          </>
        </Card>
      )}
    </VizSensor>
  );
}

export default LoadcurveCardComponent;
