import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { lang } from "lang";
import LoadcurveCardRemainingComponent from "./LoadcurveCardRemaining";

function CustomerPlansPendingComponent({ loadcurvesRemaining }) {
    const locale = localStorage.locale;

    return (
        <>
            <Grid
                container
                justify="center"
                alignItems="center"
                spacing={4}
                style={{
                    padding: "3.5em 1.5em 0em 1.5em",
                }}
            >
                {loadcurvesRemaining && loadcurvesRemaining.length !== 0 ? (
                    <>
                        {loadcurvesRemaining.map((curve, index) => (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                style={{ maxWidth: "45em" }}
                                key={index}
                            >
                                <LoadcurveCardRemainingComponent
                                    curve={curve}
                                    key={index}
                                />
                            </Grid>
                        ))}
                    </>
                ) : (
                    <Box
                        display="flex"
                        alignItems="center"
                        flex-direction="column"
                        justifyContent="center"
                    >
                        <Typography variant="h6" color="textSecondary">
                            {lang[locale].noPendingLoadcurves}
                        </Typography>
                    </Box>
                )}
            </Grid>
        </>
    );
}

export default CustomerPlansPendingComponent;
