import React from "react";
import OrderModel from "../typeDefs/OrderModel";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { lang } from "../lang";
import TableBody from "@material-ui/core/TableBody";
import NumberFormat from "react-number-format";
import TableContainer from "@material-ui/core/TableContainer";
import { Typography, Tooltip as MTooltip } from "@material-ui/core";
import AuthService from "../services/auth/AuthService";

interface OrderTableComponentProps {
    orders: OrderModel[];
    /*  customerName: string; */
}
function OrderTableComponent(props: OrderTableComponentProps) {
    const locale = localStorage.locale;
    const orders = props.orders;
    const role = AuthService.getRole();

    return (
        <>
            {!(orders.length > 0) ? (
                <p>{lang[locale].noOrders}</p>
            ) : (
                <TableContainer component={Paper}>
                    <Table aria-label="showOrders">
                        <TableHead>
                            <TableRow>
                                <TableCell>{lang[locale].dealticket}</TableCell>
                                <TableCell align="left">
                                    {lang[locale].name}
                                </TableCell>
                                {/* <TableCell align="left">
                                    {lang[locale].percentage}
                                </TableCell> */}
                                <TableCell align="left">
                                     {lang[locale].amount}  [MWh]
                                </TableCell>
                                <TableCell align="left">
                                    {lang[locale].buyDate}
                                </TableCell>
                                <TableCell align="left">
                                    {lang[locale].price} [€/MWh]
                                </TableCell>
                                {role.simple_role === "LIEFERANT" && (
                                    <>
                                        <TableCell align="left">
                                            {lang[locale].charge} [€/MWh]
                                        </TableCell>
                                        <TableCell align="left">
                                            {lang[locale].totalPrice} [€/MWh]
                                        </TableCell>
                                    </>
                                )}

                                {(role.simple_role === "LIEFERANT" && (
                                    <>
                                        <TableCell align="left">
                                            {lang[locale].costSupplier} [€]
                                        </TableCell>
                                    </>
                                )) ||
                                    (role.simple_role === "USER" && (
                                        <>
                                            <TableCell align="left">
                                                {lang[locale].cost} [€]
                                            </TableCell>
                                        </>
                                    ))}

                                {role.simple_role === "LIEFERANT" && (
                                    <>
                                        <TableCell align="left">
                                            {lang[locale].margin} [€]
                                        </TableCell>

                                        <TableCell align="left">
                                            {lang[locale].totalCosts} [€]
                                        </TableCell>
                                    </>
                                )}

                                <TableCell align="left">
                                    {lang[locale].buyer}
                                </TableCell>
                                <TableCell align="left">Commodity</TableCell>
                                <TableCell align="left">
                                    {lang[locale].confirmation}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.map((row: OrderModel) => (
                                <TableRow key={row.dealticket}>
                                    <TableCell component="th" scope="row">
                                        {row.dealticket}
                                    </TableCell>
                                    <TableCell align="left">
                                        <MTooltip
                                            title={row.name.split("_")[0]}
                                            aria-label="Name"
                                            arrow
                                            placement="bottom-start"
                                        >
                                            <div style={{ maxWidth: "11rem" }}>
                                                <Typography noWrap>
                                                    {row.name.split("_")[0]}
                                                </Typography>
                                            </div>
                                        </MTooltip>
                                    </TableCell>
                                    {/* <TableCell align="left">
                                        <NumberFormat
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            suffix={"%"}
                                            value={row.percentage * 100}
                                            displayType={"text"}
                                        />
                                    </TableCell> */}
                                    <TableCell align="left">
                                        <NumberFormat
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={3}
                                            fixedDecimalScale={true}
                                            value={row.amount}
                                            displayType={"text"}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        {new Date(row.dat).toLocaleString()}
                                    </TableCell>
                                    <TableCell align="left">
                                        <NumberFormat
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            // suffix={" "}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            value={row.price}
                                            displayType={"text"}
                                        />
                                    </TableCell>
                                    {role.simple_role === "LIEFERANT" && (
                                        <>
                                            <TableCell>
                                                <NumberFormat
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    // suffix={" [€/MWh]"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    value={row.charge}
                                                    displayType={"text"}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <NumberFormat
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    // suffix={" [€/MWh]"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    value={
                                                        row.charge + row.price
                                                    }
                                                    displayType={"text"}
                                                />
                                            </TableCell>
                                        </>
                                    )}

                                    <TableCell align="left">
                                        <NumberFormat
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            // suffix={" [€]"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            value={row.price * row.amount}
                                            displayType={"text"}
                                        />
                                    </TableCell>

                                    {role.simple_role === "LIEFERANT" && (
                                        <>
                                            <TableCell>
                                                <NumberFormat
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    // suffix={" [€]"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    value={
                                                        row.charge * row.amount
                                                    }
                                                    displayType={"text"}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <NumberFormat
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    // suffix={" [€]"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    value={
                                                        (row.charge +
                                                            row.price) *
                                                        row.amount
                                                    }
                                                    displayType={"text"}
                                                />
                                            </TableCell>
                                        </>
                                    )}

                                    <TableCell align="left">
                                        {row.email}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.commodity === 1 ? "Strom" : "Gas"}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.validators &&
                                            row.validators.map(
                                                (val) => " " + val
                                            )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
}
export default OrderTableComponent;
