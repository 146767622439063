import React, { useEffect, useState } from "react";
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import modalActions from "../actions/modal.actions";
import { lang } from "../lang";
import LoadcurveService from "../services/loadcurves/LoadcurveService";
import snackbarActions from "../actions/snackbar.actions";

const service = new LoadcurveService();

/**
 * Is for exporting loadcurves to excel etc
 * @constructor
 */
function ExportComponent(props) {
    let history = useHistory();
    const [type, setType] = useState(0);
    const [disabled, setDisabled] = useState(true);
    const locale = localStorage.locale;
    const dispatch = useDispatch();
    const link = React.useRef<HTMLAnchorElement>(null);

    /**
     * Closes modal
     */
    function closeModal() {
        // console.log("In Close Modal Export Component");
        dispatch(modalActions.closeModal());
    }

    /**
     * Handles export either quarterly or hourly
     */
    async function handleExport() {
        const amount = type === 1 ? 96 : 24;
        try {
            if (link.current) {
                if (link.current.href) {
                    return;
                }
                const result = await service.getLoadcurveCSV(
                    amount,
                    props.zrid
                );

                const blob = await result.blob();

                const href = window.URL.createObjectURL(blob);

                link.current.download = result.headers.get(
                    "content-disposition"
                )
                    ? // @ts-ignore
                      result.headers
                          .get("content-disposition")
                          .split("=")[1]
                          .replaceAll('"', "")
                    : props.zrid + ".csv";

                link.current.href = href;
                link.current.click();

                closeModal();
            }
        } catch (e) {
            if (e instanceof Error) {

                if (e.message === "401") {
                    return history.push(`/logout`);
                }
                console.error("Could not export csv of loadcurve", props.zrid, e);
                //todo change error here
                dispatch(snackbarActions.openSnackbar(e.message, "error"));
            }
        }
    }

    useEffect(() => {
        const disabled = (type === 0 && props.commodity_id === 1) || type === undefined;
        setDisabled(disabled);
    }, [type]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Typography variant="h5">{lang[locale].export}</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControl>
                    <InputLabel id="select-type">
                        {lang[locale].valueAmount}
                    </InputLabel>
                    <Select
                        labelId="select-type"
                        id="select-type-select"
                        value={type}
                        onChange={(e) => {
                            setType(e.target.value as number);
                        }}
                    >
                        {props.commodity_id === 1 && (
                        <MenuItem value={0}>
                            {lang[locale].notSelected}
                        </MenuItem>
                        )}
                        {props.commodity_id === 1 && (
                            <MenuItem value={1}>
                                {lang[locale].quarterHour}
                            </MenuItem>
                        )}
                        {props.commodity_id === 1 ? (
                            <MenuItem value={2}>{lang[locale].fullHour}</MenuItem>
                        ) : (<MenuItem value={0}>{lang[locale].fullHour}</MenuItem>) }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6}>
                        <Grid container justify="flex-start">
                            <Button color="primary" onClick={closeModal}>
                                {lang[locale].close}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justify="flex-end">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleExport}
                                disabled={disabled}
                                component={"a"}
                                ref={link}
                            >
                                {lang[locale].export}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ExportComponent;
