import React, { useEffect, useState } from "react";
import PortfolioService from "./../services/portfolios/PortfolioService";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import snackbarActions from "actions/snackbar.actions";
import {
  Paper,
  Button,
  Grid,
  FormGroup,
  TextField,
  FormControlLabel,
  TableCell,
  IconButton,
  Switch,
  Typography,
  Tooltip,
  Checkbox,
  InputLabel,
} from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import { lang } from "../lang";
import modalActions from "actions/modal.actions";
const locale = localStorage.locale;

const PFMservive = new PortfolioService();

function EditPortfolio(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  const [portfolioEditZrIdspass, setPortfolioEditZrIdspass] = useState<any>([]);
  const [notCurrentOffers, setNotCurrentOffers] = useState<any>([]);
  const [isCurrentOffers, setIsCurrentOffers] = useState<any>([]);

  function removeDuplicatesByProperty(arr, property) {
    const uniqueMap = new Map();
    const uniqueArray = [];
  
    for (const item of arr) {
      const key = property ? item[property] : JSON.stringify(item);
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, item);
        //@ts-ignore
        uniqueArray.push(item);
      }
    }
  
    return uniqueArray;
  }

  const portfoDetailHandler = (id) => {
    if (props.role === "LIEFERANT") {
      PFMservive.getPortfolioDetails(Number(props.customerName), id)
        .then((res) => {
          setPortfolioEditZrIdspass(Object.keys(res["portfolio_header_data"]));
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
      } else {
        //@ts-ignore
        PFMservive.getPortfolioDetails(Number(props.customer), id)
        .then((res) => {
          setPortfolioEditZrIdspass(Object.keys(res["portfolio_header_data"]));
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
  };

  const portfoNonCurrentShower = (id) => {
    if (props.role === "LIEFERANT") {
      PFMservive.getPortfoliosDifference(Number(props.customerName), id)
        .then((res) => {
          setNotCurrentOffers(removeDuplicatesByProperty(res, "zrid"));
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          // dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
      } else {
        //@ts-ignore
        PFMservive.getPortfoliosDifference(Number(props.customer), id)
        .then((res) => {
          setNotCurrentOffers(removeDuplicatesByProperty(res, "zrid"));
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          // dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
  };

  // useEffect(()=>{
  //   portfoDetailHandler(props.selectedToDelete)
  //   portfoNonCurrentShower(props.selectedToDelete)
  // },[])

  useEffect(() => {
    portfoDetailHandler(props.selectedToDelete);
    portfoNonCurrentShower(props.selectedToDelete);
  }, []);

  const removeCheckboxHandler = (id, pfid, customerName) => {
    PFMservive.removeFromPortfolio(customerName, pfid, id)
      .then((res) => {
        props.portfoDetailHandler(pfid);
        portfoDetailHandler(props.selectedToDelete);
        // setPortfolioEditZrIdspass(portfolioEditZrIdspass.filter(obj => obj !== id))
        setTimeout(() => {
          const temp = ([...notCurrentOffers,{zrid : id, status : "added", commodity: props.commodity }])
          setNotCurrentOffers(temp.sort(function(a, b) { 
            return a.zrid - b.zrid;
          }));
        }, 4000);
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
    // portfoNonCurrentShower(props.selectedToDelete);
    // portfoDetailHandler(props.selectedToDelete);
  };

  const addCheckboxHandler = (id, pfid, customerName) => {
    PFMservive.addtoPortfolio(customerName, pfid, id)
      .then((res) => {
        props.portfoDetailHandler(pfid);
        portfoDetailHandler(props.selectedToDelete);
        setNotCurrentOffers(notCurrentOffers.filter(obj => obj.zrid !== id))
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error(e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
      // portfoNonCurrentShower(props.selectedToDelete);
      // portfoDetailHandler(props.selectedToDelete);
    // props.portfoDetailHandler(pfid);
  };

  const closePortfoHandler = () => {
    dispatch(modalActions.closeModal());
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <Grid>
          <h3>Add to:</h3>
          <Grid
            item
            style={{
              marginLeft: "2em",
              display: "flex",
              flexDirection: "column",
              maxHeight: "200px",
              overflowY: notCurrentOffers.length > 3 ? "scroll" : "hidden",
            }}
          >
            {notCurrentOffers &&
              notCurrentOffers.length > 0 &&
              notCurrentOffers
              ?.filter(
                (x) => x.commodity === props.commodity && (x.tranche > 0 || x.status === "added") 
              )
                .map((perm) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={false}
                        size="small"
                        onChange={() => {
                          addCheckboxHandler(
                            Number(perm.zrid),
                            props.selectedToDelete,
                            props.role === "LIEFERANT"
                              ? props.customerName
                              : props.customer
                          );
                        }}
                        name="permission"
                        color="primary"
                      />
                    }
                    label={perm.zrid}
                  />
                ))}
          </Grid>
        </Grid>
        <Grid>
          <h3>Remove From:</h3>
          <Grid
            item
            style={{
              marginLeft: "2em",
              display: "flex",
              flexDirection: "column",
              maxHeight: "200px",
              overflowY:
                portfolioEditZrIdspass && portfolioEditZrIdspass.length > 3
                  ? "scroll"
                  : "hidden",
            }}
          >
            {portfolioEditZrIdspass &&
              portfolioEditZrIdspass.length > 0 &&
              portfolioEditZrIdspass?.map((perm) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked
                      size="small"
                      onChange={() => {
                        removeCheckboxHandler(
                          Number(perm),
                          props.selectedToDelete,
                          props.role === "LIEFERANT"
                            ? props.customerName
                            : props.customer
                        );
                      }}
                      name="permission"
                      color="primary"
                    />
                  }
                  label={perm}
                />
              ))}
          </Grid>
        </Grid>
      </div>
      <br></br>
      <Button
        variant="outlined"
        color="primary"
        type="submit"
        onClick={closePortfoHandler}
      >
        {lang[locale].close}
      </Button>
    </>
  );
}

export default EditPortfolio;
