import { authConstants } from "constants/auth.constants";
import { lang } from "lang";
import AuthService from "services/auth/AuthService";
import ClaimsModel from "typeDefs/ClaimsModel";
import ResponseObject from "../typeDefs/ResponseObject";
import snackbarActions from "./snackbar.actions";

/**
 * Export variable, which gets used over the project
 */
const authActions = {
    login,
    checkLogin,
    logout,
    validate,
    activate2FA,
    internalValidate,
    leavePage,
};
const authService: AuthService = new AuthService();
const locale = localStorage.locale;
/* const role = AuthService.getRole();
 */
function saveInLocalstorage(user: ResponseObject) {
    if (!localStorage.locale) localStorage.locale = "de";
    localStorage.company = user.claims.company;
    localStorage.uid = user.claims.uid;
    localStorage.firstName = user.claims.firstname;
    localStorage.lastName = user.claims.lastname;
    localStorage.exp = user.claims.exp;
    localStorage.role = JSON.stringify(user.Role);
}

/**
 * Action which triggeres the login service to authenticate the user
 */
function login(user: ClaimsModel) {
    return (dispatch) => {
        dispatch(request());
        return authService
            .loginUserByEmailAndPassword(user.email, user.password)
            .then((userData: ResponseObject) => {
                //setting the localstorage for displaying needs
                const status = userData.claims.status;
                const active = userData.claims.validated;
                const exp = userData.claims.exp;
                saveInLocalstorage(userData);
                
                dispatch(success(status, active, exp));
            })
            .catch((e) => {
                dispatch(failure(e.message));
            });
    };

    function request() {
        return { type: authConstants.LOGIN_REQUEST };
    }

    function success(active, validated, exp) {
        
        return {
            type: authConstants.LOGIN_SUCCESS,
            active,
            validated,
            exp,
        };
    }

    function failure(error: Error) {
        
        return { type: authConstants.LOGIN_FAILURE, error };
    }
}

/**
 * Checks if it can refresh token, if not, not loggedin
 */
function checkLogin() {
  
    return (dispatch) => {
        dispatch(request());
        //check if an id is stored

        return authService
            .refreshCookie()
            .then((json: ResponseObject) => {
                
                saveInLocalstorage(json);
                const status = JSON.stringify(json.claims.status);
                const val = JSON.stringify(json.claims.validated);
                const exp = (json.claims.exp !== undefined)? json.claims.exp : 0;
                dispatch(success(status, val, exp));
            })
            .catch((e) => {
                console.debug("Refreshing with errors", e);
                dispatch(failure(new Error(e.message)));
            });
    };

    function request() {
        return { type: authConstants.LOGIN_REQUEST };
    }

    function success(status: string, val: string, exp: number) {
        const active = status === "true" ? true : false;
        const validated = val === "true" ? true : false;

        return {
            type: authConstants.LOGIN_SUCCESS,
            active,
            validated,
            exp,
        };
    }

    function failure(error) {
        return { type: authConstants.NOT_LOGGEDIN, error };
    }
}

function logout() {
    return (dispatch) => {
        authService
            .logout()
            .then(() => {
                
                dispatch(success());
                for (let i in localStorage) {
                    if (
                        i !== "defaultHiddenColumnNamesSupplier" &&
                        i !== "defaultOrderSupplier" &&
                        i !== "defaultHiddenColumnNamesCustomer" &&
                        i !== "defaultOrderCustomer"
                    ) {
                        localStorage.removeItem(i);
                    }
                }
            })
            .catch((e) => {
                
                console.error(e);
                dispatch(snackbarActions.openSnackbar(e.message, "error"));
            });
    };

    function success() {
        return {
            type: authConstants.LOGOUT,
        };
    }
}

//Error Message einbinden
function validate(pin) {
    return (dispatch) => {
        authService
            .validateTwoFA(pin)
            .then((res) => {
                const active = res.claims.status;
                const validated = res.claims.validated;
                dispatch({
                    type: authConstants.TFA_VALIDATE,
                    active,
                    validated,
                });
            })
            .catch((e) => {
                // console.log("Error", e);
                dispatch(snackbarActions.openSnackbar(e.message, "error"));
            });
    };
}


function internalValidate(pin) {
    return (dispatch) => {
        authService
            .validateTwoFA(pin)
            .then((res) => {
                // const active = res.claims.status;
                const validated = res.claims.validated;
                dispatch({
                    type: authConstants.TFA_INTERNALVALIDATION,
                    validated,
                });
            })
            .catch((e) => {
                // console.log("Error", e);
                dispatch(snackbarActions.openSnackbar(e.message, "error"));
            });
    };
}

function leavePage(pin) {
    return (dispatch) => {
                dispatch({
                    type: authConstants.TFA_LEAVEPAGE,
                    pin
                });
            }
}

//Error Message einbinden
function activate2FA(active, pin) {
    return (dispatch) => {
        authService
            .activateTwoFA(active, pin)
            .then((res) => {
                active = res === "true" ? true : false;
                dispatch({
                    type: authConstants.TFA_ACTIVATE,
                    active,
                });
                
                dispatch(
                    snackbarActions.openSnackbar(
                        active
                            ? lang[locale].tfaActivated
                            : lang[locale].tfaDeactivated,
                        "success"
                    )
                );
            })
            .catch((e) => {
                // console.log("Error", e);
                dispatch(snackbarActions.openSnackbar(e.message, "error"));
            });
    };
}

export default authActions;
