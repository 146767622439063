import React, { useEffect } from "react";
import { Route, Redirect } from "react-router";
import { useSelector } from "services/helpers/useSelector";
import { useDispatch } from "react-redux";
import authActions from "actions/auth.actions";
import { Grid, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    grid: {
        backgroundColor: theme.palette.background.default,
        height: "100vh",
    },
}));

/**
 * Component used for authenticating users
 * @param param0 Props and children
 */
function PrivateRouteComponent({ children, ...rest }) {
    const dispatch = useDispatch();
    const loggedIn = useSelector((state) => state.auth.loggedIn);
    //const loggedIn = undefined;
    const classes = useStyles();
    useEffect(() => {
        //checks login once on component mount
        dispatch(authActions.checkLogin());
    }, [dispatch]);
    return (
        <Route {...rest}>
            <>
                {loggedIn === undefined ? (
                    <Grid
                        container
                        justify="center"
                        className={classes.grid}
                        data-testid="Loading"
                        alignItems="center"
                    >
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                ) : (
                    <>{loggedIn ? children : <Redirect to="/auth/login" />}</>
                )}
            </>
        </Route>
    );
}

export default PrivateRouteComponent;
