import { ciSettingsConstants } from "constants/ciSettings.constants";
import AuthService from "services/auth/AuthService";

function update(primary: string, secondary: string, gradient: any, logo: any) {
    return { type: ciSettingsConstants.UPDATE_CI, primary, secondary, gradient, logo };
}

function get() {
    return { type: ciSettingsConstants.GET_CI };
}

const ciSettingsActions = {
    get,
    update,
};

export default ciSettingsActions;