import React, { useEffect, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { Tooltip as MTooltip } from "@material-ui/core";
import { Button, Typography } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsBackupRestoreTwoToneIcon from "@mui/icons-material/SettingsBackupRestoreTwoTone";
import AddIcon from "@mui/icons-material/Add";
import { lang } from "../lang";
import UserService from "../services/users/UserService";
import modalActions from "../actions/modal.actions";
import snackbarActions from "actions/snackbar.actions";
import { useDispatch } from "react-redux";
import DeleteBuyTimeDayComponent from "./DeleteBuyTimeDay";
import AuthService from "../services/auth/AuthService";
import { ChromePicker } from "react-color";

function ColorSettingComponent(props) {
  const service = new UserService();
  const locale = localStorage.locale;
  const [primaryCircle, setPrimaryCircle] = useState(false);
  const [secondaryCircle, setSecondaryCircle] = useState(false);
  const [gradientCircle, setGradientCircle] = useState(false);
  const [fileContent, setFileContent] = useState(null);
  const [fileContentBadge, setFileContentBadge] = useState(null);
  const [currentGradientColor, setCurrentGradientColor] = useState("");
  const [defaultPrimary, setDefaultPrimary] = useState("");
  const [defaultSecondary, setDefaultsecondary] = useState("");
  const [defaultGradient, setDefaultGradient] = useState([]);
  const [completeString, setCompleteString] = useState({
    color: {
      primary_color: "",
      secondary_color: "",
      gradient_color: "",
    },
    picture: "",
  });
  const [coloR, setColoR] = useState({
    primary: "#0983B1",
    secondary: "#fab516",
    gradient: [],
  }); // Initial color, you can set it to any color you prefer

  const colorPickerRef = useRef(null);
  const handleInputChange = (event, nameee) => {
    const name = nameee;
    const value = event.target ? event.target : event.hex;

    if (name !== "gradient") {
      if (name === "primary") {
        setDefaultPrimary("");
      }
      if (name === "secondary") {
        setDefaultsecondary("");
      }
      // Create a new object with the updated property
      const updatedObject = {
        ...coloR,
        [name]: value,
      };
      setColoR(updatedObject);
    } else if (name === "gradient") {
      setDefaultGradient([]);
      // Create a new object with the updated property
      const updatedObject = {
        ...coloR,
        [name]: coloR.gradient.concat(event),
      };
      // console.log("color.gradient.push(event.hex)",color.gradient.push(event.hex))

      setColoR(updatedObject);
    }
    // Update the state with the new object
  };

  const handleSupplierCi = () => {
    service.updateSupplierCi(completeString).then((res) => console.log(res));
    service.getSupplierInfo().then((res) => {
      setColoR({
        primary:
          res.Supplier.color.primary_color !== 0
            ? res.Supplier.color.primary_color
            : "#0983B1",
        secondary:
          res.Supplier.color.secondary_color !== 0
            ? res.Supplier.color.secondary_color
            : "#fab516",
        // gradient: res.Supplier.color.gradient_color
        gradient: res.Supplier.color.gradient_color,
      });
      //@ts-ignore
      setFileContentBadge(" data:image/png;base64," + res.Supplier.picture);
    });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleColorChange = (newColor) => {
    setColoR(newColor.hex);
  };

  const handelcolorPrimaryClick = () => {
    setPrimaryCircle(!primaryCircle);
  };
  const handelcolorsecondaryClick = () => {
    setSecondaryCircle(!secondaryCircle);
  };
  const handelcolorGradientClick = () => {
    setGradientCircle(!gradientCircle);
  };
  const handelGradientcolorClick = () => {
    return (
      <ChromePicker color={coloR.secondary} onChange={handleColorChange} />
    );
  };

  const removehandLer = () => {
    const updatedObject = {
      ...coloR,
      gradient: [],
    };
    // console.log("color.gradient.push(event.hex)",color.gradient.push(event.hex))

    setColoR(updatedObject);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // console.log("eeee", e)
        //@ts-ignore
        const content = e.target.result;
        //@ts-ignore
        setFileContent(content.split(",")[1]);
        //@ts-ignore
        setFileContentBadge(content);
      };

      reader.readAsDataURL(file);
    }
  };

  const defaultHandler = (e, typ, DefValue) => {
    if (typ === "primary") {
      setDefaultPrimary(DefValue);
      setColoR({ ...coloR, primary: DefValue });
    }
    if (typ === "secondary") {
      setDefaultsecondary(DefValue);
      setColoR({ ...coloR, secondary: DefValue });
    } else if (typ === "gradient") {
      //@ts-ignore
      setDefaultGradient([DefValue]);
      //@ts-ignore
      setColoR({ ...coloR, gradient: [DefValue] });
    }
  };

  useEffect(() => {
    service.getSupplierInfo().then((res) => {
      setColoR({
        primary:
          res.Supplier.color.primary_color !== 0
            ? res.Supplier.color.primary_color
            : "#0983B1",
        secondary:
          res.Supplier.color.secondary_color !== 0
            ? res.Supplier.color.secondary_color
            : "#F34336",

        gradient:
          res.Supplier.color.gradient_color
            .split("linear-gradient(to right,")[1]
            .split(")")[0]
            .split(",")[0].length > 1
            ? res.Supplier.color.gradient_color
                .split("linear-gradient(to right,")[1]
                .split(")")[0]
                .split(",")
            : "#0983B1",
        //@ts-ignore
        // gradient: ["#0583B1"],
      });
      //@ts-ignore
      setFileContentBadge(" data:image/png;base64," + res.Supplier.picture);
    });
    //@ts-ignore
    // .then(res=>console.log(res.Supplier.picture))
  }, []);

  useEffect(() => {
    setCompleteString({
      //@ts-ignore
      color: {
        primary_color: defaultPrimary !== "" ? defaultPrimary : coloR.primary,
        secondary_color:
          defaultSecondary !== "" ? defaultSecondary : coloR.secondary,
        //@ts-ignore
        gradient_color:
          coloR.gradient.length === 1
            ? `linear-gradient(to right, ${coloR.gradient[0]})`
            : `linear-gradient(to right, ${coloR.gradient})`,
      },
      //@ts-ignore
      picture: fileContentBadge && fileContentBadge.split(",")[1],
    });
  }, [coloR, fileContent]);

  return (
    <>
      <Stack component="form" noValidate spacing={3}>
        <div style={{display: "flex", flexDirection : "row"}}>
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <TextField
                  name="primary"
                  inputProps={{
                    // step: 3600, // 5 min
                    style: { color: "white" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: "white" },
                  }}
                  label={lang[locale].Primary}
                  value={defaultPrimary !== "" ? defaultPrimary : coloR.primary}
                  onChange={(e) => e.preventDefault()}
                />
                <div
                  style={{
                    borderRadius: 50,
                    backgroundColor:
                      defaultPrimary !== "" ? defaultPrimary : coloR.primary,
                    width: "30px",
                    height: "30px",
                    margin: "0 2rem",
                  }}
                  onClick={handelcolorPrimaryClick}
                ></div>
                {primaryCircle ? (
                  <>
                    <ChromePicker
                      color={
                        defaultPrimary !== "" ? defaultPrimary : coloR.primary
                      }
                      onChange={(e) => handleInputChange(e, "primary")}
                    />
                    <MTooltip title={"Default Primary"}>
                      <Button
                        onClick={(e) => defaultHandler(e, "primary", "#0983B1")}
                      >
                        <SettingsBackupRestoreTwoToneIcon />
                      </Button>
                    </MTooltip>
                  </>
                ) : null}
              </div>
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: "2rem"
                }}
              >
                <TextField
                  name="secondary"
                  inputProps={{
                    // step: 3600, // 5 min
                    style: { color: "white" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: "white" },
                  }}
                  label={lang[locale].Secondary}
                  value={
                    defaultSecondary !== "" ? defaultSecondary : coloR.secondary
                  }
                  onChange={(e) => e.preventDefault()}
                ></TextField>
                <div
                  style={{
                    borderRadius: 50,
                    backgroundColor:
                      defaultSecondary !== ""
                        ? defaultSecondary
                        : coloR.secondary,
                    width: "30px",
                    height: "30px",
                    margin: "0 2rem",
                  }}
                  onClick={handelcolorsecondaryClick}
                ></div>
                {secondaryCircle ? (
                  <>
                    <ChromePicker
                      color={
                        defaultSecondary !== ""
                          ? defaultSecondary
                          : coloR.secondary
                      }
                      onChange={(e) => handleInputChange(e, "secondary")}
                    />
                    <MTooltip title={"Default Secondary"}>
                      <Button
                        onClick={(e) =>
                          defaultHandler(e, "secondary", "#fab516")
                        }
                      >
                        <SettingsBackupRestoreTwoToneIcon />
                      </Button>
                    </MTooltip>
                  </>
                ) : null}
              </div>
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: "2rem",
                }}
              >
                <TextField
                  inputProps={{
                    // step: 3600, // 5 min
                    style: { color: "white" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: "white" },
                  }}
                  value={
                    defaultGradient.length > 0
                      ? defaultGradient
                      : coloR.gradient
                  }
                  label={lang[locale].gradient}
                ></TextField>
                <div
                  style={{
                    borderRadius: 50,
                    background:
                      // defaultGradient.length !== 0 ?
                      //  coloR.gradient[0],
                      // : defaultGradient[0],
                      coloR.gradient.length === 1
                        ? coloR.gradient[0]
                        : coloR.gradient.length > 1
                        ? `linear-gradient(to right,${coloR.gradient})`
                        : "#0983B1",
                    width: "100px",
                    height: "30px",
                    margin: "0 2rem",
                  }}
                  onClick={handelcolorGradientClick}
                  onChange={(e) => e.preventDefault()}
                ></div>
                {gradientCircle ? (
                  <>
                    <ChromePicker
                      //@ts-ignore
                      color={currentGradientColor}
                      // onChange={(e) => handleInputChange(e, "gradient")}
                      onChange={(e) => setCurrentGradientColor(e.hex)}
                    />
                    <MTooltip title={"Default Gradient"}>
                      <Button
                        onClick={(e) =>
                          defaultHandler(e, "gradient", "#0983B1")
                        }
                      >
                        <SettingsBackupRestoreTwoToneIcon />
                      </Button>
                    </MTooltip>
                    <IconButton
                      onClick={() =>
                        handleInputChange(currentGradientColor, "gradient")
                      }
                    >
                      <AddIcon style={{ color: "white" }} />
                    </IconButton>
                    <IconButton onClick={removehandLer}>
                      <DeleteIcon style={{ color: "white" }} />
                    </IconButton>
                  </>
                ) : null}
              </div>
            </div>
            <div style={{ marginTop: "2rem" }}>
              <Typography>Logo: </Typography>
              <input type="file" onChange={handleFileChange} accept=".png" />
              <span style={{ fontSize: "0.8rem" }}>
              &nbsp;&nbsp;{lang[locale].MaxFileSize}&nbsp;2 MB
              </span>
              <div style={{ marginTop: "1rem" }}>
                {fileContentBadge && (
                  <div>
                    {/* <h5>Max File Size:</h5> */}
                    {/* <pre>{fileContent}</pre> */}
                    <img
                      //@ts-ignore
                      src={fileContentBadge}
                      alt="Selected"
                      style={{ maxWidth: "20%", height: "20%" }}
                    />
                  </div>
                )}
              </div>
              <Button
                onClick={handleSupplierCi}
                variant="contained"
                // color="primary"
                style={{
                  width: 150,
                  marginTop: "3rem",
                  backgroundColor: coloR.primary,
                  color: "white",
                }}
                // disabled={Times[0].trading_start_time === "" || Times[0].trading_end_time === "" || Times[1].trading_start_time === "" || Times[1].trading_end_time === "" || Times[2].trading_start_time === "" || Times[2].trading_end_time === "" || Times[3].trading_start_time === "" || Times[3].trading_end_time === "" || Times[4].trading_start_time === "" || Times[4].trading_end_time === ""}
              >
                {lang[locale].save}
              </Button>
            </div>
          </div>
        <div onClick={()=>{
          setPrimaryCircle(false);
          setSecondaryCircle(false);
          setGradientCircle(false);
        }} style={{width : "50%" }}></div>
        </div>
      </Stack>
    </>
  );
}

export default ColorSettingComponent;
