import React, { useCallback, useEffect, useState } from "react";
import { Grid, Paper, Typography, useTheme } from "@material-ui/core";
import {
  Area,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Brush,
  ComposedChart,
  Cell,
  Scatter,
  Dot,
} from "recharts";
import { lang } from "../lang";
import NumberFormat from "react-number-format";
import OrderModel from "../typeDefs/OrderModel";
import { useDispatch } from "react-redux";
import pricecurveshowerConstants from "../constants/pricecurveshower.constants";
import LoadcurvePriceHistory from "../typeDefs/LoadcurvePriceHistory";
import AuthService from "../services/auth/AuthService";
import _ from "lodash";
import { isNumber } from "@material-ui/x-grid";
import moment from "moment";
interface PayloadData {
  dataKey: string;
  name: string;
  payload: object;
  value: number;
}

export function ReferenceLabel(props) {
  const { fill, value, textAnchor, fontSize, viewBox, dy, dx } = props;
  const x = viewBox.width + viewBox.x + 20;
  const y = viewBox.y - 6;
  return (
    <text
      x={x}
      y={y}
      dy={dy}
      dx={dx}
      fill={fill}
      fontSize={fontSize || 10}
      textAnchor={textAnchor}
    >
      {value}
    </text>
  );
}

export function CustomToolTipDetailed({
  active,
  payload,
  label,
  setLocalPriceCurveData,
}) {
  const locale = localStorage.locale;
  const issLieferant = AuthService.getRole().simple_role === "LIEFERANT";

  let realPayload: PayloadData | undefined = undefined;
  let dateKey = "";
  if (payload[0]) {
    realPayload = payload[0];

    let keys = Object.keys(payload[0].payload);
    //filter for the right date key
    dateKey = keys.filter((key) => key !== realPayload?.dataKey)[0];
  }
  if (payload[0] && payload[0].payload && setLocalPriceCurveData !== undefined)
    setLocalPriceCurveData(payload[0].payload);
  return (
    <>
      {realPayload && realPayload.value[0] > 0 && (
        <Paper style={{ padding: "20px 10px" }}>
          <Typography>
            {lang[locale].date}:{" "}
            {payload[0]?.payload.erstellungsdatum
              ? new Date(payload[0]?.payload.erstellungsdatum).toLocaleString(
                  "de-DE",
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  }
                )
              : new Date(realPayload.payload[dateKey]).toLocaleString(
                  "de-DE",
                  options
                )}
          </Typography>
          <Typography>
            {issLieferant ? lang[locale].costSupplier : realPayload.name}:{" "}
            <NumberFormat
              thousandSeparator="."
              decimalSeparator=","
              fixedDecimalScale={true}
              decimalScale={2}
              suffix=" €/MWh"
              value={realPayload.value[0]}
              displayType={"text"}
            />
          </Typography>
          {issLieferant && (
            <Typography>
              {lang[locale].totalPrice}:{" "}
              <NumberFormat
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale={true}
                decimalScale={2}
                suffix=" €/MWh"
                value={realPayload.value[1]}
                displayType={"text"}
              />
            </Typography>
          )}
          {issLieferant && (
            <Typography>
              {lang[locale].charge}:{" "}
              <NumberFormat
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale={true}
                decimalScale={2}
                suffix=" €/MWh"
                value={realPayload.value[1] - realPayload.value[0]}
                displayType={"text"}
              />
            </Typography>
          )}
          {/* {realPayload.payload["kennung"] && (
            <Typography>
              {lang[locale].priceCurveID}: {realPayload.payload["kennung"]}
            </Typography>
          )} */}
        </Paper>
      )}
    </>
  );
}

const options: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  // day: "numeric",
};

function PriceHistoryGraph({
  height,
  width,
  referenceBottom,
  referenceTop,
  orders,
  referenceActive,
  indicatores,
  fullHistories,
  upperLimit,
  lowerLimit,
  setDataToExport,
  dataToExport,
}) {
  const localized = localStorage.locale;
  const showLocalLang = lang[localized].add === "Add" ? "en-EN" : "de-DE";
  const theme = useTheme();
  const locale = localStorage.locale;
  const dispatch = useDispatch();
  const [lastData, setLastData] = useState<LoadcurvePriceHistory | undefined>(
    undefined
  );

  const [SMA20, setSma20] = useState(false);
  const [SMA90, setSma90] = useState(false);
  const [SMA200, setSma200] = useState(false);
  const [EMA20, setEma20] = useState(false);
  const [EMA90, setEma90] = useState(false);
  const [EMA200, setEma200] = useState(false);
  const [PREIS, setPREIS] = useState(true);
  const [PRICE, setPRICE] = useState(false);
  const [kp, setKp] = useState(false);
  const [VAR, setVAR] = useState(false);
  const [WILLIAMS, setwilliams] = useState(true);
  const [MACD, setMacd] = useState(true);
  const [MOMENTUM, setMomentum] = useState(true);
  const [BOLLINGERBANDUPP, setBollingerBandUpp] = useState(false);
  const [BOLLINGERBANDLOW, setBollingerBandLow] = useState(false);
  const [isLieferant, setIsLieferant] = useState(false);
  // const [tooltipActive, setTooltipActive] = useState(true);
  const [xAxisMonths, setXaxisMonths] = useState([]);

  const fullCopy = [...fullHistories];

  // const formatDate = (date) => {
  //   return moment(date, "YYYY-MM-DD HH:mm:ss").format("MMMM");
  // };

  const ema9 =
    indicatores[0].Data?.map((i) => {
      return { time: i.time, ema9: i.value };
    }) || [];
  const ema20 =
    indicatores[1].Data?.map((i) => {
      return { time: i.time, ema20: i.value };
    }) || [];
  const ema90 =
    indicatores[2].Data?.map((i) => {
      return { time: i.time, ema90: i.value };
    }) || [];
  const ema200 =
    indicatores[3].Data?.map((i) => {
      return { time: i.time, ema200: i.value };
    }) || [];
  const sma20 =
    indicatores[4].Data?.map((i) => {
      return { time: i.time, sma20: i.value };
    }) || [];
  const sma90 =
    indicatores[5].Data?.map((i) => {
      return { time: i.time, sma90: i.value };
    }) || [];
  const sma200 =
    indicatores[6].Data?.map((i) => {
      return { time: i.time, sma200: i.value };
    }) || [];
  const williams =
    indicatores[7].Data?.map((i) => {
      return {
        time: i.time,
        williams: i.value,
        wiliminus20: -20,
        wiliminus80: -80,
      };
    }) || [];
  const macd =
    indicatores[8].Data?.map((i) => {
      return { time: i.time, macd: i.value };
    }) || [];
  const momentum =
    indicatores[9].Data?.map((i) => {
      return { time: i.time, momentum: i.value };
    }) || [];
  const bollingerbandLow =
    indicatores[10].Data?.map((i) => {
      return { time: i.time, bollingerbandLow: i.value };
    }) || [];
  const bollingerbandUpp =
    indicatores[11].Data?.map((i) => {
      return { time: i.time, bollingerbandUpp: i.value };
    }) || [];

  const toggleHandler = (e) => {
    if (e.dataKey === "ema20") {
      setEma20(!EMA20);
      if(dataToExport.map((i)=>Object.keys(i))[0].includes("ema20")){
        removeKeyValuePair("ema20")
      }
      else if(!dataToExport.map((i)=>Object.keys(i))[0].includes("ema20")){
        const temp = dataToExport.map((item, i) =>
          Object.assign(
            {},
            item,
            ema20[i],
          )
        );
        setDataToExport(temp);
      }
    }
    if (e.dataKey === "ema90") {
      setEma90(!EMA90);
      if(dataToExport.map((i)=>Object.keys(i))[0].includes("ema90")){
        removeKeyValuePair("ema90")
      }
      else if(!dataToExport.map((i)=>Object.keys(i))[0].includes("ema90")){
        const temp = dataToExport.map((item, i) =>
          Object.assign(
            {},
            item,
            ema90[i],
          )
        );
        setDataToExport(temp);
      }
    }
    if (e.dataKey === "ema200") {
      setEma200(!EMA200);
      if(dataToExport.map((i)=>Object.keys(i))[0].includes("ema200")){
        removeKeyValuePair("ema200")
      }
      else if(!dataToExport.map((i)=>Object.keys(i))[0].includes("ema200")){
        const temp = dataToExport.map((item, i) =>
          Object.assign(
            {},
            item,
            ema200[i],
          )
        );
        setDataToExport(temp);
      }
    }
    if (e.dataKey === "sma20") {
      setSma20(!SMA20);
      if(dataToExport.map((i)=>Object.keys(i))[0].includes("sma20")){
        removeKeyValuePair("sma20")
      }
      else if(!dataToExport.map((i)=>Object.keys(i))[0].includes("sma20")){
        const temp = dataToExport.map((item, i) =>
          Object.assign(
            {},
            item,
            sma20[i],
          )
        );
        setDataToExport(temp);
      }
    }
    if (e.dataKey === "sma90") {
      setSma90(!SMA90);
      if(dataToExport.map((i)=>Object.keys(i))[0].includes("sma90")){
        removeKeyValuePair("sma90")
      }
      else if(!dataToExport.map((i)=>Object.keys(i))[0].includes("sma90")){
        const temp = dataToExport.map((item, i) =>
          Object.assign(
            {},
            item,
            sma90[i],
          )
        );
        setDataToExport(temp);
      }
    }
    if (e.dataKey === "sma200") {
      setSma200(!SMA200);
      if(dataToExport.map((i)=>Object.keys(i))[0].includes("sma200")){
        removeKeyValuePair("sma200")
      }
      else if(!dataToExport.map((i)=>Object.keys(i))[0].includes("sma200")){
        const temp = dataToExport.map((item, i) =>
          Object.assign(
            {},
            item,
            sma200[i],
          )
        );
        setDataToExport(temp);
      }
    }
    if (e.dataKey === "kombinierterPreis") {
      setKp(!kp);
    }
    if (e.dataKey === "preis") {
      setPREIS(!PREIS);
    }
    if (e.dataKey === "gesamt") {
      setPRICE(!PRICE);
    }
    if (e.dataKey === "VaR") {
      if (PREIS) {
        setPREIS(!PREIS);
        setTimeout(() => {
          setPREIS(PREIS);
        }, 3000);
      }
      setVAR(!VAR);
    }
    if (e.dataKey === "williams") {
      setwilliams(!WILLIAMS);
      if(dataToExport.map((i)=>Object.keys(i))[0].includes("williams")){
        removeKeyValuePair("williams")
      }
      else if(!dataToExport.map((i)=>Object.keys(i))[0].includes("williams")){
        const temp = dataToExport.map((item, i) =>
          Object.assign(
            {},
            item,
            williams[i],
          )
        );
        setDataToExport(temp);
      }
      
    }
    if (e.dataKey === "macd") {
      setMacd(!MACD);
      if(dataToExport.map((i)=>Object.keys(i))[0].includes("macd")){
        removeKeyValuePair("macd")
      }
      else if(!dataToExport.map((i)=>Object.keys(i))[0].includes("macd")){
        const temp = dataToExport.map((item, i) =>
          Object.assign(
            {},
            item,
            macd[i],
          )
        );
        setDataToExport(temp);
      }
    }
    if (e.dataKey === "momentum") {
      setMomentum(!MOMENTUM);
      if(dataToExport.map((i)=>Object.keys(i))[0].includes("momentum")){
        removeKeyValuePair("momentum")
      }
      else if(!dataToExport.map((i)=>Object.keys(i))[0].includes("momentum")){
        const temp = dataToExport.map((item, i) =>
          Object.assign(
            {},
            item,
            momentum[i],
          )
        );
        setDataToExport(temp);
      }
    }
    if (e.dataKey === "bollingerbandUpp") {
      setBollingerBandUpp(!BOLLINGERBANDUPP);
      if(dataToExport.map((i)=>Object.keys(i))[0].includes("bollingerbandUpp")){
        removeKeyValuePair("bollingerbandUpp")
      }
      else if(!dataToExport.map((i)=>Object.keys(i))[0].includes("bollingerbandUpp")){
        const temp = dataToExport.map((item, i) =>
          Object.assign(
            {},
            item,
            bollingerbandUpp[i],
          )
        );
        setDataToExport(temp);
      }
    }
    if (e.dataKey === "bollingerbandLow") {
      setBollingerBandLow(!BOLLINGERBANDLOW);
      if(dataToExport.map((i)=>Object.keys(i))[0].includes("bollingerbandLow")){
        removeKeyValuePair("bollingerbandLow")
      }
      else if(!dataToExport.map((i)=>Object.keys(i))[0].includes("bollingerbandLow")){
        const temp = dataToExport.map((item, i) =>
          Object.assign(
            {},
            item,
            bollingerbandLow[i],
          )
        );
        setDataToExport(temp);
      }
    }
  };

  const ordersCopy = orders && [...orders];

  const ordersRepeatTime = ordersCopy?.reduce((val, cur) => {
    val[cur.dat.split("T")[0]] = val[cur.dat.split("T")[0]]
      ? val[cur.dat.split("T")[0]] + 1
      : 1;
    return val;
  }, {});

  const res =
    ordersRepeatTime &&
    Object.keys(ordersRepeatTime).map((key) => ({
      time: key,
      count: ordersRepeatTime[key],
    }));

  // console.log("orders",orders);
  // console.log("res",res)

  const OrdersPunkte =
    res?.reverse().map((i) => {
      return { time: i.time, repeat: i.count };
    }) || [];

  // console.log("OrdersPunkte",OrdersPunkte)#

  const mergedAll = fullHistories.map((item, i) =>
    Object.assign(
      {},
      item,
      ema9[i],
      ema20[i],
      ema90[i],
      ema200[i],
      sma20[i],
      sma90[i],
      sma200[i],
      williams[i],
      macd[i],
      momentum[i],
      bollingerbandLow[i],
      bollingerbandUpp[i]
    )
  );

  function removeKeyValuePair(keyToRemove) {
    const initialValue = [...dataToExport];
    const updatedArray = initialValue.map(
      ({ [keyToRemove]: _, ...rest }) => rest
    );
    setDataToExport(updatedArray);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };
  // function addKeyValuePair(keyToAdd) {
  //   const initialValue = [...dataToExport];
  //   const updatedArray = initialValue.map(
  //     ({ [keyToRemove]: _, ...rest }) => rest
  //   );
  //   return updatedArray;
  // };
  
  // console.log("mergeall", mergedAll.map((i)=>Object.keys(i))[0])

  useEffect(() => {
    const tempo = fullHistories.map((item, i) =>
      
      Object.assign(
        {},
        item,
        {Datum : formatDate(item.dat)},
        {Energiebeschaffungspreis: item.preis},
        williams[i],
        macd[i],
        momentum[i],
      ))

      setDataToExport(tempo)
  }, []);

  const kaufPunkte = (OrdersPunkte, fullCopy) => {
    const result: any = [];
    for (let i = 0; i < fullCopy.length; i++) {
      result.push({
        ...fullCopy[i],
        ...OrdersPunkte?.find((j) => j.time === fullCopy[i].dat.split("T")[0]),
        ...orders?.find(
          (k) => k.dat.split("T")[0] === fullCopy[i].dat.split("T")[0]
        ),
      });
    }
    return result;
  };

  // const kaufPunkte = (OrdersPunkte, fullCopy) => {
  //   const result = [];
  //   for (const i in fullCopy) {
  //     for (const j in OrdersPunkte) {
  //       if (
  //         fullCopy[i].dat.split("T")[0] === OrdersPunkte[j].time
  //       ) {
  //         //@ts-ignore
  //         result.push(Object.assign({}, fullCopy[i], {kauftime : OrdersPunkte[j].time, kaufRepeat : OrdersPunkte[j].repeat}));
  //       }
  //     }
  //   }
  //   return result;
  // };

  const uniqueKaufData = kaufPunkte(OrdersPunkte, fullCopy);

  const upperDiagraminitial = fullHistories.map((item, i) =>
    Object.assign(
      {},
      item,
      ema20[i],
      ema90[i],
      ema200[i],
      sma20[i],
      sma90[i],
      sma200[i],
      bollingerbandLow[i],
      bollingerbandUpp[i],
      uniqueKaufData[i]
    )
  );

  const copyUpperDiagram = [...upperDiagraminitial];

  const gesammtPunkte = copyUpperDiagram.map((i) => {
    if (i.hasOwnProperty("repeat")) {
      return { ...i, gesamt: i.kombinierterPreis[1] };
    }
  });

  const upperDiagram = fullHistories.map((item, i) =>
    Object.assign(
      { upperLimit, lowerLimit },
      item,
      ema20[i],
      ema90[i],
      ema200[i],
      sma20[i],
      sma90[i],
      sma200[i],
      bollingerbandLow[i],
      bollingerbandUpp[i],
      uniqueKaufData[i],
      gesammtPunkte[i]
    )
  );

  const lowerDiagram = fullHistories.map((item, i) =>
    Object.assign({}, item, williams[i], macd[i], ema9[i], momentum[i])
  );

  useEffect(() => {
    if (AuthService.getRole().simple_role === "LIEFERANT") {
      setIsLieferant(true);
    } else setIsLieferant(false);
  }, []);

  function CustomToolTipUpper({
    active,
    payload,
    // label,
    setLocalPriceCurveData,
  }) {
    const locale = localStorage.locale;
    let realPayload: PayloadData | undefined = undefined;
    let dateKey = "";
    if (payload[0]) {
      realPayload = payload[0];

      let keys = Object.keys(payload[0].payload);
      //filter for the right date key
      dateKey = keys.filter((key) => key !== realPayload?.dataKey)[0];
    }
    if (
      payload[0] &&
      payload[0].payload &&
      setLocalPriceCurveData !== undefined
    )
      setLocalPriceCurveData(payload[0].payload);

    return (
      <>
        {realPayload && (
          <Paper
            style={{
              padding: "20px 10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              width: "100%",
              margin: "auto",
            }}
          >
            <Typography>
              {lang[locale].date}:{" "}
              {payload[0].payload?.erstellungsdatum
                ? new Date(payload[0]?.payload.erstellungsdatum).toLocaleString(
                    "de-DE",
                    {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    }
                  )
                : new Date(realPayload.payload[dateKey]).toLocaleString(
                    "de-DE",
                    options
                  )}
            </Typography>
            {/* <Typography>
              {realPayload.name} : {` `}
              <NumberFormat
                thousandSeparator="."
                decimalSeparator=","
                fixedDecimalScale={true}
                decimalScale={2}
                suffix=" €/MWh"
                value={realPayload.value}
                displayType={"text"}
              />
            </Typography> */}
            {PREIS && (
              <Typography>
                {lang[locale].energyprocurementprice}: {` `}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  decimalScale={2}
                  suffix=" €/MWh"
                  value={realPayload.payload["preis"]}
                  displayType={"text"}
                />
              </Typography>
            )}
            {kp && realPayload.payload["kombinierterPreis"].length > 0 && (
              <Typography>
                {lang[locale].totalPrice}: {` `}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  decimalScale={2}
                  suffix=" €/MWh"
                  value={realPayload.payload["kombinierterPreis"][1]}
                  displayType={"text"}
                />
              </Typography>
            )}
            {kp && realPayload.payload["kombinierterPreis"].length > 0 && (
              <Typography>
                {lang[locale].charge}: {` `}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  decimalScale={2}
                  suffix=" €/MWh"
                  value={
                    realPayload.payload["kombinierterPreis"][1] -
                    realPayload.value
                  }
                  displayType={"text"}
                />
              </Typography>
            )}
            {EMA20 && realPayload.payload["ema20"] > 0 && (
              <Typography>
                EMA20: {` `}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  decimalScale={2}
                  suffix=" €/MWh"
                  value={realPayload.payload["ema20"]}
                  displayType={"text"}
                />
              </Typography>
            )}
            {EMA90 && realPayload.payload["ema90"] > 0 && (
              <Typography>
                EMA90: {` `}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  decimalScale={2}
                  suffix=" €/MWh"
                  value={realPayload.payload["ema90"]}
                  displayType={"text"}
                />
              </Typography>
            )}
            {EMA200 && realPayload.payload["ema200"] > 0 && (
              <Typography>
                EMA200: {` `}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  decimalScale={2}
                  suffix=" €/MWh"
                  value={realPayload.payload["ema200"]}
                  displayType={"text"}
                />
              </Typography>
            )}
            {SMA20 && realPayload.payload["sma20"] > 0 && (
              <Typography>
                SMA20: {` `}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  decimalScale={2}
                  suffix=" €/MWh"
                  value={realPayload.payload["sma20"]}
                  displayType={"text"}
                />
              </Typography>
            )}
            {SMA90 && realPayload.payload["sma90"] > 0 && (
              <Typography>
                SMA90: {` `}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  decimalScale={2}
                  suffix=" €/MWh"
                  value={realPayload.payload["sma90"]}
                  displayType={"text"}
                />
              </Typography>
            )}
            {SMA200 && realPayload.payload["sma200"] > 0 && (
              <Typography>
                SMA200: {` `}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  decimalScale={2}
                  suffix=" €/MWh"
                  value={realPayload.payload["sma200"]}
                  displayType={"text"}
                />
              </Typography>
            )}
            {BOLLINGERBANDLOW &&
              isNumber(realPayload.payload["bollingerbandLow"]) && (
                <Typography>
                  {lang[locale].BolligerBandLow}: {` `}
                  <NumberFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    fixedDecimalScale={true}
                    decimalScale={2}
                    suffix=" €/MWh"
                    value={realPayload.payload["bollingerbandLow"]}
                    displayType={"text"}
                  />
                </Typography>
              )}
            {BOLLINGERBANDUPP &&
              isNumber(realPayload.payload["bollingerbandUpp"]) && (
                <Typography>
                  {lang[locale].BolligerBandUp}: {` `}
                  <NumberFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    fixedDecimalScale={true}
                    decimalScale={2}
                    suffix=" €/MWh"
                    value={realPayload.payload["bollingerbandUpp"]}
                    displayType={"text"}
                  />
                </Typography>
              )}
            {VAR && (
              <Typography>
                VaR: {` `}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  decimalScale={2}
                  suffix=" €/MWh"
                  value={realPayload.payload["VaR"]}
                  displayType={"text"}
                />
              </Typography>
            )}
            {PRICE && isNumber(realPayload.payload["price"]) && (
              <Typography
                style={{
                  color: "#42a1f5",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: "100%",
                  margin: "auto",
                }}
              >
                {/* {realPayload.payload["repeat"]} X Gekauft : {` `} */}
                {lang[locale].buypoint}:{" "}
                <li
                  style={{
                    listStyleType: "none",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    margin: "auto",
                  }}
                >
                  {orders.map((i) => {
                    if (
                      i.dat.split("T")[0] ===
                      payload[0].payload?.dat.split("T")[0]
                    )
                      return (
                        <ul
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            width: "100%",
                            margin: "auto",
                          }}
                        >
                          <Typography>
                            {` ${i.dat.split("T")[1].slice(0, 5)} \u00A0`}
                          </Typography>
                          <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale={true}
                            decimalScale={2}
                            suffix=" €/MWh "
                            value={
                              isLieferant
                                ? Number(i.price + i.charge)
                                : Number(i.price)
                            }
                            displayType={"text"}
                          />
                        </ul>
                      );
                  })}
                </li>
              </Typography>
            )}
          </Paper>
        )}
      </>
    );
  }

  function CustomToolTipLower({
    active,
    payload,
    // label,
    // setLocalPriceCurveData,
  }) {
    const locale = localStorage.locale;
    let realPayload: PayloadData | undefined = undefined;
    let dateKey = "";
    if (payload[0]) {
      realPayload = payload[0];
      let keys = Object.keys(payload[0].payload);
      //filter for the right date key
      dateKey = keys.filter((key) => key !== realPayload?.dataKey)[0];
    }

    return (
      <>
        {realPayload && (
          <Paper style={{ padding: "20px 10px" }}>
            <Typography>
              {lang[locale].date}:{" "}
              {payload[0]?.payload.erstellungsdatum
                ? new Date(payload[0]?.payload.erstellungsdatum).toLocaleString(
                    "de-DE",
                    {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    }
                  )
                : new Date(realPayload.payload[dateKey]).toLocaleString(
                    "de-DE",
                    options
                  )}
            </Typography>
            {WILLIAMS && (
              <Typography>
                Williams: {` `}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  decimalScale={2}
                  // suffix=" € /MWh"
                  value={realPayload.payload["williams"]}
                  displayType={"text"}
                />
              </Typography>
            )}
            {MACD && (
              <Typography>
                Macd: {` `}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  decimalScale={2}
                  suffix=" €/MWh"
                  value={realPayload.payload["macd"]}
                  displayType={"text"}
                />
              </Typography>
            )}
            {MACD && (
              <Typography>
                Macd-Trigger: {` `}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  decimalScale={2}
                  suffix=" €/MWh"
                  value={realPayload.payload["ema9"]}
                  displayType={"text"}
                />
              </Typography>
            )}
            {MOMENTUM && (
              <Typography>
                Momentum: {` `}
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  fixedDecimalScale={true}
                  decimalScale={2}
                  suffix=" €/MWh"
                  value={realPayload.payload["momentum"]}
                  displayType={"text"}
                />
              </Typography>
            )}
          </Paper>
        )}
      </>
    );
  }

  const setLocalPriceCurveData = useCallback(
    (singleData: LoadcurvePriceHistory) => {
      const originalData: LoadcurvePriceHistory = fullHistories.find(
        (elem: LoadcurvePriceHistory) => elem.dat === singleData.dat
      );
      if (originalData !== lastData) {
        dispatch({
          type: pricecurveshowerConstants.SET,
          // kennung: originalData?.kennung,
          erstellungsdatum: originalData?.erstellungsdatum,
        });
      }

      setLastData(originalData);
    },
    [fullHistories, dispatch, lastData]
  );

  return (
    <Grid item xs={12}>
      <ResponsiveContainer height={height} width={width}>
        <ComposedChart
          data={upperDiagram}
          data-testid="areachart"
          style={{ margin: "0 0" }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#f5f5f5" />
          <YAxis
            label={{
              value: `${lang[locale].prices} [€ / MWh]`,
              angle: -90,
              position: "insideLeft",
              fill: "white",
            }}
          />
          <XAxis
            tickFormatter={(uniqueMonth) =>
              new Date(uniqueMonth).toLocaleString("de-DE", options)
            }
            allowDataOverflow={true}
            dataKey="time"
            allowDuplicatedCategory={false}
            type="category"
            interval="preserveStart"
            angle={25}
            dx={6}
            dy={6}
            hide={true}
            width={90}
          />
          <Tooltip
            wrapperStyle={{ outline: "none" }}
            filterNull={false}
            content={
              <CustomToolTipUpper
                setLocalPriceCurveData={setLocalPriceCurveData}
                active="true"
                payload={upperDiagram}
                // label={}
              />
            }
          />
          <Legend verticalAlign="top" height={36} onClick={toggleHandler} />
          <Line
            name={lang[locale].energyprocurementprice}
            type="monotone"
            dataKey="preis"
            stroke={"#ebad1c"}
            hide={!PREIS}
            strokeWidth={2}
            dot={false}
            isAnimationActive={false}
          />
          {orders && orders.length > 0 && (
            <Line
              name={lang[locale].purchases}
              type="monotone"
              dataKey="gesamt"
              stroke={PRICE ? "#42a1f5" : "none"}
              hide={!PRICE}
              fill="#42a1f5"
              dot={true}
              isAnimationActive={false}
            />
          )}
          {AuthService.getRole().simple_role === "LIEFERANT" ? (
            <Area
              dataKey="kombinierterPreis"
              name={lang[locale].totalPrice}
              stroke="#2a7528"
              fill="#3eb03a"
              hide={!kp}
              isAnimationActive={false}
            />
          ) : null}
          <Line
            name="VaR"
            type="monotone"
            dataKey="VaR"
            stroke="#33ffff"
            hide={!VAR}
            dot={false}
            isAnimationActive={false}
          />
          <Line
            // name="VaR"
            type="monotone"
            dataKey="upperLimit"
            stroke="red"
            // hide={!VAR}
            dot={false}
            isAnimationActive={false}
            legendType="none"
          />
          <Line
            // name="VaR"
            type="monotone"
            dataKey="lowerLimit"
            stroke="#0583B1"
            // hide={!VAR}
            dot={false}
            isAnimationActive={false}
            legendType="none"
          />
          <Line
            name="EMA20"
            type="monotone"
            dataKey="ema20"
            stroke="#42b3f5"
            hide={!EMA20}
            dot={false}
          />
          <Line
            name="EMA90"
            type="monotone"
            dataKey="ema90"
            stroke="#99ff33"
            hide={!EMA90}
            dot={false}
          />
          <Line
            name="EMA200"
            type="monotone"
            dataKey="ema200"
            stroke="#8884c8"
            hide={!EMA200}
            dot={false}
          />
          <Line
            name="SMA20"
            type="monotone"
            dataKey="sma20"
            stroke="#f56942"
            hide={!SMA20}
            dot={false}
          />
          <Line
            name="SMA90"
            type="monotone"
            dataKey="sma90"
            stroke="#f542da"
            hide={!SMA90}
            dot={false}
          />
          <Line
            name="SMA200"
            type="monotone"
            dataKey="sma200"
            stroke="#42f58d"
            hide={!SMA200}
            dot={false}
          />
          <Line
            name={
              BOLLINGERBANDLOW
                ? `${lang[locale].BolligerBandLow}`
                : "Bollingerband"
            }
            type="monotone"
            dataKey="bollingerbandLow"
            stroke="#e07f7e"
            hide={!BOLLINGERBANDLOW}
            dot={false}
          />
          {BOLLINGERBANDLOW && (
            <Line
              name={lang[locale].BolligerBandUp}
              type="monotone"
              dataKey="bollingerbandUpp"
              stroke="#e07f7e"
              hide={!BOLLINGERBANDUPP}
              dot={false}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>

      <ResponsiveContainer height={300} width={width}>
        <ComposedChart
          data={mergedAll}
          data-testid="areachart"
          style={{ margin: "0 0" }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#f5f5f5" />
          <YAxis
          // allowDataOverflow={true}
          />
          <XAxis
            tickFormatter={(monthArray) =>
              new Date(monthArray).toLocaleString(showLocalLang, options)
            }
            // tickFormatter={monthArray}
            allowDataOverflow={true}
            dataKey="time"
            allowDuplicatedCategory={false}
            type="category"
            interval={"preserveStart"}
            angle={25}
            dx={6}
            dy={6}
          />

          <Tooltip
            wrapperStyle={{ outline: "none" }}
            filterNull={false}
            content={
              <CustomToolTipLower active="true" payload={lowerDiagram} />
            }
          />
          <Legend verticalAlign="bottom" height={36} onClick={toggleHandler} />
          <Line
            name="Williams"
            type="monotone"
            dataKey="williams"
            stroke="#ffcc33"
            hide={!WILLIAMS}
            dot={false}
          />
          <Line
            name="Macd"
            type="monotone"
            dataKey="macd"
            stroke="#2a7528"
            hide={!MACD}
            dot={false}
          />
          <Line
            name="Macd-Trigger"
            type="monotone"
            dataKey="ema9"
            stroke="#ff33ff"
            hide={!MACD}
            dot={false}
            legendType={MACD ? "line" : "none"}
          />
          <Line
            name="Wiliminus20"
            type="monotone"
            dataKey="wiliminus20"
            stroke="red"
            hide={!WILLIAMS}
            dot={false}
            legendType="none"
          />
          <Line
            name="Wiliminus80"
            type="monotone"
            dataKey="wiliminus80"
            stroke="red"
            hide={!WILLIAMS}
            dot={false}
            legendType="none"
          />
          <Line
            name="Momentum"
            type="monotone"
            dataKey="momentum"
            stroke="#33ffff"
            hide={!MOMENTUM}
            dot={false}
          />
          <Brush
            height={20}
            opacity={0.1}
            stroke="green"
            y={475}
            style={{ zIndex: 1 }}
            // startIndex={0}
            // endIndex={60}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Grid>
  );
}

export default PriceHistoryGraph;
