import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { lang } from "lang";
import LoadcurveCardComponent from "./LoadcurveCard";
import { useSelector } from "services/helpers/useSelector";
import LoadcurveModel from "../typeDefs/LoadcurveModel";

function SupplierPlansFinishedComponent({ filtered, curvelistChanged, orderListChanged, DataKind }) {
    const locale = localStorage.locale;
    // @ts-ignore
    const customer = useSelector((state) => state.customer.customerData);
    const customer_id = customer && customer.id;

    return (
        <>
            <Grid
                container
                justify="center"
                alignItems="center"
                spacing={4}
                style={{
                    padding: "3.5em 1.5em 0em 1.5em",
                }}
            >
                {filtered && filtered.length !== 0 ? (
                    <>
                        {filtered.map((curve: LoadcurveModel) => (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                key={curve.zrid}
                                style={{ maxWidth: "45em" }}
                            >
                                <div id={filtered.zrid}>
                                    <LoadcurveCardComponent
                                        loadcurve={curve}
                                        id={customer_id}
                                        curvelistChanged={curvelistChanged}
                                        orderListChanged={orderListChanged}
                                        handleDays={[]}
                                        DataKind={DataKind}
                                    />
                                </div>
                            </Grid>
                        ))}
                    </>
                ) : (
                    <Box
                        display="flex"
                        alignItems="center"
                        flex-direction="column"
                        justifyContent="center"
                        /* minHeight="70vh" */
                    >
                        <Typography variant="h6" color="textSecondary">
                            {(DataKind === 1 || undefined) ? lang[locale].noLoadcurves : DataKind === 5 ? "Derzeit sind keine gelöschten Angebote vorhanden" : DataKind === 4 ? "Derzeit sind keine abgelaufenen Angebote vorhanden" : DataKind === 6 && "Derzeit sind keine ausstehenden Angebote vorhanden" }
                        </Typography>
                    </Box>
                )}
            </Grid>
        </>
    );
}

export default SupplierPlansFinishedComponent;
