const loadcurveConstants = {
    GET_SUCCESS: "LOADCURVE_GET_SUCCESS",
    GET_SUCCESS_REMAINING: "LOADCURVE_GET_SUCCESS_REMAINING",
    GET_REQUEST: "LOADCURVE_GET_REQUEST",
    GET_FAILURE: "LOADCURVE_GET_FAILURE",
    POST_REQUEST: "LOADCURVE_POST_REQUEST",
    POST_SUCCESS: "LOADCURVE_POST_SUCCESS",
    POST_FAILURE: "LOADCURVE_POST_FAILURE",
    DELETE_REQUEST: "LOADCURVE_DELETE_REQUEST",
    DELETE_SUCCESS: "LOADCURVE_DELETE_SUCCESS",
    DELETE_FAILURE: "LOADCURVE_DELETE_FAILURE",
    RESET_STATE: "LOADCURVE_RESET_STATE",
    RESET_STATE_LOGOUT: "LOADCURVE_RESET_STATE_LOGOUT",
};

export default loadcurveConstants;
