import { AnyAction } from "redux";
import LoadcurveModel from "typeDefs/LoadcurveModel";
import loadcurveConstants from "constants/loadcurve.constants";

/**
 * State for loadcurvereducer
 */
export interface LoadCurveState {
    loadcurves?: LoadcurveModel[];
    loadcurvesRemaining?;
    loading?: boolean;
    error?: Error;
    //loading which gets triggered when a user uploads a new loadcurve
    createLoading: boolean;
    createError?: Error;
    createSuccess?: boolean;
    deleteError?: Error;
    deleteLoading: boolean;
    deleteSuccess?: boolean;
}

const initialState: LoadCurveState = {
    createSuccess: undefined,
    createError: undefined,
    createLoading: false,
    deleteError: undefined,
    deleteLoading: false,
    deleteSuccess: undefined,
    loadcurves: undefined,
    loadcurvesRemaining: undefined,
    loading: undefined,
    error: undefined,
};

/**
 * Reducer for all loadcurve based stuff
 * @param state State of loadcurves
 * @param action The action which gets send
 */
export function loadcurve(
    state: LoadCurveState = initialState,
    action: AnyAction
) {
    switch (action.type) {
        case loadcurveConstants.DELETE_REQUEST:
            return {
                ...state,
                deleteLoading: true,
            };
        case loadcurveConstants.DELETE_SUCCESS:
            return {
                ...state,
                deleteLoading: false,
                deleteSuccess: true,
            };
        case loadcurveConstants.DELETE_FAILURE:
            return {
                ...state,
                deleteLoading: false,
                deleteError: action.error,
                deleteSuccess: false,
            };

        case loadcurveConstants.POST_SUCCESS:
            return {
                ...state,
                createLoading: false,
                createSuccess: true,
            };
        case loadcurveConstants.POST_FAILURE:
            return {
                ...state,
                createError: action.error,
                createLoading: false,
            };
        case loadcurveConstants.POST_REQUEST:
            return {
                ...state,
                createLoading: true,
            };
        case loadcurveConstants.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case loadcurveConstants.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                loadcurves: action.loadcurves,
            };
        case loadcurveConstants.GET_SUCCESS_REMAINING:
            return {
                ...state,
                loading: false,
                loadcurvesRemaining: action.loadcurvesRemaining,
            };
        case loadcurveConstants.GET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case loadcurveConstants.RESET_STATE:
            return {
                ...state,
                createLoading: false,
                createSuccess: undefined,
                createError: undefined,
                deleteSuccess: undefined,
                deleteLoading: false,
                deleteError: undefined,
            };
        case loadcurveConstants.RESET_STATE_LOGOUT:
            return {
                createSuccess: undefined,
                createError: undefined,
                createLoading: false,
                deleteError: undefined,
                deleteLoading: false,
                deleteSuccess: undefined,
                loadcurves: undefined,
                loadcurvesRemaining: undefined,
                loading: undefined,
                error: undefined,
            };
        default:
            return state;
    }
}
