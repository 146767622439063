import React from "react";
import {
    Grid,
    TextField,
    Button,
    Typography,
    CircularProgress,
    CssBaseline,
} from "@material-ui/core";
import { lang } from "lang";
import { Link } from "react-router-dom";
import { emailRegex } from "services/helpers/Regex";
import "App.css";
import AuthService from "../services/auth/AuthService";
import { useDispatch } from "react-redux";
import snackbarActions from "../actions/snackbar.actions";

const service = new AuthService();

interface ResetComponentProps {
    locale: string;
}
/**
 * Component for resetting the password
 * @param props Locales get send
 */
function ResetComponent(props: ResetComponentProps) {
    const locale = props.locale;
    const [email, setEmail] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    function checkDisabled() {
        return email !== "" && !emailRegex.test(email);
    }
    //handles submit
    function handleSubmit(e) {
        setLoading(true);
        service
            .resetPasswordTrigger(email)
            .then(() => {
                setLoading(false);
                dispatch(
                    snackbarActions.openSnackbar(
                        lang[locale].passwordResetTriggerSuccess,
                        "success"
                    )
                );
            })
            .catch((e) => {
                setLoading(false);
                console.error(e);
                dispatch(snackbarActions.openSnackbar(e.message, "error"));
            });
    }

    return (
        <Grid container direction="row" spacing={4}>
            <CssBaseline />
            <Grid item xs={12}>
                <Typography variant="h5">
                    {lang[locale].resetPasswordHeader}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>{lang[locale].resetPasswordSubtitle}</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="email"
                    label={lang[locale].resetPasswordText}
                    className="maximalWidth"
                    inputProps={{
                        "data-testid": "resetEmail",
                    }}
                    variant="outlined"
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    required
                    error={checkDisabled()}
                    helperText={
                        checkDisabled() && lang[locale].resetPasswordEmailError
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={6}>
                        <Grid
                            container
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Grid item>
                                <Button
                                    data-testid="backButton"
                                    color="primary"
                                    component={Link}
                                    to="/auth/login"
                                >
                                    {lang[locale].back}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justify="flex-end">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                type="submit"
                                data-testid="submitReset"
                                disabled={checkDisabled() || email === ""}
                            >
                                {loading ? (
                                    <CircularProgress size={30} />
                                ) : (
                                    lang[locale].send
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ResetComponent;
