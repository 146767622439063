import React, {  useState } from "react";
import { lang } from "../lang";

//Materials UI
import { Button, TextField, CardContent, Card, Box, Grid, Typography } from "@material-ui/core";

//Hooks
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

//Actions
import snackbarActions from "../actions/snackbar.actions";
import usersActions from "actions/users.actions";
// import authActions from "actions/auth.actions";

//Services
import UserService from "../services/users/UserService";
import { emailRegex } from "services/helpers/Regex";

const userService = new UserService();

function AddUserComponent(props) {
    const [newEmail, setNewEmail] = useState<string>("");
    const [newFirstName, setNewFirstName] = useState<string>("");
    const [newLastName, setNewLastName] = useState<string>("");
    const dispatch = useDispatch();
    let history = useHistory();
    //@ts-ignore
    const {id, name} = useParams();

    const locale = localStorage.locale;
    function addCustomer(e) {
        e.preventDefault();

        const user = {
            firstname: newFirstName,
            lastname: newLastName,
            email: newEmail,
            role: {
                simple_role: props.role,
                permissions: [],
            },
            company_id:
                props.role === "LIEFERANT" ? 0 : Number(props.company_id),
        };

        userService
            .createUser(user)
            .then(() => {
                updateStore();
                dispatch(
                    snackbarActions.openSnackbar(
                        lang[locale].userCreated,
                        "success"
                    )
                );
                setNewFirstName("");
                setNewLastName("");
                setNewEmail("");
            })
            .catch((e) => {
                if (e.message === "401") {
                    return history.push(`/logout`);
                }
                console.error(e);
                dispatch(snackbarActions.openSnackbar(e.message, "error"));
            });
    }


    function updateStore() {
        props.role === "LIEFERANT"
            ? userService
                  .getUsersFromSupplier()
                  .then((users) => {
                      dispatch(usersActions.updateAll(users));
                  })
                  .catch((e) => {
                      if (e.message === "401") {
                          return history.push(`/logout`);
                      }
                      console.error(e);
                      dispatch(
                          snackbarActions.openSnackbar(e.message, "error")
                      );
                  })
            : userService
                  .getUsersByCustomerId(props.company_id)
                  .then((users) => {
                      dispatch(usersActions.updateAll(users));
                  })
                  .catch((e) => {
                      if (e.message === "401") {
                          return history.push(`/logout`);
                      }
                      console.error(e);
                      dispatch(
                          snackbarActions.openSnackbar(e.message, "error")
                      );
                  });
    }

    function checkDisabled() {
        return newFirstName === "" || newFirstName.length > 50 || newLastName === "" || newLastName.length > 50 || newEmail === "" || newEmail.length > 50 || !emailRegex.test(newEmail);
    }

    return (
        <Card>
            <CardContent>
                <form onSubmit={addCustomer}>
                    <Grid>
                        <Typography variant="body2" color="primary" style={{marginLeft : "16px"}}>
                            {props.role === "LIEFERANT" ? <div>{lang[locale].addnewsupplier} {localStorage.company}</div> : <div>{lang[locale].addusertocustomer} {name}</div>}
                        </Typography>
                    </Grid>
                    <Box
                        flexDirection="row"
                        bgcolor="background.paper"
                        display="flex"
                    >
                        <Box p={2}>
                            <TextField
                                value={newFirstName}
                                id="newFirstName"
                                label={lang[locale].firstName}
                                className="maximalWidth"
                                type="text"
                                variant="outlined"
                                onChange={(e) => {
                                    setNewFirstName(e.target.value);
                                }}
                                required
                                autoComplete="off"
                                error={newFirstName.length > 50}
                                helperText={newFirstName.length > 50 && lang[locale].morethanAllowed}
                                />
                        </Box>
                        <Box p={2}>
                            <TextField
                                value={newLastName}
                                id="newLastName"
                                label={lang[locale].lastName}
                                className="maximalWidth"
                                type="text"
                                variant="outlined"
                                onChange={(e) => {
                                    setNewLastName(e.target.value);
                                }}
                                required
                                autoComplete="off"
                                error={newLastName.length > 50}
                                helperText={newLastName.length > 50 && lang[locale].morethanAllowed}
                                />
                        </Box>
                        <Box p={2}>
                            <TextField
                                value={newEmail}
                                id="newEmail"
                                label={lang[locale].email}
                                className="maximalWidth"
                                type="text"
                                variant="outlined"
                                onChange={(e) => {
                                    setNewEmail(e.target.value);
                                }}
                                required
                                autoComplete="off"
                                error={newEmail.length > 0 && !emailRegex.test(newEmail)}
                                helperText={newEmail.length > 50 && lang[locale].morethanAllowed}
                            />
                        </Box>
                        <Box p={3}>
                            <Button
                                variant="contained"
                                // color="primary"
                                style={{backgroundColor : checkDisabled() ? "#595959" : props.coloR.primary, color : checkDisabled() ? "#898989" : "white"}}
                                type="submit"
                                disabled={checkDisabled()}
                            >
                                {lang[locale].add}
                            </Button>
                        </Box>
                    </Box>
                </form>
            </CardContent>
        </Card>
    );
}

export default AddUserComponent;
