import { lang } from "../lang";

//Hooks
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

//Material UI
import { Button, Grid, Typography } from "@material-ui/core";

//Services
import UserService from "../services/users/UserService";

//Actions
import snackbarActions from "../actions/snackbar.actions";
import modalActions from "../actions/modal.actions";
import usersActions from "actions/users.actions";
import customerActions from "actions/customer.actions";

const service = new UserService();

function DeleteUserOrCustomerComponent(props) {
    let history = useHistory();
    const locale = localStorage.locale;
    const dispatch = useDispatch();

    // console.log("delete", props);

    function del() {
        service
            .delete(props.type, props.id)
            .then(() => {
                props.type === "user"
                    ? dispatch(usersActions.deleteSingle(props.id))
                    : dispatch(customerActions.deleteSingle(props.id));

                dispatch(
                    snackbarActions.openSnackbar(
                        props.information + " erfolgreich gelöscht.",
                        "success"
                    )
                );

                closeModal();
            })
            .catch((e) => {
                if (e.message === "401") {
                    return history.push(`/logout`);
                }
                dispatch(snackbarActions.openSnackbar(e.message, "error"));
            });
    }

    function closeModal() {
        // console.log("In Close Modal DeleteUserOrCustomer Component");
        dispatch(modalActions.closeModal());
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography>
                    {props.type === "user"
                        ? lang[locale].deleteUser
                        : lang[locale].deleteCustomer}
                </Typography>
                <Typography>
                    <b>{props.information}</b>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container justify="space-between">
                    <Grid item>
                        <Button color="primary" onClick={closeModal}>
                            {lang[locale].close}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={del}
                        >
                            {lang[locale].send}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DeleteUserOrCustomerComponent;
