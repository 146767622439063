import React, { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { KeyboardDatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import forecastActions from "../actions/forecast.actions";
import { lang } from "../lang";
import { useDispatch } from "react-redux";
import { useSelector } from "../services/helpers/useSelector";

/**
 * Component for selecting the measured date, if usage type measured
 * is set
 * @constructor
 */
function SelectMeasuredDatesComponent() {
    const dispatch = useDispatch();
    const { measuredFrom, measuredTo } = useSelector(
        (state) => state.forecast.data
    );

    useEffect(() => {
        dispatch(
            forecastActions.saveForecastSingle(
                "measuredFrom",
                new Date(new Date().getFullYear() - 1, 0, 1)
            )
        );
        dispatch(
            forecastActions.saveForecastSingle(
                "measuredTo",
                new Date(new Date().getFullYear() - 1, 11, 31)
            )
        );
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const locale = localStorage.locale;

    function checkFromDate(from: Date, to: Date): string[] {
        const check: string[] = [];
        const today = new Date();
        const minMeasuredDate = new Date(
            today.getFullYear() - 5,
            today.getMonth(),
            today.getDate()
        );
        if (to !== null && from > to) {
            check.push(lang[locale].dateWrongError);
        }
        if (isNaN(from.valueOf())) {
            check.push(lang[locale].invalidDate);
        }
        if (from < minMeasuredDate) {
            check.push(
                lang[locale].fromDateTooSmall +
                    minMeasuredDate.toLocaleString("de-DE", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                    })
            );
        }
        return check;
    }

    function checkToDate(from: Date, to: Date): string[] {
        const check: string[] = [];
        const maxMeasuredDate = new Date(Date.now() - 30 * 86400000);
        if (isNaN(to.valueOf())) {
            check.push(lang[locale].invalidDate);
        }
        if (to > maxMeasuredDate) {
            check.push(
                lang[locale].toDateTooBig +
                    maxMeasuredDate.toLocaleString("de-DE", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                    })
            );
        }
        return check;
    }
    return (
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === "de" ? deLocale : enLocale}>
                    <KeyboardDatePicker
                        helperText=""
                        variant="inline"
                        format="dd.MM.yyyy"
                        fullWidth
                        autoOk={true}
                        onChange={(date: Date | any) => {
                            dispatch(
                                forecastActions.saveForecastSingle(
                                    "measuredFrom",
                                    date
                                )
                            );
                        }}
                        value={measuredFrom}
                        error={measuredFrom === null}
                        id="measuredStartdate"
                        data-testid="measuredStartdate"
                        label={lang[locale].measuredStart}
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                        required
                    />
                </MuiPickersUtilsProvider>
                    <Grid>
                        {measuredFrom !== null &&
                            checkFromDate(measuredFrom, measuredTo).map(
                                (entry) => (
                                    <Typography
                                        variant="caption"
                                        color="error"
                                        data-testid="date-error"
                                    >
                                        {entry}
                                    </Typography>
                                )
                            )}
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === "de" ? deLocale : enLocale}>
                    <KeyboardDatePicker
                        helperText=""
                        variant="inline"
                        fullWidth
                        format="dd.MM.yyyy"
                        data-testid="measuredEnddate"
                        onChange={(date: Date | any) => {
                            dispatch(
                                forecastActions.saveForecastSingle(
                                    "measuredTo",
                                    date
                                )
                            );
                        }}
                        autoOk={true}
                        value={measuredTo}
                        error={measuredTo === null}
                        id="measuredEnddate"
                        label={lang[locale].measuredEnd}
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                        required
                    />
                </MuiPickersUtilsProvider>
                    <Grid>
                        {measuredTo !== null &&
                            checkToDate(measuredFrom, measuredTo).map(
                                (entry) => (
                                    <Typography
                                        variant="caption"
                                        color="error"
                                        data-testid="date-error"
                                        key={entry}
                                    >
                                        {entry}
                                    </Typography>
                                )
                            )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SelectMeasuredDatesComponent;
