import { Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import { lang } from "lang";
import { useSelector } from "services/helpers/useSelector";

function LoadcurveCardRemainingComponent({ curve }) {
  const locale = localStorage.locale;
  const iniproError = useSelector((state) => state.constants.inipro_error);

  const errorCode = () => {
    for (let i in iniproError) {
      if (iniproError[i].id === curve.error_code) {
        // console.log(iniproError[i][locale])
        return iniproError[i][locale];
      }
    }
  };
  errorCode();

  return (
    <Card>
      <div
        style={{
          width: "100%",
          backgroundColor: curve.status === 2 ? "grey" : "red",
          height: 15,
        }}
      ></div>
      <CardContent>
        {curve.status === 2 ? (
          <Typography variant="h5" gutterBottom>
            {lang[locale].prognosisInProcess}
          </Typography>
        ) : (
          <>
            <Typography variant="h5" gutterBottom>
              {lang[locale].prognosisImportError}
            </Typography>
            <Typography>{errorCode()}</Typography>
          </>
        )}
        <Typography variant="body2">
          {curve.name.split("_")[0]} <br />
          {curve.email} <br />
          {new Date(curve.timestamp).toLocaleString("de-DE")}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default LoadcurveCardRemainingComponent;
