const forecastConstants = {
    SAVE_SINGLE: "FORECAST_SAVE_SINGLE",
    SAVE_SINGLE_FLEXIMPORT: "FORECAST_SAVE_SINGLE_FLEXIMPORT",
    CREATE_REQUEST: "FORECAST_CREATE_REQUEST",
    CREATE_SUCCESS: "FORECAST_CREATE_SUCCESS",
    CREATE_ERROR: "FORECAST_CREATE_ERROR",
    RESET: "FORECAST_RESET",
    RESET_FPLDATA: "FORECAST_RESET_FPLDATA",
    UPDATE_PROFILETYPES: "FORECAST_UPDATE_PROFILETYPES",
    ADD_FINISHED_NAMES: "FORECAST_ADD_FINISHED_NAMES",
    REMOVE_FINISHED_NAMES: "FORECAST_REMOVE_FINISHED_NAMES"
}

export default forecastConstants;