import { lang } from "../lang";

//Hooks
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "../services/helpers/useSelector";

//Material UI
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";

//Actions
import snackbarActions from "../actions/snackbar.actions";
import loadcurveActions from "../actions/loadcurve.actions";
import modalActions from "../actions/modal.actions";
import customerActions from "actions/customer.actions";

//Services
import UserService from "../services/users/UserService";
import AuthService from "../services/auth/AuthService";

const service = new UserService();
// const authService = new AuthService();

/**
 * Responsable for deleting loadcurves
 * @constructor
 */
function DeleteLoadcurveComponent(props) {
    const dispatch = useDispatch();
    const deleteSuccess = useSelector((state) => state.loadcurve.deleteSuccess);
    const deleteLoading = useSelector((state) => state.loadcurve.deleteLoading);
    /*  const deleteError = useSelector((state) => state.loadcurve.deleteError); */
    const locale = localStorage.locale;
    // @ts-ignore
    const customer_id = useSelector((state) => state.custome?.customerData.id);
    const role = AuthService.getRole();

    function handleClose() {
        // console.log("In Close Modal DeleteLoadcurveComponent");
        dispatch(modalActions.closeModal());
    }

    function handleSubmit() {
        dispatch(loadcurveActions.deleteLoadcurve(props.zrid));
    }

    useEffect(() => {
        if (deleteSuccess) {
            dispatch(
                snackbarActions.openSnackbar(
                    lang[locale].deleteSuccess,
                    "success"
                )
            );
            dispatch(loadcurveActions.resetState());
            dispatch(loadcurveActions.getLoadcurves(customer_id, props.DataKind));
            /* Update the customer object or the customers to have the new current count of the prognosis counter */
            role.simple_role !== "LIEFERANT"
            ? service.getCustomer().then((res) => {
                dispatch(customerActions.update(res));
            })
            : service.getCustomers().then((customers) => {
                dispatch(customerActions.updateAll(customers));
            });
            handleClose();
        }
    }, [deleteSuccess]);

    return (
        <Grid container spacing={8}>
            <Grid item xs={12}>
                <Typography variant="h6">
                    {lang[locale].reallyWantToDeleteWithName} "
                    {props.name.split("_")[0]}"
                </Typography>
            </Grid>

            <Grid item xs={6}>
                <Grid container alignItems="center">
                    <Grid item>
                        <Button
                            onClick={handleClose}
                            data-testid="cancel"
                            color="primary"
                        >
                            {lang[locale].cancel}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container justify="flex-end" alignItems="center">
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            data-testid="submit"
                            disabled={deleteLoading}
                            onClick={handleSubmit}
                        >
                            {deleteLoading ? (
                                <CircularProgress data-testid="circular" />
                            ) : (
                                lang[locale].confirm
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DeleteLoadcurveComponent;
