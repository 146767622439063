import CreateForecastModel from "../typeDefs/CreateForecastModel";
import { AnyAction } from "redux";
import forecastConstants from "../constants/forecast.constants";
import ProfileType from "../typeDefs/ProfileType";
import FleximportData from "../typeDefs/FleximportData";

//initial data interface
export interface ForecastReducerInitial {
    data: CreateForecastModel;
    loading: boolean;
    error: Error | undefined;
    finishedRequest: boolean;
    profileTypes: ProfileType[] | undefined;
    //rows and cols for fleximport
    fleximportData: FleximportData;
    finishedNames: string[];
}

//standard data for fleximport data
export const initialFleximportData: FleximportData = {
    dateDifference: null,
    lessFactor: 1,
    moreFactor: 1,
    normalFactor: 1,
    cols: [],
    endDateValue: null,
    endValue: undefined,
    rows: [],
    startDateValue: null,
    startValue: undefined,
    unit: "",
    neededCount: 0,
    actualCount: 0,
    checked: {
        missingHours: true,
        doubleHours: true,
    },
    energy: 0,
    octoberTimeChangeRowId: [{ row: 0, date: "" }],
    marchTimeChangeRowId: [{ row: 0, date: "" }],
};
export const initialData: CreateForecastModel = {
    priceCurve: "0",
    pricing_type:"",
    istFrom: new Date(),
    istTo: new Date(),
    name: "",
    measuredFrom: new Date(),
    measuredTo: new Date(),
    progFrom: new Date(new Date().getFullYear() + 1, 0, 1),
    progTo: new Date(new Date().getFullYear() + 1, 11, 31),
    accept_from: undefined,
    accept_to: undefined,
    order_from: new Date(),
    order_to: new Date(),
    gas: false,
    forecastType: 0,
    usage: 0,
    performance: 0,
    //standard for not selected
    productType: 2,
    usageType: 0,
    holidayRegion: "",
    temperatureStation: "",
    fileName: "",
    tranche:undefined,
    divide_amount:true,
    max_order_tranche: 0,
    order_type:"",
    calendar: [],
    fallback_pricing_curve_id: undefined,
    fallback_pricing_date: undefined,
};
export const initial: ForecastReducerInitial = {
    data: initialData,
    error: undefined,
    loading: false,
    finishedRequest: false,
    profileTypes: undefined,
    fleximportData: initialFleximportData,
    finishedNames: [],
};

/**
 * Reducer for general forecast state with inipro
 * @param state
 * @param action
 */
export function forecast(
    state: ForecastReducerInitial = initial,
    action: AnyAction
) {
    let newNames;
    switch (action.type) {
        case forecastConstants.RESET_FPLDATA:
            // console.log("Reducer Reset FPL Data");
            return {
                ...state,
                fleximportData: initialFleximportData,
            };
        case forecastConstants.ADD_FINISHED_NAMES:
            newNames = [...state.finishedNames];
            newNames.push(action.name);
            return {
                ...state,
                finishedNames: newNames,
            };
        case forecastConstants.REMOVE_FINISHED_NAMES:
            let names = [...state.finishedNames];
            newNames = names.filter((name) => name !== action.name);
            return {
                ...state,
                finishedNames: newNames,
            };

        case forecastConstants.UPDATE_PROFILETYPES:
            return {
                ...state,
                profileTypes: action.profileTypes,
            };
        case forecastConstants.SAVE_SINGLE:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.field]: action.data,
                },
            };
        case forecastConstants.SAVE_SINGLE_FLEXIMPORT:
            // console.log("Reducer Save Fleximport Single");
            return {
                ...state,
                fleximportData: {
                    ...state.fleximportData,
                    [action.field]: action.data,
                },
            };
        case forecastConstants.CREATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case forecastConstants.CREATE_SUCCESS:
            return {
                ...state,
                finishedRequest: true,
                error: undefined,
                loading: false,
            };
        case forecastConstants.CREATE_ERROR:
            return {
                ...state,
                finishedRequest: true,
                error: action.error,
                loading: false,
            };
        case forecastConstants.RESET:
            return {
                ...initial,
                finishedNames: state.finishedNames,
            };

        default:
            return state;
    }
}
