import React from "react";
import {Card, CardContent, Grid, Typography} from "@material-ui/core";
import {lang} from "../lang";
import {useSelector} from "../services/helpers/useSelector";

/**
 * Card for showing information about the fleximport like energyamount
 * or needed rows or so
 * @constructor
 */
function FleximportValueCardComponent(){



    const locale = localStorage.locale;
    const data = useSelector(state => state.forecast.fleximportData);
    return (
        <Card>
            <CardContent>
                <Grid container spacing={4} justify="center" alignItems="center">
                    <Grid item xs={6}>
                        <Typography>{lang[locale].neededCount}:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>{lang[locale].actualCount}:</Typography>
                    </Grid>

                    <Grid item xs={6} style={{padding : "0 0 0 1rem"}}>
                        <Typography>{data.neededCount > 0 ? data.neededCount.toLocaleString() : 0}</Typography>
                    </Grid>
                    <Grid item xs={6} style={{padding : "0 0 0 1rem"}}>
                        <Typography>{data.actualCount > 0 ? data.actualCount.toLocaleString() : 0}</Typography>
                    </Grid>

                    {data.actualCount !== data.neededCount ? (
                        <Grid item xs={12}>
                            <Typography color="error">{lang[locale].valuesIncorrect}</Typography>
                        </Grid>

                    ) : ""}
                </Grid>

            </CardContent>

        </Card>
    )

}

export default FleximportValueCardComponent;