import React, { useEffect, useState } from "react";
import ProfileType from "../typeDefs/ProfileType";
import { useDispatch } from "react-redux";
import { useSelector } from "../services/helpers/useSelector";
import forecastActions from "../actions/forecast.actions";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import { lang } from "../lang";
import LoadcurveService from "../services/loadcurves/LoadcurveService";
import { useLocation } from "react-router";
import StyledGasPowerSwitch from "./StyledGasPowerSwitch";
import AuthService from "../services/auth/AuthService";
// import authActions from "../actions/auth.actions";
import snackbarActions from "../actions/snackbar.actions";
import Grid from "@material-ui/core/Grid";
import { Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import WhatshotIcon from "@material-ui/icons/Whatshot";

const loadcurveService = new LoadcurveService();

/**
 * Function to toggle between gas and power

 * @constructor
 */
export function TogglePowerGasComponent() {
  const [powerProfiles, setPowerProfiles] = useState<ProfileType[] | undefined>(
    undefined
  );
  const [gasProfiles, setGasProfiles] = useState<ProfileType[] | undefined>(
    undefined
  );
  const [disabled, setDisabled] = useState(false);
  const role = AuthService.getRole();
  let history = useHistory();

  /**
   * This state is for checking, if a user abortet the fleximport
   * if the resetting of the profile type and the forecast type
   * has been prevented one time. Because if we not check this,
   * the switch between gas and power does not reset the forecast type and profile
   */
  const [preventedReset, setPreventedReset] = useState(false);

  const dispatch = useDispatch();
  const locale = localStorage.locale;
  const data = useSelector((state) => state.forecast.data);
  const forecast = useSelector((state) => state.forecast.data.pricing_type);
  const location = useLocation();
  const [onlyGas, setOnlyGas] = useState(false);
  const [onlyPower, setOnlyPower] = useState(false);
  // const selectedCustomerP = useSelector((props) => props.customer["customerData"]["permissions"]);
  // const selectedCustomerPerm = Object.values(selectedCustomerP);



  //set default value with user permissions

  useEffect(() => {
    // if(role.simple_role !== "LIEFERANT"){
      if (
        !role.permissions?.includes("GAS" && "STROM")
      ) {
        setDisabled(true);
      }
      if (
        role.permissions?.includes("STROM") &&
        !role.permissions?.includes("GAS")
      ) {
        dispatch(forecastActions.saveForecastSingle("strom", true));
        dispatch(forecastActions.saveForecastSingle("gas", false));
        setOnlyPower(true);
      } else if (
        role.permissions?.includes("GAS") &&
        !role.permissions?.includes("STROM")
      ) {
        dispatch(forecastActions.saveForecastSingle("gas", true));
        dispatch(forecastActions.saveForecastSingle("strom", false));
        setOnlyGas(true);
      }
    // }
    // if(role.simple_role === "LIEFERANT"){
    //   if (
    //     !selectedCustomerPerm?.includes("GAS" && "STROM")
    //   ) {
    //     setDisabled(true);
    //   }
    //   if (
    //     selectedCustomerPerm?.includes("STROM") &&
    //     !selectedCustomerPerm?.includes("GAS")
    //   ) {
        
    //     dispatch(forecastActions.saveForecastSingle("strom", true));
    //     dispatch(forecastActions.saveForecastSingle("gas", false));
    //     setOnlyPower(true);
    //   } else if (
    //     selectedCustomerPerm?.includes("GAS") &&
    //     !selectedCustomerPerm?.includes("STROM")
    //   ) {
        
    //     dispatch(forecastActions.saveForecastSingle("gas", true));
    //     dispatch(forecastActions.saveForecastSingle("strom", false));
    //     setOnlyGas(true);
    //   }
    // }
  }, []);
  //gets the right thing from api if undefined
  useEffect(() => {
    //fetch if necessary
    if (data.gas === false) {
      loadcurveService
        .getProfiles("power")
        .then((profiles) => {
          setPowerProfiles(profiles);
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
    if (data.gas === true) {
      loadcurveService
        .getProfiles("gas")
        .then((profiles) => {
          setGasProfiles(profiles);
        })
        .catch((e) => {
          if (e.message === "401") {
            return history.push(`/logout`);
          }
          console.error(e);
          dispatch(snackbarActions.openSnackbar(e.message, "error"));
        });
    }
  }, [data.gas]);

  useEffect(() => {
    const fleximportAbort = new URLSearchParams(location.search).get(
      "fleximportAbort"
    );
    if ((!fleximportAbort || preventedReset) && forecast !== "FORMEL") {
      //reset state
      dispatch(forecastActions.saveForecastSingle("profile", 0));
      dispatch(forecastActions.saveForecastSingle("forecastType", 0));
      dispatch(forecastActions.saveForecastSingle("priceCurve", "0"));
    } else if (fleximportAbort && !preventedReset) {
      setPreventedReset(true);
    }
  }, [data.gas]);

  useEffect(() => {
    //check which profiles are needed, then set them for parent component
    if (data.gas && gasProfiles) {
      dispatch(forecastActions.updateProfileTypes(gasProfiles));
    } else if (!data.gas && powerProfiles) {
      dispatch(forecastActions.updateProfileTypes(powerProfiles));
    }
  }, [gasProfiles, powerProfiles, data.gas, dispatch]);

  //handles switch toggle
  function handleToggle() {
    dispatch(forecastActions.saveForecastSingle("gas", !data.gas));
    dispatch(forecastActions.saveForecastSingle("forecastType", 0));
  }

  return (
    <Grid xs={12} sm={8} lg={6} md={6} component="label" container alignItems="center" spacing={1}>
      {onlyPower ? (
          <Tooltip title={lang[locale].powerPermission}>
            <FlashOnIcon style={{ color: "#0983B1" }} />
          </Tooltip>
      ) : onlyGas ? (
        <Tooltip title={lang[locale].gasPermission}>
          <WhatshotIcon style={{ color: "#FAB515" }} />
        </Tooltip>
      ) : (
        <>
          <Grid item color="primary">
            {lang[locale].gas}
          </Grid>
          <Grid item>
            <StyledGasPowerSwitch
              checked={!data.gas}
              onChange={handleToggle}
              disabled={disabled}
            />
          </Grid>
          <Grid item color="primary">
            {lang[locale].power}
          </Grid>
        </>
      )}{" "}
    </Grid>
  );
}
