import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
  Tooltip as MTooltip,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import modalActions from "../actions/modal.actions";
import { lang } from "../lang";
import TwoDecimalComponent from "./TwoDecimal";
import LoadcurveService from "services/loadcurves/LoadcurveService";
import { KeyboardDatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";

function VirtualPurchaseComponent(props) {
  let StartDate = new Date(props.ProgFrom);
  let EndDate = new Date(props.ProgTo);
  const service = new LoadcurveService();
  const dispatch = useDispatch();
  const locale = localStorage.locale;
  const [buyDate, setBuyDate] = useState<string | null>("");
  const [SpecificPrice, setspecificPrice] = useState(0);
  const [Leistung, setLeistung] = useState(props.leistung);
  const [Amount, setAmount] = useState(props.amount);

  const selectHandler = (e) => {
    e.target.select();
  };

  function AmounChanger(startDate, endDate) {
    // Clone the start date to avoid modifying the original date
    
    let count = 0;
  
    // Loop through each day until the end date
    while (startDate <= endDate) {
      if(props.productTyp === "Base"){

        count++;
      }
      if(props.productTyp !== "Base"){

        // Check if the current day is not a weekend (Saturday or Sunday)
        if (startDate.getDay() !== 0 && startDate.getDay() !== 6) {
          count++;
        }
      }
  
      // Move to the next day
      startDate.setDate(startDate.getDate() + 1);
    }
  
    if(props.productTyp === "Base"){

      
      return ((count * 24 * Leistung) / 1000);
    }
    if(props.productTyp !== "Base"){

      
      return ((count * 12 * Leistung) / 1000);
    }
  }

  // function LeistungChanger(startDate, endDate) {
  //   // Clone the start date to avoid modifying the original date
    
  //   let count = 0;
  
  //   // Loop through each day until the end date
  //   while (startDate <= endDate) {
  //     if(props.productTyp === "Base"){

  //       count++;
  //     }
  //     if(props.productTyp !== "Base"){

  //       // Check if the current day is not a weekend (Saturday or Sunday)
  //       if (startDate.getDay() !== 0 && startDate.getDay() !== 6) {
  //         count++;
  //       }
  //     }
  
  //     // Move to the next day
  //     startDate.setDate(startDate.getDate() + 1);
  //   }
  
  //   if(props.productTyp === "Base"){

      
  //     return ((Amount * 1000) / (count * 24));
  //   }
  //   if(props.productTyp !== "Base"){

      
  //     return  ((Amount * 1000) / (count * 12));
  //   }
  // }


  function virtualkaufhandler() {
    service
      .createVirtualBuy(props.customer.id, 
        buyDate,
        Amount > 0 ? Amount : props.amount,
        SpecificPrice,
        props.zrid,
        true
    )
      .then((res) => console.log("res", res));
      props.setOrderChanged(2);
      handleClose();
  }

  function handleClose() {
    // console.log("In Close Modal DeleteLoadcurveComponent");
    dispatch(modalActions.closeModal());
  }

  const TextFieldComponent = (props) => {
    return <TextField {...props} disabled={true} />
  }

  function buyDateHandler(e) {
    setBuyDate(e?.toISOString());
    // console.log("iso", e?.toISOString())
  }

  useEffect(() => {
    setBuyDate((new Date(Date.now())).toISOString());
  }, []);

  useEffect(()=>{
    setAmount(Number(AmounChanger(StartDate, EndDate)))
  },[Leistung])

  // useEffect(()=>{
  //   if(Amount > 0){
  //     setLeistung(Number(LeistungChanger(StartDate, EndDate)))
  //   }
  // },[Amount])

  return (
    <div style={{ padding: "0 0.5em 0.5em" }}>
      <Typography>
        <h3>{lang[locale].VirtualPurchase}</h3>
      </Typography>
      <Grid
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Grid item xs={6}>
          <Typography>{lang[locale].offresid}</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            marginBottom: "1em",
          }}
        >
          <Typography>{props.zrid}</Typography>
        </Grid>
      </Grid>
      <Grid
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-betweens",
          alignItems: "center",
          marginBottom: "1em",
        }}
      >
        <Grid item xs={6}>
          <Typography>Name: </Typography>
        </Grid>
        <Grid item xs={6}>
          {props.name.length > 14 ? (
            <MTooltip title={props.name}>
              <Typography>{`${props.name.slice(0, 13)}...`}</Typography>
            </MTooltip>
          ) : (
            <Typography>{props.name}</Typography>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          marginBottom: "1em",
        }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === "de" ? deLocale : enLocale}>
        <KeyboardDatePicker
          helperText=""
          required
          variant="inline"
          format="dd.MM.yyyy"
          fullWidth
          minDate={props.orderFrom}
          maxDate={props.orderTo}
          onChange={(e) => buyDateHandler(e)}
          //   defaultValue={tommorow}
          value={buyDate !== "" ? buyDate : Date.now()}
          //   error={
          //     progFrom === null ||
          //     (differenceInDays(progFrom,tommorow) < 0 &&
          //       data.pricing_type?.toLowerCase().indexOf("spot") !== -1)
          //   }
          //   minDateMessage={
          //     "Das Datum darf nicht vor dem " +
          //     format(tommorow, "dd.MM.yyyy") +
          //     " liegen."
          id="buydate"
          data-testid="buydate"
          label={lang[locale].buyDate}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          autoOk={true}
          TextFieldComponent={TextFieldComponent}
        />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          marginBottom: "1em",
        }}
      >
        <TextField
          required
          value={SpecificPrice ? SpecificPrice : 0}
          id="specificPrice"
          label={`${lang[locale].specificPrice} [€/MWh]`}
          onFocus={selectHandler}
          onChange={(e) => {
            setspecificPrice(Number(e.target.value));
          }}
          InputProps={{
            inputComponent: TwoDecimalComponent,
          }}
          error={SpecificPrice === 0}
          style={{ width: 250 }}
        />
      </Grid>
      <Grid style={{display : "flex", flexDirection : "row"}}>
          {props.type === "Standardhandelsprodukt" && (<Grid
        item
        xs={6}
        style={{
          marginBottom: "1em",
        }}
      >
        <TextField
          style={{ maxWidth: 250 }}
          required
          value={Leistung > 0 ? Leistung : props.leistung}
          onChange={(e) =>{
            setLeistung(Number(e.target.value));
          } }
          id="specificPrice"
          label={`${lang[locale].performance} [kW]`}
          onFocus={selectHandler}
          // InputProps={{
          //   inputComponent: TwoDecimalComponent,
          // }}
        />
      </Grid>)}
      
      <Grid
        item
        xs={6}
        style={{
          marginBottom: "1em",
          marginLeft : "1rem"
        }}
      >
        <TextField
          style={{ maxWidth: 250 }}
          required
          value={Amount > 0 ? Amount : props.amount}
          onChange={(e) => setAmount(Number(e.target.value))}
          id="specificPrice"
          label={`${lang[locale].amount} [MWh]`}
          onFocus={selectHandler}
          InputProps={{
            inputComponent: TwoDecimalComponent,
          }}
          disabled={props.type === "Standardhandelsprodukt"}
        />
      </Grid>
      </Grid>
      <Grid
        container
        justify="flex-end"
        style={{ padding: 0, position: "relative" }}
      >
        <Grid style={{ padding: 0, position: "absolute" }}>
          <Button color="primary" onClick={handleClose}>
            {lang[locale].close}
          </Button>
          <Button color="primary" onClick={virtualkaufhandler} disabled={SpecificPrice === 0}>
            {lang[locale].save}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default VirtualPurchaseComponent;
