import de from "./de";
import en from "./en";
/**
 * Type for language
 */
interface Language {
    usernameOrPasswordWrong: string;
    loginButtonText: string;
    loginFormCompany: string;
    loginFormUsername: string;
    loginFormPassword: string;
    oldPassword: string;
    newPassword: string;
    repeatPassword: string;
    forgotPassword: string;
    resetPasswordText: string;
    resetPasswordEmailError: string;
    resetPasswordHeader: string;
    resetPasswordSubtitle: string;
    setNewPasswordSubtitle: string;
    back: string;
    toLogin: string;
    myplans: string;
    myprofile: string;
    logout: string;
    add: string;
    filters: string;
    startdate: string;
    enddate: string;
    nofilter: string;
    deletefilters: string;
    details: string;
    notLoggedIn: string;
    send: string;
    close: string;
    drop: string;
    dropHere: string;
    fileName: string;
    fplUpload: string;
    maxFileSizeExceeded: string;
    wrongFileType: string;
    uploadSuccess: string;
    priceFrom: string;
    amount: string;
    noPriceGiven: string;
    foreCast: string;
    loadcurve: string;
    gas: string;
    power: string;
    period: string;
    general: string;
    dateWrongError: string;
    notSelected: string;
    forecastType: string;
    profileType: string;
    performance: string;
    usage: string;
    productType: string;
    temperatureStation: string;
    usageType: string;
    holidayRegion: string;
    others: string;
    measured: string;
    specific: string;
    temperatureFinder: string;
    email: string;
    successFPL: string;
    next: string;
    errorNotEnoughPermissions: string;
    forecastSuccessfullyCreated: string;
    errorFieldsWrong: string;
    progUpload: string;
    istUpload: string;
    dateSelectError: string;
    actualCount: string;
    neededCount: string;
    timedifferenceValues: string;
    values: string;
    nullvalues: string;
    doubleHour: string;
    unit: string;
    startvalue: string;
    endvalue: string;
    energyAmount: string;
    valuesIncorrect: string;
    noProvided: string;
    startValueHigher: string;
    forecastPeriod: string;
    quarterHour: string;
    fullHour: string;
    valueAmount: string;
    export: string;
    noLoadcurves: string;
    noPendingLoadcurves: string;
    measuredPeriod: string;
    measuredStart: string;
    measuredEnd: string;
    variant: string;
    fplUploadMenuItem: string;
    inWork: string;
    name: string;
    passwordResetTriggerSuccess: string;
    passwordResetTriggerFailure: string;
    resetPassword: string;
    resetPasswordSuccess: string;
    resetPasswordError: string;
    reallyWantToDeleteWithName: string;
    deleteError: string;
    cancel: string;
    confirm: string;
    deleteSuccess: string;
    delete: string;
    priceCurve: string;
    priceCurveGetError: string;
    priceCurveChoose: string;
    pricingInformation: string;
    errorCreatingForecast: string;
    additionalInformation: string;
    prognosisCurve: string;
    resetGraphic: string;
    price: string;
    recentPrice: string;
    buy: string;
    buySingular: string;
    date: string;
    setBounds: string;
    upperBound: string;
    lowerBound: string;
    usedIstData: string;
    forecast: string;
    boughtPlan: string;
    buySuccess: string;
    buyFailure: string;
    buyToFollowingPrice: string;
    buyFullLoadcurve: string;
    buySelectSize: string;
    boughtAt: string;
    buypoint: string;
    showOrders: string;
    percentage: string;
    buyDate: string;
    dealticket: string;
    offersId: string;
    costSupplier: string;
    cost: string;
    noOrders: string;
    showTimeChangeDay: string;
    createPriceHistory: string;
    creationTime: string;
    shouldNotBeBeforeEndDate: string;
    shouldNotBeBeforeStartDate: string;
    search: string;
    priceCurveName: string;
    filterDateHelper: string;
    uploadExistingForecast: string;
    createForecast: string;
    dropProg: string;
    dropIst: string;
    priceCurveInformation: string;
    priceCurveID: string;
    priceCurveCreationDate: string;
    priceWatching: string;
    users: string;
    addCustomer: string;
    addUser: string;
    userUpdated: string;
    userCreated: string;
    firstName: string;
    lastName: string;
    isSupplier: string;
    inIndustrieCustomer: string;
    chooseCustomer: string;
    permissions: string;
    successCustomerUpdate: string;
    successCustomerCreation: string;
    errorCustomerCreation: string;
    customerName: string;
    customerId: string;
    supplierUser: string;
    supplier: string;
    customer: string;
    liquidity: string;
    maxProg: string;
    editCustomer: string;
    editUser: string;
    limitOrder: string;
    limitYear: string;
    limitMonth: string;
    parallelBuyWarning: string;
    flexTranche: string;
    allOrders: string;
    deleteUser: string;
    deleteCustomer: string;
    errorGeneric: string;
    deletePortfolio:string;
    TTPFM_si_insert: string;
    createCustomer_internal_error: string;
    createCustomer_missing_permissions: string;
    createCustomer_missing_relation: string;
    createCustomer_parse_data: string;
    createForecastForCustomer_missing_permissions: string;
    createForecastForCustomer_missing_relation: string;
    createForecastForCustomer_unknownFunction: string;
    createForecast_unknownFunction: string;
    createISTData_parse_data: string;
    createUser_customer_relation: string;
    createUser_internal_error: string;
    createUser_missing_permissions: string;
    createUser_missing_relation: string;
    createUser_parse_data: string;
    createUser_privilege_escalation: string;
    createUser_user_relation: string;
    createUser_user_already_exists: string;
    createUser_email_error: string;
    createUser_email_too_long: string;
    createUser_empty_data: string;
    createUser_invalid_email: string;
    createUser_name_too_long: string;
    createUser_name_too_short: string;
    customerData_internal_error: string;
    customerData_missing_permissions: string;
    customerData_missing_relation: string;
    customerOrders_missing_permissions: string;
    customerOrders_missing_relation: string;
    customer_missing_permissions: string;
    customer_missing_relation: string;
    customer_parse_data: string;
    customers_missing_permissions: string;
    deleteCustomer_missing_permissions: string;
    deleteCustomer_missing_relation: string;
    deleteUser_internal_error: string;
    deleteUser_missing_permissions: string;
    deleteUser_missing_relation: string;
    forecastCreation_internal_error: string;
    forecastCreation_invalid_IST_timeframe: string;
    forecastCreation_invalid_name: string;
    forecastCreation_invalid_prog_timeframe: string;
    forecastCreation_missing_permissions: string;
    forecastCreation_parse_data: string;
    forecastCreation_parsing_pricing_curve: string;
    forecastCreation_pricing_curve_empty: string;
    forecastCreation_pricing_curve_not_found: string;
    forecastCreation_progcount_limit: string;
    supplierData_missing_permissions: string;
    supplierData_missing_relation: string;
    supplierUsers_missing_permissions: string;
    supplierUsers_missing_relation: string;
    updateCustomerInfo_internal_error: string;
    updateCustomerInfo_missing_permissions: string;
    updateCustomerInfo_missing_relation: string;
    updateCustomerInfo_parse_data: string;
    updatePermissions_internal_error: string;
    updatePermissions_missing_permissions: string;
    updatePermissions_missing_relation: string;
    updatePermissions_parse_data: string;
    upload_internal_error: string;
    upload_unsupported_media_type: string;
    users_missing_permissions: string;
    users_missing_relation: string;
    orderFPL_TAN_expired: string;
    orderFPL_no_TAN: string;
    orderFPL_yearlimit: string;
    orderFPL_monthlimit: string;
    orderFPL_orderlimit: string;
    orderFPL_unknown_ttpfm_error: string;
    orderFPL_bad_amount: string;
    orderFPL_bad_timeframe: string;
    orderFPL_internal_error: string;
    orderFPL_missing_permissions: string;
    orderFPL_missing_relation: string;
    orderFPL_missing_TAN: string;
    orderFPL_ordered_recently: string;
    orderFPL_parse_data: string;
    orderFPL_TAN_invalid: string;
    creator: string;
    buyer: string;
    nameTooShortFirst: string;
    nameTooShortSecond: string;
    nameTooLongFirst: string;
    nameTooLongSecond: string;
    wrongCharacters: string;
    invalidDate: string;
    usageTooSmallYear: string;
    usageTooSmallDay: string;
    usageTooBigYear: string;
    usageTooBigDay: string;
    usageTooSmallMeasured: string;
    usageTooBigMeasured: string;
    usageSecond: string;
    performanceTooSmall: string;
    performanceTooBig: string;
    toDateTooBig: string;
    fromDateTooSmall: string;
    selectCustomer: string;
    biggerThanMaxDate: string;
    biggerThanMaxDateTest: string;
    smallerThanMinDate: string;
    smallerThanMinDateTest: string;
    progcount: string;
    password: string;
    invalidEmail: string;
    passwordMatch: string;
    changePassword: string;
    changeEmail: string;
    changeName: string;
    passwordDifferent: string;
    newPasswordSuccess: string;
    newEmailSuccess: string;
    newNameSuccess: string;
    newChargesSuccess: string;
    confirmation: string;
    finalBuy: string;
    specificPrice: string;
    percentageForecast: string;
    pleaseEnterTan: string;
    remainingTime: string;
    confirmationStatus: string;
    perBuy: string;
    perMonth: string;
    perYear: string;
    fourEyesPrinciple: string;
    surchargePowerPrice: string;
    surchargeGasPrice: string;
    surchargePowerSpot: string;
    surchargeGasSpot: string;
    surchargePowerFormel: string;
    surchargeGasFormel: string;
    surcharges: string;
    prognosisCustomer: string;
    endCustomer: string;
    industrialCustomer: string;
    buyHelperText: string;
    buyPrognosis: string;
    purchaseLimitExhausted: string;
    purchaseLimitExceededOne: string;
    purchaseLimitExceededTwo: string;
    purchaseCannotBeBought: string;
    globalStromF: string;
    globalStromSpotF: string;
    globalStromSpotE: string;
    globalStromE: string;
    globalGasF: string;
    globalGasE: string;
    globalGasSpotE: string;
    globalGasSpotF: string;
    globalCharges: string;
    charge: string;
    missing_permissions: string;
    internal_error: string;
    confirmOrder_bad_token: string;
    confirmOrder_invalid_token: string;
    createCustomer_customer_exists: string;
    createCustomer_customer_type_invalid: string;
    createCustomer_name_too_long: string;

    deleteFPL_missing_relation: string;
    deleteFPL_order_reference: string;
    forecastCreation_deceed_usage_limit: string;
    forecastCreation_exceed_usage_limit: string;
    generateTAN_confirmation_emails_conflict: string;
    generateTAN_confirmation_emails_missing: string;
    generateTAN_exceed_limit: string;
    generateTAN_invalid_data: string;
    generateTAN_invalid_percentage: string;
    generateTAN_parse_data: string;
    generateTAN_price_changed: string;
    generateTAN_price_invalid: string;
    generateTAN_send_email_error: string;
    generateTAN_send_confirmation_emails: string;
    generateTAN_ordered_recently: string;
    getCSV_missing_relation: string;
    getFPLHeadData_missing_relation: string;
    getFPL_missing_relation: string;
    getForecastInformationByName_missing_relation: string;
    getForecastInformationByName_parse_data: string;
    getForecastInformation_missing_relation: string;
    getFullFPLData_missing_relation: string;
    getHistoryData_missing_relation: string;
    getIntradayData_missing_relation: string;
    getLatestUserData_invalid_timestamp: string;
    getOrderStatus_missing_relation: string;
    getPriceBounds_missing_relation: string;
    getPriceBounds_parse_data: string;
    login_failed: string;
    login_parse_data: string;
    orderFPL_pending_confirmation: string;
    orderFPL_send_email_error: string;
    pricing_missing_relation: string;
    resetPassswordRequest_email_invalid: string;
    resetPasswordRequest_missing_email: string;
    resetPassword_password_empty: string;
    resetPassword_password_missing_special_chars: string;
    resetPassword_password_short: string;
    resetPassword_signature_expired: string;
    resetPassword_signature_invalid: string;
    resetPassword_token_invalid: string;
    setPriceBound_missing_relation: string;
    setPriceBounds_lower_greater_upper: string;
    setPriceBounds_parse_data: string;
    setStatus_missing_relation: string;
    switchPricing_parse_data: string;
    updateCustomerCharge_bad_data: string;
    updateCustomerCharge_missing_relation: string;
    updatePermissions_privilege_escalation: string;
    updateSupplierCharge_bad_data: string;
    updateSupplierCharge_missing_relation: string;
    updateUser_current_password_mismatch: string;
    updateUser_email_exists: string;
    updateUser_email_too_long: string;
    updateUser_empty_data: string;
    updateUser_invalid_email: string;
    updateUser_missing_current_password: string;
    updateUser_name_too_long: string;
    updateUser_name_too_short: string;
    updateUser_parse_data: string;
    updateUser_password_missing_special_chars: string;
    updateUser_password_too_short: string;
    totalPrice: string;
    totalCosts: string;
    margin: string;
    filterMonth: string;
    contains: string;
    notContains: string;
    startsWith: string;
    endsWith: string;
    equals: string;
    doesNotEqual: string;
    greaterThan: string;
    greaterThanOrEqual: string;
    lessThan: string;
    lessThanOrEqual: string;
    noData: string;
    totalRowsSelected: string;
    count: string;
    weightedAverage: string;
    sum: string;
    groupByColumn: string;
    exportData: string;
    customers: string;
    permissionsInfo: string;
    questionsAndAnswers: string;
    onlineSupport: string;
    marketreport: string,
    customerSupport: string;
    minimum: string;
    maximum: string;
    characters: string;
    uppercaseCharacter: string;
    lowercaseCharacter: string;
    numberCharacter: string;
    specialCharacter: string;
    prognosisInProcess: string;
    prognosisImportError: string;
    pendingCurves: string;
    offresid:string;
    pending: string;
    twoFA: string;
    scanQR: string;
    authenticating: string;
    inputPin: string;
    tfa_missing_token: string;
    tfa_token_mismatch: string;
    tfa_bad_data: string;
    tfa_not_found: string;
    tfa_cannot_overwrite: string;
    explanation2FA: string;
    activateWithPin: string;
    tfaActivated: string;
    tfaDeactivated: string;
    activate: string;
    deactivate: string;
    confirmedSuccess: string;
    finishedCurves: string;
    edit: string;
    userlist: string;
    save: string;
    resetTime: string;
    sessionExpired: string;
    addcustomertosupplier: string;
    addusertocustomer: string;
    addnewsupplier: string;
    globalStromI: string;
    globalGasI: string;
    alreadybought?: string;
    stillFree?: string;
    customerwithpurchazedproduct: string,
    customerwithoutpurchazedproduct: string,
    copyPastePassVerbote: string,
    chargeOnlyWithClick: string,
    powerPermission: string,
    gasPermission: string,
    tradingPermission: string,
    priceBoundsPermission: string,
    startValueHint: string,
    yearlyUsage: string,
    daily: string,
    totalCostsWithOutUmlaut: string,
    buyerWithOutUmlaut: string,
    confirmationWithOutUmlaut: string,
    morethanAllowed: string,
    energyprocurementprice: string,
    zoominout: string,
    RowPerPage:string;
    foureyewarning: string,
    foureyewarningMessage: string,
    priceMonitoring : string,
    history: string,
    intraday:string,
    addToPfm:string,
    portfolioManagement:string,
    login_expired:string,
    time:string,
    noIntraday: string,
    noOfTranches:string,
    from:string,
    selectPortfolio:string,
    processing:string,
    generateTAN_process_in_progress:string;
    casenumber:string;
    noSpotPrice:string;
    expireDate: string;
    unlimited: string;
    BolligerBandLow: string;
    BolligerBandUp: string;
    pricecurvefirst: string;
    rlmcommodityconflict: string;
    fleximportcommodityconflict: string;
    purchases:string;
    formula: string;
    pricetype: string;
    pricecurve: string;
    fixedprice:string;
    singlePurchases: string;
    SumOfAllPurchases: string;
    purchaseOn:string;
    Dprice : string;
    procurement :string;
    procured: string;
    total:string;
    noPortfolioMessage: string;
    noPortfolioDetailMessage : string;
    addSuccessToPfm:string;
    removeSuccessFromPfm:string;
    openPosition:string;
    type: string;
    offerSurcharge: string;
    offerSurchargeText : string;
    zeroTrancheWarning : string;
    pricedevelopment: string;
    rlmcommodityconflictStunde: string;
    actualData: string;
    offerName: string;
    VirtualPurchase: string;
    purchasePlusOP:string;
    dailyTrading:string;
    weekly: string;
    monthly:string;
    automaticTrancheProcurement: string;
    Atp: string;
    Mtp: string;
    Dp: string;
    Mp: string;
    acceptTheOffer: string;
    acceptTheOfferMessage: string;
    maxmoreTranchenOrder: string;
    acceptFrom : string
    acceptTo : string;
    ManagementPeriod : string;
    procurementCalendar: string;
    procurementCal: string;
    TypeOfTrancheProcurement: string;
    buyTimeLimit: string;
    deliveryPeriod : string;
    OfferOpenUntil : string;
    OfferWasAccepted : string;
    OfferExpiredOn : string;
    selectAll : string;
    deselectAll : string;
    TakingOverFrom : string;
    SuccessfulTimeChange : string;
    DeleteTimeDay : string;
    monday: string;
    sunday : string;
    saturday : string;
    friday : string;
    wednesday : string;
    tuesday : string;
    thursday : string;
    buyTime : string;
    buyTimeDeleteConfirm : string;
    infoOscillatorsIndicators: string;
    priceHistory: string;
    prices: string;
    colorSettings: string;
    removeOrderFromOrders: string;
    maxForecastCount: string[];
    hedgechannelVerlassMeldung: string[];
    addtoportfolio: string[];
    GeneralVerification: string;
    twofa_token_mismatch: string;
    twofa_muss_aktiv: string;
    generateTAN_no_trading_day : string;
    verified: string;
    userCount: string;
    userByuserCreated: string;
    TotalManagementPeriod: string;
    totalDeliveryPeriod: string;
    delayedPurchase: string;
    delayedPurchaseConfirmation: string;
    tooOldPrice: string;
    invalidUserCount: string;
    static: string;
    dynamic:string;
    csvExport:string;
    dailyusage:string;
    endeOptions:string;
    immediatelyAfterwards: string;
    onSpecificTradingDay: string;
    aboutSpot:string;
    MaxFileSize: string;
    Secondary:string;
    Primary:string;
    gradient: string;
    mondayStart: string;
    sundayStart : string;
    saturdayStart : string;
    fridayStart : string;
    wednesdayStart : string;
    tuesdayStart : string;
    thursdayStart : string;
    mondayEnd: string;
    sundayEnd : string;
    saturdayEnd : string;
    fridayEnd : string;
    wednesdayEnd : string;
    tuesdayEnd : string;
    thursdayEnd : string;
    newCustomer: string;
    Disabled: string;
    Activated: string;
    Activeoffers: string;
    Inactiveoffers: string;
    Deletedoffers: string;
    OffersInPreparation: string;
    MeasuredConsumption: string;
    TrancheQuantity: string;
    BuyAtCurrentPrice : string;
    NoManualPurchasePossible: string;
    BuyAtClosingPrice:string;
    EndOfOffer:string;
    automatik:string;
    product: string;
    Year:string;
    AssignOverallFormula: string;
    OverallFormula: string;
    AddFormula : string;
    factor: string;
    AdditiveConstant: string;
    SelectFormulaTerm: string;
}
export const lang = {
    de,
    en,
};
export default Language;
