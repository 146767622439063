import { priceConstants } from "../constants/price.constants";
import PriceService from "../services/price/PriceService";
import { useHistory } from "react-router-dom";

const service = new PriceService();

/**
 * Default export with all functions
 */
const priceActions = {
    getPriceBounds,
    setPriceBounds,
    togglePriceBound,
};

/**
 * Action for setting price bounds
 * @param zrid zrid of loadcurve
 */
/* let history = useHistory(); */
function getPriceBounds(zrid: number) {
    return (dispatch) => {
        dispatch(request());
        //get from service
        return service
            .getPriceBounds(zrid)
            .then((res) => {
                //if everyting ok, just success it
                dispatch(success(res.upper, res.lower, res.status === 1));
            })
            .catch((e) => {
                /* if (e.message === "401") {
                    return history.push(`/logout`);
                } */
                console.error(e);
                dispatch(failure(e.message));
            });
    };

    function request() {
        return {
            type: priceConstants.GET_BOUNDS_REQUEST,
        };
    }
    function success(upper: number, lower: number, active) {
        return {
            type: priceConstants.GET_BOUNDS_SUCCESS,
            upper,
            lower,
            active,
        };
    }
    function failure(error: Error) {
        return {
            type: priceConstants.GET_BOUNDS_FAILURE,
            error,
        };
    }
}

/**
 * Sets price bounds for certain loadcurve
 * @param zrid
 * @param upper
 * @param lower
 */
function setPriceBounds(zrid: number, upper: number, lower: number) {
    return (dispatch) => {
        dispatch(request());
        //get from service
        return service
            .setPriceBounds(zrid, upper, lower)
            .then((res) => {
                //if everyting ok, just success it
                dispatch(success(upper, lower));
            })
            .catch((e) => {
                /* if (e.message === "401") {
                    return history.push(`/logout`);
                } */
                dispatch(failure(new Error(e.message)));
            });
    };

    function request() {
        return {
            type: priceConstants.SAVE_BOUNDS_REQUEST,
        };
    }
    function success(upper: number, lower: number) {
        return {
            type: priceConstants.SAVE_BOUNDS_SUCCESS,
            upper,
            lower,
        };
    }
    function failure(error: Error) {
        return {
            type: priceConstants.SAVE_BOUNDS_FAILURE,
            error,
        };
    }
}

/**
 * Toggles wether the price bounds are active
 * @param zrid
 * @param active
 */
function togglePriceBound(zrid: number, active: boolean) {
    return (dispatch) => {
        dispatch(request());
        //get from service
        return service
            .toggleActive(zrid, active)
            .then((res) => {
                //if everyting ok, just success it
                dispatch(success(active));
            })
            .catch((e) => {
                /* if (e.message === "401") {
                    return history.push(`/logout`);
                } */
                dispatch(failure(new Error(e.message)));
            });
    };

    function request() {
        return {
            type: priceConstants.TOGGLE_ACTIVE_REQUEST,
        };
    }
    function success(active: boolean) {
        return {
            type: priceConstants.TOGGLE_ACTIVE_SUCCESS,
            active,
        };
    }
    function failure(error: Error) {
        return {
            type: priceConstants.TOGGLE_ACTIVE_FAILURE,
            error,
        };
    }
}

export default priceActions;
