import React, { useEffect } from "react";
import {
    Grid,
    TextField,
    Button,
    Typography,
    CircularProgress,
    Link as VisualLink,
} from "@material-ui/core";
import ClaimsModel from "typeDefs/ClaimsModel";
import { lang } from "lang";
import { Link } from "react-router-dom";
import { useSelector } from "services/helpers/useSelector";
import { useDispatch } from "react-redux";
import authActions from "actions/auth.actions";
import snackbarActions from "actions/snackbar.actions";
import { useLocation } from "react-router";
import { emailRegex } from "services/helpers/Regex";

/**
 * Component which has the form for logging the user in
 */
function LoginFormComponent(props:any) {
    const locale = props.locale;
    // neccessary state
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    // state from redux (error)
    const errorFromState = useSelector((state) => state.auth.error);
    const loading = useSelector((state) => state.auth.loading);
    const dispatch = useDispatch();

    /*Parsing the query params*/
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let confirmed = useQuery().get("confirmed");
    // console.log("confirmed", confirmed);

    function onSubmit(e) {
        e.preventDefault();
        const user: ClaimsModel = {
            email,
            password,
        };
        dispatch(authActions.login(user));
    }

    // checks if anything is missing for submission
    function checkDisabled() {
        return email === "" || password === "" || !emailRegex.test(email) || email.length > 50 || password.length > 50;
    }

    // console.log("Error", error);

    useEffect(() => {
        confirmed &&
            dispatch(
                snackbarActions.openSnackbar(
                    lang[locale].confirmedSuccess,
                    "success"
                )
            );
    }, []);

    return (
        <>
            <form onSubmit={onSubmit}>
                <Grid
                    container
                    direction="column"
                    spacing={4}
                    data-testid="loginform"
                >
                    {errorFromState ? (
                        <Grid item xs={12}>
                            <Typography color="error">{errorFromState}</Typography>
                        </Grid>
                    ) : (
                        ""
                    )}
                    <Grid item xs={12}>
                        <Typography variant="h5">Login</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="email"
                            label={lang[locale].email}
                            variant="outlined"
                            inputProps={{
                                "data-testid": "email",
                            }}
                            className="maximalWidth"
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            required
                            error={email.length > 0 && !emailRegex.test(email)}
                            helperText={email.length > 50 && lang[locale].morethanAllowed}
                            autoComplete="username"
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="password"
                            label={lang[locale].loginFormPassword}
                            variant="outlined"
                            inputProps={{
                                "data-testid": "password",
                            }}
                            type="password"
                            className="maximalWidth"
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            required
                            error={password.length > 50}
                            helperText={password.length > 50 && lang[locale].morethanAllowed}
                            autoComplete="current-password"
                            />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container justify="flex-end">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                data-testid="submit"
                                disabled={checkDisabled() || loading}
                            >
                                {loading ? (
                                    <CircularProgress size={30} />
                                ) : (
                                    <>{lang[locale].loginButtonText}</>
                                )}
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container justify="center">
                            <VisualLink component={Link} to="/auth/reset">
                                <Typography>
                                    {lang[locale].forgotPassword}
                                </Typography>
                            </VisualLink>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

export default LoginFormComponent;
