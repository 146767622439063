import { customerConstants } from "constants/customer.constants";
import { AnyAction } from "redux";

export interface customerReducerInitialStateType {
    customerData: {
        c_limit_year?: number;
        charge: [];
        current_count?: number;
        id?: number;
        kunde_nr: string;
        lieferanten_id?: number;
        limit_month?: number;
        limit_order?: number;
        limit_year?: number;
        name?: string;
        prognose_count?: number;
        status?: number;
        type?: string;
    };
    customers: Array<Object>;
}

let initialState: customerReducerInitialStateType = {
    customerData: {
        c_limit_year: undefined,
        charge: [],
        current_count: undefined,
        id: undefined,
        kunde_nr: "",
        lieferanten_id: undefined,
        limit_month: undefined,
        limit_order: undefined,
        limit_year: undefined,
        name: undefined,
        prognose_count: undefined,
        status: undefined,
        type: undefined,
    },
    customers: [],
};

export function customer(
    state: customerReducerInitialStateType = initialState,
    action: AnyAction
) {
    switch (action.type) {
        case customerConstants.GET_CUSTOMER:
            return { ...state.customerData };
        case customerConstants.UPDATE_CUSTOMER:
            return {
                ...state,
                customerData: action.customerData,
            };
        case customerConstants.UPDATE_ALL_CUSTOMERS:
            return {
                ...state,
                customers: action.customers,
            };
        case customerConstants.GET_ALL_CUSTOMERS:
            return {
                ...state.customers,
            };
        case customerConstants.DELETE_SINGLE_CUSTOMER:
            let newCustomers = [...state.customers];
            newCustomers = newCustomers.filter(
                // @ts-ignore
                (c) => c.id !== Number(action.customer)
            );
            return {
                customers: newCustomers,
            };
        case customerConstants.UPDATE_SINGLE_CUSTOMER:
            const customers = [...state.customers];
            const index = customers.findIndex(
                // @ts-ignore
                (c) => c.id === Number(action.customer.id)
            );
            customers[index] = action.customer;
            return {
                ...state,
                customers,
            };
        default:
            return state;
    }
}
