import React, { useEffect } from "react";
import ForecastModel from "../typeDefs/ForecastModel";
import { useSelector } from "../services/helpers/useSelector";
import { useDispatch } from "react-redux";
import forecastActions from "../actions/forecast.actions";
import {
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import { lang } from "../lang";
import LoadcurveService from "../services/loadcurves/LoadcurveService";
import snackbarActions from "../actions/snackbar.actions";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";

const loadcurveService = new LoadcurveService();

/**
 * Select forecast type component
 * @constructor
 */
export function SelectForecastType() {
    const location = useLocation();
    const pathName = location.pathname?.split("/")[2];
    const [forecastTypes, setForecastTypes] = React.useState<
        ForecastModel[] | undefined
    >(undefined);
    const data = useSelector((state) => state.forecast.data);
    const dispatch = useDispatch();
    const locale = localStorage.locale;
    let history = useHistory();

    // console.log("forecastTypes", forecastTypes);

    useEffect(() => {
        const type = data.gas ? "gas" : "power";
        loadcurveService
            .getForecastTypes(type)
            .then((res) => {
                setForecastTypes(res);
            })
            .catch((e) => {
                if (e.message === "401") {
                    return history.push(`/logout`);
                }
                console.error(e);
                dispatch(snackbarActions.openSnackbar(e.message, "error"));
            });
    }, [data.gas]);

    /**
     * Saves the forecastType in state
     * @param e
     */
    function handleChange(e) {
        // console.log("forecastType", e.target.value);
        dispatch(
            forecastActions.saveForecastSingle("forecastType", e.target.value)
        );
        dispatch(
            forecastActions.saveForecastSingle("profile", 0)
        );
        dispatch(
            forecastActions.saveForecastSingle("holidayRegion", "")
        );
        dispatch(forecastActions.saveForecastSingle("usage", 0));
        dispatch(forecastActions.saveForecastSingle("temperatureStation", ""));
        dispatch(forecastActions.saveForecastSingle("usageType", 0));
        dispatch(forecastActions.saveForecastSingle("performance", 0));
    }

    function Translate(bez){
       return (locale !== "de" && bez === "Standardhandelsprodukt") ? "Standard trading product" : bez 
    }
    
    return (
        <>
            {forecastTypes === undefined ? (
                <CircularProgress data-testid="circular" />
            ) : (
                <FormControl
                    fullWidth={true}
                    error={data.forecastType === 0 || data.forecastType === "0"}
                >
                    <InputLabel id="forecastType">
                        {lang[locale].forecastType}
                    </InputLabel>
                    <Select
                        labelId="forecastType"
                        id="forecastSelect"
                        value={data.forecastType ? data.forecastType : ''}
                        onChange={(e)=>handleChange(e)}
                    >
                        {/* <MenuItem value="0">
                            {lang[locale].notSelected}
                        </MenuItem> */}
                        {forecastTypes.map((forecast) => (
                            <MenuItem
                                key={forecast.id}
                                data-testid="type"
                                value={forecast.id}
                                //preisentwicklung under construction
                                // disabled={pathName === "pricedevelopment" && forecast.bez === "Strom-RLM"}
                            >
                                {Translate(forecast.bez)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </>
    );
}
