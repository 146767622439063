import { userConstants } from "constants/user.constants";
import AuthService from "services/auth/AuthService";

function update(firstName: string, lastName: string) {
    return { type: userConstants.UPDATE_USER, firstName, lastName };
}

function get() {
    return { type: userConstants.GET_USER };
}

const userActions = {
    get,
    update,
};

export default userActions;
