import React, { useState } from "react";
import {
    Button,
    CircularProgress,
    CssBaseline,
    Grid,
    TextField,
    Typography,
    ListItemText,
} from "@material-ui/core";
import { lang } from "../lang";
import { Link, useParams, Redirect } from "react-router-dom";
import AuthService from "../services/auth/AuthService";
import { useDispatch } from "react-redux";
import snackbarActions from "../actions/snackbar.actions";
import { useSelector } from "../services/helpers/useSelector";

/** TO-DO:
 * 1. Refactoring SetNewPasswordProfile and SetNewPassword
 */

const service = new AuthService();
function SetNewPasswordComponent({ locale }) {
    const [loading, setLoading] = React.useState(false);
    const [newPassword, setNewPassword] = React.useState("");
    const [repeatPassword, setRepeatPassword] = React.useState("");
    const [redirect, setRedirect] = useState(false);
    const { min_password_length, max_password_length } = useSelector(
        (state) => state.constants
    );
    const dispatch = useDispatch();
    const { key } : any = useParams();

    /*  Gets the username out of the Base64 token  */
    const username = JSON.parse(atob(key.split(".")[1]))["Email"];

    function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        //reset password on api
        service
            .resetPassword(newPassword, key)
            .then(() => {
                setLoading(false);
                setRedirect(true);
                dispatch(
                    snackbarActions.openSnackbar(
                        lang[locale].resetPasswordSuccess,
                        "success"
                    )
                );
            })
            .catch((e) => {
                setLoading(false);
                console.error(e);
                dispatch(snackbarActions.openSnackbar(e.message, "error"));
            });
    }

    function checkDisabled() {
        return (
            newPassword !== repeatPassword ||
            newPassword === "" ||
            repeatPassword === "" || newPassword.length > 50 || repeatPassword.length > 50
        );
    }

    function checkNewPassword(): string[] {
        const check: string[] = [];
        if (newPassword.length < min_password_length) {
            check.push(
                lang[locale].minimum +
                    min_password_length +
                    lang[locale].characters
            );
        }
        if (newPassword.length > max_password_length) {
            check.push(
                lang[locale].maximum +
                    max_password_length +
                    lang[locale].characters
            );
        }
        if (!newPassword.match(/\d/)) {
            check.push(lang[locale].numberCharacter);
        }
        if (!newPassword.match(/[a-z]/)) {
            check.push(lang[locale].lowercaseCharacter);
        }
        if (!newPassword.match(/[A-Z]/)) {
            check.push(lang[locale].uppercaseCharacter);
        }
        if (!newPassword.match(/[^a-zA-Z\d]/)) {
            check.push(lang[locale].specialCharacter);
        }
        return check;
    }

    const check = checkNewPassword();

    function checkRepeatPassword(): string[] {
        const check: string[] = [];

        if (repeatPassword !== newPassword) {
            check.push(lang[locale].passwordMatch);
        }
        return check;
    }

    const checkRepeat = checkRepeatPassword();

    return (
        <>
            <CssBaseline />
            {redirect ? (
                <Redirect to="/app/login" />
            ) : (
                <form onSubmit={handleSubmit}>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                {lang[locale].resetPassword}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                variant="subtitle2"
                                color="inherit"
                                gutterBottom
                            >
                                {lang[locale].setNewPasswordSubtitle}
                            </Typography>

                            <ul>
                                <li>
                                    <Typography variant="subtitle2">
                                        {lang[locale].minCharacter}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2">
                                        {lang[locale].uppercaseCharacter}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2">
                                        {lang[locale].lowercaseCharacter}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2">
                                        {lang[locale].numberCharacter}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="subtitle2">
                                        {lang[locale].specialCharacter}
                                    </Typography>
                                </li>
                            </ul>
                        </Grid>
                        <TextField
                            autoComplete="username"
                            id="username"
                            name="username"
                            type="hidden"
                            value={{ username }}
                        />
                        <Grid item xs={12}>
                            <TextField
                                // onPaste={(e)=>{
                                // e.preventDefault()
                                // return false;
                                // }} onCopy={(e)=>{
                                // e.preventDefault()
                                // return false;}}
                                autoComplete="new-password"
                                id="password"
                                label={lang[locale].loginFormPassword}
                                className="maximalWidth"
                                type="password"
                                inputProps={{
                                    "data-testid": "password",
                                }}
                                variant="outlined"
                                onChange={(e) => {
                                    setNewPassword(e.target.value);
                                }}
                                required
                                error={newPassword.length > 50}
                                helperText={newPassword.length > 50 && lang[locale].morethanAllowed}
                            />
                        </Grid>
                        {newPassword && check.length !== 0 && (
                            <Grid item>
                                {check.map((entry) => (
                                    <ListItemText>
                                        <Typography
                                            variant="caption"
                                            color="error"
                                            data-testid="date-error"
                                        >
                                            {entry}
                                        </Typography>
                                    </ListItemText>
                                ))}
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                // onPaste={(e)=>{
                                // e.preventDefault()
                                // return false;
                                // }} onCopy={(e)=>{
                                // e.preventDefault()
                                // return false;}}
                                autoComplete="new-password"
                                id="repeatPassword"
                                label={lang[locale].repeatPassword}
                                className="maximalWidth"
                                type="Password"
                                inputProps={{
                                    "data-testid": "repeatPassword",
                                }}
                                variant="outlined"
                                onChange={(e) => {
                                    setRepeatPassword(e.target.value);
                                }}
                                required
                                error={repeatPassword.length > 50}
                                helperText={repeatPassword.length > 50 && lang[locale].morethanAllowed}
                            />
                        </Grid>
                        {repeatPassword && checkRepeat.length !== 0 && (
                            <Grid item>
                                {checkRepeat.map((entry) => (
                                    <Typography
                                        variant="caption"
                                        color="error"
                                        data-testid="date-error"
                                    >
                                        {entry}
                                    </Typography>
                                ))}
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Grid
                                        container
                                        justify="flex-start"
                                        alignItems="center"
                                    >
                                        <Grid item>
                                            <Button
                                                data-testid="backButton"
                                                color="primary"
                                                component={Link}
                                                to="/auth/login"
                                            >
                                                {lang[locale].toLogin}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container justify="flex-end">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            /* onClick={handleSubmit} */
                                            type="submit"
                                            data-testid="submitReset"
                                            disabled={checkDisabled()}
                                        >
                                            {loading ? (
                                                <CircularProgress size={30} />
                                            ) : (
                                                lang[locale].send
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )}
        </>
    );
}

export default SetNewPasswordComponent;
