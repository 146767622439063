/**
 * Management of users and customers
 */
import UserModel from "../../typeDefs/UserModel";
import CustomerModel from "../../typeDefs/CustomerModel";
import { config } from "../helpers/Constants";
import Permission from "../../typeDefs/Permission";
import OrderModel from "../../typeDefs/OrderModel";
import errorCodeTranslator from "../helpers/errorCodeTranslator";

class UserService {
    /**
     * Gets all users of one customer
     * @param id
     */
    async getUsersByCustomerId(id: number): Promise<UserModel[]> {
        const res = await fetch(
            config.url.API_URL + "/api/admin/" + id + "/users"
        );
        
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status === 204) {
            return [];
        }
        const json = await res.json();
        if (res.status !== 200 && res.status !== 204) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Gets all users of current supplier
     */
    async getUsersFromSupplier(): Promise<UserModel[]> {
        const res = await fetch(
            config.url.API_URL + "/api/admin/supplier/users"
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }
    /**
     * Gets all customers from api
     */
    async getCustomers(): Promise<CustomerModel[]> {
        const res = await fetch(config.url.API_URL + "/api/admin/customers");
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        
        const json = await res.json();
        if (res.status === 204) {
            return [];
        }
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Gets all customer emails
     */

     async getCustomersEmails(): Promise<any> {
        const res = await fetch(config.url.API_URL + "/api/customer/emails");
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status === 204) {
            return [];
        }
        if (res.status !== 200) {
            throw { message: res.status.toString() };
        }
        return json;
    }

    async getCustomer(): Promise<CustomerModel> {
        const res = await fetch(config.url.API_URL + "/api/customer/info");
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        
        if (res.status !== 200) {

            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    async getKWStatus(): Promise<any> {
        const res = await fetch(config.url.API_URL + "/api/report_status");
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        
        if (res.status !== 200) {

            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Checks if the buy is confirmed
     */

    async getStatus(zrid: number): Promise<any> {
        const res = await fetch(
            config.url.API_URL + "/api/order/status/" + zrid
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status === 204) {
            return [];
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Gets all permissions
     */
    async getPermissions(): Promise<Permission[]> {
        const res = await fetch(config.url.API_URL + "/api/admin/permissions");
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Creates user ind b
     * @param user
     */
    async createUser(user: UserModel): Promise<void> {
        const res = await fetch(config.url.API_URL + "/api/admin/create/user", {
            method: "POST",
            body: JSON.stringify(user),
        });
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status !== 200) {
            const json = await res.json();
            throw { message: errorCodeTranslator(json.message) };
        }
    }

    async createUserByUser(user: UserModel): Promise<void> {
        const res = await fetch(config.url.API_URL + "/api/customer/create/user", {
            method: "POST",
            body: JSON.stringify(user),
        });
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status !== 200) {
            const json = await res.json();
            throw { message: errorCodeTranslator(json.message) };
        }
    }

    /**
     * Creates customer in db
     * @param cust
     */
    async createCustomer(cust: CustomerModel) {
        const res = await fetch(
            config.url.API_URL + "/api/admin/create/customer",
            {
                method: "POST",
                body: JSON.stringify(cust),
            }
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status !== 200) {
            const json = await res.json();
            throw { message: errorCodeTranslator(json.message) };
        }
    }

    /**
     * Gets user by certain id
     * @param id
     */
    async getUserById(id: number): Promise<UserModel> {
        const res = await fetch(
            config.url.API_URL + "/api/admin/userpermissions/" + id
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }

    /**
     * Updates user in db
     * @param editUser
     */
    async updateUser(user): Promise<void> {
        
        const res = await fetch(
            config.url.API_URL + "/api/admin/update/permissions",
            {
                method: "POST",
                body: JSON.stringify(user),
            }
        );
        
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status !== 200) {
            const json = await res.json();
            throw { message: errorCodeTranslator(json.message) };
        }

        const json = await res.json();
        
        return json;
    }




    async getSupplierBuyTimes(): Promise<any> {
        const res = await fetch(
            config.url.API_URL + "/api/admin/supplier/order/times"
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
        return json;
    }


    async updateSupplierBuyTimes(Times): Promise<void> {
        
        const res = await fetch(
            config.url.API_URL + "/api/admin/supplier/order/times",
            {
                method: "POST",
                body: JSON.stringify(Times),
            }
        );
        
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }

        
        
        return json;
    }

    async deleteSupplierBuyTimeDay(day: number) {
        const res = await fetch(
            config.url.API_URL + "/api/admin/supplier/order/time/" + day,
            {
                method: "DELETE",
            }
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status !== 200) {
            const json = await res.json();
            throw { message: errorCodeTranslator(json.message) };
        }
    }

    /**
     * Updates customer
     * @param subObj
     */
    async updateCustomer(subObj: {
        id: any;
        prognose_count: number;
        limit_order: number;
        limit_year: number;
        limit_month: number;
        status: number;
        user_count:number;
        name:string;
        // power_charge: number;
        // gas_charge: number;
        charge:[];
        permissions: [];
        expire: Date;
    }): Promise<void> {
        const res = await fetch(
            config.url.API_URL + "/api/admin/update/customerinfo",
            {
                method: "POST",
                body: JSON.stringify({
                    id: subObj.id,
                    prognose_count: subObj.prognose_count,
                    limit_year: subObj.limit_year,
                    limit_order: subObj.limit_order,
                    limit_month: subObj.limit_month,
                    status: subObj.status,
                    user_count:subObj.user_count,
                    name:subObj.name,
                    // power_charge: subObj.power_charge,
                    // gas_charge: subObj.gas_charge,
                    charge: subObj.charge,
                    permissions: subObj.permissions,
                    expire: subObj.expire,
                }),
            }
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
    }

    /**
     * Updates customer
     * @param subObj
     */
    async updateCustomerCharges(chargeObj): Promise<void> {
        const res = await fetch(
            config.url.API_URL + "/api/admin/update/customer/charge",
            {
                method: "PATCH",
                body: JSON.stringify(chargeObj),
            }
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }
    }

    async updateSupplierCi(CiObject): Promise<any> {
        const res = await fetch(config.url.API_URL + "/api/admin/supplier/ci", {
            method: "POST",
            body: JSON.stringify(CiObject),
        });
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status !== 200) {
            const json = await res.json();
            throw { message: errorCodeTranslator(json.message) };
        }
    }

    /*updates the supplier charge*/

    async updateSupplierCharge(
        subAry: [
            {
                type: string;
                commodity: number;
                value: number;
                pricing_type: string;
            },
            {
                type: string;
                commodity: number;
                value: number;
                pricing_type: string;
            },
            {
                type: string;
                commodity: number;
                value: number;
                pricing_type: string;
            },
            {
                type: string;
                commodity: number;
                value: number;
                pricing_type: string;
            },
            {
                type: string;
                commodity: number;
                value: number;
                pricing_type: string;
            },
            {
                type: string;
                commodity: number;
                value: number;
                pricing_type: string;
            },
            {
                type: string;
                commodity: number;
                value: number;
                pricing_type: string;
            },
            {
                type: string;
                commodity: number;
                value: number;
                pricing_type: string;
            },
        ]
    ): Promise<void> {
        const res = await fetch(
            config.url.API_URL + "/api/admin/update/supplier/charge",
            {
                method: "PATCH",
                body: JSON.stringify(subAry),
            }
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status !== 200) {
            const json = await res.json();
            throw { message: errorCodeTranslator(json.message) };
        }
    }

    /**
     * Gets info of single customer
     * @param id
     */
    async getCustomerInfo(
        id: number | undefined | string
    ): Promise<CustomerModel> {
        const res = await fetch(
            config.url.API_URL + "/api/admin/customer/" + id
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }

        return json;
    }

    /**
     * Gets info of single customer
     * @param id
     */
    async getSupplierInfo(): Promise<any> {
        const res = await fetch(
            config.url.API_URL + "/api/supplier/info"
            // config.url.API_URL + "/api/admin/supplier/info"
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200) {
            throw { message: errorCodeTranslator(json.message) };
        }

        return json;
    }

    /**
     * Gets all orders of one customer
     * @param customerId
     */
    async getOrdersByCustomerId(customerId: number): Promise<OrderModel[]> {
        const res = await fetch(
            config.url.API_URL + "/api/admin/" + customerId + "/orders"
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        const json = await res.json();
        if (res.status !== 200 && res.status !== 204) {
            throw { message: errorCodeTranslator(json.message) };
        } else if (res.status === 204) {
            return [];
        }

        return json;
    }

    async delete(type: "user" | "customer", id: number) {
        const res = await fetch(
            config.url.API_URL + "/api/admin/delete/" + type + "/" + id,
            {
                method: "DELETE",
            }
        );
        if (res.status === 401) {
            throw { message: res.status.toString() };
        }
        if (res.status !== 200) {
            const json = await res.json();
            throw { message: errorCodeTranslator(json.message) };
        }
    }
}

export default UserService;
