import React from "react";
import { useSelector } from "services/helpers/useSelector";
import { useDispatch } from "react-redux";
import modalActions from "actions/modal.actions";
import { Modal, Grid, Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "3em 2em",
        margin: "3em 0em 3em 0em",
    },
    grid: {
        height: "100%",
        overflow: "auto",
    },
}));
/**
 * Component for Modal
 */
function ModalComponent() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const open = useSelector((state) => state.modal?.open);
    const ModalBody = useSelector((state) => state.modal.body);
    const props = useSelector((state) => state.modal.props);
    const lg = useSelector((state) => state.modal.lg);
    function handleClose() {
        // console.log("In Close Modal Modal Component");
        dispatch(modalActions.closeModal());
    }
    return (
        <div>
            <Modal open={open} onClose={handleClose} data-testid="modal">
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.grid}
                >
                    <Grid item xs={12} md={10} lg={lg ? lg : 6}>
                        <Paper className={classes.paper}>
                            {ModalBody ? <ModalBody {...props} /> : <></>}
                        </Paper>
                    </Grid>
                </Grid>
            </Modal>
        </div>
    );
}

export default ModalComponent;
