import React, { useState, useEffect } from "react";
import { Grid, Typography, ListItemText } from "@material-ui/core";
import { lang } from "../lang";
import { useDispatch } from "react-redux";
import forecastActions from "../actions/forecast.actions";
import { useSelector } from "../services/helpers/useSelector";
import { KeyboardDatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import ForecastService from "../services/forecast/ForecastService";
import { format,formatDistance, addDays, add, differenceInDays, subHours, addHours } from "date-fns";

interface SelectDatePeriodProps {
  min: Date;
  max: Date;
  forecastType: number;
}

const service = new ForecastService();
/**
 * Component is for selecting the period in what the uploaded data is
 * @constructor
 */
function SelectDataPeriodComponent(props: SelectDatePeriodProps) {
  const locale = localStorage.locale;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.forecast.fleximportData);
  const stateData = useSelector((state) => state.forecast.data);
  const forecastType = useSelector((state) => state.forecast.data.forecastType);
  const { startDateValue, endDateValue } = data;
  const [checkFromDate, setCheckFromDate] = useState<string[]>([]);
  const [checkToDate, setCheckToDate] = useState<string[]>([]);

  const toDay = new Date();
  const tommorow = add(toDay, { days: 1})
  let endYear = toDay.getFullYear() + 4;
  let endOfNextFourYears = new Date(endYear, 11, 31);

  useEffect(() => {
    startDateValue !== null &&
      setCheckFromDate(
        service.checkFromDate(
          startDateValue,
          endDateValue,
          props.min,
          forecastType,
          stateData.priceCurve,
          toDay,
          tommorow
        )
      );
  }, [startDateValue, endDateValue,stateData.pricing_type]);

  useEffect(() => {
    endDateValue !== null &&
      setCheckToDate(
        service.checkToDate(endDateValue, props.max, forecastType, stateData.priceCurve, endOfNextFourYears)
      );
  }, [endDateValue,stateData.pricing_type]);

  return (
    <Grid container alignItems="center" spacing={4}>
      <Grid item xs={6} style={{ padding: "0 1rem 1rem 1rem" }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === "de" ? deLocale : enLocale}>
        <KeyboardDatePicker
          disabled={stateData.priceCurve === "0" && stateData.forecastType === 0}
          // minDate={Date.now()}
          helperText=""
          required
          variant="inline"
          format="dd.MM.yyyy"
          margin="normal"
          data-testid={"startdate"}
          /* minDate={props.forecastType === 0 ? props.min : null}
                    minDateMessage={
                        "Das Datum darf nicht vor dem " +
                        format(props.min, "dd.MM.yyyy") +
                        " liegen."
                    } */
          error={data.startDateValue === null}
          value={startDateValue}
          autoOk={true}
          onChange={(date: Date | null) => {
            dispatch(
              forecastActions.saveFleximportSingle("startDateValue", date)
            );
          }}
          id="enddate"
          label={lang[locale].startdate}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>

        <Grid>
          {startDateValue !== null &&
            checkFromDate.map((entry: string) => (
              <ListItemText>
                <Typography
                  variant="caption"
                  color="error"
                  data-testid="date-error"
                >
                  {entry}
                </Typography>
              </ListItemText>
            ))}
        </Grid>
        {stateData.priceCurve === "0" && stateData.forecastType === 0 && (
             <Grid>
             <ListItemText>
               <Typography
                 variant="caption"
                 color="error"
                 data-testid="date-error"
               >
                {lang[locale].pricecurvefirst}
               </Typography>
             </ListItemText>
           </Grid>
        )}
       
      </Grid>
      <Grid item xs={6} style={{ padding: "0 1rem 1rem 1rem" }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === "de" ? deLocale : enLocale}>
        <KeyboardDatePicker
          disabled={stateData.priceCurve === "0" && stateData.forecastType === 0}
          helperText=""
          required
          variant="inline"
          format="dd.MM.yyyy"
          margin="normal"
          data-testid={"enddate"}
          /* maxDate={props.forecastType === 0 ? props.max : null}
                    maxDateMessage={
                        "Das Datum darf nicht nach dem " +
                        format(props.max, "dd.MM.yyyy") +
                        " liegen."
                    } */
          error={data.endDateValue === null}
          value={endDateValue}
          autoOk={true}
          onChange={(date: Date | null) => {
            dispatch(
              forecastActions.saveFleximportSingle("endDateValue", date)
            );
          }}
          id="enddate"
          label={lang[locale].enddate}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>

        <Grid>
          {endDateValue !== null &&
            checkToDate.map((entry: string) => (
              <ListItemText>
                <Typography
                  variant="caption"
                  color="error"
                  data-testid="date-error"
                >
                  {entry}
                </Typography>
              </ListItemText>
            ))}
        </Grid>
        {stateData.priceCurve === "0" && stateData.forecastType === 0 && (
             <Grid>
             <ListItemText>
               <Typography
                 variant="caption"
                 color="error"
                 data-testid="date-error"
               >
                 {lang[locale].pricecurvefirst}
               </Typography>
             </ListItemText>
           </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default SelectDataPeriodComponent;
