import React, {useEffect} from "react";

import { Grid, useTheme } from "@material-ui/core";

import uPlot from "uplot";
import "style/uplot.min.css";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { lang } from "lang";

/**
 * Area chart with zoom functionality
 * @param data data of chart
 * @constructor
 */
function LineChartComponent({ data, title, color = "primary" }) {
    const locale = localStorage.locale;
    // console.log("LineChart", data, title, color);

    function getSize() {
        return {
            width:
                window.innerWidth > 1100
                    ? window.innerWidth - 500
                    : window.innerWidth - 200,
            height: window.innerHeight - 300,
        };
    }

    const diagramEl = React.useRef<any>(null);
    const theme = useTheme();
    let opts = {
        title: title,
        ...getSize(),
        scales: {
            x: {
                time: true,
                //	auto: false,
                //	range: [0, 6],
            }
        },
        series: [
            {
                label: lang[locale].time,
                value: (self, rawValue) =>
                    format(rawValue * 1000, "dd.MM.yyyy HH:mm", { locale: de }),
            },
            {
                label: lang[locale].performance + " in kW",
                stroke: theme.palette[color].main,
            },
        ],
         hooks: {
            ready: [
                (u) => {
                    // Disable legend label click functionality
                    const legendLabels = u.root.querySelectorAll('.u-legend .u-series');
                    legendLabels.forEach((label) => {
                        label.style.pointerEvents = 'none';
                    });
                }
            ]
        },
        axes: [
            {

                stroke: theme.palette.text.primary,
                values: [
                    // tick incr          default           year                             month    day                        hour     min                sec       mode
                    [
                        3600 * 24 * 365,
                        "{YYYY}",
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        1,
                    ],
                    [
                        3600 * 24 * 28,
                        "{MM}.{YY}",
                        "\n{YYYY}",
                        null,
                        null,
                        null,
                        null,
                        null,
                        1,
                    ],
                    [
                        3600 * 24,
                        "{D}.{MM}",
                        "\n{YYYY}",
                        null,
                        null,
                        null,
                        null,
                        null,
                        1,
                    ],
                    [
                        3600,
                        "{H}:{mm}",
                        "\n{D}.{MM}.{YY}",
                        null,
                        "\n{D}.{M}",
                        null,
                        null,
                        null,
                        1,
                    ],
                    [
                        60,
                        "{H}:{mm}",
                        "\n{D}.{M}.{YY}",
                        null,
                        "\n{D}.{M}",
                        null,
                        null,
                        null,
                        1,
                    ],
                    [
                        1,
                        ":{ss}",
                        "\n{D}.{M}.{YY} {H}:{mm}",
                        null,
                        "\n{D}.{M} {H}:{mm}",
                        null,
                        "\n{H}:{mm}",
                        null,
                        1,
                    ],
                    [
                        0.001,
                        ":{ss}.{fff}",
                        "\n{D}.{M}.{YY} {H}:{mm}",
                        null,
                        "\n{D}.{M} {H}:{mm}",
                        null,
                        "\n{H}:{mm}",
                        null,
                        1,
                    ],
                ],
            },
            {
                stroke: theme.palette.text.primary,
                size: 100,
            },
        ],
    };
    const [diagram, setDiagram] = React.useState<any>();

    useEffect(() => {
        if (!diagram) setDiagram(new uPlot(opts, data, diagramEl.current));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const debouncedHandleResize = function handleResize() {
            if (diagram) {
                diagram.setSize(getSize());
            }
        };

        window.addEventListener("resize", debouncedHandleResize);

        return () => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Grid item xs={12}>
            <div ref={diagramEl} />
        </Grid>
    );
}

export default LineChartComponent;
