import React, { useEffect, useState } from "react";
import { useSelector } from "../services/helpers/useSelector";
import { useDispatch } from "react-redux";
import forecastActions from "../actions/forecast.actions";
import modalActions from "../actions/modal.actions";
import FormelPriceComponent from "./FormelPrice";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Button,
  Tooltip,
} from "@material-ui/core";
import { lang } from "../lang";
import PriceCurveModel from "../typeDefs/PriceCurveModel";
import ForecastService from "../services/forecast/ForecastService";
import snackbarActions from "../actions/snackbar.actions";
import AuthService from "../services/auth/AuthService";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";

const service = new ForecastService();

interface SelectPriceCurveProps {
  gas: boolean;
  setMinMax?(min: string, max: string): void;
  fromPage: string;
  preisType: string | undefined;
  isInCreatePE?: boolean;
  setPreiskurveListe: any;
}

function SelectPriceCurveComponent(props: SelectPriceCurveProps) {
  const location = useLocation();
  const pathName = location.pathname?.split("/")[2];
  let history = useHistory();
  const locale = localStorage.locale;
  const [priceCurves, setPriceCurves] = useState<PriceCurveModel[] | undefined>(
    undefined
  );
  const priceCurve = useSelector((state) => state.forecast.data.priceCurve);
  const formula = useSelector((state) => state.forecast.data.formula);
  const [gesamtFormel, setGesamtFormel] = useState<string[]>([]);
  const dispatch = useDispatch();
  const [preisArt, setPreisArt] = useState("PREISKURVE");
  const [usertype, setUserType] = useState("");
  const role = AuthService.getRole();
  const globalstate = useSelector((state) => state);
  // const stateCustomer = useSelector((state) => state.customer);
  // const usertype = stateCustomer["customerData"]["type"];
  useEffect(() => {
    if (pathName !== "pricedevelopment") {
      setUserType(globalstate.customer["customerData"]["type"]);
    }
  }, []);
  //if gas changes, get the new pricecurves
  useEffect(() => {
    service
      .getPriceCurves(props.gas)
      .then((curves) => {
        setPriceCurves(curves);
        props.setPreiskurveListe(curves);
      })
      .catch((e) => {
        if (e.message === "401") {
          return history.push(`/logout`);
        }
        console.error("Error getting pricecurves", e);
        dispatch(snackbarActions.openSnackbar(e.message, "error"));
      });
  }, [props.gas]);

  useEffect(() => {
    if (
      (formula && Object.keys(formula).length !== 0) ||
      (props.preisType !== undefined && props.preisType === "FORMEL")
    ) {
      setPreisArt("FORMEL");
    }
  }, []);

  useEffect(() => {
    if (formula && Object.keys(formula).length > 0) {
      if (Object.keys(formula).length > 0 && formula["formulas"].length > 0) {
        // setAdditivDisable(true);
      }
      // setConstant(formula["constant"]);
      // setAdditiv(formula["constant"]);
      // setFormulas(formula["formulas"]);
      if (formula["formulas"]?.length === 1) {
        setGesamtFormel([
          `${formula["formulas"][0].factor} x ${formula["formulas"][0].time_frame} ${formula["formulas"][0].product} ${formula["formulas"][0].year} + `,
        ]);
      } else if (formula["formulas"]?.length > 1) {
        let gesamtFormelArr: string[] = [];
        for (let i of formula["formulas"]) {
          gesamtFormelArr.push(
            ` ${i.factor} x ${i.time_frame} ${i.product} ${i.year} + `
          );
        }
        setGesamtFormel(gesamtFormelArr);
      }
    }
  }, [formula]);

  function handelEditFormelClick(e) {
    if (formula && Object.keys(formula).length !== 0) {
      e.stopPropagation();
      dispatch(
        modalActions.openModal(FormelPriceComponent, {
          fromPage: props.fromPage,
          formula: formula,
        })
      );
    }
  }

  function handleFormelClick(e) {
    e.stopPropagation();
    dispatch(
      modalActions.openModal(FormelPriceComponent, {
        fromPage: props.fromPage,
      })
    );
  }

  /**
   * Changes profile in state
   * @param e
   */
  function handleChange(e) {
    dispatch(forecastActions.saveForecastSingle("forecastType", 0));
    if (priceCurves) {
      const curve = priceCurves.filter(
        (curve) => curve.description === e.target.value
      )[0];
      dispatch(
        forecastActions.saveForecastSingle("priceCurve", e.target.value)
      );
      dispatch(forecastActions.saveForecastSingle("pricing_type", curve.kind));
      if (curve === undefined) {
        dispatch(forecastActions.saveForecastSingle("pricing_type", "SPOT"));
      }
      if (curve !== undefined) {
        // dispatch(
        //   forecastActions.saveForecastSingle("pricing_type", "PREISKURVE")
        // );
        if (usertype && pathName !== "pricedevelopment" && usertype === "F") {
          dispatch(forecastActions.saveForecastSingle("tranche", 0));
        }
        if (usertype && pathName !== "pricedevelopment" && usertype === "E") {
          dispatch(forecastActions.saveForecastSingle("tranche", 1));
        }
      }
      if (curve && props.setMinMax) {
        props.setMinMax(
          new Date(curve.min).toDateString(),
          new Date(curve.max).toDateString()
        );
      }
    }
  }

  function handleArtChange(e) {
    setPreisArt(e.target.value);
    dispatch(
      forecastActions.saveForecastSingle("pricing_type", e.target.value)
    );
  }

  //check if only one pricecurve is available and set it then
  useEffect(() => {
    if (priceCurves && priceCurves.length === 1) {
      dispatch(
        forecastActions.saveForecastSingle(
          "priceCurve",
          priceCurves[0].description
        )
      );
      dispatch(forecastActions.saveForecastSingle("pricing_type", preisArt));
      if (priceCurves[0] && props.setMinMax) {
        props.setMinMax(
          new Date(priceCurves[0].min).toDateString(),
          new Date(priceCurves[0].max).toDateString()
        );
      }
    }
    // resolve pricecurve select reset wenn we return from formel
    // else {
    //   dispatch(forecastActions.saveForecastSingle("priceCurve", "0"));
    // }
  }, [priceCurves]);

  return (
    <Grid
      item
      xs={12}
      md={12}
      style={{ display: "flex", flexDirection: "row" }}
    >
      {priceCurves !== undefined ? (
        <>
          {priceCurves.length > 1 ? (
            <>
              <FormControl
                fullWidth={true}
                error={priceCurve === "0"}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // maxWidth: "14em",
                  marginTop: "0.5rem",
                  // marginRight : "7rem"
                }}
              >
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                  <InputLabel id="priceCurve" style={{ fontSize: "1rem" }}>
                    {lang[locale].priceCurve}
                  </InputLabel>
                  <Select
                    labelId="priceCurve"
                    id="priceCurveSelect"
                    inputProps={{
                      "data-testid": "priceCurveSelect",
                    }}
                    value={priceCurve ? priceCurve : ""}
                    onChange={handleChange}
                  >
                    {priceCurves.map((curve: PriceCurveModel) => (
                      <MenuItem
                        data-testid="priceCurve"
                        key={curve.description}
                        value={curve.description ? curve.description : ""}
                      >
                        {curve.description}
                      </MenuItem>
                    ))}
                    {/* <MenuItem
                      data-testid="priceCurve"
                      key={"SPOT"}
                      value={
                        props.gas
                          ? "timpetools Spotmarkt Gas"
                          : "timpetools Spotmarkt Strom"
                      }
                    >
                      Spot
                    </MenuItem> */}
                  </Select>
                </Grid>
              </FormControl>
              {priceCurve.indexOf("Spotmarkt") === -1 &&
              role.simple_role === "LIEFERANT" ? (
                //preis art
                <Grid
                  xs={12}
                  md={6}
                  style={{ marginTop: "0.5rem", marginLeft: "2rem", display : "flex", flexDirection : "row", alignItems : "center" }}
                >
                  <FormControl>
                    <InputLabel id="priceCurve">
                      {lang[locale].pricetype}
                    </InputLabel>
                    <Select
                      autoWidth
                      labelId="priceArt"
                      id="priceArtSelect"
                      inputProps={{
                        "data-testid": "priceArtSelect",
                      }}
                      value={preisArt ? preisArt : ""}
                      onChange={handleArtChange}
                      style={{ cursor: "pointer" }}
                    >
                      <MenuItem
                        data-testid="priceArt"
                        key={"PREISKURVE"}
                        value={"PREISKURVE"}
                      >
                        {lang[locale].pricecurve}
                      </MenuItem>
                      <MenuItem
                        data-testid="priceArt"
                        key={"FORMEL"}
                        value={"FORMEL"}
                      >
                        {lang[locale].formula}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {preisArt === "FORMEL" &&
                  priceCurve.indexOf("Spotmarkt") === -1 ? (
                    <Button
                      style={{
                        color:
                          formula && Object.keys(formula).length !== 0
                            ? "white"
                            : "red",
                        width: "0.3em",
                        padding: 0,
                        marginTop: "2rem",
                      }}
                      onClick={
                        formula && Object.keys(formula).length !== 0
                          ? handelEditFormelClick
                          : handleFormelClick
                      }
                    >
                      <Tooltip
                        title={
                          formula && Object.keys(formula).length > 0
                            ? `${gesamtFormel} ${formula["constant"]}`
                            : "Neue Formel erstellen"
                        }
                      >
                        <BorderColorIcon style={{ fontSize: "1rem" }} />
                      </Tooltip>
                    </Button>
                  ) : null}
                </Grid>
              ) : null}
            </>
          ) : (
            <>
              <FormControl
                fullWidth={true}
                error={priceCurve === "0"}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // maxWidth: "14em",
                  marginTop: "1rem",
                }}
              >
                <Grid item xs={12} md={8}>
                  <InputLabel id="priceCurve" style={{ fontSize: "1rem" }}>
                    {lang[locale].priceCurve}
                  </InputLabel>
                  <Select
                    labelId="priceCurve"
                    id="priceCurveSelect"
                    inputProps={{
                      "data-testid": "priceCurveSelect",
                    }}
                    value={priceCurve ? priceCurve : ""}
                    onChange={handleChange}
                  >
                    {priceCurves.map((curve: PriceCurveModel) => (
                      <MenuItem
                        data-testid="priceCurve"
                        key={curve.description}
                        value={curve.description ? curve.description : ""}
                      >
                        {curve.description}
                      </MenuItem>
                    ))}
                    {/* <MenuItem
                      data-testid="priceCurve"
                      key={"SPOT"}
                      value={
                        props.gas
                          ? "timpetools Spotmarkt Gas"
                          : "timpetools Spotmarkt Strom"
                      }
                    >
                      Spot
                    </MenuItem> */}
                  </Select>
                </Grid>
              </FormControl>
              {priceCurve.indexOf("Spotmarkt") === -1 ? (
                <div style={{ marginTop: "1rem" }}>
                  <FormControl>
                    <InputLabel id="priceCurve">Preisart</InputLabel>
                    <Select
                      labelId="priceArt"
                      id="priceArtSelect"
                      inputProps={{
                        "data-testid": "priceArtSelect",
                      }}
                      value={preisArt ? preisArt : ""}
                      onChange={handleArtChange}
                      style={{ cursor: "pointer" }}
                    >
                      <MenuItem
                        data-testid="priceArt"
                        key={"PREISKURVE"}
                        value={"PREISKURVE"}
                      >
                        {lang[locale].priceCurve}
                      </MenuItem>
                      <MenuItem
                        data-testid="priceArt"
                        key={"FORMEL"}
                        value={"FORMEL"}
                      >
                        {lang[locale].formula}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              ) : null}
              {preisArt === "FORMEL" &&
              priceCurve.indexOf("Spotmarkt") === -1 ? (
                <Button
                  style={{
                    color:
                      formula && Object.keys(formula).length !== 0
                        ? "white"
                        : "red",
                    width: "0.3em",
                    padding: 0,
                    marginTop: "2.8rem",
                  }}
                  onClick={
                    formula && Object.keys(formula).length !== 0
                      ? handelEditFormelClick
                      : handleFormelClick
                  }
                >
                  <Tooltip
                    title={
                      formula && Object.keys(formula).length > 0
                        ? `${gesamtFormel} ${formula["constant"]}`
                        : "Neue Formel erstellen"
                    }
                  >
                    <BorderColorIcon style={{ fontSize: "1rem" }} />
                  </Tooltip>
                </Button>
              ) : null}
            </>
          )}
        </>
      ) : (
        <CircularProgress data-testid="circular" />
      )}
    </Grid>
  );
}

export default SelectPriceCurveComponent;
